// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('feedController', ['$scope', 'Restangular', '$rootScope', function (
//   $scope: any,
//   Restangular: any,
//   $rootScope: any
// ) {
//   const init = function () {
//     $scope.items_to_show = 0
//     return get_user_feed_object()
//   }

//   var get_user_feed_object = function () {
//     const user_feed_object = Restangular.one(`users/${$rootScope.session.getUser().id}/feed`)
//     return user_feed_object.get().then(function (response: any) {
//       $scope.items_to_show = response.new_count
//       return $scope.user_feed_object = response
//     }
//     , (error: any) => console.log(error));
//   }

//   $scope.show_more_items = (x: any) => $scope.items_to_show += x

//   return init()
// }])
