/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'


angular.module('complyosClient').directive('apiSearchOption', [
  'fortyCore', 'arrayFunctions', 'helpService', 'Restangular',
  (fortyCore: any, arrayFunctions: any, helpService: any, Restangular: any) => ({
    templateUrl: 'views/directives/api_search_option.html',
    restrict: 'E',

    scope: {
      node: '=',
      enableDescendantChecking: '=',
      descendantsName: '<',
      descendantsPrefix: '<',
      selectNextNode: '&',
      displayNameKey: '<',
      valueKey: '<'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
      $scope.nodeTreeBuilder = {
        isLoading: true,
        isPopoutOpen: false,
        searchInput: '',
        nodePath: [],
        currentNodeList: [],
        maxItemCount: 150
      }

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.showChildrenDescendantCheckingHelpers = () => {
        return $scope.enableDescendantChecking && Array.isArray($scope.node.children) && ($scope.node.children.length > 0 || $scope.node.has_children == true)
      }

      return $scope
    }
  })
])
