import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngFlashMessages', // <ng-flash-messages>
  templateUrl: './flash-messages.component.html',
  styleUrls: ['./flash-messages.component.scss']
})
export class FlashMessages implements OnInit {

  @Input() alerts: Array<any> = [];

  constructor() { }

  ngOnInit(): void {
  }

}

angular
  .module('complyosClient')
  .directive('ngFlashMessages', downgradeComponent({ component: FlashMessages }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('flashDirective', () =>
//   ({
//     templateUrl: 'views/directives/flash-display.html',
//     restrict: 'E',
//     scope: {
//       alert_array: '=alerts'
//     }
//   })
// )