import * as angular from 'angular';
import _ from 'lodash';
import { Injectable, Inject } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable()
export class FlashService {

  constructor(
    @Inject('$timeout') private $timeout: any
  ) {
  }

  alerts: Array<any> = [];
  default_alerts: any = {
    ok: {
      name: 'default_ok',
      dismissable: true,
      class: 'alert-info',
      icon: 'fa-thumbs-up',
      strong: 'Success',
      message: 'Standard response for successful HTTP requests'
    },

    success: {
      name: 'default_success',
      dismissable: true,
      class: 'alert-success',
      icon: 'fa-thumbs-up',
      strong: 'Success',
      message: 'Standard response for successful HTTP requests'
    },

    hunch: {
      name: 'default_hunch',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error',
      message: 'There was an unknown error. Please contact us so we can work on a resolution for you'
    },

    toolarge: {
      name: 'default_toolarge',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error',
      message: 'Your file was too large for us to handle'
    },

    error: {
      name: 'default_error',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error',
      message: 'Something went wrong'
    },

    unauthorized: {
      name: 'default_unauthorized',
      dismissable: true,
      class: 'alert-danger',
      icon: 'fa-lock',
      strong: 'Unauthorized',
      message: 'You do not have sufficient privileges to perform this action'
    },

    unprocessable: {
      name: 'default_unprocessable',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Bad data',
      message: 'This action cannot be processed'
    },

    timeout: {
      name: 'default_timeout',
      dismissable: true,
      class: 'alert-info',
      icon: 'fa-clock-o',
      strong: 'Timeout',
      message: 'Your session has expired and you must log in again'
    },

    locked: {
      name: 'default_locked',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-lock',
      strong: 'Locked',
      message: 'This element is currently un-editable. Please contact your administrator if you believe this is in error'
    },

    unacceptable: {
      name: 'default_not_acceptable',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error',
      message: 'File contents were corrupted during upload or on retrieval from the server. Verify contents and try again.'
    }
  };

  add_default_alert(name: any) {
    return this.add_alert(this.default_alerts[name])
  }

  add_alert(alert_object: any) {
    // extend the default_alert_options with the custom alert_object
    const default_alert_options = {
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error'
    }

    alert_object = angular.extend(default_alert_options, alert_object)

    if ((alert_object.name !== undefined) && (alert_object.name !== null)) {
      let duplicate_alert = false

      let that = this;
      _.each(this.alerts, function (a: any) {
        if (a.name === alert_object.name) {
          duplicate_alert = true
          a.class = a.class + ' animated shake'
          return that.$timeout(() => a.class = a.class.replace(/animated shake/g, '')
            , 1000);
        }
      })

      if (!duplicate_alert) {
        const full_alert_object = _.merge(alert_object, this.alert_functions(alert_object))
        return this.alerts.push(full_alert_object)
      }
    }
  }

  remove_alert(alert_object: any) {
    return this.alerts = _.pull(this.alerts, alert_object)
  }

  remove_alert_by_name(alert_name: any) {
    return this.alerts = this.alerts.filter(function (alert) {
      return alert.name !== alert_name
    })
  }

  clear_alerts() {
    return this.alerts = []
  }

  emit_alert(scope: any, alert: any) {
    return scope.$emit('emit_alert', alert)
  }

  broadcast_alert(scope: any, alert: any) {
    return scope.$broadcast('broadcast_alert', alert)
  }

  listen_for_alert(scope: any) {
    scope.$on('broadcast_alert', (event: any, data: any) => {
      return this.add_alert(data)
    })

    return scope.$on('emit_alert', (event: any, data: any) => {
      return this.add_alert(data)
    });
  }

  default_alert_by_code(code: any) {
    switch (code) {
      case 200:
        return this.default_alerts['ok']
      case 401: case 403:
        return this.default_alerts['unauthorized']
      case 406:
        return this.default_alerts['unacceptable']
      case 413:
        return this.default_alerts['toolarge']
      case 422:
        return this.default_alerts['unprocessable']
      case 498:
        return this.default_alerts['timeout']
      case 423:
        return this.default_alerts['locked']
      case -1:
        return this.default_alerts['hunch']
      default:
        return this.default_alerts['error']
    }
  }

  process_error(error: any) {
    // console.log(error)

    const alert_object = angular.copy(this.default_alert_by_code(error.status))
    if (Array.isArray(error.data)) {
      alert_object.message = alert_object.message + ' :: ' + error.data.join(', ')
    }

    return this.add_alert(alert_object)
  }

  alert_functions(alert_object: any) {
    return {
      dismiss: () => {
        return this.remove_alert(alert_object)
      }
    }
  }
}

// angular
//   .module('complyosClient')
//   .service('flashService', flashService);
angular
  .module("complyosClient")
  .factory("flashService", downgradeInjectable(FlashService));

//- --------------------------------------------------------------------
//- FOR REFERENCE: njs-classified

// @Injectable()
// export class flashService {
//   private $timeout: any;

//   static $inject = ['$timeout'];
//   constructor($timeout: any) {
//     this.$timeout = $timeout;
//   }

//   alerts: Array<any> = [];
//   default_alerts: any = {
//     ok: {
//       name: 'default_ok',
//       dismissable: true,
//       class: 'alert-info',
//       icon: 'fa-thumbs-up',
//       strong: 'Success',
//       message: 'Standard response for successful HTTP requests'
//     },

//     success: {
//       name: 'default_success',
//       dismissable: true,
//       class: 'alert-success',
//       icon: 'fa-thumbs-up',
//       strong: 'Success',
//       message: 'Standard response for successful HTTP requests'
//     },

//     hunch: {
//       name: 'default_hunch',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Error',
//       message: 'There was an unknown error. Please contact us so we can work on a resolution for you'
//     },

//     toolarge: {
//       name: 'default_toolarge',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Error',
//       message: 'Your file was too large for us to handle'
//     },

//     error: {
//       name: 'default_error',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Error',
//       message: 'Something went wrong'
//     },

//     unauthorized: {
//       name: 'default_unauthorized',
//       dismissable: true,
//       class: 'alert-danger',
//       icon: 'fa-lock',
//       strong: 'Unauthorized',
//       message: 'You do not have sufficient privileges to perform this action'
//     },

//     unprocessable: {
//       name: 'default_unprocessable',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Bad data',
//       message: 'This action cannot be processed'
//     },

//     timeout: {
//       name: 'default_timeout',
//       dismissable: true,
//       class: 'alert-info',
//       icon: 'fa-clock-o',
//       strong: 'Timeout',
//       message: 'Your session has expired and you must log in again'
//     },

//     locked: {
//       name: 'default_locked',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-lock',
//       strong: 'Locked',
//       message: 'This element is currently un-editable. Please contact your administrator if you believe this is in error'
//     },

//     unacceptable: {
//       name: 'default_not_acceptable',
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Error',
//       message: 'File contents were corrupted during upload or on retrieval from the server. Verify contents and try again.'
//     }
//   };

//   add_default_alert(name: any) {
//     return this.add_alert(this.default_alerts[name])
//   }

//   add_alert(alert_object: any) {
//     // extend the default_alert_options with the custom alert_object
//     const default_alert_options = {
//       dismissable: true,
//       class: 'alert-warning',
//       icon: 'fa-exclamation-triangle',
//       strong: 'Error'
//     }

//     alert_object = angular.extend(default_alert_options, alert_object)

//     if ((alert_object.name !== undefined) && (alert_object.name !== null)) {
//       let duplicate_alert = false

//       let that = this;
//       _.each(this.alerts, function (a: any) {
//         if (a.name === alert_object.name) {
//           duplicate_alert = true
//           a.class = a.class + ' animated shake'
//           return that.$timeout(() => a.class = a.class.replace(/animated shake/g, '')
//             , 1000);
//         }
//       })

//       if (!duplicate_alert) {
//         const full_alert_object = _.merge(alert_object, this.alert_functions(alert_object))
//         return this.alerts.push(full_alert_object)
//       }
//     }
//   }

//   remove_alert(alert_object: any) {
//     return this.alerts = _.pull(this.alerts, alert_object)
//   }

//   remove_alert_by_name(alert_name: any) {
//     return this.alerts = this.alerts.filter(function (alert) {
//       return alert.name !== alert_name
//     })
//   }

//   clear_alerts() {
//     return this.alerts = []
//   }

//   emit_alert(scope: any, alert: any) {
//     return scope.$emit('emit_alert', alert)
//   }

//   broadcast_alert(scope: any, alert: any) {
//     return scope.$broadcast('broadcast_alert', alert)
//   }

//   listen_for_alert(scope: any) {
//     scope.$on('broadcast_alert', (event: any, data: any) => {
//       return this.add_alert(data)
//     })

//     return scope.$on('emit_alert', (event: any, data: any) => {
//       return this.add_alert(data)
//     });
//   }

//   default_alert_by_code(code: any) {
//     switch (code) {
//       case 200:
//         return this.default_alerts['ok']
//       case 401: case 403:
//         return this.default_alerts['unauthorized']
//       case 406:
//         return this.default_alerts['unacceptable']
//       case 413:
//         return this.default_alerts['toolarge']
//       case 422:
//         return this.default_alerts['unprocessable']
//       case 498:
//         return this.default_alerts['timeout']
//       case 423:
//         return this.default_alerts['locked']
//       case -1:
//         return this.default_alerts['hunch']
//       default:
//         return this.default_alerts['error']
//     }
//   }

//   process_error(error: any) {
//     // console.log(error)

//     const alert_object = angular.copy(this.default_alert_by_code(error.status))
//     if (Array.isArray(error.data)) {
//       alert_object.message = alert_object.message + ' :: ' + error.data.join(', ')
//     }

//     return this.add_alert(alert_object)
//   }

//   alert_functions(alert_object: any) {
//     return {
//       dismiss: () => {
//         return this.remove_alert(alert_object)
//       }
//     }
//   }
// }

// angular
//   .module('complyosClient')
//   .service('flashService', flashService);


//- --------------------------------------------------------------------
//- FOR REFERENCE: njs


// complyosClient.factory('flashService', ['$timeout', function ($timeout: any) {
//   return {
//     default_alerts: {
//       ok: {
//         name: 'default_ok',
//         dismissable: true,
//         class: 'alert-info',
//         icon: 'fa-thumbs-up',
//         strong: 'Success',
//         message: 'Standard response for successful HTTP requests'
//       },

//       success: {
//         name: 'default_success',
//         dismissable: true,
//         class: 'alert-success',
//         icon: 'fa-thumbs-up',
//         strong: 'Success',
//         message: 'Standard response for successful HTTP requests'
//       },

//       hunch: {
//         name: 'default_hunch',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'There was an unknown error. Please contact us so we can work on a resolution for you'
//       },

//       toolarge: {
//         name: 'default_toolarge',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'Your file was too large for us to handle'
//       },

//       error: {
//         name: 'default_error',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'Something went wrong'
//       },

//       unauthorized: {
//         name: 'default_unauthorized',
//         dismissable: true,
//         class: 'alert-danger',
//         icon: 'fa-lock',
//         strong: 'Unauthorized',
//         message: 'You do not have sufficient privileges to perform this action'
//       },

//       unprocessable: {
//         name: 'default_unprocessable',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Bad data',
//         message: 'This action cannot be processed'
//       },

//       timeout: {
//         name: 'default_timeout',
//         dismissable: true,
//         class: 'alert-info',
//         icon: 'fa-clock-o',
//         strong: 'Timeout',
//         message: 'Your session has expired and you must log in again'
//       },

//       locked: {
//         name: 'default_locked',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-lock',
//         strong: 'Locked',
//         message: 'This element is currently un-editable. Please contact your administrator if you believe this is in error'
//       },

//       unacceptable: {
//         name: 'default_not_acceptable',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'File contents were corrupted during upload or on retrieval from the server. Verify contents and try again.'
//       }
//     },

//     alerts: [],

//     add_default_alert(name: any) {
//       return this.add_alert(this.default_alerts[name])
//     },

//     add_alert(alert_object: any) {
//       // extend the default_alert_options with the custom alert_object
//       const default_alert_options = {
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error'
//       }

//       alert_object = angular.extend(default_alert_options, alert_object)

//       if ((alert_object.name !== undefined) && (alert_object.name !== null)) {
//         let duplicate_alert = false

//         _.each(this.alerts, function (a: any) {
//           if (a.name === alert_object.name) {
//             duplicate_alert = true
//             a.class = a.class + ' animated shake'
//             return $timeout(() => a.class = a.class.replace(/animated shake/g, '')
//               , 1000);
//           }
//         })

//         if (!duplicate_alert) {
//           const full_alert_object = _.merge(alert_object, this.alert_functions(alert_object))
//           return this.alerts.push(full_alert_object)
//         }
//       }
//     },

//     remove_alert(alert_object: any) {
//       return this.alerts = _.pull(this.alerts, alert_object)
//     },

//     remove_alert_by_name(alert_name: any) {
//       return this.alerts = this.alerts.filter(function (alert) {
//         return alert.name !== alert_name
//       })
//     },

//     clear_alerts() {
//       return this.alerts = []
//     },

//     emit_alert(scope: any, alert: any) {
//       return scope.$emit('emit_alert', alert)
//     },

//     broadcast_alert(scope: any, alert: any) {
//       return scope.$broadcast('broadcast_alert', alert)
//     },

//     listen_for_alert(scope: any) {
//       scope.$on('broadcast_alert', (event: any, data: any) => {
//         return this.add_alert(data)
//       })

//       return scope.$on('emit_alert', (event: any, data: any) => {
//         return this.add_alert(data)
//       });
//     },

//     default_alert_by_code(code: any) {
//       switch (code) {
//         case 200:
//           return this.default_alerts['ok']
//         case 401: case 403:
//           return this.default_alerts['unauthorized']
//         case 406:
//           return this.default_alerts['unacceptable']
//         case 413:
//           return this.default_alerts['toolarge']
//         case 422:
//           return this.default_alerts['unprocessable']
//         case 498:
//           return this.default_alerts['timeout']
//         case 423:
//           return this.default_alerts['locked']
//         case -1:
//           return this.default_alerts['hunch']
//         default:
//           return this.default_alerts['error']
//       }
//     },

//     process_error(error: any) {
//       // console.log(error)

//       const alert_object = angular.copy(this.default_alert_by_code(error.status))
//       if (Array.isArray(error.data)) {
//         alert_object.message = alert_object.message + ' :: ' + error.data.join(', ')
//       }

//       return this.add_alert(alert_object)
//     },

//     alert_functions(alert_object: any) {
//       return {
//         dismiss: () => {
//           return this.remove_alert(alert_object)
//         }
//       }
//     }

//   };
// }])