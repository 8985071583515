// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('complyosOrganizationsController', ['$rootScope', '$scope', '$uibModal', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'Restangular', 'treeManagerService', 'utils', function (
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   confirmationModalFactory: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   Restangular: any,
//   treeManagerService: any,
//   utils: any
// ) {
//   const init = function () {
//     // service instantiation
    
//     $scope.flashService = angular.copy(flashService)
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.processingStateService = fortyCore.processingStateService
    
//     $scope.tree_manager = angular.copy(treeManagerService)

//     // variable instantiation
//     $scope.query = ''
//     $scope.organizations = undefined
//     $scope.flat_organizations = [] // used for displaying record counts

//     // function instantiation
//     // $scope.loadingStateService.init()
//     $scope.get_organizations()
//   }

//   // get list of organizations
//   // flatten the org tree
//   // and set a 'showChildren' property on each organization
//   $scope.get_organizations = () => {
//     $scope.loadingStateService.init()
//     $scope.organizations = undefined
//     Restangular.all('organizations').getList().then(function (success: any) {
//       $scope.loadingStateService.process_success(success)
//       $scope.organizations = success
//       // search records once orgs are loaded
//       $scope.search($scope.query)
//     }

//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       utils.log(error)
//     })
//   }

//   // watcher to re-flatten organizations whenever there is a change.
//   $scope.$watch('organizations', (newValue: any, oldValue: any) => {
//     $scope.flat_organizations = $scope.tree_manager.flatten_tree($scope.organizations)
//   }, true)

//   $scope.resetOrganizationTreeVisibility = () => {
//     $scope.tree_manager.recursively_assign_key($scope.organizations, 'show', true)
//     $scope.tree_manager.recursively_assign_key($scope.organizations, 'showChildren', false)
//   }

//   // watcher to handle searching
//   $scope.$watch('query', (newValue: any, oldValue: any) => {
//     if ($scope.organizations) {
//       $scope.search(newValue)
//     }
//   })

//   $scope.search = function (query: any) {
//     $scope.resetOrganizationTreeVisibility()

//     if (query !== '') {
//       // hide all organizations, reveal later if there are matches found.
//       $scope.tree_manager.recursively_assign_key($scope.organizations, 'show', false)

//       var recursively_evaluate_visibility = function (organization_array: any) {
//         let any_match = false
//         // Loop over the organization_array and look for any_match in tree
//         organization_array.forEach((organization: any) => {
//           let exact_match = false
//           let deep_match = false
//           // check the top level organization for an exact_match
//           if ($scope.organizationQueryMatch(organization)) {
//             exact_match = true
//           }
//           // loop over the children in a recursive manner look for a deep_match.
//           if (organization.children) {
//             deep_match = recursively_evaluate_visibility(organization.children)
//           }
//           // set any_match varible for recursion purposes.
//           if (exact_match || deep_match) {
//             any_match = true
//           }
//           // Assign show attributes based on match variables
//           organization.show = (exact_match || deep_match)
//           organization.showChildren = deep_match
//         })
//         return any_match
//       }

//       recursively_evaluate_visibility($scope.organizations)
//     }
//   }

//   $scope.recordCount = function () {
//     if ($scope.query) {
//       let num = $scope.flat_organizations.filter((org: any) => $scope.organizationQueryMatch(org)).length
//       return `(${num} Matches)`
//     } else {
//       let num = $scope.flat_organizations.length
//       if (num !== 0) {
//         return `(${num} Total)`
//       }
//     }
//   }

//   $scope.toggleShowChildren = (organization: any) => {
//     if (organization.showChildren) {
//       organization.showChildren = false
//     } else {
//       organization.showChildren = true
//       $scope.tree_manager.recursively_assign_key(organization.children, 'show', true)
//     }
//   }

//   // change the active organization for user
//   $scope.attempt_change_organization = (organization: any) => {
//     $scope.processingStateService.performFunction(organization, function () {
//       if (Array.isArray(organization.children) && organization.children.length > 0) {
//         let organizationHasChildren = true
//         let preventFilterChange = false
//         $rootScope.session.setOrganization(organization, preventFilterChange, organizationHasChildren)
//       } else {
//         $rootScope.session.setOrganization(organization)
//       }
//       $rootScope.session.goTo('/home')
//     })
//   }

//   // combine city and state with correct syntax as needed.
//   $scope.concat_locality_and_region = function (organization: any) {
//     // provide comma if both present
//     if (organization.locality && organization.region) {
//       return `${organization.locality}, ${organization.region}`
//     } else if ((organization.locality === null) || (organization.locality === '')) {
//       return organization.region
//     } else if ((organization.region === null) || (organization.region === '')) {
//       return organization.locality
//     } else {
//       // if neither provided, return empty string
//       return ''
//     }
//   }

//   /* FILTER */

//   // function that actually does string comparison for matching.
//   $scope.organizationQueryMatch = function (organization: any) {
//     const search_string = $scope.query.toLowerCase()
//     let match = false

//     if (search_string === '') {
//       match = true
//     } else if (organization.name.toLowerCase().indexOf(search_string) >= 0) {
//       match = true
//     } else if (organization.identifier && ((organization.identifier.toLowerCase()).indexOf(search_string) >= 0)) {
//       match = true
//     } else if (organization.org_type && (organization.org_type.toLowerCase().indexOf(search_string) >= 0)) {
//       match = true
//     } else if (organization.region && ((organization.region.toLowerCase()).indexOf(search_string) >= 0)) {
//       match = true
//     } else if (organization.locality && ((organization.locality.toLowerCase()).indexOf(search_string) >= 0)) {
//       match = true
//     }

//     return match
//   }

//   /* CRUD */

//   $scope.openOrganizationCreateModal = function (organizations: any) {
//     const new_organization_object = {}
//     return openOrganizationModal(new_organization_object, 'create', organizations)
//   }

//   $scope.openOrganizationReadModal = (organization_object: any, organizations: any) => {
//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(organization_object, () => openOrganizationModal(organization_object, 'read', organizations))
//   }

//   $scope.openOrganizationUpdateModal = (organization_object: any, organizations: any) => {
//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(organization_object, () => openOrganizationModal(organization_object, 'update', organizations))
//   }

//   $scope.openOrganizationDestroyModal = function (organization_object: any) {
//     const confirmation_object = {
//       // severity: severity string
//       // title: title string
//       // button_icon: icon string
//       // button_text: text string
//       // deny: method to run on dismiss
//       confirm () { return destroy_organization(organization_object) },
//       message: `<p>This action will:</p><ul><li>Delete ${organization_object.name}</li><li>Remove all the user associations that are connected to ${organization_object.name}</li><li>Delete all configured requirements belonging to ${organization_object.name}</li><li>Delete all requirement entries, attachments and notifications associated with the aforementioned configured requirements</li></ul><p>This action is not reversible.</p>`
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(organization_object, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(organization_object, $scope.processingStateService.processing_states.RESTING)
//         }
//         return true
//       })
//     );
//   }

//   var destroy_organization = (organization: any) => {
//     Restangular.all(`organizations/${organization.id}`).remove().then(function (success: any) {
//       $scope.tree_manager.remove_object_from_tree($scope.organizations, organization)
//       if ($rootScope.session.getOrganization() && ($rootScope.session.getOrganization().id === organization.id)) {
//         return $rootScope.session.setOrganization(null)
//       }
//     }, function (error: any) {
//       $scope.processingStateService.set(organization, $scope.processingStateService.processing_states.RESTING)
//       $scope.flashService.process_error(error)
//       return utils.log(error)
//     })
//   }

//   var openOrganizationModal = function (organization_object: any, ui_setting: any, organizations: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/organization_modal.html',
//       controller: 'organizationModalController',
//       size: 'small',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions () {
//           return {
            
//             organization: angular.copy(organization_object),
//             ui_setting,
            
//             organizations: angular.copy(organizations)
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     return modalInstance.result.then(function (result: any) {
//       if (ui_setting === 'create') {
//         // ensure that the organization will be visible.
//         result.show = true
//         $scope.tree_manager.add_object_to_tree($scope.organizations, result)
//       } else if (ui_setting === 'update') {
//         $scope.tree_manager.update_object_in_tree($scope.organizations, result)
//       }

//       if ($rootScope.session.getOrganization() && ($rootScope.session.getOrganization().id === result.id)) {
//         return $rootScope.session.setOrganization(result)
//       }
//     }, function (reason: any) {
//     }).finally(() => $scope.processingStateService.set(organization_object, $scope.processingStateService.processing_states.RESTING));
//   }

//   return init()
// }])
