import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngPrivacyPolicy',
  templateUrl: './privacy-policy.component.html'
})
export class PrivacyPolicyComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>
  ) {}

  ngOnInit(): void {
  }

  closeModal = () => this.dialogRef.close('close')
}

angular
  .module('complyosClient')
  .directive('ngPrivacyPolicy', downgradeComponent({ component: PrivacyPolicyComponent }) as angular.IDirectiveFactory)
