import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FlashService } from '../../../services/flash_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../../scripts/services/ajs-utils';
import angular from 'angular';

@Component({
  selector: 'ngForgotPassword',
  templateUrl: './forgot-password.component.html'
})

export class ForgotPasswordComponent implements OnInit {
  // Injected Services
  private flashService: any;

  // Properties
  forgotPasswordForm: FormGroup;
  failedSubmission: boolean = false;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
    private utils: Utils,
    private Restangular: Restangular,
    private fb: FormBuilder
  ) { 
    this.flashService = angular.copy(flashService)
    this.forgotPasswordForm = this.fb.group({
      username: ['', Validators.required],
    });
  }

  
  ngOnInit(): void {
  }

  submitForm = () => {
    if (this.forgotPasswordForm.invalid) {
      this.failedSubmission = true;
      return
    } else {
      const formObject = this.forgotPasswordForm.value;
      return this.requestNewPassword(formObject)
    }
  }

  requestNewPassword = (user: any) => {
    const forgot_password_flash = {
        strong: 'Success',
        name: `reset_password_${user.username}`,
        dismissable: false,
        class: 'alert-info',
        message: `Password reset instructions have been sent to ${user.username}`
    }

    return this.Restangular.all('user_services/forgot_password').post(user).then((success: any) => this.flashService.add_alert(forgot_password_flash)
        , (error: any) => {
            this.flashService.process_error(error)
            return this.utils.log(error, 'error', false)
        })
  }
}

angular
  .module('complyosClient')
  .directive('ngForgotPassword', downgradeComponent({ component: ForgotPasswordComponent }) as angular.IDirectiveFactory)
