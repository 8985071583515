import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormControlWarn } from 'app/_ng/extensions/AbstractControlWarning';
import { RequirementValidators } from '../../extensions/RequirementValidators';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';
import * as Restangular from '../../../vendor/restangular/restangular'
import _ from 'lodash';
import { map } from 'lodash';

@Component({
  selector: 'ng-requirement-information-editor',
  templateUrl: './requirement-information-editor.component.html',
  styleUrls: ['./requirement-information-editor.component.scss']
})
export class RequirementInformationEditorComponent implements OnInit {


  @Input() uiSetting: string = 'read';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()
  @Input() formSubmitAttempted

  //Properties
  form: FormGroup
  formReady:boolean = false;
  ValidationHelpers = ValidationHelpers
  current_selected_interval = { name: '' }

  constructor(
    public controlContainer: ControlContainer,
    @Inject('$scope') private $scope,
    private Restangular: Restangular,
  ) {

  }

  ngOnInit(): void {
    this.addFormFields();
  }

  // LISTS
  public intervals = []
  public annotation_states = [
    { value: 'annotation_required', display: 'Annotation Required' },
    { value: 'annotation_optional', display: 'Annotation Optional' },
    { value: 'annotation_disabled', display: 'Annotation Disabled' }
  ]

  addFormFields = () => {
    this.form = this.controlContainer.control as FormGroup
    this.form.addControl('name', new FormControl(this.requirement.name, Validators.required))
    this.form.addControl('description', new FormControl(this.requirement.description, (control) => RequirementValidators.requiredIfPublished(control, this.form)))
    this.form.addControl('purpose', new FormControl(this.requirement.purpose))
    this.form.addControl('annotation_state', new FormControl(this.requirement.annotation_state, Validators.required))
    this.form.addControl('annotation_help', new FormControl(this.requirement.annotation_help, (control) => this.annotationValidator(control, this.form)))

    this.formReady = true;
  }

  // VALIDATORS
  annotationValidator = (control: AbstractControl, form: FormGroup): ValidationErrors | null => {
    if (this.controlContainer && this.form.value.annotation_state == 'annotation_required') {
      return RequirementValidators.requiredIfPublished(control, form)
    }
    return null
  }
}
