import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../vendor/restangular/restangular'
import _ from 'lodash';

@Component({
  selector: 'ngControlNumbers',
  templateUrl: './control-numbers.component.html'
})
export class ControlNumbersComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$state') private $state,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    const customStates = {
      loading: {
        text: `Validating permissions for record ${this.$state.params.control_number}`
      },
      validated: {
        name: 'validated',
        icon: 'fa-check',
        text: 'CHECK!'
      }
    }

    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, customStates)
    this.loadingStateService.init()

    setTimeout(() => {
      this.getControlNumberRecord()
    }, 750)
  }

  getControlNumberRecord = () => {
    this.Restangular.one(
      `control_numbers/${this.$state.params.control_number}`
    ).get().then((success: any) => {
      this.loadingStateService.set_state('validated')
      setTimeout(() => {
        this.processControlNumberRecord(success.plain())
      }, 250)
    }, (error: any) => {
      if (error.status === 500) {
        this.addRecordNotFoundFlash(error)
      } else if (error.status === 401) {
        this.addPermissionFailureFlash(error)
      } else {
        this.addGenericError(error)
      }
      this.$rootScope.session.goTo('/home')
    })
  }

  processControlNumberRecord = (controlNumberRecord: any) => {
    switch (controlNumberRecord.controllable_type) {
      case 'Organization':
        this.openOrganizationModal(controlNumberRecord)
        break
      case 'Requirement':
        this.openRequirementModal(controlNumberRecord)
        break
      case 'Binder':
        this.loadBinderInformationPage(controlNumberRecord)
        break
    }
  }

  openOrganizationModal = (controlNumberRecord: any) => {
    let organization = controlNumberRecord.controllable

    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/organization_modal.html',
      controller: 'organizationModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            organization: organization,
            organizations: [], // required for some reason
            ui_setting: 'read'
          }
        }
      }
    })

    modalInstance.result.then((result: any) => {
      // happy close
    }, (reason: any) => {
      // angry close
    }).finally(() => {
      this.$rootScope.session.goTo('/home')
    })
  }

  openRequirementModal = (controlNumberRecord: any) => {
    let requirement = controlNumberRecord.controllable
    console.log(requirement)
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/requirement_modal.html',
      controller: 'requirementModalController',
      size: 'lg',
      resolve: {
        modalOptions () {
          return {
            object: requirement,
            ui_setting: 'read'
          }
        }
      }
    })

    return modalInstance.result.then((result: any) => {
      // happy close
    }, (reason: any) => {
      // angry close
    }).finally(() => {
      this.$rootScope.session.goTo('/home')
    });
  }

  loadBinderInformationPage = (controlNumberRecord: any) => {
    // first get the organization
    this.Restangular.one(
      `organizations/${controlNumberRecord.controllable.organization_id}`
    ).get().then((success: any) => {
      // then set the organization
      this.$rootScope.session.setOrganization(success.plain())
      // finally redirect user
      this.$rootScope.session.goTo(`requirements/binder/${controlNumberRecord.controllable_id}`)
    }, (error: any) => {
      this.supportingRecordError(error)
    })
  }

  addRecordNotFoundFlash = (error: any) => {
    console.log(error)
    this.$rootScope.$broadcast(
      'add_global_alert', {
        name: 'recordNotFoundAlert',
        dismissable: true,
        class: 'alert-warning',
        strong: null,
        message: 'Record not found'
      }
    )
  }

  addPermissionFailureFlash = (error: any) => {
    console.log(error)
    this.$rootScope.$broadcast(
      'add_global_alert', {
        name: 'binderOrganizationAlert',
        dismissable: true,
        class: 'alert-warning',
        strong: null,
        message: 'You do not have permission to view this record'
      }
    )
  }

  addGenericError = (error: any) => {
    console.log(error)
    this.$rootScope.$broadcast(
      'add_global_alert', {
        name: 'controlNumberGenericAlert',
        dismissable: true,
        class: 'alert-warning',
        strong: null,
        message: "Whoops, can't do that :("
      }
    )
  }

  supportingRecordError = (error: any) => {
    console.log(error)
    this.$rootScope.$broadcast(
      'add_global_alert', {
        name: 'supportingRecordError',
        dismissable: true,
        class: 'alert-warning',
        strong: null,
        message: 'Error loading supporting records'
      }
    )
  }
}

angular
  .module('complyosClient')
  .directive('ngControlNumbers', downgradeComponent({ component: ControlNumbersComponent }) as angular.IDirectiveFactory)
