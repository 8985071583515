import * as angular from 'angular';
import { Injectable, Inject } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
import * as Restangular from '../../vendor/restangular/restangular'

@Injectable()
export class AuthenticationService {
  private session: any;

  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('ENV') private ENV,
    @Inject('$q') private $q,
    private Restangular: Restangular,
  ) {
  }

  validate_token = (token: any) => {
    // *sessionBreadCrumb* console.log(`authentication_service > validate_token(${token})`)
    const deferred = this.$q.defer()
    let user_services_params = { token: token }
    this.Restangular.all(
      `user_services/token_validate`
    ).post(user_services_params).then(function (success: any) {
      // *sessionBreadCrumb* console.log(`authentication_service > validate_token() :: SUCCESS`)
      return deferred.resolve({
        success: true,
        token: success.token
      })
    }, function (error: any) {
      // *sessionBreadCrumb* console.log(`authentication_service > validate_token() :: ERROR`)
      deferred.reject({
        success: false,
        error: error
      })
    })

    return deferred.promise
  }

  /* USERNAME & PASSWORD */

  // ### THIS ALSO NEEDS TO BROADCAST ###

  submit_user_credentials = (user_credentials: any) => {
    return this.Restangular.all(
      'user_services/login'

    ).post(user_credentials).then((success: any) => {
      // *sessionBreadCrumb* console.log('authentication_service > submit_user_credentials() :: BROADCASTING TOKEN')
      this.$rootScope.$broadcast('sessionToken', success.token)
      return success
    }, (error: any) => {
      // login controller handles errors
      this.$rootScope.$broadcast('userIsNotAuthenticated', error)
      return error
    });
  }

  /* USER */

  retrieve_user = (token: any) => {
    // *sessionBreadCrumb* console.log(`authentication_service > retrieve_user('${token}')`)
    const token_object = {
      filter: {
        token: token
      }
    }
    return this.Restangular.all(
      'users/find'
    ).post(token_object).then((response: any) => {
      this.$rootScope.session.setUser(response)
      return response
    }, (error: any) => error);
  }

  /* SINGLE SIGN ON */

  sso_url = (sso_idp_name: any) => {
    return `${this.ENV.COMPLYOS_API_URL}/user_services/sso/initiate/${sso_idp_name}`
  }

  initiate_sso_login = (sso_idp_name: any) => {
    this.$rootScope.session.removeTokenCookie()
    return window.location.href = this.sso_url(sso_idp_name)
  }

  /* LOGOUT */

  logout = () => {
    this.Restangular.all('user_services/logout').post().then(() => {
      this.$rootScope.session.clearToken()
      this.$rootScope.session.removeTokenCookie()
    })
  }
}

angular
  .module("complyosClient")
  .factory("authenticationService", downgradeInjectable(AuthenticationService));
