import { Component, Directive, ElementRef, Injector, Input, OnInit } from "@angular/core"
import { UpgradeComponent } from "@angular/upgrade/static"

@Directive({ selector: 'ajs-organization-needed-prompt' })
export class ajsOrganizationNeededPromptWrapper extends UpgradeComponent implements OnInit {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() interactibleOrgsOnly: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('organizationNeededPrompt', elementRef, injector);
  }
}