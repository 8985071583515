/* eslint-disable
    camelcase,
    handle-callback-err,
    no-return-assign,
    no-undef,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

'use strict'

import * as _ from 'lodash';
import * as angular from "angular"

var complyosClient = angular.module('complyosClient')

complyosClient.service('complyosServices', ['$q', '$rootScope', '$timeout', 'fortyCore', 'Restangular', function (
  $q: any,
  $rootScope: any,
  $timeout: any,
  fortyCore: any,
  Restangular: any
) {
  return {

    /* SNAPSHOT */

    snapshot: {

      get_status_color (status_string: any) {
        switch (status_string) {
          case 'empty': return '#95A5A6' // Default
          case 'archived': return '#2C3E50' // Primary
          case 'pending': return '#3498DB' // Info
          case 'attested_as_complete': return '#18BC9C' // Success
          case 'partially_complete': return '#8A2BE2'
          case 'closed_not_complete': return '#7b0404'
          case 'requires_resubmission': return '#ECC8EC'
          case 'open': return '#b4bcc2' //
          case 'new': return '#F7EB00' //
          case 'first': return '#F39C12' // Warning
          case 'second': return '#ED7328' //
          case 'third': return '#E74C3C' // Danger
          case 'past_due': return '#E02853'
        }
      }, // formerly 'missed'

      get_status_icon (status_string: any) {
        switch (status_string) {
          case 'empty': return 'fa-circle-o'
          case 'archived': return 'fa-minus-circle'
          case 'pending': return 'fa-dot-circle-o'
          case 'attested_as_complete': return 'fa-check-circle-o'
          case 'partially_complete': return 'fa-ban'
          case 'closed_not_complete': return 'fa-times-circle'
          case 'requires_resubmission': return 'fa-circle-o'
          case 'open': return 'fa-circle-o'
          case 'new': return 'fa-circle-o'
          case 'first': return 'fa-circle-o'
          case 'second': return 'fa-circle-o'
          case 'third': return 'fa-circle-o'
          case 'past_due': return 'fa-times-circle-o'
        }
      }, // formerly 'missed'

      get_status_text (status_string: any) {
        switch (status_string) {
          case 'new': return '0'
          case 'first': return '1'
          case 'second': return '2'
          case 'third': return '3'
        }
      }
    },

    /* SAVE RELAY */

    save_relay: {

      /* START */

      start (s: any, o: any, f: any, a: any, c: any, r: any) {
        this.modal_scope = s
        this.base_object = o
        this.base_object_form = f
        this.base_object_api_route = a
        this.promise_chain_array = c
        this.finished_function = r
        this.started = new Date()
        this.debug = false

        return this.log_start().then(() => {
          return this.start_promise_chain(this.promise_chain_array).then((success: any) => {
            this.log_finished()
            this.finished_function(this.base_object)
          }, (error: any) => {
            this.log_error(error)
          }).finally(() => {
            this.reset_save_relay()
          });
        });
      },

      /* PROMISE PROCESSING */

      start_promise_chain (promise_chain_array: any) {
        const root_defer = $q.defer()
        // build a promise_chain using extend_promise_chain
        // the last link in the promise_chain will be root_defer.resolve()
        const promise_chain = this.extend_promise_chain(promise_chain_array, root_defer)
        // run the chain, this will execute all the promises in order,
        // and if there are no failures root_defer will be resolved
        promise_chain()
        return root_defer.promise
      },

      extend_promise_chain (promise_array: any, root_defer: any) {
        const current_promise :any = _.first(promise_array)
        const remaining_promises = promise_array.slice(1)

        // if there are multiple promises we need to recursively chain them
        if (promise_array.length > 1) {
          if (_.isArray(current_promise)) {
            return () => {
              return this.parallel_promises(current_promise).then(
                this.extend_promise_chain(remaining_promises, root_defer),
                (error: any) => root_defer.reject(error));
            };
          } else {
            if (
              typeof current_promise === 'string' &&
              current_promise.includes('promise_alias')
            ) {
              return () => {
                return this[current_promise]().then(
                  this.extend_promise_chain(remaining_promises, root_defer),
                  (error: any) => root_defer.reject(error));
              };
            } else {
              return () => {
                return current_promise().then(
                  this.extend_promise_chain(remaining_promises, root_defer),
                  (error: any) => root_defer.reject(error));
              };
            }
          }

        // on the last promise in the chain, we need to resolve the root_defer
        } else {
          return () => {
            return this[current_promise]().then(
              () => root_defer.resolve())
          }
        }
      },

      parallel_promises (promises: any) {
        const promise_array: any = []
        _.each(promises, (promise: any) => {
          promise_array.push(this[promise]())
        })
        return $q.all(promise_array)
      },
      // returns promise, that returns array of promise results

      /* HELPER METHODS */
      // TODO remove/comment/leave these are not needed, only used for testing

      fail_log () {
        const defer = $q.defer()
        setTimeout(() => {
          this.ts_log('fail_log: FAILING')
          defer.reject('FAIL REASON')
        }
        , 500)
        return defer.promise
      },

      delay_log (text: any, ms: any) {
        const defer = $q.defer()
        this.ts_log(`delay_log: triggerd ${text} - ${ms}ms`)

        setTimeout(() => {
          this.ts_log(`delay_log: finished ${text} - ${ms}ms`)
          defer.resolve(ms)
        }
        , ms)

        return defer.promise
      },

      log_start () {
        return this.async_log('triggerd all the things!!!')
      },

      log_error () {
        return this.async_log('failed to finish the things')
      },

      log_finished () {
        return this.async_log('finished all the things!!!')
      },

      async_log (text: any) {
        const defer = $q.defer()
        this.ts_log(`${text}`)
        defer.resolve()
        return defer.promise
      },

      time_elapsed () {
        const finished = new Date().getTime()
        const ms = finished - this.started
        return (ms / 1000).toFixed(3)
      },

      ts_log (message: any) {
        if (this.debug) {
          if (typeof message === 'string') {
            return console.log(`${this.time_elapsed()}: ${message}`)
          } else {
            console.log(`${this.time_elapsed()}:`)
            return console.log(message)
          }
        }
      },

      shallow_update (old_object: any, new_object: any) {
        return _.each(new_object.plain(), function (value: any, key: any, object: any) {
          // console.log "==============================="
          // console.log "Key:"
          // console.log key
          // console.log "Value:"
          // console.log value

          // copy all the Primitive values
          if (
            _.isString(object[key]) ||
            _.isNumber(object[key]) ||
            _.isBoolean(object[key])
          ) {
            // console.log "Copying Primitive value"
            old_object[key] = object[key]
          }
          // console.log "old_object[#{key}] is now #{object[key]}"

          // copy any managed object id's
          if (
            _.isObject(object[key]) &&
            object[key]['id']
          ) {
            if (old_object[key] === undefined) {
              old_object[key] = {}
            }
            // console.log "Copying managed object id"
            return old_object[key]['id'] = object[key]['id']
          }
        });
      },
      // console.log "old_object[#{key}]['id'] is now #{object[key]['id']}"

      /* TRIGGERS / WATCHERS */

      /* ALIASES */

      base_object_save_promise_alias () {
        return this.create_or_update_base_object()
      },

      entry_complete_manager_promise_alias () {
        return this.save_standard_manager('entry_complete_manager', this.base_object.complete)
      },

      attachment_manager_promise_alias () {
        return this.save_standard_manager('attachment_manager', this.base_object.attachments)
      },

      note_manager_promise_alias () {
        return this.save_standard_manager('note_manager', this.base_object.notes)
      },

      reference_manager_promise_alias () {
        return this.save_standard_manager('reference_manager', this.base_object.references)
      },

      tagging_manager_promise_alias () {
        return this.save_standard_manager('tagging_manager', this.base_object.taggings)
      },

      profile_manager_promise_alias () {
        return this.save_standard_manager('profile_manager', this.base_object.profile)
      },

      user_organization_manager_promise_alias () {
        return this.save_standard_manager('user_organization_manager', this.base_object.organizations)
      },

      user_lob_manager_promise_alias() {
        return this.save_standard_manager('user_lob_manager', this.base_object.user_lobs)
      },

      user_role_manager_promise_alias () {
        return this.save_standard_manager('user_role_manager', this.base_object.user_role)
      },

      state_manager_promise_alias () {
        return this.save_standard_manager('state_manager', this.base_object.state)
      },

      version_control_completor_promise_alias() {
        return this.save_standard_manager('version_control_completor', this.base_object.state)
      },

      /* BASE OBJECT */

      create_or_update_base_object () {
        // @ts_log "triggerd base_object_save"

        // set a promise, this allows us to chain these functions all day long
        const deferred = $q.defer()

        if (this.base_object.id === undefined) {
          // base_object_create
          Restangular.all(
            this.base_object_api_route

          ).post(this.base_object).then((success: any) => {
            this.shallow_update(this.base_object, success)
            return deferred.resolve(success)
          }
          // @ts_log "finished base_object_save"

          , (error: any) => {
            this.modal_scope.flashService.process_error(error)
            // @ts_log "@reset_save_relay"
            fortyCore.processingStateService.set(
              this.base_object_form,
              fortyCore.processingStateService.processing_states.RESTING
            )
            return deferred.reject
            // @ts_log "failed base_object_save"
          }).finally(function () {
          })
        } else {
          // base_object_save
          Restangular.one(
            this.base_object_api_route,
            this.base_object.id

          ).patch(this.base_object).then((success: any) => {
            this.shallow_update(this.base_object, success)
            return deferred.resolve(success)
          }
          // @ts_log "finished base_object_save"

          , (error: any) => {
            this.modal_scope.flashService.process_error(error)
            // @ts_log "@reset_save_relay"
            fortyCore.processingStateService.set(
              this.base_object_form,
              fortyCore.processingStateService.processing_states.RESTING
            )
            return deferred.reject
            // @ts_log "failed base_object_save"
          }).finally(function () {
          })
        }

        // return the promise
        return deferred.promise
      },

      save_standard_manager (manager_name: any, managed_object: any) {
        // @ts_log "triggerd #{manager_name}"
        // set a promise, this allows us to chain these functions all day long
        const deferred = $q.defer()

        var handler = this.modal_scope.$on(`${manager_name}_finished`, (event: any, result: any) => {
          // @ts_log "recieved #{manager_name}_finished"
          handler // immmedeatly deregister the event handler

          if (!result.fault) {
            _.merge(managed_object, result.object)
            return deferred.resolve(result.object)
            // @ts_log "finished #{manager_name}"
          } else {
            this.modal_scope.flashService.process_error(result.fault)
            return deferred.reject(result.fault)
          }
        })
        // @ts_log "failed #{manager_name}"

        // kindly request that the event start
        this.modal_scope.$broadcast(`${manager_name}_start`, this.base_object)
        // @ts_log "broadcast #{manager_name}_start"

        // return the promise
        return deferred.promise
      },

      /* END */

      reset_save_relay () {
        // @ts_log "@reset_save_relay"
        fortyCore.processingStateService.set(
          this.base_object_form,
          fortyCore.processingStateService.processing_states.RESTING
        )
        // Reset all the things
        this.modal_scope = undefined
        this.base_object = undefined
        this.base_object_form = undefined
        this.base_object_api_route = undefined
        this.promise_chain_array = undefined
        this.finished_function = undefined
        this.fault_counter = undefined
        return this.pending_requests = undefined
      }
    },

    /* REQUIREMENT FILTER */

    requirement_filter: {

      filter (requirement: any) {
        if (!$rootScope.tags || $rootScope.tags.length === 0) {
          return this.unpublished_match(requirement) ||
          this.published_match(requirement) ||
          this.name_match(requirement) ||
          this.interval_match(requirement) ||
          this.reference_match(requirement)
        } else {
          return this.tags_match(requirement) &&
          (
            this.unpublished_match(requirement) ||
            this.published_match(requirement) ||
            this.name_match(requirement) ||
            this.interval_match(requirement) ||
            this.reference_match(requirement)
          )
        }
      },

      name_match (requirement: any) {
        const search_text = $rootScope.filter_query.query.toLowerCase()
        if ((requirement.name.toLowerCase()).indexOf(search_text) >= 0) {
          return true
        } else {
          return false
        }
      },

      interval_match (requirement: any) {
        const search_text = $rootScope.filter_query.query.toLowerCase()
        if (requirement.interval && (requirement.interval.name.toLowerCase()).indexOf(search_text) >= 0) {
          return true
        }
      },

      // check if any of the objects references matches string
      // returns true or false
      reference_match (requirement: any) {
        let match = false
        const search_text = $rootScope.filter_query.query.toLowerCase()

        _.each(requirement.reference_objects, function (reference: any) {
          const reference_string = reference.value.toLowerCase()
          if ((reference_string).indexOf(search_text) >= 0) {
            match = true
            return match
          }
        })
        return match
      },

      tags_match (requirement: any) {
        let match = false
        _.each(requirement.taggings, (tagging: any) => {
          return _.each($rootScope.tags, function (tag: any) {
            if (tag.id === tagging.tag_id) {
              match = true
              return match
            }
          });
        })
        return match
      },

      published_match (requirement: any) {
        const search_text = $rootScope.filter_query.query.toLowerCase()
        if ((search_text.toLowerCase() === 'published') && requirement.published) {
          return true
        } else {
          return false
        }
      },  

      unpublished_match (requirement: any) {
        const search_text = $rootScope.filter_query.query.toLowerCase()
        if (!requirement.taggings) {
        }
        if ((search_text.toLowerCase() === 'unpublished') && !requirement.published) {
          return true
        } else {
          return false
        }
      }
    }

  };
}])
