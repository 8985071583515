/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars
*/
'use strict'


angular.module('complyosClient').directive('organizationRow', [
  'fortyCore', 'helpService',
  (fortyCore, helpService) => ({
    templateUrl: 'views/directives/organization_row.html',
    restrict: 'E',

    scope: {
      stageOrganizationFn: '&',
      getOrganizationIconFn: '&',
      organizationFilterFn: '&',
      allowCreate: '<',
      allowDestroy: '<',
      organization: '='
    },

    link($scope: any, $element: any, $attributes: any) {
      $scope = this.registerFunctions($scope)


      // console.log($scope.organization)

      return $scope.fortyCore = fortyCore
    },

    registerFunctions($scope: any) {


      return $scope
    }
  })
])
