import angular from 'angular';
import { Component, Inject, OnInit } from '@angular/core';
import { FlashService } from '../../services/flash_service.service';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import { DateService } from '../../services/date_service.service';
import { Location } from '@angular/common'
import _ from 'lodash';

@Component({
  selector: 'ngValidationModeComments',
  templateUrl: './validation-mode-comments.component.html',
})

export class ValidationModeCommentsComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;
  private dateService: any;

  // Properties
  validationCommentCount: number = 0
  isActiveOrg: boolean = false
  validationModeComments: Array<any> = [];
  outstandingPromises: Array<any> = [];
  showGlobalFilter: boolean = false;
  addressThisCommentTooltip: string = 'Address this ValidationMode® comment';
  mailToLink: string = 'mailto:CORP.TechnologySolutions@HCAHealthcare.com';

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('$q') private $q,
    private helpService: helpService,
    private Restangular: Restangular,
    private DateService: DateService,
    private location: Location
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService
    this.loadingStateService = angular.copy(loadingStateService)
    this.dateService = angular.copy(DateService)
    this.session = this.$rootScope.session
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.isActiveOrg = this.session.activeOrganizationPresent()
    if (this.location.path().includes('validation_mode')) {
      this.showGlobalFilter = true
    }

    this.watchPillFilter()
    this.watchForNewComments()
    this.watchForUpdateComments()
    this.$scope.$on('activeOrgIdUpdated', () => {
      this.isActiveOrg = this.session.activeOrganizationPresent()
    })
  }

  isOrgPillSelected = (pills: any) => {
    let activeOrganizationPresent = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
    return activeOrganizationPresent
  }

  watchPillFilter = () => {
    this.$scope.$on('filterChange-dashboard', (e: any, pills: any) => {
      this.abortAllPromises(this.outstandingPromises)
      // if on survey mode page, don't make the api call when there are no org pills
      // it takes forever, causes timeouts and we don't show this component unless an org pill has been selected anyway
      if(this.showGlobalFilter) {
        setTimeout(() => this.getValidationModeComments(pills), 50)
      } else if(!this.showGlobalFilter && this.isOrgPillSelected(pills)) {
        setTimeout(() => this.getValidationModeComments(pills), 50)
      }
    })
  }

  watchForNewComments = () => {
    this.$rootScope.$on('validationModeCommentAdded', (event: any, data: any) => {
      if (this.loadingStateService.loading_state.name === 'nocontent') {
        this.loadingStateService.set_state('content')
      }
      this.validationModeComments.push(data)
    })
  }

  watchForUpdateComments = () => {
    this.$rootScope.$on('validationModeCommentUpdated', (event: any, data: any, flag: boolean) => {
      this.validationModeComments.forEach((e: any) => {
        if(e.entry && e.entry.binder && e.entry.binder.id === data.id){
          e.entry.binder.has_citation = flag
        }
      });
    })
  }

  getValidationModeComments = (pills: any) => {
    this.loadingStateService.set_state('loading')
    let queryParams = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)
    
    this.Restangular.one(`binders/validation_mode`).withHttpConfig({ timeout: abort.promise }).get(queryParams).then((success: any) => {
        this.validationModeComments = success.plain().items
        this.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        this.validationCommentCount = success.pagination_info.totalCount
        this.loadingStateService.process_success(success.items)
      }
      , (error: any) => {
        this.loadingStateService.process_error(error)
      }
    )
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  newOrganizationCommentToolTip = () => {
    if(this.isActiveOrg) {
      return `Create a new ValidationMode® comment for ${this.session.getOrganization().name}.`
    } else {
      return `There can only be one organization selected in the filter to add a new validation mode comment.`
    }
  }

  openValidationModeCommentAcknowlegementModal = (validationModeCommentObject: any) => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/notes/validation_mode_comment_response.html',
      controller: 'validationModeCommentResponseModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            validation_mode_comment_object: angular.copy(validationModeCommentObject)
          }
        }
      }
    })

    modalInstance.result.then((returnObject: any) => {
      validationModeCommentObject.responses.push(returnObject.acknowledgementObject)
      if (validationModeCommentObject.noteable_type === 'Entry') {
        validationModeCommentObject.entry.complete_status = returnObject.markEntryAsRequiresResubmission
      }
    })
  }

  openEntryModal = (entryObject: any) => {
    let modalInstance
    let ui_setting = this.$scope.session.userCan('entries_update') ? 'update' : 'read'
    modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/entry_modal.html',
      controller: 'entryModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            
            object: angular.copy(entryObject),
            ui_setting
          }
        }
      }
    })

    modalInstance.result.then((returnObject: any) => {
      Object.assign(entryObject, returnObject)
    })
  }

  userRoleIsBLacklisted = () => {
    if (
      (this.$scope.session.getUser().user_role.role.title === 'LOB Admin') ||
      (this.$scope.session.getUser().user_role.role.title === 'LOB Read-Only') ||
      (this.$scope.session.getUser().user_role.role.title === 'Organization Read-Only')
    ) {
      return true
    }
  }

  openOrganizationNoteModal = () => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/notes/organization_validation_mode_comment.html',
      controller: 'organizationValidationModeCommentModalController',
      size: 'lg',
      resolve: {
        modalOptions () {}
      }
    })

    return modalInstance.result.then((success: any) => {
        this.validationModeComments.push(success)
      }
    );
  }

  trackByIndex = (index: number, item: any): string => item.index;
}

angular
  .module('complyosClient')
  .directive('ngValidationModeComments', downgradeComponent({ component: ValidationModeCommentsComponent }) as angular.IDirectiveFactory)
