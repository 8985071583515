/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('complyosOperatorsController', ['$rootScope', '$scope', 'confirmationModalFactory', 'filterService', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', 'arrayFunctions', function (
  $rootScope: any,
  $scope: any,
  confirmationModalFactory: any,
  filterService: any,
  flashService: any,
  fortyCore: any,
  loadingStateService: any,
  objectManager: any,
  Restangular: any,
  utils: any,
  arrayFunctions: any
) {
  const init = function () {
    /* LOADING FEEDBACK */
    $scope.processingStateService = fortyCore.processingStateService
    $scope.flashService = angular.copy(flashService)
    
    $scope.fortyCore = angular.copy(fortyCore)
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.loadingStateService.init()
    $scope.previousPills = []
    const custom_states = {
      nocontent: {
        icon: 'fa-exclamation-triangle',
        text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
      }
    }
    $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
    watchEntityFilter()
  }

  var getOperators = function (pills: any) {
    let query_params = {
      param_data: {}
    }

    $scope.loadingStateService.set_state('loading')
    query_params.param_data = $rootScope.storeService.stringifyTagParams(
      $rootScope.storeService.pillsToObject(pills)
    )
    Restangular.one('operators').get(query_params).then(
      (success: any) => {
        $scope.loadingStateService.process_success(success.items)
        $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        $scope.operators = success.items
        $scope.operatorsCount = success.pagination_info.totalCount
      }
      , (error: any) => {
        $scope.loadingStateService.process_error(error)
        utils.log(error)
      }
    )
  }

  var watchEntityFilter = () => {
    var refresh = function (e: any, pills: any) {
      let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted']) || $scope.previousPills === null
      $scope.previousPills = pills
      if (widgetShouldRefresh) {
        getOperators(pills)
      }
    }
    $scope.$on('filterChange-dashboard', refresh)
  }

  return init()
}])
