import { Component, Directive, ElementRef, Injector, Input } from "@angular/core"
import { UpgradeComponent } from "@angular/upgrade/static"

@Directive({ selector: 'ajs-collapse-panel' })
export class ajsCollapsePanelWrapper extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() collapsed?: any = false;
  @Input() header: any;
  @Input() icon?: any;
  @Input() preventCollapse?: any = false;
  @Input() panelStyle?: any = "";
  @Input() panelHeadingStyle?: any = "";
  @Input() panelHeadingTextStyle?: any = "";

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('collapsePanel', elementRef, injector);
  }
}