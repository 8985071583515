import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import { fortyCore } from '../../../../scripts/services/fortyau_services';

@Component({
  selector: 'ngTrend',
  templateUrl: './trend.component.html'
})
export class TrendComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  // Properties
  private previousPills: any = null;
  private trendData: any;
  private deferredRefresh: any;
  private outstandingPromises: Array<any> = [];
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('fortyCore') private fortyCore: any,  
    private helpService: helpService,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.fortyCore = fortyCore
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.watchEntityFilter()
  }

  getAnalyticData = (pills: any) => {
    this.loadingStateService.set_state('loading')

    let query_params = {
      model: 'Binder',
      method: 'trend',
      param_data: {}
    }

    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)
    this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then((response: any) => {
      this.trendData = response.plain().data
      if (this.trendData && this.trendData.trend && this.trendData.trend.label_percent != null && !isNaN(this.trendData.trend.label_percent)) {
        this.loadingStateService.process_success(response)
      } else {
        this.loadingStateService.set_state('error')
      }
    }, (error: any) => {
      this.loadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
    })
  }

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getAnalyticData(pills), 50)
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted', 'pagination_params']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        if (resultsCount <= 30000) {
          this.executeRefresh(pills)
        } else {
          this.loadingStateService.set_state('query_too_large_warning')
          this.deferredRefresh = this.executeRefresh.bind(null, pills)
        }
      }
    }
    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }
}

angular
  .module('complyosClient')
  .directive('ngTrend', downgradeComponent({ component: TrendComponent }) as angular.IDirectiveFactory)
