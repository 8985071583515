import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { AuthenticationService } from '../../../services/authentication_service.service';
import _ from 'lodash';

@Component({
  selector: 'ngSsoLogin',
  templateUrl: './sso-login.component.html'
})
export class SsoLoginComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  private authenticationService: any;

  // Properties
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(AuthenticationService) authenticationService: AuthenticationService,
    private Restangular: Restangular
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.authenticationService = authenticationService

  }

  ngOnInit(): void {
    const custom_states = {
      sso_init: {
        name: 'sso_init',
        icon: 'fa-cog fa-spin',
        text: 'Redirecting for SSO authentication'
      },
      sso_error_idpNotFound: {
        name: 'sso_error_idpNotFound',
        icon: 'fa-exclamation-triangle',
        text: 'SSO identity provider not found'
      },
      sso_error_SSOFailed: {
        name: 'sso_error_SSOFailed',
        icon: 'fa-exclamation-triangle',
        text: 'SSO failed',
        try_again: true
      },
      sso_error_UserNotProvisioned: {
        name: 'sso_error_UserNotProvisioned',
        icon: 'fa-exclamation-triangle',
        text: 'User not provisioned'
      },
      sso_error_TokenNotSet: {
        name: 'sso_error_TokenNotSet',
        icon: 'fa-exclamation-triangle',
        text: 'Token not set',
        try_again: true
      },
      sso_error_SessionNotConfigured: {
        name: 'sso_error_SessionNotConfigured',
        icon: 'fa-exclamation-triangle',
        text: 'Session not configured',
        try_again: true
      },
      sso_error_UserNotEnabled: {
        name: 'sso_error_UserNotEnabled',
        icon: 'fa-exclamation-triangle',
        text: 'User not enabled'
      },
      sso_success: {
        name: 'sso_success',
        icon: 'fa-thumbs-up',
        text: 'User authenticated'
      }
    }

    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.loadingStateService.set_state('sso_init')

    if (this.$rootScope.session.paramExists('sso', 'success')) {
      return this.process_sso_success()
    } else if (this.$rootScope.session.paramExists('sso', 'error')) {
      return this.process_sso_error()
    } else {
      return this.start_sso_process()
    }
  }

  // Client loads
  // SSO page loads init runs, params are empty, start_sso_process() is called
  // start_sso_process calls initiate_sso_login method in authenticationService
  // initiate_sso_login redirects browser to API /initiate endpoint
  // API creates sso request
  // API redirects broweser to SSO request url
  // SSO provider validates username and password
  // SSO provider redirects browser to API /consume endpoint
  // API processes response, and sets token on user
  // API exposes token via shared cookie
  // API redirects to client SSO page
  // Client loads
  // SSO page detects a sso success param as present,
  // Client gets token from shared cookie
  // session is restored

  /* FUNCTIONS */
  start_sso_process = () => {
    if (this.$rootScope.session.sso_domained()) {
      const sso_idp = this.$rootScope.session.get_sso_idp()
      return this.authenticationService.initiate_sso_login(sso_idp.name)
    } else {
      return this.loadingStateService.set_state('sso_error_idpNotFound')
    }
  }

  // ### THIS FAILS TO BROADCAST ###

  process_sso_success = () => {
    // Check to see if the token shared via cookie is present
    this.Restangular.one('user_services/cookie_validate').get().then((response)=> {
      if (response.token) {
        let token = response.token
  
        // // BROADCAST THE TOKEN
        // console.log('sso > process_sso_success() :: ATTEMPTING BROADCASTING TOKEN')
        // $rootScope.$broadcast('sessionToken', token)
  
        // BYPASS THE BROADCAST
        this.$rootScope.session.bypassBroadcast(token)
  
        if (this.$rootScope.session.data.token === token) {
          this.loadingStateService.set_state('sso_success')
  
        // TRY TO RECOVER ON FAILURE
        } else {
          console.log('sso > process_sso_success() :: BROADCAST BYPASS FAILED')
          this.loadingStateService.set_state('sso_error_SessionNotConfigured')
          console.log(this.loadingStateService.loading_state.name)
          this.$rootScope.session.removeTokenCookie()
        }
      } else {
        this.loadingStateService.set_state('sso_error_TokenNotSet')
      }

      this.$rootScope.session.deleteParam('sso', 'success')
    })
  }

  process_sso_error = () => {
    const error = this.$rootScope.session.getParam('sso', 'error')
    // https://docs.oracle.com/cd/E19681-01/821-1818/gjwal/index.html
    this.loadingStateService.set_state(`sso_error_${error}`)
    if (error === 'UserNotProvisioned') {
      setTimeout(() => {
        return this.$rootScope.session.goTo('/marketing')
      }, 1500)
    }
    this.$rootScope.session.deleteParam('sso', 'error')
  }
}

angular
  .module('complyosClient')
  .directive('ngSsoLogin', downgradeComponent({ component: SsoLoginComponent }) as angular.IDirectiveFactory)
