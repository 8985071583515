import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeLayoutComponent } from './components/layouts/home-layout/home-layout.component';
// import { BillsPageComponent } from './components/pages/fact-queries-page/fact-queries-page.component';
// import { BillPageComponent } from './components/pages/bill-page/bill-page.component';
// import { AccountPageComponent } from './components/pages/account-page/account-page.component';
// import { SdpPageComponent } from './components/pages/sdp-page/sdp-page.component';
// import { LoginLayoutComponent } from './components/layouts/login-layout/login-layout.component';
// import { LoginPageComponent } from './components/pages/login-page/login-page.component';
// import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
// import { SettingsPageComponent } from './components/pages/settings-page/settings-page.component';
// import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
// import { OptionsPageComponent } from './components/pages/options-page/options-page.component';
// import { ArkPageComponent } from './components/pages/ark-page/ark-page.component';

const routes: Routes = [
	// { 
	// 	path: '',
	// 	component: HomeLayoutComponent,
	// 	canActivate: [AuthGuard],
	// 	children: [
	// 		{ path: 'bills', component: BillsPageComponent },
	// 		{ path: 'bill/:id', component: BillPageComponent },
	// 		{ path: 'account', component: AccountPageComponent},
	// 		{ path: 'sdp', component: SdpPageComponent },
	// 		// { path: 'ark', component: ArkPageComponent },
	// 		{ path: 'options', component: OptionsPageComponent },
	// 		{ path: '', redirectTo: '/ark', pathMatch: 'full' },
	// 		{
	// 			path: 'admin',
	// 			canActivate: [AdminGuard],
	// 			children: [
	// 				{ path: 'settings', component: SettingsPageComponent },
	// 				{ path: '', redirectTo: '/admin/settings', pathMatch: 'full' },
	// 			]
	// 		}
	// 	]
	// },
	// {
	// 	path: '',
	// 	component: LoginLayoutComponent,
	// 	children: [
	// 		{ path: 'ark', component: ArkPageComponent },
	// 		{ path: 'login', component: LoginPageComponent },
	// 		{ path: 'register', component: RegisterPageComponent },
	// 		{ path: 'PageNotFound', component: PageNotFoundComponent },
	// 		{ path: '**', redirectTo: '/PageNotFound' }
	// 	]
	// }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
