/* eslint-disable
    camelcase,
    no-undef,
*/

angular.module('complyosClient').service('queueProcessorService', ['$rootScope', function (
  $rootScope: any
) {
  var service :any = {} // the service object we'll return

  var queues = {}

  // ---------------------------------- [START] PRIVATE FUNCTION ----------------------------------
  function getOrCreateQueue (queueName: any, perItemAction: any, completeCallback: any, errorCallback: any) {
    if (!queues.hasOwnProperty(queueName)) {
      queues[queueName] = buildQueueObject(queueName, perItemAction, completeCallback, errorCallback)
    }
    return queues[queueName]
  }

  function getQueue (queueName: any) {
    return queues[queueName]
  }

  function buildQueueObject (queueName: any, perItemAction: any, completeCallback: any, errorCallback: any) {
    let queue = {
      queueName: queueName,
      isRunning: false,
      items: [], // we'll add these in a standard step
      perItemAction: perItemAction,
      completeCallback: completeCallback,
      errorCallback: errorCallback
    }

    return queue
  }

  function processNext (queueName: any) {
    try {
      let queue = getQueue(queueName)
      queue.isRunning = true
      if (queue.items.length > 0) {
        let nextItem = queue.items.shift()

        let promise = queue.perItemAction(nextItem)
        promise
          .then(() => processNext(queueName), null, () => completeQueue(queueName))
        promise.catch(() => {
          queue.errorCallback()
          completeQueue(queueName)
        })
      } else {
        completeQueue(queueName)
      }
    } catch (error) {
      completeQueue(queueName)
    }
  }

  function completeQueue (queueName: any) {
    let queue = getQueue(queueName)
    queue.isRunning = false
    try {
      queue.completeCallback()
    } catch (error) {
      console.log(error)
    }
    delete queues[queueName]
  }
  // ---------------------------------- [ END ] PRIVATE FUNCTIONS ----------------------------------

  // ---------------------------------- [START] PUBLIC FUNCTION ----------------------------------
  service.enqueue = (queueName: any, items: any, perItemAction: any, completeCallback: any, errorCallback: any) => {
    let queue = getOrCreateQueue(queueName, perItemAction, completeCallback, errorCallback)

    queue.items = queue.items.concat(items)

    if (!queue.isRunning) {
      processNext(queue.queueName)
    }

    return queue
  }
  service.emptyQueue = (queueName: any) => {
    let queue = getQueue(queueName)
    if (queue) {
      queue.items = []
    }
  }
  // ---------------------------------- [ END ] PUBLIC FUNCTION ----------------------------------

  return service
}])
