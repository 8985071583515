import { Component, OnInit, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngTaskPieChart',
  templateUrl: './task-pie-chart.component.html'
})
export class TaskPieChart implements OnInit {
  @Input() fruitalytics: any;

  // Properties
  private pieTotal: any;
  private slices: Array<any> = [];
  
  constructor(
    @Inject('complyosServices') private complyosServices,
  ) {
  }

  ngOnInit(): void {
    this.getPieTotals()
  }

  getPieTotals = () => {
    let pie_total = (
      (this.fruitalytics.data['*-#'] ? this.fruitalytics.data['*-#'] : 0) +
      (this.fruitalytics.data['*-X'] ? this.fruitalytics.data['*-X'] : 0) +
      (this.fruitalytics.data['1'] ? this.fruitalytics.data['1'] : 0)
    )

    this.slices = []

    if (this.fruitalytics.data['*-#']) {
      this.slices.push({
        label: 'Open Tasks',
        value: this.fruitalytics.data['*-#'],
        percent: (this.fruitalytics.data['*-#'] / pie_total),
        color: this.complyosServices.snapshot.get_status_color('open')
      })
    }

    if (this.fruitalytics.data['*-X']) {
      this.slices.push({
        label: 'Past Due Tasks',
        value: this.fruitalytics.data['*-X'],
        percent: (this.fruitalytics.data['*-X'] / pie_total),
        color: this.complyosServices.snapshot.get_status_color('past_due')
      })
    }

    if (this.fruitalytics.data['1']) {
      this.slices.push({
        label: 'Partially Complete Tasks',
        value: this.fruitalytics.data['1'],
        percent: (this.fruitalytics.data['1'] / pie_total),
        color: this.complyosServices.snapshot.get_status_color('partially_complete')
      })
    }

    const pieEl = document.querySelector('svg')

    let cumulativePercent = 0

    const getCoordinatesForPercent = function (percent: any) {
      const x = Math.cos(2 * Math.PI * percent)
      const y = Math.sin(2 * Math.PI * percent)
      return [x, y]
    }

    const get_large_arc_flag = function (percent: any) {
      if (percent > 0.5) {
        return 1
      } else {
        return 0
      }
    }

    return _.each(this.slices, function (slice: any) {
      const start_array = getCoordinatesForPercent(cumulativePercent)
      const startX = start_array[0]
      const startY = start_array[1]

      cumulativePercent += slice.percent

      const end_array = getCoordinatesForPercent(cumulativePercent)
      const endX = end_array[0]
      const endY = end_array[1]

      const largeArcFlag = get_large_arc_flag(slice.percent)

      const pathData = [
        `M ${startX} ${startY}`,
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
        'L 0 0'
      ].join(' ')

      const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path')
      pathEl.setAttribute('d', pathData)
      pathEl.setAttribute('fill', slice.color)
      return pieEl.appendChild(pathEl)
    });
  }
}

angular
  .module('complyosClient')
  .directive('ngTaskPieChart', downgradeComponent({ component: TaskPieChart }) as angular.IDirectiveFactory)
