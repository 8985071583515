import { Component, Directive, ElementRef, Injector, Input } from "@angular/core"
import { UpgradeComponent } from "@angular/upgrade/static"

@Directive({ selector: 'ajs-satalite-paginator' })
export class ajsSatalitePaginatorWrapper extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() paginationChangeBroadcast: any;
  @Input() paginationChangeListen: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('satalitePaginator', elementRef, injector);
  }
}