/* eslint-disable
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('complyosClient').directive('ngDownload', () =>

  ({
    restrict: 'A',

    link (scope: any, element: any, attrs: any) {
      scope.URL = window.URL
      scope.Blob = window.Blob

      scope.setupUrlDownload = function (a: any, url: any, filename: any, mimeType: any) {
        if (a.href !== '#') { scope.URL.revokeObjectURL(a.href) }
        a.download = filename
        a.href = url
        return a.dataset.downloadurl = [mimeType, a.download, a.href].join(':')
      }

      scope.setupDataDownload = function (a: any, data: any, filename: any, mimeType: any) {
        const blob = new scope.Blob([data], { type: mimeType })
        const url = scope.URL.createObjectURL(blob)
        return scope.setupUrlDownload(a, url, filename, mimeType)
      }
      const a = element[0]

      if (a.draggable) {
        element.bind('dragstart', (event: any) => event.originalEvent.dataTransfer.setData('DownloadURL', a.dataset.downloadurl))
      }

      a.href = '#'
      element.click(function () { if (a.href === '#') { return false } })

      const attrNames = ['ngDownload', 'mimeType', 'url', 'content', 'dux']

      const updateDom = function () {
        // TODO definitely not eval ing right.
        let [filename, mimeType, url, content, dux] = Array.from((Array.from(attrNames).map((attr) => scope.$eval(attrs[attr]))))
        filename = attrs.ngDownload || 'file'
        mimeType = mimeType || 'text/plain'

        if (url != null) {
          return scope.setupUrlDownload(a, url, filename, mimeType)
        } else if (content != null) {
          return scope.setupDataDownload(a, content, filename, mimeType)
        }
      }

      const watchers = {}
      return (() => {
        const result = []
        for (let attr of Array.from(attrNames)) {
          result.push((attr =>
            attrs.$observe(attr, function (attrValue: any) {
              if (watchers[attr] != null) { watchers[attr]() }
              if (attrValue) { return watchers[attr] = scope.$watch(attrValue, updateDom) }
            })
          )(attr))
        }
        return result
      })();
    }
  })
)
