// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// 'use strict'

// import angular from "angular";

// export class usersForgotPasswordController implements angular.IController {
//     static $inject = ['$location', '$q', '$rootScope', '$scope', 'authenticationService', 'flashService', 'Restangular', 'utils'];

//     $onInit() { }

//     constructor(
//         $location: any,
//         $q: any,
//         $rootScope: any,
//         $scope: any,
//         authenticationService: any,
//         flashService: any,
//         Restangular: any,
//         utils: any) {

//         /* FLASHES */
//         let requestNewPassword: any

//         $scope.flashService = angular.copy(flashService)

//         /* FUNCTIONS */
//         $scope.submit_form = function (form: any, object: any) {
//             if (form.$valid) {
//                 return requestNewPassword(object)
//             } else {
//                 return form.failed_submission = true
//             }
//         }

//         requestNewPassword = function (user: any) {
//             const forgot_password_flash = {
//                 strong: 'Success',
//                 name: `reset_password_${user.username}`,
//                 dismissable: false,
//                 class: 'alert-info',
//                 message: `Password reset instructions have been sent to ${user.username}`
//             }

//             return Restangular.all('user_services/forgot_password').post(user).then((success: any) => $scope.flashService.add_alert(forgot_password_flash)
//                 , function (error: any) {
//                     $scope.flashService.process_error(error)
//                     return utils.log(error)
//                 });
//         };
//     }
// }
// angular
//     .module('complyosClient')
//     .controller('usersForgotPasswordController', usersForgotPasswordController)


// // ------------------------------------


// // var complyosClient = angular.module('complyosClient')

// // complyosClient.controller('usersForgotPasswordController', ['$location', '$q', '$rootScope', '$scope', 'authenticationService', 'flashService', 'Restangular', 'utils', function (
// //   $location: any,
// //   $q: any,
// //   $rootScope: any,
// //   $scope: any,
// //   authenticationService: any,
// //   flashService: any,
// //   Restangular: any,
// //   utils: any
// // ) {
// //   /* FLASHES */
// //   let requestNewPassword: any
  
// //   $scope.flashService = angular.copy(flashService)

// //   /* FUNCTIONS */
// //   $scope.submit_form = function (form: any, object: any) {
// //     if (form.$valid) {
// //       return requestNewPassword(object)
// //     } else {
// //       return form.failed_submission = true
// //     }
// //   }

// //   return requestNewPassword = function (user: any) {
// //     const forgot_password_flash = {
// //       strong: 'Success',
// //       name: `reset_password_${user.username}`,
// //       dismissable: false,
// //       class: 'alert-info',
// //       message: `Password reset instructions have been sent to ${user.username}`
// //     }

// //     return Restangular.all('user_services/forgot_password').post(user).then((success: any) => $scope.flashService.add_alert(forgot_password_flash)
// //       , function (error: any) {
// //         $scope.flashService.process_error(error)
// //         return utils.log(error)
// //       });
// //   };
// // }])
