/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/

'use strict'

import angular from "angular";

export class confirmationModalController implements angular.IController {
    static $inject = ['$scope', '$uibModalInstance', 'confirmationObject'];

    $onInit() { }

    constructor(
        $scope: any,
        $uibModalInstance: any,
        confirmationObject: any) {

        $scope.severity = confirmationObject.severity || 'danger'
        $scope.title = confirmationObject.title || 'Delete Confirmation'
        $scope.message = confirmationObject.message || 'Are you sure you want to delete this?'
        $scope.button_icon = confirmationObject.button_icon || 'fa-trash-o'
        $scope.button_text = confirmationObject.button_text || 'Delete'

        /**
          * Close the instance and resolve
          *
          */
        $scope.ok = () => $uibModalInstance.close(true)

        /**
          * Close the instance and reject
          *
          */
        $scope.cancel = () => $uibModalInstance.dismiss(false)
    }
}

angular
    .module('complyosClient')
    .controller('confirmationModalController', confirmationModalController)

// angular
//     .module('complyosClient')
//     .factory('confirmationModalFactory', ['$uibModal', ($uibModal: any) => ({
//     openConfirmationModal(confirmationObject: any) {
//         // Open the modal instance and pass in our options via resolve
//         const $uibModalInstance = $uibModal.open({
//             resolve: {
//                 confirmationObject() {
//                     return confirmationObject
//                 }
//             },
//             templateUrl: 'views/directives/confirmation_modal.html',
//             controller: 'confirmationModalController'
//         })

//         // Resolve the response from the modal
//         return $uibModalInstance.result.then(function () {
//             // Run the function requiring confirmation
//             const success = confirmationObject.confirm || (() => console.log('Confirm'))
//             success()
//             return true
//         }
//             , function () {
//                 const failure = confirmationObject.deny || (() => console.log('Deny'))
//                 failure()
//                 return false
//             })
//     }
// })])


// ------------------------------------

// var complyosClient = angular.module('complyosClient')

// /**
//   * Controller used by the confirmation modal directive to handle the angular-ui modal window
//   *
//   * $uibModalInstance: The angular-ui modal instance
//   * confirmationModalScope: Scope from the directive passed into this controller
//   */

// complyosClient.controller('confirmationModalController', ['$scope', '$uibModalInstance', 'confirmationObject', function (
//     $scope: any,
//     $uibModalInstance: any,
//     confirmationObject: any
// ) {
//     $scope.severity = confirmationObject.severity || 'danger'
//     $scope.title = confirmationObject.title || 'Delete Confirmation'
//     $scope.message = confirmationObject.message || 'Are you sure you want to delete this?'
//     $scope.button_icon = confirmationObject.button_icon || 'fa-trash-o'
//     $scope.button_text = confirmationObject.button_text || 'Delete'

//     /**
//       * Close the instance and resolve
//       *
//       */
//     $scope.ok = () => $uibModalInstance.close(true)

//     /**
//       * Close the instance and reject
//       *
//       */
//     return $scope.cancel = () => $uibModalInstance.dismiss(false)
// }])

