/* eslint-disable
    camelcase,
    no-undef,
*/

angular.module('complyosClient').factory('filterService', ['$rootScope', function (
  $rootScope: any
) {
  return {
    getCached () {
      let cachedDashboardFilters = $rootScope.session.data.filters_dashboard
      if (cachedDashboardFilters) {
        console.log('requested cached filters: ', cachedDashboardFilters)
        cachedDashboardFilters = cachedDashboardFilters.filter((f: any) => !(f.Field === 'organization_ids'))
        return JSON.parse(JSON.stringify(cachedDashboardFilters))
      }
      return []
    },

    setCache (filterPills: any) {
      $rootScope.session.data.filters_dashboard = JSON.parse(JSON.stringify(filterPills))
    },

    hasOrganizationPill (pills: any) {
      let orgPill = pills.find((p: any) => p.Field === 'organization_ids')
      return orgPill != null
    }
  };
}])
