// 'use strict'

import angular from "angular";

//- --------------------------------------------------------------------
//- NOTE: this service did not work when converted to the class-style service
//-     it has still been adapted to run in NG12+ entities, but just not as a 
//-     class.
//- --------------------------------------------------------------------

// export class fortyCore {
//   private Modernizr: any;

//   public detectionService: any;
//   public processingStateService: any;
//   public objectService: any;
//   public math: any;
//   public text: any;

//   static $inject = ['Modernizr'];
//   constructor(
//     Modernizr: any) {
//     this.Modernizr = Modernizr;

//     // Detects browser types and platforms
//     this.detectionService = {

//       /* BROWSER DETECTION SERVICES */
//       // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
//       // Most browsers set the name and version in the format BrowserName/
//       // VersionNumber, with the notable exception of Internet Explorer.
//       // But as the name is not the only information in a user agent string that
//       // is in that format, you can not discover the name of the browser, you
//       // can only check if the name you are looking for. But note that some
//       // browsers are lying: Chrome for example reports both as Chrome and
//       // Safari. So to detect Safari you have to check for the Safari string
//       // and the absence of the Chrome string, Chromium often reports itself
//       // as Chrome too or Seamonkey sometimes reports itself as Firefox.

//       isSafari() {
//         return /Safari/i.test(navigator.userAgent || navigator.vendor) && !this.isChrome() && !this.isChromium();
//       },

//       isChrome() { // working for chrome 71+ Updated 2018.12.05
//         return /Chrome/i.test(navigator.userAgent || navigator.vendor) || (!!window["chrome"] && !!window["chrome"].webstore);
//       },

//       isChromium() {
//         return /Chromium/i.test(navigator.userAgent || navigator.vendor);
//       },

//       isFirefox() {
//         return /Firefox/i.test(navigator.userAgent || navigator.vendor);
//       },

//       isOpera() {
//         return /Opera|OPR\//i.test(navigator.userAgent || navigator.vendor);
//       },

//       isInternetExplorer() {
//         return /;MSIE/i.test(navigator.userAgent || navigator.vendor);
//       },

//       isSeamonkey() {
//         return /Seamonkey/i.test(navigator.userAgent || navigator.vendor);
//       },

//       // Regex taken from http://detectmobilebrowser.com
//       // THANKS GUYS!!!
//       detectMobileBrowser(a: any) {
//         return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
//       },

//       isMobileBrowser() {
//         return this.detectMobileBrowser(navigator.userAgent || navigator.vendor || window["opera"])
//       },

//       isMobileBrowserOrTablet() {
//         return /android|ipad|playbook|silk/i.test(navigator.userAgent || navigator.vendor) || this.isMobileBrowser(navigator.userAgent || navigator.vendor || window["opera"]);
//       },

//       /* PLATFORM DETECTION SERVICES */
//       // Do not use these unless you have no other choice
//       // User agents and platforms can be faked.
//       // Written: 03/08/2016. Any changes to browsers past this date are not included

//       isIOSPlatform() {
//         return /iphone|ipod|ipad|iphone simulator|ipod simulator|ipad simulator/i.test(navigator.platform);
//       },

//       // Many android platforms will return with a flavor of linux or null,
//       // therefore, this is a non-comprehensive test
//       isAndroidPlatform() {
//         return /android/i.test(navigator.platform);
//       },

//       /* FEATURE CHECKING SERVICES */
//       // Checks for features using modernizer or directly checking element/attribute presence
//       // Most of the time, just using Modernizr is the better option than hand-spinning a method

//       /* AMALGAM SERVICES */
//       // These user multiple check types to get a best guess at what environment you are working in

//       isMobileOrTablet() {
//         return this.isAndroidPlatform() || this.isIOSPlatform() || this.isMobileBrowserOrTablet()
//       },

//       isMobileAmalgam() {
//         return Modernizr.touchevents || this.isMobileOrTablet()
//       }

//     };

//     // Services pertaining to state driven objects and actions
//     this.processingStateService = {

//       processing_states: {
//         RESTING: 'resting',
//         PENDING: 'pending'
//       },

//       // Performs a function on a given stated objects
//       // Function perform in non-pending state
//       performFunction(object: any, fn: any) {
//         if ((object.processing_state !== undefined) && (object.processing_state === this.processing_states.PENDING)) {
//           return false
//         } else {
//           object.processing_state = this.processing_states.PENDING
//           fn()
//           return true
//         }
//       },

//       // Sets the state of an object
//       set(object: any, state: any) {
//         if (this.objectService.containsFirstLevelValue(this.processing_states, state)) {
//           return object.processing_state = state
//         } else {
//           return false
//         }
//       }

//     }

//     this.objectService = {

//       containsFirstLevelValue(object: any, value: any) {
//         for (let key in object) {
//           if (object[key] === value) {
//             return true
//           }
//         }

//         return false
//       }

//     }

//     this.math = {

//       percentagize(percentage: any) {
//         const percent = Math.round(percentage * 100)
//         return percent
//       },

//       percentagize_without_rounding(percentage: any) {
//         const percent = percentage * 100
//         return percent
//       },

//       set_decimal_places(number: any, decimal_points: any) {
//         return number.toFixed(decimal_points)
//       },

//       check_presence(number: any) {
//         if (number === undefined) {
//           return false
//         } else {
//           return true
//         }
//       }
//     }

//     this.text = {

//       // https://gist.github.com/maruf-nc/5625869
//       titalize(input: any) {
//         const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
//         input = input.toLowerCase()
//         input = input.replace('_', ' ')
//         return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (match: any, index: any, title: any) {
//           if ((index > 0) && ((index + match.length) !== title.length) && (match.search(smallWords) > -1) && (title.charAt(index - 2) !== ':') && ((title.charAt(index + match.length) !== '-') || (title.charAt(index - 1) === '-')) && (title.charAt(index - 1).search(/[^\s-]/) < 0)) {
//             return match.toLowerCase()
//           }
//           if (match.substr(1).search(/[A-Z]|\../) > -1) {
//             return match
//           }
//           return match.charAt(0).toUpperCase() + match.substr(1)
//         });
//       },

//       splitWords(input_to_split: any, param: any) {
//         const new_regex = new RegExp(param, 'g')

//         if (input_to_split.search(new_regex) !== -1) {
//           const modified_string = input_to_split.split(new_regex).join(' ')
//           return modified_string
//         } else {
//           return input_to_split
//         }
//       },

//       trimPhrase(phrase: any, start_position_param: any, end_position_param: any) {
//         const new_phrase = phrase.slice(start_position_param, end_position_param)
//         return new_phrase
//       },

//       condensePhrase(phrase: any, word_count: any, split_param: any) {
//         const spaced_phrase = this.splitWords(phrase, split_param)
//         return spaced_phrase.split(' ')[word_count - 1]
//       },

//       pluralize(input_to_pluralize: any) {
//         return input_to_pluralize + 's'
//       },

//       ellipsisize(input_to_ellip: any, word_count: any, split_param: any) {
//         const word_array = input_to_ellip.split(split_param)
//         let shortened_string = ''

//         let word_index = 0
//         while (word_index < word_count) {
//           // this if is for if the word count exceeds the amount of words in input, will prevent undefineds.
//           if (word_array[word_index] !== undefined) {
//             shortened_string = shortened_string.concat(' ', word_array[word_index])
//           }
//           word_index++
//         }

//         if (word_array.length < word_count) {
//           return shortened_string
//         } else {
//           return shortened_string + ' ...'
//         }
//       },

//       copy(string: any) {
//         var handler = function (event: any) {
//           event.clipboardData.setData('text/plain', string)
//           event.preventDefault()
//           return document.removeEventListener('copy', handler, true)
//         }

//         document.addEventListener('copy', handler, true)
//         document.execCommand('copy')
//       }

//     }
//   }

// }

// // FortyModule
// angular
//   .module('FortyAUServices')
//   .service("fortyCore", fortyCore);

//- --------------------------------------------------------------------
//- FOR REFERENCE

export function fortyCore(Modernizr: any) {
  var core = {

    // Detects browser types and platforms
    detectionService: {

      /* BROWSER DETECTION SERVICES */
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
      // Most browsers set the name and version in the format BrowserName/
      // VersionNumber, with the notable exception of Internet Explorer.
      // But as the name is not the only information in a user agent string that
      // is in that format, you can not discover the name of the browser, you
      // can only check if the name you are looking for. But note that some
      // browsers are lying: Chrome for example reports both as Chrome and
      // Safari. So to detect Safari you have to check for the Safari string
      // and the absence of the Chrome string, Chromium often reports itself
      // as Chrome too or Seamonkey sometimes reports itself as Firefox.

      isSafari() {
        return /Safari/i.test(navigator.userAgent || navigator.vendor) && !this.isChrome() && !this.isChromium();
      },

      isChrome() { // working for chrome 71+ Updated 2018.12.05
        return /Chrome/i.test(navigator.userAgent || navigator.vendor) || (!!window["chrome"] && !!window["chrome"].webstore);
      },

      isChromium() {
        return /Chromium/i.test(navigator.userAgent || navigator.vendor);
      },

      isFirefox() {
        return /Firefox/i.test(navigator.userAgent || navigator.vendor);
      },

      isOpera() {
        return /Opera|OPR\//i.test(navigator.userAgent || navigator.vendor);
      },

      isInternetExplorer() {
        return /;MSIE/i.test(navigator.userAgent || navigator.vendor);
      },

      isSeamonkey() {
        return /Seamonkey/i.test(navigator.userAgent || navigator.vendor);
      },

      // Regex taken from http://detectmobilebrowser.com
      // THANKS GUYS!!!
      detectMobileBrowser(a: any) {
        return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
      },

      isMobileBrowser() {
        return this.detectMobileBrowser(navigator.userAgent || navigator.vendor || window["opera"])
      },

      isMobileBrowserOrTablet() {
        return /android|ipad|playbook|silk/i.test(navigator.userAgent || navigator.vendor) || this.isMobileBrowser(navigator.userAgent || navigator.vendor || window["opera"]);
      },

      /* PLATFORM DETECTION SERVICES */
      // Do not use these unless you have no other choice
      // User agents and platforms can be faked.
      // Written: 03/08/2016. Any changes to browsers past this date are not included

      isIOSPlatform() {
        return /iphone|ipod|ipad|iphone simulator|ipod simulator|ipad simulator/i.test(navigator.platform);
      },

      // Many android platforms will return with a flavor of linux or null,
      // therefore, this is a non-comprehensive test
      isAndroidPlatform() {
        return /android/i.test(navigator.platform);
      },

      /* FEATURE CHECKING SERVICES */
      // Checks for features using modernizer or directly checking element/attribute presence
      // Most of the time, just using Modernizr is the better option than hand-spinning a method

      /* AMALGAM SERVICES */
      // These user multiple check types to get a best guess at what environment you are working in

      isMobileOrTablet() {
        return this.isAndroidPlatform() || this.isIOSPlatform() || this.isMobileBrowserOrTablet()
      },

      isMobileAmalgam() {
        return Modernizr.touchevents || this.isMobileOrTablet()
      }

    },

    // Services pertaining to state driven objects and actions
    processingStateService: {

      processing_states: {
        RESTING: 'resting',
        PENDING: 'pending'
      },

      // Performs a function on a given stated objects
      // Function perform in non-pending state
      performFunction(object: any, fn: any) {
        if ((object.processing_state !== undefined) && (object.processing_state === this.processing_states.PENDING)) {
          return false
        } else {
          object.processing_state = this.processing_states.PENDING
          fn()
          return true
        }
      },

      // Sets the state of an object
      set(object: any, state: any) {
        if (core.objectService.containsFirstLevelValue(this.processing_states, state)) {
          return object.processing_state = state
        } else {
          return false
        }
      }

    },

    objectService: {

      containsFirstLevelValue(object: any, value: any) {
        for (let key in object) {
          if (object[key] === value) {
            return true
          }
        }

        return false
      }

    },

    math: {

      percentagize(percentage: any) {
        const percent = Math.round(percentage * 100)
        return percent
      },

      percentagize_without_rounding(percentage: any) {
        const percent = percentage * 100
        return percent
      },

      set_decimal_places(number: any, decimal_points: any) {
        return number.toFixed(decimal_points)
      },

      check_presence(number: any) {
        if (number === undefined) {
          return false
        } else {
          return true
        }
      }
    },

    text: {

      // https://gist.github.com/maruf-nc/5625869
      titalize(input: any) {
        const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
        input = input.toLowerCase()
        input = input.replace('_', ' ')
        return input.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function (match: any, index: any, title: any) {
          if ((index > 0) && ((index + match.length) !== title.length) && (match.search(smallWords) > -1) && (title.charAt(index - 2) !== ':') && ((title.charAt(index + match.length) !== '-') || (title.charAt(index - 1) === '-')) && (title.charAt(index - 1).search(/[^\s-]/) < 0)) {
            return match.toLowerCase()
          }
          if (match.substr(1).search(/[A-Z]|\../) > -1) {
            return match
          }
          return match.charAt(0).toUpperCase() + match.substr(1)
        });
      },

      splitWords(input_to_split: any, param: any) {
        const new_regex = new RegExp(param, 'g')

        if (input_to_split.search(new_regex) !== -1) {
          const modified_string = input_to_split.split(new_regex).join(' ')
          return modified_string
        } else {
          return input_to_split
        }
      },

      trimPhrase(phrase: any, start_position_param: any, end_position_param: any) {
        const new_phrase = phrase.slice(start_position_param, end_position_param)
        return new_phrase
      },

      condensePhrase(phrase: any, word_count: any, split_param: any) {
        const spaced_phrase = this.splitWords(phrase, split_param)
        return spaced_phrase.split(' ')[word_count - 1]
      },

      pluralize(input_to_pluralize: any) {
        return input_to_pluralize + 's'
      },

      ellipsisize(input_to_ellip: any, word_count: any, split_param: any) {
        const word_array = input_to_ellip.split(split_param)
        let shortened_string = ''

        let word_index = 0
        while (word_index < word_count) {
          // this if is for if the word count exceeds the amount of words in input, will prevent undefineds.
          if (word_array[word_index] !== undefined) {
            shortened_string = shortened_string.concat(' ', word_array[word_index])
          }
          word_index++
        }

        if (word_array.length < word_count) {
          return shortened_string
        } else {
          return shortened_string + ' ...'
        }
      },

      copy(string: any) {
        var handler = function (event: any) {
          event.clipboardData.setData('text/plain', string)
          event.preventDefault()
          return document.removeEventListener('copy', handler, true)
        }

        document.addEventListener('copy', handler, true)
        document.execCommand('copy')
      }

    }

  }

  return core
}

angular
  .module('FortyAUServices')
  .service('fortyCore', ['Modernizr', fortyCore])