// /* eslint-disable
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// angular.module('complyosClient').directive('analyticsTrend', [
//   'helpService',
//   'fortyCore',
//   (helpService, fortyCore) => ({
//     templateUrl: 'views/directives/analytics_trend.html',
//     restrict: 'E',

//     scope: {
//       trend: '=trend'
//     },

//     link ($scope: any, $ele: any, $attrs: any) {
//       $scope.helpService = helpService
//       return $scope.fortyCore = fortyCore
//     }
//   })

// ])
