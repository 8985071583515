// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     handle-callback-err,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// import angular from 'angular'
// import * as _ from 'lodash';
// import Humanize from 'humanize-plus'
// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsBindersController', ['$q', '$rootScope', '$scope', '$uibModal', 'confirmationModalFactory', 'FileSaver', 'filterService', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', 'arrayFunctions', 'helpService', function (
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   confirmationModalFactory: any,
//   FileSaver: any,
//   filterService: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any,
//   arrayFunctions: any,
//   helpService: any
// ) {
//   const init = function () {
//     $scope.helpService = helpService
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.flashService = angular.copy(flashService)
//     $scope.fortyCore = angular.copy(fortyCore)
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.previousPills = null
//     const custom_states = {
//       nocontent: {
//         icon: 'fa-exclamation-triangle',
//         text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.outstandingPromises = []
//     $scope.binders_need_start_date_state_count = 0
//     $scope.binders_loaded = false
//     $scope.userCanClickBulkButton = false
//     $scope.binderCount = 0

//     watchEntityFilter()
//   }

//   $scope.displayPairingKeyIcon = (binder: any) => {
//     if (
//       (
//         binder.requirement.managed_by_paired_application === true
//         // binder.schedule_enabled === false ||
//         // binder.requirement.schedule_state === 'schedule_optional' ||
//         // binder.requirement.schedule_state === 'schedule_disabled'
//       ) &&
//       !binder.archived &&
//       binder.requirement.compliance_method === 'Engineering Central' // TODO: this will have to be romoved if we pair with a second application
//     ) {
//       return true
//     } else {
//       return false
//     }
//   }

//   // sort by method will take sort type and pass to api as a filter pill to be sorted and returned
//   $scope.sort_by = function (sort_method: any) {
//     console.log(sort_method)
//   }

//   $scope.hasUserAppliedNotRequiredPills = function (pills: any)  {
//     $scope.userCanClickBulkButton = pills.some((p: any) => p.isRequired === false || p.isRequired === undefined)
//   }

//   var getBinders = function (pills: any) {
//     $scope.binders_loaded = false
//     let query_params = {
//       param_data: {}
//     }
//     $scope.loadingStateService.set_state('loading')
//     query_params.param_data = $rootScope.storeService.stringifyTagParams(
//       $rootScope.storeService.pillsToObject(pills)
//     )
//     query_params.param_data['sorted'] = ['name']
//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     Restangular.one('binders').withHttpConfig({ timeout: abort.promise }).get(query_params).then(
//       (success: any) => {
//         $scope.loadingStateService.process_success(success.items)
//         $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//         $scope.binders = success.items
//         $scope.binderCount = success.pagination_info.totalCount
//         $scope.binders_loaded = true
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     )
//   }

//   const getNeedsStartDateCount = function (pills: any) {
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }
//     Restangular.one(`binders/needs_start_date_count`).get(query_params).then(
//       (success: any) => {
//         let result = success.plain()
//         $scope.binders_need_start_date_state_count = result.record_count
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     )
//   }

//   $scope.abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   $scope.openBinderReadModal = (binder_object: any) => {
//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(binder_object, () => openBinderModal(binder_object, 'read'))
//   }

//   $scope.openBinderUpdateModal = (binder_object: any) => {
//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(binder_object, () => openBinderModal(binder_object, 'update'))
//   }

//   // https://angular-ui.github.io/bootstrap/#/modal
//   var openBinderModal = function (binder_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/binder_modal.html',
//       controller: 'binderModalController',
//       size: 'small',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions () {
//           return {
            
//             object: angular.copy(binder_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     return modalInstance.result.then(function (result: any) {
//       if (ui_setting === 'create') {
//         objectManager.array_action($scope.binders, result, 'merge')
//       }
//       if (ui_setting === 'update') {
//         return objectManager.array_action($scope.binders, result, 'update')
//       }
//     }, function (reason: any) {

//     }).finally(() =>
//       $scope.processingStateService.set(
//         binder_object,
//         $scope.processingStateService.processing_states.RESTING
//       )
//     );
//   }

//   /**
//     * Simple function to provide the Humanize library access to the template
//     * @param {string}
//     * @return {string}
//     */
//   $scope.capitalize = (string: any) => Humanize.capitalize(string).replace('_', ' ')

//   $scope.navigate_to_management_url = function (binder: any) {
//     window.open(
//       binder.mailbox_pairing_management_url,
//       '_blank'
//     )
//   }

//   $scope.pushStartDateNeededPill = () => {
//     let startDateNeededPill = {
//       isUnique: true,
//       Field: 'schedule_generation_state',
//       Op: 'Equals',
//       displayName: 'Schedule State',
//       displayValue: 'Start Date Needed',
//       Value: 'start_date_needed',
//       dateCreated: dayjs().$d
//     }
//     $rootScope.$broadcast('push-new-filter', startDateNeededPill)
//   }

//   var watchEntityFilter = () => {
//     var refresh = function (e: any, pills: any) {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted']) || $scope.previousPills === null
//       $scope.previousPills = pills
//       if (widgetShouldRefresh) {
//         $scope.abortAllPromises($scope.outstandingPromises)
//         $scope.hasUserAppliedNotRequiredPills(pills)
//         setTimeout(() => getBinders(pills), 50)
        
//         setTimeout(() => getNeedsStartDateCount(angular.copy(pills)), 50)
//       }
//     }
//     $scope.$on('filterChange-dashboard', refresh)
//   }

//   /* BULK CONFIGURE */
//   $scope.openBulkArchiveRequirementsModal = function () {
//     var $ctrl = this
//     let unpaged_pills = $scope.previousPills.filter((pills: any) => (pills.Field !== 'pagination_params'))
//     let binderCount = $scope.binderCount
//     $ctrl.items = ['assign', unpaged_pills, binderCount]
//     var modalInstance = $uibModal.open({
//       component: 'bulkConfigureRequirements',
//       size: 'xl',
//       resolve: {
//         items: function () {
//           return $ctrl.items
//         }
//       }
//     })

//     return modalInstance.result.then(function (params: any) {
//       console.log(params)
//     }, function (params: any) {
//       if (params === 'submit') {
//         setTimeout(() => getBinders($scope.previousPills), 50)
//       }
//     });
//   }

//   /* JSON EXPORT BUTTON */
//   $scope.downloadJsonExport = function (binder: any) {
//     $scope.flashService.clear_alerts()
//     let query_params = {
//       report: 'json_dump_export',
//       param_data: {
//         binder_ids: [binder.id],
//         organization_ids: [binder.organization_id]
//       }
//     }

//     Restangular.setFullResponse(true).one(`reports/json_dump_export`).get(query_params).then(function (response: any) {
//       var report_file = new Blob([response.data], { type: 'text/csv' })
//       var report_name = `json_export_report_${dayjs().format('YYYY-MM-DD')}.csv`
//       FileSaver.saveAs(report_file, report_name)
//     }, function (error: any) {
//       window.scrollTo({ top: 0 })
//       $scope.flashService.add_alert({
//         name: 'report_error',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-warning',
//         strong: error.statusText,
//         message: error.data[0]
//       })
//     }).finally(() => {
//       // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//       // Reset the full response setting
//       Restangular.setFullResponse(false)
//     })
//   }

//   $scope.can_show_json_download = function (binder: any) {
//     let user_type = $scope.session.getUser().user_role.role.title
//     if(user_type == 'System Admin' && binder.requirement.compliance_method.includes('Form')) {
//       return true
//     } else {
//       return false
//     }
//   }

//   return init()
// }])
