'use strict';

import angular from "angular";

angular.module('config', [])

  .constant('ENV', {
    'APPLICATION_URL': 'https://hca.complyos.com',
    'COMPLYOS_API_URL': 'https://api.complyos.com',
    'COMPLYOS_DOMAIN': 'complyos.com',
    'COMPLYOS_FORMS_URL': 'https://forms.complyos.com/#',
    // 'gaCode': 'UA-55932200-2',
    'NAME': 'production',
  });
