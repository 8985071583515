// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('complyosDashboardController', ['$uibModal', '$rootScope', '$scope', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
//   $uibModal: any,
//   $rootScope: any,
//   $scope: any,
//   confirmationModalFactory: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
//     $scope.fortyCore = fortyCore
    
//     $scope.loadingStateService = angular.copy(loadingStateService)

//     $scope.title = 'Complyos Dashboard'
//     $scope.duck_data = []

//     const ducks = [
//       'attachment_count',
//       'binder_count',
//       'organization_count',
//       'requirement_count',
//       'user_count'
//     ]

//     $scope.loadingStateService.init()
//     return line_up_the_ducks(ducks)
//   }

//   var line_up_the_ducks = (ducks: any) => _.each(ducks, function (duck: any) {
//     const duck_query = {
//       model: 'Instance',
//       method: duck,
//       param_data: {}
//     }
//     return get_duck_data(duck_query).then(function () {
//       if ($scope.duck_data.length === ducks.length) {
//         return $scope.loadingStateService.set_state('content')
//       }
//     })
//   })

//   var get_duck_data = (duck_query: any) => Restangular.one('analytics/gather').get(duck_query).then((response: any) => $scope.duck_data.push(response)
//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     })

//   $scope.get_duck_attributes = function (method: any) {
//     let duck_attributes = {}
//     switch ($scope.fortyCore.text.condensePhrase(method, 1, '_')) {
//       case 'attachment':
//         duck_attributes = {
//           icon: 'fa-file-pdf-o',
//           display_name: 'Attachments'
//         }
//         break
//       case 'binder':
//         duck_attributes = {
//           icon: 'fa-list',
//           display_name: 'Configured Requirements'
//         }
//         break
//       case 'organization':
//         duck_attributes = {
//           icon: 'fa-building-o',
//           display_name: 'Organizations'
//         }
//         break
//       case 'requirement':
//         duck_attributes = {
//           icon: 'fa-clipboard',
//           display_name: 'Requirements'
//         }
//         break
//       case 'user':
//         duck_attributes = {
//           icon: 'fa-group',
//           display_name: 'Users'
//         }
//         break
//     }

//     return duck_attributes
//   }

//   $scope.check_duck_data = function (duck_stat: any) {
//     if ($scope.fortyCore.math.check_presence(duck_stat)) {
//       return duck_stat
//     } else {
//       return 'N/A'
//     }
//   }

//   $scope.active_duck_percentage = function (duck_stats: any) {
//     if ($scope.fortyCore.math.check_presence(duck_stats.count_active)) {
//       return $scope.fortyCore.math.set_decimal_places($scope.fortyCore.math.percentagize_without_rounding(duck_stats.count_active / duck_stats.count_all), 1) + '%'
//     } else {
//       return 'N/A'
//     }
//   }

//   $scope.see_duck_history = (duck_stats: any) => $scope.duck_history_enabled = true

//   // do stuff here to see actual duck history

//   // $scope.labels = ["January", "February", "March", "April", "May", "June", "July"]
//   // $scope.series = ['Series A', 'Series B']
//   // $scope.data = [
//   //     [65, 59, 80, 81, 56, 55, 40],
//   //     [28, 48, 40, 19, 86, 27, 90]
//   // ]

//   // $scope.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }]
//   // $scope.options = {
//   //   scales: {
//   //     yAxes: [
//   //       {
//   //         id: 'y-axis-1',
//   //         type: 'linear',
//   //         display: true,
//   //         position: 'left'
//   //       },
//   //       {
//   //         id: 'y-axis-2',
//   //         type: 'linear',
//   //         display: true,
//   //         position: 'right'
//   //       }
//   //     ]
//   //   }
//   // }

//   return init()
// }])
