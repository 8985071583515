/* eslint-disable
    camelcase,
    no-undef,
    no-undef-init
*/

var dayjs = require("dayjs")
// import { default as dayjs } from 'dayjs'
import * as angular from "angular"
import * as _ from 'lodash';


angular.module('complyosClient').factory('sessionService', ['$http', '$location', '$q', '$rootScope', '$state', '$stateParams', '$transitions', '$window', 'authenticationService', 'ENV', 'Restangular', 'filterService', 'storeService', 'arrayFunctions', 'constants', 'utils', 
function (
  $http: any,
  $location: any,
  $q: any,
  $rootScope: any,
  $state: any,
  $stateParams: any,
  $transitions: any,
  $window: any,
  authenticationService: any,
  ENV: any,
  Restangular: any,
  filterService: any,
  storeService: any,
  arrayFunctions: any,
  constants: any,
  utils: any
) {
  $rootScope.filterService = filterService
  $rootScope.storeService = storeService
  return {

    initalize () {
      // HEY, the session is a complicated beast, if you have a need to debug
      // or work on it in general, we have added some breadcrumbs of sort. EX:
      // *sessionBreadCrumb* console.log('Session debugging crumbs are on')
      // just do a global find and replace for this: // *sessionBreadCrumb*
      // be sure to restore them to a commented state when you are done.

      this.localStorageContainer = `${$location.$$host.replace(/\./g, '_')}_session`

      this.setSessionData().then((success: any) => {
        this.validateOrWait()
      })
      this.watchSessionData()
      this.watchUrlParams()
      this.watchRoute()
      this.watchUnload()
    },

    /* INITIALIZE */

    blankSessionData () {
      return {
        'ENV': ENV,
        'sso_idp': undefined,
        'sso_domained': undefined,
        'urlParams': {},
        'token': undefined,
        'activeOrganization': undefined,
        'activeRoute': undefined,
        'activeUser': undefined,
        'coldCallSessionState': undefined,
        'suspendedSessionState': undefined,
        'previousSessionState': undefined,
        'happy': undefined,
        'documentReview': {
          'navigationLocked': undefined,
          'entityFilterKey': undefined,
          'quickSearchHistory': {}
        },
        'isFilterLocked': false
      }
    },

    // set a blank session data object, and retrieve previous if possible.
    setSessionData () {
      const deferred = $q.defer()
      // *sessionBreadCrumb* console.log('session_service > setSessionData()')

      // Set the base session data to a blank object
      this.data = this.blankSessionData()

      // Restore some session data from the previousSessionState
      if ($window.localStorage.getItem(this.localStorageContainer)) {
        // get the sessionObject without previousSessionObject, avoid russian nesting
        let sessionObject = JSON.parse($window.localStorage.getItem(this.localStorageContainer))
        delete sessionObject.previousSessionState

        // remove some un-needed keys
        delete sessionObject.happy

        // move some possible states to the base session object, remove from sessionObject
        this.data.token = sessionObject.token
        delete sessionObject.token
        this.data.coldCallSessionState = sessionObject.coldCallSessionState
        delete sessionObject.coldCallSessionState
        this.data.suspendedSessionState = sessionObject.suspendedSessionState
        delete sessionObject.suspendedSessionState
        this.data.filters_dashboard = sessionObject.filters_dashboard
        delete sessionObject.filters_dashboard
        this.data.isFilterLocked = sessionObject.isFilterLocked
        delete sessionObject.isFilterLocked

        // data specific to survey_mode
        this.data.documentReview = sessionObject.documentReview
        delete sessionObject.documentReview

        // save the rest of previousSessionState into the base session object
        this.data.previousSessionState = sessionObject
      }

      // set our sso_idp provider if possible
      // resolve setSessionData promise when finished
      this.set_sso_idp().then((success: any) => {
        // not resolving anything related to set_sso_idp
        deferred.resolve(this.data)
      }, (error: any) => {
        console.log(error)
      })

      return deferred.promise
    },

    // validate previous token, or redirect user and wait.
    validateOrWait () {
      // *sessionBreadCrumb* console.log('session_service > validateOrWait()')
      if (this.data.token !== undefined) {
        // *sessionBreadCrumb* console.log('session_service > validateOrWait() :: TOKEN FOUND - VALIDATING')
        authenticationService.validate_token(this.data.token).then(
          (success: any) => {
            // *sessionBreadCrumb* console.log('session_service > validateOrWait() :: TOKEN VALID')
            this.postAuthenticate(this.data.token)
          }, (error: any) => {
            console.log(error)
            // *sessionBreadCrumb* console.log('session_service > validateOrWait() > authenticationService.validate_token() :: TOKEN NOT VALID - WAITING')
            this.removeTokenCookie()
            this.data.token = undefined
            this.waitForToken()
            this.escortUnauthorizedVisitor()
          }
        )
      } else {
        this.waitForToken()
        this.escortUnauthorizedVisitor()
      }
    },

    waitForToken () {
      // *sessionBreadCrumb* console.log(`session_service > waitForToken() :: WAITING`)

      this.data.tokenListener = $rootScope.$on('sessionToken', (event: any, data: any) => {
        // *sessionBreadCrumb* console.log(`session_service > waitForToken() :: TOKEN SET`)
        this.data.token = data
        this.postAuthenticate(this.data.token)
        // call the listener a second time to de-register
        this.data.tokenListener()
      })

      // call the listener once to register
      return this.data.tokenListener
    },

    bypassBroadcast (token: any) {
      // *sessionBreadCrumb* console.log(`session_service > bypassBroadcast() :: TOKEN SET`)
      this.data.token = token
      this.postAuthenticate(token)

      // call the "wait" listener a second time to de-register
      this.data.tokenListener()
    },

    postAuthenticate (token: any) {
      // *sessionBreadCrumb* console.log(`session_service > postAuthenticate('${token}')`)
      this.useToken(token)

      authenticationService.retrieve_user(token).then((success: any) => {
        // choose what session to configure for our users

        // if the user tried to cold-call the application
        if (this.data.coldCallSessionState) {
          // *sessionBreadCrumb* console.log('session_service > configureSession(coldCallSessionState)', this.data.coldCallSessionState)
          this.configureSession(this.data.coldCallSessionState)
          this.data.coldCallSessionState = undefined

        // If a user suspends their session, or 'times-out', they will have to re-auth
        } else if (this.data.suspendedSessionState) {
          // *sessionBreadCrumb* console.log('session_service > configureSession(suspendedSessionState)', this.data.suspendedSessionState)
          this.configureSession(this.data.suspendedSessionState)
          this.data.suspendedSessionState = undefined

        // If a user closes the tab, as long as their token is fresh, it will re-load
        } else if (this.previousSessionStateApplicable()) {
          // *sessionBreadCrumb* console.log('session_service > configureSession(previousSessionState)', sessionObject)
          this.configureSession(this.data.previousSessionState)
          this.data.previousSessionState = undefined

        // If a user logs out, and needs a 'clean' session we load the defaultSessionStata
        } else {
          // *sessionBreadCrumb* console.log('session_service > configureSession(defaultSessionState)', sessionObject)
          this.configureSession(this.defaultSessionState())
        }

        // remove any existing timeout banners
        $rootScope.$broadcast(
          'remove_global_alert_by_name',
          'default_timeout'
        )
        // *sessionBreadCrumb* utils.log('session_service :: HAPPY', 'success')
        this.data.happy = true
      }, (error: any) => {
        console.log(error)
      }).finally(() => {
        return true
      })
    },

    /* SESSION DATA */

    watchSessionData () {
      $rootScope.$watch(() => $rootScope.session.data, (new_value: any, old_value: any, scope: any) => {
        this.saveSessionData()
      }, true)
    },

    saveSessionData () {
      $window.localStorage.setItem(this.localStorageContainer, JSON.stringify(this.data))
      $rootScope.$broadcast('session_updated', { action: 'updated' })
      var sessionObject = JSON.parse($window.localStorage.getItem(this.localStorageContainer))
    },

    /* SESSION STATES - FAILD, HISTORY, NEW  */

    // Keeping this here -- not used
    watchUnload () {
      $window.onbeforeunload = (event: any) => {
        // *sessionBreadCrumb* console.log(`session_service > watchUnload() :: EVENT`)
      }
    },

    saveColdCallSessionState ($currentRoute: any) {
      // *sessionBreadCrumb* console.log(`session_service > saveColdCallSessionState()`, $currentRoute)
      let navigationEventType = this.navigationEventType()
      let assembledState = {
        activeRoute: undefined,
        activeOrganization: undefined
      }

      // save "navigate" type calls to coldCallSessionState
      // do nothing for "reload", the user has previousSessionState,
      // post authenticat will use that if possible.
      if (navigationEventType === 'navigate') {
        let full_path_string = location.pathname + location.search

        // let full_path_string = $currentRoute.url
        // _.each($currentRoute.pathParams, (value: any, key: any) => {
        //   full_path_string = full_path_string.replace(`:${key}`, value)
        // })
        assembledState.activeRoute = full_path_string

        // extract organization_id param and save
        // if ($currentRoute.params.hasOwnProperty('organization_id')) {
        //   // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: any; }' is not assignable to type 'und... Remove this comment to see the full error message
        //   assembledState.activeOrganization = {
        //     id: $currentRoute.params.organization_id
        //   }
        // }

        // save it to the data object
        this.data.coldCallSessionState = assembledState
      }

      // *sessionBreadCrumb* console.log(`session_service > saveColdCallSessionState()`, this.data.coldCallSessionState)
    },

    navigationEventType () {
      // performance api not supported in all browsers :(
      let navigationEvents :any = performance.getEntriesByType('navigation')
      let initialNavigationEvent = navigationEvents[0]
      return initialNavigationEvent.type
    },

    suspendSession () {
      // PREPARE
      // save values for post logout actions
      var old_session_data = angular.copy(this.data)

      // SUSPEND
      // terminate session with API, remove tokens.
      authenticationService.logout()
      // save the information
      var suspended = angular.copy(this.data)
      this.data = this.blankSessionData()
      this.data.suspendedSessionState = suspended
      // redirect
      this.goTo('/main')
      // *sessionBreadCrumb* utils.log(`session_service > suspendSession() :: SUCCESS`, 'warning')

      // CLEANUP
      // keep the sso meta so a user may reauthenticate
      this.data.sso_domained = old_session_data.sso_domained
      this.data.sso_idp = old_session_data.sso_idp
      // wait for a user to reauthenticate
      this.waitForToken()
    },

    previousSessionStateApplicable () {
      if (
        this.data.previousSessionState &&
        this.data.previousSessionState.activeUser &&
        this.data.previousSessionState.activeUser.id === this.data.activeUser.id
      ) {
        return true
      } else {
        return false
      }
    },

    // The default happy path if there is nothing to restore
    defaultSessionState () {
      let activeOrg = undefined

      try {
        if (this.data.activeUser.organizations.length === 1 && this.data.activeUser.has_only_one_organization_and_no_descendants) {
          activeOrg = this.data.activeUser.organizations[0]
        }
      } catch (ex) {}
      return {
        activeOrganization: activeOrg,
        activeRoute: '/home'
      }
    },

    configureSession (sessionObject: any) {
      // *sessionBreadCrumb* console.log('session_service > configureSession()', sessionObject)
      this.configureOrganization(sessionObject).then((success: any) => {
        this.configureRoute(sessionObject)
        this.configureSurveyMode(sessionObject)
        $rootScope.storeService.initalize()
      })
    },

    configureOrganization (sessionObject: any) {
      // *sessionBreadCrumb* console.log('session_service > configureOrganization()')
      const deferred = $q.defer()

      // TODO: validate that user still has the organization since last login.
      // && this.data.activeUser.organizations include sessionObject.activeOrganization
      if (sessionObject.activeOrganization) {
        let organizationKeys = (Object.keys(sessionObject.activeOrganization))
        let weOnlyHaveTheOrganizationId = (organizationKeys.length === 1 && organizationKeys[0] === 'id')

        if (weOnlyHaveTheOrganizationId) {
          this.retrieveOrganization(sessionObject.activeOrganization.id).then((success: any) => {
            this.setOrganization(success)
            deferred.resolve(success)
          })

        // set the saved organization from the last session
        } else {
          this.setOrganization(sessionObject.activeOrganization)
          deferred.resolve(sessionObject.activeOrganization)
        }

      // sessionObject.activeOrganization is undefined
      } else {
        // we have nothing to resolve
        deferred.resolve(true)
      }

      return deferred.promise
    },

    configureRoute (sessionObject: any) {
      // restoreActiveRoute
      if (
        sessionObject.activeRoute &&
        (sessionObject.activeRoute !== '/user_services/sso') &&
        (sessionObject.activeRoute !== '/user_services/login') &&
        (sessionObject.activeRoute !== '/main')
      ) {
        this.goTo(sessionObject.activeRoute)
      } else {
        this.goTo('/home')
      }
    },

    configureSurveyMode (sessionObject: any) {
      // restoreNagivationLockState
      if (
        sessionObject.documentReview &&
        sessionObject.documentReview.navigationLocked
      ) {
        this.data.documentReview.navigationLocked = sessionObject.documentReview.navigationLocked
      }

      // restore entityFilterKey
      if (
        sessionObject.documentReview &&
        sessionObject.documentReview.entityFilterKey
      ) {
        this.data.documentReview.entityFilterKey = sessionObject.documentReview.entityFilterKey
      }
    },

    /* AUTHETHICATION */

    escortUnauthorizedVisitor () {
      // If the user knows where they are going, we can help them get there
      // a quick bit of auth will set them straight.
      if (this.data.coldCallSessionState) {
        // *sessionBreadCrumb* console.log(`session_service > escortUnauthorizedVisitor() :: TO LOGIN`)
        this.redirectToAuthenticationPage()
      } // otherwise, they will be fine on the public side of the app.
    },

    redirectToAuthenticationPage () {
      // if the session is loaded and we have a sso
      if (this.data.sso_domained === true) {
        // Redirect & start SSO workflow. See sso.js
        // *sessionBreadCrumb* console.log(`session_service > redirectToAuthenticationPage() :: SSO`)
        this.goTo('/user_services/sso')
        // success will be broadcasted

      // if the session is loaded without a sso
      } else if (this.data.sso_domained === false) {
        // Redirect to login page, allow user to enter credentials.
        // *sessionBreadCrumb* console.log(`session_service > redirectToAuthenticationPage() :: UN/PW`)
        this.goTo('/user_services/login')
        // success will be broadcasted

      // The session is still loading, we need to wait and re-run
      } else { // set_sso_idp is still running
        // wait for sso promise to finish
        // *sessionBreadCrumb* console.log(`session_service > redirectToAuthenticationPage() :: WAIT`)
        $rootScope.$watch(() => $rootScope.session.data.sso_domained, (new_value: any, old_value: any, scope: any) => {
          if (new_value !== undefined) {
            this.redirectToAuthenticationPage()
          }
        }, true)
      }
    },

    logOut () {
      // PREPARE
      // save values for post logout actions
      var old_session_data = angular.copy(this.data)

      // LOGOUT
      this.goTo('/main')
      authenticationService.logout()
      this.data = this.blankSessionData()
      $rootScope.$broadcast('clear_global_alerts')
      $window.localStorage.removeItem(this.localStorageContainer)
      // *sessionBreadCrumb* utils.log(`session_service > logOut() :: SUCCESS`, 'error')

      // CLEANUP
      // keep the sso meta so a user may reauthenticate
      this.data.sso_domained = old_session_data.sso_domained
      this.data.sso_idp = old_session_data.sso_idp
      // wait for a user to reauthenticate
      this.waitForToken()
    },

    /* TOKEN */

    useToken (token: any) {
      // *sessionBreadCrumb* console.log(`session_service > useToken('${token}')`)

      // Use the token in all request headers.
      $http.defaults.headers = {
        common: {
          'Authorization': `Token token=${token}`
        }
      }
      // save token in sessionStorage for Cypress testing workflow
      if (ENV.NAME === 'local') {
        $window.sessionStorage.setItem('cypressToken', token)
      }
    },

    clearToken () {
      this.data.token = undefined
    },

    removeTokenCookie () {
      document.cookie = 'token=; expires=Thu, 01 Jan 70 00:00:00 UTC; path=/; domain=.' + ENV.COMPLYOS_DOMAIN + ';'
    },

    /* SSO */

    get_sso_idp () {
      return this.data.sso_idp
    },

    set_sso_idp () {
      const possible_sso_idp = $location.$$host.split('.')[0]
      const params = {
        'filter[name]': possible_sso_idp
      }
      return Restangular.one(
        'sso_idps/find'
      ).get(params).then((response: any) => {
        // api will render :no_content if sso_idp not found
        if (response !== undefined) {
          this.data.sso_idp = response.plain()
          this.data.sso_domained = true
          return this.data.sso_idp
        } else {
          this.data.sso_domained = false
        }
      });
    },

    sso_domained () {
      return this.data.sso_domained
    },

    /* ROUTES */

    watchRoute () {
      let _this = this;
      $transitions.onSuccess({}, () => {
        _this.data.activeRoute = $location.url()
      });
    },

    goTo (route: any) {
      // *sessionBreadCrumb* console.log(`session_service > goTo('${route}')`)
      $location.url(route)
    },

    /* URL PARAMS */

    // angular does not like query params, like at all...
    // we process and save them for later use.
    // please remove params from the object after they have been used.
    watchUrlParams () {
      $rootScope.$on('$locationChangeStart', (event: any, newUrl: any, oldUrl: any) => {
        // extract the query from the url
        var parser = document.createElement('a')
        parser.href = newUrl
        var queryString = parser.search.substring(1)
        // take the queryString and extract the key value pairs
        if (queryString !== '') {
          var pairs = queryString.split('&')
          for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=')
            var key = pair[0]
            var value = decodeURIComponent(pair[1])
            // shove the param into the parmamWorkaround object
            this.data.urlParams[key] = value
            // console.log('urlParams:', this.data.urlParams)
          }
        }
      })
    },

    paramCategoryExists (category: any) {
      // console.log('this.data.urlParams', this.data.urlParams)
      return _.some(Object.keys(this.data.urlParams), (param: any) => {
        return param.includes(`${category}_`)
      });
    },

    paramExists (category: any, param: any) {
      return _.includes(Object.keys(this.data.urlParams), `${category}_${param}`)
    },

    getParam (category: any, param: any) {
      return this.data.urlParams[`${category}_${param}`]
    },

    deleteParam (category: any, param: any) {
      delete this.data.urlParams[`${category}_${param}`]
    },

    /* USER */

    getUser () {
      return this.data.activeUser
    },

    setUser (user: any) {
      if (typeof user.plain !== 'undefined') {
        user = user.plain()
      }
      this.data.activeUser = user
      // *sessionBreadCrumb* console.log(`session_service > setUser('${this.data.activeUser.email}')`)
    },
    
    setProfile (profile: any) {
      this.data.activeUser.profile = profile
    },

    activeUserPresent () {
      return this.getUser() !== undefined
    },

    /* PERMISSIONS */

    userCan (permission: any) {
      return this.getUserPermission(permission)
    },

    userCannot (permission: any) {
      return !this.getUserPermission(permission)
    },

    getUserPermission (permission_string: any) {
      const user = this.getUser()
      if (user) {
        const permission_boolean = user.permission[permission_string]
        if (
          (permission_boolean === undefined) ||
          !(typeof permission_boolean === 'boolean')
        ) {
          // *sessionBreadCrumb* console.log(`permission '${permission_string}' is not processable`)
          return false
        } else {
          return permission_boolean
        }
      }
    },

    /* ORGANIZATION */

    getOrganization () {
      return this.data.activeOrganization
    },

    activeOrganizationPresent () {
      return this.getOrganization() !== undefined && this.getOrganization() !== null
    },

    activeOrganizationInteractable () {
      if (this.activeOrganizationPresent()) {
        if (
          this.getOrganization().state &&
          this.getOrganization().state.archived
        ) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },

    setOrganization (organization: any, preventFilterChange = false, organizationHasChildren = false) {
      // use restangular plain() to reveal base object.
      if ((organization !== null) && (typeof organization.plain !== 'undefined')) {
        organization = organization.plain()
      }

      this.data.activeOrganization = organization
      if (this.data.activeOrganization && this.data.activeOrganization.id && !preventFilterChange) {
        // TODO: remove when we go organization centric

        var filters = filterService.getCached()
        if (!Array.isArray(filters)) {
          filters = []
        }

        let newOrgPill: any = {
          isUnique: false,
          isRequired: false,
          isHidden: false,
          Field: 'organizations_ids',
          Op: constants.FilterType.Select,
          displayName: 'Organization',
          displayValue: this.data.activeOrganization.name,
          Value: this.data.activeOrganization.id,
          dateCreated: dayjs()['$d'],
          isLocked: this.isNavigationLocked() ? true : false
        }
        if (organizationHasChildren) {
          newOrgPill.includeDescendants = organizationHasChildren
          newOrgPill.descendantsName = 'organizations'
        }

        filters = arrayFunctions.pushAndOverwrite(filters, newOrgPill, (existing: any, update: any) => (existing.Field === update.Field))
        filterService.setCache(filters)

        $rootScope.$broadcast('reloadFiltersFromCache')
      }
      if (organizationHasChildren) {        
        this.data.activeOrganization = null
      }
      // depricated, were moving away from active org id, pull this out when pages no longer use it
      $rootScope.$broadcast('activeOrgIdUpdated')
    },

    retrieveOrganization (organization_id: any) {
      return Restangular.one(
        'organizations',
        organization_id
      ).get().then((response: any) => {
        return response
      }, (error: any) => error);
    },

    /* NAVIGATION */
    // When navigation is locked, (only implemented on the SurveyMode page)
    // all menu items except logout are hidden.

    isNavigationLocked () {
      return !!this.data.documentReview.navigationLocked
    },

    lockNavigation () {
      this.data.documentReview.navigationLocked = true
      $rootScope.$broadcast('lockFilter')

      // this will cause the browser back button to perform a page refresh rather
      // than navigating back once the page has been locked
      $window.onpopstate = function (e: any) {
        $window.history.forward(1)
      }
    },

    unlockNavigation () {
      this.data.documentReview.navigationLocked = false
    },
  };
}])
