import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngLandingMain',
  templateUrl: './landing-main.component.html'
})

export class LandingMainComponent implements OnInit {
  // Properties
  private ssoAuthenticationButtons: Array<any>;
  private localAuthenticationButtons: Array<any>;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('ENV') private ENV,
  ) { 
  }

  
  ngOnInit(): void {
    this.ssoAuthenticationButtons = [
      {
        title: 'Login: Single Sign On',
        class: 'btn-primary',
        visible: () => {
          return (
            !this.$rootScope.session.activeUserPresent() &&
            this.$rootScope.session.data.sso_domained
          )
        },
        action: () => {
          this.$rootScope.session.goTo('/user_services/sso')
        }
      }
    ]
    this.localAuthenticationButtons = [
      {
        title: 'Login: Username/Password',
        class: 'btn-link',
        visible: () => {
          return (
            !this.$rootScope.session.activeUserPresent() &&
            this.ENV.NAME !== 'staging' &&
            this.ENV.NAME !== 'production'
          )
        },
        action: () => {
          this.$rootScope.session.goTo('/user_services/login')
        }
      }
    ]
  }
}

angular
  .module('complyosClient')
  .directive('ngLandingMain', downgradeComponent({ component: LandingMainComponent }) as angular.IDirectiveFactory)
