import { Component, Inject, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngApiSearchOption',
  templateUrl: './api-search-option.component.html'
})
export class ApiSearchOptionComponent implements OnInit {
  // Properties
  @Input() selectNextNode: (node: any) => void;
  @Input() node: any;
  @Input() enableDescendantChecking: boolean;
  @Input() descendantsName: string;
  @Input() descendantsPrefix: string;
  @Input() displayNameKey: string;
  @Input() valueKey: string;

  private nodeTreeBuilder = { isLoading: true, isPopoutOpen: false, searchInput: '', nodePath: [], currentNodeList: [], maxItemCount: 150 };
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
  ) { 
    this.helpService = angular.copy(helpService)
    this.fortyCore = angular.copy(fortyCore)
  }

  ngOnInit(): void {
  }

  showChildrenDescendantCheckingHelpers = () => {
    return this.enableDescendantChecking && Array.isArray(this.node.children) && (this.node.children.length > 0 || this.node.has_children == true)
  }
}

angular
  .module('complyosClient')
  .directive('ngApiSearchOption', downgradeComponent({ component: ApiSearchOptionComponent }) as angular.IDirectiveFactory)
