// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('complyosPermissionsController', ['$scope', 'loadingStateService', 'Restangular', 'utils', function (
//   $scope: any,
//   loadingStateService: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = () => {
//     /* LOADING FEEDBACK */
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.loadingStateService.init()

//     /* Queries */
//     $scope.rp = { query: '' }

//     getPermissions()
//   }

//   const getPermissions = () =>
//     Restangular.all('roles').getList().then(
//       function (response: any) { // success
//         $scope.loadingStateService.process_success(response)
//         $scope.roles = response
//         assemble_role_attributes()
//         assemble_role_permissions()
//       }
//       , function (error: any) { // error
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     )

//   var assemble_role_permissions = function () {
//     $scope.role_permissions = []
//     if ($scope.roles[0]) {
//       return _.each($scope.roles[0].permissions, function (value: any, key: any) {
//         if (
//           (key !== 'id') &&
//           (key !== 'permissible_id') &&
//           (key !== 'permissible_type') &&
//           (key !== 'permissions_create') &&
//           (key !== 'created_at') &&
//           (key !== 'updated_at')
//         ) {
//           return $scope.role_permissions.push(key)
//         }
//       });
//     }
//   }

//   var assemble_role_attributes = function () {
//     $scope.role_attributes = []
//     if ($scope.roles[0]) {
//       return _.each($scope.roles[0].plain(), function (value: any, key: any) {
//         if (
//           (key !== 'id') &&
//           (key !== 'description') &&
//           (key !== 'title') &&
//           (key !== 'permissions')
//         ) {
//           return $scope.role_attributes.push(key)
//         }
//       });
//     }
//   }

//   init()
// }])
