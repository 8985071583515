import angular from "angular";

//- --------------------------------------------------------------------
//- NOTE: this service did not work when converted to the class-style service
//-     it has still been adapted to run in NG12+ entities, but just not as a 
//-     class.
//- --------------------------------------------------------------------

// export class LoadingStateService {
//   //static $inject = []; // no injects

//   // PROPERTIES
//   private loading_state: any;

//   constructor() {
//   }

//   loading_states: {
//     authenticating: {
//       name: 'authenticating',
//       icon: 'fa-cog fa-spin',
//       text: 'Redirecting through Ping Authentication'
//     },
//     loading: {
//       name: 'loading',
//       icon: 'fa-cog fa-spin',
//       text: 'Loading'
//     },
//     nocontent: {
//       name: 'nocontent',
//       icon: 'fa-circle-thin',
//       text: 'No Data Found'
//     },
//     unauthorized: {
//       name: 'unauthorized',
//       icon: 'fa-lock',
//       text: 'Permission Denied'
//     },
//     error: {
//       name: 'error',
//       icon: 'fa-exclamation-triangle',
//       text: 'Error loading Data'
//     },
//     content: {
//       name: 'content',
//       icon: null,
//       text: null
//     },
//     organizationRequired: {
//       name: 'organizationRequired',
//       icon: 'fa-building',
//       text: 'Organization Required'
//     },
//     query_too_large_warning: {
//       name: 'query_too_large_warning',
//       icon: 'fa-cog',
//       show_indicator: false
//     }
//   }

//   set_state(state: any) {
//     return this.loading_state = this.loading_states[state]
//   }

//   // make a get_current_state function and rename this to
//   // get_default_state to avoid confusion
//   get_state(state: any) {
//     return this.loading_states[state]
//   }

//   process_success(response: any) {
//     if ((response.status === 204) || (response.length === 0) || (response === {})) {
//       return this.set_state('nocontent')
//     } else {
//       return this.set_state('content')
//     }
//   }

//   process_error(error: any) {
//     switch (error.status) {
//       case 401: case 403:
//         return this.set_state('unauthorized')
//       default:
//         return this.set_state('error')
//     }
//   }


//   init(load_state: any) {
//     return this.set_state('loading')
//   }
// }

// angular
//   .module('complyosClient')
//   .service('loadingStateService', LoadingStateService);

//- --------------------------------------------------------------------
//- FOR REFERENCE:

export function LoadingStateService() {
  return {
    loading_states: {
      authenticating: {
        name: 'authenticating',
        icon: 'fa-cog fa-spin',
        text: 'Redirecting through Ping Authentication'
      },
      loading: {
        name: 'loading',
        icon: 'fa-cog fa-spin',
        text: 'Loading'
      },
      nocontent: {
        name: 'nocontent',
        icon: 'fa-circle-thin',
        text: 'No Data Found'
      },
      unauthorized: {
        name: 'unauthorized',
        icon: 'fa-lock',
        text: 'Permission Denied'
      },
      error: {
        name: 'error',
        icon: 'fa-exclamation-triangle',
        text: 'Error loading Data'
      },
      content: {
        name: 'content',
        icon: null,
        text: null
      },
      organizationRequired: {
        name: 'organizationRequired',
        icon: 'fa-building',
        text: 'Organization Required'
      },
      query_too_large_warning: {
        name: 'query_too_large_warning',
        icon: 'fa-cog',
        show_indicator: false
      }
    },

    set_state(state: any) {
      return this.loading_state = this.loading_states[state]
    },

    // make a get_current_state function and rename this to
    // get_default_state to avoid confusion
    get_state(state: any) {
      return this.loading_states[state]
    },

    process_success(response: any) {
      if ((response.status === 204) || (response.length === 0) || (Object.keys(response).length === 0)) {
        return this.set_state('nocontent')
      } else {
        return this.set_state('content')
      }
    },

    process_error(error: any) {
      switch (error.status) {
        case 401: case 403:
          return this.set_state('unauthorized')
        default:
          return this.set_state('error')
      }
    },


    init(load_state: any) {
      return this.set_state('loading')
    }

  };
}


angular
  .module('FortyAUServices')
  .service('loadingStateService', [LoadingStateService])

//- --------------------------------------------------------------------
//- FOR REFERENCE:

// complyosClient.factory('loadingStateService', function () {
//   return {
//     loading_states: {
//       authenticating: {
//         name: 'authenticating',
//         icon: 'fa-cog fa-spin',
//         text: 'Redirecting through Ping Authentication'
//       },
//       loading: {
//         name: 'loading',
//         icon: 'fa-cog fa-spin',
//         text: 'Loading'
//       },
//       nocontent: {
//         name: 'nocontent',
//         icon: 'fa-circle-thin',
//         text: 'No Data Found'
//       },
//       unauthorized: {
//         name: 'unauthorized',
//         icon: 'fa-lock',
//         text: 'Permission Denied'
//       },
//       error: {
//         name: 'error',
//         icon: 'fa-exclamation-triangle',
//         text: 'Error loading Data'
//       },
//       content: {
//         name: 'content',
//         icon: null,
//         text: null
//       },
//       organizationRequired: {
//         name: 'organizationRequired',
//         icon: 'fa-building',
//         text: 'Organization Required'
//       },
//       query_too_large_warning: {
//         name: 'query_too_large_warning',
//         icon: 'fa-cog',
//         show_indicator: false
//       }
//     },

//     set_state(state: any) {
//       return this.loading_state = this.loading_states[state]
//     },

//     // make a get_current_state function and rename this to
//     // get_default_state to avoid confusion
//     get_state(state: any) {
//       return this.loading_states[state]
//     },

//     process_success(response: any) {
//       if ((response.status === 204) || (response.length === 0) || (response === {})) {
//         return this.set_state('nocontent')
//       } else {
//         return this.set_state('content')
//       }
//     },

//     process_error(error: any) {
//       switch (error.status) {
//         case 401: case 403:
//           return this.set_state('unauthorized')
//         default:
//           return this.set_state('error')
//       }
//     },


//     init(load_state: any) {
//       return this.set_state('loading')
//     }

//   };
// })