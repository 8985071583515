/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('complyosCommunicationsController', [ '$uibModal', '$rootScope', '$scope', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
  $uibModal: any,
  $rootScope: any,
  $scope: any,
  confirmationModalFactory: any,
  flashService: any,
  fortyCore: any,
  loadingStateService: any,
  objectManager: any,
  Restangular: any,
  utils: any
) {
  const init = function () {
    
    $scope.flashService = angular.copy(flashService)
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.processingStateService = fortyCore.processingStateService
    return get_communications()
  }

  var get_communications = function () {
    $scope.loadingStateService.set_state('loading')
    const params = {
      type: 'Feed::Communication'
    }

    return Restangular.all('notes').get('', params).then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      return $scope.communications = success
    }

    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    });
  }

  $scope.openEditCommunicationModal = (communicationId: any) => {
    let communicationText = ''
    const index = $scope.communications.findIndex(c => c.id === communicationId);
    if (index !== -1){
      communicationText = $scope.communications[index].text
    }
    $scope.openCommunicationModal('edit-single', communicationId, communicationText)
  }

  $scope.canUserCreate = () => {
    return $rootScope.session.getUser().user_role.role.weight >= 1000
  }

  $scope.openCommunicationCreateModal = () => {
    $scope.openCommunicationModal('create')
  }

  $scope.openCommunicationModal = function (ui_setting: any, communicationId = null, communicationText = null) {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modals/new_communication_modal.html',
      controller: 'communicationsModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            ui_setting,
            communicationId,
            communicationText
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then((result: any) => get_communications());
  }

  return init()
}])
