/* eslint-disable
    no-undef,
*/
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.controller('authentiwaitController', ['utils', 'session', '$rootScope', function (
  utils: any,
  session: any,
  $rootScope: any
) {
  const init = function () {
    // *sessionBreadCrumb* utils.log(`authentiwait > init() :: WAITED`, 'warning')

    // "session" is avaliable to us on load, this page, and most of the app.
    // we explicitly wait for it to load in order to use native angular
    // functions in our initialization process. this may not be super
    // useful here, (or anywhere else), but it helps the app work
    // console.log(session)
  }

  return init()
}])
