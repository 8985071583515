// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * DS207: Consider shorter variations of null checks
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationDashboardSnapshotController', ['$q', '$rootScope', '$scope', '$uibModal', 'filterService', 'loadingStateService', 'Restangular', function (
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   filterService: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
    
//     $scope.snapshotLoadingStateService = angular.copy(loadingStateService)
//     $scope.snapshotLoadingStateService.init()
//     $scope.date_pills = {}
//     $scope.outstandingPromises = []
//     $scope.deferredRefresh = null
    
//     $scope.reportLoadingStateService = angular.copy(loadingStateService)
//     const custom_states = {
//       ready: {
//         icon: 'fa-download',
//         text: 'Download Report'
//       }
//     }
//     $scope.reportLoadingStateService.loading_states = _.merge($scope.reportLoadingStateService.loading_states, custom_states)
//     $scope.reportLoadingStateService.set_state('ready')

//     watchPillFilter()
//   }

//   var executeRefresh = (pills: any) => {
//     $scope.abortAllPromises($scope.outstandingPromises)
//     setTimeout(() => get_analytic_data(pills), 50)
//   }

//   var get_analytic_data = function (pills: any) {
//     $scope.snapshotLoadingStateService.set_state('loading')

//     let query_params = {
//       model: 'Binder',
//       method: 'snapshot',
//       param_data: {}
//     }
//     query_params.param_data = $rootScope.storeService.stringifyTagParams(
//       $rootScope.storeService.pillsToObject(pills)
//     )
//     query_params.param_data['sorted'] = ['risk']

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then(function (success: any) {
//       $rootScope.$broadcast('pagination-results-updated', success.pagination)
//       $scope.snapshotLoadingStateService.process_success(success)
//       $scope.organization_snapshot = success
//     }
//     , (error: any) => {
//       $scope.snapshotLoadingStateService.set_state('query_too_large_warning')
//       $scope.deferredRefresh = executeRefresh.bind(null, pills)
//     })
//   }

//   $scope.abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   // $scope.download_report = () => {
//   //   $scope.reportLoadingStateService.set_state('loading')

//   //   let query_params = {
//   //     report: 'requirements_snapshot',
//   //     param_data: {}
//   //   }
//   //   query_params.param_data = $rootScope.storeService.stringifyTagParams(
//   //     $rootScope.storeService.pillsToObject(filterService.getCached())
//   //   )

//   //   Restangular.setFullResponse(true).one(`reports/requirements_snapshot`).get(query_params).then(function (response) {
//   //     var report_file = new Blob([response.data], { type: 'text/csv' })
//   //     var report_name = `requirements_snapshot_report_${dayjs().format('YYYY-MM-DD')}.csv`
//   //     saveAs(report_file, report_name)

//   //     $scope.reportLoadingStateService.set_state('ready')
//   //   }, function (error) {
//   //     console.log(error)
//   //     $scope.reportLoadingStateService.set_state('error')
//   //   }).finally(() => {
//   //     // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//   //     // Reset the full response setting
//   //     Restangular.setFullResponse(false)
//   //   })
//   // }

//   $scope.get_report_button_class = () => {
//     if ($scope.reportLoadingStateService.loading_state.name === 'error') {
//       return 'btn-warning'
//     } else {
//       return 'btn-default'
//     }
//   }

//   var watchPillFilter = () => {
//     var refresh = function (e: any, pills: any) {
//       $scope.abortAllPromises($scope.outstandingPromises)
//       setTimeout(() => get_analytic_data(pills), 50)
//       get_date_pill(pills)
//     }
//     $scope.$on('refresh-dashboard-widgets', refresh)
//     $scope.$on('cancel-pending-filter-queries', () => $scope.abortAllPromises($scope.outstandingPromises))
//   }

//   var get_date_pill = function (pills: any) {
//     let datePill = pills.find((p: any) => p.Field === 'date')
//     if (datePill) {
//       $scope.date_pills = JSON.parse(JSON.stringify(pills.find((p: any) => p.Field === 'date')))
//       $scope.date_pills.Value.date_range_begin = dayjs($scope.date_pills.Value.date_range_begin).format('MM-DD-YYYY')
//       $scope.date_pills.Value.date_range_end = dayjs($scope.date_pills.Value.date_range_end).format('MM-DD-YYYY')
//     }
//   }

//   // fake a entry object to pass to the entry modal
//   const fake_it_till_you_make_it = function (entry_snapshot: any) {
//     let fake_entry
//     return fake_entry = { id: entry_snapshot.entry_id, binder: { user_assigned_id: entry_snapshot.binder.user_assigned_id } }
//   }

//   // update the orginal entry snapshot
//   const you_made_it = (entry_snapshot: any, entry_object: any) => entry_snapshot.status = entry_object.status

//   // TODO: this may not be needed, Remove if this is true.
//   $scope.openEntryModal = function (entry_snapshot: any, ui_setting: any) {
//     const entry_object = fake_it_till_you_make_it(entry_snapshot)
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/entry_modal.html',
//       controller: 'entryModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(entry_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     return modalInstance.result.then((result: any) => you_made_it(entry_snapshot, result)
//       , function (reason: any) {
//       });
//   }

//   return init()
// }])
