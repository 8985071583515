import { Component, Inject, OnInit } from '@angular/core';
import { FlashService } from '../../services/flash_service.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'ngReports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private reportPreview: any;
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('loadingStateService') loadingStateService: any,
    private helpService: helpService
  ) { 
    this.flashService = angular.copy(flashService)
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  private reportsList: Array<any> = [
    {
      pretty_name: 'Dashboard Completion Report',
      report_name: 'dashboard_completion',
      description: 'Completion Percentage and Details of each Task Date for your Organization(s).',
      enabled_filters: ['date', 'organizations_ids', 'interval_ids', 'reference_id', 'requirement_ids', 'surveying_ids', 'tag_ids'],
      required_filters: ['organizations_ids'],
      help_text: {
        organizations_ids: "Must select at least one organization"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'Dashboard Detailed Report',
      report_name: 'dashboard_detail',
      description: 'Detailed information about Configured Requirements for your Organization(s).',
      enabled_filters: ['date', 'organizations_ids', 'interval_ids', 'reference_id', 'requirement_ids', 'surveying_ids', 'tag_ids'],
      required_filters: ['organizations_ids', 'date'],
      help_text: {
        organizations_ids: "Must select at least one organization"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'Dashboard Overview Report',
      report_name: 'dashboard_organization_overview',
      description: 'Dashboard-Organization Overview that is displayed and Estimated Survey Date.',
      enabled_filters: ['date', 'organizations_ids', 'interval_ids', 'reference_id', 'requirement_ids', 'surveying_ids', 'tag_ids'],
      required_filters: ['organizations_ids', 'date'],
      help_text: {
        organizations_ids: "Must select at least one organization"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'Form Response Report',
      report_name: 'form_response',
      description: 'This report can be run for only one Requirement at a time, and one or multiple Organizations, to review the answers and comments entered when the user was completing a Form attached to a Requirement.',
      enabled_filters: ['organizations_ids', 'single_requirements_id'],
      required_filters: ['organizations_ids', 'single_requirements_id'],
      help_text: {
        organizations_ids: "Must select at least one organization",
        single_requirements_id: "Must select one requirement"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'Store Requirements List Report',
      report_name: 'requirements_export',
      description: 'List of Requirements, and requirement information  that is available for your Line of Business.  This report should be used to determine requirements that are Aligned with Engineering Central.',
      enabled_filters: ['organizations_ids', 'interval_ids', 'reference_id', 'requirement_ids', 'surveying_ids', 'tag_ids', 'published_visibility_store'],
      required_filters: ['organizations_ids'],
      help_text: {
        organizations_ids: "Must select at least one organization"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'User Activity Report',
      report_name: 'user_activity',
      description: 'This report can be used to view User information for your Organization, including the last time a User was active in Complyos.',
      enabled_filters: ['tag_ids'],
      required_filters: ['tag_ids'],
      help_text: { 
        tag_ids: "Must select at least one tag"
      },
      permissions: ['all']
    },
    {
      pretty_name: 'Requirement Review Report',
      report_name: 'requirement_review_window',
      description: 'This report can be used to view when a requirement was last updated and the next date it needs to be reviewed by.',
      enabled_filters: ['review_window', 'interval_ids', 'requirement_ids', 'reference_id', 'surveying_ids', 'tag_ids', 'published_visibility'],
      permissions: ['System Admin', 'Requirement Author']
      // required_filters: ['organizations_ids'],
      // help_text: { 
      //   organizations_ids: "Must select at least one organization"
      // }
    }
  ]

  ngOnInit(): void {
    const custom_states = {
      loading: {
        text: 'Loading Database Assoiciations'
      },
      ready: {
        name: 'ready',
        icon: 'fa-check',
        text: `Choose report above to run`
      },
      error: {
        name: 'report_error',
        text: 'Error processing report'
      }
    }

    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.loadingStateService.set_state('ready')
    this.filterReportsOnPermissions()
  }

  filterReportsOnPermissions = () => {
    this.reportsList = this.reportsList.filter(report => this.canViewReport(report))
  }

  canViewReport = (report: any) => {
    if(report.permissions[0] == 'all') {
      return true
    } else {
      return report.permissions.includes(this.session.getUser().user_role.role.title)
    }
  }

  /* CRUD */

  launchReportFilterModal = (reportConfig: any) => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/ajs-report-modal-adapter.html',
      controller: 'ajsReportModalAdapter',
      size: 'lg',
      resolve: {
        modalOptions() {
          return { object: reportConfig }
          }
        }
    })
    return modalInstance.result.then(result => {
      this.reportPreview = result.csvPreview
      this.reportPreview.reportName = result.reportName
      this.reportPreview.reportFile = result.reportFile
      this.loadingStateService.process_success(result)
    })
  }

  downloadReport = () => {
    FileSaver.saveAs(this.reportPreview.reportFile, this.reportPreview.reportName)
    return 'report exported'
  }

  identify = (index: number, item: any): string => item.index;
}

angular
  .module('complyosClient')
  .directive('ngReports', downgradeComponent({ component: ReportsComponent }) as angular.IDirectiveFactory)
