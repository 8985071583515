/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq,
  yoda
*/
'use strict'


angular.module('complyosClient').directive('paginatorFull', [
  'fortyCore', 'constants', 'arrayFunctions', 'helpService',
  (fortyCore, constants, arrayFunctions, helpService) => ({
    templateUrl: 'views/directives/pagination/paginator_full.html',
    restrict: 'E',

    scope: {
      paginationParams: '=',
      paginationResults: '<',
      isSmallPaginatorOnly: '<',
      paginationChangedCallback: '&'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
    },

    registerFunctions ($scope: any) {
      $scope.goToPage = (page: any) => {
        if ($scope.canNavigateGlobal(page)) {
          $scope.paginationParams.page = page;
          $scope.paginationChangedCallback()
        }
      }

      $scope.canNavigateGlobal = (globalPage: any) => {
        if (1 <= globalPage && globalPage <= $scope.paginationResults.pageCount) {
          return true
        } else {
          return false
        }
      }

      $scope.canNavigateRelative = (relativePage: any) => {
        let targetPage = $scope.paginationResults.currentPage + relativePage

        return $scope.canNavigateGlobal(targetPage)
      }

      $scope.hasPageSize = () => {
        let hasPageSize = false
        if ($scope.paginationParams && $scope.paginationParams.pageSize && $scope.paginationParams.pageSize > 0) {
          hasPageSize = true
        }

        return hasPageSize
      }

      return $scope;
    }
  })

])
