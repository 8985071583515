/* eslint-disable
    no-undef,
*/
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('requirementsBinderController', ['$scope', '$state', 'Restangular', 'utils', function (
  $scope: any,
  $state: any,
  Restangular: any,
  utils: any
) {
  const init = function () {
    $scope.$on('binder_update', (event: any, data: any) => {
      $scope.binder = data
    })
    getBinder()
  }

  var getBinder = function () {
    // there might allready be a binder, dont get a duplicate.
    if (!$scope.binder) {
      Restangular.all('binders').get($state.params.binder_id).then((success: any) => {
        $scope.binder = success
      }, (error: any) => {
        utils.log(error)
      })
    }
  }

  return init()
}])
