import { Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { FlashService } from '../../services/flash_service.service';
import { objectManager } from '../../../scripts/services/ajs-object_manager';
import * as Restangular from '../../../vendor/restangular/restangular'
import * as _ from 'lodash';
import { AbstractControl, ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractControlWarn, FormControlWarn } from 'app/_ng/extensions/AbstractControlWarning';
import { composeSort, maxLength } from 'angular-ui-router';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';

@Component({
  selector: 'ngTaggingManager', // ng-tagging-manager
  templateUrl: './tagging-manager.component.html',
  styleUrls: ['./tagging-manager.component.scss']
})
export class TaggingManagerComponent implements OnInit, OnDestroy {
  // Injected Services
  private loadingStateService: any;
  private flashService: any;


  @Input() allowCreate: boolean = false;
  @Input() allowRead: boolean = false;      // I dont think this is used
  @Input() allowUpdate: boolean = false;    // I dont think this is used
  @Input() allowDestroy: boolean = false;
  @Input() hasError: any = false;
  @Input() pickTagsAccess: any;
  @Input() roleSelected: any;
  @Input() uiSetting: any;
  @Input() filterByLobs: any;
  @Input() stageTaggings: any;
  @Input() taggableId: any;
  @Input() taggableType: string;
  @Input() formModel: any;
  @Input() ngRequired: boolean = undefined;
  @Input() minsuggestedlength: any;

  // Properties
  parentForm: FormGroup
  form: FormGroup
  formReady = false
  // private tagForm: FormGroup;
  ValidationHelpers = ValidationHelpers

  // private tm: any = {};
  private outstandingPromises: any = [];
  private unregister_ajs_tag_save_listener: any


  //ugly reference
  public that = this

  constructor(
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    public controlContainer: ControlContainer,
    @Inject(FlashService) flashService: FlashService,
    @Inject('loadingStateService') loadingStateService: any,
    private objectManager: objectManager,
    private Restangular: Restangular,
    private elm: ElementRef
  ) {
    this.flashService = angular.copy(flashService)
    this.loadingStateService = angular.copy(loadingStateService)
    loadingStateService.init()

    // this.addNgValidators()
  }

  ngOnInit(): void {
    this.addFormFields();
    this.startWatchers()
    let abort = this.$q.defer()
  }
  ngOnDestroy(): void {
    // Angular $scope does not get destroyed when using tabs
    // must manually unregister
    if (this.unregister_ajs_tag_save_listener) {
      this.unregister_ajs_tag_save_listener()
    }
  }

  addFormFields = () => {
    this.parentForm = this.controlContainer.control as FormGroup
    this.parentForm.addControl('tagFormGroup', new FormGroup({}))
    this.form = this.parentForm.get('tagFormGroup') as FormGroup

    this.form.addControl('tags', new FormControl('TAGS_PROXY', [
      this.required,
      this.minlength,
      this.maxlength,
      this.warning_minSugguestedLength
    ]))
    
    this.formReady = true;
  }

  private tm: any = {
    that: this,

    tags: [],
    // filtered_tags: [1],   //TODO: just a test
    tag_query: '',
    tags_configured: false,
    no_filter_query: true,
    flat_tags: [],
    tags_to_show: true,
    has_error: this.hasError,

    warnings: {
      test: 'test',
      warning: 'waaarnings'
    },

    pending_transactions: 0,
    failed_transactions: 0,

  }

  tm_initialize() {
    // TODO: $formModel
    this.tm.ng_model_value = this.formModel
    // TODO: $formModel
    this.tm.users_tags = this.formModel

    this.tm.taggable_id = this.taggableId
    this.tm.tags = []
    // this.get_tags()
    // this.add_validators()
    // this.add_warners()
    this.watch_ng_model()
    this.listen_for_start_event()
    this.watch_for_tags_to_stage()

    // this.addNgValidators()

    // $formModel.$validate() causes modelValue to go undefined
    // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
    // TODO: $formModel
    // return $formModel.$options = {
    //   allowInvalid: true
    // }
  }

  search_show() {
    if (this.messages_no_role_selected()) {
      return false // hide
    } else if (this.messages_no_tags_necessary()) {
      return false
    } else {
      return !this.messages_no_tags_configured()
    }
  }

  messages_show() {
    return this.messages_no_role_selected() ||
      this.messages_no_tags_necessary() ||
      this.messages_no_tags_configured() ||
      this.messages_no_tags_match()
  }

  messages_no_role_selected() {
    return !this.roleSelected &&
      (this.roleSelected !== undefined)
  } // the second part differentiates between user and requirement modal

  messages_no_tags_necessary() {
    return !this.pickTagsAccess &&
      (this.pickTagsAccess !== undefined)
  } // the second part differentiates between user and requirement modal

  messages_no_tags_configured() {
    return !this.messages_no_tags_necessary() &&
      !this.allowCreate &&
      !this.allowDestroy &&
      this.tm &&
      this.tm.users_tags &&
      (this.tm.users_tags.length === 0)
  }

  messages_no_tags_match() {
    return this.tm.tag_query &&
      this.tm &&
      this.tm.filtered_tags &&
      !this.tm.filtered_tags.length
  }

  table_show() {
    if (!this.messages_no_role_selected() &&
      !this.messages_no_tags_necessary() &&
      this.tm &&
      this.tm.filtered_tags &&
      this.tm.filtered_tags.length
    ) {
      return true
    }
  }

  check_warning_state() {
    return _.isEmpty(this.tm.warnings)
  }



  get_tags() {
    if (Array.isArray(this.filterByLobs) && this.filterByLobs.length > 0) {
      const params = {
        "lob[]": this.filterByLobs.map((lob: any) => lob.title)
      }

      this.loadingStateService.set_state('loading')
      this.abortAllPromises(this.outstandingPromises)
      let abort = this.$q.defer()
      this.outstandingPromises.push(abort)

      return this.Restangular.all('tags/show_lob_children_tags').withHttpConfig({ timeout: abort.promise }).getList(params).then(
        (success: any) => {
          this.loadingStateService.process_success(success)
          let tags_result = success.plain()
          this.tm.tags = tags_result
          this.filter_tags_at_level(this.tm.tags)
          // this.tm.filtered_tags = this.tm.tags.filter(t => this.tag_filter(t))
          this.tm.flat_tags = this.flatten_tree(tags_result)
          this.stageDuplicateTags()
          // TODO: $formModel
          // return $formModel.$validate()
          // this.addNgValidators()
          // this.tagForm.markAllAsTouched();
          this.tagEditor.updateValueAndValidity()
        }, (error: any) => console.log(error));
    } else {
      return this.Restangular.all('tags').getList().then(
        (success: any) => {
          this.loadingStateService.process_success(success)
          let tags_result = success.plain()
          this.tm.tags = tags_result
          this.filter_tags_at_level(this.tm.tags)
          // this.tm.filtered_tags = this.tm.tags.filter(t => this.tag_filter(t))
          this.tm.flat_tags = this.flatten_tree(tags_result)
          this.stageDuplicateTags()
          // TODO: $formModel
          // return $formModel.$validate()
          // this.addNgValidators()
          // this.tagForm.markAllAsTouched();
          this.tagEditor.updateValueAndValidity()
        }, (error: any) => console.log(error));
    }
  }

  taggings_include(tag: any) {
    // return _.some($formModel.$modelValue, tagging => tagging.tag.id === tag.id)
    // return _.some(this.tm.ng_model_value, (tagging: any) => tagging.tag.id === tag.id);
    return _.some(this.formModel, (tagging: any) => tagging.tag.id === tag.id);
  }

  get_tag_icon = (tag: any) => {
    let icon

    if (tag.pending_action) {
      if (tag.pending_action === 'create') {
        icon = 'fa fa-plus-square'
      } else {
        icon = 'fa fa-minus-square-o'
      }
    } else { // there are no pending_actions
      if (this.taggings_include(tag)) {
        icon = 'fa fa-check-square'
      } else {
        icon = 'fa fa-square-o'
      }
    }

    return icon
  }

  /* FILTER */

  flatten_tree(array: any) {
    let flat_array: any = []

    _.each(array, (item: any) => {
      flat_array.push(item)

      if (item['children'] && (item['children'].length > 0)) {
        return flat_array = flat_array.concat(
          this.flatten_tree(item['children'])
        )
      }
    })

    return flat_array
  }

  tag_passes_filter = (tag: any) => {
    const search_string = this.tm.tag_query.toLowerCase()
    let match = false

    // create and destroy filter
    if (this.allowCreate || this.allowDestroy) {
      if (search_string === '') {
        match = true
      } else if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
        match = true
      } else if (search_string.toLowerCase() === 'tagged') {
        if ((tag.pending_action === 'create') || (this.tm.taggings_include(tag) && (tag.pending_action !== 'destroy'))) {
          match = true
        }
      }

      // read only filter
    } else {
      if (search_string) {
        if (
          this.taggings_include(tag) &&
          ((tag.title.toLowerCase()).indexOf(search_string) >= 0)
        ) {
          match = true
        }
      } else {
        if (this.taggings_include(tag)) {
          match = true
        }
      }
    }

    if (match) {
      this.tm.tags_to_show = true
    }

    return match
  }

  tag_query_changed_debounced = _.debounce(function (value) {
    this.tag_query_changed(value)
  }, 350)

  tag_query_changed = (value) => {
    this.tm.tag_query = value
    this.filter_tags_at_level(this.tm.tags)
  }

  filter_tags_at_level = (tags: Array<any>) => {
    this.tm.filtered_tags = tags.filter(t => this.tag_filter(t))
  }

  // allows for filtering of tags based on the view mode, and permissions
  // if editing then show all tags, and allow "tagged" query
  // if viewing then only the tagged show up. searchs still works
  tag_filter = (tag: any) => {
    // recursive function that checks the tag and children
    // against tag_passes_filter function
    // takes args
    //   - tag: object checking against
    // returns: true or false

    var tag_or_descendent_passes_filter = (tag: any) => {
      let match = false

      if (this.tag_passes_filter(tag)) {
        match = true
      }

      if ((match === false) && tag.children) {
        // Some (Alias: any) Returns true if any of the values in the list
        // pass the predicate truth test. Short-circuits and stops
        // traversing the list if a true element is found.
        match = _.some(tag.children, (tag: any) => tag_or_descendent_passes_filter(tag))
      }

      return match
    }

    // ui = 'read'
    // if !$scope.tm.taggings_include(tag) && !$scope.allowCreate
    //   return false

    // the function that kicks it all off
    // check the top level tags, and recursively the children
    // with itself
    // takes args
    //   - tag: object checking against
    // returns: true or false
    return tag_or_descendent_passes_filter(tag)
  }

  /* ACTIONS */

  increment_pending = () => {
    return this.tm.pending_transactions++
  }

  decrement_pending = () => {
    this.tm.pending_transactions--
    return this.evaluate_transaction_status()
  }

  increment_failed = () => {
    return this.tm.failed_transactions++
  }

  // checks to see if there are any more pending_transactions
  // if not then we are done and can call emit_completion_event
  evaluate_transaction_status = () => {
    if (this.tm.pending_transactions === 0) {
      return this.emit_completion_event()
    }
  }

  // creates and sets pending_action attribute on the tag.
  // used for icon display
  // used in process_tags function
  // used for validation
  // sets pending_action to:
  //   - undefined if pending action is canceld
  //   - destroy   if tag was exsting and is no longer needed
  //   - create    if tag did not exist and is wanted
  stage_tag = (tag: any) => {
    if (this.uiSetting === 'read') {
      return 'in read mode'
    } else if (tag.pending_action) {
      tag.pending_action = undefined
    } else {
      if (this.taggings_include(tag)) {
        if (this.allowDestroy)
          tag.pending_action = 'destroy'
      } else {
        if (this.allowCreate)
          tag.pending_action = 'create'
      }
    }

    this.tagEditor.updateValueAndValidity()
  }

  //to be used when duplicating a requirement.
  // basically we just move the tags out of taggings_included, and 
  // into the staging area to be created on save
  stageDuplicateTags = () => {
    if (Array.isArray(this.stageTaggings) && this.stageTaggings.length < 1) {
      return
    }

    var stage_tags_and_children = (tags: any) => {
      _.each(tags, (tag: any) => {

        var tag_marked_for_duplication = _.some(this.stageTaggings, (tagging: any) => tagging.tag.id === tag.id);
        if (tag_marked_for_duplication) {
          tag.pending_action = 'create'
        }

        if (tag.children) {
          stage_tags_and_children(tag.children)
        }
      })
    }
    stage_tags_and_children(this.tm.tags)
  }

  // loops over all the tags, then recursively the children
  // with each tag it accesses any pending actions
  // if it needs to be created then it:
  //   - creates a tagging
  //   - sends the tagging to the create_tagging function
  // if it needs to be deleted then it:
  //   - looks up the tagging in $formModel.$modelValue
  //   - sends the tagging to the destroy_tagging function
  //
  // finally it calls evaluate_transaction_status function in the case
  // that there are no tags to process. Ensures @emit_completion_event
  // is called
  process_tags = () => {
    var collect_pending_tags_recursive = (tags: any) => {
      var pending_tags = []
      _.each(tags, (tag: any) => {
        if (tag.pending_action === 'create' || tag.pending_action === 'destroy') {
          pending_tags.push(tag)
        }

        if (tag.children) {
          var child_pending_tags = collect_pending_tags_recursive(tag.children)
          pending_tags = pending_tags.concat(child_pending_tags)
        }
      });
      return pending_tags
    }

    var process_pending_tags = (tags: any) => {
      _.each(tags, (tag: any) => {
        let tagging
        if (tag.pending_action === 'destroy') {
          tagging = _.find(this.formModel, (tagging: any) => tagging.tag.id === tag.id)
          this.destroy_tagging(tagging)
          tag.pending_action = undefined
        }

        if (tag.pending_action === 'create') {
          tagging = {
            tag_id: tag.id,
            // used for better error messages
            // tag_title not accepted by api
            tag_title: tag.title,
            taggable_id: this.tm.taggable_id,
            taggable_type: this.taggableType
          }
          this.create_tagging(tagging)
          tag.pending_action = undefined
        }
      });
    }

    // process all the top level tags, and recursively the children
    var pending_tags = collect_pending_tags_recursive(this.tm.tags)
    process_pending_tags(pending_tags)

    return this.evaluate_transaction_status()
  }

  // creates tagging.
  // increment_pending action counter
  // on success, pushes tagging into $formModel.$modelValue
  // on error, emits alert to flashService
  // finally decrement_pending action counter
  create_tagging = (tagging: any) => {
    this.increment_pending()
    return this.Restangular.all('taggings').post(tagging).then(
      (success: any) => this.objectManager.array_action(this.formModel, success.plain(), 'merge'), 
      (error: any) => {
        const alert = {
          name: `create_error_${tagging.tag_title}`,
          dismissable: true,
          class: 'alert-warning',
          icon: 'fa-exclamation-triangle',
          strong: 'Error:',
          message: 'There was an error creating ' +
            `tagging '${tagging.tag_title}'. ` +
            `${this.flashService.default_alert_by_code(error.status).message}`
        }
        this.flashService.emit_alert(this.$scope, alert)
        return this.increment_failed()
      }).finally(() => {
        return this.decrement_pending()
      }
    );
  }

  // destroy tagging.
  // increment_pending action counter
  // on success, removes tagging from $formModel.$modelValue
  // on error, emits alert to flashService
  // finally decrement_pending action counter
  destroy_tagging = (tagging: any) => {
    this.increment_pending()
    return this.Restangular.one('taggings', tagging.id).remove().then(
      (success: any) => this.objectManager.array_action(this.formModel, tagging, 'remove'), 
      (error: any) => {
        const alert = {
          name: `delete_error_${tagging.tag.title}`,
          dismissable: true,
          class: 'alert-warning',
          icon: 'fa-exclamation-triangle',
          strong: 'Error:',
          message: 'There was an error removing ' +
            `tagging '${tagging.tag.title}'. ` +
            `${this.flashService.default_alert_by_code(error.status).message}`
        }
        this.flashService.emit_alert(this.$scope, alert)
        return this.increment_failed()
      }
    ).finally(() => {
      return this.decrement_pending()
    });
  }





  /* WATCHERS */

  /* WATCHERS, LISTENERS AND EMITTERS */
  listen_for_start_event = () => {
    // this.$scope.$on("$destroy", () => {console.log("$scope destroyed")})

    if (this.uiSetting != 'read') {
      this.unregister_ajs_tag_save_listener = this.$scope.$on('tagging_manager_start', (event: any, data: any) => {
        // set the requirement_id because previously we may not have had it
        this.tm.taggable_id = data.id
  
        if (!this.tm.taggable_id) {
          // tags may be attached to a note in a list- try find the id before moving on
          this.tm.taggable_id = this.try_find_id_in_list(data)
        }
        // reset the failed_transactions so that we
        // have a chance of fixing the issue and trying again
        this.tm.failed_transactions = 0
        // now on to the saving
        return this.process_tags()
      });
    }
  }

  watch_for_tags_to_stage = () => {
    return this.$scope.$watch(() => this.stageTaggings, (new_value: any, old_value: any, scope: any) => {
      if (Array.isArray(this.stageTaggings) && this.stageTaggings.length > 0) {
        this.stageDuplicateTags()
      }
    });
  }

  try_find_id_in_list = (list: any, current_level = 0, max_depth = 4) => {
    let l1 = list[Object.keys(list)[0]]

    if (l1.id) {
      return l1.id
    } else {
      return this.try_find_id_in_list(l1, current_level++, max_depth)
    }
  }

  emit_completion_event = () => {
    const return_object = {
      manager: 'tagging_manager',
      object: this.formModel,
      fault: this.tm.failed_transactions++ > 0
    }
    return this.$scope.$emit('tagging_manager_finished', return_object)
  }

  // this syncronizes the $formModel.$modelValue and @ng_model_value
  // we use @ng_model_value in the view exclusively
  watch_ng_model = () => {
    // TODO: $formModel
    // return this.$scope.$watch(() => $formModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
    //   this.$scope.tm.ng_model_value = $formModel.$modelValue
    // });
    return this.$scope.$watch(() => this.formModel, (new_value: any, old_value: any, scope: any) => {
      this.tm.ng_model_value = this.formModel
    });
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  startWatchers = () => {
    // there is a delay between the time the directive is ready and formModel is
    // we watch for ready and run code once
    let ng_model_ready = false
    this.$scope.$watch(() => this.formModel, (new_value: any, old_value: any, scope: any) => {
      if (ng_model_ready === false) {
        this.tm_initialize()
        return ng_model_ready = true
      }
    })

    this.$scope.$watch('filterByLobs',
      (newValue: any, oldValue: any) => {
        this.get_tags()
      }, true
    )

    // TODO move this into it's own function, call on init
    this.$scope.$watch(() => this.minsuggestedlength, (new_value: any, old_value: any, scope: any) => {
      if (new_value !== old_value) {
        // TODO: $formModel
        // return $formModel.$validate()
      }
    });
  }

  // ---------------------------- START [OLD VAL/WARN] ----------------------------
  /* VALIDATIONS */

  // counts all the tags for validation
  // loops over all the tags, then recursively the children
  // includes all the tags that are:
  //  - pending to be created
  //  - currently tagged, and not pending a destroy action
  // does not count:
  //  - tags that are not tagged
  //  - tags that are tagged and pending a destroy action
  //
  // Returns a integer
  get_validaton_length = () => {
    let count = 0

    var count_tags_and_children = (tags: any) => _.each(tags, (tag: any) => {
      if (tag.pending_action === 'create') {
        count = count + 1
      } else if (
        this.taggings_include(tag) &&
        (tag.pending_action !== 'destroy')
      ) {
        count = count + 1
      }

      if (tag.children) {
        return count_tags_and_children(tag.children)
      }
    })

    // count top level tags, then children
    count_tags_and_children(this.tm.tags)

    return count
  }
  // ----------------------------  END  [OLD VAL/WARN] ----------------------------

  // ---------------------------- START [new Ng Validation] ----------------------------
  get tagEditor(): FormControlWarn {
    return <FormControlWarn>this.form.get('tags');
  }

  required = (c: FormControlWarn) => {
    // const { required } = $attributes
    var required = false;
    if (this.ngRequired !== undefined) {
      required = this.ngRequired;
    } else {
      var requiredRaw = this.elm.nativeElement.getAttribute('required')
      if (requiredRaw === true || requiredRaw === 1 || requiredRaw === "true") {
        required = true;
      }
    }
    if (required) {
      return this.get_validaton_length() <= 0 ? { required: "This field is required" } : true
    } else {
      return true
    }
  }
  // ensure get_validaton_length is greater than minlength
  minlength = (c: FormControlWarn) => {
    const minlength = Number(this.elm.nativeElement.getAttribute('minlength'))
    if (Number.isNaN(minlength) || !minlength) {
      return true
    } else {
      return this.get_validaton_length() < minlength ? { minlength: `At least ${minlength} tags required` } : true
    }
  }

  // ensure get_validaton_length is less than maxlength
  maxlength = (c: FormControlWarn) => {
    const maxlength = Number(this.elm.nativeElement.getAttribute('maxlength'))

    if (Number.isNaN(maxlength) || !maxlength) {
      return true
    } else {
      return this.get_validaton_length() > maxlength ? { maxlength: `Maximum allowed tags: ${maxlength}` } : true
    }
  };

  warning_minSugguestedLength = (c: FormControlWarn) => {
    if (!this.minsuggestedlength) {
      return null
    }

    let tagsCount = this.get_validaton_length()

    if (!c.warnings)
      c.warnings = {}

    if (tagsCount < this.minsuggestedlength) {
      c.warnings["warning_minSugguestedLength"] = `Please select at least ${this.minsuggestedlength} tag(s)`
    } else if (_.has(c.warnings, 'warning_minSugguestedLength')) {
      delete c.warnings.warning_minSugguestedLength
      if (!Object.keys(c.warnings).length) {
        c.warnings = null;
      }
    }
    return null;
  }
  // warning_maxSugguestedLength = (c: FormControlWarn) => {
  //   if (!this.minsuggestedlength) {
  //     return null
  //   }

  //   let tagsCount = this.get_validaton_length()

  //   if (!c.warnings)
  //     c.warnings = {}

  //   if (tagsCount > (this.minsuggestedlength - 2)) {
  //     c.warnings["warning_maxSugguestedLength"] = `Please select at most ${this.minsuggestedlength - 2} tag(s)`
  //   } else if (_.has(c.warnings, 'warning_maxSugguestedLength')) {
  //     delete c.warnings.warning_maxSugguestedLength
  //     if (!Object.keys(c.warnings).length) {
  //       c.warnings = null;
  //     }
  //   }
  //   return null;
  // }

  // isValidSynchronus = (formFeild) => {
  //   formFeild.updateValueAndValidity()
  //   return formFeild.valid
  // }
  // ----------------------------  END  [new Ng Validation] ----------------------------
}

angular
  .module('complyosClient')
  .directive('ngTaggingManager', downgradeComponent({ component: TaggingManagerComponent }) as angular.IDirectiveFactory)


//- --------------------------------------------------------------------
//- USAGE

              // ng-tagging-manager(
              //   ,[allow-create]="session.userCan('taggings_create') && ui_setting != 'read'"
              //   ,[allow-destroy]="session.userCan('taggings_destroy') && ui_setting != 'read'"
              //   ,[ng-model]="requirement.taggings"
              //   ,[taggable-id]="requirement.id"
              //   ,[taggable-type]="Requirement"
              //   ,[stage-taggings]="taggingsToStage"
              //   ,[ng-required]="requirement.published"

              //   ,[has-error]="requirement_form.field_taggings.$invalid && requirement_form.failed_submission"
              //   ,[name]="field_taggings"

              // )
              //   //- required
              //   //- ,[minsuggestedlength]="3"
              //   //- minlength="3"
              //   //- maxlength="8"


//- --------------------------------------------------------------------
//- FOR REFERENCE

// complyosClient.directive('taggingManager', [
//   '$rootScope',
//   'confirmationModalFactory',
//   'flashService',
//   'fortyCore',
//   'objectManager',
//   'Restangular',
//   '$q',
//   'loadingStateService',
//   function (
//     $rootScope: any,
//     confirmationModalFactory: any,
//     flashService: any,
//     fortyCore: any,
//     objectManager: any,
//     Restangular: any,
//     $q: any,
//     loadingStateService: any
//   ) {
//     return {
//       restrict: 'E',
//       templateUrl: 'views/directives/tagging_manager.html',
//       require: 'ngModel',
//       scope: {
//         allowCreate: '=',
//         allowRead: '=',
//         allowUpdate: '=',
//         allowDestroy: '=',
//         taggableId: '=',
//         taggableType: '@',
//         hasError: '=?',
//         pickTagsAccess: '=',
//         roleSelected: '=',
//         minsuggestedlength: '=?',
//         uiSetting: '=',
//         filterByLobs: "=",
//         stageTaggings: "="
//       },

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized
//       //    attribute names and their corresponding attribute values.
//       //  $ngModel is the actual model that this directive interacts with
//       link($scope: any, $element: any, $attributes: any, $ngModel: any) {
//         $scope.outstandingPromises = []

//         $scope.loadingStateService = angular.copy(loadingStateService),
//           $scope.loadingStateService.init()
//         $scope.tm = {

//           tags: [],
//           tag_query: '',
//           tags_configured: false,
//           no_filter_query: true,
//           flat_tags: [],
//           tags_to_show: true,
//           has_error: $scope.hasError,

//           initialize() {
//             this.ng_model_value = $ngModel.$modelValue
//             this.users_tags = $ngModel.$modelValue
//             this.taggable_id = $scope.taggableId
//             this.tags = []
//             // this.get_tags()
//             this.add_validators()
//             this.add_warners()
//             this.watch_ng_model()
//             this.listen_for_start_event()
//             this.watch_for_tags_to_stage()


//             // $ngModel.$validate() causes modelValue to go undefined
//             // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
//             return $ngModel.$options = {
//               allowInvalid: true
//             }
//           },

//           check_warning_state() {
//             return _.isEmpty(this.warnings)
//           },



//           get_tags() {
//             if (Array.isArray($scope.filterByLobs) && $scope.filterByLobs.length > 0) {
//               const params = {
//                 "lob[]": $scope.filterByLobs.map((lob: any) => lob.title)
//               }

//               $scope.loadingStateService.set_state('loading')
//               $scope.abortAllPromises($scope.outstandingPromises)
//               let abort = $q.defer()
//               $scope.outstandingPromises.push(abort)

//               return Restangular.all('tags/show_lob_children_tags').withHttpConfig({ timeout: abort.promise }).getList(params).then(
//                 (success: any) => {
//                   $scope.loadingStateService.process_success(success)
//                   this.tags = success
//                   this.flat_tags = $scope.tm.flatten_tree(success)
//                   this.stageDuplicateTags()
//                   return $ngModel.$validate()
//                 }, (error: any) => console.log(error));
//             } else {
//               return Restangular.all('tags').getList().then(
//                 (success: any) => {
//                   $scope.loadingStateService.process_success(success)
//                   this.tags = success
//                   this.flat_tags = $scope.tm.flatten_tree(success)
//                   this.stageDuplicateTags()
//                   return $ngModel.$validate()
//                 }, (error: any) => console.log(error));
//             }
//           },

//           taggings_include(tag: any) {
//             // return _.some($ngModel.$modelValue, tagging => tagging.tag.id === tag.id)
//             return _.some($scope.tm.ng_model_value, (tagging: any) => tagging.tag.id === tag.id);
//           },

//           get_tag_icon(tag: any) {
//             let icon

//             if (tag.pending_action) {
//               if (tag.pending_action === 'create') {
//                 icon = 'fa fa-plus-square'
//               } else {
//                 icon = 'fa fa-minus-square-o'
//               }
//             } else { // there are no pending_actions
//               if ($scope.tm.taggings_include(tag)) {
//                 icon = 'fa fa-check-square'
//               } else {
//                 icon = 'fa fa-square-o'
//               }
//             }

//             return icon
//           },

//           search: {
//             show() {
//               if ($scope.tm.messages.no_role_selected()) {
//                 return false // hide
//               } else if ($scope.tm.messages.no_tags_necessary()) {
//                 return false
//               } else {
//                 return !$scope.tm.messages.no_tags_configured()
//               }
//             }
//           },

//           messages: {
//             show() {
//               return $scope.tm.messages.no_role_selected() ||
//                 $scope.tm.messages.no_tags_necessary() ||
//                 $scope.tm.messages.no_tags_configured() ||
//                 $scope.tm.messages.no_tags_match()
//             },

//             no_role_selected() {
//               return !$scope.roleSelected &&
//                 ($scope.roleSelected !== undefined)
//             }, // the second part differentiates between user and requirement modal

//             no_tags_necessary() {
//               return !$scope.pickTagsAccess &&
//                 ($scope.pickTagsAccess !== undefined)
//             }, // the second part differentiates between user and requirement modal

//             no_tags_configured() {
//               return !$scope.tm.messages.no_tags_necessary() &&
//                 !$scope.allowCreate &&
//                 !$scope.allowDestroy &&
//                 $scope.tm &&
//                 $scope.tm.users_tags &&
//                 ($scope.tm.users_tags.length === 0)
//             },

//             no_tags_match() {
//               return $scope.tm.tag_query &&
//                 $scope.tm &&
//                 $scope.tm.filtered_tags &&
//                 !$scope.tm.filtered_tags.length
//             }
//           },

//           table: {
//             show() {
//               if (!$scope.tm.messages.no_role_selected() &&
//                 !$scope.tm.messages.no_tags_necessary() &&
//                 $scope.tm &&
//                 $scope.tm.filtered_tags &&
//                 $scope.tm.filtered_tags.length
//               ) {
//                 return true
//               }
//             }
//           },

//           /* FILTER */

//           flatten_tree(array: any) {
//             let flat_array: any = []

//             _.each(array, function (item: any) {
//               flat_array.push(item)

//               if (item['children'] && (item['children'].length > 0)) {
//                 return flat_array = flat_array.concat(
//                   $scope.tm.flatten_tree(item['children'])
//                 )
//               }
//             })

//             return flat_array
//           },

//           tag_passes_filter(tag: any) {
//             const search_string = $scope.tm.tag_query.toLowerCase()
//             let match = false

//             // create and destroy filter
//             if ($scope.allowCreate || $scope.allowDestroy) {
//               if (search_string === '') {
//                 match = true
//               } else if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
//                 match = true
//               } else if (search_string.toLowerCase() === 'tagged') {
//                 if ((tag.pending_action === 'create') || ($scope.tm.taggings_include(tag) && (tag.pending_action !== 'destroy'))) {
//                   match = true
//                 }
//               }

//               // read only filter
//             } else {
//               if (search_string) {
//                 if (
//                   $scope.tm.taggings_include(tag) &&
//                   ((tag.title.toLowerCase()).indexOf(search_string) >= 0)
//                 ) {
//                   match = true
//                 }
//               } else {
//                 if ($scope.tm.taggings_include(tag)) {
//                   match = true
//                 }
//               }
//             }

//             if (match) {
//               $scope.tm.tags_to_show = true
//             }

//             return match
//           },

//           // allows for filtering of tags based on the view mode, and permissions
//           // if editing then show all tags, and allow "tagged" query
//           // if viewing then only the tagged show up. searchs still works
//           tag_filter(tag: any) {
//             // recursive function that checks the tag and children
//             // against tag_passes_filter function
//             // takes args
//             //   - tag: object checking against
//             // returns: true or false

//             var tag_or_descendent_passes_filter = function (tag: any) {
//               let match = false

//               if ($scope.tm.tag_passes_filter(tag)) {
//                 match = true
//               }

//               if ((match === false) && tag.children) {
//                 // Some (Alias: any) Returns true if any of the values in the list
//                 // pass the predicate truth test. Short-circuits and stops
//                 // traversing the list if a true element is found.
//                 match = _.some(tag.children, (tag: any) => tag_or_descendent_passes_filter(tag))
//               }

//               return match
//             }

//             // ui = 'read'
//             // if !$scope.tm.taggings_include(tag) && !$scope.allowCreate
//             //   return false

//             // the function that kicks it all off
//             // check the top level tags, and recursively the children
//             // with itself
//             // takes args
//             //   - tag: object checking against
//             // returns: true or false
//             return tag_or_descendent_passes_filter(tag)
//           },

//           /* ACTIONS */

//           pending_transactions: 0,
//           failed_transactions: 0,

//           increment_pending() {
//             return this.pending_transactions++
//           },

//           decrement_pending() {
//             this.pending_transactions--
//             return this.evaluate_transaction_status()
//           },

//           increment_failed() {
//             return this.failed_transactions++
//           },

//           // checks to see if there are any more pending_transactions
//           // if not then we are done and can call emit_completion_event
//           evaluate_transaction_status() {
//             if (this.pending_transactions === 0) {
//               return this.emit_completion_event()
//             }
//           },

//           // creates and sets pending_action attribute on the tag.
//           // used for icon display
//           // used in process_tags function
//           // used for validation
//           // sets pending_action to:
//           //   - undefined if pending action is canceld
//           //   - destroy   if tag was exsting and is no longer needed
//           //   - create    if tag did not exist and is wanted
//           stage_tag(tag: any) {
//             if ($scope.uiSetting === 'read') {
//               return 'in read mode'
//             } else if (tag.pending_action) {
//               tag.pending_action = undefined
//             } else {
//               if ($scope.tm.taggings_include(tag)) {
//                 tag.pending_action = 'destroy'
//               } else {
//                 tag.pending_action = 'create'
//               }
//             }

//             return $ngModel.$validate()
//           },

//           //to be used when duplicating a requirement.
//           // basically we just move the tags out of taggings_included, and 
//           // into the staging area to be created on save
//           stageDuplicateTags() {
//             if (Array.isArray($scope.stageTaggings) && $scope.stageTaggings.length < 1) {
//               return
//             }

//             var stage_tags_and_children = (tags: any) => {
//               _.each(tags, (tag: any) => {

//                 var tag_marked_for_duplication = _.some($scope.stageTaggings, (tagging: any) => tagging.tag.id === tag.id);
//                 if (tag_marked_for_duplication) {
//                   tag.pending_action = 'create'
//                 }

//                 if (tag.children) {
//                   stage_tags_and_children(tag.children)
//                 }
//               })
//             }
//             stage_tags_and_children(this.tags)
//           },

//           // loops over all the tags, then recursively the children
//           // with each tag it accesses any pending actions
//           // if it needs to be created then it:
//           //   - creates a tagging
//           //   - sends the tagging to the create_tagging function
//           // if it needs to be deleted then it:
//           //   - looks up the tagging in $ngModel.$modelValue
//           //   - sends the tagging to the destroy_tagging function
//           //
//           // finally it calls evaluate_transaction_status function in the case
//           // that there are no tags to process. Ensures @emit_completion_event
//           // is called
//           process_tags() {
//             var process_tags_and_children = (tags: any) => {
//               // loop through all the tags and deal with it
//               // https://www.youtube.com/watch?v=fZlEi8Kfsag&feature=youtu.be&t=242
//               return _.each(tags, (tag: any) => {
//                 let tagging
//                 if (tag.pending_action === 'destroy') {
//                   tagging = _.find($ngModel.$modelValue, (tagging: any) => tagging.tag.id === tag.id)
//                   this.destroy_tagging(tagging)
//                   tag.pending_action === undefined
//                 }

//                 if (tag.pending_action === 'create') {
//                   tagging = {
//                     tag_id: tag.id,
//                     // used for better error messages
//                     // tag_title not accepted by api
//                     tag_title: tag.title,
//                     taggable_id: this.taggable_id,
//                     taggable_type: $scope.taggableType
//                   }
//                   this.create_tagging(tagging)
//                   tag.pending_action === undefined
//                 }

//                 if (tag.children) {
//                   return process_tags_and_children(tag.children)
//                 }
//               });
//             }

//             // process all the top level tags, and recursively the children
//             process_tags_and_children(this.tags)

//             return this.evaluate_transaction_status()
//           },

//           // creates tagging.
//           // increment_pending action counter
//           // on success, pushes tagging into $ngModel.$modelValue
//           // on error, emits alert to flashService
//           // finally decrement_pending action counter
//           create_tagging(tagging: any) {
//             this.increment_pending()
//             return Restangular.all('taggings').post(tagging).then((success: any) => objectManager.array_action($ngModel.$modelValue, success, 'merge'), (error: any) => {
//               const alert = {
//                 name: `create_error_${tagging.tag_title}`,
//                 dismissable: true,
//                 class: 'alert-warning',
//                 icon: 'fa-exclamation-triangle',
//                 strong: 'Error:',
//                 message: 'There was an error creating ' +
//                   `tagging '${tagging.tag_title}'. ` +
//                   `${flashService.default_alert_by_code(error.status).message}`
//               }
//               flashService.emit_alert($scope, alert)
//               return this.increment_failed()
//             }).finally(() => {
//               return this.decrement_pending()
//             });
//           },

//           // destroy tagging.
//           // increment_pending action counter
//           // on success, removes tagging from $ngModel.$modelValue
//           // on error, emits alert to flashService
//           // finally decrement_pending action counter
//           destroy_tagging(tagging: any) {
//             this.increment_pending()

//             return Restangular.one('taggings', tagging.id).remove().then((success: any) => objectManager.array_action($ngModel.$modelValue, tagging, 'remove'), (error: any) => {
//               const alert = {
//                 name: `delete_error_${tagging.tag.title}`,
//                 dismissable: true,
//                 class: 'alert-warning',
//                 icon: 'fa-exclamation-triangle',
//                 strong: 'Error:',
//                 message: 'There was an error removing ' +
//                   `tagging '${tagging.tag.title}'. ` +
//                   `${flashService.default_alert_by_code(error.status).message}`
//               }
//               flashService.emit_alert($scope, alert)
//               return this.increment_failed()
//             }).finally(() => {
//               return this.decrement_pending()
//             });
//           },

//           /* VALIDATIONS */

//           // counts all the tags for validation
//           // loops over all the tags, then recursively the children
//           // includes all the tags that are:
//           //  - pending to be created
//           //  - currently tagged, and not pending a destroy action
//           // does not count:
//           //  - tags that are not tagged
//           //  - tags that are tagged and pending a destroy action
//           //
//           // Returns a integer
//           get_validaton_length() {
//             let count = 0

//             var count_tags_and_children = (tags: any) => _.each(tags, function (tag: any) {
//               if (tag.pending_action === 'create') {
//                 count = count + 1
//               } else if (
//                 $scope.tm.taggings_include(tag) &&
//                 (tag.pending_action !== 'destroy')
//               ) {
//                 count = count + 1
//               }

//               if (tag.children) {
//                 return count_tags_and_children(tag.children)
//               }
//             })

//             // count top level tags, then children
//             count_tags_and_children(this.tags)

//             return count
//           },

//           // pushes custom validators into model validators array
//           // works with angular form validation
//           add_validators() {
//             // ensure get_validaton_length is greater 0
//             $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
//               const { required } = $attributes
//               if ((required === false) || (required === undefined)) {
//                 return true
//               } else {
//                 return this.get_validaton_length() > 0
//               }
//             }

//             // ensure get_validaton_length is greater than minlength
//             $ngModel.$validators.minlength = (modelValue: any, viewValue: any) => {
//               if (!$attributes.minlength) {
//                 return true
//               } else {
//                 return this.get_validaton_length() >= $attributes.minlength
//               }
//             }

//             // ensure get_validaton_length is less than maxlength
//             return $ngModel.$validators.maxlength = (modelValue: any, viewValue: any) => {
//               if (!$attributes.maxlength) {
//                 return true
//               } else {
//                 return this.get_validaton_length() <= $attributes.maxlength
//               }
//             };
//           },

//           add_warners() {
//             // the object that we are putting our messages in
//             $ngModel.$warnings = {}

//             return $ngModel.$validators.minsuggestedlength = (modelValue: any, viewValue: any) => {
//               if ($scope.minsuggestedlength !== undefined) {
//                 const minsuggestedlength = `Please select at least ${$scope.minsuggestedlength} tag`
//                 // add or remove the warning message
//                 if (this.get_validaton_length() < $scope.minsuggestedlength) {
//                   if (!_.has($ngModel.$warnings, 'minsuggestedlength')) {
//                     $ngModel.$warnings.minsuggestedlength = minsuggestedlength
//                   }
//                 } else {
//                   if (_.has($ngModel.$warnings, 'minsuggestedlength')) {
//                     delete $ngModel.$warnings.minsuggestedlength
//                   }
//                 }

//                 // syncronate the warnings with the view
//                 this.warnings = $ngModel.$warnings
//               }

//               // returning true will keep this validator from causing
//               // the manager to error out. We only want a warning not a error
//               return true
//             };
//           },

//           /* WATCHERS */

//           /* WATCHERS, LISTENERS AND EMITTERS */
//           listen_for_start_event() {
//             return $scope.$on('tagging_manager_start', (event: any, data: any) => {
//               // set the requirement_id because previously we may not have had it
//               this.taggable_id = data.id

//               if (!this.taggable_id) {
//                 // tags may be attached to a note in a list- try find the id before moving on
//                 this.taggable_id = this.try_find_id_in_list(data)
//               }
//               // reset the failed_transactions so that we
//               // have a chance of fixing the issue and trying again
//               this.failed_transactions = 0
//               // now on to the saving
//               return this.process_tags()
//             });
//           },

//           watch_for_tags_to_stage() {
//             return $scope.$watch(() => $scope.stageTaggings, (new_value: any, old_value: any, scope: any) => {
//               if (Array.isArray($scope.stageTaggings) && $scope.stageTaggings.length > 0) {
//                 this.stageDuplicateTags()
//               }
//             });
//           },

//           try_find_id_in_list(list: any, current_level = 0, max_depth = 4) {
//             let l1 = list[Object.keys(list)[0]]

//             if (l1.id) {
//               return l1.id
//             } else {
//               return this.try_find_id_in_list(l1, current_level++, max_depth)
//             }
//           },

//           emit_completion_event() {
//             const return_object = {
//               manager: 'tagging_manager',
//               object: $ngModel.$modelValue,
//               fault: this.failed_transactions > 0
//             }
//             return $scope.$emit('tagging_manager_finished', return_object)
//           },

//           // this syncronizes the $ngModel.$modelValue and @ng_model_value
//           // we use @ng_model_value in the view exclusively
//           watch_ng_model() {
//             return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
//               $scope.tm.ng_model_value = $ngModel.$modelValue
//             });
//           }

//         }

//         $scope.abortAllPromises = (promiseArray: any) => {
//           if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//             promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//           }
//           promiseArray.length = 0
//         }

//         // there is a delay between the time the directive is ready and ngModel is
//         // we watch for ready and run code once
//         let ng_model_ready = false
//         $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
//           if (ng_model_ready === false) {
//             $scope.tm.initialize()
//             return ng_model_ready = true
//           }
//         })

//         $scope.$watch('filterByLobs',
//           function (newValue: any, oldValue: any) {
//             $scope.tm.get_tags()
//           }, true
//         )

//         // TODO move this into it's own function, call on init
//         return $scope.$watch(() => $scope.minsuggestedlength, function (new_value: any, old_value: any, scope: any) {
//           if (new_value !== old_value) {
//             return $ngModel.$validate()
//           }
//         });


//       }
//     };
//   }
// ])
