import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';

@Component({
  selector: 'ngQueryTooLargeWarning',
  templateUrl: './query-too-large-warning.component.html'
})
export class QueryTooLargeWarningComponent implements OnInit {
  @Input() isPopoutOpen: boolean;
  @Input() deferredFn: () => void;
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    private helpService: helpService,
  ) { 
  }

  ngOnInit(): void {
  }

  togglePopout = () => {
    this.isPopoutOpen = !this.isPopoutOpen
  }

  executeDeferredFn = () => {
    this.deferredFn()
  }
}

angular
  .module('complyosClient')
  .directive('ngQueryTooLargeWarning', downgradeComponent({ component: QueryTooLargeWarningComponent }) as angular.IDirectiveFactory)
