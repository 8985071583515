import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { version } from 'process';
import { RequirementTabsComponent } from '../requirement-tabs/requirement-tabs.component';
import angular from 'angular';
import { FlashService } from '../../../services/flash_service.service';

@Component({
  selector: 'ng-requirement-version-comparer',
  templateUrl: './requirement-version-comparer.component.html',
  styleUrls: ['./requirement-version-comparer.component.scss']
})
export class RequirementVersionComparerComponent implements OnInit {

  @Input() requirementId: number;
  @Input() uiSetting: string = 'read';
  @Input() flashServiceRef: FlashService
  @Input() modalProcessingStateServiceRef: any;
  @Output() onSaveCompleted = new EventEmitter();
  @Output() isEditValid = new EventEmitter<boolean>()
  @Output() isPauseValid = new EventEmitter<boolean>()

  @ViewChild('editableRequirement') editableRequirement: RequirementTabsComponent;

  // Injected Services
  public session: any;
  private loadingStateService: any;

  // Properties
  public requirement = null
  public selectedTabIndex = 0
  public leftVersionInfo = null
  public editRequirement

  // Configs
  PanelStyleConfigs = {
    editable: {
      true: {
        panelHeadingStyle: { background: '#2C3E50', padding: '0px' },
        panelHeadingTextStyle: { color: 'white', margin: '5px', fontWeight: '400', fontSize: '15px' }
      },
      false: {
        panelHeadingStyle: { padding: '0px' },
        panelHeadingTextStyle: { margin: '5px', fontWeight: '400', fontSize: '15px' }
      }
    }
  }

  constructor(
    @Inject('$rootScope') private $rootScope,
    private formBuilder: FormBuilder,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
  ) {
    this.session = $rootScope.session
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.getRequirement()
      .then(() => this.setEditRequirement())


  }

  isEditValidChanged = (isValid) => {
    this.isEditValid.emit(isValid)
  }
  isPauseValidChanged = (isValid) => {
    this.isPauseValid.emit(isValid)
  }

  getVersionName(versionInfo) {
    if (versionInfo.is_edit_state) {
      return 'Edit State'
    } else if (versionInfo.is_current_version) {
      return 'Current Version'
    } else if (versionInfo.is_snapshot) {
      return `v${versionInfo.version_number}`
    }
  }

  setEditRequirement = () => {
    if (this.requirement?.edit_state_id && this.uiSetting == 'update') {
      this.leftVersionInfo = {
        requirement_id: this.requirementId,
        is_edit_state: true,
        is_current_version: false,
        snapshot_id: null,
        date_modified: this.editRequirement?.updated_at
      }
    } else {
      this.leftVersionInfo = null
    }
  }
  onBaseRequirementUpdated = (updatedBaseRequirement) => {
    this.requirement = updatedBaseRequirement
    this.setEditRequirement()
    this.onSaveCompleted.emit()
  }

  getRequirement = () => {
    this.loadingStateService.set_state('loading')
    return this.Restangular.all('requirements').get(this.requirementId).then((success: any) => 
    {
      this.requirement = success.plain()
      this.loadingStateService.set_state('content')
    }, (error) => {
      this.flashServiceRef.process_error(error)
      this.loadingStateService.set_state('error')
    })
  }
  getEditRequirement = () => {
    this.loadingStateService.set_state('loading')
    return this.Restangular.one(`requirements/${this.requirementId}/load_or_create_edit_state`).get().then((success: any) => 
    {
      this.editRequirement = success.plain()
      this.$rootScope.$broadcast('requirementEditState', success.id)
      this.loadingStateService.set_state('content')
    }, (error) => {
      this.flashServiceRef.process_error(error)
      this.loadingStateService.set_state('error')
    })
  }
  startEditing = () => {
    this.getEditRequirement()
    .then(() => this.getRequirement()
      .then(() => this.setEditRequirement())
    )
  }
  clearSelectedInfo = (infoToClearName) => {
    this[infoToClearName] = null
  }
}
