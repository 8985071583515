// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('usersSetPasswordController', ['$state', '$scope', 'flashService', 'passwordService', 'Restangular', 'utils', function (
//   $state: any,
//   $scope: any,
//   flashService: any,
//   passwordService: any,
//   Restangular: any,
//   utils: any
// ) {
//   /* FLASHES */
  
//   $scope.flashService = angular.copy(flashService)

//   /* SCOPE */
//   $scope.ui = {
//     action: {
//       title: null,
//       message: null
//     },
//     user: {
//       password: {
//         messages: []
//       }
//     }
//   }

//   $scope.user = {}

//   /* INIT */
//   const initialise = function () {
//     $scope.user.service_token = $state.params.service_token

//     if ($state.params.action === 'activate') {
//       $scope.ui.action.title = 'Activate Account'
//       $scope.ui.action.message = 'Please enter your desired password'
//     }

//     if ($state.params.action === 'reset_password') {
//       $scope.ui.action.title = 'Reset Account Password'
//       return $scope.ui.action.message = 'Please enter your desired password'
//     }
//   }

//   initialise()

//   /* FORM */
//   $scope.submit_form = function (form: any, object: any) {
//     if (form.$valid) {
//       return saveObject(object)
//     } else {
//       return form.failed_submission = true
//     }
//   }

//   var saveObject = function (object: any) {
//     // console.log(object)

//     // Reset is the route we want to hit, Pass a token and a new password. It does a lookup and bam!
//     return Restangular.all('user_services/reset').post({ user: object }).then(function (response: any) {
//       const success_flash = {
//         strong: 'Success',
//         name: 'success',
//         dismissable: false,
//         class: 'alert-info',
//         message: 'Password has been updated'
//       }
//       return $scope.flashService.add_alert(success_flash)
//     }

//     , function (error: any) {
//       $scope.flashService.process_error(error)
//       return utils.log(error)
//     });
//   }

//   /* WATCHERS */
//   // Validation for the Password and strong standards
//   return $scope.$watch('user.password', function (newValue: any, oldValue: any) {
//     // A blank password will not need to be checked
//     if ((newValue === undefined) || (newValue === '') || (newValue === null)) {
//       return $scope.set_password_form.field_password.$setValidity('', false)

//     // if there is something in the password field, we check.
//     } else {
//       const checker = passwordService.checker(newValue)

//       if ((checker.strength >= 3) && (checker.length >= 8) && (checker.length <= 128)) {
//         $scope.set_password_form.field_password.$setValidity('', true)
//       } else {
//         $scope.set_password_form.field_password.$setValidity('', false)
//       }

//       // bubble up the messages from checker
//       return $scope.ui.user.password.messages = checker.messages
//     }
//   });
// }])
