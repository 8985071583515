// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationTasksToCompleteController', ['$q', '$rootScope', '$scope', 'arrayFunctions', 'helpService', 'loadingStateService', 'Restangular', function (
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   arrayFunctions: any,
//   helpService: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
//     $scope.previousPills = null
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     const custom_states = {
//       kudos: {
//         icon: 'fa-check-circle',
//         text: '100% on top of the work'
//       },
//       no_tasks: {
//         icon: 'fa-info-circle',
//         text: 'No Tasks to Display'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.outstandingPromises = []
//     $scope.deferredRefresh = null

    
//     $scope.helpService = angular.copy(helpService)

//     watchEntityFilter()
//   }

//   var get_analytic_data = function (pills: any) {
//     let query_params = {
//       model: 'Binder',
//       method: 'tasks_to_complete',
//       param_data: {}
//     }

//     $scope.loadingStateService.set_state('loading')
//     query_params.param_data = $rootScope.storeService.stringifyTagParams(
//       $rootScope.storeService.pillsToObject(pills)
//     )

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then(function (response: any) {
//       if (response.data['T'] === 0) {
//         $scope.loadingStateService.set_state('no_tasks')
//       }
//       else if ((response.data.open_entry_count === 0) && (response.data.past_due_entry_count === 0) && (response.data['1'] === 0) && (response.data['T'] !== 0)) {
//         $scope.loadingStateService.set_state('kudos')
//       } else {
//         $scope.loadingStateService.process_success(response)
//       }
//       $scope.fruitalytics = response.plain()
//     }, (error: any) => {
//       $scope.loadingStateService.set_state('query_too_large_warning')
//       $scope.deferredRefresh = executeRefresh.bind(null, pills)
//     })
//   }

//   var abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   var executeRefresh = (pills: any) => {
//     abortAllPromises($scope.outstandingPromises)
//     setTimeout(() => get_analytic_data(pills), 50)
//   }

//   var watchEntityFilter = () => {
//     var refresh = function (e: any, pills: any, resultsCount: any) {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted', 'pagination_params']) || $scope.previousPills === null
//       $scope.previousPills = pills
//       if (widgetShouldRefresh) {
//         if (resultsCount <= 30000) {
//           executeRefresh(pills)
//         } else {
//           $scope.loadingStateService.set_state('query_too_large_warning')
//           $scope.deferredRefresh = executeRefresh.bind(null, pills)
//         }
//       }
//     }
//     $scope.$on('refresh-dashboard-widgets', refresh)
//     $scope.$on('cancel-pending-filter-queries', () => abortAllPromises($scope.outstandingPromises))
//   }

//   return init()
// }])
