import * as angular from 'angular';
import _ from 'lodash';


export class treeManager {

  static $inject = ['objectManager'];
  constructor(
    private objectManager: any,
    ) {}

  public tree_manager: any = {}
  
  public update_object_in_tree = (tree: any, updated_object: any) => {
    const object = this.find_object_in_tree_by_id(tree, updated_object.id)

    if (object.parent_id !== updated_object.parent_id) {
      this.remove_object_from_tree(tree, object)
      return this.add_object_to_tree(tree, updated_object)
    } else {
      const { depth } = object
      _.extend(object, updated_object)
      return object.depth = depth
    }
  }

  public add_object_to_tree = (tree: any, object: any) => {
    if (object.parent_id) {
      const parent = this.find_object_in_tree_by_id(tree, object.parent_id)
      parent.children.push(object)
    } else {
      tree.push(object)
    }


    this.set_object_depth(tree, object)
  }

  public set_object_depth = (tree: any, object: any) => {
    if (object.parent_id) {
      const parent = this.find_object_in_tree_by_id(tree, object.parent_id)
      object.depth = parent.depth + 1
    } else {
      object.depth = 0
    }

    if (object.children) {
      return _.each(object.children, (child: any) => {
        return this.set_object_depth(tree, child)
      });
    }
  }

  public remove_object_from_tree = (tree: any, object: any) => {
    if (object.parent_id) {
      const parent = this.find_object_in_tree_by_id(tree, object.parent_id)
      return this.objectManager.array_action(parent.children, object, 'remove')
    } else {
      return this.objectManager.array_action(tree, object, 'remove')
    }
  }

  public find_object_in_tree_by_id = (tree: any, id: any) => {
    let target_object: any

    var check_object_and_children = function (object: any) {
      if (object.id === id) {
        target_object = object
      }

      if ((target_object === undefined) && object.children) {
        return _.each(object.children, (child: any) => check_object_and_children(child));
      }
    }

    _.each(tree, (object: any) => check_object_and_children(object))

    return target_object
  }

  public flatten_tree = (tree: any) => {
    const flattened_tree: any = []

    var recursively_flatten = function (branch: any) {
      _.each(branch, function (object: any) {
        flattened_tree.push(object)

        if (object.children) {
          return recursively_flatten(object.children)
        }
      })
    }

    recursively_flatten(tree)
    return flattened_tree
  }

  public set_show_property = (tree_list: any) => {
    // set default show property on each organization

    const flat_list = this.flatten_tree(tree_list)

    return _.each(flat_list, function (item: any) {
      item.show = false
      return 'cool'
    });
  }

  public recursively_assign_key = (tree: any, key: any, value: any) => {
    var assign = function (branch: any) {
      _.each(branch, function (object: any) {
        object[key] = value
        if (object.children) {
          return assign(object.children)
        }
      })
    }

    assign(tree)
  }
}

angular
  .module('complyosClient')
  .service("treeManager", treeManager);