/* eslint-disable
    camelcase,
    handle-callback-err,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'
var complyosClient = angular.module('complyosClient')

complyosClient.directive('noteManager', [
  '$rootScope',
  'flashService',
  'fortyCore',
  'Restangular',
  '$q',
  'helpService',
  function (
    $rootScope: any,
    flashService: any,
    fortyCore: any,
    Restangular: any,
    $q: any,
    helpService: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/note_manager.html',
      require: 'ngModel',
      scope: {
        allowCreate: '=',
        allowRead: '=',
        allowUpdate: '=',
        allowUpdateSingle: '=',
        allowWysiwyg: '=',
        defaultNote: '=',
        type: '@',
        noteableId: '=',
        parentId: '=',
        noteableType: '@',
        notesReadLabel: '@',
        hideNotesReadLabel: '=',
        noteCreateLabel: '@',
        hideNoteCreateLabel: '='
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.helpService = helpService
        $scope.flashService = flashService
        $scope.text = fortyCore.text

        $scope.nm = {

          initialize () {
            this.allow_create = $scope.allowCreate
            this.allow_read = $scope.allowRead
            this.allow_update = $scope.allowUpdate
            this.allow_update_single = $scope.allowUpdateSingle
            this.allow_wysiwyg = $scope.allowWysiwyg
            this.default_note = $scope.defaultNote
            this.failure = undefined
            this.ng_model_value = $ngModel.$modelValue
            this.new_note = {
              noteable_id: $scope.noteableId,
              noteable_type: $scope.noteableType,
              parent_id: $scope.parentId,
              type: $scope.type,
              text: undefined
            }
            if (this.default_note) {
              this.new_note.text = this.default_note
            }
            this.noteCreateLabel = $scope.noteCreateLabel ? $scope.noteCreateLabel : 'New Note'
            this.hideNoteCreateLabel = $scope.hideNoteCreateLabel

            this.notesReadLabel = $scope.notesReadLabel ? $scope.notesReadLabel : 'All Notes'
            this.hidenotesReadLabel = $scope.hidenotesReadLabel

            // TODO this placeholder buisness should be done way before it gets to the manager, move it.
            if ($scope.noteListLabel) {
              this.placeholder = `Provide a detailed description of what has changed on this requirement. \
For each change, the field that was changed and what the field has changed to should be included.`
            } else {
              this.placeholder = ''
            }

            this.add_validators()
            this.watch_for_text_change()
            this.watch_for_edit_change()
            this.watch_ng_model()
            this.listen_for_start_event()
            this.watch_for_default_note()

            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          format_placeholder_text (type: any) {
            const trimmed_type = $scope.text.trimPhrase(type, type.lastIndexOf(':') + 1, type.length)
            const new_placeholder = $scope.text.splitWords(trimmed_type, '(?=[A-Z])')
            return new_placeholder
          },

          begin_api_operations () {
            const promises = []
            // console.log @new_note
            // add save promise to the stack (if needed of course)
            if (this.new_note.text && (this.new_note.text.trim !== '')) {
              promises.push(this.create_note())
            }

            // add all the update promises to the stack
            for (let note of Array.from(this.ng_model_value)) {
              if (
                //@ts-ignore
                (note.old_text !== undefined) && // there is a note that we are editing
                //@ts-ignore
                (note.text.trim !== '') // and the change is not empty
              ) {
                promises.push(this.save_edits(note))
              }
            }

            // run each and every promise then finish
            return $q.all(promises).then((success: any) => {
              this.emit_completion_event()
            }
            , function (error: any) {
              // alerts should be emitted by the individual promise that failed
            }).finally(function () {
            });
          },

          create_note () {
            return Restangular.all('notes').post(this.new_note).then((success: any) => {
              this.ng_model_value.push(success)
              this.new_note.text = ''
            }
            , function (error: any) {
              flashService.emit_alert(
                $scope,
                flashService.default_alert_by_code(error.status)
              )
            }).finally(function () {
            });
          },

          edit_note (note: any) {
            return note.old_text = angular.copy(note.text)
          },

          abandon_edits (note: any) {
            note.text = angular.copy(note.old_text)
            return delete note.old_text
          },

          save_edits (note: any) {
            return Restangular.one('notes', note.id).patch(note).then(function (response: any) {
            }, (error: any) => flashService.emit_alert(
              $scope,
              flashService.default_alert_by_code(error.status)
            )
            ).finally(function () {
            });
          },

          attribution (note: any) {
            if (note.user !== undefined) {
              return `by ${note.user.profile.display_name}`
            } else {
              return ''
            }
          },

          check_yo_self (note: any) {
            if (note.user !== undefined) {
              if (note.user.profile.display_name === $rootScope.session.getUser().profile.display_name) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          },

          get_icon (note: any) {
            switch (note.category.toLowerCase()) {
              case 'activity': return 'fa-clock-o'
              case 'comment': return 'fa-comment-o'
              case 'review note': return 'fa-clipboard'
              default: return 'fa-sticky-note-o'
            }
          },

          /* VALIDATION */
          get_validaton_length () {
            // console.log "checking text length"
            let length = 0
            if (this.allow_create && this.new_note.text) {
              // console.log "the text is #{@new_note.text}"
              ({ length } = this.new_note.text)
            } else if (this.allow_update_single && this.ng_model_value && this.ng_model_value[0] && this.ng_model_value[0].text) {
              ({ length } = this.ng_model_value[0].text)
            }
            // console.log "length is #{length}"
            return length
          },

          add_validators () {
            return $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              // console.log "validating text length"
              if (($attributes.required === false) || ($attributes.required === undefined)) {
                // console.log "valid == true"
                return true
              } else {
                // console.log "valid == #{@get_validaton_length() > 0}"
                return this.get_validaton_length() > 0
              }
            };
          },

          watch_for_text_change () {
            return $scope.$watch(() => {
              return this.new_note.text
            }
            , (new_value: any, old_value: any, scope: any) =>
              // console.log "text length changed"
              $ngModel.$validate()
            );
          },
          watch_for_edit_change () {
            return $scope.$watch(() => {
              return this.new_note.text
            }
            , (new_value: any, old_value: any, scope: any) =>
              // console.log "text length changed"
              $ngModel.$validate()
            );
          },

          /* WATCHERS, LISTENERS AND EMITTERS */
          listen_for_start_event () {
            return $scope.$on('note_manager_start', (event: any, data: any) => {
              this.new_note.noteable_id = data.id
              return this.begin_api_operations()
            });
          },

          watch_for_default_note () {
            $scope.$watch('defaultNote', (newValue: any, oldValue: any) => {
              this.default_note = newValue
              this.new_note.text = newValue
            })
          },

          emit_completion_event () {
            const return_object = {
              manager: 'note_manager',
              object: $ngModel.$modelValue,
              fault: this.failure
            }
            return $scope.$emit('note_manager_finished', return_object)
          },

          // this syncronizes the $ngModel.$modelValue and @ng_model_value
          // we use @ng_model_value in the view exclusively
          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
              // console.log $ngModel.$modelValue
              this.ng_model_value = $ngModel.$modelValue
              // $scope.$apply()
            });
          }

        }

        // added these lines of code due to the ng model and scoped variables not updating here
        // when they are updated on the controler. this was creating outdated scope issues

        // this first bit updates the ngModel value, which is usually an entry's notes
        $ngModel.$render = () => $scope.nm.ng_model_value = $ngModel.$modelValue

        // this watches for updates to the noteable id. traditionally when saving a note, it was part
        // of a modal and the note manager would be dismissed immediately, so you couldnt add multiple notes.
        // but now that the batch review modal allows multiple notes to be entered at a time and on mutliple
        // entries without closing the modal, the id has to be updated so the notes are added to the correct
        // entry
        $scope.$watch('noteableId', function () {
          if (
            $scope.noteableId && // if there is a noteableID
            $scope.nm.new_note // and there is a place to place it...
          ) {
            return $scope.nm.new_note.noteable_id = $scope.noteableId
          }
        })

        $scope.updateNotesText = (event: any) => {
          // $scope.requirement.instructions = event
          if($scope.allowUpdateSingle) {
            $scope.nm.ng_model_value[0].text = event
          } else {
            $scope.nm.new_note.text = event
          }
        }
        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.nm.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
