/* eslint-disable
    no-control-regex,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('vendorModalController', ['$scope', 'modalOptions', '$uibModalInstance', function (
  $scope: any,
  modalOptions: any,
  $uibModalInstance: any
) {
  const init = () => $scope.vendor = modalOptions.vendor.replace(new RegExp('\n', 'g'), '<br />')

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  return init()
}])
