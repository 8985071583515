// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     object-curly-spacing
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationOverviewFruitalyticTreeController', [
//   '$q',
//   '$rootScope',
//   '$scope',
//   'arrayFunctions',
//   'complyosServices',
//   'filterService',
//   'FileSaver',
//   'fortyCore',
//   'helpService',
//   'loadingStateService',
//   'Restangular',
//   'treeManagerService',
//   'flashService',
//   function (
//     $q: any,
//     $rootScope: any,
//     $scope: any,
//     arrayFunctions: any,
//     complyosServices: any,
//     filterService: any,
//     FileSaver: any,
//     fortyCore: any,
//     helpService: any,
//     loadingStateService: any,
//     Restangular: any,
//     treeManagerService: any,
//     flashService: any
//   ) {
//     const initialize = function () {
      
//       $scope.helpService = angular.copy(helpService)
//       $scope.fortyCore = angular.copy(fortyCore)
//       $scope.complyosServices = angular.copy(complyosServices)
//       $scope.flashService = angular.copy(flashService)
//       $scope.flashService.listen_for_alert($scope)

//       $scope.tree_manager = angular.copy(treeManagerService)
//       $scope.param_to_remove = ['sorted']
//       $scope.outstandingPromises = []
//       $scope.deferredRefresh = null

//       var custom_states = {
//         ready: {
//           icon: 'fa-download',
//           text: 'Download Report'
//         }
//       }

      
//       $scope.overviewLoadingStateService = angular.copy(loadingStateService)
//       $scope.overviewLoadingStateService.loading_states = _.merge($scope.overviewLoadingStateService.loading_states, custom_states)
//       $scope.overviewLoadingStateService.init()

//       // Overview report
      
//       $scope.reportLoadingStateService = angular.copy(loadingStateService)
//       $scope.reportLoadingStateService.loading_states = _.merge($scope.reportLoadingStateService.loading_states, custom_states)
//       $scope.reportLoadingStateService.set_state('ready')

//       // Snapshot report
      
//       $scope.snaphotReportLoadingStateService = angular.copy(loadingStateService)
//       $scope.snaphotReportLoadingStateService.loading_states = _.merge($scope.snaphotReportLoadingStateService.loading_states, custom_states)
//       $scope.snaphotReportLoadingStateService.set_state('ready')

//       $scope.organizationSelected = false
//       $scope.organizations = null
//       $scope.organizations_count = null
//       $scope.previousPills = null
//       $scope.collapse_panel_body = false
//       $scope.overview_response = {}
//       $scope.overview_request = {
//         model: 'Binder',
//         method: 'organization_overview',
//         param_data: {}
//       }

//       watchFilterChange()
//     }

//     var executeRefresh = (pills: any) => {
//       $scope.abortAllPromises($scope.outstandingPromises)
//       setTimeout(() => get_organization_overview(pills), 50)
//     }

//     var watchFilterChange = () => {
//       var refresh = function (e: any, pills: any, resultsCount: any) {
//         $scope.isOrgPillSelected(pills)
//         if ($scope.organizations) {
//           $scope.reloadTree($scope.organizations, pills, resultsCount)
//         } else {
//           let params = { param_data: { /* if they want visibility, defualt it here */ } }
//           Restangular.all('organizations/filter').getList(params).then(
//             (success: any) => {
//               $scope.organizations = success.plain()
//               $scope.organizations_count = $scope.organizations.length
//               $scope.reloadTree($scope.organizations, pills, resultsCount)
//             }
//           )
//         }
//       }
//       $scope.$on('refresh-dashboard-widgets', refresh)
//       $scope.$on('cancel-pending-filter-queries', () => $scope.abortAllPromises($scope.outstandingPromises))
//     }

//     $scope.reloadTree = (organizations: any, pills: any, resultsCount: any) => {
//       let pillsCopy = JSON.parse(JSON.stringify(pills))
//       let orgPills = pillsCopy.filter((p: any) => p.Field === 'organizations_ids')
//       if (!Array.isArray(orgPills) || orgPills.length < 1) {
//         let topLevelOrgs = getTopLevelOrgFilters(organizations)
//         pillsCopy = pillsCopy.concat(topLevelOrgs)
//       }

//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pillsCopy, ['sorted', 'pagination_params']) || $scope.previousPills === null
//       $scope.previousPills = pillsCopy
//       if (widgetShouldRefresh) {
//         if (resultsCount <= 30000) {
//           executeRefresh(pillsCopy)
//         } else {
//           $scope.overviewLoadingStateService.set_state('query_too_large_warning')
//           $scope.deferredRefresh = executeRefresh.bind(null, pillsCopy)
//         }
//       }
//     }

//     var getTopLevelOrgFilters = (topLevelOrganizations: any) => {
//       if (!topLevelOrganizations) {
//         return
//       }

//       let topLevelOrgPills = topLevelOrganizations.map((o: any) => {
//         return {
//           Field: 'organizations_ids',
//           displayName: 'Organizations',
//           Op: 'Multi',
//           Value: o.id,
//           displayValue: o.name,
//           dateCreated: dayjs().$d
//         }
//       })
//       return topLevelOrgPills
//     }

//     var get_organization_overview = (pills: any) => {
//       if (!pills) {
//         return
//       }
//       $scope.overviewLoadingStateService.set_state('loading')

//       let query_params = angular.copy($scope.overview_request)
//       let newPills = $rootScope.storeService.pillsToRemove(pills, $scope.param_to_remove)
//       query_params.param_data = $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(newPills)
//       )

//       let abort = $q.defer()
//       $scope.outstandingPromises.push(abort)

//       organization_overview_request(query_params, abort).then(function (response: any) {
//         $scope.overviewLoadingStateService.process_success(response)
//         $scope.overview_response = response.plain()

//         $scope.organizations_count = $scope.overview_response.data.tree.length
//         // only get the parent org (for upwards navigation) if there is only one
//         if ($scope.overview_response.data.tree.length === 1) {
//           $scope.get_parent_organization(response.data.tree[0].parent_id)
//           $scope.organizations_count += response.data.tree[0].children.length
//         }
//       }, function (error: any) {
//         $scope.overviewLoadingStateService.set_state('query_too_large_warning')
//         $scope.deferredRefresh = executeRefresh.bind(null, pills)
//       })
//     }

//     var get_moar_overview = (tree_item: any) => {
//       tree_item.loading_moar = true
      
//       let query_params = angular.copy($scope.overview_request)
//       query_params.param_data = $rootScope.storeService.pillsToObject(filterService.getCached())
//       query_params.param_data.organization_ids = [{Value: tree_item.id}]
//       delete query_params.param_data.organizations_ids
//       // TODO use tree_manager.set_object_depth(tree, object) vs passing starting_depth
//       query_params.param_data.starting_depth = tree_item.depth
//       query_params.param_data = $rootScope.storeService.stringifyTagParams(query_params.param_data)

//       let abort = $q.defer()
//       $scope.outstandingPromises.push(abort)

//       organization_overview_request(query_params, abort).then(function (response: any) {
//         response = response.plain()
//         if (response.data.tree[0].children.length !== 0){
//           $scope.organizations_count += response.data.tree[0].children.length
//         }
//         $scope.tree_manager.update_object_in_tree(
//           $scope.overview_response.data.tree,
//           response.data.tree[0]
//         )
//         tree_item.loading_moar = false
//       }, function (error: any) {
//         $scope.overviewLoadingStateService.process_error(error)
//       })
//     }

//     var organization_overview_request = (params: any, abort: any) => {
//       return Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(params)
//     }

//     $scope.abortAllPromises = (promiseArray: any) => {
//       if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//         promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//       }
//       promiseArray.length = 0
//     }

//     $scope.toggle_expand_tree_item = (tree_item: any) => {
//       if (
//         !tree_item.expanded &&
//         tree_item.descendent_count > 0
//       ) {
//         if (tree_item.children.length === 0){
//           get_moar_overview(tree_item)
//         }
//         else{
//           tree_item.expanded = !tree_item.expanded
//           $scope.organizations_count += tree_item.children.length
//         }
//       } else {
//         tree_item.expanded = !tree_item.expanded
//         $scope.organizations_count -= tree_item.children.length
//       }
//     }

//     $scope.get_parent_organization = (id: any) => {
//       if (id) {
//         Restangular.one(`organizations/${id}`).get().then((response: any) => $scope.parent = response)
//       }
//     }

//     $scope.toggle_panel_body = () => {
//       $scope.collapse_panel_body = !$scope.collapse_panel_body
//     }

//     $scope.attempt_change_organization = (id: any) => {
//       // TODO replace with a better "set organization as pill" step.
//       Restangular.one(`organizations/${id}`).get().then((response: any) => {
//         let preventFilterChange = false
//         $rootScope.session.setOrganization(response, preventFilterChange)
//       })
//     }

//     $scope.download_report = () => {
//       $scope.reportLoadingStateService.set_state('loading')

//       let query_params = {
//         report: 'dashboard_organization_overview',
//         param_data: {}
//       }
//       query_params.param_data = $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject($scope.previousPills)
//       )

//       Restangular.setFullResponse(true).one(`reports/dashboard_organization_overview`).get(query_params).then(function (response: any) {
//         var report_file = new Blob([response.data], { type: 'text/csv' })
//         var report_name = `dashboard_organization_overview_report_${dayjs().format('YYYY-MM-DD')}.csv`
//         FileSaver.saveAs(report_file, report_name)
//         $scope.reportLoadingStateService.set_state('ready')
//       }
//       ,(error: any, report_name: string) => {
//         let message = error.data ? error.data[0] : 'The report has timed out. Please narrow your results and try again.'
//         const alert = {
//           name: `report_error_${report_name}`,
//           dismissable: true,
//           class: 'alert-warning',
//           icon: 'fa-exclamation-triangle',
//           strong: 'Error:',
//           message: `${message}`
//         }
//         $scope.flashService.emit_alert($scope, alert)
//         $scope.reportLoadingStateService.set_state('ready')
//       }).finally(() => {
//         // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//         // Reset the full response setting
//         Restangular.setFullResponse(false)
//       })
//     }

//     $scope.isOrgPillSelected = (pills: any) => {
//       $scope.organizationSelected = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
//     }

//     $scope.download_dashboard_detail_report = () => {
//       $scope.reportLoadingStateService.set_state('loading')

//       let query_params = {
//         report: 'dashboard_detail',
//         param_data: {}
//       }
//       query_params.param_data = $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject($scope.previousPills)
//       )

//       Restangular.setFullResponse(true).one(`reports/dashboard_detail`).get(query_params).then((response: any) => {
//         var report_file = new Blob([response.data], { type: 'text/csv' })
//         var report_name = `dashboard_detail_report_${dayjs().format('YYYY-MM-DD')}.csv`
//         FileSaver.saveAs(report_file, report_name)

//         $scope.reportLoadingStateService.set_state('ready')
//       }, (error: any, report_name: string) => {
//         let message = error.data ? error.data[0] : 'The report has timed out. Please narrow your results and try again.'
//         const alert = {
//           name: `report_error_${report_name}`,
//           dismissable: true,
//           class: 'alert-warning',
//           icon: 'fa-exclamation-triangle',
//           strong: 'Error:',
//           message: `${message}`
//         }
//         $scope.flashService.emit_alert($scope, alert)
//         $scope.reportLoadingStateService.set_state('ready')
//       }).finally(() => {
//         // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//         // Reset the full response setting
//         Restangular.setFullResponse(false)
//       })
//     }

//     $scope.get_report_button_class = () => {
//       if ($scope.reportLoadingStateService.loading_state.name === 'error') {
//         return 'btn-warning'
//       } else {
//         return 'btn-default'
//       }
//     }

//     $scope.get_legend_color = (status: any) => {
//       return complyosServices.snapshot.get_status_color(status)
//     }

//     // SORT

//     $scope.add_default_sort = () => {
//       $scope.order_organizations_by_function = $scope.order_organizations_by_past_due_entries
//       $scope.reverse_organizations_order = true
//     }

//     $scope.order_organizations_by_name = (organization: any) => organization.meta.label

//     $scope.order_organizations_by_organization_count = (organization: any) => organization.data.organization_count

//     $scope.order_organizations_by_binder_count = (organization: any) => organization.data.binder_count

//     $scope.order_organizations_by_open_binder_count = (organization: any) => organization.data.open_binder_count

//     $scope.order_organizations_by_past_due_binder_count = (organization: any) => organization.data.past_due_binder_count

//     $scope.order_organizations_by_open_entries = (organization: any) => organization.data.open_entry_count

//     $scope.order_organizations_by_past_due_entries = (organization: any) => organization.data.past_due_entry_count

//     $scope.order_organizations_by_closed_not_complete_entries = (organization: any) => organization.data.closed_not_complete_entry_count

//     initialize()
//   }
// ])
