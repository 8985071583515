import { Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import angular from "angular";

// import { complyosClient } from '../../app'

@Injectable()
export class PasswordService {
  // has_... functions returns true or false
  has_uppercase_letters(string: any) {
    return /[A-Z]/.test(string);
  }

  has_digits(string: any) {
    return /\d/.test(string);
  }

  has_extra_chars(string: any) {
    return /\W/.test(string);
  }

  has_downcase_letters(string: any) {
    return /[a-z]{1}/.test(string);
  }

  checker(password: any) {
    const foo = {
      strength: 0,
      length: password.length,
      messages: []
    }

    if (password.length < 8) {
      foo.messages.push({ text: 'Make your password longer' })
    }

    if (this.has_uppercase_letters(password)) {
      foo.strength += 1
    } else {
      foo.messages.push({ text: 'Consider adding uppercase letters' })
    }

    if (this.has_digits(password)) {
      foo.strength += 1
    } else {
      foo.messages.push({ text: 'A number could be great' })
    }

    if (this.has_extra_chars(password)) {
      foo.strength += 1
    } else {
      foo.messages.push({ text: 'Try one of these ! @ # $ % ^ & *' })
    }

    if (this.has_downcase_letters(password)) {
      foo.strength += 1
    } else {
      foo.messages.push({ text: 'Lowercase letters work well' })
    }

    return foo
  }

}

// complyosClient.factory('passwordService', downgradeInjectable(PasswordService))



//- --------------------------------------------------------------------
//- FOR REFERENCE

// var complyosClient = angular.module('complyosClient')

// complyosClient.factory('passwordService', function () {
//   return {

//     // has_... functions returns true or false
//     has_uppercase_letters(string: any) {
//       return /[A-Z]/.test(string);
//     },

//     has_digits(string: any) {
//       return /\d/.test(string);
//     },

//     has_extra_chars(string: any) {
//       return /\W/.test(string);
//     },

//     has_downcase_letters(string: any) {
//       return /[a-z]{1}/.test(string);
//     },

//     checker(password: any) {
//       const foo = {
//         strength: 0,
//         length: password.length,
//         messages: []
//       }

//       if (password.length < 8) {
//         // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
//         foo.messages.push({ text: 'Make your password longer' })
//       }

//       if (this.has_uppercase_letters(password)) {
//         foo.strength += 1
//       } else {
//         // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
//         foo.messages.push({ text: 'Consider adding uppercase letters' })
//       }

//       if (this.has_digits(password)) {
//         foo.strength += 1
//       } else {
//         // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
//         foo.messages.push({ text: 'A number could be great' })
//       }

//       if (this.has_extra_chars(password)) {
//         foo.strength += 1
//       } else {
//         // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
//         foo.messages.push({ text: 'Try one of these ! @ # $ % ^ & *' })
//       }

//       if (this.has_downcase_letters(password)) {
//         foo.strength += 1
//       } else {
//         // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
//         foo.messages.push({ text: 'Lowercase letters work well' })
//       }

//       return foo
//     }

//   };
// })
