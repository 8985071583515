import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DOCUMENT } from '@angular/common';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngMarketing',
  templateUrl: './marketing.component.html'
})
export class MarketingComponent implements OnInit {
  // Properties
  private activeSlide: number = 0;
  private COMPLYOS_DOMAIN: string;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('ENV') private ENV,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  
  ngOnInit(): void {
    this.COMPLYOS_DOMAIN = this.ENV.COMPLYOS_DOMAIN
  }

  rewindSlide = () => {
    if (this.activeSlide === 0) {
      return this.activeSlide = 8
    } else {
      return this.activeSlide = this.activeSlide - 1
    }
  }

  advanceSlide = () => {
    if (this.activeSlide === 8) {
      return this.activeSlide = 0
    } else {
      return this.activeSlide = this.activeSlide + 1
    }
  }

  buildEmailHref = function () {
    /* MAIL RECIEPENTS */
    let user_email, user_name, user_role
    const to = [`info@${this.ENV.COMPLYOS_DOMAIN}`]
    const cc = []
    const bcc: any = []

    /* USER INFORMATION */
    const user = this.$rootScope.session.getUser()

    if (user) {
      user_name = user.profile.display_name
      user_email = user.email
      user_role = user.user_role.role.title
      cc.push(user.email)
    } else {
      user_name = ''
      user_email = ''
      user_role = ''
    }

    /* LOCATION INFORMATION */
    const site = this.document.location.hostname
    const currentPage = this.document.location.href

    /* EMAIL */
    const subject = `Complyos Access: ${site}`

    const body = `\
I would like more information about Complyos for my organization\
`

    let href = `mailto:${to.join(',')}?`

    if (cc.length) {
      href += `cc=${cc.join(',')}&`
    }
    if (bcc.length) {
      href += `bcc=${bcc.join(',')}&`
    }

    href += `subject=${encodeURIComponent(subject)}&`
    href += `body=${encodeURIComponent(body)}`

    return href
  }
}

angular
  .module('complyosClient')
  .directive('ngMarketing', downgradeComponent({ component: MarketingComponent }) as angular.IDirectiveFactory)
