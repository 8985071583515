/* eslint-disable
    camelcase,
    eqeqeq,
    no-return-assign,
    no-undef,
*/
/*
example of how to apply this to an existing icon
the sort-by-manager and sort-by-content will link to sort_by_manager.pug
the sort value will be the name param that will be passed to the API

th.text-center.hidden-sm.hidden-xs
  small.text-muted.cursor-pointer
    sort-by-manager(page-name="'list'" sort-value="'paired'" sort-apply-broadcast="'push-new-filter'")
      // sort-by-content
      span.fa-stack(
        tooltip-enable="helpService.tooltip_enabled()"
        tooltip-placement="top-right"
        tooltip-popup-delay="500"
        uib-tooltip="Pairing state"
        style="padding-bottom:5px"
      )
        i.fa.fa-stack-2x.fa-square.fa-2x
        i.fa.fa-stack-1x.fa-key.fa-inverse.fa-rotate-90
*/
'use strict'

var dayjs = require("dayjs")

var SortByManagerComponent = {
  selector: "sortByManager", //<sort-by-manager>
  // templateUrl: 'views/directives/sort_by_manager.html',
  template: require('../../views/directives/sort_by_manager.pug'),
  restrict: 'E',
  bindings: {
    sortValue: '<',
    pageName: '<',
    sortApplyBroadcast: '<'
  },
  transclude: true,

  controller: class SortByManagerController {
    // BINDING PLACEHOLDERS
    private sortValue: any;
    private pageName: any;
    private sortApplyBroadcast: any;

    // PROPERTIES
    private sortParams: any = {};
    private currentSortPill: any;


    static $inject = ['$rootScope', '$scope', 'helpService', 'fortyCore', 'constants', 'filterService'];
    constructor(private $rootScope, private $scope, private helpService, private fortyCore, private constants, private filterService
    ) {
    }


    $onInit = () => {
      var that = this
      this.$scope.$on(this.sortApplyBroadcast, function (e: any, sortPill: any) {
        that.updateSortState(sortPill)
      })

      this.loadAppliedSorts()
    }

    loadAppliedSorts = () => {
      let filters = this.filterService.getCached()
      let sortPill = filters.find((p: any) => (p.Field === 'sort_params') && (p.onlyValidOn === this.pageName))
      this.currentSortPill = sortPill
      if (sortPill !== undefined) { this.updateSortState(sortPill) }
    }

      //  update the sort state from pill in local storage
    updateSortState = (sortPill: any) => {
      if (sortPill.Field !== 'sort_params') {
        return
      }
      if (sortPill.Value.value !== undefined && sortPill.Value.value !== this.sortValue) {
        this.sortParams.method = 'none'
      } else {
        this.sortParams.method = sortPill.Value.method
      }
    }

    //  when sort icon is clicked, set correct method and value and create the pill
    sortApplied = () => {
      //  determining what the correct method should be. first click will be ascending. second descending.
      //  third click on the same icon will reset back to 'none'
      //  if you click one icon and then click another, it will reset to ascending with new value
      if (this.currentSortPill !== undefined && this.sortValue === this.currentSortPill.Value.value) {
        if (this.sortParams.method === 'none') {
          this.sortParams.method = 'ascending'
        } else if (this.sortParams.method === 'ascending') {
          this.sortParams.method = 'descending'
        } else if (this.sortParams.method === 'descending') {
          this.sortParams.method = 'none'
        }
      } else {
        this.sortParams.method = 'ascending'
      }

      this.sortParams.value = this.sortValue
      //  make the pill here
      let sortPill = {
        isUnique: true,
        isRequired: true,
        isHidden: true,
        Field: 'sort_params',
        Op: this.constants.FilterType.Select,
        displayName: 'sort',
        displayValue: this.sortValue,
        Value: this.sortParams,
        dateCreated: dayjs().$d,
        onlyValidOn: this.pageName
      }
      //  this magic broadcast will add the pill into local storage
      this.$rootScope.$broadcast(this.sortApplyBroadcast, sortPill)
    }
  }
}

angular
  .module('complyosClient')
  .component(SortByManagerComponent.selector, SortByManagerComponent);

//- --------------------------------------------------------------------
//- FOR REFERENCE


// angular.module('complyosClient').directive('sortByManager', ['$rootScope', 'helpService', 'fortyCore', 'constants', 'filterService',
//   ($rootScope, helpService, fortyCore, constants, filterService) => ({
//     // templateUrl: 'views/directives/sort_by_manager.html',
//     template: require('../../views/directives/sort_by_manager.pug'),
//     restrict: 'E',

//     transclude: true,
//     // transclude: {
//     //   sortByContent: 'sortByContent'
//     // },

//     scope: {
//       sortValue: '=?',
//       pageName: '=?',
//       sortApplyBroadcast: '<'
//     },

//     link: ($scope: any, $ele: any, $attrs: any) => {
//       $scope = this.registerFunctions($scope)

//       $scope.sortParams = {}

//       $scope.$on($scope.sortApplyBroadcast, function (e: any, sortPill: any) {
//         $scope.updateSortState(sortPill)
//       })

//       $scope.loadAppliedSorts()

//       $scope.helpService = helpService
//       $scope.fortyCore = fortyCore
//     },

//     registerFunctions ($scope: any) {
//       //  on reload page, add event listener to set values from local storage.
//       $scope.loadAppliedSorts = () => {
//         let filters = filterService.getCached()
//         let sortPill = filters.find((p: any) => (p.Field === 'sort_params') && (p.onlyValidOn === $scope.pageName))
//         $scope.currentSortPill = sortPill
//         if (sortPill !== undefined) { $scope.updateSortState(sortPill) }
//       }

//       //  update the sort state from pill in local storage
//       $scope.updateSortState = (sortPill: any) => {
//         if (sortPill.Field !== 'sort_params') {
//           return
//         }
//         if (sortPill.Value.value !== undefined && sortPill.Value.value !== $scope.sortValue) {
//           $scope.sortParams.method = 'none'
//         } else {
//           $scope.sortParams.method = sortPill.Value.method
//         }
//       }

//       //  when sort icon is clicked, set correct method and value and create the pill
//       $scope.sortApplied = () => {
//         //  determining what the correct method should be. first click will be ascending. second descending.
//         //  third click on the same icon will reset back to 'none'
//         //  if you click one icon and then click another, it will reset to ascending with new value
//         if ($scope.currentSortPill !== undefined && $scope.sortValue === $scope.currentSortPill.Value.value) {
//           if ($scope.sortParams.method === 'none') {
//             $scope.sortParams.method = 'ascending'
//           } else if ($scope.sortParams.method === 'ascending') {
//             $scope.sortParams.method = 'descending'
//           } else if ($scope.sortParams.method === 'descending') {
//             $scope.sortParams.method = 'none'
//           }
//         } else {
//           $scope.sortParams.method = 'ascending'
//         }

//         $scope.sortParams.value = $scope.sortValue
//         //  make the pill here
//         let sortPill = {
//           isUnique: true,
//           isRequired: true,
//           isHidden: true,
//           Field: 'sort_params',
//           Op: constants.FilterType.Select,
//           displayName: 'sort',
//           displayValue: $scope.sortValue,
//           Value: $scope.sortParams,
//           dateCreated: dayjs().$d,
//           onlyValidOn: $scope.pageName
//         }
//         //  this magic broadcast will add the pill into local storage
//         $rootScope.$broadcast($scope.sortApplyBroadcast, sortPill)
//       }

//       return $scope
//     }
//   })
// ])
