import angular from "angular";

export class Utils {
  static $inject = ['ENV'];

  constructor(
    private ENV: any
  ) {}

  /**
    * Helper method to $scope.$apply safely. Will prevent the dreaded
    * "digest already in progress" error when trying to apply
    *
    * @param {object} Current scope
    * @param {function} The function to apply
    */
  safeApply($scope: any, fn: any) {
    const phase = $scope.$$phase
    if ((phase === '$apply') || (phase === '$digest')) {
      if (fn && (typeof (fn) === 'function')) {
        return fn()
      }
    } else {
      return $scope.$apply(fn)
    }
  }

  /**
    * This function is used to log things to the web console in
    * development and staging mode. It will not run the console.log
    * function in other modes and will not run the function if
    * it doesn't exist - good for older browsers such as IE8
    * Coloring/message severity not compatible with object inspection
    *
    * @param {any} The content that is logged to the console
    * @param {string} String used to color the message
    * @param {boolean} Used to push an alert...but not in production
    */
  log(what: any, severity = null, force: any) {
    if (force == null) { force = false }
    if ((this.ENV.NAME === 'local') || (this.ENV.NAME === 'staging') || (this.ENV.NAME === 'qa')) {
      if (force) {
        alert(what) // ha
      } else {
        try {
          // Check if the type exists and check if console is
          // a member property of window
          if (typeof console === 'object') {
            if (severity) {
              // Pick a style, bro
              const style = (() => {
                switch (false) {
                  case severity !== 'primary': return 'background: transparent; color:#357ebd;'
                  case severity !== 'error': return 'background: #d9534f; color:#fff;'
                  case severity !== 'success': return 'background: #47a447; color:#fff;'
                  case severity !== 'warning': return 'background: #f0ad4e; color:#5F4500;'
                  case severity !== 'info': return 'background: #5bc0de; color:#004B61;'
                  default: return 'background: transparent; color:#343434;'
                }
              })()

              return console.log(`%c ${what}`, style + 'padding: 0.1rem 0.3rem;')
            } else {
              return console.log(what)
            }
          }
        } finally {
          return
        }
      }
    }
  }

  shortenFileName(str: any, newLength: any) {
    if (str.length < newLength) {
      return str
    } else {
      let name = str.substr(0, str.lastIndexOf('.'))
      const ext = str.substr(str.lastIndexOf('.') + 1)
      if (name.length > (newLength - 3 - ext.length)) {
        name = name.substr(0, newLength - 3 - ext.length)
      }
      return `${name}...${ext}`
    }
  }
}

angular
  .module('complyosClient')
  .service("utils", Utils);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// complyosClient.factory('utils', ['ENV', (ENV: any) => ({
//   /**
//     * Helper method to $scope.$apply safely. Will prevent the dreaded
//     * "digest already in progress" error when trying to apply
//     *
//     * @param {object} Current scope
//     * @param {function} The function to apply
//     */
//   safeApply($scope: any, fn: any) {
//     const phase = $scope.$$phase
//     if ((phase === '$apply') || (phase === '$digest')) {
//       if (fn && (typeof (fn) === 'function')) {
//         return fn()
//       }
//     } else {
//       return $scope.$apply(fn)
//     }
//   },

//   /**
//     * This function is used to log things to the web console in
//     * development and staging mode. It will not run the console.log
//     * function in other modes and will not run the function if
//     * it doesn't exist - good for older browsers such as IE8
//     * Coloring/message severity not compatible with object inspection
//     *
//     * @param {any} The content that is logged to the console
//     * @param {string} String used to color the message
//     * @param {boolean} Used to push an alert...but not in production
//     */
//   log(what: any, severity = null, force: any) {
//     if (force == null) { force = false }
//     if ((ENV.NAME === 'local') || (ENV.NAME === 'staging') || (ENV.NAME === 'qa')) {
//       if (force) {
//         alert(what) // ha
//       } else {
//         try {
//           // Check if the type exists and check if console is
//           // a member property of window
//           if (typeof console === 'object') {
//             if (severity) {
//               // Pick a style, bro
//               const style = (() => {
//                 switch (false) {
//                   case severity !== 'primary': return 'background: transparent; color:#357ebd;'
//                   case severity !== 'error': return 'background: #d9534f; color:#fff;'
//                   case severity !== 'success': return 'background: #47a447; color:#fff;'
//                   case severity !== 'warning': return 'background: #f0ad4e; color:#5F4500;'
//                   case severity !== 'info': return 'background: #5bc0de; color:#004B61;'
//                   default: return 'background: transparent; color:#343434;'
//                 }
//               })()

//               return console.log(`%c ${what}`, style + 'padding: 0.1rem 0.3rem;')
//             } else {
//               return console.log(what)
//             }
//           }
//         } finally {
//           return
//         }
//       }
//     }
//   },

//   shortenFileName(str: any, newLength: any) {
//     if (str.length < newLength) {
//       return str
//     } else {
//       let name = str.substr(0, str.lastIndexOf('.'))
//       const ext = str.substr(str.lastIndexOf('.') + 1)
//       if (name.length > (newLength - 3 - ext.length)) {
//         name = name.substr(0, newLength - 3 - ext.length)
//       }
//       return `${name}...${ext}`
//     }
//   }
// })
// ])