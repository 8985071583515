import * as angular from 'angular';
export class helpService {

  static $inject = ['fortyCore'];

  constructor(
    private fortyCore: any) {
  }

  public detectionService = this.fortyCore.detectionService

  public tooltips = {
    // Application
    session: {
      change_organization: 'Make this the active organization',
      return_to_parent: "Go back organization's parent",
      show_advanced: 'Show advanced options',
      export_users: 'Export user list'
    },
    // UI Elements
    form: {
      add: 'Add',
      create: 'Create',
      destroy: 'Delete',
      download: 'Download',
      read: 'Inspect',
      remove: 'Remove',
      save: 'Save',
      undo: 'Undo',
      update: 'Update',
      duplicate: 'Duplicate',
      upload: 'Upload',
      custom: 'Custom',
      copy: 'Copy'
    },
    // Models
    binder: {
      annotation: 'When configuring multiple duplicate requirements for an organization, this field is used to uniquely identify them.',
      attachments: 'These documents should contain your populated templates, inventory lists, and other support documents specific to your organization.',
      user_assigned: 'Person assigned to complete a Requirement.  Three notifications will be sent if the activity is not completed before the end of the submission window.',
      user_compliance_admin: 'Person responsible for all oversight of a Requirement. One notification will be sent if the Requirement is not completed before the end of the submission window.',
      user_responsible: 'Person who identifies and assigns a Requirement to a Task Agent. Two notifications will be sent if the Requirement is not completed before the end of the submission window.',
      vendor: 'Contact Vendor',
      form: 'Use the Complyos Form Tool to complete this requirement'
    },

    entry: {
      create_historical: 'Add new historical documentation.',
      submission_window: 'This is the date range that all documentation should be completed within.',
      update: 'Upload documentation and complete this requirement',
      read: "View this entry's documentation",
      attachments: 'Number of attachments currently uploaded',
      requires_resubmission: 'Entry requires resubmission',
      awaiting_review: 'Entry is awaiting review',
      requires_review: 'This entry requires that it be reviewed and attested by the reviewer',
      complete_work_in_paired_application: 'Complete this work in paired application.'
    },

    user: {
      inactive: 'This user is inactive. Inactive users are unable to log in until they activate their account. If the link they were emailed has expired, they can get a new one via &quot;Forgot Password&quot;.',
      locked: 'This user is locked. Locked users are unable to log in until an admin unlocks the user.',
      username: 'Username is what you will use to login with',
      email: 'Email will be the primary method of communication for all notifications',
      invalid_email: 'Please enter a correct email address'
    },
    // Other/Misc
    calendar: {
      open_pdf: 'Open PDF in a new window.'
    },

    attachments: {
      select: 'Select or drop files',
      scan: 'Scan documents',
      email: 'Copy the address used to submit documentation via email'
    },

    analytics: {
      historical: 'See historical data',
      close_historical: 'Close Historical View',
      active: "Total number of each category with a state of 'Active'. Anything that is inactive or has been archived is not included.",
      complete_percentage: 'Requirements that need a Start Date are not included in these metrics.',
      snapshot_date_range: 'These metrics and visuals represent tasks from within the selected date range. Requirements that need a Start Date are not included.',
      download_dashboard_detail_report: 'At least one Organization must be selected to run this report.',
      download_dashboard_overview_report: 'At least one Organization must be selected to run this report.'
    },

    schedule: {
      requires_review: 'See only entries that require review',
      requires_resubmission: 'See only entries that require resubmission',
      awaiting_completion: 'See only entries that are awaiting completion',
      partially_complete: 'See only entries that are partially complete'
    },
    compliance_analysis: {
      acknowledged: 'This ValidationMode&#174; comment has been seen and a response has been generated for it',
      acknowledge: 'Clicking this button will ensure that the entry is returned to the schedule'
    },

    requirements: {
      unpublished: 'This requirement is not currently available in the Store',
      unpublished_icon: 'Unpublished',
      published_icon: 'Published',
      archived_icon: 'Archived',
      edit_state_icon: 'Edit-State',
      report_tooltip: 'At least one organization must be selected',
      thirty_day_review: 'This requirement needs to be reviewed within the next 30 days',
      sixty_day_review: 'This requirement needs to be reviewed within the next 60 days',
      ninety_day_review: 'This requirement needs to be reviewed within the next 90 days'
    },

    system_reports: {
      run: 'Pull report from server',
      save: 'Save report locally',
      result_count: 'Number of results in report'
    },

    edit_schedule_status: {
      schedule_on: {
        display: 'Schedule On',
        helpText: 'Complyos will generate tasks based on the schedule start date'
      },
      no_schedule: {
        display: 'No Schedule',
        helpText: 'Schedule is disabled. Complyos will not generate tasks.'
      },
      start_date_needed: {
        display: 'Start Date Needed',
        helpText: 'Requirement tasks will not generate on the Schedule until a start date is added. Add a schedule start date to enable this functionality.'
      },
      managed_by_paired_application: {
        display: 'Managed by Paired Application',
        helpTextPaired: 'Paired application Engineering Central is responsible for schedule tasks. Notifications will not be emailed from Complyos.',
        helpTextNotPaired: 'This requirement is not paired. When paired, the application will be responsible for the schedule.'
      }
    },

    store_schedule_status: {
      schedule_on: {
        display: 'Configure with start date',
        helpText: 'Tasks will generate based on the Schedule Start Date. If you do not know your start date, choose "Configure without a Start Date".'
      },
      no_schedule: {
        display: 'Configure without a schedule',
        helpText: 'Complyos will not generate tasks for this requirement. Users may create tasks and upload documents manually.'
      },
      start_date_needed: {
        display: 'Configure without start date',
        helpText: 'Requirement tasks will not generate on the Schedule until a start date is added. A Start Date can be added later via the List page.'
      },
      managed_by_paired_application: {
        display: 'Managed by Paired Application',
        helpText: 'Paired application will be responsible for schedule. Verify pairing status in paired application to ensure scheduling. Notifications will not be emailed from Complyos.'
      }
    }
  }
  // http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key
  private object_by_string (o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, '.$1')
    s = s.replace(/^\./, '')
    const a = s.split('.')
    let i = 0
    const n = a.length
    while (i < n) {
      const k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
      ++i
    }
    return o
  }

  public get_tooltip (string: any) {
    return this.object_by_string(this.tooltips, string) || console.log(`no tooltip found for ${string}`)
  }

  public append_tooltip (string: any, suffix: any) {
    let base = this.get_tooltip(string)
    base = `${base} ${suffix}`
    return base
  }

  public tooltip_enabled () {
    return !this.detectionService.isMobileAmalgam()
  }
}

angular
  .module('complyosClient')
  .service("helpService", helpService);
// FORE REFERENCE
// 
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.service('helpService',
//   [
//     'fortyCore',
//     function (fortyCore: any) {
//       const { detectionService } = fortyCore

//       return {
//         tooltips: {

//           // Application
//           session: {
//             change_organization: 'Make this the active organization',
//             return_to_parent: "Go back organization's parent",
//             show_advanced: 'Show advanced options',
//             export_users: 'Export user list'
//           },

//           // UI Elements
//           form: {
//             add: 'Add',
//             create: 'Create',
//             destroy: 'Delete',
//             download: 'Download',
//             read: 'Inspect',
//             remove: 'Remove',
//             save: 'Save',
//             undo: 'Undo',
//             update: 'Update',
//             upload: 'Upload',
//             custom: 'Custom',
//             copy: 'Copy'
//           },

//           // Models
//           binder: {
//             annotation: 'When configuring multiple duplicate requirements for an organization, this field is used to uniquely identify them.',
//             attachments: 'These documents should contain your populated templates, inventory lists, and other support documents specific to your organization.',
//             user_assigned: 'Person assigned to complete a Requirement.  Three notifications will be sent if the activity is not completed before the end of the submission window.',
//             user_compliance_admin: 'Person responsible for all oversight of a Requirement. One notification will be sent if the Requirement is not completed before the end of the submission window.',
//             user_responsible: 'Person who identifies and assigns a Requirement to a Task Agent. Two notifications will be sent if the Requirement is not completed before the end of the submission window.',
//             vendor: 'Contact Vendor',
//             form: 'Use the Complyos Form Tool to complete this requirement'
//           },

//           entry: {
//             create_historical: 'Add new historical documentation.',
//             submission_window: 'This is the date range that all documentation should be completed within.',
//             update: 'Upload documentation and complete this requirement',
//             read: "View this entry's documentation",
//             attachments: 'Number of attachments currently uploaded',
//             requires_resubmission: 'Entry requires resubmission',
//             awaiting_review: 'Entry is awaiting review',
//             requires_review: 'This entry requires that it be reviewed and attested by the reviewer',
//             complete_work_in_paired_application: 'Complete this work in paired application.'
//           },

//           user: {
//             inactive: 'This user is inactive. Inactive users are unable to log in until they activate their account. If the link they were emailed has expired, they can get a new one via &quot;Forgot Password&quot;.',
//             locked: 'This user is locked. Locked users are unable to log in until an admin unlocks the user.',
//             username: 'Username is what you will use to login with',
//             email: 'Email will be the primary method of communication for all notifications',
//             invalid_email: 'Please enter a correct email address'
//           },

//           // Other/Misc
//           calendar: {
//             open_pdf: 'Open PDF in a new window.'
//           },

//           attachments: {
//             select: 'Select or drop files',
//             scan: 'Scan documents',
//             email: 'Copy the address used to submit documentation via email'
//           },

//           analytics: {
//             historical: 'See historical data',
//             close_historical: 'Close Historical View',
//             active: "Total number of each category with a state of 'Active'. Anything that is inactive or has been archived is not included.",
//             complete_percentage: 'Requirements that need a Start Date are not included in these metrics.',
//             snapshot_date_range: 'These metrics and visuals represent tasks from within the selected date range. Requirements that need a Start Date are not included.',
//             download_dashboard_detail_report: 'Export Detailed Report defaults to displaying all data and is not effected by changes in the Date filter.'
//           },

//           schedule: {
//             requires_review: 'See only entries that require review',
//             requires_resubmission: 'See only entries that require resubmission',
//             awaiting_completion: 'See only entries that are awaiting completion',
//             partially_complete: 'See only entries that are partially complete'
//           },

//           compliance_analysis: {
//             acknowledged: 'This ValidationMode&#174; comment has been seen and a response has been generated for it',
//             acknowledge: 'Clicking this button will ensure that the entry is returned to the schedule'
//           },

//           requirements: {
//             unpublished: 'This requirement is not currently available in the Store',
//             report_tooltip: 'At least one organization must be selected'
//           },

//           system_reports: {
//             run: 'Pull report from server',
//             save: 'Save report locally',
//             result_count: 'Number of results in report'
//           },
//           edit_schedule_status: {
//             schedule_on: {
//               display: 'Schedule On',
//               helpText: 'Complyos will generate tasks based on the schedule start date'
//             },
//             no_schedule: {
//               display: 'No Schedule',
//               helpText: 'Schedule is disabled. Complyos will not generate tasks.'
//             },
//             start_date_needed: {
//               display: 'Start Date Needed',
//               helpText: 'Requirement tasks will not generate on the Schedule until a start date is added. Add a schedule start date to enable this functionality.'
//             },
//             managed_by_paired_application: {
//               display: 'Managed by Paired Application',
//               helpTextPaired: 'Paired application Engineering Central is responsible for schedule tasks. Notifications will not be emailed from Complyos.',
//               helpTextNotPaired: 'This requirement is not paired. When paired, the application will be responsible for the schedule.'
//             }
//           },
//           store_schedule_status: {
//             schedule_on: {
//               display: 'Configure with start date',
//               helpText: 'Tasks will generate based on the Schedule Start Date. If you do not know your start date, choose "Configure without a Start Date".'
//             },
//             no_schedule: {
//               display: 'Configure without a schedule',
//               helpText: 'Complyos will not generate tasks for this requirement. Users may create tasks and upload documents manually.'
//             },
//             start_date_needed: {
//               display: 'Configure without start date',
//               helpText: 'Requirement tasks will not generate on the Schedule until a start date is added. A Start Date can be added later via the List page.'
//             },
//             managed_by_paired_application: {
//               display: 'Managed by Paired Application',
//               helpText: 'Paired application will be responsible for schedule. Verify pairing status in paired application to ensure scheduling. Notifications will not be emailed from Complyos.'
//             }
//           }
//         },

//         // http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key
//         object_by_string (o: any, s: any) {
//           s = s.replace(/\[(\w+)\]/g, '.$1')
//           s = s.replace(/^\./, '')
//           const a = s.split('.')
//           let i = 0
//           const n = a.length
//           while (i < n) {
//             const k = a[i]
//             if (k in o) {
//               o = o[k]
//             } else {
//               return
//             }
//             ++i
//           }
//           return o
//         },

//         get_tooltip (string: any) {
//           return this.object_by_string(this.tooltips, string) || console.log(`no tooltip found for ${string}`)
//         },

//         append_tooltip (string: any, suffix: any) {
//           let base = this.get_tooltip(string)
//           base = `${base} ${suffix}`
//           return base
//         },

//         tooltip_enabled () {
//           return !detectionService.isMobileAmalgam()
//         }
//       };
//     }

//   ])
