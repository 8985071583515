/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars
*/
'use strict'


angular.module('complyosClient').directive('tagRow', [
  'fortyCore', 'helpService',
  (fortyCore, helpService) => ({
    templateUrl: 'views/directives/tag_row.html',
    restrict: 'E',

    scope: {
      stageTagFn: '&',
      getTagIconFn: '&',
      tagFilterFn: '&',
      allowCreate: '<',
      allowDestroy: '<',
      tag: '='
    },

    link($scope: any, $element: any, $attributes: any) {
      $scope = this.registerFunctions($scope)

      return $scope.fortyCore = fortyCore
    },

    registerFunctions($scope: any) {
      return $scope
    }
  })
])
