/* eslint-disable
    no-return-assign,
    no-undef,
*/

'use strict'

import angular from "angular";

export class updateUserPasswordModalController implements angular.IController {
    static $inject = ['$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'modalOptions', 'passwordService', 'Restangular', 'utils'];

    $onInit() { }

    constructor(
        $scope: any,
        $uibModalInstance: any,
        flashService: any,
        fortyCore: any,
        modalOptions: any,
        passwordService: any,
        Restangular: any,
        utils: any) {

        /* THINGS */
        let updatePassword: any
        $scope.processingStateService = fortyCore.processingStateService

        $scope.flashService = angular.copy(flashService)

        /* SCOPE */
        $scope.user = modalOptions.user
        $scope.ui = {
            user: {
                password: {
                    messages: []
                }
            }
        }

        // Validation for the Password and strong standards
        $scope.$watch('user.password', function (newValue: any, oldValue: any) {
            // A blank password will not need to be updated, so so even though its dirty, and blank it is valid
            // the updateProfile function will not send off a PUT request to update the user with a blank password
            if ((newValue === undefined) || (newValue === '') || (newValue === null)) {
                if ($scope.password_form.field_password && ($scope.password_form.field_password.$valid === false)) {
                    return $scope.password_form.field_password.$setValidity('', true)
                }

                // if there is something in the password field, we check.
            } else {
                const checker = passwordService.checker(newValue)

                if ((checker.strength >= 3) && (checker.length >= 8) && (checker.length <= 128)) {
                    $scope.password_form.field_password.$setValidity('', true)
                } else {
                    $scope.password_form.field_password.$setValidity('', false)
                }

                // bubble up the messages from checker
                return $scope.ui.user.password.messages = checker.messages
            }
        })

        $scope.submit_form = (form: any, object: any) =>
            // State service will set the form to a state of PENDING
            // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
            $scope.processingStateService.performFunction(form, function () {
                if (form.$valid) {
                    return updatePassword(object)
                } else {
                    $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
                    return form.failed_submission = true
                }
            })

        // dismiss the modal and provide a reason
        $scope.dismissModal = function () {
            $uibModalInstance.dismiss('cancel')
        }

        // close modal, and pass object back to initiator
        const closeModalAndReturn = function (object: any) {
            $uibModalInstance.close(object)
        }

        updatePassword = (user: any) => Restangular.one(`users/${user.id}`).customPUT({ user: { password: user.password }})
        .then(function (success: any) {
            // sanatize and return to initializer
            user.password = null
            return closeModalAndReturn(user)
        }, function (error: any) {
            $scope.processingStateService.set($scope.password_form, $scope.processingStateService.processing_states.RESTING)
            $scope.flashService.process_error(error)
            return utils.log(error)
        });

    }
}
angular
    .module('complyosClient')
    .controller('updateUserPasswordModalController', updateUserPasswordModalController)


// ------------------------------------


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('updateUserPasswordModalController', ['$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'modalOptions', 'passwordService', 'Restangular', 'utils', function (
//   $scope: any,
//   $uibModalInstance: any,
//   flashService: any,
//   fortyCore: any,
//   modalOptions: any,
//   passwordService: any,
//   Restangular: any,
//   utils: any
// ) {
//   /* THINGS */
//   let updatePassword: any
//   $scope.processingStateService = fortyCore.processingStateService
  
//   $scope.flashService = angular.copy(flashService)

//   /* SCOPE */
//   $scope.user = modalOptions.user
//   $scope.ui = {
//     user: {
//       password: {
//         messages: []
//       }
//     }
//   }

//   // Validation for the Password and strong standards
//   $scope.$watch('user.password', function (newValue: any, oldValue: any) {
//     // A blank password will not need to be updated, so so even though its dirty, and blank it is valid
//     // the updateProfile function will not send off a PUT request to update the user with a blank password
//     if ((newValue === undefined) || (newValue === '') || (newValue === null)) {
//       if ($scope.password_form.field_password && ($scope.password_form.field_password.$valid === false)) {
//         return $scope.password_form.field_password.$setValidity('', true)
//       }

//     // if there is something in the password field, we check.
//     } else {
//       const checker = passwordService.checker(newValue)

//       if ((checker.strength >= 3) && (checker.length >= 8) && (checker.length <= 128)) {
//         $scope.password_form.field_password.$setValidity('', true)
//       } else {
//         $scope.password_form.field_password.$setValidity('', false)
//       }

//       // bubble up the messages from checker
//       return $scope.ui.user.password.messages = checker.messages
//     }
//   })

//   $scope.submit_form = (form: any, object: any) =>
//     // State service will set the form to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(form, function () {
//       if (form.$valid) {
//         return updatePassword(object)
//       } else {
//         $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
//         return form.failed_submission = true
//       }
//     })

//   // dismiss the modal and provide a reason
//   $scope.dismissModal = function () {
//     $uibModalInstance.dismiss('cancel')
//   }

//   // close modal, and pass object back to initiator
//   const closeModalAndReturn = function (object: any) {
//     $uibModalInstance.close(object)
//   }

//   return updatePassword = (user: any) => Restangular.one(`users/${user.id}`).customPUT(
//     {
//       user: {
//         password: user.password
//       }
//     }
//   ).then(function (success: any) {
//     // sanatize and return to initializer
//     user.password = null
//     return closeModalAndReturn(user)
//   }

//   , function (error: any) {
//     $scope.processingStateService.set($scope.password_form, $scope.processingStateService.processing_states.RESTING)
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   });
// }])
