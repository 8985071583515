/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef-init,
    no-undef,
    no-undef-init,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import angular from 'angular'
import * as rrule from '../../utilities/rrule/rrule'
var dayjs = require("dayjs")
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)


var complyosClient = angular.module('complyosClient')

complyosClient.controller('binderModalController', ['$location', '$rootScope', '$route', '$scope', '$uibModalInstance', '$window', 'confirmationModalFactory', 'dateService', 'filterService', 'flashService', 'helpService', 'storeService', 'fortyCore', 'arrayFunctions', 'modalOptions', 'Restangular', 'constants', 'utils', function (
  $location: any,
  $rootScope: any,
  $route: any,
  $scope: any,
  $uibModalInstance: any,
  $window: any,
  confirmationModalFactory: any,
  dateService: any,
  filterService: any,
  flashService: any,
  helpService: any,
  storeService: any,
  fortyCore: any,
  arrayFunctions: any,
  modalOptions: any,
  Restangular: any,
  constants: any,
  utils: any
) {
  const initialize = function () {
    $scope.enums = constants.schedule_generation_state
    $scope.RRule = rrule.RRule
    $scope.binder = modalOptions.object
    
    $scope.flashService = angular.copy(flashService)
    
    $scope.helpService = angular.copy(helpService)
    
    $scope.fortyCore = angular.copy(fortyCore)
    $scope.processingStateService = fortyCore.processingStateService
    $scope.ui_setting = modalOptions.ui_setting
    $scope.goodDates = []
    $scope.enable_calendar = false
    $scope.mutatedRuleInstance = {}
    $scope.flashService.listen_for_alert($scope)
    $scope.usersOrganizations = []
    $scope.is_archived_clicked = false
    $scope.selected = {
      organizations: []
    }
    $scope.selected_assignees = {}

    $scope.date_helpers = {
      start_date: {
        message: '',
        visible: false
      }
    }

    $scope.watchActiveScheduleStatus()
    $scope.watch_schedule_start_date()

    $scope.getFloatingDateString = (date: any) => {
      var floating = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      return floating
    }
    $scope.toUtcFloatingDate = (date: any) => {
      if (date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      } else {
        return undefined
      }
    }
    $scope.doDateMagic = (date: any) => {
      let fl_dateString = $scope.getFloatingDateString(date);
      let fl_dayjs = dayjs.tz(fl_dateString, "Europe/London")

      let flStart = fl_dayjs.startOf('day').toDate()
      let flEnd = fl_dayjs.endOf('day').toDate()

      if ($scope.ui_setting === 'create' || $scope.binder.has_entries === false) {
        $scope.mutatedRuleInstance.options.dtstart = fl_dayjs.add(-1, 'day').toDate()
      }

      // check the recurrence rule against the current day we are looking at.
      // if there's a match it will pop it in isThereMatch in array format
      let isThereMatch = $scope.mutatedRuleInstance.between(flStart, flEnd)
      if (isThereMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    $scope.options = {
      // if there is a recurrence rule, run doDateMagic
      dateDisabled: function (data: any) {
        var date = data.date
        var mode = data.mode
        if ($scope.interval !== undefined && $scope.interval.recurrence_rule.includes('BY')) {
          return mode === 'day' && !$scope.doDateMagic(date)
        } else if ($scope.ui_setting !== 'create' && $scope.binder.has_entries === true && date < dayjs().toDate()) {
          // if create...any date is valid, if update...only today or future dates are valid
          return true
        } else {
          return false
        }
      }
    }

    $scope.handleStoreOrganizationChange = () => {
      if (Array.isArray($scope.selected.organizations) && $scope.selected.organizations.length > 0) {
        $scope.binder.organization_id = $scope.selected.organizations[0].value
        $scope.getOrganizationUsers($scope.binder.organization_id)
      } else {
        $scope.binder.organization_id = undefined
      }
    }

    $scope.handleListOrganizationChange = (id: any) => {
      $scope.binder.organization_id = id
    }

    $scope.getOrganizationUsers = (organization_id: any) => {
      if (!organization_id) {
        try {
          organization_id = $scope.binder.organization_id
        } catch (ex) { }
      }
      if (organization_id) {
        Restangular.all(`organizations/${organization_id}/org_users`).getList(
          {
            'assignable': true,
            'requirement_id': $scope.binder.requirement_id
          }
        ).then((response: any) => {
          $scope.users = response
          $scope.mapped_users = response.map((r: any) => {
            return {
              displayName: r.profile.display_name,
              value: r.id
            }
          })
        }
        , (error: any) => utils.log(error))
      }
    }

    if ($scope.ui_setting !== 'create') {
      $scope.full_binder_loaded = false
      get_full_binder()
      get_organizations()
    } else {
      get_requirement()
    }

    getUsersOrganizations()

    $scope.encourage_nill_annotation = ($scope.binder.requirement.annotation_state === 'annotation_disabled' && $scope.binder.annotation)
  }

  // Prepare Tags for select list

  /* FUNCTIONS */
  $scope.get_checked_assignees = () => {
    if ($scope.binder.user_compliance_admin_id) {
      $scope.selected_assignees.user_compliance = [{ displayName: $scope.binder.user_compliance_admin_name, value: $scope.binder.user_compliance_admin_id }]
    } else if (!$scope.binder.user_compliance_admin_id) {
      $scope.selected_assignees.user_compliance = [{ displayName: 'Unassigned', value: 0 }]
    }
    if ($scope.binder.user_responsible_id) {
      $scope.selected_assignees.user_responsible = [{ displayName: $scope.binder.user_responsible_name, value: $scope.binder.user_responsible_id }]
    } else if (!$scope.binder.user_responsible_id) {
      $scope.selected_assignees.user_responsible = [{ displayName: 'Unassigned', value: 0 }]
    }
    if ($scope.binder.user_assigned_id) {
      $scope.selected_assignees.user_assigned = [{ displayName: $scope.binder.user_assigned_name, value: $scope.binder.user_assigned_id }]
    } else if (!$scope.binder.user_assigned_id) {
      $scope.selected_assignees.user_assigned = [{ displayName: 'Unassigned', value: 0 }]
    }
  }
  $scope.assigneesChanged = () => {
    let selectedAssigneesIds = []
    let selectedAdminIds = []
    let selectedResponsibleIds = []
    if ($scope.selected_assignees.user_assigned) {
      selectedAssigneesIds = $scope.selected_assignees.user_assigned.map((r: any) => r.value)
      $scope.binder.user_assigned_id = selectedAssigneesIds[0]
    }
    if ($scope.selected_assignees.user_compliance) {
      selectedAdminIds = $scope.selected_assignees.user_compliance.map((r: any) => r.value)
      $scope.binder.user_compliance_admin_id = selectedAdminIds[0]
    }
    if ($scope.selected_assignees.user_responsible) {
      selectedResponsibleIds = $scope.selected_assignees.user_responsible.map((r: any) => r.value)
      $scope.binder.user_responsible_id = selectedResponsibleIds[0]
    }
  }

  $scope.lastNameSort = (names: any) => {
    return names.sort(function (s1: any, s2: any) {
      if (s1.profile.display_name.split(' ')[1] < s2.profile.display_name.split(' ')[1]) { return -1 }
      if (s1.profile.display_name.split(' ')[1] > s2.profile.display_name.split(' ')[1]) { return 1 }
      return 0
    });
  }

  $scope.assigneesApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString,
        pagination_params: [{ page: 1, pageSize: 50 }]
      }
    }
    let organization_id = $scope.binder.organization_id
    let searchResults:any = {}
    if (args.apiSearchInputString === '' && organization_id !== undefined) {
      return Restangular.all(`organizations/${organization_id}/org_users`).getList(
        {
          'assignable': true,
          'requirement_id': $scope.binder.requirement_id
        }
      ).then((success: any) => {
        let response = success.plain()
        $scope.users = response
        searchResults = $scope.lastNameSort(response)
        searchResults = searchResults.map((r: any) => {
          return {
            displayName: r.profile.display_name,
            value: r.id
          }
        })
        searchResults.unshift({ displayName: 'Unassigned', value: "" })
        loadedCallback({ items: searchResults, pagination_info: { totalCount: response.length, maxItemCount: response.length } })
      }
      , (error: any) => {
        utils.log(error)
      });
    } else if (organization_id !== undefined) {
      // this happens if the user types in the search bar
      return Restangular.one(`organizations/${organization_id}/parent_assignees`).get(params).then((success: any) => {
        let response = success.plain()
        searchResults.items = $scope.lastNameSort(response.items)
        searchResults.items = searchResults.items.map((r: any) => {
          return {
            displayName: r.profile.display_name,
            value: r.id
          }
        })
        loadedCallback(searchResults)
      }
      , (error: any) => {
        utils.log(error)
      });
    }
  }

  // set default status on page load and set help text on page load
  var setDefaultScheduleStatus = (response: any) => {
    let selected_enum = undefined
    if (response.schedule_on) {
      selected_enum = $scope.enums.schedule_on
    } else if (response.start_date_needed) {
      selected_enum = $scope.enums.start_date_needed
    } else if (response.no_schedule) {
      selected_enum = $scope.enums.no_schedule
    } else if (response.managed_by_paired_application) {
      selected_enum = $scope.enums.managed_by_paired_application
    }
    if (selected_enum !== undefined) {
      $scope.binder.schedule_generation_state = selected_enum
      syncSelectedHelpText()
    }
  }

  var syncSelectedHelpText = () => {
    $scope.active_help_text = $scope.schedule_states.find((s: any) => s.value === $scope.binder.schedule_generation_state).helpText
  }

  var mutateRRuleForValidation = () => {
    // check to see if rule is a 'bymonthday' or 'byday' recurrence rule
    if ($scope.interval.recurrence_rule.includes('BY')) {
      let localRule :any;
      localRule = rrule.RRule.fromString($scope.interval.recurrence_rule)
      // set interval to 1 so the interval doesn't effect valid start dates
      // example: quarterly repeats every 3 months, we want them to start on ANY month of year
      localRule.options = {
        ...localRule.options,
        byhour: [12],
        byminute: [0],
        bysecond: [0],
      }
      localRule.options.interval = 1
      localRule.options.tzid = Intl.DateTimeFormat().resolvedOptions().timeZone
      $scope.mutatedRuleInstance = localRule
    }
  }

  var parse_descriptions = (response) => {
    if(response.assignments) {
      $scope.descriptions = JSON.parse(response.mailbox_pairing_description)
      $scope.pm_count = JSON.parse(response.mailbox_pairing_description).length
    }
  }

  var get_full_binder = () => {
    Restangular.all('binders').get($scope.binder.id).then(function (response: any) { // success
      if (response.requirement.interval) {
        $scope.interval = response.requirement.interval
        mutateRRuleForValidation()
      }
      response.schedule_start_date = dateService.convert_from_imt_date(response.schedule_start_date)
      // format_database_date
      // temp for now until we convert other models to generation status(entry, org, requirements)
      response.state.archived = response.archived === true ? true : null
      $scope.binder = response
      parse_descriptions(response)
      // load schedule statess
      $scope.initializeScheduleStates()
      syncSelectedHelpText()
      // used for date validation in save function
      $scope.original_start_date = $scope.binder.schedule_start_date
      // the date picker field will throw validation error if start date is null so we set it to empty so we can save
      // with a start date needed
      if ($scope.binder.schedule_generation_state === 'start_date_needed') { $scope.binder.schedule_start_date = '' }
      $scope.get_checked_assignees()
      $scope.full_binder_loaded = true
      $scope.enable_calendar = true
      if (response.organization_id) { $scope.getOrganizationUsers(response.organization_id) }
    }
    , (error: any) => utils.log(error))
  }

  var get_requirement = () => {
    Restangular.one('requirements', $scope.binder.requirement_id).get().then(function (response: any) {
      if (response.interval) {
        $scope.interval = response.interval
        mutateRRuleForValidation()
      }
      $scope.enable_calendar = true
      // load  schedule states
      $scope.initializeScheduleStates()
      setDefaultScheduleStatus(response)
      return $scope.binder.requirement = response
    })
  }

  var get_organizations = () => {
    Restangular.all('organizations').getList().then(function (response: any) { // success
      $scope.organizations = response
      
      return $scope.organization_select_options = angular.copy(flatten_organizations($scope.organizations))
    }
    , (error: any) => utils.log(error))
  }

  var getUsersOrganizations = () => {
    let params = { param_data: { /* if they want visibility, defualt it here */ } }
    Restangular.all('organizations/filter').getList(params).then(
      (success: any) => {
        let usersOrganizations = success.plain()
        usersOrganizationsLoaded(usersOrganizations)
      },
      (error: any) => utils.log(error)
    )
  }
  var usersOrganizationsLoaded = (usersOrganizations: any) => {
    if (!usersOrganizations) {
      return
    }
    var flattenedOrganizations = arrayFunctions.flattenNestedArrayRecursive(usersOrganizations)

    $scope.usersOrganizations = flattenedOrganizations.filter((r: any) => {
      return !r.state.archived
    }).map((r: any) => {
      return { displayName: r.name, value: r.id, level: r.level, entryId: r.entryId, parentId: r.parent_id, hasDescendants: r.hasDescendants }
    })
    if ($scope.ui_setting === 'create') {
      var pills = filterService.getCached()
      var orgPills = []
      if (Array.isArray(pills) && pills.length > 0) {
        orgPills = pills.filter(p => p.Field === 'organizations_ids')
      }
      if (orgPills.length === 1 && !orgPills[0].includeDescendants) {
        $scope.binder.organization_id = orgPills[0].Value
        $scope.getOrganizationUsers($scope.binder.organization_id)
      } else {
        $scope.binder.organization_id = undefined
      }
    }

    if ($scope.binder.organization_id) {
      var selectedOrg = $scope.usersOrganizations.find((o: any) => o.value === $scope.binder.organization_id)
      selectedOrg.ticked = true
    }
  }

  // creates a flattened array of the nested $scope.organization_select_options array and updates
  // the name with spacing for tree structure
  var flatten_organizations = function (organizations_array: any) {
    organizations_array.sort(function (a: any, b: any) {
      if (a['name'].toLowerCase() > b['name'].toLowerCase()) {
        return 1
      } else {
        return -1
      }
    })
    let flattened_organizations = []
    let i = 0
    while (i < organizations_array.length) {
      organizations_array[i].name = `${determine_depth(organizations_array[i])}${organizations_array[i].name}`
      flattened_organizations.push(organizations_array[i])
      flattened_organizations = flattened_organizations.concat(flatten_organizations(organizations_array[i]['children']))
      i++
    }
    return flattened_organizations
  }

  // creates dynamic indentions for the organizations select menu
  // TODO maybe not the best name.
  var determine_depth = function (organization: any) {
    let spacing = ''
    let i = 0
    while (i < organization.depth) {
      spacing += '\u00A0\u00A0'
      i++
    }
    return spacing
  }

  var getPairedHelpText = function () {
    if ($scope.ui_setting === 'create') {
      return $scope.helpService.get_tooltip('store_schedule_status.managed_by_paired_application.helpText')
    } else {
      if ($scope.binder.mailbox_pairing_configured) {
        return $scope.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.helpTextPaired')
      } else {
        return $scope.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.helpTextNotPaired')
      }
    }
  }

  $scope.initializeScheduleStates = function () {
    let ui = $scope.ui_setting
    let role_title = $rootScope.session.getUser().user_role.role.title
    $scope.schedule_states = [
      {
        value: $scope.enums.schedule_on,
        visible: $scope.binder.requirement.schedule_on,
        display: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.schedule_on.display') : $scope.helpService.get_tooltip('edit_schedule_status.schedule_on.display'),
        helpText: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.schedule_on.helpText') : $scope.helpService.get_tooltip('edit_schedule_status.schedule_on.helpText')
      },
      {
        value: $scope.enums.no_schedule,
        visible: $scope.binder.requirement.no_schedule,
        display: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.no_schedule.display') : $scope.helpService.get_tooltip('edit_schedule_status.no_schedule.display'),
        helpText: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.no_schedule.helpText') : $scope.helpService.get_tooltip('edit_schedule_status.no_schedule.helpText')
      },
      {
        value: $scope.enums.start_date_needed,
        visible: $scope.binder.requirement.start_date_needed,
        disabled: role_title !== 'System Admin' && role_title !== 'Organization Admin' && role_title !== 'Reviewer',
        display: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.start_date_needed.display') : $scope.helpService.get_tooltip('edit_schedule_status.start_date_needed.display'),
        helpText: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.start_date_needed.helpText') : $scope.helpService.get_tooltip('edit_schedule_status.start_date_needed.helpText')
      },
      {
        value: $scope.enums.managed_by_paired_application,
        visible: $scope.binder.requirement.managed_by_paired_application,
        disabled: true,
        display: ui === 'create' ? $scope.helpService.get_tooltip('store_schedule_status.managed_by_paired_application.display') : $scope.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.display'),
        helpText: getPairedHelpText()
      }
    ]
  }

  $scope.show_me_the_money = () => console.log($scope.binder)

  $scope.get_assignment_instructions_help_text = (role: any) => {
    if (role === 'Compliance Leader') {
      if ($scope.binder.requirement.compliance_leader_assignment_instructions) {
        return $scope.binder.requirement.compliance_leader_assignment_instructions
      } else {
        return $scope.helpService.get_tooltip('binder.user_compliance_admin')
      }
    } else if (role === 'Program Leader') {
      if ($scope.binder.requirement.program_leader_assignment_instructions) {
        return $scope.binder.requirement.program_leader_assignment_instructions
      } else {
        return $scope.helpService.get_tooltip('binder.user_responsible')
      }
    } else if (role === 'Task Agent') {
      if ($scope.binder.requirement.task_agent_assignment_instructions) {
        return $scope.binder.requirement.task_agent_assignment_instructions
      } else {
        return $scope.helpService.get_tooltip('binder.user_assigned')
      }
    }
  }

  // watch schedule start date and flip schedule status to schedule on if start date is entered and
  // start date needed is active status in dropdown
  $scope.watch_schedule_start_date = function () {
    $scope.$watch('binder.schedule_start_date', (newDate: any, oldDate: any) => {
      if (newDate !== oldDate && $scope.ui_setting !== 'create' && $scope.full_binder_loaded) {
        if ($scope.binder.schedule_generation_state === $scope.enums.start_date_needed && $scope.is_date_object(newDate)) {
          $scope.binder.schedule_generation_state = $scope.enums.schedule_on
          syncSelectedHelpText()
        }
      }
    })
  }

  // make sure help text matches up when user selects new status
  $scope.watchActiveScheduleStatus = (active_schedule_status: any, form: any) => {
    if (active_schedule_status !== undefined) {
      let match = $scope.schedule_states.find((option: any) => {
        return option.value === active_schedule_status
      })
      if (active_schedule_status === 'start_date_needed') {
        $scope.binder.schedule_start_date = ''
      }
      $scope.active_help_text = match.helpText
      $scope.active_schedule_status = active_schedule_status
    }
    // disable start date if status is not schedule enabled
    $scope.is_start_date_disabled()
  }

  $scope.submit_form = (form: any, object: any) => {
    $scope.date_helpers.start_date.visible = false
    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(form, function () {
      // checking that a date was entered, checking that rrule agrees with the date.
      // this was added because if you just used the input field and never clicked the calendar, the form would always be valid
      // if (object.schedule_start_date === null ||
      //   ($scope.interval !== undefined &&
      //   $scope.interval.recurrence_rule.includes('BY') &&
      //   $scope.doDateMagic(object.schedule_start_date) === false)) {
      //   $scope.date_helpers.start_date.message = 'Please select a valid date in accordance with the interval rules.'
      //   $scope.date_helpers.start_date.visible = true
      //   return $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
      // }
      if (form.$valid) {
        // skip past date validation when we are in create OR when there are no entries on the binder AND we are in a schedule state of schedule on
        if ($scope.ui_setting === 'create') {
          if (object.organization_id === undefined && $scope.ui_setting === 'create') {
            form.failed_submission = true
            return $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
          }
          return save_new_binder_and_close_modal(object)
        } else {
          // call validator in application_service, this returns an error count
          // only validate when field has been changed
          let number_of_errors = 0
          if ($scope.binder.schedule_start_date !== $scope.original_start_date && $scope.binder.has_entries === true && $scope.binder.schedule_generation_state === 'schedule_on') {
            number_of_errors = dateService.binder_date_validation($scope.date_helpers, object)
          } else {
            number_of_errors = 0
          }
          if (number_of_errors === 0) {
            // successful save path
            $scope.date_helpers.start_date.visible = false
            return start_save(object)
          } else {
            // fail submission path
            form.failed_submission = true
            $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
          }
        }
      } else {
        $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
        return form.failed_submission = true
      }
    })
  }

  $scope.shouldShowCompleteOption = function (scheduleState: any) {
    return scheduleState.visible
  }

  $scope.toggle_schedule_enabled = function (binder: any) {
    // don't allow schedule toggling when the binder is paired
    if (
      ($scope.full_binder_loaded || $scope.ui_setting === 'create') &&
      !binder.mailbox_pairing_configured
    ) {
      // Pre-Toggle, save the schedule_start_date if avaliable
      if ($scope.is_date_object(binder.schedule_start_date)) {
        binder.previous_schedule_start_date = binder.schedule_start_date
      }

      // Toggle
      binder.schedule_enabled = !binder.schedule_enabled

      // Post-Toggle, populate the schedule_start_date
      if (binder.schedule_enabled) {
        if (binder.previous_schedule_start_date) {
          binder.schedule_start_date = binder.previous_schedule_start_date
        } else {
          binder.schedule_start_date = dayjs().$d
        }
      } else { // !binder.schedule_enabled
        binder.schedule_start_date = null
      }

      $scope.binder_form.field_schedule_switch.$setDirty()
    }
  }

  $scope.is_start_date_disabled = function () {
    if ($scope.ui_setting === 'read') {
      return true
    } else if (($scope.ui_setting === 'create' && $scope.binder.schedule_generation_state === $scope.enums.start_date_needed) || $scope.binder.schedule_generation_state === $scope.enums.no_schedule || $scope.binder.schedule_generation_state === $scope.enums.managed_by_paired_application) {
      return true
    } else if (
      $scope.ui_setting === 'update' &&
      $rootScope.session.getUser().user_role.role.title === 'System Admin'
    ) {
      return false
    } else if (
      $scope.ui_setting === 'update' &&
      ($rootScope.session.getUser().user_role.role.title === 'Organization Admin' || $rootScope.session.getUser().user_role.role.title === 'Reviewer')
    ) {
      return false
    } else if ($scope.ui_setting === 'create') {
      return false
    } else {
      return true
    }
  }

  // Check if schedule start date is a real date for use in view
  $scope.is_date_object = function (date: any) {
    // dayJS returns true for isValid on undefined
    if (date === undefined) {
      return false
    } else {
      return dayjs(date).isValid()
    }
  }

  /* SAVE PROCESS COUNTERS */
  $scope.fault_counter = undefined
  $scope.pending_requests = undefined

  const increment_fault_counter = () => $scope.fault_counter++

  const increment_pending_requests = () => $scope.pending_requests++

  const decrement_pending_requests = function () {
    $scope.pending_requests--
    if ($scope.pending_requests === 0) {
      if ($scope.fault_counter === 0) {
        return after_save($scope.binder)
      } else {
        return finally_do()
      }
    }
  }

  /* SAVE TRIGGERS */
  var start_save = function (object: any) {
    let clone = angular.copy(object)
    if (clone.schedule_start_date) {
      clone.schedule_start_date = $scope.toUtcFloatingDate(clone.schedule_start_date)
    }
    $scope.pending_requests = 0
    $scope.fault_counter = 0
    save_base_object(clone)
    return trigger_attachment_manager_start(clone)
  }

  var after_save = (object: any) => trigger_state_manager_start(object)

  var finally_do = function () {
    $scope.processingStateService.set($scope.binder_form, $scope.processingStateService.processing_states.RESTING)
    if ($scope.fault_counter === 0) {
      return closeModalAndReturn($scope.binder)
    }
  }

  /* SAVE FUNCTIONS / LISTENERS */
  var save_new_binder_and_close_modal = (object: any) => {
    let clone = angular.copy(object)
    if (clone.schedule_start_date) {
      clone.schedule_start_date = $scope.toUtcFloatingDate(clone.schedule_start_date)
    }
    Restangular.all('binders').post(clone).then(
      (success: any) => closeModalAndReturn(success), 
      (error: any) => $scope.flashService.process_error(error)
    ).finally(() => $scope.processingStateService.set($scope.binder_form, $scope.processingStateService.processing_states.RESTING))
  }

  var save_base_object = function (object: any) {
    increment_pending_requests()
    return Restangular.one('binders', object.id).patch({ binder: object }).then((success: any) => $scope.binder = success, function (error: any) {
      increment_fault_counter()
      return $scope.flashService.process_error(error)
    }).finally(() => decrement_pending_requests());
  }

  var trigger_attachment_manager_start = function (object: any) {
    increment_pending_requests()
    return $scope.$broadcast('attachment_manager_start', object)
  }

  $scope.$on('attachment_manager_finished', function (event: any, manager_result: any) {
    $scope.binder.attachments = manager_result.attachments
    if (manager_result.fault) { increment_fault_counter() }
    return decrement_pending_requests()
  })

  var trigger_state_manager_start = (object: any) => $scope.$broadcast('state_manager_start', object)

  $scope.$on('state_manager_finished', function (event: any, manager_result: any) {
    // Only update the state when changes were made
    if (manager_result.object !== null) {
      $scope.binder.state = manager_result.object

      // We revoke this on the api, but due to the order of this 'save relay',
      // its not really represented in the return objects. so we set it here.
      if ($scope.binder.archived === true) {
        $scope.binder.mailbox_pairing_configured = false
      }
    }
    if (manager_result.fault) { increment_fault_counter() }
    return finally_do()
  })

  $scope.navigate_to_management_url = function (binder: any) {
    window.open(
      binder.mailbox_pairing_management_url,
      '_blank'
    )
  }

  $scope.openBinderDestroyModal = function (binder: any) {
    let modalReady = (binder_stats :any) => {
      const confirmation_object = {
        confirm() { return $scope.destroy_binder(binder) },
        message:
          `<p>${binder_stats.task_count} tasks and all attached documents (${binder_stats.attachment_count}) will be deleted and this requirement will no longer be configured for your Organization. None of these documents will be available for your surveying entity to view.  THIS ACTION IS NOT REVERSIBLE</p>`
      }

      return $scope.processingStateService.performFunction(binder, () =>
        confirmationModalFactory.openConfirmationModal(
          confirmation_object
        ).then(function (result: any) {
          if (!result) {
            $scope.processingStateService.set(
              binder,
              $scope.processingStateService.processing_states.RESTING
            )
          }
          return true
        })
      );
    }
  
    Restangular.one(`binders/${$scope.binder.id}/binder_stats`).get().then((success: any) => {
      console.log('success:', success)
      let binder_stats = success.plain()
      modalReady(binder_stats)
    }, function (error: any) {
      console.log('err:', error)
    });
  }

  $scope.destroy_binder = (binder: any) => {
    $scope.processingStateService.set($scope.binder_form, $scope.processingStateService.processing_states.PENDING)
    Restangular.all(`binders/${binder.id}`).remove().then(
      (success: any) => {
        if ($location.path().includes('/requirements/binders')) {
          $window.location.reload();
        } else {
          $rootScope.session.goTo('/requirements/binders')
        }
      },
      (error: any) => {
        $scope.processingStateService.set(
          $scope.binder_form,
          $scope.processingStateService.processing_states.RESTING
        )
        $scope.flashService.process_error(error)
        return utils.log(error)
      }
    )
  }

  $scope.$on('state_manager_updated', function (event: any, archived_value: any) {
    // disable save button if archived is clicked and binder is paired
    $scope.is_archived_clicked = archived_value
  })

  /* MODAL FUNCTIONS */
  var closeModalAndReturn = (object: any) => $uibModalInstance.close(object)

  $scope.closeModal = () => $uibModalInstance.dismiss('cancel')

  /* INIT */
  return initialize()
}])
