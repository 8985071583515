// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationSurveyModeController', ['$interval','$q','arrayFunctions','$rootScope','$scope','$uibModal','confirmationModalFactory','dateService','flashService','fortyCore','loadingStateService','Restangular',function (
//   $interval: any,
//   $q: any,
//   arrayFunctions: any,
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   confirmationModalFactory: any,
//   dateService: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.flashService = angular.copy(flashService)
//     $scope.binderLSS = angular.copy(loadingStateService)
//     $scope.binders = []
//     $scope.entityFilterOptions = []
//     $scope.searchFilter = ''
//     $scope.quickSearchFilter = undefined
//     $scope.previousPills = null
//     $scope.outstandingPromises = []

//     const custom_states = {
//       activeorgprompt: {
//         name: 'activeorgprompt',
//         icon: 'fa-building',
//         text: 'Organization Required. Please select from filter above.'
//       }
//     }
//     $scope.binderLSS.loading_states = _.merge($scope.binderLSS.loading_states, custom_states)
//     $scope.binderLSS.init()
    
//     $scope.quickSearchLSS = angular.copy(loadingStateService)
//     $scope.quickSearchLSS.init()

//     $scope.watchEntityFilter()

//     // Manual searching
//     $scope.watchSearchFilter()
//     $scope.search_helpers = [
//       'Interval (e.g. weekly, monthly, annual)',
//       "Requirement (e.g. 'exit sign inspection')",
//       'Reference number (e.g. 02.03.05, EP 3)'
//     ]
//     $scope.current_search_helper = $scope.search_helpers[0]

//     $scope.watch_date_filter()
//     watchDashboardFilter()

//     // END INIT
//   }


//   $scope.display_entity_filter = (pills) => {
//     if (!$scope.activeOrganizationPresent) {
//       $scope.binderLSS.set_state('activeorgprompt')
//     } else {
//       $scope.entityFilterOptions = [
//         {
//           key: 'all',
//           filterValue: '',
//           displayLabel: 'All Surveying Entities'
//         },
//         {
//           key: 'aaahc',
//           filterValue: 'Accreditation Association for Ambulatory Health Care (AAAHC)',
//           displayLabel: 'Accreditation Association for Ambulatory Health Care (AAAHC)'
//         },
//         {
//           key: 'cap',
//           filterValue: 'College of American Pathologists Accreditation Program (CAP)',
//           displayLabel: 'College of American Pathologists Accreditation Program (CAP)'
//         },
//         {
//           key: 'tjc-ahc',
//           filterValue: 'The Joint Commission - Ambulatory Health Care (TJC AHC)',
//           displayLabel: 'The Joint Commission - Ambulatory Health Care (TJC AHC)'
//         },
//         {
//           key: 'tjc-hap',
//           filterValue: 'The Joint Commission - Hospital Accreditation Program (TJC HAP)',
//           displayLabel: 'The Joint Commission - Hospital Accreditation Program (TJC HAP)',
//           quickSearchURL: 'reference_objects/aspects/tjc_full_list',
//           quickSearchArray: []
//         },
//         {
//           key: 'tjc-hap',
//           filterValue: 'The Joint Commission - Lab Accreditation Program (TJC LAB)',
//           displayLabel: 'The Joint Commission - Lab Accreditation Program (TJC LAB)'
//         },
//         {
//           key: 'uca',
//           filterValue: 'Urgent Care Association (UCA)',
//           displayLabel: 'Urgent Care Association (UCA)'
//         }
//       ]
//       $scope.get_binders(pills)
//       emit_reset_date_range()

//       $scope.documentReviewState = $rootScope.session.data.documentReview

//       if ($scope.documentReviewState.entityFilterKey) {
//         $scope.entityFilter = $scope.entityFilterOptions.find((option: any) => {
//           return option.key === $scope.documentReviewState.entityFilterKey
//         })
//       } else {
//         $scope.entityFilter = $scope.entityFilterOptions[0]
//       }
//     }
//   }

//   $scope.isOrgPillSelected = (pills: any) => {
//     $scope.activeOrganizationPresent = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
//     $scope.display_entity_filter(pills)
//   }

//   $scope.abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   var watchDashboardFilter = () => {
//     $scope.$on('filterChange-dashboard', (e: any, pills: any) => {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted', 'pagination_params', 'acknowledged_scope']) || $scope.previousPills === null
//       $scope.previousPills = pills
//       if(widgetShouldRefresh) {
//         $scope.abortAllPromises($scope.outstandingPromises)
//         setTimeout(() => $scope.isOrgPillSelected(pills), 50)
//       }
//     })
//   }

//   $scope.get_binders = function (pills) {
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       ),
//       date_range_begin: dayjs().subtract(10, 'years').$d,
//       date_range_end: dayjs().$d
//     }

//     $scope.binderLSS.set_state('loading')

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     Restangular.all('binders/survey_mode').withHttpConfig({ timeout: abort.promise }).getList(query_params).then((success: any) => {
//       // prevent the no content loading message
//       $scope.binderLSS.process_success({ status: 200 })
//       $scope.binders = success
//       $scope.entityFilteredBinders = success
//       $scope.order_binders_by_function = $scope.order_binders_by_interval
//       start_text_carousel()
//       restore_quick_search()

//       emit_reset_active_preset()
//       $scope.quickSearchFilter = undefined
//       emit_reset_date_range()
//     }, (error: any) => {
//       $scope.binderLSS.process_error(error)
//     }).finally(function () {
//       return true
//     });
//   }

//   let restore_quick_search = () => {
//     // restore quicksearch filter selection if one was made
//     if (
//       $scope.documentReviewState.quickSearchHistory &&
//       $scope.documentReviewState.quickSearchHistory.tjc &&
//       $scope.quickSearchFilter
//     ) {
//       const searchArray = $scope.documentReviewState.quickSearchHistory.tjc
//       $scope.applyQuickSearch(searchArray[searchArray.length - 1])
//     }
//   }

//   $scope.update_binder_processing_state = function (binder: any) {
//     // binders dont have this property by default.
//     // so if its the first time opening the binders modal, we need to give it a state.
//     if (binder.processing_state === undefined) {
//       binder.processing_state = $scope.processingStateService.processing_states.RESTING
//     }

//     const binder_resting = binder.processing_state === $scope.processingStateService.processing_states.RESTING

//     if (binder_resting) {
//       return $scope.processingStateService.set(binder, $scope.processingStateService.processing_states.PENDING)
//     } else {
//       return $scope.processingStateService.set(binder, $scope.processingStateService.processing_states.RESTING)
//     }
//   }

//   // BATCH REVIEW METHODS

//   $scope.openBatchReviewModal = function (binder: any, ui_setting: any) {
//     $scope.selected_binder = binder
//     $scope.update_binder_processing_state(binder)

//     return Restangular.all(`binders/${binder.id}/entry_review`).getList($scope.date_params).then(function (success: any) {
//       binder.entries = success
//       $scope.launch_batch_review_modal(binder.plain(), ui_setting)
//     });
//   }

//   $scope.launch_batch_review_modal = function (binder_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/notes/entry_validation_mode_comment.html',
//       controller: 'entryValidationModeCommentModalController',
//       size: 'xl',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(binder_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // for this modal instance, we arent passing back an updated object
//     // so we skip both the success and failure block and head straight for the finally
//     // for the sole purpose of updating the binder processing state
//     return modalInstance.result.then(function (result: any) {
//       console.log(result)
//     }, function (reason: any) {
//       console.log(reason)
//     }).finally(() => {
//       $scope.update_binder_processing_state($scope.find_binder($scope.selected_binder.id))
//     });
//   }

//   /* QUICK SEARCH */

//   $scope.quickSearchPanelVisible = function () {
//     return (
//       $scope.entityFilter &&
//       $scope.entityFilter.quickSearchURL &&
//       $scope.entityFilter.quickSearchArray && 
//       $scope.activeOrganizationPresent
//     )
//   }

//   $scope.applyQuickSearch = function (quickSearchString: any) {
//     $scope.quickSearchFilter = quickSearchString
//     // unset the searchFilter, there can be only one.
//     $scope.searchFilter = undefined
//     $scope.filterBinders()
//     $scope.addToQSHistory(quickSearchString)
//   }

//   $scope.watch_date_filter = () => {
//     return $rootScope.$on('date_filter_updated', (scope: any, object: any) => {
//       $scope.date_params = object
//       $scope.filterBinders()
//     });
//   }

//   let emit_reset_date_range = function () {
//     $rootScope.$emit('reset_date_filter')
//   }

//   let emit_reset_active_preset = function () {
//     $rootScope.$emit('reset_active_preset')
//   }

//   $scope.addToQSHistory = function (quickSearchString: any) {
//     if (!$scope.inQSHistory(quickSearchString)) {
//       emit_reset_date_range()
//       $scope.entityQSHistory().push(quickSearchString)
//     }
//   }

//   $scope.entityQSHistory = function () {
//     if ($scope.documentReviewState.quickSearchHistory === undefined) {
//       $scope.documentReviewState.quickSearchHistory = {}
//     }
//     if ($scope.documentReviewState.quickSearchHistory[$scope.entityFilter.key] === undefined) {
//       $scope.documentReviewState.quickSearchHistory[$scope.entityFilter.key] = []
//     }

//     return $scope.documentReviewState.quickSearchHistory[$scope.entityFilter.key]
//   }

//   $scope.inQSHistory = function (quickSearchString: any) {
//     return $scope.entityQSHistory().find((historyItem: any) => {
//       return historyItem === quickSearchString
//     });
//   }

//   $scope.removeFromQSHistory = function (quickSearchString: any) {
//     let historyIndex = $scope.entityQSHistory().findIndex((historyItem: any) => {
//       return historyItem === quickSearchString
//     })
//     $scope.entityQSHistory().splice(historyIndex, 1)

//     if ($scope.entityQSHistory().length === 0) {
//       $scope.quickSearchFilter = undefined
//     }
//   }

//   $scope.clearQSHistory = function () {
//     $scope.documentReviewState.quickSearchHistory[$scope.entityFilter.key] = []
//     $scope.quickSearchFilter = undefined
//     emit_reset_active_preset()
//     emit_reset_date_range()
//   }

//   // Get the quickSearchArray from the quickSearchUrl
//   $scope.getQSArrayOnFirstTouch = (entity: any) => {
//     if (entity.quickSearchURL && entity.quickSearchArray.length === 0) {
//       let query_params = {
//         filter: 'full'
//       }
//       $scope.quickSearchLSS.set_state('loading')

//       Restangular.one(entity.quickSearchURL).get(query_params).then(function (success: any) {
//         entity.quickSearchArray = success.sort(sortAlphaNum)
//         $scope.quickSearchLSS.set_state('content')
//       }, (error: any) => {
//         $scope.quickSearchLSS.process_error(error)
//       })
//     }
//   }

//   /* ENTITY FILTER */

//   $scope.watchEntityFilter = function () {
//     $scope.$watch('entityFilter', (newEntity: any, oldEntity: any) => {
//       if(newEntity) {
//         $scope.getQSArrayOnFirstTouch(newEntity)
//         $scope.entityFilter = newEntity
//         $scope.documentReviewState.entityFilterKey = newEntity.key
//       }
//     })
//   }

//   $scope.canLockEntityFilter = function (entity_filter_form: any) {
//     if (entity_filter_form.entity_filter_drop_list.$modelValue.key === 'all') {
//       return entity_filter_form.failed_submission = true
//     } else {
//       $scope.confirmLockEntityFilter()
//     }
//   }

//   $scope.confirmLockEntityFilter = function () {
//     // build object for user to confirm.
//     const confirmation_object = {
//       severity: 'warning',
//       title: 'Confirm Lock',
//       button_icon: 'fa-lock',
//       button_text: 'Lock',
//       message: `Are you sure you want to apply a survey lock for \
//                 '${$scope.entityFilter.displayLabel}'? <br> \
//                 Logging out will be required to undo this action`,
//       // action to be called on confirm
//       confirm () {
//         // entity filter select visibility is based on session.isNavigationLocked()
//         $rootScope.session.lockNavigation()
//       }
//     }
//     // call the confirmation modal allow user to choose.
//     confirmationModalFactory.openConfirmationModal(confirmation_object)
//   }

//   /* FILTER */

//   $scope.watchSearchFilter = function () {
//     $scope.$watch('searchFilter', (newValue: any, oldValue: any) => {
//       if (newValue !== undefined) {
//         emit_reset_active_preset()
//         // unset the quickSearchFilter, there can be only one filter
//         $scope.quickSearchFilter = undefined
//         // we want to reset the date range filter to 1 year each time
//         // a new search is entered and call filterBinders from there
//         emit_reset_date_range()
//       }
//     }, true)
//   }

//   // the ng-change method that occurs when the search bar changes
//   // kicks off the filtering off the filtering process
//   $scope.filterBinders = function () {
//     $scope.reset_reference_match()
//     $scope.entityFilteredBinders = []
//     $scope.dateFilteredBinders = []
//     $scope.searchFilteredBinders = []

//     // stage 1
//     // Start with binders, no filter whatsoever.
//     if ($scope.entityFilter) {
//       // Apply the entityFilter, and save to pre_filterd_binders.
//       _.each($scope.binders, function (binder: any) {
//         if ($scope.binder_text_filter(binder, $scope.entityFilter.filterValue)) {
//           return $scope.entityFilteredBinders.push(binder)
//         }
//       })
//     } else {
//       $scope.entityFilteredBinders = $scope.binders
//     }

//     // stage 2
//     // apply the date filter
//     _.each($scope.entityFilteredBinders, function (binder: any) {
//       if (binder_date_filter(binder, $scope.date_params)) {
//         return $scope.dateFilteredBinders.push(binder)
//       }
//     })

//     // stage 3
//     // Start with result of stage 2, all the entityFilter action applied.
//     // Apply the searchFilter, and save to searchFilteredBinders.
//     return _.each($scope.dateFilteredBinders, function (binder: any) {
//       // only one search type can be used at a time, the other is set to undefined
//       if ($scope.quickSearchFilter) {
//         if ($scope.reference_filter(binder, $scope.quickSearchFilter)) {
//           return $scope.searchFilteredBinders.push(binder)
//         }
//       } else if ($scope.searchFilter) {
//         if ($scope.binder_text_filter(binder, $scope.searchFilter)) {
//           return $scope.searchFilteredBinders.push(binder)
//         }
//       }
//     });
//   }

//   // called during the filter on change method.
//   // allows for the reference match to be reset, in case the
//   // user isnt searching by references anymore.
//   $scope.reset_reference_match = () => $scope.reference_match = false

//   // reference_filter used by the quickSearch sidebar
//   $scope.reference_filter = function (binder: any, query: any) {
//     let match = false
//     const search_string = query.toLowerCase()
//     _.each(binder.requirement.reference_objects, function (reference_object: any) {
//       if (reference_object.value.toLowerCase() === search_string) {
//         return match = true
//       }
//     })
//     return match
//   }

//   // binder live filter
//   $scope.binder_text_filter = function (binder: any, query: any) {
//     let match = false
//     const search_string = query.toLowerCase()

//     if (search_string === '') {
//       match = true
//     }
//     if ((binder.display_name.toLowerCase()).indexOf(search_string) >= 0) {
//       match = true
//     }
//     if (binder.requirement.interval && (binder.requirement.interval.name.toLowerCase()).indexOf(search_string) >= 0) {
//       match = true
//     }
//     _.each(binder.requirement.reference_objects, function (reference_object: any) {
//       if ((reference_object.value.toLowerCase()).indexOf(search_string) >= 0) {
//         return match = true
//       }
//     })
//     _.each(binder.requirement.tags, function (tag: any) {
//       if ((tag.title.toLowerCase()).indexOf(search_string) >= 0) {
//         return match = true
//       }
//     })

//     return match
//   }

//   let binder_date_filter = function (binder: any, date_params: any) {
//     let match = false
//     if (
//       date_params.date_range_begin <= binder.last_entry_date &&
//       date_params.date_range_end >= binder.first_entry_date
//     ) {
//       match = true
//     } else {
//       match = false
//     }
//     return match
//   }

//   // searches the references object and checks to see if the user is searching by a reference value
//   // if they are we set reference_match to true and only show the references that match the
//   // search criteria. Else, we hide the refernces column.
//   $scope.relevant_references = function (reference_object: any) {
//     // only one of these filters will be set at a time.
//     // Yeah, its probably not great, but its a hotfix...
//     const activeFilter = $scope.searchFilter || $scope.quickSearchFilter || ''
//     const search_string = activeFilter.toLowerCase()

//     const check_match = function (reference_object: any) {
//       if (((reference_object.value.toLowerCase()).indexOf(search_string) >= 0) && (search_string !== '')) {
//         $scope.reference_match = true
//         return true
//       } else {
//         return false
//       }
//     }

//     return check_match(reference_object)
//   }

//   /* SORT */

//   $scope.order_binders_by_display_name = (binder: any) => binder.display_name

//   // document names are alphanumeric strings
//   // default sorting doesn't work because string values are compares so the resuts return as
//   // [A1, A11, A12, ...]
//   // This performs an alphanumeric sort so we get
//   // [A9, A10, A11]
//   const sortAlphaNum = (a: any, b: any) => {
//     a = String(a.value)
//     b = String(b.value)
//     let value = a.localeCompare(b, 'en', { numeric: true })
//     return value
//   }

//   /* MISC */

//   $scope.find_binder = (binder_id: any) => {
//     return _.find($scope.searchFilteredBinders, (b: any) => b.id === binder_id);
//   }

//   // used to create a carousel that rotates the search helper messages
//   // stopper is a promise that allows the carousel to essentially 'stop' when a
//   // search or filter occurs without this the timer goes haywire. to prevent the
//   // haywire, the stopper stops the current timer and when get binders is called
//   // again a new timer is established
//   let stopper: any
//   var start_text_carousel = () => {
//     stopper = $interval(() =>
//       $('.fade-toggle').fadeOut(500, () =>
//         setTimeout(function () {
//           let current_search_helper_index = $scope.search_helpers.indexOf($scope.current_search_helper)
//           current_search_helper_index++
//           if (current_search_helper_index === 3) {
//             $scope.current_search_helper = $scope.search_helpers[0]
//           } else {
//             $scope.current_search_helper = $scope.search_helpers[current_search_helper_index]
//           }

//           return $('.fade-toggle').fadeIn(500)
//         }, 0)
//       ), 7000)
//   }

//   // the next 2 methods are helpers to stop the carousel.
//   // the stop functionality is also called when a user creates a new date filter and is restarted after loading
//   var stop_text_carousel = () => $interval.cancel(stopper)

//   // used to ensure that when scope is destroyed the timer is destroyed as well
//   $scope.$on('$destroy', () => stop_text_carousel())

//   return init()
// }])
