import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngHelp',
  templateUrl: './help.component.html'
})
export class HelpComponent implements OnInit {
  // Injected Services

  // Properties
  private steps: Array<any>;
  private itemsPerRow: number;
  private rowCount: number[];
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
  ) { 
  }

  
  ngOnInit(): void {
    this.steps = [
      {
        number: '1',
        icon: 'fa-file-o',
        label: 'Getting Started',
        text: 'Learn more about how to get started, overview, navigation tips and what is needed.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'Getting Started',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EW8asRBvvypPljftJhFZ9h0BDqZf-F9u6Y3z79kSGAA5Fw'
              },
              {
                text: 'Subscribe to receive scheduled maintenance, system downtime, new features and updates notifications (add All.DL Flash Complyos)',
                url: 'http://flash.app.medcity.net/Subscribe/Subscription'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'Getting Started - Complyos Overview',
                url: 'https://mediaconnect.app.medcity.net/sharevideo/19530f48-5bf1-40aa-8cde-090025437e25'
              }
            ]
          }
        ]
      },
      {
        number: '2',
        icon: 'fa-user',
        label: 'Users',
        text: 'Grant system permissions and manage users.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'Users Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/Eds2y3Wyc9lFn4RtUFgZbWcBVrFEA8GyQtCrdHqsJLkK8Q'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'User access through eSAF',
                url: 'https://mediaconnect.app.medcity.net/sharevideo/ffea5047-c84d-4bcb-bb1b-55fce81efa63'
              }
            ]
          }
        ]
      },
      {
        number: '3',
        icon: 'fa-pencil',
        label: 'Requirements Management',
        text: 'Configure requirements that relate to your organization from the Store. Manage configured requirements in the List.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'Store Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EU8oYWZz_XxEq-_CIK43M6cBUHwmZ50nN5tt8buPlZF3IA'
              },
              {
                text: 'List Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/Ed0f1jaOrUxHuEUCn1FJJEEBDPE6QaLltfxg33bcpccjow'
              },
              {
                text: 'Configured Requirements Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EQ-KOU_R691OtFZn1eR0RywBENgfdaMJ48KPMzZi1u8GlA'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'Store Overview',
                url: 'http://orblinks.hcaqa.corpadqa.net/OnlineHelp/Captivates/TW/Complyos_Store_Overview/'
              },
              {
                text: 'How to Configure Requirements',
                url: 'http://orblinks.hcaqa.corpadqa.net/OnlineHelp/Captivates/TW/Complyos_Configure_Requirements/'
              },
              {
                text: 'List',
                url: 'https://mediaconnect.app.medcity.net/#/videos/ab8d2dc8-36b5-4860-b12f-647f24c0377b'
              },
              {
                text: 'Edit Assignees',
                url: 'https://mediaconnect.app.medcity.net/#/videos/527f7461-f2c7-41fd-b8be-37584424e05c'
              },
              {
                text: 'Edit Start Dates',
                url: 'https://mediaconnect.app.medcity.net/#/videos/af0756c6-1d92-4401-a9c9-e6a04b074989'
              },
              {
                text: 'Update Documentation From a Completed Task',
                url: 'https://mediaconnect.app.medcity.net/#/videos/54472ee6-07c1-4933-a287-4798a7ea2e04'
              }
            ]
          }
        ]
      },
      {
        number: '4',
        icon: 'fa-upload',
        label: 'Schedule',
        text: 'Upload documentation for individual requirements to be survey-ready.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'Schedule Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/ES9LK6K6eTlPkQDyeysSICkB7sQ0ZIZhr5pI5vOIzcvItg'
              },
              {
                text: 'Scan Setup Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EW9EnXE54llEsaMfBAF0SmQB0YYy_9sBxLto2N8JGqvaIQ'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'Scanning Documents',
                url: 'https://mediaconnect.app.medcity.net/sharevideo/62da6299-5236-4c35-b5cc-968c69621a07'
              },
              {
                text: 'Complete a Configured Requirement Task',
                url: 'https://mediaconnect.app.medcity.net/#/videos/509e02f0-88bb-49ee-9a98-5007e62850da'
              }
            ]
          }
        ]
      },
      {
        number: '5',
        icon: 'fa-tachometer',
        label: 'Dashboard & Reports',
        text: 'At a glance, check the compliance status of your organization from the Dashboard and run Reports.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'Dashboard Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/Ee-j5xKJFLJMrM8cWFCxp8YBadacJX8wuMAtbeQWuYFouQ'
              },
              {
                text: 'ValidationMode® Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EQAVLR7i395ChqK7HoPufp0BWc9VEo5IdGRg6aLY74v0Ow'
              },
              {
                text: 'Reports Guide',
                url: 'https://hcahealthcare.sharepoint.com/sites/CORP-FacilitiGroup/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCORP%2DFacilitiGroup%2FShared%20Documents%2FComplyos%20User%20Guides%2F5c%5FReports%20Guide%2Epdf&parent=%2Fsites%2FCORP%2DFacilitiGroup%2FShared%20Documents%2FComplyos%20User%20Guides'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'Dashboard Overview',
                url: 'https://mediaconnect.app.medcity.net/sharevideo/54c46a8b-d5d0-4474-9475-ea86ce2db524'
              }
            ]
          }
        ]
      },
      {
        number: '6',
        icon: 'fa-cog',
        label: 'SurveyMode®',
        text: 'Simplify the survey process by giving surveyors or reviewers access to electronic documentation.',
        link_groups: [
          {
            label: 'User Guides',
            links: [
              {
                text: 'SurveyMode® Guide',
                url: 'https://hcahealthcare.sharepoint.com/:b:/s/CORP-FacilitiGroup/EccL2kXo13NGtrc6D6BRpBsB6H6bwi_G6yGgwbE5FKFaDg'
              }
            ]
          },
          {
            label: 'Videos',
            links: [
              {
                text: 'SurveyMode®',
                url: 'https://mediaconnect.app.medcity.net/#/playlist/6f1a1e01-00e5-49de-8dc1-143e0b5db979/videos/?videoId=6342652f-c83d-4450-8246-718f090ee593'
              }
            ]
          }
        ]
      }
    ]

    this.itemsPerRow = 3
    this.rowCount = _.range(Math.ceil(this.steps.length / this.itemsPerRow))
  }

  click_service_central_link = () => window.open('https://hcaservicecentral.service-now.com/hca', '_blank')

  click_email_link = () => {
    /* MAIL RECIEPENTS */
    let user_email, user_name, user_role
    const to = ['hcaservicecentral@service-now.com']
    const cc = []

    /* USER INFORMATION */
    const user = this.$rootScope.session.getUser()

    if (user) {
      user_name = user.profile.display_name
      user_email = user.email
      user_role = user.user_role.role.title
      cc.push(user.email)
    } else {
      user_name = ''
      user_email = ''
      user_role = ''
    }

    /* EMAIL */
    // const subject = `Complyos Access: ${site}`

    let href = `mailto:${to.join(',')}?`

    if (cc.length) {
      href += `cc=${cc.join(',')}&`
    }

    href += `subject=Complyos&`

    return href
  }

  trackByIndex = (index: number, item: any): string => item.index;
}

angular
  .module('complyosClient')
  .directive('ngHelp', downgradeComponent({ component: HelpComponent }) as angular.IDirectiveFactory)
