// /* eslint-disable
//     camelcase,
//     eqeqeq,
//     no-return-assign,
//     no-undef,
// */
// 'use strict'

// import { ComponentFactoryResolver } from "@angular/core"
// import angular from "angular"
// import * as _ from 'lodash';

// var dayjs = require("dayjs")

// angular.module('complyosClient').directive('completePercentage', ['$q', '$rootScope', 'arrayFunctions', 'loadingStateService', 'FileSaver', 'filterService', 'helpService', 'Restangular',
//   (
//     $q: any,
//     $rootScope: any,
//     arrayFunctions: any,
//     loadingStateService: any,
//     FileSaver: any,
//     filterService: any,
//     helpService: any,
//     Restangular: any
//   ) => ({
//     templateUrl: 'views/directives/complete_percentage.html',
//     restrict: 'E',

//     scope: {
//     },

//     link($scope: any, $ele: any, $attrs: any) {
//       $scope = this.registerFunctions($scope)

//       $scope.previousPills = null
//       $scope.loadingStateService = angular.copy(loadingStateService)
//       $scope.loadingStateService.init()
//       // Overview report
      
//       $scope.reportLoadingStateService = angular.copy(loadingStateService)
//       $scope.reportLoadingStateService.loading_states = _.merge($scope.reportLoadingStateService.loading_states, custom_states)
//       $scope.reportLoadingStateService.set_state('ready')
//       $scope.outstandingPromises = []
//       $scope.deferredRefresh = null

//       $scope.helpService = angular.copy(helpService)

//       $scope.watchEntityFilter()

//       var custom_states = {
//         ready: {
//           icon: 'fa-download',
//           text: 'Download Report'
//         }
//       }

//       return $scope
//     },

//     registerFunctions ($scope: any) {
//       $scope.get_analytic_data = function (pills: any) {
//         $scope.loadingStateService.set_state('loading')

//         let query_params = {
//           model: 'Binder',
//           method: 'complete_percentage',
//           param_data: {}
//         }

//         query_params.param_data = $rootScope.storeService.stringifyTagParams(
//           $rootScope.storeService.pillsToObject(pills)
//         )

//         let abort = $q.defer()
//         $scope.outstandingPromises.push(abort)

//         Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then(function (response: any) {
//           let res = response.plain()
//           $scope.data = res.data
//           $scope.date_range_begin = res.args.table.date_range_begin
//           $scope.date_range_end = res.args.table.date_range_end
//           if ($scope.data && $scope.data.percent_complete !== undefined && !isNaN($scope.data.percent_complete)) {
//             $scope.loadingStateService.process_success(response)
//           } else {
//             $scope.loadingStateService.set_state('error')
//           }
//         }, (error: any) => {
//           $scope.loadingStateService.set_state('query_too_large_warning')
//           $scope.deferredRefresh = $scope.executeRefresh.bind(null, pills)
//         })
//       }

//       $scope.executeRefresh = (pills: any) => {
//         $scope.abortAllPromises($scope.outstandingPromises)
//         setTimeout(() => $scope.get_analytic_data(pills), 50)
//       }

//       $scope.watchEntityFilter = () => {
//         var refresh = function (e: any, pills: any, resultsCount: any) {
//           let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted', 'pagination_params']) || $scope.previousPills === null
//           $scope.previousPills = pills
//           if (widgetShouldRefresh) {
//             if (resultsCount <= 30000) {
//               $scope.executeRefresh(pills)
//             } else {
//               $scope.loadingStateService.set_state('query_too_large_warning')
//               $scope.deferredRefresh = $scope.executeRefresh.bind(null, pills)
//             }
//           }
//         }
//         $scope.$on('refresh-dashboard-widgets', refresh)
//         $scope.$on('cancel-pending-filter-queries', () => $scope.abortAllPromises($scope.outstandingPromises))
//       }

//       $scope.abortAllPromises = (promiseArray: any) => {
//         if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//           promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//         }
//         promiseArray.length = 0
//       }

//       $scope.dashboard_complete_report = () => {
//         $scope.reportLoadingStateService.set_state('loading')

//       let query_params = {
//         report: 'dashboard_completion',
//         param_data: {}
//       }
//       query_params.param_data = $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject($scope.previousPills)
//       )

//       Restangular.setFullResponse(true).one(`reports/dashboard_completion`).get(query_params).then(function (response: any) {
//         var report_file = new Blob([response.data], { type: 'text/csv' })
//         var report_name = `dashboard_completion_report_${dayjs().format('YYYY-MM-DD')}.csv`
//         FileSaver.saveAs(report_file, report_name)
//         $scope.reportLoadingStateService.set_state('ready')
//       }
//       , function (error: any) {
//         console.log(error)
//         $scope.reportLoadingStateService.set_state('error')
//       }).finally(() => {
//         // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//         // Reset the full response setting
//         Restangular.setFullResponse(false)
//       })
//       }

//       return $scope
//     }
//   })
// ])
