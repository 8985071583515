import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import dayjs from 'dayjs'

@Component({
  selector: 'date-range-picker',
  template: `
  <mat-form-field>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <span style="font-size: 10px;">
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid') || range.controls.end.hasError('matEndDateInvalid')">Invalid date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matDatepickerParse') || range.controls.start.hasError('matDatepickerParse')">Invalid date</mat-error>
    <mat-error *ngIf="range.controls.start.hasError('maxDateExceeded')">Start date range exceeded</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('maxDateExceeded')">End date range exceeded</mat-error>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  </span>`,
  styleUrls: ['./date-range-picker.scss']
})
export class DateRangePicker implements OnInit {
  private maxDate: Date = new Date()
  @Output() dateRangeSelected = new EventEmitter<any>()

  range = new FormGroup({
    start: new FormControl<Date | null>(null, [Validators.required, this.maxDateValidator()]),
    end: new FormControl<Date | null>(null, [Validators.required, this.maxDateValidator()]),
  })

  ngOnInit() {
    this.range.valueChanges.subscribe(value => {
      if(this.range.valid && value.start && value.end) {
        this.dateRangeSelected.emit(value)
      }
    })
  }

  maxDateValidator() {
    return (control: any) => {
      const selectedDate = dayjs(control.value)
      if (selectedDate.isValid() && selectedDate.isAfter(dayjs(this.maxDate))) {
        return { 'maxDateExceeded': true }
      }
      return null
    }
  }
}
