import { Component, Inject, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import { FlashService } from '../../../services/flash_service.service';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import angular from 'angular';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import * as FileSaver from 'file-saver';
var dayjs = require("dayjs")

@Component({
  selector: 'ngOrganizationOverview',
  templateUrl: './organization-overview.component.html'
})
export class OrganizationOverviewComponent implements OnInit {
  @Input() dashboardCollectionBinderCount: number;
  // Injected Services
  private detailReportLoadingStateService: any;
  private overviewLoadingStateService: any;
  private overviewReportLoadingStateService: any;
  private flashService: any;
  
  // Properties
  private orderOrganizationsByFunction: any;
  private deferredRefresh: any;
  private reverseOrganizationsOrder: boolean = false;
  private previousPills: any = null;
  private collapsePanelBody: boolean = false;
  private organizationSelected: boolean = false;
  private overviewResponse: any;
  private parent: any;
  private organizationsCount: any = 0;
  private paramToRemove: Array<any> = ['sorted'];
  private outstandingPromises: Array<any> = [];
  private organizations: any = null;
  private overviewRequest: any = {
    model: 'Binder',
    method: 'organization_overview',
    param_data: {}
  }
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('complyosServices') private complyosServices,
    @Inject(FlashService) flashService: FlashService,
    @Inject(treeManager) private treeManager: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.flashService = angular.copy(flashService)
    this.treeManager = angular.copy(treeManager)
    this.detailReportLoadingStateService = angular.copy(loadingStateService)
    this.overviewReportLoadingStateService = angular.copy(loadingStateService)
    this.overviewLoadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    let customStates = {
      ready: {
        icon: 'fa-download',
        text: 'Download Report'
      }
    }
    this.overviewLoadingStateService.loading_states = _.merge(this.overviewLoadingStateService.loading_states, customStates)
    this.overviewLoadingStateService.init()

    this.overviewReportLoadingStateService.loading_states = _.merge(this.overviewReportLoadingStateService.loading_states, customStates)
    this.overviewReportLoadingStateService.init()
    this.overviewReportLoadingStateService.set_state('ready')

    this.detailReportLoadingStateService.loading_states = _.merge(this.detailReportLoadingStateService.loading_states, customStates)
    this.detailReportLoadingStateService.init()
    this.detailReportLoadingStateService.set_state('ready')
    this.watchFilterChange()
  }

  onCountChange = (count: number) => {
    this.organizationsCount = count;
    this.updateScrollBarClass()
  }

  updateScrollBarClass = () => {
    const element = document.getElementById('organization_overview_ul');
    if (element) {
      if (this.organizationsCount >= 7) {
        element.classList.add('scroll_bar');
      } else {
        element.classList.remove('scroll_bar');
      }
    }
  }

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getOrganizationOverview(pills), 50)
  }

  watchFilterChange = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      this.isOrgPillSelected(pills)
      if (this.organizations) {
        this.reloadTree(this.organizations, pills, resultsCount)
      } else {
        let params = { param_data: { /* if they want visibility, defualt it here */ } }
        this.Restangular.all('organizations/filter').getList(params).then(
          (success: any) => {
            this.organizations = success.plain()
            this.organizationsCount = this.organizations.length
            this.reloadTree(this.organizations, pills, resultsCount)
          }
        )
      }
    }

    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  reloadTree = (organizations: any, pills: any, resultsCount: any) => {
    let pillsCopy = JSON.parse(JSON.stringify(pills))
    let orgPills = pillsCopy.filter((p: any) => p.Field === 'organizations_ids')
    if (!Array.isArray(orgPills) || orgPills.length < 1) {
      let topLevelOrgs = this.getTopLevelOrgFilters(organizations)
      pillsCopy = pillsCopy.concat(topLevelOrgs)
    }

    let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pillsCopy, ['sorted', 'pagination_params']) || this.previousPills === null
    this.previousPills = pillsCopy
    if (widgetShouldRefresh) {
      if (resultsCount <= 30000) {
        this.executeRefresh(pillsCopy)
      } else {
        this.overviewLoadingStateService.set_state('query_too_large_warning')
        this.deferredRefresh = this.executeRefresh.bind(null, pillsCopy)
      }
    }
  }

  getOrganizationOverview = (pills: any) => {
    if (!pills) {
      return
    }
    this.overviewLoadingStateService.set_state('loading')

    let queryParams = angular.copy(this.overviewRequest)
    let newPills = this.$rootScope.storeService.pillsToRemove(pills, this.paramToRemove)
    queryParams.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(newPills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.organizationOverviewRequest(queryParams, abort).then((response: any) => {
      this.overviewLoadingStateService.process_success(response)
      this.overviewResponse = response.plain()

      this.organizationsCount = this.overviewResponse.data.tree.length
      // only get the parent org (for upwards navigation) if there is only one
      if (this.overviewResponse.data.tree.length === 1) {
        this.getParentOrganization(response.data.tree[0].parent_id)
        this.organizationsCount += response.data.tree[0].children.length
      }
    }, (error: any) => {
      this.overviewLoadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
    })
  }

  organizationOverviewRequest = (params: any, abort: any) => {
    return this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(params)
  }

  getTopLevelOrgFilters = (topLevelOrganizations: any) => {
    if (!topLevelOrganizations) {
      return
    }

    let topLevelOrgPills = topLevelOrganizations.map((o: any) => {
      return {
        Field: 'organizations_ids',
        displayName: 'Organizations',
        Op: 'Multi',
        Value: o.id,
        displayValue: o.name,
        dateCreated: dayjs().$d
      }
    })
    return topLevelOrgPills
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  getParentOrganization = (id: any) => {
    if (id) {
      this.Restangular.one(`organizations/${id}`).get().then((response: any) => this.parent = response)
    }
  }

  togglePanelBody = () => {
    this.collapsePanelBody = !this.collapsePanelBody
  }

  attemptChangeOrganization = (id: any) => {
    // TODO replace with a better "set organization as pill" step.
    this.Restangular.one(`organizations/${id}`).get().then((response: any) => {
      let preventFilterChange = false
      this.$rootScope.session.setOrganization(response, preventFilterChange)
    })
  }

  downloadDashboardOverviewReport = () => {
    this.overviewReportLoadingStateService.set_state('loading')

    let queryParams = {
      report: 'dashboard_organization_overview',
      param_data: {}
    }
    queryParams.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(this.previousPills)
    )

    this.Restangular.setFullResponse(true).one(`reports/dashboard_organization_overview`).get(queryParams).then((response: any) => {
      var reportFile = new Blob([response.data], { type: 'text/csv' })
      var reportName = `dashboard_organization_overview_report_${dayjs().format('YYYY-MM-DD')}.csv`
      FileSaver.saveAs(reportFile, reportName)
      this.overviewReportLoadingStateService.set_state('ready')
    }
    ,(error: any, reportName: string) => {
      let message = error.data ? error.data[0] : 'The report has timed out. Please narrow your results and try again.'
      const alert = {
        name: `report_error_${reportName}`,
        dismissable: true,
        class: 'alert-warning',
        icon: 'fa-exclamation-triangle',
        strong: 'Error:',
        message: `${message}`
      }
      this.flashService.emit_alert(this.$scope, alert)
      this.overviewReportLoadingStateService.set_state('error')
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  isOrgPillSelected = (pills: any) => {
    this.organizationSelected = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
  }

  downloadDashboardDetailReport = () => {
    this.detailReportLoadingStateService.set_state('loading')

    let query_params = {
      report: 'dashboard_detail',
      param_data: {}
    }
    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(this.previousPills)
    )

    this.Restangular.setFullResponse(true).one(`reports/dashboard_detail`).get(query_params).then((response: any) => {
      var reportFile = new Blob([response.data], { type: 'text/csv' })
      var reportName = `dashboard_detail_report_${dayjs().format('YYYY-MM-DD')}.csv`
      FileSaver.saveAs(reportFile, reportName)

      this.detailReportLoadingStateService.set_state('ready')
    }, (error: any, reportName: string) => {
      let message = error.data ? error.data[0] : 'The report has timed out. Please narrow your results and try again.'
      const alert = {
        name: `report_error_${reportName}`,
        dismissable: true,
        class: 'alert-warning',
        icon: 'fa-exclamation-triangle',
        strong: 'Error:',
        message: `${message}`
      }
      this.flashService.emit_alert(this.$scope, alert)
      this.detailReportLoadingStateService.set_state('error')
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  getDetailReportButtonClass = () => {
    if (this.detailReportLoadingStateService.loading_state.name === 'error') {
      return 'btn-warning'
    } else {
      return 'btn-default'
    }
  }

  getOverviewReportButtonClass = () => {
    if (this.overviewReportLoadingStateService.loading_state.name === 'error') {
      return 'btn-warning'
    } else {
      return 'btn-default'
    }
  }

  getLegendColor = (status: any) => {
    return this.complyosServices.snapshot.get_status_color(status)
  }

  // SORT

  addDefaultSort = () => {
    this.orderOrganizationsByFunction = this.orderOrganizationsByPastDueEntries
    this.reverseOrganizationsOrder = true
  }

  orderOrganizationsByName = (organization: any) => organization.meta.label

  orderOrganizationsByOrganizationCount = (organization: any) => organization.data.organization_count

  orderOrganizationsByBinderCount = (organization: any) => organization.data.binder_count

  orderOrganizationsByOpenBinderCount = (organization: any) => organization.data.open_binder_count

  orderOrganizationsByPastDueBinderCount = (organization: any) => organization.data.past_due_binder_count

  orderOrganizationsByOpenEntries = (organization: any) => organization.data.open_entry_count

  orderOrganizationsByPastDueEntries = (organization: any) => organization.data.past_due_entry_count

  orderOrganizationsByClosedNotCompleteEntries = (organization: any) => organization.data.closed_not_complete_entry_count
}

angular
  .module('complyosClient')
  .directive('ngOrganizationOverview', downgradeComponent({ component: OrganizationOverviewComponent }) as angular.IDirectiveFactory)
