import { Component, Inject, OnInit } from '@angular/core';
import { FlashService } from '../../services/flash_service.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as Restangular from '../../../vendor/restangular/restangular'
import { Utils } from '../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngEmailLogs',
  templateUrl: './email-logs.component.html'
})
export class EmailLogsComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private emailLogs: Array<any> = [];
  private previousPills: Array<any> = [];
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('loadingStateService') loadingStateService: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.flashService = angular.copy(flashService)
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      nocontent: {
        icon: 'fa-exclamation-triangle',
        text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
      }
    }

    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()

    this.watchEntityFilter()
  }

  getEmailLogs = (pills: any) => {
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }
    this.loadingStateService.set_state('loading')
    return this.Restangular.one('email_logs').get(query_params).then((success: any) => {
        this.emailLogs = success.items
        this.loadingStateService.process_success(success.items)
        this.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
      }, (error: any) => {
        this.loadingStateService.process_error(error)
      }
    );
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted'])
      this.previousPills = pills
      if (widgetShouldRefresh) {
        this.getEmailLogs(pills)
      }
    }
    this.$scope.$on('filterChange-dashboard', refresh)
  }
}

angular
  .module('complyosClient')
  .directive('ngEmailLogs', downgradeComponent({ component: EmailLogsComponent }) as angular.IDirectiveFactory)
