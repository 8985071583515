var FilterType = {
  Filter: 1,
  Select: 2,
  Multi: 3,
  Date: 4,
  NodeTreeBuilder: 5,
  Radio: 6,
  CustomSearch: 7,
  MultiAPISearch: 8
};

export const constants = {
  FilterType: FilterType,
  schedule_generation_state: {
    schedule_on: 'schedule_on',
    no_schedule: 'no_schedule',
    start_date_needed: 'start_date_needed',
    managed_by_paired_application: 'managed_by_paired_application',
    archived: 'archived'
  },

  FilterBy: {
    constructor() {
      this.Field = ''
      this.Op = ''
      this.Value = ''
      this.displayName = ''
      this.displayValue = ''
    }
  },

  FieldFilterConfig: {
    constructor() {
      this.fieldDisplayName = ''
      this.fieldValue = ''
      this.type = FilterType.Filter
      this.list = []
    }
  }
};
