/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('userLobManager', [
  'flashService',
  'Restangular',
  '$timeout',
  'utils',
  function (
    flashService: any,
    Restangular: any,
    $timeout: any,
    utils: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/user_lob_manager.html',
      require: 'ngModel',
      scope: {
        userId: '=',
        allowUpdate: '=',
        showError: '=',
        uiSetting: '=',
        multiLob: "="
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized
      //  attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.ulm = {

          initialize () {
            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            $ngModel.$options = {
              allowInvalid: true
            }

            this.pending_user_lobs = { lobs: [] }
            this.set_pending_user_lobs()
            this.watch_ng_model()
            this.listen_for_start_event()
          },

          watch_ng_model () {
            $scope.$watch(() => $ngModel.$modelValue, function (new_value: any, old_value: any, scope: any) {
              // console.log('newVal: ', new_value)
              // console.log('oldVal: ', old_value)
              $scope.ulm.set_pending_user_lobs()
              if ($ngModel.$modelValue && $ngModel.$modelValue.id && $scope.ulm.lobs) {
                $scope.ulm.pending_user_lobs.lobs = new_value
                $scope.ulm.validate()
              }
            })
          },

          // get_roles () {
          //   const lobs = Restangular.all('tags/show_lob_tags')
          //   // lobs.getList().then((response: any) => {
          //   //   console.log('lobs: ', response.plain())
          //   // }
          //   // );

          //   return lobs.getList().then((response: any) => {
          //     this.roles = response.plain()

          //     if ($scope.uiSetting !== 'read') {
          //       let filteredRoles = []

          //       for (let i = 0; i < this.roles.length; i++) {
          //         const role = this.roles[i]

          //         if (role.title !== 'Surveyor') {
          //           filteredRoles.push(role)
          //         }
          //       }

          //       this.roles = filteredRoles
          //     }

          //     return $timeout(() => {
          //       return this.set_pending_user_role()
          //     }
          //     , 1000)
          //   }
          //   , (error: any) => flashService.emit_alert(
          //     $scope,
          //     flashService.default_alert_by_code(error.status)
          //   )
          //   );
          // },

          line_of_bussiness_api_search_fn(args: any) {
            let loadedCallback = args.loadedCallback
            return Restangular.all('tags/show_lob_tags').getList().then((success: any) => {
              let response = success.plain()
              let searchResults = response.map((r: any) => { return { title: r.title, id: r.id }})
              loadedCallback({ items: searchResults, pagination_info: { totalCount: response.length, maxItemCount: response.length } })
            }, (error: any) => {
                utils.log(error)
            });
          },

          // set_pending_user_role () {
          //   if ($scope.uiSetting === 'create') {
          //     this.pending_user_role.role = {}
          //   } else if ($ngModel.$modelValue && ($ngModel.$modelValue !== undefined) && $ngModel.$modelValue.id) {
          //     // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '_'.
          //     this.pending_user_role.role = _.find(this.roles, (role: any) => role.id === $ngModel.$modelValue.role.id)
          //   }

          //   // Now that all the pending code has been set up
          //   // we can add the validators and validate to avoid
          //   // any client errors
          //   this.add_validators()
          //   return this.validate()
          // },
          set_pending_user_lobs() {
            if ($scope.uiSetting === 'create') {
              this.pending_user_lobs.lobs = []
            } else if ($ngModel.$modelValue && ($ngModel.$modelValue !== undefined)) {
              this.pending_user_lobs.lobs = $ngModel.$modelValue.map((r: any) => { return { title: r.title, id: r.id, ticked: true } })
            }

            // Now that all the pending code has been set up
            // we can add the validators and validate to avoid
            // any client errors
            this.add_validators()
            return this.validate()
          },

          update_pending_user_lobs () {
            if ($ngModel.$modelValue === null || $ngModel.$modelValue === undefined) {
              $ngModel.$modelValue = {}
            }
            $ngModel.$modelValue.$pending_user_lobs = this.pending_user_lobs.lobs
            let valid = this.validate()
            $scope.$apply()
            return valid
          },

          add_validators () {
            return $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              if ($attributes.required === false) {
                return true
              } else {
                return this.pending_user_lobs.lobs.length > 0
              }
            };
          },

          validate () {
            return $ngModel.$validate()
          },

          show_validation_error () {
            return $scope.showError && !$ngModel.$valid
          },

          //------------------------ [START] LOB SAVE / UPDATE ------------------------
          listen_for_start_event () {
            return $scope.$on('user_lob_manager_start', (event: any, data: any) => {
              if ($scope.allowUpdate && $scope.userId) {
                return this.start_update_user_lobs()
              } else {
                return this.emit_completion_event()
              }
            });
          },

          start_update_user_lobs () {
            if (Array.isArray($scope.ulm.pending_user_lobs.lobs) && $scope.ulm.pending_user_lobs.lobs.length > 0 && $scope.userId) {
              return this.update_user_lobs()

            } else {
              // console.log "waiting for user_role id..."
              // no need to reset lobs
            }
          },

          update_user_lobs(input_lobs: any) {
            let params = {
              lobs: $scope.ulm.pending_user_lobs.lobs
            }

            return Restangular.all(`users/${$scope.userId}/reset_user_lobs`).post(params)
              .then((success: any) => {
                // console.log(success)
              }, (error: any) => {
                flashService.emit_alert($scope, flashService.default_alert_by_code(error.status))
                return this.fault = error
              }).finally(() => {
                return this.emit_completion_event()
              });
          },

          emit_completion_event () {
            const return_object = {
              manager: 'user_lob_manager',
              object: $ngModel.$modelValue,
              fault: this.fault
            }
            return $scope.$emit('user_lob_manager_finished', return_object)
          }
          //------------------------ [ END ] LOB SAVE / UPDATE ------------------------

        }

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.ulm.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
