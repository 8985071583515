import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngOrganizationOverviewRowItem',
  templateUrl: './organization-overview-row-item.component.html'
})
export class OrganizationOverviewRowItemComponent implements OnInit {
  @Input() treeItem: any;
  @Input() overviewResponse: any;
  @Input() organizationsCount: any;
  @Input() onCountChange: any;
  @Output() organizationsCountChange = new EventEmitter<number>();
  // Injected Services
  private session: any;
  private overviewLoadingStateService: any;
  
  // Properties
  private outstandingPromises: Array<any> = [];
  private overviewRequest: any = {
    model: 'Binder',
    method: 'organization_overview',
    param_data: {}
  }
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('complyosServices') private complyosServices,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(treeManager) private treeManager: any,
    private Restangular: Restangular,
    private utils: Utils,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.treeManager = angular.copy(treeManager)
    this.session = $rootScope.session
    this.overviewLoadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
  }

  getMoarOverview = (treeItem: any) => {
    treeItem.loading_moar = true
    
    let queryParams = angular.copy(this.overviewRequest)
    queryParams.param_data = this.$rootScope.storeService.pillsToObject(this.$rootScope.filterService.getCached())
    queryParams.param_data.organization_ids = [{Value: treeItem.id}]
    delete queryParams.param_data.organizations_ids
    // TODO use tree_manager.set_object_depth(tree, object) vs passing starting_depth
    queryParams.param_data.starting_depth = treeItem.depth
    queryParams.param_data = this.$rootScope.storeService.stringifyTagParams(queryParams.param_data)

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.organizationOverviewRequest(queryParams, abort).then((response: any) => {
      response = response.plain()
      if (response.data.tree[0].children.length !== 0){
        this.organizationsCount += response.data.tree[0].children.length
        this.organizationsCountChange.emit(this.organizationsCount)
      }
      this.treeManager.update_object_in_tree(
        this.overviewResponse.data.tree,
        response.data.tree[0]
      )
      treeItem.loading_moar = false
    }, (error: any) => {
      this.overviewLoadingStateService.process_error(error)
    })
  }

  organizationOverviewRequest = (params: any, abort: any) => {
    return this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(params)
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  toggleExpandTreeItem = (treeItem: any) => {
    if (
      !treeItem.expanded &&
      treeItem.descendent_count > 0
    ) {
      if (treeItem.children.length === 0){
        this.getMoarOverview(treeItem)
      }
      else{
        treeItem.expanded = !treeItem.expanded
        this.organizationsCount += treeItem.children.length
      }
    } else {
      treeItem.expanded = !treeItem.expanded
      this.organizationsCount -= treeItem.children.length
    }
    this.organizationsCountChange.emit(this.organizationsCount)
  }

  attemptChangeOrganization = (id: any) => {
    // TODO replace with a better "set organization as pill" step.
    this.Restangular.one(`organizations/${id}`).get().then((response: any) => {
      let preventFilterChange = false
      this.$rootScope.session.setOrganization(response, preventFilterChange)
    })
  }
}

angular
  .module('complyosClient')
  .directive('ngOrganizationOverviewRowItem', downgradeComponent({ component: OrganizationOverviewRowItemComponent }) as angular.IDirectiveFactory)
