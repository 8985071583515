/* eslint-disable
    camelcase,
    handle-callback-err,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('organizationValidationModeCommentModalController', ['$scope', '$rootScope', '$uibModalInstance', 'flashService', 'Restangular', function (
  $scope: any,
  $rootScope: any,
  $uibModalInstance: any,
  flashService: any,
  Restangular: any
) {
  const init = function () {
    
    $scope.flashService = angular.copy(flashService)

    $scope.organizationNoteObject = {
      type: 'Feed::ValidationModeComment',
      noteable_id: $rootScope.session.getOrganization().id,
      noteable_type: 'Organization',
      text: ''
    }
  }

  $scope.submitValidationModeCommentForm = (form: any) => {
    if (form.$valid) {
      form.failed_submission = false
      $scope.submitValidationModeComment(form)
    } else {
      form.failed_submission = true
    }
  }

  $scope.submitValidationModeComment = function (form: any) {
    Restangular.all('notes').post($scope.organizationNoteObject).then(
      (success: any) => {
        $scope.closeModalAndReturn(success)
      },
      (error: any) => {
        $scope.flashService.process_error(error)
      }
    )
  }

  $scope.closeModalAndReturn = (success: any) => {
    $uibModalInstance.close(success)
  }

  $scope.dismissModal = () => $uibModalInstance.dismiss('cancel')

  return init()
}])
