/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

angular.module('complyosClient').directive('apiSearchDropdown', [
  'fortyCore', 'arrayFunctions', 'helpService', 'Restangular',
  (fortyCore, arrayFunctions, helpService, Restangular) => ({
    templateUrl: 'views/directives/api_search_dropdown.html',
    restrict: 'E',

    scope: {
      onDropdownCloseFn: '&',
      onOutputChange: '&',
      applyCurrentNodePath: '&',
      apiSearchFunction: '&',
      nodeTreeBuilderConfigConfig: '=',
      descendantsName: '<',
      descendantsPrefix: '<',
      outputNodePath: '=',
      refreshOnOpen: '=',
      noMultiSelect: '=',
      useSmallPopout: '=',
      hideApply: '=',
      summaryIsCount: '=',
      enableDescendantChecking: '=',
      hideSearch: '=',
      hideSelectedListInPopout: "=",
      placeholder: "=",
      displayNameKey: "<",
      valueKey: "<",
      popupPositioning: "<"
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
      $scope.nodeTreeBuilder = {
        isLoading: true,
        isPopoutOpen: false,
        searchInput: '',
        nodePath: [],
        currentNodeList: [],
        maxItemCount: 150
      }

      $scope.tickOptions = {
        Unchecked: false,
        Checked: true,
        CheckedWithDescendants: 'CheckedWithDescendants'
      }

      // $scope.itemPluralName = $scope.itemPluralName || 'items'
      $scope.updateOutputNodePath($scope.outputNodePath || [])

      if (!$scope.refreshOnOpen) {
        $scope.loadSearchResults('')
      }

      $scope.displayNameKey = $scope.displayNameKey || "displayName"
      $scope.valueKey = $scope.valueKey || "value"

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.updateOutputNodePath = (updated: any) => {
        $scope.outputNodePath = updated
        setTimeout(() => { $scope.onOutputChange() }, 100)
      }

      $scope.applyNodePath = () => {
        // $scope.nodeTreeBuilder.isPopoutOpen = false;
        $scope.applyCurrentNodePath()
        $scope.reset()
      }

      $scope.reset = () => {
        $scope.updateOutputNodePath([])
        $scope.clearList()
        $scope.nodeTreeBuilder.isPopoutOpen = false
      }

      $scope.clearList = () => {
        $scope.nodeTreeBuilder.currentNodeList = []
        $scope.nodeTreeBuilder.searchInput = ''
      }

      $scope.openNodeTreeBuilderPopout = () => {
        $scope.toggleNodeTreeBuilderPopout(true);
        setTimeout(() => {
          let search = document.getElementById('node-tree-search-input')
          if (search != null) {
            search.focus()
          }
        }, 50)
      }

      $scope.onDropdownOpen = () => {
        if ($scope.refreshOnOpen) {
          $scope.nodeTreeBuilder.currentNodeList = []
          $scope.filterChanged(false)
        }
      }

      $scope.onDropdownClose = () => {
        if ($scope.refreshOnOpen) {
          $scope.clearList()
        }
        if ($scope.onDropdownCloseFn) {
          setTimeout(() => { $scope.onDropdownCloseFn() }, 100)
        }
      }


      $scope.toggleNodeTreeBuilderPopout = (isOpen = null) => {
        if (isOpen !== null) {
          $scope.nodeTreeBuilder.isPopoutOpen = isOpen
        } else {
          $scope.nodeTreeBuilder.isPopoutOpen = !$scope.nodeTreeBuilder.isPopoutOpen
        }

        // on open
        if ($scope.nodeTreeBuilder.isPopoutOpen) {
          $scope.onDropdownOpen()
        }

        // on close
        if ($scope.nodeTreeBuilder.isPopoutOpen !== true) {
          $scope.onDropdownClose()
        }
      }


      $scope.selectNextNode = (node: any) => {
        let path = JSON.parse(JSON.stringify($scope.outputNodePath))
        if ($scope.noMultiSelect) {
          path = []
          $scope.syncCurrentListToValue($scope.tickOptions.Unchecked)
          $scope.toggleNodeTreeBuilderPopout(false)
          // $scope.nodeTreeBuilder.isPopoutOpen = false
        }
        let nodeHasDescendants = Array.isArray(node.children) && node.children.length > 0 || node.has_children == true
        node.ticked = $scope.bumpTickProperty(node.ticked, $scope.enableDescendantChecking, nodeHasDescendants)

        path = path.filter((n: any) => n[$scope.valueKey] != node[$scope.valueKey])
        if (node.ticked) {
          path.push(node)
        }
        $scope.updateOutputNodePath(path)
      }

      $scope.bumpTickProperty = function (currentState: any, descendantsEnabled: any, hasDescendants: any) {
        currentState = currentState || $scope.tickOptions.Unchecked;
        // $scope.tickOptions
        let validOptions = 2;
        if (descendantsEnabled && hasDescendants) {
          validOptions = validOptions + 1;
        }
        let index = $scope.getIndexfromOption(currentState);

        let selectedIndex = index - 1
        if (selectedIndex < 0) {
          selectedIndex = validOptions - 1
        }

        return $scope.getOptionFromIndex(selectedIndex);
      }
      $scope.getIndexfromOption = function (selectionOption: any) {
        const optsArray = Object.values($scope.tickOptions);
        return optsArray.indexOf(selectionOption);
      }
      $scope.getOptionFromIndex = function (index: any) {
        const optsArray = Object.values($scope.tickOptions);
        return optsArray[index];
      }

      $scope.loadSearchResultsDebounced = _.debounce((searchText: any) => $scope.loadSearchResults(searchText), 1000)
      $scope.loadSearchResults = (apiSearchInputString: any) => {
        $scope.nodeTreeBuilder.paginationInfo = undefined
        $scope.nodeTreeBuilder.currentNodeList = []
        $scope.nodeTreeBuilder.isLoading = true
        $scope.nodeTreeBuilder.error = null;
        let promise = $scope.apiSearchFunction({ apiSearchInputString, loadedCallback: $scope.onApiSearchResultsLoaded })
        if (promise) {
          promise.then(null, (e: any) => $scope.nodeTreeBuilder.error = e)
          promise.finally((p: any) => $scope.nodeTreeBuilder.isLoading = false)
        }
      }

      $scope.onApiSearchResultsLoaded = (apiSearchResults: any) => {
        if (Array.isArray(apiSearchResults.items)) {
          $scope.nodeTreeBuilder.currentNodeList = apiSearchResults.items || []
        } else {
          $scope.nodeTreeBuilder.currentNodeList = []
        }
        $scope.nodeTreeBuilder.paginationInfo = apiSearchResults.pagination_info
        // $scope.checkAllSelectedOptions()
        $scope.syncCurrentListToValue($scope.tickOptions.Checked, $scope.tickOptions.Checked)
        $scope.syncCurrentListToValue($scope.tickOptions.CheckedWithDescendants, $scope.tickOptions.CheckedWithDescendants)
      }

      $scope.syncCurrentListToValue = (newTickValue: any, onlySyncOptionsWithOldTickValue: any = null) => {
        let nodePathOptionsToChange = $scope.outputNodePath
        if (onlySyncOptionsWithOldTickValue) {
          nodePathOptionsToChange = nodePathOptionsToChange.filter((npo: any) => npo.ticked == onlySyncOptionsWithOldTickValue)
        }
        let values = nodePathOptionsToChange.map((n: any) => n[$scope.valueKey])
        // let checkedOptions = $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => values.includes(n[$scope.valueKey]))
        let checkedOptions = $scope.findAllOptionsByValue($scope.nodeTreeBuilder.currentNodeList, values)
        checkedOptions.forEach((o: any) => { o.ticked = newTickValue })
      }

      $scope.findAllOptionsByValue = (list: any, values: Array<any>) => {
        let matches: any = []
        list.forEach((n: any) => {
          if (values.includes(n[$scope.valueKey])) {
            matches.push(n)
          }
          if (Array.isArray(n.children) && n.children.length > 0) {
            let childrenMatches = $scope.findAllOptionsByValue(n.children, values)
            matches = matches.concat(childrenMatches)
          }
        })
        return matches
        // $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => values.includes(n[$scope.valueKey]))
      }

      $scope.truncateNodePath = (indexToRemove: any) => {
        if (indexToRemove == -1) {
          // remove all selected options from PATH and deselect all selected options in list
          let valuesToDeselect = $scope.outputNodePath.map((n: any) => n[$scope.valueKey])
          // flat
          // let tickedOptions = $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => n.ticked)
          let tickedOptions = $scope.findAllOptionsByValue($scope.nodeTreeBuilder.currentNodeList, valuesToDeselect)
          tickedOptions.forEach((n: any) => { n.ticked = false })
          $scope.outputNodePath.length = 0
        } else {
          // remove ONE selected option from PATH, and delesect that option in the list
          let value = $scope.outputNodePath[indexToRemove][$scope.valueKey]
          $scope.outputNodePath.splice(indexToRemove, 1)
          // flat
          // let nodesToUncheck = $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => n[$scope.valueKey] == value)
          let nodesToUncheck = $scope.findAllOptionsByValue($scope.nodeTreeBuilder.currentNodeList, [value])
          nodesToUncheck.forEach((n: any) => { n.ticked = false })
        }
        $scope.updateOutputNodePath($scope.outputNodePath)
      }

      $scope.filterChanged = (debounce = true) => {
        let searchText = $scope.nodeTreeBuilder.searchInput || ''
        searchText = searchText.toUpperCase()
        if (debounce) {
          $scope.loadSearchResultsDebounced(searchText)
        } else {
          $scope.loadSearchResults(searchText)
        }
      }

      return $scope
    }
  })
])
