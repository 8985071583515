// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationDashboardSurveyDateController', ['$rootScope', '$scope', 'dateService', 'loadingStateService', function (
//   $rootScope: any,
//   $scope: any,
//   dateService: any,
//   loadingStateService: any
// ) {
//   const init = function () {
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.organization = $rootScope.session.getOrganization()
//     $scope.loadingStateService.init()

//     $scope.date_survey_last = dayjs($scope.organization.date_survey_last).format('MMMM DD, YYYY')
//     $scope.date_survey_next_begin = dayjs($scope.organization.date_survey_next_begin).format('MMMM DD, YYYY')
//     $scope.date_survey_next_end = dayjs($scope.organization.date_survey_next_end).format('MMMM DD, YYYY')
//   }

//   return init()
// }])
