// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsScheduleController', ['$filter', '$httpParamSerializer', '$location', '$q', '$rootScope', '$scope', '$timeout', '$uibModal', '$window', 'ENV', 'fileService', 'filterService', 'fortyCore', 'loadingStateService', 'Restangular', 'utils', 'helpService', function (
//   $filter: any,
//   $httpParamSerializer: any,
//   $location: any,
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   $timeout: any,
//   $uibModal: any,
//   $window: any,
//   ENV: any,
//   fileService: any,
//   filterService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   Restangular: any,
//   utils: any,
//   helpService: any
// ) {
//   const init = function () {
//     $scope.textService = fortyCore.text
//     $scope.helpService = helpService
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.binders_need_start_date_state_count = 0

//     const custom_states = {
//       nocontent: {
//         icon: 'fa-archive',
//         html: '<div>Your Organization has no incomplete task dates matching your selected filters.  Please go to your <a href="' + `${window.location.origin}`+ '/requirements/binders">List</a> to manage your Organization configured requirement(s). If no results, go to the <a href="' + `${window.location.origin}`+ '/requirements/store">Store</a> to configure if the requirement(s) are applicable to your Organization.</div>',
//       },
//       activeorgprompt: {
//         name: 'activeorgprompt',
//         icon: 'fa-building',
//         text: 'Organization Required. Please select from filter above.'
//       },
//       currentorgisarchived: {
//         name: 'currentorgisarchived',
//         icon: 'fa-building',
//         text: 'This Organization is Archived. Please select an active Organization from the filter above.'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.outstandingPromises = []

//     watchPillFilter()
//     // getNeedsStartDateCount()
//   }

//   var watchPillFilter = () => {
//     $scope.$on('filterChange-dashboard', function (e: any, pills: any) {
//       $scope.abortAllPromises($scope.outstandingPromises)
      
//       setTimeout(() => getSchedule(angular.copy(pills)), 50)
      
//       setTimeout(() => getNeedsStartDateCount(angular.copy(pills)), 50)
//     })
//   }

//   const getNeedsStartDateCount = function (pills: any) {
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }
//     Restangular.one(`binders/needs_start_date_count`).get(query_params).then(
//       (success: any) => {
//         let result = success.plain()
//         $scope.binders_need_start_date_state_count = result.record_count
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     )
//   }

//   const getSchedule = function (pills: any) {
//     $scope.loadingStateService.set_state('loading')
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     Restangular.one(`entries/collection_schedule`).withHttpConfig({ timeout: abort.promise }).get(query_params).then(
//       (success: any) => {
//         $scope.loadingStateService.process_success(success.items)
//         $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//         $scope.resultCount = success.pagination_info.totalCount
//         $scope.schedule = processSchedule(success.items)
//         $scope.filtered_schedule = processSchedule(success.items) // Filtering happens API side, depricated
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     )
//   }

//   $scope.viewStartDateNeededOnListPage = () => {
//     let startDateNeededPill = {
//       isUnique: true,
//       Field: 'schedule_generation_state',
//       Op: 'Equals',
//       displayName: 'Schedule State',
//       displayValue: 'Start Date Needed',
//       Value: 'start_date_needed',
//       dateCreated: dayjs().$d
//     }
//     $rootScope.$broadcast('push-new-filter', startDateNeededPill)
//     $rootScope.session.goTo('/requirements/binders')
//   }

//   $scope.abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   /* FORM */

//   $scope.can_launch_form_tool = (entry: any) => {
//     return entry.id && entry.binder.requirement.has_form
//   }

//   $scope.launch_form_tool = function (entry: any) {
//     let entryOrgId
//     let activeOrg = $rootScope.session.data.activeOrganization
//     if (entry && entry.binder && entry.binder.organization && entry.binder.organization.id) {
//       entryOrgId = entry.binder.organization.id
//     } else if (activeOrg && activeOrg.id) {
//       entryOrgId = $rootScope.session.data.activeOrganization.id
//     } else {
//       alert('no organization set for this record.')
//     }
//     const params = {
//       action: 'completeForm',
//       id: entry.id,
//       return_url: `${$window.location.href}?organization_id=${entryOrgId}`
//     }
//     const serilalized_params = $httpParamSerializer(params)
//     const redirect_url = ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
//     return $window.location = redirect_url
//   }

//   /* VENDOR */

//   $scope.canOpenVendorModal = (entry: any) => (entry.id !== null) &&
//   entry.binder.vendor &&
//   (entry.binder.vendor !== null)

//   $scope.openVendorModal = function (entry: any) {
//     let modalInstance
//     return modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/vendor_modal.html',
//       controller: 'vendorModalController',
//       size: 'sm',
//       resolve: {
//         modalOptions () {
          
//           return { vendor: angular.copy(entry.binder.vendor) }
//         }
//       }
//     })
//   }

//   /**
//   * Open the modal to view entry object
//   * @param {integer} the id of the entry
//   * @param {string} the UI `setting` that the modal wil use.
//   *   see entry.js -   setModalSettings() function
//   */
//   $scope.openEntryModal = function (entry_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/entry_modal.html',
//       controller: 'entryModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(entry_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     return modalInstance.result.then(function (result: any) {
//       entry_object = result
//       let mi = -1
//       let ti = -1

//       // search for the entry in the list
//       _.each($scope.schedule, (monthGroup: any, monthIndex: any) =>
//         _.each(monthGroup.entries, function (entry: any, entryIndex: any) {
//           if (_.isEqual(entry.id, entry_object.id)) {
//             // [monthIndex, entryIndex]
//             mi = monthIndex
//             return ti = entryIndex
//           }
//         })
//       )

//       const entry_found = () => (mi !== -1) && (ti !== -1)

//       // for completing an entry
//       if (entry_object.complete_status === 'attested_as_complete' || entry_object.state.locked) {
//         // remove the entry and the monthGroup
//         if (entry_found()) {
//           $scope.schedule[mi].entries.splice(ti, 1)
//           // if there is no longer a entry in the monthGroup, then hide that that as well
//           if ($scope.schedule[mi].entries.length === 0) {
//             $scope.schedule.splice(mi, 1)
//           }
//         }
//       } else {
//         // for updating the attachment count
//         if (entry_found()) {
//           $scope.schedule[mi].entries[ti] = entry_object
//         }
//       }

//       // we update the original list starting on 103 and then update the filtered list
//       return reflow($scope.current_filter)
//     }

//     , (reason: any) => utils.log(`Modal dismissed at: ${dayjs().$d} reason: ${reason}`, 'info'));
//   }

//   $scope.is_all = (entry: any) => {
//     return true
//   }

//   $scope.is_partially_complete = (entry: any) => {
//     return (
//       $scope.is_requires_review(entry) ||
//       $scope.is_requires_resubmission(entry) ||
//       $scope.is_actually_partially_complete(entry)
//     )
//   }

//   $scope.is_requires_review = (entry: any) => {
//     return (
//       (entry.attachments.length >= 1) &&
//       (entry.binder.requires_review === true)
//     )
//   }

//   $scope.is_requires_resubmission = (entry: any) => {
//     return entry.complete_status === 'requires_resubmission'
//   }

//   $scope.is_actually_partially_complete = (entry: any) => {
//     return (
//       (entry.attachments.length >= 1) ||
//       (entry.complete_status === 'partially_complete')
//     )
//   }

//   var reflow = function (filter: any) {
//     let filtered_schedule: any
//     if ($scope.current_filter) {
//       filtered_schedule = []
//       _.each($scope.schedule, function (monthGroup: any) {
//         // first check if there are any matches in the monthGroup.
//         const match_in_monthGroup = _.findIndex(monthGroup.entries, (entry: any) => $scope[`is_${filter}`](entry))

//         // if there are any matches, then push them into filtered_schedule.
//         if (match_in_monthGroup !== -1) {
//           const new_month_group = {
//             id: monthGroup.id,
//             entries: _.filter(monthGroup.entries, (entry: any) => $scope[`is_${filter}`](entry))
//           }
//           return filtered_schedule.push(new_month_group)
//         }
//       })
//       return $scope.filtered_schedule = filtered_schedule
//     } else {
//       return $scope.filtered_schedule = $scope.schedule
//     }
//   }

//   $scope.apply_filter = function (filter: any) {
//     $scope.current_filter = filter
//     return reflow(filter)
//   }

//   /**
//   * Add the month separators, create a title slug, move the ICS
//   * strings, etc, etc
//   * @param {array} collection of binder objects
//   */
//   const processSchedule = function (schedule: any) {
//     let processed = schedule

//     // Order by month
//     processed = $filter('orderBy')(processed, (p: any) => dayjs(p.event_date).unix()
//       , true)

//     // reverse the list so that it will display correctly
//     processed.reverse()

//     // Posts go to the same month category if they have the same month and same year
//     processed = $filter('headerChunk')(processed, (p1: any, p2: any) =>
//       // true or false
//       (dayjs(p1.event_date).month() === dayjs(p2.event_date).month()) && (dayjs(p1.event_date).year() === dayjs(p2.event_date).year())

//     // Identify the groups by month
//     , (p: any) => dayjs(dayjs(p.event_date)).startOf('month'))

//     return processed
//   }

//   $scope.navigate_to_management_url = function (binder: any) {
//     window.open(
//       binder.mailbox_pairing_management_url,
//       '_blank'
//     )
//   }

//   init()
// }])

// complyosClient.filter('headerChunk', [() =>
//   (function(orig: any, same: any, getChunkID: any) {
//     if (!(orig instanceof Array)) { return orig }
//     if (orig.length === 0) { return orig }
//     const result = []
//     let cur = []
//     let i = 0
//     i = 0
//     while (i < orig.length) {
//       if ((i === 0) || same(orig[i], orig[i - 1])) {
//         cur.push(orig[i])
//       } else {
//         result.push({
//           id: getChunkID(orig[i - 1]),
//           entries: cur
//         })

//         cur = [orig[i]]
//       }
//       i++
//     }
//     result.push({
//       id: getChunkID(orig[orig.length - 1]),
//       entries: cur
//     })

//     i = 0
//     while (i < result.length) {
//       result[i].$$hashKey = i
//       i++
//     }
//     return result
//   })
// ])
