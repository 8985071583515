import { Component, Inject, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngSnapshotLinear',
  templateUrl: './snapshot-linear.component.html'
})
export class SnapshotLinearComponent implements OnInit {
  @Input() binderSnapshot: any;
  @Input() heightUnit: string;
  @Input() heightQuantity: string;
  @Input() width: string;
  // Properties
  private entrySnapshots: any;
  private svgHeightQuantity: any;
  private svgHeightUnit: any;
  private svgWidth: any;

  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('complyosServices') private complyosServices
  ) {
  }

  ngOnInit(): void {
    this.entrySnapshots = this.binderSnapshot.entry_snapshots
    this.svgHeightQuantity = this.heightQuantity ? this.heightQuantity : '45'
    this.svgHeightUnit = this.heightUnit ? this.heightUnit : 'px'
    this.svgWidth = this.width ? this.width : '100%'
  }

  svgGetHeight () {
    return `${this.svgHeightQuantity}${this.svgHeightUnit}`
  }

  shapeGetFillColor (status: any) {
    return this.complyosServices.snapshot.get_status_color(status)
  }

  shapeGetStrokeWidth () {
    // "#{@svgHeightQuantity / 10}#{@svgHeightUnit}"
    return '.15em'
  }

  shapeGetStrokeColor () {
    return 'rgb(255,255,255)'
  }

  shapeGetXOffset (entry_snapshot: any) {
    let offset = 0
    _.each(this.entrySnapshots, function (snapshot: any) {
      if (snapshot.meta.index < entry_snapshot.meta.index) {
        return offset += snapshot.meta.percent
      }
    })
    return `${offset}%`
  }

  calcTransparentColorOnWhite = (hex_color: any, opacity: any) => {
    // TODO these methods should be moved into a color service

    // convert Hex string and opacity to rgba_array
    // Args:
    //   hex - 6 digit hex string with or without "#"
    //   opacity - number in range 0..100
    // Returns: array representing rgba values of converted
    const hexoToRgbaArray = function (hex: any, opacity: any) {
      if (opacity == null) { opacity = undefined }
      hex = hex.replace('#', '')
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      const a = opacity !== undefined ? opacity / 100 : 1
      return [r, g, b, a]
    }

    // get a RGB value which is most similar to the RGBA tuple visually on white background.
    // optionally pass in a second RGB color to use instead of white
    // Args:
    //   rgba1 - array representing rgba values of color on top of rgba2
    //   rgba2 - array representing rgba values of color below rgba1
    // Returns: array representing rgba values of combined
    const rgbaOnColor = (rgba1: any, rgba2: any = null) => {
      if (rgba2 == null) { rgba2 = undefined }
      rgba2 = rgba2 !== undefined ? rgba2 : [255, 255, 255, 1]
      const r = Math.round(((1 - rgba1[3]) * rgba2[0]) + (rgba1[3] * rgba1[0]))
      const g = Math.round(((1 - rgba1[3]) * rgba2[1]) + (rgba1[3] * rgba1[1]))
      const b = Math.round(((1 - rgba1[3]) * rgba2[2]) + (rgba1[3] * rgba1[2]))
      const a = 1
      return [r, g, b, a]
    }

    // converts rgba_array to string
    // Args:
    //   rgba_array - array representing rgba values in order [red, green, blue, alpha]
    // Returns: rgba string
    const rgbaArrayToString = (rgba_array: any) => `rgba(${rgba_array[0]},${rgba_array[1]},${rgba_array[2]},${rgba_array[3]})`

    // converts string to rgba_array
    // Args:
    //   string - rgba string
    // Returns: array representing rgba values in order [red, green, blue, alpha]
    const rgbaStringToArray = (string: any) => {
      string = string.replace('rgba(', '[').replace(')', ']')
      return eval(string)
    }

    // http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
    const rgbaArrayToHex = (rgb_array: any) => `#${((1 << 24) + (rgb_array[0] << 16) + (rgb_array[1] << 8) + rgb_array[2]).toString(16).slice(1)}`

    return rgbaArrayToHex(rgbaOnColor(hexoToRgbaArray(hex_color, opacity)))
  }

  shapeRectangleGetFill (status: any) {
    return this.calcTransparentColorOnWhite(this.shapeGetFillColor(status), 30)
  }

  shapeRectangleGetWidth (entry_snapshot: any) {
    return `${entry_snapshot.meta.display_percent}%`
  }

  shapeCircleGetRadius () {
    return `${this.svgHeightQuantity / 6}${this.svgHeightUnit}`
  }

  shapeCircleGetYOffset () {
    return `${this.svgHeightQuantity / 2}${this.svgHeightUnit}`
  }

  openEntryModal = (entry_object: any, ui_setting: any) => {
    entry_object['binder'] = this.binderSnapshot.binder
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/entry_modal.html',
      controller: 'entryModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            
            object: angular.copy(entry_object),
            ui_setting
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then((result: any) => {
    }
    , (reason: any) => {
    })
  }
}

angular
  .module('complyosClient')
  .directive('ngSnapshotLinear', downgradeComponent({ component: SnapshotLinearComponent }) as angular.IDirectiveFactory)
