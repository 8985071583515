/* eslint-disable
    camelcase,
    handle-callback-err,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('entryValidationModeCommentModalController', ['$scope', '$rootScope', '$uibModalInstance', '$timeout', 'flashService', 'fortyCore', 'modalOptions', 'objectManager', 'Restangular', 'fileService', 'dateService', '$window', function (
  $scope: any,
  $rootScope: any,
  $uibModalInstance: any,
  $timeout: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  objectManager: any,
  Restangular: any,
  fileService: any,
  dateService: any,
  $window: any
) {
  const init = function () {
    
    $scope.flashService = angular.copy(flashService)
    $scope.processingStateService = fortyCore.processingStateService
    $scope.detectionServices = fortyCore.detectionService
    
    $scope.dateService = angular.copy(dateService)
    $scope.textService = fortyCore.text

    $scope.note_panel_visible = (modalOptions.uiSetting === 'update')
    $scope.formFailedSubmit = false

    $scope.user_role = $rootScope.session.getUser().user_role.role.title
    $scope.binder = angular.copy(modalOptions.object)
    $scope.selected_entry = null
    $scope.validationModeComment = $scope.defaultValidationModeComment()

    $scope.initReady = false
    $uibModalInstance.rendered.then(() => {
      $scope.initReady = true
    })
  }

  $scope.select_entry_attachment = (attachment: any, entry: any) => {
    $scope.formFailedSubmit = false
    $scope.selected_entry = entry
    $scope.validationModeComment = $scope.defaultValidationModeComment()
    $scope.validationModeComment.noteable_id = entry.id
    $scope.read_attachment_file(attachment)
  }

  $scope.defaultValidationModeComment = () => {
    return {
      type: 'Feed::ValidationModeComment',
      noteable_id: undefined,
      noteable_type: 'Entry',
      text: '',
      requiresResubmission: false
    }
  }

  $scope.set_resubmit_note = () => {
    if ($scope.validationModeComment.requiresResubmission === true) {
      $scope.validationModeComment.text = `Task documentation did not meet this requirement's applicable standards and requires_resubmission. Returned to schedule by ${$scope.session.getUser().profile.display_name}.`
    }
  }

  $scope.read_attachment_file = function (attachment: any) {
    // once we get side by side functionality. take out if statement.
    if ($scope.detectionServices.isMobileAmalgam() || $scope.detectionServices.isSafari()) {
      return open_file_url(attachment.url, '_blank')
    } else {
      return $scope.objectURL = attachment.url
    }
  }

  var open_file_url = function (url: any, target: any) {
    $window.open(url, target)
    return true
  }

  $scope.beginSubmitValidationModeComment = (form: any) => {
    $scope.processingStateService.performFunction(form, function () {
      if (form.$valid) {
        $scope.formFailedSubmit = false
        $scope.submitValidationModeComment(form)
      } else {
        $scope.formFailedSubmit = true
        $scope.processingStateService.set(
          form,
          $scope.processingStateService.processing_states.RESTING
        )
      }
    })
  }

  $scope.submitValidationModeComment = function (form: any) {
    Restangular.all('notes').post($scope.validationModeComment).then(
      (note_save_success: any) => {
        if ($scope.validationModeComment.requiresResubmission === true) {
          $scope.markEntryAsRequiresResubmission().then(
            (success: any) => {
              objectManager.array_action($scope.binder.entries, success, 'merge')
              $scope.endSubmitValidationModeComment(form, $scope.selected_entry, note_save_success)
            },
            (error: any) => {
              $scope.flashService.process_error(error)
            }
          )
        } else {
          $scope.endSubmitValidationModeComment(form, $scope.selected_entry, note_save_success)
        }
      },
      (note_save_error: any) => {
        $scope.flashService.process_error(note_save_error)
      }
    )
  }

  $scope.markEntryAsRequiresResubmission = () => {
    return Restangular.all(
      `entries/${$scope.selected_entry.id}/complete_status`
    ).post({
      complete_status: 'requires_resubmission'
    })
  }

  $scope.endSubmitValidationModeComment = (form: any, entry: any, note: any) => {
    // using a small timeout so that the user can see the button state
    // change and see when processing begins and ends
    $timeout(() => {
      // Note_save_success does not contain the information about the
      // entry. We are merging the note and the selected_entry (with its binder)
      // so that binder info is broadcasted to the ValidationMode panel.
      let full_entry = Object.assign({}, entry, { binder: $scope.binder })
      let full_note_object = Object.assign({}, note, { entry: full_entry })
      $rootScope.$broadcast('validationModeCommentAdded', full_note_object)
      // this will reset the comment field to '' and will also
      // uncheck the return to schedule checkbox
      $scope.validationModeComment = $scope.defaultValidationModeComment()
      $scope.processingStateService.set(
        form,
        $scope.processingStateService.processing_states.RESTING
      )
      form.failed_submission = false
    }, 500)
  }

  $scope.closeModalAndReturn = (success: any) => {
    $uibModalInstance.close(success)
  }

  $scope.submitCitation = function () {
    const entries_id = $scope.binder.entries[0]?.id
    if (!entries_id){
      console.error("binder does not have any entries");
      return;
    }

    return Restangular.all(
      `entries/${entries_id}/binder_citation`
    ).patch({
      entry:{
        binder_citation: $scope.binder.entries[0].binder.has_citation
      }
    })
  }

  $scope.updateCitation = () => {
    $scope.submitCitation().then(
      (response: any) => {
        $scope.citationMessage()
        console.log(response.message || "citation updated successfully");
        $rootScope.$broadcast('validationModeCommentUpdated', $scope.binder, $scope.binder.has_citation)
      },
      (error: any) => {
        console.error({ error });
      }
    )
  }

  $scope.citationMessage = () => {
    $scope.flashService.clear_alerts()
    if ($scope.binder.entries[0].binder.has_citation){
      $scope.binder.has_citation = true
      $scope.flashService.add_alert({
        name: 'citaion alert',
        dismissable: true,
        class: 'alert-success',
        strong: 'Success',
        message: 'Citation Saved'
      })
    }
    else{
      $scope.binder.has_citation = false
      $scope.flashService.add_alert({
        name: 'citaion alert',
        dismissable: true,
        class: 'alert-success',
        strong: 'Success',
        message: 'Citation Removed'
      }) 
    }
  }

  // dismiss the modal and provide a reason
  $scope.dismissModal = function () {
    $uibModalInstance.close('Dismissed')
  }

  return init()
}])
