import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import angular from 'angular';
import * as Restangular from '../../../vendor/restangular/restangular'
import { FlashService } from '../../services/flash_service.service';
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';

@Component({
  selector: 'ng-requirement-status-editor',
  templateUrl: './requirement-status-editor.component.html',
  styleUrls: ['./requirement-status-editor.component.scss']
})
export class RequirementStatusEditorComponent implements OnInit {


  @Input() uiSetting: string = 'read';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()

  //Injected Serivces
  public session: any;
  private flashService: any

  //Properties
  form: FormGroup
  formReady: boolean = false;
  original_published_state = undefined;
  original_archived_state = undefined;
  ValidationHelpers = ValidationHelpers
  private stateTypes: Array<any> = ['archived']

  constructor(
    public controlContainer: ControlContainer,
    @Inject('$rootScope') private $rootScope,
    private confirmationModalFactory: ConfirmationModalFactory,
    @Inject(FlashService) flashService: FlashService,
    private Restangular: Restangular,
  ) {
    this.session = $rootScope.session
    this.flashService = angular.copy(flashService)
  }

  ngOnInit(): void {
    this.addFormFields();

    this.original_published_state = this.requirement.published
    this.original_archived_state = !!this.requirement.state?.archived
    if (this.requirement.edit_state_for_id) {
      this.get_original_requirement_states(this.requirement.edit_state_for_id)
    }
  }

  // LISTS
  public stateConfigs = {
    published_state: {
      true: { currentState: 'Published', transitionState: 'Unpublished', icon: 'fa fa-check', transitionIcon: 'fa fa-minus', function: 'transitionFunction', currentStateColor: '#18BC9C', transitionStateColor: '#F39C12' },
      false: { currentState: 'Unpublished', transitionState: 'Published', icon: 'fa fa-minus', transitionIcon: 'fa fa-check', function: 'transitionFunction', currentStateColor: '#F39C12', transitionStateColor: '#18BC9C' },
    },
  }


  addFormFields = () => {
    this.form = this.controlContainer.control as FormGroup
    this.form.addControl('published', new FormControl(this.requirement.published))

    this.formReady = true;
  }

  discard_edit_state = () => {
    // for testing
    this.Restangular.one(`requirements/${this.requirement.id}/discard_edit_state`).remove().then((response: any) => { // success
      location.reload();
    })
  }
  get_original_requirement_states = (id) => this.Restangular.all('requirements').get(id).then(
    success => {
      var original_requirement = success.plain()
      this.original_published_state = original_requirement.published
      this.original_archived_state = original_requirement.state.archived === true
    }, (error: any) => this.flashService.process_error(error)
  )
  transitionButtonClick = (transitionTo: any) => {
    if (transitionTo === 'Unpublished' || transitionTo === 'Published') {
      this.confirm_unpublish(transitionTo)
    }
  }
  confirm_unpublish = (transitionTo: string) => {
    if (this.requirement.published === true) {
      const confirmation_object = {
        severity: 'danger',
        title: 'Unpublish Requirement',
        button_icon: 'fa-cloud-download',
        button_text: 'Unpublish',
        message: '<p>Are you sure you want to unpublish this? Unpublishing may have adverse side effects. The preferred flow would be to archive it.</p>',
        confirm: this.togglePublished
      }

      return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
    } else {
      this.togglePublished()
    }
  }
  togglePublished = () => {
    var toggledValue = !this.requirement.published
    this.form.get('published').setValue(toggledValue)
    this.requirement.published = toggledValue
    ValidationHelpers.updateTreeValidity(this.form)
    return this.requirement.published
  }
  cancelButtonClick = (event: any) => {
    this.togglePublished()
  }
}
