// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var papa = require("papaparse")
// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('complyosReportsController', ['$uibModal', '$rootScope', '$scope', 'confirmationModalFactory', 'FileSaver', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', '$window', function (
//   $uibModal: any,
//   $rootScope: any,
//   $scope: any,
//   confirmationModalFactory: any,
//   FileSaver: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any,
//   $window: any
// ) {
//   const init = function () {
//     /* FLASHES */
    
//     $scope.flashService = angular.copy(flashService)

//     /* LOADING FEEDBACK */
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     const customStates = {
//       loading: {
//         text: 'Loading Database Assoiciations'
//       },
//       ready: {
//         name: 'ready',
//         icon: 'fa-check',
//         text: `Choose report above to run`
//       },
//       error: {
//         name: 'report_error',
//         text: 'Error processing report'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, customStates)
//     $scope.loadingStateService.set_state('loading')

//     // Report objects
//     $scope.reports = [
//       // {
//       //   pretty_name: 'Requirement Adoption',
//       //   report_name: 'requirement_adoption',
//       //   param_data: {
//       //     requirement_ids: [], // required
//       //     organization_ids: [] // passing nothing will return results for all orgs
//       //   }
//       // },
//       {
//         pretty_name: 'User Activity',
//         report_name: 'user_activity',
//         description: 'This report can be used to view User information for your Organization, including the last time a User was active in Complyos.',
//         param_data: null
//       },
//       // {
//       //   pretty_name: 'For Urgent Care Centers - All "Urgent Care Service Line (UCC)" tagged requirements',
//       //   report_name: 'ucc_requirement_status',
//       //   param_data: null
//       // },
//       // {
//       //   pretty_name: 'For Hospitals - All "TJC Document List" tagged requirements',
//       //   report_name: 'hospital_tjc_status',
//       //   param_data: null
//       // },
//       // {
//       //   pretty_name: 'Collection Progress',
//       //   report_name: 'collection_progress',
//       //   param_data: {
//       //     organization_ids: [], // required
//       //     tag_ids: [] // required
//       //   }
//       // },
//       {
//         pretty_name: 'Form Response Report',
//         report_name: 'form_response',
//         description: 'This report can be run for only one Requirement at a time, and one or multiple Organizations, to review the answers and comments entered when the user was completing a Form attached to a Requirement.',
//         param_data: {
//           organization_ids: [], // required
//           requirement_ids: null // required
//         }
//       }
//     ]

//     // Loading feedback
//     Promise.all([
//       getRequirements(),
//       getOrganizations(),
//       getTags()
//     ]).then((values) => {
//       $scope.$apply($scope.loadingStateService.set_state('ready'))
//     })
//   }

//   $scope.launch_report_filter_modal = (report: any) => {
//     console.log(report)
//     $uibModal.open({
//       templateUrl: '_ng/components/report-filter-modal/report-filter-modal.component.html',
//       compopnent: 'ReportFilterModal',
//       size: 'lg',
//       resolve: {
//         modalOptions() {
//           return console.log('hi')
//         }
//       }
//     })
//   }

//   // creates a flattened array of the nested $scope.parent_select_opitons array and updates
//   // the name with spacing for tree structure
//   var flatten_organizations = function (organizations_array: any) {
//     organizations_array.sort(function (a: any, b: any) {
//       if (a['name'].toLowerCase() > b['name'].toLowerCase()) {
//         return 1
//       } else {
//         return -1
//       }
//     })
//     let flattened_organizations = []
//     let i = 0
//     while (i < organizations_array.length) {
//       organizations_array[i].name = `${determine_depth(organizations_array[i])}${organizations_array[i].name}`
//       flattened_organizations.push(organizations_array[i])
//       flattened_organizations = flattened_organizations.concat(flatten_organizations(organizations_array[i]['children']))
//       i++
//     }
//     return flattened_organizations
//   }

//   // creates dynamic indentions for the organizations select menu
//   // TODO maybe not the best name.
//   var determine_depth = function (organization: any) {
//     let spacing = ''
//     let i = 0
//     while (i < organization.depth) {
//       spacing += '\u00A0\u00A0'
//       i++
//     }
//     return spacing
//   }

//   var getRequirements = () => {
//     return Restangular.all('requirements/requirementsById').getList().then((response: any) => {
//       $scope.requirements = response
//       // for form response report 
//       $scope.form_requirements = response.filter(req => {
//         return req.compliance_method == "Form" || req.compliance_method == "Form/Template"
//       })
//     }, (error: any) => {
//       utils.log(error)
//     });
//   }

//   var getOrganizations = () => {
//     return Restangular.all('organizations').getList().then((response: any) => {
//       $scope.organizations = angular.copy(flatten_organizations(response))
//     }, (error: any) => {
//       utils.log(error)
//     });
//   }

//   var getTags = () => {
//     return Restangular.all('tags').getList({ flat: true }).then((response: any) => {
//       $scope.tags = response
//     }, (error: any) => {
//       utils.log(error)
//     });
//   }

//   $scope.run_report = function (report_number: any) {
//     let report = $scope.reports[report_number]
    
//     let stringified_report = angular.copy(report)
//     if (report_number === 0 || report_number === 4) {
//       stringified_report.param_data = $rootScope.storeService.stringifyTagParams(stringified_report.param_data)
//     }
//     $scope.flashService.clear_alerts()
//     $scope.loadingStateService.loading_state = {
//       name: `loading_report_${report_number}`,
//       icon: 'fa-gear fa-spin',
//       text: `Loading Report ${report.pretty_name}`
//     }

//     Restangular.setFullResponse(true).one(`reports/${report.report_name}`).get(stringified_report).then(function (response: any) {
//       $scope.loadingStateService.process_success(response)
//       $scope.report_file = new Blob([response.data], { type: 'text/csv' })
//       $scope.report_name = `${report.report_name}_${dayjs().format('YYYY-MM-DD')}.csv`

//       if (response.data.length < 500000) { // larger files break the preview.
//         // Load preview & let user choose to download
//         $scope.csv_preview = parse_csv(response.data)
//       } else {
//         $scope.download_report()
//         $scope.loadingStateService.loading_state = {
//           name: `report_downloaded}`,
//           icon: 'fa-download',
//           text: `Report Downloaded`
//         }
//       }

//     // -
//     }, (error: any) => {
//       utils.log(error)
//       $scope.loadingStateService.process_error(error)
//       $scope.flashService.add_alert({
//         name: 'report_error',
//         class: 'alert-danger',
//         strong: error.statusText,
//         message: error.data[0]
//       })

//     // -
//     }).finally(() => {
//       // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//       // Reset the full response setting
//       Restangular.setFullResponse(false)
//     })
//   }

//   $scope.download_report = function () {
//     FileSaver.saveAs($scope.report_file, $scope.report_name)
//     return 'report exported'
//   }

//   var parse_csv = function (data_to_parse: any) {
//     const csvRowArray = papa.parse(data_to_parse).data

//     const csvData = {
//       headers: [],
//       rows: []
//     }

//     let row = 0
//     while (row < csvRowArray.length) {
//       // Each array item, which is a row, is a string
//       // we now split that row into an array on a comma to create our "cells"
//       if (row === 0) {
//         // First row is our headers
//         csvData.headers = csvRowArray[row]
//       } else {
//         // every row after is actual data
//         csvData.rows.push(csvRowArray[row])
//       }
//       row++
//     }

//     return csvData
//   }

//   return init()
// }])
