
import * as angular from 'angular';
import _ from 'lodash';

export class objectManager {
  // private $q: any;
  // private utils: any;

  static $inject = ['$q', 'utils'];
  constructor(
    private $q: any, 
    private utils: any) {
      // this.$q = $q;
      // this.utils = utils;

  }

  private actions = [
    'merge',
    'remove',
    'update',
    'pull'
  ]

  private merge_object = function (arr: any, obj: any) {
    let index
    if (obj.id === undefined) {
      index = -1
    } else {
      index = _.findIndex(arr, { id: obj.id })
    }
    if (index > -1) {
      return _.merge(arr[index], obj)
    } else {
      return arr.push(obj)
    }
  }

  private remove_object = function (arr: any, obj: any) {
    if (obj.id === undefined) {
      return _.remove(arr, obj)
    } else {
      return _.remove(arr, { id: obj.id })
    }
  }
  private pull_object = function (arr: any, obj: any) {
    if (obj.id === undefined) {
      return _.pull(arr, obj)
    } else {
      return _.pull(arr, { id: obj.id })
    }
  }

  private update_object = function (arr: any, obj: any) {
    if (obj.id === undefined) {
      return this.utils.log('object does not have id, cannot update array', 'error')
    } else {
      const index = _.findIndex(arr, { id: obj.id })
      if (index > -1) {
        // we want to keep the memory address
        const object_l = arr[index]
        const object_r = obj
        const l_keys = _.keys(object_l)
        const r_keys = _.keys(object_r)
        const remove_keys = _.difference(l_keys, r_keys)
        _.each(remove_keys, (key: any) => object_l[key] = undefined)
        return _.each(r_keys, function (key: any) {
          object_l[key] = object_r[key]
        });
      }
    }
  }

  public array_action(arr: any, obj: any, action: any) {
    if (!arr || !obj || (this.actions.indexOf(action) === -1)) {
      this.utils.log('Array or Object incorrect.', 'error')
      this.utils.log({ arr, obj, action, index: this.actions.indexOf(action) })
      return false
    }

    switch (action) {
      case 'merge':
        this.merge_object(arr, obj)
        break
      case 'remove':
        this.remove_object(arr, obj)
        break
      case 'update':
        this.update_object(arr, obj)
        break
      case 'pull':
        this.pull_object(arr, obj)
        break
    }

    return true
  }
}

angular
  .module('complyosClient')
  .service("objectManager", objectManager);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// complyosClient.factory('objectManager', ['$q', 'utils', function ($q: any, utils: any) {
//   const actions = [
//     'merge',
//     'remove',
//     'update'
//   ]

//   const merge_object = function (arr: any, obj: any) {
//     let index
//     if (obj.id === undefined) {
//       index = -1
//     } else {
//       index = _.findIndex(arr, { id: obj.id })
//     }
//     if (index > -1) {
//       return _.merge(arr[index], obj)
//     } else {
//       return arr.push(obj)
//     }
//   }

//   const remove_object = function (arr: any, obj: any) {
//     if (obj.id === undefined) {
//       return _.remove(arr, obj)
//     } else {
//       return _.remove(arr, { id: obj.id })
//     }
//   }

//   const update_object = function (arr: any, obj: any) {
//     if (obj.id === undefined) {
//       return utils.log('object does not have id, cannot update array', 'error')
//     } else {
//       const index = _.findIndex(arr, { id: obj.id })
//       if (index > -1) {
//         // we want to keep the memory address
//         const object_l = arr[index]
//         const object_r = obj
//         const l_keys = _.keys(object_l)
//         const r_keys = _.keys(object_r)
//         const remove_keys = _.difference(l_keys, r_keys)
//         _.each(remove_keys, (key: any) => object_l[key] = undefined)
//         return _.each(r_keys, function (key: any) {
//           object_l[key] = object_r[key]
//         });
//       }
//     }
//   }

//   return {
//     array_action(arr: any, obj: any, action: any) {
//       if (!arr || !obj || (actions.indexOf(action) === -1)) {
//         utils.log('Array or Object incorrect.', 'error')
//         utils.log({ arr, obj, action, index: actions.indexOf(action) })
//         return false
//       }

//       switch (action) {
//         case 'merge':
//           merge_object(arr, obj)
//           break
//         case 'remove':
//           remove_object(arr, obj)
//           break
//         case 'update':
//           update_object(arr, obj)
//           break
//       }

//       return true
//     }
//   };
// }])