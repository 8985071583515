import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngUserDashboard',
  templateUrl: './user-dashboard.component.html'
})
export class UserDashboardComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  // Properties
  private previousPills: any = null;
  private organization: any;
  private showSurveyTracker: boolean = false;
  private dashboardCollectionBinderCount: number = 0;
  private param_to_remove: Array<any> = ['sorted', 'pagination_params'];
  private filtersToDisable: Array<any> = ['visible', 'role_ids'];
  private outstandingPromises: Array<any> = [];
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private arrayFunctions: ArrayFunctions
  ) {
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    this.$scope.$on('activeOrgIdUpdated', () => {
      this.getActiveOrg()
    })
    this.getActiveOrg()
    this.loadingStateService.init()
    this.watchEntityFilter()
  }

  watchEntityFilter = () => {
    this.$scope.$on('filterChange-dashboard', (e: any, pills: any) => {
      let binderCountMayChange = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['date', 'sorted', 'pagination_params']) || this.previousPills === null
      this.previousPills = pills
      if (binderCountMayChange) {
        this.abortAllPromises(this.outstandingPromises)
        setTimeout(() => this.getBinderCount(pills, binderCountMayChange), 50)
      } else {
        this.$rootScope.$broadcast('refresh-dashboard-widgets', pills, this.dashboardCollectionBinderCount)
      }
    })
  }

  getBinderCount = (pills: any, binderCountMayChange: any) => {
    this.loadingStateService.set_state('loading')

    var queryParams = {
      model: 'Binder',
      method: 'binder_count',
      param_data: {}
    }

    let newPills = this.$rootScope.storeService.pillsToRemove(pills, this.param_to_remove)
    queryParams.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(newPills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(queryParams).then((success: any) => {
      this.dashboardCollectionBinderCount = success.data.collection_count
      this.loadingStateService.set_state('content')
      if (this.dashboardCollectionBinderCount > 0 || binderCountMayChange) {
        // $scope.broadcastPaginationResults(success.pagination)
        this.$rootScope.$broadcast('refresh-dashboard-widgets', pills, this.dashboardCollectionBinderCount)
      }
    }
    , (error: any) => {
      this.loadingStateService.process_error(error)
      this.utils.log(error, 'error', false)
    })
  }

  getActiveOrg = () => {
    this.organization = this.$rootScope.session.getOrganization()
    this.showSurveyTracker = this.shouldShowSurveyTracker()
  }

  shouldShowSurveyTracker = () => {
    if (!this.organization) {
      return false
    }

    const { date_survey_last, date_survey_next_begin, date_survey_next_end } = this.organization
    return !!(date_survey_last || (date_survey_next_begin && date_survey_next_end))
  }

  abortAllPromises = (promiseArray: any) => {
    this.$rootScope.$broadcast('cancel-pending-filter-queries')
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }
}

angular
  .module('complyosClient')
  .directive('ngUserDashboard', downgradeComponent({ component: UserDashboardComponent }) as angular.IDirectiveFactory)
