import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Restangular from '../../../../vendor/restangular/restangular'
import angular from 'angular';

@Component({
  selector: 'ng-requirement-version-selector',
  templateUrl: './requirement-version-selector.component.html',
  styleUrls: ['./requirement-version-selector.component.scss']
})
export class RequirementVersionSelectorComponent implements OnInit {

  @Input() requirementId
  @Input() versionInfo: any
  @Output() versionInfoChange = new EventEmitter<any>();
  @Input() disabledVersionInfo: any

  //properties
  version_history: Array<any> = []

  constructor(
    private Restangular: Restangular
  ) { }

  ngOnInit(): void {
    this.getVersionHistory()
  }

  getVersionHistory() {
    return this.Restangular.one(`requirements/${this.requirementId}/get_version_history`).get().then(
      (success_response: any) => {
        this.version_history = success_response
      }
    ).finally(function () { });
  }

  selectVersionInfo = (version_info) => {
    this.versionInfo = angular.copy(version_info)
    delete this.versionInfo.version

    this.versionInfoChange.emit(this.versionInfo)
  }
  versionInfoEqualityComparer(first, second) {

    return first && second 
      && first.snapshot_id == second.snapshot_id
      && first.is_edit_state == second.is_edit_state
      && first.is_current_version == second.is_current_version
  }
}
