// import { fortyCore } from './forty_core.service';
import { njsResource } from './njs_resource.resource';
import { objectManager } from './ajs-object_manager';
import { treeManager } from './ajs-tree_manager_service';
import { helpService } from './help';
import * as Restangular from '../../vendor/restangular/restangular'
import { Utils } from './ajs-utils';
import { ConfirmationModalFactory } from './ajs-confirmation_modal_factory';
import { ArrayFunctions } from '../utilities/ajs-array_functions';

// import * as Restangular from 'restangular' // DONT USE THIS RESTANGULAR. USE THE VENDOR VERSION
//                                               view ../../vendor/restangular/reason.md

// ----------------------------------------------------------------------------------------------------------
//  SERVICE UPGRADE ADAPRTERS
//      This is how you adapt an existing ajs service to be used in an Angular12+ service
//
//  STEPS:
//      1) Move service to its own file, using classes (with $inject, constructor, and functions)
//          see `flash_service.service.ts` or `_SERVICE_TEMPLATE.ts` for reference.
//      2) if the service was not already in its own file: Add the new ajs-service file reference 
//          to `index.legacy.ts`. EXAMPLE "import './services/ajs-flash_service.service'"
//      3) Add Factory/Provider pair to this file (copy and update the xxxFactory/xxxProvider examples below)
//      4) Add your new provider to app.module.ts "providers" property
//      
//  TO USE:
//      when you want to inject it into a ng12-service OR ng12-component:
//      1) Add your service to the contructor 
//          EXAMPLE: constructor(private flashService: flashService)
//               OR: constructor(@Inject(flashService) private flashService: flashService)
//                                  ^ this does not seem to be required for ajs-adapted services
//               OR: constructor(@Inject('$timeout') private $timeout)
//                                            ^ some services have not been 'class-ified' and so much be 
//                                                id'd and injected based on their string key ('$timeout')
//
//  PITFALLS:
//      * make sure your imports NEVER use absolute paths, ie: 'app/scripts/.....'
//        they should ALWAYS use reletive paths, ie: '../../scripts/.....'
//        the folder 'app' does not exist in the build process
// ----------------------------------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------------------------------
//  TEMPLATE
//
// export function xxxFactory(i: any) {
//   return i.get('xxx');
// }
// export const xxxProvider = {
//   provide: xxx,
//   useFactory: xxxFactory,
//   deps: ['$injector']
// };
// ----------------------------------------------------------------------------------------------------------

// $state
export function $stateFactory(i: any) {
  return i.get('$state');
}
export const $stateProvider = {
  provide: '$state',
  useFactory: $stateFactory,
  deps: ['$injector']
};

// $q
export function $qFactory(i: any) {
  return i.get('$q');
}
export const $qProvider = {
  provide: '$q',
  useFactory: $qFactory,
  deps: ['$injector']
};

// $httpParamSerializer
export function $httpParamSerializerFactory(i: any) {
  return i.get('$httpParamSerializer');
}
export const $httpParamSerializerProvider = {
  provide: '$httpParamSerializer',
  useFactory: $httpParamSerializerFactory,
  deps: ['$injector']
};

// $window
export function $windowFactory(i: any) {
  return i.get('$window');
}
export const $windowProvider = {
  provide: '$window',
  useFactory: $windowFactory,
  deps: ['$injector']
};

// njsResource
export function njsResourceFactory(i: any) {
  return i.get('njsResource');
}
export const njsResourceProvider = {
  provide: njsResource,
  useFactory: njsResourceFactory,
  deps: ['$injector']
};

// $timeout
export function $timeoutFactory(i: any) {
  return i.get('$timeout');
}
export const $timeoutProvider = {
  provide: '$timeout',
  useFactory: $timeoutFactory,
  deps: ['$injector']
};

// fortyCore
export function fortyCoreFactory(i: any) {
  return i.get('fortyCore');
}
export const fortyCoreProvider = {
  provide: 'fortyCore',     // Could not be 'class'-ified so use a string as injection key
  useFactory: fortyCoreFactory,
  deps: ['$injector']
};

// objectManager
export function objectManagerFactory(i: any) {
  return i.get('objectManager');
}
export const objectManagerProvider = {
  provide: objectManager,
  useFactory: objectManagerFactory,
  deps: ['$injector']
};

// treeManager
export function treeManagerFactory(i: any) {
  return i.get('treeManager');
}
export const treeManagerProvider = {
  provide: treeManager,
  useFactory: treeManagerFactory,
  deps: ['$injector']
};

// helpService
export function helpServiceFactory(i: any) {
  return i.get('helpService');
}
export const helpServiceProvider = {
  provide: helpService,
  useFactory: helpServiceFactory,
  deps: ['$injector']
};

// njsRestangular
export function restangularFactory(i: any) {
  return i.get('Restangular');
}
export const restangularProvider = {
  provide: Restangular,
  useFactory: restangularFactory,
  deps: ['$injector']
};

// $scope
export function $scopeFactory(i: any) {
  // return i.get('$scope');
  return i.get('$rootScope').$new()
}
export const $scopeProvider = {
  provide: '$scope',
  useFactory: $scopeFactory,
  deps: ['$injector']
};

// $rootScope
export function $rootScopeFactory(i: any) {
  // return i.get('$scope');
  return i.get('$rootScope')
}
export const $rootScopeProvider = {
  provide: '$rootScope',
  useFactory: $scopeFactory,
  deps: ['$injector']
};

// ENV
export function envFactory(i: any) {
    return i.get('ENV');
}
export const envProvider = {
    provide: 'ENV',
    useFactory: envFactory,
    deps: ['$injector']
};

// $uibModal
export function $uibModalFactory(i: any) {
    return i.get('$uibModal');
}
export const $uibModalProvider = {
  provide: '$uibModal',
  useFactory: $uibModalFactory,
  deps: ['$injector']
};

// NOTE: could not get new NG to find this provider
// // $uibModalInstance
// export function $uibModalInstanceFactory(i: any) {
//   return i.get('$uibModalInstance');
// }
// export const $uibModalInstanceProvider = {
//   provide: '$uibModalInstance',
//   useFactory: $uibModalInstanceFactory,
//   deps: ['$injector']
// };

// complyosServices
export function complyosServicesFactory(i: any) {
  return i.get('complyosServices');            // Could not be 'class'-ified so use a string as injection key
}
export const complyosServicesProvider = {
  provide: 'complyosServices',
  useFactory: complyosServicesFactory,
  deps: ['$injector']
};

// loadingStateService
export function loadingStateServiceFactory(i: any) {
  return i.get('loadingStateService');            // Could not be 'class'-ified so use a string as injection key
}
export const loadingStateServiceProvider = {
  provide: 'loadingStateService',
  useFactory: loadingStateServiceFactory,
  deps: ['$injector']
};

// utils
export function utilsFactory(i: any) {
  return i.get('utils');
}
export const utilsProvider = {
  provide: Utils,
  useFactory: utilsFactory,
  deps: ['$injector']
};

// Upload
export function uploadFactory(i: any) {
  return i.get('Upload');
}
export const uploadProvider = {
  provide: 'Upload',
  useFactory: uploadFactory,
  deps: ['$injector']
};

// confirmationModalFactory
// its the factory for the factory. what do you want? :P
export function confirmationModalFactoryFactory(i: any) {
  return i.get('confirmationModalFactory');
}
export const confirmationModalFactoryProvider = {
  provide: ConfirmationModalFactory,
  useFactory: confirmationModalFactoryFactory,
  deps: ['$injector']
};

// arrayFunctions
export function arrayFunctionsFactory(i: any) {
  return i.get('arrayFunctions');
}
export const arrayFunctionsProvider = {
  provide: ArrayFunctions,
  useFactory: arrayFunctionsFactory,
  deps: ['$injector']
};

//- ----------------------------------------------------------------------------------------------------------
//-
//- SERVICES THAT HAVE BEEN FULLY UPGRADED TO ANGULAR 12 CAN BE MOVED BELOW
//- 

// flashService
// export function flashServiceFactory(i: any) {
//   return i.get('flashService');
// }
// export const flashServiceProvider = {
//   provide: flashService,
//   useFactory: flashServiceFactory,
//   deps: ['$injector']
// };

// ----------------------------------------------------------------------------------------------------------