import { Component, Inject, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngSnapshotMetrics',
  templateUrl: './snapshot-metrics.component.html'
})
export class SnapshotMetrics implements OnInit {
  @Input() showPercent: boolean;
  @Input() showMetrics: boolean;
  @Input() metrics: any;
  
  constructor(
    @Inject('complyosServices') private complyosServices,
    @Inject('fortyCore') private fortyCore: any,
  ) {
  }

  ngOnInit(): void {
  }

  getTotal () {
    return this.metrics.total ? this.metrics.total : 0
  }

  getLegendColor (metric: any) {
    return this.complyosServices.snapshot.get_status_color(metric)
  }

  titalize (string: any) {
    const split_words = this.fortyCore.text.splitWords(string, '_')
    return this.fortyCore.text.titalize(split_words)
  }

  calculatePercentOfTotal (value: any) {
    const percent = ((value / this.getTotal()) * 100)
    return Number((percent).toFixed(1))
  }
}

angular
  .module('complyosClient')
  .directive('ngSnapshotMetrics', downgradeComponent({ component: SnapshotMetrics }) as angular.IDirectiveFactory)
