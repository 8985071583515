/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

var dayjs = require("dayjs")

var DashboardFilterComponent = {
  selector: "dashboardFilter", //<dashboard-filter>
  // templateUrl: 'views/directives/collapse_panel.html',
  template: require('../../views/directives/dashboard_filter.pug'),
  restrict: 'E',
  bindings: {
    disableFiltersConfig: '=',
    allowLocking: '=',
    injectedDefaultFilters: '=',
    pageName: '=',
    hideClearFiltersButton: '=',
    defaultFilterConfig: '=',
    disableCaching: '=',
    showFormRequirements: '=',
    broadcastIdentifier: '=?',
    debounceTime: '=?',
    searchHitsPlaceholder: '=?'
  },

  controller: class DashboardFilterController {
    // BINDING PLACEHOLDERS
    private disableFiltersConfig: any;
    private allowLocking: any;
    private injectedDefaultFilters: any;
    private pageName: any;
    private hideClearFiltersButton: any;
    private defaultFilterConfig: any;
    private disableCaching: any;
    private showFormRequirements: boolean;
    private broadcastIdentifier: any;
    private debounceTime: number;
    private searchHitsPlaceholder: string;

    // PROPERTIES
    private untouchedPresets: any;
    private filterLoadingState: any;
    private tree_data: any;

    // CONFIG LISTS
    private ArchivedRadioOptions: Array<any>;
    private AcknowledgedRadioOptions: Array<any>;
    private ScheduleVisibilityRadioOptions: Array<any>;
    private StatusRadioOptions_RequirementsPage: Array<any>;
    private StatusRadioOptions_StorePage: Array<any>;
    private StatusRadioOptions_EmailLogsPage: Array<any>;
    private dateRangeOptions: Array<any>;
    private reviewDateRangeOptions: Array<any>;
    private EditStateRadioOptions: Array<any>;
    private WithEditStateRadioOptions: Array<any>;
    private filterConfigs: Array<any>;
    private presetFilters: Array<any>;

    static $inject = ['$rootScope', '$scope', 'arrayFunctions', 'constants', 'filterService', 'fortyCore', 'loadingStateService', 'Restangular', 'storeService', 'utils'];
    constructor(private $rootScope, private $scope, private arrayFunctions, private constants, private filterService, private fortyCore, private loadingStateService, private Restangular, private storeService, private utils
    ) {
    }

    $onInit = () => {
      this.broadcastIdentifier = this.broadcastIdentifier || 'filterChange-dashboard'
      // the debounce time is normally 1500
      // in most cases where the dashboard filter is used, api calls are being made everytime a filter pill is removed
      // the debounce time will make sure the user is done clicking away pills before making an api call
      // think if you rapid fire remove 20 pills at once. we don't want to make 20 api calls. better to make 1 call once they are done removing all of them
      // if you don't want a debounce time on the page you are using the filter you can set debounceTime="1"
      this.debounceTime = this.debounceTime || 1500
      this.searchHitsPlaceholder = this.searchHitsPlaceholder || "SEARCH BY: Interval, Requirement, Reference or Tag"

      this.initializeConfigs()
      this.pushPublishedVisibilityObject()

      if (Array.isArray(this.injectedDefaultFilters) && this.injectedDefaultFilters.length > 0) {
        this.presetFilters = this.presetFilters.concat(this.injectedDefaultFilters)
      }


      this.untouchedPresets = JSON.parse(JSON.stringify(this.presetFilters))

      this.filterLoadingState = angular.copy(this.loadingStateService)

      this.filterLoadingState.init()
      this.filterLoadingState.set_state('loading')

      this.disableVisibility()
      
      this.removeFilterConfig(this.disableFiltersConfig)
      this.$scope.$on('referencesLoaded', function (e: any) {
        this.loadRequirements()
      })
      this.loadTerminalReferences()
      this.loadCachedFilters()
      this.resetVisibilityFilter()
      this.$scope.$broadcast('filtersLoaded-dashboard', this.presetFilters)

      this.$scope.$on('reloadFiltersFromCache', (e: any) => {
        this.resetFromCache()
      })

      this.filterLoadingState.set_state('content')
    }

    initializeConfigs = () => {
      
      this.ArchivedRadioOptions =[
        { name: 'All', displayName: 'All', tooltip: 'show all configured requirements', value: 'all' },
        { name: 'Active', displayName: 'Active', tooltip: 'show active configured requirements', value: 'active' },
        { name: 'Archived', displayName: 'Archived', tooltip: 'show archived configured requirements', value: 'archived' },
        { name: 'Start Date Needed', displayName: 'Start Date Needed', tooltip: 'show configured requirements that need a start date', value: 'needs_start_date', overrideFieldValue: 'schedule_generation_state' },
        { name: 'Citation', displayName: 'Citation', tooltip: 'show requirements which has citation', value: 'citation'}
      ]
      this.AcknowledgedRadioOptions =[
        { name: 'All', displayName: 'All', tooltip: 'Show all comments', value: 'all' },
        { name: 'Acknowledged', displayName: 'Acknowledged', tooltip: 'show only acknowledged comments', value: 'acknowledged' },
        // { name: 'requires_resubmission', displayName: 'requires_resubmission', tooltip: 'show only acknowledged comments', value: 'requires_resubmission' },
        // { name: 'NotAcknowledged', displayName: 'NotAcknowledged', tooltip: 'show only acknowledged comments', value: 'not_acknowledged' },
        { name: 'Needs Action', displayName: 'Needs Action', tooltip: 'show comments that do not have a response OR are attached to entries that require resubmission', value: 'action_needed' }
      ]
      this.ScheduleVisibilityRadioOptions =[
        { name: 'All', displayName: 'All', tooltip: 'Show all entries', value: 'all' },
        { name: 'Partially Complete', displayName: 'Partially Complete', tooltip: 'Show only entries that are partially complete', value: 'partially_complete' },
        { name: 'Requires Approval', displayName: 'Requires Approval', tooltip: 'Show only entries that are ready for review by an admin', value: 'requires_review' },
        { name: 'Requires Resubmission', displayName: 'Requires Resubmission', tooltip: 'Show only entries that have been marked as Requires Resubmission through validation', value: 'requires_resubmission' },
        { name: 'Citation', displayName: 'Citation', tooltip: 'show requirements which has citation', value: 'citation'}
      ]
      this.StatusRadioOptions_RequirementsPage =[
        { name: 'All', displayName: 'All', tooltip: 'Show all requirements', value: 'all' },
        { name: 'Published', displayName: 'Published', tooltip: 'Show only requirements that are published', value: 'published' },
        { name: 'Unpublished', displayName: 'Unpublished', tooltip: 'Show only requirements that are unpublished and not archived', value: 'unpublished' },
        { name: 'Edit-State', displayName: 'Edit-State', tooltip: 'Show only requirements that have an pending changes', value: 'edit_state_id' },
        { name: 'Archived', displayName: 'Archived', tooltip: 'Show only requirements that are archived', value: 'archived' }
      ]
      this.StatusRadioOptions_StorePage = [
        { name: 'All', displayName: 'All', tooltip: 'Show all requirements', value: 'all' },
        { name: 'Published', displayName: 'Published', tooltip: 'Show only requirements that are published', value: 'published' },
        { name: 'Unpublished', displayName: 'Unpublished', tooltip: 'Show only requirements that are unpublished and not archived', value: 'unpublished' },
        { name: 'Archived', displayName: 'Archived', tooltip: 'Show only requirements that are archived', value: 'archived' }
      ]
      this.StatusRadioOptions_EmailLogsPage = [
        { name: 'All', displayName: 'All Emails', tooltip: 'Show all Emails - failed and successful', value: 'all' },
        { name: 'Successful', displayName: 'Successful Emails', tooltip: 'Show only successful emails', value: 'successful' },
        { name: 'Failed', displayName: 'Failed Emails', tooltip: 'Show only emails that failed to send', value: 'failed' }
      ]
      this.dateRangeOptions = [
        { name: '6 mo', displayName: '6 months', tooltip: 'last 6 months', range: { date_range_begin: dayjs().subtract(6, 'months').$d, date_range_end: dayjs().utc().endOf('day').$d } },
        { name: '1 yr', displayName: '1 year', tooltip: 'last year', range: { date_range_begin: dayjs().subtract(1, 'years').$d, date_range_end: dayjs().utc().endOf('day').$d } },
        { name: '3 yr', displayName: '3 years', tooltip: 'last 3 years', range: { date_range_begin: dayjs().subtract(3, 'years').$d, date_range_end: dayjs().utc().endOf('day').$d } }
      ]
      this.reviewDateRangeOptions = [
        { name: 'All', displayName: 'All', tooltip: 'All Requirements', range: { date_range_begin: 'all', date_range_end: 'all' } },
        { name: '30', displayName: '30', tooltip: 'This requirement needs to be reviewed within the next 30 days', range: { date_range_begin: dayjs().subtract(23, 'months').$d, date_range_end: dayjs().subtract(10, 'years').$d } },
        { name: '60', displayName: '60', tooltip: 'This requirement needs to be reviewed within the next 60 days', range: { date_range_begin: dayjs().subtract(22, 'months').$d, date_range_end: dayjs().subtract(23, 'months').$d } },
        { name: '90', displayName: '90', tooltip: 'This requirement needs to be reviewed within the next 90 days', range: { date_range_begin: dayjs().subtract(21, 'months').$d, date_range_end: dayjs().subtract(22, 'months').$d } }
      ]
      // this.EditStateRadioOptions = [
      //   { name: 'All', displayName: 'All', tooltip: 'Show all requirements', value: 'all' },
      //   { name: 'Edit Version', displayName: 'Edit Version', tooltip: 'Show only edit_state requirements', value: 'true' },
      //   { name: 'Current Version', displayName: 'Current Version', tooltip: 'Show only requirements not in edit state ()', value: 'false' }
      // ]
      // this.WithEditStateRadioOptions = [
      //   { name: 'All', displayName: 'All', tooltip: 'Show all requirements', value: 'all' },
      //   { name: 'Has Pending Edits', displayName: 'Has Pending Edit-State', tooltip: 'Show only requirements with pending Edit-States', value: 'true' }
      // ]
      // let AssignmentTypeRadioOptions = [
      //   { name: 'All', displayName: 'All', tooltip: 'Show all Assignments', value: 'all' },
      //   { name: 'Configured Requirements', displayName: 'Configured Requirements', tooltip: 'Show only Configured Requirement Assignments', value: 'Configured Requirement' },
      //   { name: 'Organizations', displayName: 'Organizations', tooltip: 'Show only Organization Assignments', value: 'Organization' }
      // ]
      this.filterConfigs = [
        { position: 0, filterIdentifier:'search_hits', fieldDisplayName: 'Search', fieldValue: 'search_hits', type: this.constants.FilterType.CustomSearch, findSearchMatchesFunction: this.findSearchMatches, isUnique: true },
        { position: 0.1, filterIdentifier:'assignee_user_id', fieldDisplayName: 'Assignees', fieldValue: 'assignee_user_id', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.assigneesApiSearchFunction, descendantsName: 'assignees' },
        { position: 1, filterIdentifier:'date', fieldDisplayName: 'Date', fieldValue: 'date', type: this.constants.FilterType.Date, isUnique: true, list: this.dateRangeOptions },
        { position: 2, filterIdentifier:'interval_ids', fieldDisplayName: 'Intervals', fieldValue: 'interval_ids', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.intervalsApiSearchFunction },
        { position: 2.9, filterIdentifier:'organizations_ids', fieldDisplayName: 'Organizations', fieldValue: 'organizations_ids', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.organizationsApiSearchFunction, enableDescendantChecking: true, descendantsName: 'organizations', descendantsPrefix: 'sub-' },
        { position: 4, filterIdentifier:'reference_id', fieldDisplayName: 'References', fieldValue: 'reference_id', type: this.constants.FilterType.NodeTreeBuilder, loadNextLevelFunction: this.loadNextLevelReferences, segmentToolTipFunction: null, descendantsName: 'references' },
        { position: 5, filterIdentifier:'requirement_ids', fieldDisplayName: 'Requirements', fieldValue: 'requirement_ids', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.requirementsApiSearchFunction },
        { position: 6, filterIdentifier:'role_ids', fieldDisplayName: 'Roles', fieldValue: 'role_ids', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.rolesApiSearchFunction },
        { position: 7, filterIdentifier:'surveying_ids', fieldDisplayName: 'Surveying Entities', fieldValue: 'surveying_ids', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.surveyingEntitiesApiSearchFunction },
        { position: 8, filterIdentifier:'tag_ids', fieldDisplayName: 'Tags', fieldValue: 'tag_ids', enableDescendantChecking: true, descendantsName: 'tags', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.tagsApiSearchFunction, descendantsPrefix: 'sub-' },
        { position: 9, filterIdentifier:'visible', fieldDisplayName: 'Visibility (All, Active, Archived, Needs Start Date, Citation)', fieldValue: 'visible', type: this.constants.FilterType.Radio, isUnique: true, list: this.ArchivedRadioOptions },
        { position: 10, filterIdentifier:'acknowledged_scope', fieldDisplayName: 'Visibility (All, Acknowledged, Needs Action)', fieldValue: 'acknowledged_scope', type: this.constants.FilterType.Radio, isUnique: true, list: this.AcknowledgedRadioOptions },
        { position: 11, filterIdentifier:'schedule_visibility', fieldDisplayName: 'Visibility (All, Partially Complete, Requires Approval, Requires Resubmission, Citation)', fieldValue: 'schedule_visibility', type: this.constants.FilterType.Radio, isUnique: true, list: this.ScheduleVisibilityRadioOptions },
        { position: 12, filterIdentifier:'published_visibility', fieldDisplayName: 'Visibility (All, Published, Unpublished, Edit-State, Archived)', fieldValue: 'published_visibility', type: this.constants.FilterType.Radio, isUnique: true, list: this.StatusRadioOptions_RequirementsPage },
        { position: 13, filterIdentifier:'published_visibility_store', fieldDisplayName: 'Visibility (All, Published, Unpublished, Archived)', fieldValue: 'published_visibility', type: this.constants.FilterType.Radio, isUnique: true, list: this.StatusRadioOptions_StorePage },
        { position: 14, filterIdentifier:'single_requirements_id', fieldDisplayName: 'Requirements', fieldValue: 'single_requirements_id', type: this.constants.FilterType.MultiAPISearch, apiSearchFunction: this.requirementsApiSearchFunction, noMultiSelect: true, isUnique: true},
        { position: 15, filterIdentifier:'review_window', fieldDisplayName: 'Review Window (30, 60, 90)', fieldValue: 'review_window', type: this.constants.FilterType.Date, isUnique: true, list: this.reviewDateRangeOptions},
        { position: 16, filterIdentifier:'email_logs_visibility', fieldDisplayName: 'Visibility (All, Successful, Failed)', fieldValue: 'email_logs_visibility', type: this.constants.FilterType.Radio, isUnique: true, list: this.StatusRadioOptions_EmailLogsPage },
        // { position: 13, fieldDisplayName: 'Edit-State', fieldValue: 'is_edit_state', type: this.constants.FilterType.Radio, isUnique: true, list: this.EditStateRadioOptions },
        // { position: 13.1, fieldDisplayName: 'Edit-State', fieldValue: 'edit_state_id', type: this.constants.FilterType.Radio, isUnique: true, list: this.WithEditStateRadioOptions }
      ]
      this.presetFilters = [{
        isUnique: true,
        isRequired: true,
        Field: 'date',
        Op: this.constants.FilterType.Date,
        displayName: 'Date',
        displayValue: '6 months',
        Value: { date_range_begin: dayjs().subtract(6, 'months').$d, date_range_end: dayjs().utc().endOf('day').$d },
        dateCreated: dayjs().$d
      }, {
        isUnique: true,
        isRequired: true,
        isHidden: true,
        Field: 'pagination_params',
        Op: this.constants.FilterType.Date,
        displayName: 'Page',
        displayValue: 'page: 1 (50/pg)',
        Value: { 'page': 1, 'pageSize': 50 },
        dateCreated: dayjs().$d
      },
      {
        isUnique: true,
        isRequired: true,
        Field: 'visible',
        Op: this.constants.FilterType.Radio,
        displayName: 'Visibility',
        displayValue: 'Active',
        Value: 'active',
        dateCreated: dayjs().$d
      },
      {
        isUnique: true,
        isRequired: true,
        Field: 'schedule_visibility',
        Op: this.constants.FilterType.Radio,
        displayName: 'Visibility',
        displayValue: 'All',
        Value: 'all',
        dateCreated: dayjs().$d
      },
      {
        isUnique: true,
        isRequired: true,
        Field: 'email_logs_visibility',
        Op: this.constants.FilterType.Radio,
        displayName: 'Visibility',
        displayValue: 'All Emails',
        Value: 'all',
        dateCreated: dayjs().$d
      },
      {
        isUnique: true,
        isRequired: true,
        Field: 'acknowledged_scope',
        Op: this.constants.FilterType.Radio,
        displayName: 'Visibility',
        displayValue: 'Action Needed',
        Value: 'action_needed',
        dateCreated: dayjs().$d
      },
      {
        isUnique: true,
        isRequired: true,
        Field: 'review_window',
        Op: this.constants.FilterType.Date,
        displayName: 'Review Window',
        displayValue: 'All',
        Value: { date_range_begin: 'all', date_range_end: 'all' },
        dateCreated: dayjs().$d
      }
      // {
      //   isUnique: true,
			// 	isRequired: true,
			// 	isHidden: true,
			// 	Field: 'is_edit_state',
      //   Op: this.constants.FilterType.Radio,
      //   displayName: 'Version',
      //   displayValue: 'EditState',
      //   Value: 'false',
      //   dateCreated: dayjs().$d
      // },
      // {
      //   isUnique: true,
			// 	isRequired: true,
			// 	isHidden: true,
			// 	Field: 'edit_state_id',
      //   Op: this.constants.FilterType.Radio,
      //   displayName: 'EditState',
      //   displayValue: 'All',
      //   Value: 'all',
      //   dateCreated: dayjs().$d
      // }
      ]
    }

  onFiltersChange = function (appliedFilters: any) {
    // if (!this.organizationCheck(appliedFilters)) {
    //   this.resetFromCache()
    //   return
    // }
    let appliedFiltersCopy = angular.copy(appliedFilters)
    let organizationPills = appliedFiltersCopy.filter((f: any) => f.Field === 'organizations_ids')
    let activeOrg = this.$rootScope.session.getOrganization()
    if (Array.isArray(organizationPills) && organizationPills.length == 1 && !organizationPills[0].includeDescendants) {
      let orgId = organizationPills[0].Value
      if (!activeOrg || activeOrg.id != orgId) {
        this.Restangular.one(`organizations/${orgId}`).get().then((response: any) => {
          this.$rootScope.session.setOrganization(response, true)
        })
      }
    } else if (activeOrg != null) {
      this.$rootScope.session.setOrganization(null, true)
    }

    if (this.disableCaching !== true) {
      this.filterService.setCache(appliedFiltersCopy)
    }
    let applicableFilters = this.removeDisabledFilters(appliedFiltersCopy)
    console.log('broadcasted filters: ', applicableFilters)
    this.$rootScope.$broadcast(this.broadcastIdentifier, applicableFilters)
  }

  removeDisabledFilters = (incomingFilters: any) => {
    let filters = JSON.parse(JSON.stringify(incomingFilters))
    if (Array.isArray(this.disableFiltersConfig) && this.disableFiltersConfig.length > 0) {
      var activeFilters = this.filterConfigs.map(fc => fc.fieldValue)
      filters = filters.filter((f: any) => !this.disableFiltersConfig.includes(f.Field) || activeFilters.includes(f.Field))
    }
    return filters
  }

  removeFilterConfig = (fieldsToDisable: any) => {
    if (Array.isArray(fieldsToDisable)) {
      this.filterConfigs = this.filterConfigs.filter((c: any) => !fieldsToDisable.includes(c.filterIdentifier))
    }
  }

  isFilterDisabled = (fieldValue: any) => {
    if (Array.isArray(this.disableFiltersConfig)) {
      return this.disableFiltersConfig.includes(fieldValue)
    } else {
      return false
    }
  }

  pushPublishedVisibilityObject = () => {
    let publishedVisibility_requirementsPage = {
      isUnique: true,
      isRequired: true,
      Field: 'published_visibility',
      Op: this.constants.FilterType.Radio,
      displayName: 'Visibility',
      displayValue: 'Published',
      Value: 'published',
      dateCreated: dayjs().$d
    }
    if (this.$rootScope.session.getUser().user_role.role.title === 'System Admin' ||
      this.$rootScope.session.getUser().user_role.role.title === 'Requirement Author') {
      this.presetFilters.push(publishedVisibility_requirementsPage)
    }
  }

  disableVisibility = () => {
    if (this.$rootScope.session.getUser().user_role.role.title !== 'System Admin' &&
      this.$rootScope.session.getUser().user_role.role.title !== 'Requirement Author') {
      this.disableFiltersConfig.push('published_visibility')
      this.disableFiltersConfig.push('published_visibility_store')
    }
  }
  resetVisibilityFilter = () => {
    this.presetFilters = this.presetFilters.filter(pf => !(pf.Field == 'published_visibility' && pf.Value == "edit_state_id"))
  }

  loadTerminalReferences = () => {
    if (this.isFilterDisabled('reference_id')) {
      return
    }

    let terminalReferences = this.storeService.getStoreSessionData('terminalReferences')
    if (!terminalReferences) {
      return
    }

    var terminalReferencesList = terminalReferences.map((r: any) => {
      return { displayName: r.value, value: r.reference_object_id }
    })

    var terminalReferencesConfig = this.filterConfigs.find((c: any) => c.position == 4)
    terminalReferencesConfig.list = terminalReferencesList

    let squashedList = this.arrayFunctions.squashOptions(terminalReferencesList, 'displayName', 'value')
    terminalReferencesConfig.list = squashedList
    this.filterConfigs = this.arrayFunctions.pushAndOverwrite(this.filterConfigs, terminalReferencesConfig, (existing: any, update: any) => existing.position == update.position)
    this.filterConfigs = this.filterConfigs.sort(this.sortByPositionAsc)
  }

  loadRequirements = () => {
    if (this.isFilterDisabled('requirement_ids')) {
      return
    }

    let requirements = this.storeService.getStoreSessionData('requirements')
    if (!requirements || !Array.isArray(requirements)) {
      return
    }

    var requirementsList = requirements.map(r => {
      if (r.interval !== undefined) {
        return { displayName: r.display_name, value: r.id, order: r.interval.order, interval: r.interval.name }
      } else {
        return { displayName: r.display_name, value: r.id, order: 0, interval: '' }
      }
    })
    var requirementsConfig = {
      position: 5,
      fieldDisplayName: 'Requirements',
      fieldValue: 'requirement_ids',
      type: this.constants.FilterType.Multi,
      list: []
    }
    requirementsConfig.list = requirementsList.sort((a, b) => a.order - b.order)
    this.filterConfigs = this.arrayFunctions.pushAndOverwrite(this.filterConfigs, requirementsConfig, (existing: any, update: any) => existing.position == update.position)
    this.filterConfigs = this.filterConfigs.sort(this.sortByPositionAsc)
  }

  findSearchMatches = (args: any) => {
    let filterString = args.filterString
    let matchesFoundCallback = args.matchesFoundCallback
    let whitelistFields = args.whitelistFields || []
    let blacklistFields = args.blacklistFields || []

    let myPromise = new Promise((resolve, reject) => {
      let matches: any = []
      let matchInformation = {
        searchString: filterString,
        matches: []
      }
      if (filterString.length < 3) {
        matchesFoundCallback(matchInformation)
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
        return myPromise
      }
      let configsWithLists = this.filterConfigs.filter((c: any) => !blacklistFields.includes(c.fieldValue)
        && (c.type == this.constants.FilterType.Multi || c.type == this.constants.FilterType.NodeTreeBuilder)
        && Array.isArray(c.list) && c.list.length > 0
      )
      configsWithLists.forEach((config: any) => {
        let ignore = [
          'organizations_ids'
          // 'interval_ids',
          // 'tag_ids'
        ]
        if (ignore.includes(config.fieldValue)) {
          return
        }
        let list = config.list

        let localMatches = list.filter((i: any) => i.displayName && i.displayName.toUpperCase().includes(filterString))
        // .map(i => i.value)
        if (localMatches.length > 0) {
          let pseudoPills = localMatches.map((m: any) => {
            return {
              Field: config.fieldValue,
              Value: m.value
              // displayValue: m.displayName
            }
          })
          let matchObject = {
            fieldValue: config.fieldValue,
            matches: pseudoPills
          }
          matches.push(matchObject)
        }
      });

      matchInformation.matches = matches

      matchesFoundCallback(matchInformation)
      // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
      resolve();
    });

    return myPromise
  }

  organizationsApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString,
        // pagination_params: [{ page: 1, pageSize: 50 }]
      }
    }
    const _this = this
    // let params = { param_data: { /* if they want visibility, defualt it here */ } }
    return this.Restangular.all('organizations/filter_names').getList(params).then(function (success: any) {
      let response = { items: success.plain() }

      let searchResults: any = {}
      if (response && Array.isArray(response.items) && response.items.length > 0) {
        searchResults = response

        var recursive_map_fn = (i: any) => {
          return {
            id: i.id,
            value: i.id,
            displayName: i.name,
            children: i.children.map(recursive_map_fn),
            has_children: i.has_children
          }
        }
        searchResults.items = searchResults.items.map(recursive_map_fn)
      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  intervalsApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString
      }
    }
    const _this = this
    return this.Restangular.all('intervals').getList(params).then(function (success: any) {
      let response = success.plain()
      let searchResults: any = {}

      if (response && Array.isArray(response) && response.length > 0) {
        searchResults = response
        var recursive_map_fn = (i: any) => {
          return {
            id: i.id,
            value: i.id,
            displayName: i.name,
            description: i.description
          }
        }
        searchResults.items = searchResults.map(recursive_map_fn)

      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  surveyingEntitiesApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString
      }
    }
    const _this = this
    return this.Restangular.all('tags/filter_survey_entities').getList(params).then(function (success: any) {
      let response = success.plain()
      let searchResults: any = {}

      if (response && Array.isArray(response) && response.length > 0) {
        searchResults = response
        var recursive_map_fn = (i: any) => {
          return {
            id: i.id,
            value: i.id,
            displayName: i.title
          }
        }
        searchResults.items = searchResults.map(recursive_map_fn)

      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  tagsApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString
      }
    }
    const _this = this
    return this.Restangular.all('tags/filter_business_names').getList(params).then(function (success: any) {
      let response = success.plain()
      let searchResults: any = {}

      if (response && Array.isArray(response) && response.length > 0) {
        searchResults = response
        var recursive_map_fn = (i: any) => {
          return {
            id: i.id,
            value: i.id,
            displayName: i.name,
            children: i.children.map(recursive_map_fn),
            has_children: i.has_children
          }
        }
        searchResults.items = searchResults.map(recursive_map_fn)

      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  requirementsApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        append_archived: true,
        published_visibility: ['all'],
        text_search: args.apiSearchInputString,
        pagination_params: [{ page: 1, pageSize: 50 }]
      }
    }
    const _this = this
    return this.Restangular.all('requirements/filter').getList(params).then(function (success: any) {
      let response = success.plain()
      // for the reports page we only want the form type requirements
      response = _this.onlyShowFormRequirements(response)
      let searchResults = _this.buildRequirementObject(response)

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  buildRequirementObject = (response: any) => {
    let searchResults: any = {}
    if (response && Array.isArray(response) && response.length > 0) {
      searchResults = response
      var recursive_map_fn = (i: any) => {
        if (i.interval !== undefined) {
          return { displayName: i.display_name, value: i.id, order: i.interval.order, interval: i.interval.name, description: i.description }
        } else {
          return { displayName: i.display_name, value: i.id, order: 0, interval: '', description: i.description }
        }
      }
      searchResults.items = searchResults.map(recursive_map_fn)

    } else {
      searchResults = {}
    }
    return searchResults
  }

  onlyShowFormRequirements = (response: any) => {
    if(this.showFormRequirements) {
      response = response.filter(req => {
        return req.compliance_method == "Form" || req.compliance_method == "Form/Template"
      })
    }
    return response
  }

  assigneesApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString,
        pagination_params: [{ page: 1, pageSize: 50 }]
      }
    }

    return this.Restangular.one('operators/binder_assignees').get(params).then((success: any) => {
      let response = success.plain()

      let searchResults: any = {}
      if (response && Array.isArray(response.items) && response.items.length > 0) {
        searchResults = response
        searchResults.items = searchResults.items.map((i: any) => {
          return {
            id: i.id,
            value: i.id,
            displayName: i.profile.display_name
          }
        })
        searchResults.items.unshift({ displayName: 'Unassigned', value: "" })
      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  rolesApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString
      }
    }
    const _this = this
    return this.Restangular.all('roles').getList(params).then(function (success: any) {
      let response = success.plain()
      let searchResults: any = {}

      if (response && Array.isArray(response) && response.length > 0) {
        searchResults = response
        var recursive_map_fn = (i: any) => {
          return {
            id: i.id,
            value: i.title,
            displayName: i.title
          }
        }

        searchResults.items = searchResults.map(recursive_map_fn)

      } else {
        searchResults = {}
      }

      loadedCallback(searchResults)
    }, function (error: any) {
      return this.utils.log(error)
    });
  }

  loadNextLevelReferences = (args: any) => {
    let currentNodePath = args.currentNodePath
    let loadedCallback = args.loadedCallback
    const currentPathLength = currentNodePath.length
    const params = {
      node_map: JSON.stringify(currentNodePath)
    }

    return this.Restangular.all('reference_objects/tree_navigation').getList(params).then((success: any) => {
      this.tree_data = success

      let currentNodeChildren = {}
      if (Array.isArray(success) && success.length > currentPathLength) {
        currentNodeChildren = success[currentPathLength].items
        if (Array.isArray(currentNodeChildren) && currentNodeChildren.length > 0) {
          currentNodeChildren = currentNodeChildren.filter(n => n.identifier).sort((a, b) => {
            return a.identifier.localeCompare(b.identifier, undefined, { 'numeric': true })
          })
        }
      } else {
        currentNodeChildren = []
      }

      loadedCallback(currentNodeChildren)
    }, function (error: any) {
      // this.loadingStateService.process_error(error)
      return this.utils.log(error)
    });
  }

  loadCachedFilters = () => {
    if (this.disableCaching) {
      return
    }
    var filters = this.filterService.getCached()
    if (filters != null && filters.length > 0) {
      this.presetFilters = this.arrayFunctions.pushAndOverwriteList(this.presetFilters, filters, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
    }
  }
  resetFromCache = () => {
    if (this.disableCaching) {
      return
    }
    let filters = this.filterService.getCached()
    filters = filters.filter((f: any) => !f.onlyValidOn || f.onlyValidOn === this.pageName)
    if (Array.isArray(filters)) {
      this.presetFilters = filters
      this.$scope.$broadcast('filtersLoaded-dashboard', this.presetFilters)
    }
  }

  sortByPositionAsc = (first: any, second: any) => {
    return first.position - second.position
  }

  }
};

// angular.module('complyosClient').directive('dashboardFilter', [

//   (
//   ) => ({
//     link($scope: any, $ele: any, $attrs: any) {
      
//     }
//   })
// ])

angular
  .module('complyosClient')
  .component(DashboardFilterComponent.selector, DashboardFilterComponent);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('dashboardFilter', [
//   '$rootScope',
//   'arrayFunctions',
//   'constants',
//   'filterService',
//   'fortyCore',
//   'loadingStateService',
//   'Restangular',
//   'storeService',
//   'utils',
//   (
//     $rootScope,
//     arrayFunctions,
//     constants,
//     filterService,
//     fortyCore,
//     loadingStateService,
//     Restangular,
//     storeService,
//     utils
//   ) => ({
//     // templateUrl: 'views/directives/dashboard_filter.html',
//     template: require('../../views/directives/dashboard_filter.pug'),
//     restrict: 'E',

//     scope: {
//       disableFiltersConfig: '=',
//       allowLocking: '=',
//       injectedDefaultFilters: '=',
//       pageName: '=',
//       hideClearFiltersButton: '=',
//       defaultFilterConfig: '=',
//       disableCaching: '=',
//       broadcastIdentifier: '=?'
//     },

//     link ($scope: any, $ele: any, $attrs: any) {
//       $scope.broadcastIdentifier = $scope.broadcastIdentifier || 'filterChange-dashboard'
//       $scope = this.registerFunctions($scope)
//       // $scope.sortByOptions = [{ displayName: 'Interval', value: 'interval' }, { displayName: 'Risk Order', value: 'risk' }]
//       let ArchivedRadioOptions = [
//         { name: 'All', displayName: 'All', tooltip: 'show all configured requirements', value: 'all' },
//         { name: 'Active', displayName: 'Active', tooltip: 'show active configured requirements', value: 'active' },
//         { name: 'Archived', displayName: 'Archived', tooltip: 'show archived configured requirements', value: 'archived' },
//         { name: 'Start Date Needed', displayName: 'Start Date Needed', tooltip: 'show configured requirements that need a start date', value: 'needs_start_date', overrideFieldValue: 'schedule_generation_state' }
//       ]
//       let AcknowledgedRadioOptions = [
//         { name: 'All', displayName: 'All', tooltip: 'Show all comments', value: 'all' },
//         { name: 'Acknowledged', displayName: 'Acknowledged', tooltip: 'show only acknowledged comments', value: 'acknowledged' },
//         // { name: 'requires_resubmission', displayName: 'requires_resubmission', tooltip: 'show only acknowledged comments', value: 'requires_resubmission' },
//         // { name: 'NotAcknowledged', displayName: 'NotAcknowledged', tooltip: 'show only acknowledged comments', value: 'not_acknowledged' },
//         { name: 'Needs Action', displayName: 'Needs Action', tooltip: 'show comments that do not have a response OR are attached to entries that require resubmission', value: 'action_needed' }
//       ]
//       let ScheduleVisibilityRadioOptions = [
//         { name: 'All', displayName: 'All', tooltip: 'Show all entries', value: 'all' },
//         { name: 'Partially Complete', displayName: 'Partially Complete', tooltip: 'Show only entries that are partially complete', value: 'partially_complete' },
//         { name: 'Requires Approval', displayName: 'Requires Approval', tooltip: 'Show only entries that are ready for review by an admin', value: 'requires_review' },
//         { name: 'Requires Resubmission', displayName: 'Requires Resubmission', tooltip: 'Show only entries that have been marked as Requires Resubmission through validation', value: 'requires_resubmission' }
//       ]
//       let PublishedVisibilityRadioOptions = [
//         { name: 'All', displayName: 'All', tooltip: 'Show all requirements', value: 'all' },
//         { name: 'Published', displayName: 'Published', tooltip: 'Show only requirements that are published', value: 'published' },
//         { name: 'Unpublished', displayName: 'Unpublished', tooltip: 'Show only requirements that are unpublished and not archived', value: 'unpublished' },
//         { name: 'Archived', displayName: 'Archived', tooltip: 'Show only requirements that are archived', value: 'archived' }
//       ]
//       // let AssignmentTypeRadioOptions = [
//       //   { name: 'All', displayName: 'All', tooltip: 'Show all Assignments', value: 'all' },
//       //   { name: 'Configured Requirements', displayName: 'Configured Requirements', tooltip: 'Show only Configured Requirement Assignments', value: 'Configured Requirement' },
//       //   { name: 'Organizations', displayName: 'Organizations', tooltip: 'Show only Organization Assignments', value: 'Organization' }
//       // ]
//       $scope.filterConfigs = [
//         { position: 0, fieldDisplayName: 'Search', fieldValue: 'search_hits', type: constants.FilterType.CustomSearch, findSearchMatchesFunction: $scope.findSearchMatches, isUnique: true },
//         // { position: 0.05, fieldDisplayName: 'Assignment Type', fieldValue: 'record_type', type: constants.FilterType.Radio, isUnique: true, list: AssignmentTypeRadioOptions },
//         { position: 0.1, fieldDisplayName: 'Assignees', fieldValue: 'assignee_user_id', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.assigneesApiSearchFunction, descendantsName: 'assignees' },
//         { position: 1, fieldDisplayName: 'Date', fieldValue: 'date', type: constants.FilterType.Date, isUnique: true },
//         { position: 2, fieldDisplayName: 'Intervals', fieldValue: 'interval_ids', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.intervalsApiSearchFunction },
//         { position: 2.9, fieldDisplayName: 'Organizations', fieldValue: 'organizations_ids', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.organizationsApiSearchFunction, enableDescendantChecking: true, descendantsName: 'organizations', descendantsPrefix: 'sub-' },
//         // { position: 3, fieldDisplayName: 'Organizations (loading)', fieldValue: 'organizations_ids', enableDescendantChecking: true, descendantsName: 'sub-organizations', type: constants.FilterType.Multi, list: [] },
//         { position: 4, fieldDisplayName: 'References', fieldValue: 'reference_id', type: constants.FilterType.NodeTreeBuilder, loadNextLevelFunction: $scope.loadNextLevelReferences, segmentToolTipFunction: null, descendantsName: 'references' },
//         { position: 5, fieldDisplayName: 'Requirements', fieldValue: 'requirement_ids', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.requirementsApiSearchFunction },
//         // { position: 5, fieldDisplayName: 'Sort By', fieldValue: 'sorted', type: constants.FilterType.Select, list: $scope.sortByOptions, isUnique: true, isRequired: true },
//         { position: 6, fieldDisplayName: 'Roles', fieldValue: 'role_ids', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.rolesApiSearchFunction },
//         { position: 7, fieldDisplayName: 'Surveying Entities', fieldValue: 'surveying_ids', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.surveyingEntitiesApiSearchFunction },
//         { position: 8, fieldDisplayName: 'Tags', fieldValue: 'tag_ids', enableDescendantChecking: true, descendantsName: 'tags', type: constants.FilterType.MultiAPISearch, apiSearchFunction: $scope.tagsApiSearchFunction, descendantsPrefix: 'sub-' },
//         { position: 9, fieldDisplayName: 'Visibility (All, Active, Archived, Needs Start Date)', fieldValue: 'visible', type: constants.FilterType.Radio, isUnique: true, list: ArchivedRadioOptions },
//         { position: 10, fieldDisplayName: 'Visibility (All, Acknowledged, Needs Action)', fieldValue: 'acknowledged_scope', type: constants.FilterType.Radio, isUnique: true, list: AcknowledgedRadioOptions },
//         { position: 11, fieldDisplayName: 'Visibility (All, Partially Complete, Requires Approval, Requires Resubmission)', fieldValue: 'schedule_visibility', type: constants.FilterType.Radio, isUnique: true, list: ScheduleVisibilityRadioOptions },
//         { position: 12, fieldDisplayName: 'Visibility (All, Published, Unpublished, Archived)', fieldValue: 'published_visibility', type: constants.FilterType.Radio, isUnique: true, list: PublishedVisibilityRadioOptions }
//       ]
//       $scope.presetFilters = [{
//         isUnique: true,
//         isRequired: true,
//         Field: 'date',
//         Op: constants.FilterType.Date,
//         displayName: 'Date',
//         displayValue: '6 months',
//         Value: { date_range_begin: dayjs().subtract(6, 'months').$d, date_range_end: dayjs().utc().endOf('day').$d },
//         dateCreated: dayjs().$d
//       }, {
//         isUnique: true,
//         isRequired: true,
//         isHidden: true,
//         Field: 'pagination_params',
//         Op: constants.FilterType.Date,
//         displayName: 'Page',
//         displayValue: 'page: 1 (50/pg)',
//         Value: { 'page': 1, 'pageSize': 50 },
//         dateCreated: dayjs().$d
//       },
//       {
//         isUnique: true,
//         isRequired: true,
//         Field: 'visible',
//         Op: constants.FilterType.Radio,
//         displayName: 'Visibility',
//         displayValue: 'Active',
//         Value: 'active',
//         dateCreated: dayjs().$d
//       },
//       {
//         isUnique: true,
//         isRequired: true,
//         Field: 'schedule_visibility',
//         Op: constants.FilterType.Radio,
//         displayName: 'Visibility',
//         displayValue: 'All',
//         Value: 'all',
//         dateCreated: dayjs().$d
//       },
//       {
//         isUnique: true,
//         isRequired: true,
//         Field: 'acknowledged_scope',
//         Op: constants.FilterType.Radio,
//         displayName: 'Visibility',
//         displayValue: 'Action Needed',
//         Value: 'action_needed',
//         dateCreated: dayjs().$d
//       }]

//       $scope.pushPublishedVisibilityObject()

//       if (Array.isArray($scope.injectedDefaultFilters) && $scope.injectedDefaultFilters.length > 0) {
//         $scope.presetFilters = $scope.presetFilters.concat($scope.injectedDefaultFilters)
//       }
//       $scope.untouchedPresets = JSON.parse(JSON.stringify($scope.presetFilters))
      
//       $scope.filterLoadingState = angular.copy(loadingStateService)

//       $scope.filterLoadingState.init()
//       $scope.filterLoadingState.set_state('loading')

//       $scope.disableVisibility()
//       $scope.removeFilterConfig($scope.disableFiltersConfig)
//       $scope.$on('referencesLoaded', function (e: any) {
//         $scope.loadRequirements()
//       })
//       $scope.loadTerminalReferences()
//       $scope.loadCachedFilters()
//       $scope.$broadcast('filtersLoaded-dashboard', $scope.presetFilters)

//       $scope.$on('reloadFiltersFromCache', function (e: any) {
//         $scope.resetFromCache()
//       })

//       $scope.filterLoadingState.set_state('content')
//       return $scope.fortyCore = fortyCore
//     },

//     registerFunctions ($scope: any) {
//       $scope.onFiltersChange = function (appliedFilters: any) {
//         // if (!$scope.organizationCheck(appliedFilters)) {
//         //   $scope.resetFromCache()
//         //   return
//         // }

//         let organizationPills = appliedFilters.filter((f: any) => f.Field === 'organizations_ids')
//         let activeOrg = $rootScope.session.getOrganization()
//         if (Array.isArray(organizationPills) && organizationPills.length == 1 && !organizationPills[0].includeDescendants) {
//           let orgId = organizationPills[0].Value
//           if (!activeOrg || activeOrg.id != orgId) {
//             Restangular.one(`organizations/${orgId}`).get().then((response: any) => {
//               $rootScope.session.setOrganization(response, true)
//             })
//           }
//         } else if (activeOrg != null) {
//           $rootScope.session.setOrganization(null, true)
//         }

//         if ($scope.disableCaching !== true) {
//           filterService.setCache(appliedFilters)
//         }
//         let applicableFilters = $scope.removeDisabledFilters(appliedFilters)
//         console.log('broadcasted filters: ', applicableFilters)
//         $rootScope.$broadcast($scope.broadcastIdentifier, applicableFilters)
//       }

//       $scope.removeDisabledFilters = (incomingFilters: any) => {
//         let filters = JSON.parse(JSON.stringify(incomingFilters))
//         if (Array.isArray($scope.disableFiltersConfig) && $scope.disableFiltersConfig.length > 0) {
//           filters = filters.filter((f: any) => !$scope.disableFiltersConfig.includes(f.Field))
//         }
//         return filters
//       }

//       $scope.removeFilterConfig = (fieldsToDisable: any) => {
//         if (Array.isArray(fieldsToDisable)) {
//           $scope.filterConfigs = $scope.filterConfigs.filter((c: any) => !fieldsToDisable.includes(c.fieldValue))
//         }
//       }

//       $scope.isFilterDisabled = (fieldValue: any) => {
//         if (Array.isArray($scope.disableFiltersConfig)) {
//           return $scope.disableFiltersConfig.includes(fieldValue)
//         } else {
//           return false
//         }
//       }

//       $scope.pushPublishedVisibilityObject = () => {
//         let publishedVisibility = {
//           isUnique: true,
//           isRequired: true,
//           Field: 'published_visibility',
//           Op: constants.FilterType.Radio,
//           displayName: 'Visibility',
//           displayValue: 'Published',
//           Value: 'published',  
//           dateCreated: dayjs().$d
//         }
//         if ($rootScope.session.getUser().user_role.role.title === 'System Admin' ||
//             $rootScope.session.getUser().user_role.role.title === 'Requirement Author') {
//           $scope.presetFilters.push(publishedVisibility)
//         }
//       }

//       $scope.disableVisibility = () => {
//         if ($rootScope.session.getUser().user_role.role.title !== 'System Admin' &&
//             $rootScope.session.getUser().user_role.role.title !== 'Requirement Author') {
//           $scope.disableFiltersConfig.push('published_visibility')
//         }
//       }

//       $scope.loadTerminalReferences = () => {
//         if ($scope.isFilterDisabled('reference_id')) {
//           return
//         }

//         let terminalReferences = storeService.getStoreSessionData('terminalReferences')
//         if (!terminalReferences) {
//           return
//         }

//         var terminalReferencesList = terminalReferences.map((r: any) => {
//           return { displayName: r.value, value: r.reference_object_id }
//         })

//         var terminalReferencesConfig = $scope.filterConfigs.find((c: any) => c.position == 4)
//         terminalReferencesConfig.list = terminalReferencesList

//         let squashedList = arrayFunctions.squashOptions(terminalReferencesList, 'displayName', 'value')
//         terminalReferencesConfig.list = squashedList
//         $scope.filterConfigs = arrayFunctions.pushAndOverwrite($scope.filterConfigs, terminalReferencesConfig, (existing: any, update: any) => existing.position == update.position)
//         $scope.filterConfigs = $scope.filterConfigs.sort($scope.sortByPositionAsc)
//       }

//       $scope.loadRequirements = () => {
//         if ($scope.isFilterDisabled('requirement_ids')) {
//           return
//         }

//         let requirements = storeService.getStoreSessionData('requirements')
//         if (!requirements || !Array.isArray(requirements)) {
//           return
//         }

//         var requirementsList = requirements.map(r => {
//           if (r.interval !== undefined) {
//             return { displayName: r.display_name, value: r.id, order: r.interval.order, interval: r.interval.name }
//           } else {
//             return { displayName: r.display_name, value: r.id, order: 0, interval: '' }
//           }
//         })
//         var requirementsConfig = {
//           position: 5,
//           fieldDisplayName: 'Requirements',
//           fieldValue: 'requirement_ids',
//           type: constants.FilterType.Multi,
//           list: []
//         }
//         requirementsConfig.list = requirementsList.sort((a, b) => a.order - b.order)
//         $scope.filterConfigs = arrayFunctions.pushAndOverwrite($scope.filterConfigs, requirementsConfig, (existing: any, update: any) => existing.position == update.position)
//         $scope.filterConfigs = $scope.filterConfigs.sort($scope.sortByPositionAsc)
//       }

//       $scope.findSearchMatches = (args: any) => {
//         let filterString = args.filterString
//         let matchesFoundCallback = args.matchesFoundCallback
//         let whitelistFields = args.whitelistFields || []
//         let blacklistFields = args.blacklistFields || []

//         let myPromise = new Promise((resolve, reject) => {
//           let matches: any = []
//           let matchInformation = {
//             searchString: filterString,
//             matches: []
//           }
//           if (filterString.length < 3) {
//             matchesFoundCallback(matchInformation)
//             // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
//             resolve();
//             return myPromise
//           }
//           let configsWithLists = $scope.filterConfigs.filter((c: any) => !blacklistFields.includes(c.fieldValue)
//           && (c.type == constants.FilterType.Multi || c.type == constants.FilterType.NodeTreeBuilder)
//           && Array.isArray(c.list) && c.list.length > 0
//           )
//           configsWithLists.forEach((config: any) => {
//             let ignore = [
//               'organizations_ids'
//               // 'interval_ids',
//               // 'tag_ids'
//             ]
//             if (ignore.includes(config.fieldValue)) {
//               return
//             }
//             let list = config.list

//             let localMatches = list.filter((i: any) => i.displayName && i.displayName.toUpperCase().includes(filterString))
//             // .map(i => i.value)
//             if (localMatches.length > 0) {
//               let pseudoPills = localMatches.map((m: any) => {
//                 return {
//                   Field: config.fieldValue,
//                   Value: m.value
//                   // displayValue: m.displayName
//                 }
//               })
//               let matchObject = {
//                 fieldValue: config.fieldValue,
//                 matches: pseudoPills
//               }
//               matches.push(matchObject)
//             }
//           });

//           matchInformation.matches = matches

//           matchesFoundCallback(matchInformation)
//           // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
//           resolve();
//         });

//         return myPromise
//       }

//       $scope.organizationsApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString,
//             // pagination_params: [{ page: 1, pageSize: 50 }]
//           }
//         }
//         const _this = this
//         // let params = { param_data: { /* if they want visibility, defualt it here */ } }
//         return Restangular.all('organizations/filter_names').getList(params).then(function (success: any) {
//           let response = {items: success.plain()}

//           let searchResults :any= {}
//           if (response && Array.isArray(response.items) && response.items.length > 0) {
//             searchResults = response
            
//             var recursive_map_fn = (i: any) => {
//               return {
//                 id: i.id,
//                 value: i.id,
//                 displayName: i.name,
//                 children: i.children.map(recursive_map_fn),
//                 has_children: i.has_children
//               }
//             }
//             searchResults.items = searchResults.items.map(recursive_map_fn)
//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.intervalsApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString
//           }
//         }
//         const _this = this
//         return Restangular.all('intervals').getList(params).then(function (success: any) {
//           let response = success.plain()
//           let searchResults:any = {}
          
//           if (response && Array.isArray(response) && response.length > 0) {
//             searchResults = response
//             var recursive_map_fn = (i: any) => {
//               return {
//                 id: i.id,
//                 value: i.id,
//                 displayName: i.name,
//                 description: i.description
//               }
//             }
//             searchResults.items = searchResults.map(recursive_map_fn)

//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.surveyingEntitiesApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString
//           }
//         }
//         const _this = this
//         return Restangular.all('tags/filter_survey_entities').getList(params).then(function (success: any) {
//           let response = success.plain()
//           let searchResults:any = {}
          
//           if (response && Array.isArray(response) && response.length > 0) {
//             searchResults = response
//             var recursive_map_fn = (i: any) => {
//               return {
//                 id: i.id,
//                 value: i.id,
//                 displayName: i.title
//               }
//             }
//             searchResults.items = searchResults.map(recursive_map_fn)

//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.tagsApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString
//           }
//         }
//         const _this = this
//         return Restangular.all('tags/filter_business_names').getList(params).then(function (success: any) {
//           let response = success.plain()
//           let searchResults:any = {}
          
//           if (response && Array.isArray(response) && response.length > 0) {
//             searchResults = response
//             var recursive_map_fn = (i: any) => {
//               return { 
//                 id: i.id,
//                 value: i.id,
//                 displayName: i.name,
//                 children: i.children.map(recursive_map_fn),
//                 has_children: i.has_children
//               }
//             }
//             searchResults.items = searchResults.map(recursive_map_fn)

//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.requirementsApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             append_archived: true,
//             published_visibility: ['all'],
//             text_search: args.apiSearchInputString,
//             pagination_params: [{ page: 1, pageSize: 50 }]
//           }
//         }
//         const _this = this
//         return Restangular.all('requirements/filter').getList(params).then(function (success: any) {
//           let response = success.plain()
//           let searchResults:any = {}
          
//           if (response && Array.isArray(response) && response.length > 0) {
//             searchResults = response
//             var recursive_map_fn = (i: any) => {
//               if (i.interval !== undefined) {
//                 return { displayName: i.display_name, value: i.id, order: i.interval.order, interval: i.interval.name, description: i.description }
//               } else {
//                 return { displayName: i.display_name, value: i.id, order: 0, interval: '', description: i.description }
//               }
//             }
//             searchResults.items = searchResults.map(recursive_map_fn)

//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       },

//       $scope.assigneesApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString,
//             pagination_params: [{ page: 1, pageSize: 50 }]
//           }
//         }

//         return Restangular.one('operators/binder_assignees').get(params).then((success: any) => {
//           let response = success.plain()

//           let searchResults:any = {}
//           if (response && Array.isArray(response.items) && response.items.length > 0) {
//             searchResults = response
//             searchResults.items = searchResults.items.map((i: any) => {
//               return {
//                 id: i.id,
//                 value: i.id,
//                 displayName: i.profile.display_name
//               }
//             })
//             searchResults.items.unshift({ displayName: 'Unassigned', value: "" })
//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.rolesApiSearchFunction = (args: any) => {
//         let loadedCallback = args.loadedCallback
//         const params = {
//           param_data: {
//             text_search: args.apiSearchInputString
//           }
//         }
//         const _this = this
//         return Restangular.all('roles').getList(params).then(function (success: any) {
//           let response = success.plain()
//           let searchResults:any = {}
          
//           if (response && Array.isArray(response) && response.length > 0) {
//             searchResults = response
//             var recursive_map_fn = (i: any) => {
//               return {
//                 id: i.id,
//                 value: i.title,
//                 displayName: i.title
//               }
//             }

//             searchResults.items = searchResults.map(recursive_map_fn)

//           } else {
//             searchResults = {}
//           }

//           loadedCallback(searchResults)
//         }, function (error: any) {
//           return utils.log(error)
//         });
//       }

//       $scope.loadNextLevelReferences = (args: any) => {
//         let currentNodePath = args.currentNodePath
//         let loadedCallback = args.loadedCallback
//         const currentPathLength = currentNodePath.length
//         const params = {
//           node_map: JSON.stringify(currentNodePath)
//         }

//         return Restangular.all('reference_objects/tree_navigation').getList(params).then((success: any) => {
//           $scope.tree_data = success

//           let currentNodeChildren = {}
//           if (Array.isArray(success) && success.length > currentPathLength) {
//             currentNodeChildren = success[currentPathLength].items
//             if (Array.isArray(currentNodeChildren) && currentNodeChildren.length > 0) {
//               currentNodeChildren = currentNodeChildren.filter(n => n.identifier).sort((a, b) => {
//                 return a.identifier.localeCompare(b.identifier, undefined, { 'numeric': true })
//               })
//             }
//           } else {
//             currentNodeChildren = []
//           }

//           loadedCallback(currentNodeChildren)
//         }, function (error: any) {
//           // $scope.loadingStateService.process_error(error)
//           return utils.log(error)
//         });
//       }

//       $scope.loadCachedFilters = () => {
//         if ($scope.disableCaching) {
//           return
//         }
//         var filters = filterService.getCached()
//         if (filters != null && filters.length > 0) {
//           $scope.presetFilters = arrayFunctions.pushAndOverwriteList($scope.presetFilters, filters, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
//         }
//       }
//       $scope.resetFromCache = () => {
//         if ($scope.disableCaching) {
//           return
//         }
//         let filters = filterService.getCached()
//         filters = filters.filter((f: any) => !f.onlyValidOn || f.onlyValidOn === $scope.pageName)
//         if (Array.isArray(filters)) {
//           $scope.presetFilters = filters
//           $scope.$broadcast('filtersLoaded-dashboard', $scope.presetFilters)
//         }
//       }

//       $scope.sortByPositionAsc = (first: any, second: any) => {
//         return first.position - second.position
//       }

//       return $scope;
//     }
//   })
// ])
