
import { Component, EventEmitter, forwardRef, Inject, Injectable, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { complyosClient } from '../../../scripts/app.legacy'
// import { PasswordService } from '../../../../scripts/ng/services/password.service';
// import { ContactService } from 'src/scripts/ng/services/contact.service';

@Injectable()
@Component({
  selector: 'sampleComponent',
  template: `
    <div style="background-color:darkgray;padding:20px;color:red;width:400px;">
        This is how you do angular(4+) components, to be compatable in Angular.js app (which is then upgraded inside an Angular(4+) hybrid)

        <button (click)="fireEvent($event)">trigger event</button>
    </div>`,
})
export class SampleComponent {
  @Input() exampleInput!: number;
  @Output() exampleEmitter = new EventEmitter<number>();

    // constructor(@Inject(forwardRef(() => ContactService)) private contactService: ContactService) { }
  // constructor(@Inject(forwardRef(() => PasswordService)) private passwordService: PasswordService) {}

  fireEvent() {
    this.exampleEmitter.emit(88)

    // console.log(this.passwordService.checker("fawefaaae"))
  }
}

complyosClient
    .directive('sampleComponent', downgradeComponent({ component: SampleComponent }) as angular.IDirectiveFactory)