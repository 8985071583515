/* eslint-disable
    camelcase,
    no-undef,
    handle-callback-err
*/

angular.module('complyosClient').factory('storeService', ['$rootScope', '$window', 'Restangular', 'utils', function (
  $rootScope: any,
  $window: any,
  Restangular: any,
  utils: any
) {
  return {

    initalize () {
      // this store service will be used to initiate a local store and utilize session storage
      // global collection builder was the main force for starting this, but could be used in other areas as needed
      this.setStoreSessionData()
    },

    /* INITIALIZE */
    // make a centralized "store"
    storeSessionData () {
      return {
        'store': {
          'surveyingEntities': {},
          'bussinessTypes': {},
          'requirements': {},
          'intervals': {},
          'organizations': {}
        }
      }
    },
    //* STORE METHODS *//

    // set a blank session data object, and retrieve previous if possible.
    setStoreSessionData () {
      // check to see if previous selected tags data is available
      // we will always want to get new tags from the api in case they have changed
      // save previous selected filters
      this.data = this.storeSessionData()
      if (!$window.sessionStorage.getItem('store')) {
        $window.sessionStorage.setItem('store', JSON.stringify(this.data.store))
      }
      this.getTerminalReferences()
    },

    // example format  of how to form your save object, use key/value with key corresponding to a store key
    // mockParams = {
    //   key: 'selectedFilters',
    //   value: [5,6,7,8]
    // }
    saveStoreSessionData (params: any) {
      let storeObject = JSON.parse($window.sessionStorage.getItem('store'))
      if (storeObject) {
        storeObject[params.key] = params.value
        $window.sessionStorage.setItem('store', JSON.stringify(storeObject))
      }
    },

    //  pass in key value to get data
    // example -- let params = 'surveyingEntities'
    //         -- getStoreSessionData(params)
    getStoreSessionData (params: any) {
      let storeObject = JSON.parse($window.sessionStorage.getItem('store'))
      if (storeObject) {
        return storeObject[params]
      }
    },

    //* GETTING TAG DATA *//

    getTerminalReferences () {
      const _this = this
      Restangular.all('reference_objects/all_built').getList().then(function (success: any) {
      // Restangular.all('reference_objects/all').getList().then(function (success) {
        let returnData = success.plain()
        _this.saveStoreSessionData({ key: 'terminalReferences', value: returnData })
        $rootScope.$broadcast('terminalReferencesLoaded')
      }, (error: any) => {

      })
    },

    getBusinessTags () {
      const _this = this
      // get the tags and entities and save them to session storage
      Restangular.all('tags/filter_business').getList().then(function (success: any) {
        let returnData = success.plain()

        _this.saveStoreSessionData({ key: 'bussinessTypes', value: returnData })

        $rootScope.$broadcast('tagsLoaded')
      }, (error: any) => error)
    },

    stringifyTagParams (params: any) {
      var doNotStringify = ['date', 'pagination_params']

      // for (const [key, value] of Object.entries(params)) {
      //   if (!doNotStringify.includes(key)) { params[key] = JSON.stringify(value) }
      // }
      return params
    },

    pillsToObject (inputPills: any, customParams: any) {
      var _this = this

      let pills = JSON.parse(JSON.stringify(inputPills))
      let doNotFlatten = ['organization_ids', 'organizations_ids', 'tag_ids', 'surveying_ids', 'reference_id', 'search_hits']
      let pillsObject = {}
      if (customParams) { customParams.map((customParam: any) => { pillsObject[customParam] = [] }) }
      if (pills !== undefined) {
        pills.map((pill: any) => {
          if (!pillsObject[pill.Field] && !customParams) { pillsObject[pill.Field] = [] }
          if (pillsObject[pill.Field] !== undefined && pillsObject[pill.Field].indexOf(pill.Value) === -1) {
            if (doNotFlatten.includes(pill.Field) || pill.simple_column_filter === true) {
              pillsObject[pill.Field].push(pill)
            } else if (Array.isArray(pill.Value)) {
              pill.Value.forEach((entry: any) => pillsObject[pill.Field].push(entry))
            } else {
              pillsObject[pill.Field].push(pill.Value)
            }
          }
        })

        // let searchHitsPill = pills.find(p => p.Field === 'search_hits')
        // if (searchHitsPill) {
        this.searchHitsPillsToObject(pills, customParams)
        // }
      }
      return pillsObject
    },

    searchHitsPillsToObject (pills: any, customParams: any) {
      let searchHitsPill = pills.find((p: any) => p.Field === 'search_hits')
      let pillsObject: any = []
      if (searchHitsPill) {
        let fieldArrays = searchHitsPill.Value
        fieldArrays.forEach((fa: any) => {
          // let hit = {}
          // hit[fa.fieldValue] = this.pillsToObject(fa.matches, customParams)
          pillsObject.push(this.pillsToObject(fa.matches, customParams))
        })
        searchHitsPill.Value = pillsObject
      }
    },

    // pass in which pills you want to remove in array form.  ex : pillsToRemove = ['sorted', 'interval']
    pillsToRemove (pills: any, pillsToRemove: any) {
      let deepPills = JSON.parse(JSON.stringify(pills))
      let allIndexes: any = []
      pillsToRemove.forEach((removal: any) => {
        let singleIndex = deepPills.findIndex((pill: any) => pill.Field === removal)
        if (singleIndex !== -1) { allIndexes.push(singleIndex) }
      })
      allIndexes.forEach(index => {
        deepPills.splice(index, 1)
      })
      return deepPills
    }
  };
}])
