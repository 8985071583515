import { Component, Inject, OnInit } from '@angular/core';
import { FlashService } from '../../../services/flash_service.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import * as rrule from '../../../../scripts/utilities/rrule/rrule';
import { constants } from '../../../../scripts/services/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { DateService } from '../../../services/date_service.service';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { fortyCore } from '../../../../scripts/services/fortyau_services';
import { Location } from '@angular/common'
var dayjs = require("dayjs")
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

type RuleInstance = {
  options: { dtstart: string };
  between: (startDate: any, endDate: any) => any;
};

@Component({
  selector: 'ngBinderModal',
  templateUrl: './binder-modal.component.html'
})
export class BinderModalComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  private processingStateService: any;
  private session: any;
  private dateService: any;

  // Properties
  private enums = constants.schedule_generation_state
  private RRule = rrule.RRule
  private binder: any;
  private fault_counter: any = undefined;
  private pending_requests: any = undefined;
  private original_start_date: any;
  private interval: any;
  private active_schedule_status: any;
  private active_help_text: any;
  private descriptions: string;
  private pm_count: number;
  private uiSetting: string;
  private users: Array<any> = [];
  private goodDates: Array<any> = [];
  private mappedUsers: Array<any> = [];
  private usersOrganizations: Array<any> = [];
  private schedule_states: Array<any> = [];
  private filtered_schedule_states: Array<any> = [];
  private organization_select_options: Array<any> = [];
  private is_archived_clicked: boolean = false
  private encourageNillAnnotation: boolean;
  private selected_assignees = {
    user_compliance: [],
    user_responsible: [],
    user_assigned: []
  }
  private mutatedRuleInstance = {
    options: { dtstart: ''},
    between: null as unknown as (startDate: any, endDate: any) => any
  }
  private selected = {
    organizations: []
  }
  private date_helpers = {
    start_date: {
      message: '',
      visible: false
    }
  }
  private organizations: any;
  public binderForm: any = {}
  private isLoading: boolean = false;
  private failedSubmission: boolean = false;
  private formReady: boolean = false;
  private isCalendarDisabled: boolean = true;
  private stateTypes: Array<any> = ['archived']
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private DateService: DateService,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private arrayFunctions: ArrayFunctions,
    private confirmationModalFactory: ConfirmationModalFactory,
    private location: Location,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BinderModalComponent>
  ) { 
    this.flashService = angular.copy(flashService)
    this.helpService = angular.copy(helpService)
    this.processingStateService = fortyCore.processingStateService
    this.dateService = angular.copy(DateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    // this.flashService.listen_for_alert(this.$scope)
    this.isLoading = true
    this.binder = this.data.binderObject
    this.uiSetting = this.data.uiSetting
    this.formInit()
    if (this.uiSetting !== 'create') {
      this.get_full_binder()
      this.get_organizations()
    } else {
      this.get_requirement()
    }

    this.getUsersOrganizations()
    this.attachmentManagerFinishListener()
    this.stateManagerFinishListener()
    this.stateManagerUpdatedListener()
  }

  formInit = () => {
    this.binderForm = this.formBuilder.group({
      id: new FormControl(this.binder.id),
      organization_id: new FormControl(this.binder.organization_id, [Validators.required]),
      schedule_generation_state: new FormControl(this.binder.schedule_generation_state, [Validators.required]),
      schedule_start_date: new FormControl(this.binder.schedule_start_date, this.dateValidator()),
      annotation: new FormControl(this.binder.annotation),
      organization_specific_instructions: new FormControl(this.binder.organization_specific_instructions),
      user_compliance_admin_id: new FormControl(this.binder.user_compliance_admin_id),
      user_responsible_id: new FormControl(this.binder.user_responsible_id),
      user_assigned_id: new FormControl(this.binder.user_assigned_id),
      vendor: new FormControl(this.binder.vendor),
      requires_review: new FormControl(this.binder.requires_review)
    })
    this.formReady = true
  }

  dateValidator() {
    return (control: any) => {
      const selectedDate = dayjs(control.value)
      if (this.isLoading) { return }
      if (!this.dateDisabled(selectedDate.toDate()) && !dayjs(this.original_start_date).isSame(selectedDate) && this.binderForm.get('schedule_generation_state')?.value == 'schedule_on') {
        return { 'invalidDate': true }
      }
      return null
    }
  }

  getFloatingDateString = (date: any) => {
    var floating = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return floating
  }

  toUtcFloatingDate = (date: any) => {
    if(typeof date == 'string') {
      // this will avoid timezone issues
      date = date.replace(/-/g, '\/')
    }
    const formattedDate = new Date(date)
    if (formattedDate) {
      return new Date(Date.UTC(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate()))
    } else {
      return undefined
    }
  }

  doDateMagic = (date: any) => {
    let fl_dateString = this.getFloatingDateString(date);
    let fl_dayjs = dayjs.tz(fl_dateString, "Europe/London")

    let flStart = fl_dayjs.startOf('day').toDate()
    let flEnd = fl_dayjs.endOf('day').toDate()

    if (this.uiSetting === 'create' || this.binder.has_entries === false) {
      this.mutatedRuleInstance.options.dtstart = fl_dayjs.add(-1, 'day').toDate()
    }

    // check the recurrence rule against the current day we are looking at.
    // if there's a match it will pop it in isThereMatch in array format
    let isThereMatch = this.mutatedRuleInstance.between(flStart, flEnd)
    if (isThereMatch.length > 0) {
      return true
    } else {
      return false
    }
  }

  dateDisabled = (date: Date | null): boolean => {
    const today = dayjs().toDate()
    if (!date || !dayjs(date).isValid()) {
      return false
    }
    // past dates if not in create and there are entries
    if (this.uiSetting !== 'create' && this.binder.has_entries === true && date < today) {
      return false
    }

    // no specific interval rule is defined
    if (!this.interval || !this.interval.recurrence_rule.includes('BY')) {
      return true
    }
    return this.doDateMagic(date)
  }

  handleStoreOrganizationChange = () => {
    if (Array.isArray(this.selected.organizations) && this.selected.organizations.length > 0) {
      this.binder.organization_id = this.selected.organizations[0].value
      this.binderForm.get('organization_id').setValue(this.selected.organizations[0].value)
      this.getOrganizationUsers(this.binder.organization_id)
    } else {
      this.binder.organization_id = undefined
    }
  }

  handleListOrganizationChange = (id: any) => {
    this.binder.organization_id = id
    this.binderForm.get('organization_id').setValue(id)
  }

  getOrganizationUsers = (organization_id: any) => {
    if (!organization_id) {
      try {
        organization_id = this.binder.organization_id
      } catch (ex) { }
    }
    if (organization_id) {
      this.Restangular.all(`organizations/${organization_id}/org_users`).getList(
        {
          'assignable': true,
          'requirement_id': this.binder.requirement_id
        }
      ).then((response: any) => {
        this.users = response
        this.mappedUsers = response.map((r: any) => {
          return {
            displayName: r.profile.display_name,
            value: r.id
          }
        })
      }
      , (error: any) => this.utils.log(error, 'error', false))
    }
  }

  get_checked_assignees = () => {
    if (this.binder.user_compliance_admin_id) {
      this.selected_assignees.user_compliance = [{ displayName: this.binder.user_compliance_admin_name, value: this.binder.user_compliance_admin_id }]
    } else if (!this.binder.user_compliance_admin_id) {
      this.selected_assignees.user_compliance = [{ displayName: 'Unassigned', value: 0 }]
    }
    if (this.binder.user_responsible_id) {
      this.selected_assignees.user_responsible = [{ displayName: this.binder.user_responsible_name, value: this.binder.user_responsible_id }]
    } else if (!this.binder.user_responsible_id) {
      this.selected_assignees.user_responsible = [{ displayName: 'Unassigned', value: 0 }]
    }
    if (this.binder.user_assigned_id) {
      this.selected_assignees.user_assigned = [{ displayName: this.binder.user_assigned_name, value: this.binder.user_assigned_id }]
    } else if (!this.binder.user_assigned_id) {
      this.selected_assignees.user_assigned = [{ displayName: 'Unassigned', value: 0 }]
    }
  }

  updateUserCompliance = (userCompliance: any) => {
    let selectedAdminIds = []
    selectedAdminIds = userCompliance.map((r: any) => r.value)
    this.binder.user_compliance_admin_id = selectedAdminIds[0]
    this.binderForm.get('user_compliance_admin_id').setValue(selectedAdminIds[0])
  }

  updateUserResponsible = (userResponsible: any) => {
    let selectedResponsibleIds = []
    selectedResponsibleIds = userResponsible.map((r: any) => r.value)
    this.binder.user_responsible_id = selectedResponsibleIds[0]
    this.binderForm.get('user_responsible_id').setValue(selectedResponsibleIds[0])
  }

  updateUserAssigned = (userAssigned: any) => {
    let selectedAssigneesIds = []
    selectedAssigneesIds = userAssigned.map((r: any) => r.value)
    this.binder.user_assigned_id = selectedAssigneesIds[0]
    this.binderForm.get('user_assigned_id').setValue(selectedAssigneesIds[0])
  }

  lastNameSort = (names: any) => {
    return names.sort(function (s1: any, s2: any) {
      if (s1.profile.display_name.split(' ')[1] < s2.profile.display_name.split(' ')[1]) { return -1 }
      if (s1.profile.display_name.split(' ')[1] > s2.profile.display_name.split(' ')[1]) { return 1 }
      return 0
    });
  }

  assigneesApiSearchFunction = (args: any) => {
    let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        text_search: args.apiSearchInputString,
        pagination_params: [{ page: 1, pageSize: 50 }]
      }
    }
    let organization_id = this.binder.organization_id
    let searchResults:any = {}
    if (args.apiSearchInputString === '' && organization_id !== undefined) {
      return this.Restangular.all(`organizations/${organization_id}/org_users`).getList(
        {
          'assignable': true,
          'requirement_id': this.binder.requirement_id
        }
      ).then((success: any) => {
        let response = success.plain()
        this.users = response
        searchResults = this.lastNameSort(response)
        searchResults = searchResults.map((r: any) => {
          return {
            displayName: r.profile.display_name,
            value: r.id
          }
        })
        searchResults.unshift({ displayName: 'Unassigned', value: "" })
        loadedCallback({ items: searchResults, pagination_info: { totalCount: response.length, maxItemCount: response.length } })
      }
      , (error: any) => {
        this.utils.log(error, 'error', false)
      });
    } else if (organization_id !== undefined) {
      // this happens if the user types in the search bar
      return this.Restangular.one(`organizations/${organization_id}/parent_assignees`).get(params).then((success: any) => {
        let response = success.plain()
        searchResults.items = this.lastNameSort(response.items)
        searchResults.items = searchResults.items.map((r: any) => {
          return {
            displayName: r.profile.display_name,
            value: r.id
          }
        })
        loadedCallback(searchResults)
      }
      , (error: any) => {
        this.utils.log(error, 'error', false)
      });
    }
  }

  // set default status on page load and set help text on page load
  setDefaultScheduleStatus = (response: any) => {
    let selected_enum = undefined
    if (response.schedule_on) {
      selected_enum = this.enums.schedule_on
    } else if (response.start_date_needed) {
      selected_enum = this.enums.start_date_needed
    } else if (response.no_schedule) {
      selected_enum = this.enums.no_schedule
    } else if (response.managed_by_paired_application) {
      selected_enum = this.enums.managed_by_paired_application
    }
    if (selected_enum !== undefined) {
      this.binder.schedule_generation_state = selected_enum
      this.binderForm.get('schedule_generation_state').setValue(selected_enum)
      this.syncSelectedHelpText()
    }
  }

  syncSelectedHelpText = () => {
    const selectedState = this.schedule_states.find((s: any) => s.value === this.binderForm.controls.schedule_generation_state.value)
    this.active_help_text = selectedState?.helpText || ''
  }

  mutateRRuleForValidation = () => {
    // check to see if rule is a 'bymonthday' or 'byday' recurrence rule
    if (this.interval.recurrence_rule.includes('BY')) {
      let localRule :any;
      localRule = rrule.RRule.fromString(this.interval.recurrence_rule)
      // set interval to 1 so the interval doesn't effect valid start dates
      // example: quarterly repeats every 3 months, we want them to start on ANY month of year
      localRule.options = {
        ...localRule.options,
        byhour: [12],
        byminute: [0],
        bysecond: [0],
      }
      localRule.options.interval = 1
      localRule.options.tzid = Intl.DateTimeFormat().resolvedOptions().timeZone
      this.mutatedRuleInstance = localRule
    }
  }

  parse_descriptions = (response: { assignments: any; mailbox_pairing_description: string; }) => {
    if(response.assignments) {
      this.descriptions = JSON.parse(response.mailbox_pairing_description)
      this.pm_count = JSON.parse(response.mailbox_pairing_description).length
    }
  }

  get_full_binder = () => {
    this.Restangular.all('binders').get(this.binder.id).then((response: any) => { // success
      if (response.requirement.interval) {
        this.interval = response.requirement.interval
        this.mutateRRuleForValidation()
      }
      // response.schedule_start_date = this.dateService.convert_from_imt_date(response.schedule_start_date)
      // format_database_date
      // temp for now until we convert other models to generation status(entry, org, requirements)
      response.state.archived = response.archived === true ? true : null
      this.binder = response
      this.parse_descriptions(response)
      this.encourageNillAnnotation = (this.binder.requirement.annotation_state === 'annotation_disabled' && this.binder.annotation)
      // load schedule statess
      this.initializeScheduleStates()
      this.syncSelectedHelpText()
      this.is_start_date_disabled()
      // used for date validation in save function
      this.original_start_date = this.binder.schedule_start_date
      // the date picker field will throw validation error if start date is null so we set it to empty so we can save
      // with a start date needed
      if (this.binder.schedule_generation_state === 'start_date_needed') { this.binder.schedule_start_date = '' }
      this.get_checked_assignees()
      this.isLoading = false
      if (response.organization_id) { this.getOrganizationUsers(response.organization_id) }
    }, (error: any) => this.utils.log(error, 'error', false))
  }

  get_requirement = () => {
    this.Restangular.one('requirements', this.binder.requirement_id).get().then((response: any) => {
      if (response.interval) {
        this.interval = response.interval
        this.mutateRRuleForValidation()
      }
      // load  schedule states
      this.initializeScheduleStates()
      this.setDefaultScheduleStatus(response)
      this.binder.requirement = response
      this.isCalendarDisabled = false
      return this.isLoading = false
    })
  }

  get_organizations = () => {
    this.Restangular.all('organizations').getList().then((response: any) => { // success
      this.organizations = response
      this.organization_select_options = angular.copy(this.flatten_organizations(this.organizations))
    }, (error: any) => this.utils.log(error, 'error', false))
  }

  getUsersOrganizations = () => {
    let params = { param_data: { /* if they want visibility, defualt it here */ } }
    this.Restangular.all('organizations/filter').getList(params).then(
      (success: any) => {
        let usersOrganizations = success.plain()
        this.usersOrganizationsLoaded(usersOrganizations)
      }, (error: any) => this.utils.log(error, 'error', false))
  }

  usersOrganizationsLoaded = (usersOrganizations: any) => {
    if (!usersOrganizations) {
      return
    }
    var flattenedOrganizations = this.arrayFunctions.flattenNestedArrayRecursive(usersOrganizations)

    this.usersOrganizations = flattenedOrganizations.filter((r: any) => {
      return !r.state.archived
    }).map((r: any) => {
      return { displayName: r.name, value: r.id, level: r.level, entryId: r.entryId, parentId: r.parent_id, hasDescendants: r.hasDescendants }
    })
    if (this.uiSetting === 'create') {
      var pills = this.$rootScope.filterService.getCached()
      var orgPills = []
      if (Array.isArray(pills) && pills.length > 0) {
        orgPills = pills.filter(p => p.Field === 'organizations_ids')
      }
      if (orgPills.length === 1 && !orgPills[0].includeDescendants) {
        this.binder.organization_id = orgPills[0].Value
        this.getOrganizationUsers(this.binder.organization_id)
      } else {
        this.binder.organization_id = undefined
      }
    }

    if (this.binder.organization_id) {
      var selectedOrg = this.usersOrganizations.find((o: any) => o.value === this.binder.organization_id)
      selectedOrg.ticked = true
    }
  }

  // creates a flattened array of the nested $scope.organization_select_options array and updates
  // the name with spacing for tree structure
  flatten_organizations = (organizations_array: any) => {
    organizations_array.sort((a: any, b: any) => {
      if (a['name'].toLowerCase() > b['name'].toLowerCase()) {
        return 1
      } else {
        return -1
      }
    })
    let flattened_organizations = []
    let i = 0
    while (i < organizations_array.length) {
      organizations_array[i].name = `${this.determine_depth(organizations_array[i])}${organizations_array[i].name}`
      flattened_organizations.push(organizations_array[i])
      flattened_organizations = flattened_organizations.concat(this.flatten_organizations(organizations_array[i]['children']))
      i++
    }
    return flattened_organizations
  }

  // creates dynamic indentions for the organizations select menu
  // TODO maybe not the best name.
  determine_depth = (organization: any) => {
    let spacing = ''
    let i = 0
    while (i < organization.depth) {
      spacing += '\u00A0\u00A0'
      i++
    }
    return spacing
  }

  getPairedHelpText = () => {
    if (this.uiSetting === 'create') {
      return this.helpService.get_tooltip('store_schedule_status.managed_by_paired_application.helpText')
    } else {
      if (this.binder.mailbox_pairing_configured) {
        return this.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.helpTextPaired')
      } else {
        return this.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.helpTextNotPaired')
      }
    }
  }

  initializeScheduleStates = () => {
    let ui = this.uiSetting
    let role_title = this.$rootScope.session.getUser().user_role.role.title
    this.schedule_states = [
      {
        value: this.enums.schedule_on,
        visible: this.binder.requirement.schedule_on,
        display: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.schedule_on.display') : this.helpService.get_tooltip('edit_schedule_status.schedule_on.display'),
        helpText: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.schedule_on.helpText') : this.helpService.get_tooltip('edit_schedule_status.schedule_on.helpText')
      },
      {
        value: this.enums.no_schedule,
        visible: this.binder.requirement.no_schedule,
        display: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.no_schedule.display') : this.helpService.get_tooltip('edit_schedule_status.no_schedule.display'),
        helpText: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.no_schedule.helpText') : this.helpService.get_tooltip('edit_schedule_status.no_schedule.helpText')
      },
      {
        value: this.enums.start_date_needed,
        visible: this.binder.requirement.start_date_needed,
        disabled: role_title !== 'System Admin' && role_title !== 'Organization Admin' && role_title !== 'Reviewer',
        display: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.start_date_needed.display') : this.helpService.get_tooltip('edit_schedule_status.start_date_needed.display'),
        helpText: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.start_date_needed.helpText') : this.helpService.get_tooltip('edit_schedule_status.start_date_needed.helpText')
      },
      {
        value: this.enums.managed_by_paired_application,
        visible: this.binder.requirement.managed_by_paired_application,
        disabled: true,
        display: ui === 'create' ? this.helpService.get_tooltip('store_schedule_status.managed_by_paired_application.display') : this.helpService.get_tooltip('edit_schedule_status.managed_by_paired_application.display'),
        helpText: this.getPairedHelpText()
      }
    ]
    this.filterScheduleStates()
  }

  show_me_the_money = () => console.log(this.binder)

  get_assignment_instructions_help_text = (role: any) => {
    if (role === 'Compliance Leader') {
      if (this.binder.requirement.compliance_leader_assignment_instructions) {
        return this.binder.requirement.compliance_leader_assignment_instructions
      } else {
        return this.helpService.get_tooltip('binder.user_compliance_admin')
      }
    } else if (role === 'Program Leader') {
      if (this.binder.requirement.program_leader_assignment_instructions) {
        return this.binder.requirement.program_leader_assignment_instructions
      } else {
        return this.helpService.get_tooltip('binder.user_responsible')
      }
    } else if (role === 'Task Agent') {
      if (this.binder.requirement.task_agent_assignment_instructions) {
        return this.binder.requirement.task_agent_assignment_instructions
      } else {
        return this.helpService.get_tooltip('binder.user_assigned')
      }
    }
  }

  onDateSelected = (date: Date) => {
    this.binder.schedule_start_date = date
    this.binderForm.get('schedule_start_date').setValue(date)
    if (this.binderForm.get('schedule_start_date').valid && this.uiSetting !== 'create') {
      this.binderForm.get('schedule_generation_state').setValue(this.enums.schedule_on)
      this.syncSelectedHelpText()
    }
  }

  // make sure help text matches up when user selects new status
  watchActiveScheduleStatus = () => {
    let active_status = this.binderForm.controls.schedule_generation_state.value
    if (active_status && this.schedule_states.length > 0) {
      let match = this.schedule_states.find((option: any) => {
        return option.value === active_status
      })
      if (active_status === 'start_date_needed') {
        // this.binder.schedule_start_date = ''
        this.binderForm.get('schedule_start_date').setValue('')
      }
      this.binderForm.get('schedule_start_date').updateValueAndValidity()
      this.active_help_text = match.helpText
    }
    // disable start date if status is not schedule enabled
    this.is_start_date_disabled()
  }

  submit_form = (form: any, object: any) => {
    this.date_helpers.start_date.visible = false
    this.isLoading = true
      // checking that a date was entered, checking that rrule agrees with the date.
      // this was added because if you just used the input field and never clicked the calendar, the form would always be valid
      // if (object.schedule_start_date === null ||
      //   ($scope.interval !== undefined &&
      //   $scope.interval.recurrence_rule.includes('BY') &&
      //   $scope.doDateMagic(object.schedule_start_date) === false)) {
      //   $scope.date_helpers.start_date.message = 'Please select a valid date in accordance with the interval rules.'
      //   $scope.date_helpers.start_date.visible = true
      //   return $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
      // }
    if (this.binderForm.valid) {
      // skip past date validation when we are in create OR when there are no entries on the binder AND we are in a schedule state of schedule on
      if (this.uiSetting === 'create') {
        if (this.binderForm.get('organization_id').value === undefined) {
          this.failedSubmission = true
          return this.isLoading = false
        }
        return this.save_new_binder_and_close_modal(this.binderForm.value)
      } else {
        // call validator in application_service, this returns an error count
        // only validate when field has been changed
        let number_of_errors = 0
        if (this.binder.schedule_start_date !== this.original_start_date && this.binder.has_entries === true && this.binder.schedule_generation_state === 'schedule_on') {
          number_of_errors = this.dateService.binder_date_validation(this.date_helpers, this.binderForm.value)
        } else {
          number_of_errors = 0
        }
        if (number_of_errors === 0) {
          // successful save path
          this.date_helpers.start_date.visible = false
          this.start_save(this.binderForm.value)
          return this.isLoading = false
        } else {
          // fail submission path
          this.failedSubmission = true
          return this.isLoading = false
        }
      }
    } else {
      this.failedSubmission = true
      return this.isLoading = false
    }
  }

  shouldShowCompleteOption = (scheduleState: any) => {
    return scheduleState.visible
  }

  filterScheduleStates(): void {
    this.filtered_schedule_states = this.schedule_states.filter(this.shouldShowCompleteOption)
  }

  toggle_schedule_enabled = (binder: any) => {
    // don't allow schedule toggling when the binder is paired
    if (
      (this.isLoading || this.uiSetting === 'create') &&
      !binder.mailbox_pairing_configured
    ) {
      // Pre-Toggle, save the schedule_start_date if avaliable
      binder.previous_schedule_start_date = binder.schedule_start_date

      // Toggle
      binder.schedule_enabled = !binder.schedule_enabled

      // Post-Toggle, populate the schedule_start_date
      if (binder.schedule_enabled) {
        if (binder.previous_schedule_start_date) {
          binder.schedule_start_date = binder.previous_schedule_start_date
        } else {
          binder.schedule_start_date = dayjs().$d
        }
      } else { // !binder.schedule_enabled
        binder.schedule_start_date = null
      }

      this.binderForm.field_schedule_switch.$setDirty()
    }
  }

  is_start_date_disabled = () => {
    const userRole = this.$rootScope.session.getUser().user_role.role.title
    if (this.uiSetting === 'read') {
      return this.isCalendarDisabled = true
    }
    if (this.uiSetting === 'create') {
      return (
        this.binderForm.get('schedule_generation_state').value === this.enums.start_date_needed ||
        this.binderForm.get('schedule_generation_state').value === this.enums.no_schedule ||
        this.binderForm.get('schedule_generation_state').value === this.enums.managed_by_paired_application
      ) ? this.isCalendarDisabled = true : this.isCalendarDisabled = false
    }
    if (this.uiSetting === 'update' && ['System Admin', 'Organization Admin', 'Reviewer'].includes(userRole)) {
      return this.isCalendarDisabled = false
    }
    return this.isCalendarDisabled = true
  }

  /* SAVE PROCESS COUNTERS */
  increment_fault_counter = () => this.fault_counter++

  increment_pending_requests = () => this.pending_requests++

  decrement_pending_requests = () => {
    this.pending_requests--
    if (this.pending_requests === 0) {
      if (this.fault_counter === 0) {
        return this.after_save(this.binder)
      } else {
        return this.finally_do()
      }
    }
  }

  /* SAVE TRIGGERS */
  start_save = (object: any) => {
    let clone = angular.copy(object)
    if (clone.schedule_start_date) {
      clone.schedule_start_date = this.toUtcFloatingDate(clone.schedule_start_date)
    }
    this.pending_requests = 0
    this.fault_counter = 0
    this.save_base_object(clone)
    return this.trigger_attachment_manager_start(clone)
  }

  after_save = (object: any) => this.trigger_state_manager_start(object)

  finally_do = () => {
    this.processingStateService.set(this.binderForm, this.processingStateService.processing_states.RESTING)
    if (this.fault_counter === 0) {
      return this.closeModalAndReturn(this.binder)
    }
  }

  /* SAVE FUNCTIONS / LISTENERS */
  save_new_binder_and_close_modal = (object: any) => {
    let clone = angular.copy(object)
    if (clone.schedule_start_date) {
      clone.schedule_start_date = this.toUtcFloatingDate(clone.schedule_start_date)
    }
    this.Restangular.all('binders').post(clone).then(
      (success: any) => this.closeModalAndReturn(success), 
      (error: any) => this.flashService.process_error(error)
    ).finally(() => this.processingStateService.set(this.binderForm, this.processingStateService.processing_states.RESTING))
  }

  save_base_object = (object: any) => {
    this.increment_pending_requests()
    return this.Restangular.one('binders', object.id).patch({ binder: object }).then((success: any) => this.binder = success, (error: any) => {
      this.increment_fault_counter()
      return this.flashService.process_error(error)
    }).finally(() => this.decrement_pending_requests());
  }

  trigger_attachment_manager_start = (object: any) => {
    this.increment_pending_requests()
    return this.$rootScope.$broadcast('attachment_manager_start', object)
  }

  attachmentManagerFinishListener = () => {
    this.$rootScope.$on('attachment_manager_finished', (event: any, manager_result: any) => {
      this.binder.attachments = manager_result.attachments
      if (manager_result.fault) { this.increment_fault_counter() }
      return this.decrement_pending_requests()
    })
  }

  trigger_state_manager_start = (object: any) => this.$rootScope.$broadcast('state_manager_start', object)

  stateManagerFinishListener = () => {
    this.$rootScope.$on('state_manager_finished', (event: any, manager_result: any) => {
      // Only update the state when changes were made
      if (manager_result.object !== null) {
        this.binder.state = manager_result.object
  
        // We revoke this on the api, but due to the order of this 'save relay',
        // its not really represented in the return objects. so we set it here.
        if (this.binder.archived === true) {
          this.binder.mailbox_pairing_configured = false
        }
      }
      if (manager_result.fault) { this.increment_fault_counter() }
      return this.finally_do()
    })
  }

  navigate_to_management_url = (binder: any) => {
    window.open(
      binder.mailbox_pairing_management_url,
      '_blank'
    )
  }

  openBinderDestroyModal = (binder: any) => {
    let modalReady = (binder_stats :any) => {
      const confirmation_object = {
        confirm: () => { return this.destroy_binder(binder) },
        message:
          `<p>${binder_stats.task_count} tasks and all attached documents (${binder_stats.attachment_count}) will be deleted and this requirement will no longer be configured for your Organization. None of these documents will be available for your surveying entity to view.  THIS ACTION IS NOT REVERSIBLE</p>`
      }

      return this.processingStateService.performFunction(binder, () =>
        this.confirmationModalFactory.openConfirmationModal(
          confirmation_object
        ).then((result: any) => {
          if (!result) {
            this.processingStateService.set(
              binder,
              this.processingStateService.processing_states.RESTING
            )
          }
          return true
        })
      );
    }
  
    this.Restangular.one(`binders/${this.binder.id}/binder_stats`).get().then((success: any) => {
      console.log('success:', success)
      let binder_stats = success.plain()
      modalReady(binder_stats)
    }, (error: any) => {
      console.log('err:', error)
    });
  }

  destroy_binder = (binder: any) => {
    this.isLoading = true
    this.processingStateService.set(this.binderForm, this.processingStateService.processing_states.PENDING)
    this.Restangular.all(`binders/${binder.id}`).remove().then(
      (success: any) => {
        this.closeModal()
        if (this.location.path().includes('/requirements/binders')) {
          window.location.reload();
        } else {
          this.$rootScope.session.goTo('/requirements/binders')
        }
        this.isLoading = false
      },
      (error: any) => {
        this.processingStateService.set(
          this.binderForm,
          this.processingStateService.processing_states.RESTING
        )
        this.flashService.process_error(error)
        this.isLoading = false
        return this.utils.log(error, 'error', false)
      }
    )
  }

  stateManagerUpdatedListener = () => {
    this.$rootScope.$on('state_manager_updated', function (event: any, archived_value: any) {
      // disable save button if archived is clicked and binder is paired
      this.is_archived_clicked = archived_value
    })
  }

  /* MODAL FUNCTIONS */
  closeModalAndReturn = (object: any) => this.dialogRef.close(object)

  closeModal = () => this.dialogRef.close('close')
}

angular
  .module('complyosClient')
  .directive('ngBinderModal', downgradeComponent({ component: BinderModalComponent }) as angular.IDirectiveFactory)
