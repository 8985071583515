import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as Restangular from '../../../vendor/restangular/restangular'
import angular from 'angular';
import { FlashService } from '../../services/flash_service.service';

@Component({
  selector: 'ngStateWidget', //ng-state-widget
  templateUrl: './state-widget.component.html'
})
export class StateWidget implements OnInit {
  @Input() currentState: string;
  @Input() currentStateColor: string;
  @Input() currentIconClassName: string;
  @Input() transitionState: string;
  @Input() transitionStateColor: string;
  @Input() transitionIconClassName: string;
  @Input() isPending: boolean;
  @Input() warnings: Array<any> = [];
  @Input() isUpdateable: boolean = true;
  @Input() helpTooltip: string;


  @Output() transitionButtonFn = new EventEmitter();
  @Output() cancelButtonFn = new EventEmitter();

  private flashService: any;

  constructor(
    private Restangular: Restangular,
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
  ){
    this.flashService = angular.copy(flashService)
  }

  ngOnInit(): void {
  }

  transitionClick = () => {
    this.transitionButtonFn.emit(this.transitionState)
  }

  cancelPendingClick = () => {
    this.cancelButtonFn.emit()
  }

  
  toggleShowTransitionButton($event) {
    var target = $event.target || $event.srcElement || $event.currentTarget;
    var collapsable = $(target).find('.state-widget-collapsable')
    collapsable.animate({ width: "toggle" }, 75)
  }
}

angular
  .module('complyosClient')
  .directive('ngStateWidget', downgradeComponent({ component: StateWidget }) as angular.IDirectiveFactory)