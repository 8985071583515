import { Component, Inject, OnInit } from '@angular/core';
import { FlashService } from '../../../services/flash_service.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { DateService } from '../../../services/date_service.service';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { fortyCore } from '../../../../scripts/services/fortyau_services';
var dayjs = require("dayjs")

@Component({
  selector: 'ngEntryModal',
  templateUrl: './entry-modal.component.html'
})
export class EntryModalComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  private processingStateService: any;
  private session: any;
  private dateService: any;

  // Properties
  private uiSetting: string;
  private entry: any;
  private formReady: boolean = false;
  private isLoading: boolean = true;
  private prepopulated_note: string;
  private failedSubmission: boolean = false;
  private complete_options: ({ value: string; display: string; visible: any; helpText: string; disabled?: undefined; } | { value: string; display: string; visible: any; disabled: boolean; helpText: string; })[];
  public entryForm: any = {}
  private filtered_complete_options: Array<any> = [];
  private stateTypes: Array<any> = ['inactive']
  private active_help_text: string;
  private noteControl: FormControl<string>;
  private attachmentRequired: boolean;
  private showUserProvidedDate: boolean;
  unregister_complete_manager_listener: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('fortyCore') private fortyCore: any,
    @Inject('complyosServices') private complyosServices,
    @Inject(FlashService) flashService: FlashService,
    private DateService: DateService,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EntryModalComponent>
  ) { 
    this.flashService = angular.copy(flashService)
    // needed for save relay
    this.$scope.flashService = this.flashService
    this.helpService = angular.copy(helpService)
    this.processingStateService = fortyCore.processingStateService
    this.dateService = angular.copy(DateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.flashService.listen_for_alert(this.$scope)
    this.entry = this.data.entryObject
    this.uiSetting = this.data.uiSetting
    this.listen_for_start_event()
    if (this.entry.id) {
      this.getFullEntry()
    } else {
      this.formInit()
    }

    this.complete_options = [
      {
        value: 'incomplete',
        display: 'Incomplete',
        visible: this.should_allow_complete_status('incomplete'),
        helpText: 'This is the default status'
      },
      {
        value: 'partially_complete',
        display: 'Partially Complete',
        visible: this.should_allow_complete_status('partially_complete') || (this.entry.complete_status === 'partially_complete'),
        disabled: !this.should_allow_complete_status('partially_complete') && (this.entry.complete_status === 'partially_complete'),
        helpText: 'Please document, in the Note section below, what is preventing you from completing this task.'
      },
      {
        value: 'attested_as_complete',
        display: 'Attested As Complete',
        visible: this.should_allow_complete_status('attested_as_complete'),
        helpText: 'The attestation note is considered your signature.'
      },
      {
        value: 'requires_resubmission',
        display: 'Requires Resubmission',
        visible: this.should_allow_complete_status('requires_resubmission'),
        helpText: 'Please document deficiencies below.'
      },
      {
        value: 'closed_not_complete',
        display: 'Closed Not Complete',
        visible: this.should_allow_complete_status('closed_not_complete') || (this.entry.complete_status === 'closed_not_complete'),
        disabled: !this.should_allow_complete_status('closed_not_complete') && (this.entry.complete_status === 'closed_not_complete'),
        helpText: 'Please upload a document containing the reason for closing (to be visible by surveyors), and optionally document the reason below in the note field.'
      }
    ]
    this.filterCompleteOptions()
  }

  ngOnDestroy(): void {
    // Angular $scope does not get destroyed when using tabs
    // must manually unregister
    if (this.unregister_complete_manager_listener) {
      this.unregister_complete_manager_listener()
    }
  }

  formInit = () => {
    // this is really just for note validation purposes.  the note manager will do all the saving in the db
    this.noteControl = new FormControl('', this.note_required());
    
    this.entryForm = this.formBuilder.group({
      id: new FormControl(this.entry.id),
      event_date: new FormControl(this.entry.event_date, this.eventDateRequired()),
      date_completed: new FormControl(this.entry.date_completed, this.dateCompletedRequired()),
      date_submitted: new FormControl(this.entry.date_submitted),
      complete_status: new FormControl(this.entry.complete_status),
      user_provided_next_event_date: new FormControl(this.entry.user_provided_next_event_date, this.userProvidedDateRequired()),
      notes: this.noteControl
    })
    this.formReady = true
    this.isLoading = false
  }

  dateCompletedRequired = () => {
    return (control: any) => {
      if (this.entry.complete_status === 'attested_as_complete' && !this.showUserProvidedDate && !control.value) {
        return { required: true }
      }
      return null
    }
  }

  eventDateRequired = () => {
    return (control: any) => {
      if (this.uiSetting === 'create' && !control.value) {
        return { required: true }
      }
      return null
    }
  }

  note_required = () => {
    return (control: any) => {
      if ((this.entry.complete_status === 'partially_complete' || this.entry.complete_status === 'requires_resubmission') && !control.value) {
        return { required: true }
      }
      return null
    }
  }

  userProvidedDateRequired = () => {
    return (control: any) => {
      if (this.entry.complete_status === 'attested_as_complete' && this.entry.is_head && this.showUserProvidedDate && !control.value) {
        return { required: true }
      }
      return null
    }
  }

  shouldShowCompleteOption = (scheduleState: any) => {
    return scheduleState.visible
  }

  filterCompleteOptions(): void {
    this.filtered_complete_options = this.complete_options.filter(this.shouldShowCompleteOption)
  }

  onEventDateSelected = (date: Date) => {
    this.entry.event_date = date
    this.entryForm.get('event_date').setValue(date)
  }

  onDateCompletedSelected = (date: Date) => {
    this.entry.date_completed = date
    this.entryForm.get('date_completed').setValue(date)
  }

  userEventDateSelected = (date: Date) => {
    this.entry.user_provided_next_event_date = date
    this.entryForm.get('user_provided_next_event_date').setValue(date)
  }

  // make sure help text matches up when user selects new status
  watchCompleteOptionStatus = () => {
    let active_status = this.entryForm.controls.complete_status.value
    if (active_status && this.complete_options.length > 0) {
      let match = this.complete_options.find((option: any) => {
        return option.value === active_status
      })
      this.entry.complete_status = active_status
      this.entryForm.get('complete_status').setValue(active_status)
      this.entryForm.get('date_completed').updateValueAndValidity()
      this.entryForm.get('user_provided_next_event_date').updateValueAndValidity()
      this.active_help_text = match.helpText
    }
    this.populate_note(active_status)
    this.attachment_required()
  }

  show_task_cannot_attest_message = () => {
    if ((this.session.getUser().id === this.entry.binder.user_assigned_id) && this.entry.binder.requirement.task_agent_cannot_attest) {
      return true
    } else {
      return false
    }
  }

  allow_closed_not_complete = () => {
    if (
      this.entry.binder.requirement.allow_closed_not_complete ||
      this.session.getUser().user_role.role.title === 'System Admin' ||
      this.session.getUser().user_role.role.title === 'Organization Admin' ||
      this.session.getUser().user_role.role.title === 'Reviewer'
    ) {
      return true
    } else if (this.uiSetting === 'create') {
      return false
    } else {
      return false
    }
  }


  /* RESTANGULAR */
  getFullEntry = () => {
    this.isLoading = true
    return this.Restangular.all('entries').get(this.entry.id).then((response: any) => { // success
      this.entry = response.plain()
      this.willGenerateNextTaskFromNextTaskDate(this.entry)
      this.format_database_dates(this.entry)
      this.formInit()
    }
    , (error: any) => this.utils.log(error, 'error', false))
  }

  pretty_array_length = (array: any) => {
    if (array && (array.length > 0)) {
      return `(${array.length})`
    }
  }

  // COMPLETE STATE & NOTES //

  should_allow_complete_status = (status_name: any) => {
    let allowed = true

    if (status_name === 'attested_as_complete') {
      if (this.entry.binder.requirement.task_agent_cannot_attest) {
        if (this.session.getUser().id === this.entry.binder.user_assigned_id) {
          allowed = false
        }
      }
    }

    if (status_name === 'attested_as_complete' || status_name === 'closed_not_complete') {
      if (this.entry.binder.requires_review && this.session.getUser().user_role.role.title === 'User') {
        allowed = false
      }
    }

    if (status_name === 'closed_not_complete' && !this.entry.binder.requirement.allow_closed_not_complete) {
      allowed = false
    }

    if (status_name === 'partially_complete' && !this.entry.binder.requirement.allow_partially_complete) {
      allowed = false
    }

    return allowed
  }

  populate_note = (complete_status: any) => {
    if (complete_status === 'attested_as_complete') {
      this.prepopulated_note = `I, ${this.session.getUser().profile.display_name}, certify this requirement, to the best of my knowledge, has all information and documentation necessary to demonstrate compliance with all applicable standards contained within this requirement.`
    } else if (complete_status === 'requires_resubmission') {
      this.prepopulated_note = `Task documentation did not meet this requirement's applicable standards and requires resubmission. Returned to schedule by ${this.session.getUser().profile.display_name}.`
    } else {
      this.prepopulated_note = ''
    }
    this.noteControl.setValue(this.prepopulated_note)
  }

  format_database_dates = (object: any) => {
    if (typeof object.date_completed === 'string') {
      if (this.uiSetting !== 'create') {
        object.date_completed = this.dateService.convert_from_imt_date(object.date_completed)
      }
    }

    if (typeof object.date_submitted === 'string') {
      if (this.uiSetting !== 'create') {
        object.date_submitted = this.dateService.convert_from_imt_date(object.date_submitted)
      }
    }

    if (typeof object.event_date === 'string') {
      if (this.uiSetting !== 'create') {
        object.event_date = this.dateService.convert_from_imt_date(object.event_date)
      }
    }

    if (typeof object.user_provided_next_event_date === 'string') {
      if (this.uiSetting !== 'create') {
        object.user_provided_next_event_date = this.dateService.convert_from_imt_date(object.user_provided_next_event_date)
      }
    }
  }

  start_date_conversion = (dates_to_convert: any, object: any) => {
    // we want to update the date submitted only when the entry is checked off as complete.
    if (object.complete_status === 'attested_as_complete') {
      object.date_submitted = dayjs().$d
      dates_to_convert.push('date_submitted')
    }

    if ((typeof object.date_completed !== 'undefined') && (object.date_completed !== null)) {
      dates_to_convert.push('date_completed')
    }

    if ((typeof object.user_provided_next_event_date !== 'undefined') && (object.user_provided_next_event_date !== null)) {
      dates_to_convert.push('user_provided_next_event_date')
    }

    return this.dateService.convert_dates_before_saving(object, dates_to_convert)
  }

  willGenerateNextTaskFromNextTaskDate = (entry: any) => {
    this.showUserProvidedDate = entry.binder.requirement.user_prompted_date_generation === true && entry.created_by !== 'user'
  }

  /* FORM SUBMISSION AND SAVE PROCESSES */

  user_can_attest = () => {
    if (this.entry.binder && this.entry.binder.requires_review) {
      return this.session.getUser().user_role.role.weight > 0
    } else {
      return true
    }
  }

  onNoteChange = (event) => {
    this.noteControl.setValue(event)
    this.noteControl.markAsTouched() // mark as touched to trigger note validation
  }

  attachment_required = () => {
    if (
      (this.entry.complete_status === 'attested_as_complete' && this.entry.binder.requirement.compliance_method !== 'Observation') ||
      this.entry.complete_status === 'closed_not_complete'
    ) {
      return this.attachmentRequired = true
    } else {
      return this.attachmentRequired = false
    }
  }

  isValidDateWithPlaceholders(dateField) {
    // const dateValue = this.entryForm.get('date_completed').value;
    const dateValue = this.entryForm.get(dateField)

    // event date field should never be empty if we are in create mode
    // date completed should never be empty if attested as complete
    if (dateField === 'date_completed' && this.entry.complete_status !== 'attested_as_complete' && !this.showUserProvidedDate) {
      // Check for empty or placeholder value (e.g., "__/__/____")
      if ((!dateValue.value || dateValue.value === '__/__/____')) {
        // Clear any existing errors since an empty value is considered valid
        dateValue.setErrors(null);
        return;
      }
    }
  
    // Check if it's a JavaScript Date object
    if (dateValue.value instanceof Date && !isNaN(dateValue.value.getTime())) {
      // Valid full Date object, clear any errors
      dateValue.setErrors(null);
      return;
    }
  
    // Regular expression to match a valid date in MM/DD/YYYY format
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  
    // Check if the date is a string and matches the pattern
    if (typeof dateValue.value === 'string' && dateRegex.test(dateValue.value)) {
      // Now validate the parsed date using dayjs (only if it passes the regex)
      const parsedDate = dayjs(dateValue.value, 'MM/DD/YYYY', true);
      if (parsedDate.isValid()) {
        dateValue.setErrors(null);
      } else {
        dateValue.setErrors({ invalidDateFormat: true });
      }
    } else {
      // Set an error if the date format is invalid or contains placeholders
      dateValue.setErrors({ invalidDateFormat: true });
    }
  }

  submit_form = (form: any, object: any) => {
    this.isLoading = true
    let promise_chain = []
    if (this.entryForm.get('date_completed').value !== null) {
      this.isValidDateWithPlaceholders('date_completed')
    }

    if (this.uiSetting === 'create') {
      this.isValidDateWithPlaceholders('event_date')
    }

    // only update state_manager when the entry is inactive
    // && you are system administrator
    // (inactive entries won't update in the API anyway)
    // otherwise update everything
    if (this.session.getUser().user_role.role.title === 'System Admin') {
      if (this.entry.state.inactive) {
        promise_chain = [
          'state_manager_promise_alias'
        ]
      } else {
        promise_chain = [
          'base_object_save_promise_alias',
          'attachment_manager_promise_alias',
          'note_manager_promise_alias',
          'entry_complete_manager_promise_alias',
          'state_manager_promise_alias'
        ]
      }
    } else {
      promise_chain = [
        'base_object_save_promise_alias',
        'attachment_manager_promise_alias',
        'note_manager_promise_alias',
        'entry_complete_manager_promise_alias'
      ]
    }

    if (this.entryForm.valid) {
      // this date conversion process used to be before the processingStateService perform method.
      // But a bug was discovered that when submitting an invalid form,
      // it would convert the dates, but then would fail, making the date fields go blank
      // after the failed submission, making the form non-submittable and unfixable due to disabled fields.
      // to circumvent this issue, we convert the dates only after we know we have a valid form.
      const dates_to_convert = ['event_date']
      let object_copy = angular.copy(this.entry)
      object_copy = this.start_date_conversion(dates_to_convert, object_copy)

      // July 19, 2017:
      // see notes on jade file
      // saving the state manager has been taken out
      this.complyosServices.save_relay.start(
        this.$scope,
        object_copy,
        form,
        'entries',
        promise_chain,
        this.closeModalAndReturn
      )
    } else {
      this.failedSubmission = true
      return this.isLoading = false
    }
    this.isLoading = false
  }


  /* MODAL FUNCTIONS */
  closeModalAndReturn = (data: any) => {
    this.entry.id = data.id
    this.getFullEntry().then(() => {
      this.entry.event_date = this.dateService.convert_to_imt_date(this.entry.event_date)
      this.dialogRef.close(this.entry)
    })
  }

  listen_for_start_event = () => {
    this.unregister_complete_manager_listener = this.$scope.$on('entry_complete_manager_start', (event: any, data: any) => {
      this.save_entry_complete(data)
    })
  }

  save_entry_complete = (data: any) => {
    this.Restangular.all(`entries/${data.id}/complete_status`).post({
      complete_status: this.entry.complete_status
    }).then((success: any) => {
      // $scope.ngModel = success.complete_status
    }, (error: any) => {
      this.flashService.emit_alert(
        this.$scope,
        this.flashService.default_alert_by_code(error.status)
      )
    }).finally(() => {
      this.emit_completion_event()
    })
  }

  emit_completion_event () {
    const returnObject = {
      manager: 'entry_complete_manager',
      object: this.entry.complete_status,
      fault: undefined
    }
    this.$scope.$emit('entry_complete_manager_finished', returnObject)
  }

  closeModal = () => this.dialogRef.close('close')
}

angular
  .module('complyosClient')
  .directive('ngEntryModal', downgradeComponent({ component: EntryModalComponent }) as angular.IDirectiveFactory)
