/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('replaceWithChild', ['$timeout', ($timeout: any) => ({
  require: 'ngInclude',
  restrict: 'A',

  link (scope: any, el: any, attrs: any) {
    $timeout(function () {
      el.replaceWith(el.children())
    })
  }
})
])
