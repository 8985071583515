import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { Utils } from '../../../scripts/services/ajs-utils';
import _ from 'lodash';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'ngPermission',
  templateUrl: './permission.component.html',
})

export class PermissionComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;

  // Properties
  rp: any;
  private roles: any;
  private role_permissions: any = [];
  private role_attributes: any = []

  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.rp = { query: '' }
    this.getPermissions();
  }

  getPermissions = () => {
    this.loadingStateService.set_state('loading')
    this.Restangular.all('roles').getList().then(response => {
      this.loadingStateService.process_success(response)
      this.roles = response.plain()
      this.assemble_role_attributes()
      this.assemble_role_permissions()
    },
    (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    });
  }

  assemble_role_permissions = () => {
    this.role_permissions = []
    if (this.roles[0]) {
      Object.keys(this.roles[0].permissions).forEach(key => {
        if (
          (key !== 'id') &&
          (key !== 'permissible_id') &&
          (key !== 'permissible_type') &&
          (key !== 'permissions_create') &&
          (key !== 'created_at') &&
          (key !== 'updated_at')
        ) {
          this.role_permissions.push(key)
        }
      });
    }
  }

  assemble_role_attributes = () => {
    this.role_attributes = []
    if (this.roles[0]) {
      Object.keys(this.roles[0]).forEach(key => {
        if (
          (key !== 'id') &&
          (key !== 'description') &&
          (key !== 'title') &&
          (key !== 'permissions')
        ) {
          this.role_attributes.push(key)
        }
      });
    }
  }

  filter_role_attributes = () => {
    if(!this.rp.query || this.rp.query.trim() === '') {
      return this.role_attributes;
    } else {
      return this.role_attributes.filter(attribute => attribute.toLowerCase().includes(this.rp.query.toLowerCase()))
    }
  }
  
  filter_role_permissions = () => {
    if(!this.rp.query || this.rp.query.trim() === '') {
      return this.role_permissions.sort();
    } else {
      return this.role_permissions.filter(attribute => attribute.toLowerCase().includes(this.rp.query.toLowerCase()))
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngPermission', downgradeComponent({ component: PermissionComponent }) as angular.IDirectiveFactory)