import * as angular from 'angular';
import { complyosClient } from '../app.legacy';


let CardComponent = {
    selector: "ccCard", //<cc-card>
    template: `
<div style="background-color:darkgray;padding:20px;color:red;width:400px;">
    <p>click this button to engage a few ng12 -> njs -> ng12 service</p>
    <button class="btn btn-info btn-sm"
        ng-click="$ctrl.deleteUser()">
        &nbsp;trigger service calls
    </button>
  </div>
</div>  
  `,
    bindings: {
        user: "="
    },
    controller: class CardController {
        private ng12Resource;
        private ng12Service;
        private njsResource;

        private user;

        $onInit = () => { };

        static $inject = ['ng12Resource', 'ng12Service', 'njsResource'];
        constructor(ng12Resource, ng12Service, njsResource) {
            this.ng12Resource = ng12Resource;
            this.ng12Service = ng12Service;
            this.njsResource = njsResource;
        }

        deleteUser() {
            console.log('deleteF')

            console.log('directly access contact resource: ')

            this.ng12Resource.whoami("njs component");

            this.ng12Service.loadContacts();

            this.njsResource.whoami("njs component");
        }
    }
};

// angular
//     .module("complyosClient")
angular
  .module('complyosClient')
  .component(CardComponent.selector, CardComponent);

