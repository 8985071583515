// /* eslint-disable
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('applicationFooterController', ['$rootScope', '$scope', '$uibModal', '$window', 'utils', function (
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   $window: any,
//   utils: any
// ) {
//   $scope.click_help_link = () => {
//     if ($rootScope.session.activeUserPresent()) {
//       $rootScope.session.goTo('/help')
//     } else {
//       $rootScope.session.goTo('/marketing')
//     }
//   }

//   $scope.openTermsOfServiceModal = function () {
//     let modalInstance
//     return modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/terms_of_service_modal.html',
//       controller: 'FooterController',
//       size: 'xl'
//     })
//   }

//   $scope.openPrivacyPolicyModal = function () {
//     let modalInstance
//     return modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/privacy_policy_modal.html',
//       controller: 'FooterController',
//       size: 'xl'
//     })
//   }
// }])
