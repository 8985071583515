// /* eslint-disable
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('usersLoginController', ['$location', '$q', '$rootScope', '$scope', 'authenticationService', 'flashService', 'Restangular', 'utils', function (
//   $location: any,
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   authenticationService: any,
//   flashService: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
//     /* FLASHES */
    
//     $scope.flashService = angular.copy(flashService)
//     $scope.flashService.listen_for_alert($scope)
//     return $scope.session = $rootScope.session
//   }

//   /* FUNCTIONS */

//   $scope.submit_form = function (form: any, object: any) {
//     if (form.$valid) {
//       return authenticationService.submit_user_credentials(object)
//     } else {
//       return form.failed_submission = true
//     }
//   }

//   /* EVENTS */
//   $scope.$on('userIsNotAuthenticated', (event: any, args: any) =>
//     $scope.flashService.add_alert({
//       name: `${args.status}_alert`,
//       dismissable: false,
//       class: 'alert-danger',
//       icon: 'fa-warning',
//       message: 'Access Denied'
//     })
//   )

//   return init()
// }])
