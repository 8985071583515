/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import * as _ from 'lodash';

var complyosClient = angular.module('complyosClient')

complyosClient.directive('taggingManager', [
  '$rootScope',
  'confirmationModalFactory',
  'flashService',
  'fortyCore',
  'objectManager',
  'Restangular',
  '$q',
  'loadingStateService',
  function (
    $rootScope: any,
    confirmationModalFactory: any,
    flashService: any,
    fortyCore: any,
    objectManager: any,
    Restangular: any,
    $q: any,
    loadingStateService: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/tagging_manager.html',
      require: 'ngModel',
      scope: {
        allowCreate: '=',
        allowRead: '=',
        allowUpdate: '=',
        allowDestroy: '=',
        taggableId: '=',
        taggableType: '@',
        hasError: '=?',
        pickTagsAccess: '=',
        roleSelected: '=',
        minsuggestedlength: '=?',
        uiSetting: '=',
        filterByLobs: "=",
        stageTaggings: "="
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized
      //    attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.outstandingPromises = []
        
        $scope.loadingStateService = angular.copy(loadingStateService),
        $scope.loadingStateService.init()
        $scope.tm = {

          tags: [],
          tag_query: '',
          tags_configured: false,
          no_filter_query: true,
          flat_tags: [],
          tags_to_show: true,
          has_error: $scope.hasError,

          initialize () {
            this.ng_model_value = $ngModel.$modelValue
            this.users_tags = $ngModel.$modelValue
            this.taggable_id = $scope.taggableId
            this.tags = []
            // this.get_tags()
            this.add_validators()
            this.add_warners()
            this.watch_ng_model()
            this.listen_for_start_event()
            this.watch_for_tags_to_stage()


            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          check_warning_state () {
            return _.isEmpty(this.warnings)
          },


          
          get_tags () {
            if (Array.isArray($scope.filterByLobs) && $scope.filterByLobs.length > 0) {
              const params = {
                "lob[]": $scope.filterByLobs.map((lob:any) => lob.title)
              }

              $scope.loadingStateService.set_state('loading')
              $scope.abortAllPromises($scope.outstandingPromises)
              let abort = $q.defer()
              $scope.outstandingPromises.push(abort)

              return Restangular.all('tags/show_lob_children_tags').withHttpConfig({ timeout: abort.promise }).getList(params).then(
                (success: any) => {
                  $scope.loadingStateService.process_success(success)
                  this.tags = success
                  this.flat_tags = $scope.tm.flatten_tree(success)
                  this.stageDuplicateTags()
                  return $ngModel.$validate()
                }, (error: any) => console.log(error));
            } else {
              return Restangular.all('tags').getList().then(
                (success: any) => {
                  $scope.loadingStateService.process_success(success)
                  this.tags = success
                  this.flat_tags = $scope.tm.flatten_tree(success)
                  this.stageDuplicateTags()
                  return $ngModel.$validate()
                }, (error: any) => console.log(error));
            }
          },

          taggings_include (tag: any) {
            // return _.some($ngModel.$modelValue, tagging => tagging.tag.id === tag.id)
            return _.some($scope.tm.ng_model_value, (tagging: any) => tagging.tag.id === tag.id);
          },

          get_tag_icon (tag: any) {
            let icon

            if (tag.pending_action) {
              if (tag.pending_action === 'create') {
                icon = 'fa fa-plus-square'
              } else {
                icon = 'fa fa-minus-square-o'
              }
            } else { // there are no pending_actions
              if ($scope.tm.taggings_include(tag)) {
                icon = 'fa fa-check-square'
              } else {
                icon = 'fa fa-square-o'
              }
            }

            return icon
          },

          search: {
            show () {
              if ($scope.tm.messages.no_role_selected()) {
                return false // hide
              } else if ($scope.tm.messages.no_tags_necessary()) {
                return false
              } else {
                return !$scope.tm.messages.no_tags_configured()
              }
            }
          },

          messages: {
            show () {
              return $scope.tm.messages.no_role_selected() ||
              $scope.tm.messages.no_tags_necessary() ||
              $scope.tm.messages.no_tags_configured() ||
              $scope.tm.messages.no_tags_match()
            },

            no_role_selected () {
              return !$scope.roleSelected &&
              ($scope.roleSelected !== undefined)
            }, // the second part differentiates between user and requirement modal

            no_tags_necessary () {
              return !$scope.pickTagsAccess &&
              ($scope.pickTagsAccess !== undefined)
            }, // the second part differentiates between user and requirement modal

            no_tags_configured () {
              return !$scope.tm.messages.no_tags_necessary() &&
              !$scope.allowCreate &&
              !$scope.allowDestroy &&
              $scope.tm &&
              $scope.tm.users_tags &&
              ($scope.tm.users_tags.length === 0)
            },

            no_tags_match () {
              return $scope.tm.tag_query &&
              $scope.tm &&
              $scope.tm.filtered_tags &&
              !$scope.tm.filtered_tags.length
            }
          },

          table: {
            show () {
              if (!$scope.tm.messages.no_role_selected() &&
                !$scope.tm.messages.no_tags_necessary() &&
                $scope.tm &&
                $scope.tm.filtered_tags &&
                $scope.tm.filtered_tags.length
              ) {
                return true
              }
            }
          },

          /* FILTER */

          flatten_tree (array: any) {
            let flat_array: any = []

            _.each(array, function (item: any) {
              flat_array.push(item)

              if (item['children'] && (item['children'].length > 0)) {
                return flat_array = flat_array.concat(
                  $scope.tm.flatten_tree(item['children'])
                )
              }
            })

            return flat_array
          },

          tag_passes_filter (tag: any) {
            const search_string = $scope.tm.tag_query.toLowerCase()
            let match = false

            // create and destroy filter
            if ($scope.allowCreate || $scope.allowDestroy) {
              if (search_string === '') {
                match = true
              } else if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
                match = true
              } else if (search_string.toLowerCase() === 'tagged') {
                if ((tag.pending_action === 'create') || ($scope.tm.taggings_include(tag) && (tag.pending_action !== 'destroy'))) {
                  match = true
                }
              }

            // read only filter
            } else {
              if (search_string) {
                if (
                  $scope.tm.taggings_include(tag) &&
                  ((tag.title.toLowerCase()).indexOf(search_string) >= 0)
                ) {
                  match = true
                }
              } else {
                if ($scope.tm.taggings_include(tag)) {
                  match = true
                }
              }
            }

            if (match) {
              $scope.tm.tags_to_show = true
            }

            return match
          },

          // allows for filtering of tags based on the view mode, and permissions
          // if editing then show all tags, and allow "tagged" query
          // if viewing then only the tagged show up. searchs still works
          tag_filter (tag: any) {
            // recursive function that checks the tag and children
            // against tag_passes_filter function
            // takes args
            //   - tag: object checking against
            // returns: true or false

            var tag_or_descendent_passes_filter = function (tag: any) {
              let match = false

              if ($scope.tm.tag_passes_filter(tag)) {
                match = true
              }

              if ((match === false) && tag.children) {
                // Some (Alias: any) Returns true if any of the values in the list
                // pass the predicate truth test. Short-circuits and stops
                // traversing the list if a true element is found.
                match = _.some(tag.children, (tag: any) => tag_or_descendent_passes_filter(tag))
              }

              return match
            }

            // ui = 'read'
            // if !$scope.tm.taggings_include(tag) && !$scope.allowCreate
            //   return false

            // the function that kicks it all off
            // check the top level tags, and recursively the children
            // with itself
            // takes args
            //   - tag: object checking against
            // returns: true or false
            return tag_or_descendent_passes_filter(tag)
          },

          /* ACTIONS */

          pending_transactions: 0,
          failed_transactions: 0,

          increment_pending () {
            return this.pending_transactions++
          },

          decrement_pending () {
            this.pending_transactions--
            return this.evaluate_transaction_status()
          },

          increment_failed () {
            return this.failed_transactions++
          },

          // checks to see if there are any more pending_transactions
          // if not then we are done and can call emit_completion_event
          evaluate_transaction_status () {
            if (this.pending_transactions === 0) {
              return this.emit_completion_event()
            }
          },

          // creates and sets pending_action attribute on the tag.
          // used for icon display
          // used in process_tags function
          // used for validation
          // sets pending_action to:
          //   - undefined if pending action is canceld
          //   - destroy   if tag was exsting and is no longer needed
          //   - create    if tag did not exist and is wanted
          stage_tag (tag: any) {
            if ($scope.uiSetting === 'read') {
              return 'in read mode'
            } else if (tag.pending_action) {
              tag.pending_action = undefined
            } else {
              if ($scope.tm.taggings_include(tag)) {
                tag.pending_action = 'destroy'
              } else {
                tag.pending_action = 'create'
              }
            }

            return $ngModel.$validate()
          },


          //to be used when duplicating a requirement.
          // basically we just move the tags out of taggings_included, and 
          // into the staging area to be created on save
          stageDuplicateTags () {
            if (Array.isArray($scope.stageTaggings) && $scope.stageTaggings.length < 1) {
              return
            }

            var stage_tags_and_children = (tags: any) => {
              _.each(tags, (tag: any) => {
                
                var tag_marked_for_duplication = _.some($scope.stageTaggings, (tagging: any) => tagging.tag.id === tag.id);
                if (tag_marked_for_duplication) {
                  tag.pending_action = 'create'
                }

                if (tag.children) {
                  stage_tags_and_children(tag.children)
                }
              })
            }
            stage_tags_and_children(this.tags)
          },

          // loops over all the tags, then recursively the children
          // with each tag it accesses any pending actions
          // if it needs to be created then it:
          //   - creates a tagging
          //   - sends the tagging to the create_tagging function
          // if it needs to be deleted then it:
          //   - looks up the tagging in $ngModel.$modelValue
          //   - sends the tagging to the destroy_tagging function
          //
          // finally it calls evaluate_transaction_status function in the case
          // that there are no tags to process. Ensures @emit_completion_event
          // is called
          process_tags () {
            var process_tags_and_children = (tags: any) => {
              // loop through all the tags and deal with it
              // https://www.youtube.com/watch?v=fZlEi8Kfsag&feature=youtu.be&t=242
              return _.each(tags, (tag: any) => {
                let tagging
                if (tag.pending_action === 'destroy') {
                  tagging = _.find($ngModel.$modelValue, (tagging: any) => tagging.tag.id === tag.id)
                  this.destroy_tagging(tagging)
                  tag.pending_action === undefined
                }

                if (tag.pending_action === 'create') {
                  tagging = {
                    tag_id: tag.id,
                    // used for better error messages
                    // tag_title not accepted by api
                    tag_title: tag.title,
                    taggable_id: this.taggable_id,
                    taggable_type: $scope.taggableType
                  }
                  this.create_tagging(tagging)
                  tag.pending_action === undefined
                }

                if (tag.children) {
                  return process_tags_and_children(tag.children)
                }
              });
            }

            // process all the top level tags, and recursively the children
            process_tags_and_children(this.tags)

            return this.evaluate_transaction_status()
          },

          // creates tagging.
          // increment_pending action counter
          // on success, pushes tagging into $ngModel.$modelValue
          // on error, emits alert to flashService
          // finally decrement_pending action counter
          create_tagging (tagging: any) {
            this.increment_pending()
            return Restangular.all('taggings').post(tagging).then((success: any) => objectManager.array_action($ngModel.$modelValue, success, 'merge'), (error: any) => {
              const alert = {
                name: `create_error_${tagging.tag_title}`,
                dismissable: true,
                class: 'alert-warning',
                icon: 'fa-exclamation-triangle',
                strong: 'Error:',
                message: 'There was an error creating ' +
                  `tagging '${tagging.tag_title}'. ` +
                  `${flashService.default_alert_by_code(error.status).message}`
              }
              flashService.emit_alert($scope, alert)
              return this.increment_failed()
            }).finally(() => {
              return this.decrement_pending()
            });
          },

          // destroy tagging.
          // increment_pending action counter
          // on success, removes tagging from $ngModel.$modelValue
          // on error, emits alert to flashService
          // finally decrement_pending action counter
          destroy_tagging (tagging: any) {
            this.increment_pending()

            return Restangular.one('taggings', tagging.id).remove().then((success: any) => objectManager.array_action($ngModel.$modelValue, tagging, 'remove'), (error: any) => {
              const alert = {
                name: `delete_error_${tagging.tag.title}`,
                dismissable: true,
                class: 'alert-warning',
                icon: 'fa-exclamation-triangle',
                strong: 'Error:',
                message: 'There was an error removing ' +
                  `tagging '${tagging.tag.title}'. ` +
                  `${flashService.default_alert_by_code(error.status).message}`
              }
              flashService.emit_alert($scope, alert)
              return this.increment_failed()
            }).finally(() => {
              return this.decrement_pending()
            });
          },

          /* VALIDATIONS */

          // counts all the tags for validation
          // loops over all the tags, then recursively the children
          // includes all the tags that are:
          //  - pending to be created
          //  - currently tagged, and not pending a destroy action
          // does not count:
          //  - tags that are not tagged
          //  - tags that are tagged and pending a destroy action
          //
          // Returns a integer
          get_validaton_length () {
            let count = 0

            var count_tags_and_children = (tags: any) => _.each(tags, function (tag: any) {
              if (tag.pending_action === 'create') {
                count = count + 1
              } else if (
                $scope.tm.taggings_include(tag) &&
                (tag.pending_action !== 'destroy')
              ) {
                count = count + 1
              }

              if (tag.children) {
                return count_tags_and_children(tag.children)
              }
            })

            // count top level tags, then children
            count_tags_and_children(this.tags)

            return count
          },

          // pushes custom validators into model validators array
          // works with angular form validation
          add_validators () {
            // ensure get_validaton_length is greater 0
            $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              const { required } = $attributes
              if ((required === false) || (required === undefined)) {
                return true
              } else {
                return this.get_validaton_length() > 0
              }
            }

            // ensure get_validaton_length is greater than minlength
            $ngModel.$validators.minlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.minlength) {
                return true
              } else {
                return this.get_validaton_length() >= $attributes.minlength
              }
            }

            // ensure get_validaton_length is less than maxlength
            return $ngModel.$validators.maxlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.maxlength) {
                return true
              } else {
                return this.get_validaton_length() <= $attributes.maxlength
              }
            };
          },

          add_warners () {
            // the object that we are putting our messages in
            $ngModel.$warnings = {}

            return $ngModel.$validators.minsuggestedlength = (modelValue: any, viewValue: any) => {
              if ($scope.minsuggestedlength !== undefined) {
                const minsuggestedlength = `Please select at least ${$scope.minsuggestedlength} tag`
                // add or remove the warning message
                if (this.get_validaton_length() < $scope.minsuggestedlength) {
                  if (!_.has($ngModel.$warnings, 'minsuggestedlength')) {
                    $ngModel.$warnings.minsuggestedlength = minsuggestedlength
                  }
                } else {
                  if (_.has($ngModel.$warnings, 'minsuggestedlength')) {
                    delete $ngModel.$warnings.minsuggestedlength
                  }
                }

                // syncronate the warnings with the view
                this.warnings = $ngModel.$warnings
              }

              // returning true will keep this validator from causing
              // the manager to error out. We only want a warning not a error
              return true
            };
          },

          /* WATCHERS */

          /* WATCHERS, LISTENERS AND EMITTERS */
          listen_for_start_event () {
            return $scope.$on('tagging_manager_start', (event: any, data: any) => {
              // set the requirement_id because previously we may not have had it
              this.taggable_id = data.id

              if (!this.taggable_id) {
                // tags may be attached to a note in a list- try find the id before moving on
                this.taggable_id = this.try_find_id_in_list(data)
              }
              // reset the failed_transactions so that we
              // have a chance of fixing the issue and trying again
              this.failed_transactions = 0
              // now on to the saving
              return this.process_tags()
            });
          },

          watch_for_tags_to_stage () {
            return $scope.$watch(() => $scope.stageTaggings, (new_value: any, old_value: any, scope: any) => {
              if (Array.isArray($scope.stageTaggings) && $scope.stageTaggings.length > 0) {
                this.stageDuplicateTags()
              }
            });
          },

          try_find_id_in_list (list: any, current_level = 0, max_depth = 4) {
            let l1 = list[Object.keys(list)[0]]

            if (l1.id) {
              return l1.id
            } else {
              return this.try_find_id_in_list(l1, current_level++, max_depth)
            }
          },

          emit_completion_event () {
            const return_object = {
              manager: 'tagging_manager',
              object: $ngModel.$modelValue,
              fault: this.failed_transactions > 0
            }
            return $scope.$emit('tagging_manager_finished', return_object)
          },

          // this syncronizes the $ngModel.$modelValue and @ng_model_value
          // we use @ng_model_value in the view exclusively
          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
              $scope.tm.ng_model_value = $ngModel.$modelValue
            });
          }

        }

        $scope.abortAllPromises = (promiseArray: any) => {
          if (Array.isArray(promiseArray) && promiseArray.length > 0) {
            promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
          }
          promiseArray.length = 0
        }

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.tm.initialize()
            return ng_model_ready = true
          }
        })

        $scope.$watch('filterByLobs',
          function (newValue: any, oldValue: any) {
            $scope.tm.get_tags()
          }, true
        )

        // TODO move this into it's own function, call on init
        return $scope.$watch(() => $scope.minsuggestedlength, function (new_value: any, old_value: any, scope: any) {
          if (new_value !== old_value) {
            return $ngModel.$validate()
          }
        });


      }
    };
  }
])
