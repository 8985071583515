import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import * as Restangular from '../../../vendor/restangular/restangular'
import { helpService } from '../../../scripts/services/help';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';

@Component({
  selector: 'ng-requirement-integrations-editor',
  templateUrl: './requirement-integrations-editor.component.html',
  styleUrls: ['./requirement-integrations-editor.component.scss']
})
export class RequirementIntegrationsEditorComponent implements OnInit {

  @Input() uiSetting: string = 'update';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()

  //Injected Serivces
  public session: any;

  //Properties
  form: FormGroup
  formReady: boolean = false;
  mailbox_pairing_key_copied: boolean = false;
  ValidationHelpers = ValidationHelpers

  constructor(
    public controlContainer: ControlContainer,
    @Inject('$rootScope') private $rootScope,
    @Inject('fortyCore') private fortyCore: any,    // Could not be 'class'-ified so use a string as injection key
    @Inject(helpService) private helpService: any,
    private Restangular: Restangular,
  ) {
    this.session = $rootScope.session
  }

  ngOnInit(): void {
  }
}
