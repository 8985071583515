// /* eslint-disable
//     camelcase,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('homeController', ['$rootScope', '$scope', function (
//   $rootScope: any,
//   $scope: any
// ) {
//   const init = function () {
//     // wait for the session to load, there may be a race type conditon
//     $scope.user = $rootScope.session.getUser()
//     waitForHappy()
//   }

//   // TODO: do we need to deregister this?
//   var waitForHappy = function () {
//     $rootScope.$watch(() => $rootScope.session, (new_value: any, old_value: any, scope: any) => {
//       if (new_value.data && new_value.data.happy) {
//         organization_preflight_check()
//         tags_preflight_check()
//       }
//     }, true)
//   }

//   var organization_preflight_check = function () {
//     if ($rootScope.session.getUser().user_role.role.orgs_access === 'some') {
//       if ($rootScope.session.getUser().organizations.length === 0) {
//         $rootScope.$broadcast(
//           'add_global_alert',
//           {
//             name: 'organization_preflight_error',
//             dismissable: true,
//             class: 'alert-warning',
//             strong: null,
//             message: 'Currently, you are not added to any organizations. ' +
//                           'If you believe this to be an error, ' +
//                           'contact your Organization Admin for a resolution.'
//           }
//         )
//       }
//     }
//   }

//   var tags_preflight_check = function () {
//     if (
//       ($rootScope.session.getUser().user_role.role.tags_access === 'some') &&
//       ($rootScope.session.getUser().taggings.length === 0)
//     ) {
//       return $rootScope.$broadcast(
//         'add_global_alert',
//         {
//           name: 'tags_preflight_error',
//           dismissable: true,
//           class: 'alert-warning',
//           strong: 'Alert',
//           message: 'Currently, you do not have any tags. ' +
//                         'If you believe this to be an error, ' +
//                         'contact your Organization Admin for a resolution.'
//         }
//       )
//     }
//   }

//   return init()
// }])
