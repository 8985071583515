/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

  var OrganizationNeededPromptComponent = {
  selector: "organizationNeededPrompt",
  template: require('../../views/directives/organization_needed_prompt.pug'),
  transclude: true,
  bindings: {
    interactibleOrgsOnly: '<'
  },

  controller: class OrganizationNeededPromptController {
    // Injected Services
    private session: any;

    // Properties
    private interactibleOrgsOnly: any;
    private orgNeededPrompt: any;

    static $inject = ['$rootScope', '$scope', 'arrayFunctions', 'constants', 'fortyCore', 'Restangular', 'storeService', 'utils'];
    constructor(private $rootScope, private $scope, private arrayFunctions, private constants, private fortyCore, private Restangular, private storeService, private utils
    ) {
    }

    $onInit = () => {
      this.orgNeededPrompt = {
        // hasActiveOrg: false,
        meetsOrgAccessRequirments: false,
        selectedOrgId: null,
        organizationsList: [],
        selectedOrganization: [],
        orgSelectorCollapsed: true
      }
  
      if (this.$rootScope.session.activeOrganizationPresent()) {
        this.orgNeededPrompt.selectedOrganization = [{ ticked: true, displayName: this.$rootScope.session.getOrganization().display_name, value: this.$rootScope.session.getOrganization().id }]
      }
  
      this.checkForOrgAccessRequirements()
    }
  
    checkForOrgAccessRequirements = () => {
      let hasActiveOrg = this.$rootScope.session.activeOrganizationPresent()
      let interactibleOrgPresent = this.$rootScope.session.activeOrganizationInteractable()
      this.orgNeededPrompt.meetsOrgAccessRequirments = hasActiveOrg && (!this.interactibleOrgsOnly || interactibleOrgPresent)
    }
  
    organizationsApiSearchFunction = (args: any) => {
      let loadedCallback = args.loadedCallback
      const params = {
        param_data: {
          text_search: args.apiSearchInputString,
          // pagination_params: [{ page: 1, pageSize: 50 }]
        }
      }
      // let params = { param_data: { /* if they want visibility, defualt it here */ } }
      return this.Restangular.all('organizations/filter_names').getList(params).then(function (success: any) {
        let response = {items: success.plain()}
  
        let searchResults:any = {}
        if (response && Array.isArray(response.items) && response.items.length > 0) {
          searchResults = response
          
          var recursive_map_fn = (i: any) => {
            return {
              id: i.id,
              value: i.id,
              displayName: i.name,
              children: i.children.map(recursive_map_fn),
              has_children: i.has_children
            }
          }
          searchResults.items = searchResults.items.map(recursive_map_fn)
        } else {
          searchResults = {}
        }
        
        loadedCallback(searchResults)
      }, function (error: any) {
        return this.utils.log(error)
      });
    }
  
    handleOrganiztionChange = () => {
      let selectedOrgs = this.orgNeededPrompt.selectedOrganization;
      if (Array.isArray(selectedOrgs) && selectedOrgs.length === 1) {
        this.orgNeededPrompt.selectedOrgId = selectedOrgs[0].value
      } else {
        this.orgNeededPrompt.selectedOrgId = null
      }
    }
  
    setActiveOrg = () => {
      let id = this.orgNeededPrompt.selectedOrgId;
  
      if (!id) {
        return
      }
  
      this.Restangular.one(`organizations/${id}`).get().then((response: any) => {
        this.$rootScope.session.setOrganization(response)
      }).then(() => {
        this.checkForOrgAccessRequirements()
        this.orgNeededPrompt.orgSelectorCollapsed = this.orgNeededPrompt.meetsOrgAccessRequirments
      })
    }
  
    isNavigationLocked = () => {
      return this.$rootScope.session.isNavigationLocked()
    }
    }
  }
angular
.module('complyosClient')
.component(OrganizationNeededPromptComponent.selector, OrganizationNeededPromptComponent);