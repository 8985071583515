/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import * as _ from 'lodash';


angular.module('complyosClient').directive('treeStructure', [
  '$rootScope',
  'helpService',
  'flashService',
  'loadingStateService',
  'fortyCore',
  'Restangular',
  'utils', 
    ($rootScope, helpService, flashService, loadingStateService, fortyCore, Restangular, utils) => ({
    templateUrl: 'views/directives/organization_tree_structure.html',
    restrict: 'E',

    scope: {
      structureScheme: '@'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      const init = function () {
        // service instantiation
        
        $scope.flashService = angular.copy(flashService)
        
        $scope.loadingStateService = angular.copy(loadingStateService)
        $scope.processingStateService = fortyCore.processingStateService
        $scope.text = fortyCore.text
        $scope.loadingStateService.init()
        // varible instantiation
        $scope.query = ''
        const { structureScheme } = $scope
        $scope.allow_independent_toggle = true

        // functin instantiation

        return get_tree_list(structureScheme)
      }

      // grab structureScheme list
      var get_tree_list = (structureScheme: any) => Restangular.all(structureScheme).getList().then(function (success: any) {
        $scope.loadingStateService.process_success(success)
        $scope.organizations = success
        $scope.flat_organizations = flatten_organizations(success)
        return set_show_property()
      }

      , function (error: any) {
        $scope.loadingStateService.process_error(error)
        return utils.log(error)
      })

      var set_show_property = () =>
        // set default show property on each organization
        _.each($scope.organizations, (organization: any) => organization.show = false)

      // change active organization
      $scope.attempt_change_organization = (organization: any) => $scope.processingStateService.performFunction(organization, function () {
        $rootScope.session.setOrganization(organization)
        $rootScope.session.goTo('/home')
      })

      $scope.org_filter = function (organization: any) {
        const search_string = $scope.query.toLowerCase()
        let match = false

        if (search_string === '') {
          match = true
        } else if (organization.name.toLowerCase().indexOf(search_string) >= 0) {
          match = true
        } else if (organization.identifier && ((organization.identifier.toLowerCase()).indexOf(search_string) >= 0)) {
          match = true
        } else if (organization.org_type && ((organization.org_type.toLowerCase()).indexOf(search_string) >= 0)) {
          match = true
        } else if (organization.region && ((organization.region.toLowerCase()).indexOf(search_string) >= 0)) {
          match = true
        } else if (organization.locality && ((organization.locality.toLowerCase()).indexOf(search_string) >= 0)) {
          match = true
        }

        return match
      }

      var flatten_organizations = function (organizations_array: any) {
        const flattened_organizations: any = []

        var recursively_flatten = (organizations: any) => _.each(organizations, function (org: any) {
          flattened_organizations.push(org)

          if (org.children) {
            return recursively_flatten(org.children)
          }
        })

        recursively_flatten(organizations_array)

        return flattened_organizations
      }

      $scope.organization_filter = function (organization: any) {
        // recursive function that checks the organization and children
        // against organization_passes_filter function
        // takes args
        //   - organization: object checking against
        // returns: true or false

        var organization_or_descendent_passes_filter = function (organization: any) {
          let match = false

          if ($scope.org_filter(organization)) {
            match = true
            if ($scope.allow_independent_toggle === false) {
              organization.show = false
            }

            if ($scope.query.length === 0) {
              $scope.allow_independent_toggle = true
            } else {
              $scope.allow_independent_toggle = false
            }
          }

          if ((match === false) && organization.children) {
            // Some (Alias: any) Returns true if any of the values in the list
            // pass the predicate truth test. Short-circuits and stops
            // traversing the list if a true element is found.
            match = _.some(organization.children, (organization: any) => organization_or_descendent_passes_filter(organization))

            organization.show = match
          }

          return match
        }

        return organization_or_descendent_passes_filter(organization)
      }

      return init()
    }
  })

])
