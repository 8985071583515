/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from "angular";

export class pdfPreviewModalController implements angular.IController {
  static $inject = ['$uibModalInstance', '$scope', 'modalOptions', 'utils', 'fileService'];

  $onInit() { }

  constructor(
    $uibModalInstance: any,
    $scope: any,
    modalOptions: any,
    utils: any,
    fileService: any) {
    const init = function () {
      $scope.initReady = false
      $uibModalInstance.rendered.then(() => $scope.initReady = true)
      $scope.file = modalOptions.file_object

      // We need to support two types of items,
      // 1) documents coming from our API with url attributes
      if ($scope.file.url) {
        $scope.objectURL = $scope.file.url
        $scope.file.name = $scope.file.document_file_name

        // 2) documents uploaded locally, (we need to create the url)
      } else {
        return $scope.objectURL = window.URL.createObjectURL($scope.file)
      }
    }

    $scope.close_modal = () => $uibModalInstance.dismiss('cancel')

    init()
  }
}
angular
  .module('complyosClient')
  .controller('pdfPreviewModalController', pdfPreviewModalController)

// function pdfPreviewModalController implements  (
//     $uibModalInstance: any,
//     $scope: any,
//     modalOptions: any,
//     utils: any,
//     fileService: any
// ) {
//     const init = function () {
//         $scope.initReady = false
//         $uibModalInstance.rendered.then(() => $scope.initReady = true)
//         $scope.file = modalOptions.file_object

//         // We need to support two types of items,
//         // 1) documents coming from our API with url attributes
//         if ($scope.file.url) {
//             $scope.objectURL = $scope.file.url
//             $scope.file.name = $scope.file.document_file_name

//             // 2) documents uploaded locally, (we need to create the url)
//         } else {
//             return $scope.objectURL = window.URL.createObjectURL($scope.file)
//         }
//     }

//     $scope.close_modal = () => $uibModalInstance.dismiss('cancel')

//     return init()
// }
// pdfPreviewModalController.$inject = ['$uibModalInstance', '$scope', 'modalOptions', 'utils', 'fileService'];
// complyosClient.controller('pdfPreviewModalController', pdfPreviewModalController)
// -----

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('pdfPreviewModalController', ['$uibModalInstance', '$scope', 'modalOptions', 'utils', 'fileService', function (
//   $uibModalInstance: any,
//   $scope: any,
//   modalOptions: any,
//   utils: any,
//   fileService: any
// ) {
//   const init = function () {
//     $scope.initReady = false
//     $uibModalInstance.rendered.then(() => $scope.initReady = true)
//     $scope.file = modalOptions.file_object

//     // We need to support two types of items,
//     // 1) documents coming from our API with url attributes
//     if ($scope.file.url) {
//       $scope.objectURL = $scope.file.url
//       $scope.file.name = $scope.file.document_file_name

//     // 2) documents uploaded locally, (we need to create the url)
//     } else {
//       return $scope.objectURL = window.URL.createObjectURL($scope.file)
//     }
//   }

//   $scope.close_modal = () => $uibModalInstance.dismiss('cancel')

//   return init()
// }])
