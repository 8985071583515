/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from "angular";

export class usersPermissionsController implements angular.IController {
    static $inject = ['$state', '$scope', 'loadingStateService', 'Restangular', 'utils'];

    $onInit() { }

    constructor(
        $state: any,
        $scope: any,
        loadingStateService: any,
        Restangular: any,
        utils: any) {
        /* LOADING FEEDBACK */

        $scope.loadingStateService = angular.copy(loadingStateService)
        $scope.loadingStateService.init()

        /* RESTANGULAR */
        const getPermissions = () =>
            Restangular.all('users').get($state.params.user_id).then(function (response: any) { // success
                $scope.loadingStateService.process_success(response)
                return $scope.permissions = extract_permissions(response)
            }
                , function (error: any) { // error
                    $scope.loadingStateService.process_error(error)
                    return utils.log(error)
                })

        getPermissions()

        /* FUNCTIONS */
        var extract_permissions = function (user_object: any) {
            const { permission } = user_object

            delete permission.id
            delete permission.permissible_id
            delete permission.permissible_type
            delete permission.created_at
            delete permission.updated_at

            return permission
        }

        /* Queries */
        $scope.permissionQuery = ''
    }
}
angular
    .module('complyosClient')
    .controller('usersPermissionsController', usersPermissionsController)


// ------------------------------------

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('usersPermissionsController', ['$state', '$scope', 'loadingStateService', 'Restangular', 'utils', function (
//   $state: any,
//   $scope: any,
//   loadingStateService: any,
//   Restangular: any,
//   utils: any
// ) {
//   /* LOADING FEEDBACK */
  
//   $scope.loadingStateService = angular.copy(loadingStateService)
//   $scope.loadingStateService.init()

//   /* RESTANGULAR */
//   const getPermissions = () =>
//     Restangular.all('users').get($state.params.user_id).then(function (response: any) { // success
//       $scope.loadingStateService.process_success(response)
//       return $scope.permissions = extract_permissions(response)
//     }
//     , function (error: any) { // error
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     })

//   getPermissions()

//   /* FUNCTIONS */
//   var extract_permissions = function (user_object: any) {
//     const { permission } = user_object

//     delete permission.id
//     delete permission.permissible_id
//     delete permission.permissible_type
//     delete permission.created_at
//     delete permission.updated_at

//     return permission
//   }

//   /* Queries */
//   return $scope.permissionQuery = ''
// }])
