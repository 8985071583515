'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('ajsRequirementVersionHistoryModalAdapter', ['$uibModalInstance', '$scope', 'modalOptions', function (
  $uibModalInstance: any,
  $scope: any,
  modalOptions: any
) {
  const initialize = function () {
    $scope.requirementId = modalOptions.requirementId
    $scope.requirement = modalOptions.requirement
  }

  $scope.dismiss = () => {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.close = (result) => {
    $uibModalInstance.close(result)
  }

  initialize()
}])
