import { Component, Inject, OnInit, Input, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import _ from 'lodash';
import angular from 'angular';
var dayjs = require("dayjs")

@Component({
  selector: 'ngDateRangeFilter',
  templateUrl: './date-range-filter.component.html'
})
export class DateRangeFilter implements OnInit {
  @ViewChild('picker') picker: MatDatepicker<Date>
  @Input() binderLss: any;
  // Injected Services
  private loadingStateService: any;
  private session: any;
  dateRangeForm: FormGroup;
  formReady:boolean = false;

  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('loadingStateService') loadingStateService: any,
    ) 
    {

    this.loadingStateService = angular.copy(loadingStateService)
    }
  
  private custom_range = {
    date_range_begin: null,
    date_range_end: null
  }
  private date_range = {
    date_range_begin: dayjs().subtract(10, 'years').$d,
    date_range_end: dayjs().$d
  }
  private date_params = {
    date_range_begin: dayjs().subtract(10, 'years').$d,
    date_range_end: dayjs().$d
  }
  private selected_preset = '1yr'
  private preset_date_ranges = [
    {
      key: '1yr',
      name: 'Last 12 Months',
      range: {
        date_range_begin: dayjs().subtract(1, 'years').$d,
        date_range_end: dayjs().$d
      }
    },
    {
      key: '2yrs',
      name: 'Last 2 Years',
      range: {
        date_range_begin: dayjs().subtract(2, 'years').$d,
        date_range_end: dayjs().$d
      }
    },
    {
      key: '3yrs',
      name: 'Last 3 Years',
      range: {
        date_range_begin: dayjs().subtract(3, 'years').$d,
        date_range_end: dayjs().$d
      }
    },
    {
      key: 'all',
      name: 'All',
      range: {
        date_range_begin: dayjs().subtract(10, 'years').$d,
        date_range_end: dayjs().$d
      }
    },
    {
      key: 'custom',
      name: 'Custom',
      range: {
        date_range_begin: dayjs().subtract(1, 'years').$d,
        date_range_end: dayjs().$d
      }
    }
  ]

  ngOnInit(): void {
    this.loadingStateService = angular.copy(this.loadingStateService)
    this.loadingStateService.init()
    this.select_preset_date_range(this.preset_date_ranges[0])
    this.watch_reset_date_filter()
    this.watch_reset_active_preset()
  }

  onCustomDateRangeSelected(dateRange: any) {
    // store them for later and we can remit if they have already been entered and a user clicks on "custom"
    this.custom_range.date_range_begin = dayjs(dateRange.start).format('YYYY-MM-DD')
    this.custom_range.date_range_end = dayjs(dateRange.end).format('YYYY-MM-DD')
    this.date_params.date_range_begin = this.custom_range.date_range_begin
    this.date_params.date_range_end = this.custom_range.date_range_end
    this.emit_date_filter_object()
  }

  select_preset_date_range = (preset: any) => {
    if (!preset.active) {
      this.reset_active_preset()
      preset.active = true
      this.selected_preset = preset.key
      if (this.selected_preset !== 'custom') {
        this.date_params = {
          date_range_begin: dayjs(preset.range.date_range_begin).format('YYYY-MM-DD'),
          date_range_end: dayjs(preset.range.date_range_end).format('YYYY-MM-DD')
        }
        this.emit_date_filter_object()
      } else if (this.selected_preset == 'custom' && this.custom_range.date_range_begin && this.custom_range.date_range_end) {
        this.date_params = {
          date_range_begin: this.custom_range.date_range_begin,
          date_range_end: this.custom_range.date_range_end
        }
        this.emit_date_filter_object()
      }
    }
  }

  watch_reset_date_filter = () => {
    return this.$rootScope.$on('reset_date_filter', (scope: any, object: any) => {
      this.select_preset_date_range(this.preset_date_ranges[0])
    });
  }

  watch_reset_active_preset = () => {
    return this.$rootScope.$on('reset_active_preset', (scope: any, object: any) => {
      this.reset_active_preset()
    });
  }

  reset_active_preset = () => {
    _.each(this.preset_date_ranges, (p: any) => p.active = undefined)
  }

  emit_date_filter_object = () => {
    this.$rootScope.$emit('date_filter_updated', this.date_params)
  }
}

angular
  .module('complyosClient')
  .directive('ngDateRangeFilter', downgradeComponent({ component: DateRangeFilter }) as angular.IDirectiveFactory)