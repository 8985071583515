import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { objectManager } from '../../../../scripts/services/ajs-object_manager';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import { FlashService } from '../../../services/flash_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import { BinderService } from '../../../services/broadcast-services/binder.service';
import { Subscription } from 'rxjs';
import { EntryModalComponent } from '../../modals/entry-modal/entry-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ngDocumentation',
  templateUrl: './documentation.component.html'
})
export class DocumentationComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private entries: any;
  private binder: any;
  private bindersLoaded: boolean = false;

  // subscriptions
  private binderUpdateSubscription: Subscription;
  private entryApiCallSubscription: Subscription;


  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('$state') private $state,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(objectManager) private objectManager: objectManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private arrayFunctions: ArrayFunctions,
    private binderService: BinderService,
    private dialog: MatDialog
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    // handle entry api call trigger
    this.entryApiCallSubscription = this.binderService.entryApiCallTrigger$.subscribe(() => this.getEntries())
    // handle the binderObject data from binder.service
    this.binderUpdateSubscription = this.binderService.binderUpdate$.subscribe(binderObject => {
        this.binder = binderObject
      }
    )
    this.loadingStateService.init()

    this.getBinder()
    this.getEntries()
  }

  getBinder = () => {
    // there might allready be a binder
    this.loadingStateService.set_state('loading')
    if (!this.binder) {
      return this.Restangular.all('binders').get(this.$state.params.binder_id).then((response: any) => {
        this.binder = response
        this.loadingStateService.process_success(response)
      }, (error: any) => this.utils.log(error, 'error', false));
    } else {
      this.loadingStateService.set_state('content')
    }
  }

  getEntries = () => {
    this.loadingStateService.set_state('loading')
    this.Restangular.all(`binders/${this.$state.params.binder_id}/entries_stubs`).getList().then((response: any) => { // success
      this.loadingStateService.process_success(response)
      return this.entries = response
    }
    , (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  isScheduled = (entry: any) => entry.date_submission_window_open && entry.date_submission_window_close

  isRequiresResubmission = (entry: any) => entry.complete_status === 'requires_resubmission'

  openEntryModal = (entryObject: any, uiSetting: any) => {
    const dialogRef = this.dialog.open(EntryModalComponent, {
      width: '600px',
      data: { entryObject: entryObject, uiSetting: uiSetting }
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'close' || result === undefined || result === 'outsideClick') {
        this.processingStateService.set(entryObject, this.processingStateService.processing_states.RESTING)
        return
      }
      if (uiSetting === 'create') {
        this.objectManager.array_action(this.entries, result, 'merge')
        if (this.loadingStateService.loading_state.name === 'nocontent') {
          this.loadingStateService.set_state('content')
        }
      } else if (uiSetting === 'update') {
        this.objectManager.array_action(this.entries, result, 'update')
      }
      this.binderService.triggerBinderApiCall()
      this.processingStateService.set(entryObject, this.processingStateService.processing_states.RESTING)
    })
  }

  openEntryCreateModal = () => {
    const entry = {
      binder: this.binder,
      binder_id: this.$state.params.binder_id,
      created_by: 'user',
      complete_status: 'incomplete',
      attachments: [],
      notes: [],
      state: {
        inactive: null
      }
    }
    return this.openEntryModal(entry, 'create')
  }

  reloadEntryAndOpenModal = (entry, ui_setting, fn) => {
    // s15-c2556-HOTFIX: this reloading the entry should be down inside the modal (wherea spinner is shown)
    //    but alas, this is a hotfix, so this is the quickest way to get it working again
    return this.Restangular.all('entries').get(entry.id).then((response: any) => { // success
      let full_entry = response.plain()
      fn(full_entry, ui_setting)
    }, (error: any) => this.utils.log(error, 'error', false))
  }

  openEntryReadModal = (entry: any) => {
    // openEntryModal(entry, 'read')
    this.reloadEntryAndOpenModal(entry, 'read', this.openEntryModal)
  }

  openEntryUpdateModal = (entry: any) => {
    // openEntryModal(entry, 'update')
    this.reloadEntryAndOpenModal(entry, 'update', this.openEntryModal)
  }

  openEntryDestroyModal = (entry: any) => {
    const confirmation_object = {
      // severity: severity string
      // title: ti   tle string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm: () => { return this.destroyEntry(entry) },
      message: `<p>This action will:</p> \
<ul> \
<li>Delete the entry for ${entry.event_date}</li> \
</ul> \
<p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(entry, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then((result: any) => {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          this.processingStateService.set(
            entry,
            this.processingStateService.processing_states.RESTING
          )
        }
        return true
      })
    );
  }

  destroyEntry = (entry: any) => this.Restangular.all(`entries/${entry.id}`).remove().then((success: any) => this.objectManager.array_action(this.entries, entry, 'remove'), (error: any) => {
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', false)
  }).finally(() => {
    this.processingStateService.set(
      entry,
      this.processingStateService.processing_states.RESTING
    )
  })

  ngOnDestroy() {
    if (this.binderUpdateSubscription) {
      this.binderUpdateSubscription.unsubscribe();
    }

    if (this.entryApiCallSubscription) {
      this.entryApiCallSubscription.unsubscribe();
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngDocumentation', downgradeComponent({ component: DocumentationComponent }) as angular.IDirectiveFactory)
