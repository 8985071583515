// /* eslint-disable
//   no-return-assign,
//   no-undef,
//   semi,
//   operator-linebreak,
//   no-unused-vars,
//   eqeqeq
// */
// 'use strict'


// angular.module('complyosClient').directive('queryTooLargeWarning', [
//   'fortyCore', 'constants', 'arrayFunctions', 'helpService',
//   (fortyCore, constants, arrayFunctions, helpService) => ({
//     templateUrl: 'views/directives/query_too_large_warning.html',
//     restrict: 'E',
//     transclude: true,

//     scope: {
//       isPopoutOpen: '=',
//       deferredFn: '&'
//     },

//     link ($scope: any, $ele: any, $attrs: any) {
//       $scope = this.registerFunctions($scope)

//       // $scope.popout = {
//       //   isOpen: true
//       // }

//       // $scope.isPopoutOpen = true

//       $scope.helpService = helpService
//       return $scope.fortyCore = fortyCore;
//     },

//     registerFunctions ($scope: any) {
//       $scope.togglePopout = () => {
//         $scope.isPopoutOpen = !$scope.isPopoutOpen
//       }

//       $scope.executeDeferredFn = () => {
//         $scope.deferredFn()
//       }

//       return $scope
//     }
//   })
// ])
