/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'


angular.module('complyosClient').directive('nodeTreeBuilder', [
  'fortyCore', 'arrayFunctions', 'helpService', 'Restangular',
  (fortyCore, arrayFunctions, helpService, Restangular) => ({
    templateUrl: 'views/directives/node_tree_builder.html',
    restrict: 'E',

    scope: {
      onOutputChange: '&',
      applyCurrentNodePath: '&',
      loadNextLevelFunction: '&',
      nodeTreeBuilderConfigConfig: '=',
      descendantsName: '<',
      outputNodePath: '='
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
      $scope.nodeTreeBuilder = {
        isLoading: true,
        isPopoutOpen: false,
        searchInput: '',
        nodePath: [],
        currentNodeList: []
      }
      // $scope.itemPluralName = $scope.itemPluralName || 'items'
      $scope.updateOutputNodePath($scope.outputNodePath || [])

      $scope.loadNextLevel([])

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.updateOutputNodePath = (updated: any) => {
        $scope.outputNodePath = updated
        setTimeout(() => { $scope.onOutputChange() }, 100)
      }

      $scope.applyNodePath = () => {
        $scope.nodeTreeBuilder.isPopoutOpen = false;
        $scope.applyCurrentNodePath()
        $scope.reset()
      }

      $scope.reset = () => {
        $scope.updateOutputNodePath([])
        $scope.nodeTreeBuilder.currentNodeList = []
        $scope.nodeTreeBuilder.searchInput = ''
        $scope.loadNextLevel([]);
      }

      $scope.openNodeTreeBuilderPopout = () => {
        $scope.toggleNodeTreeBuilderPopout(true);
        setTimeout(() => { document.getElementById('node-tree-search-input').focus() }, 50)
      }

      $scope.toggleNodeTreeBuilderPopout = (isOpen = null) => {
        if (isOpen !== null) {
          $scope.nodeTreeBuilder.isPopoutOpen = isOpen
        } else {
          $scope.nodeTreeBuilder.isPopoutOpen = !$scope.nodeTreeBuilder.isPopoutOpen
        }
      }

      // $scope.nullishCoalesce = (check: any, defaultTo: any) => {
      //   if (check !== null && check != undefined) {
      //     return check
      //   }
      //   return defaultTo
      // }

      $scope.selectNextNode = (node: any) => {
        let path = JSON.parse(JSON.stringify($scope.outputNodePath))
        path.push(node)
        $scope.updateOutputNodePath(path)

        let pathIdentifierArray = $scope.outputNodePath.map((n: any) => n.identifier)
        $scope.loadNextLevel(pathIdentifierArray)
      }

      $scope.loadNextLevel = (currentNodePath: any) => {
        $scope.nodeTreeBuilder.searchInput = ''
        $scope.nodeTreeBuilder.currentNodeList = []
        $scope.nodeTreeBuilder.isLoading = true
        let promise = $scope.loadNextLevelFunction({ currentNodePath, loadedCallback: $scope.onNextLevelLoaded })
        if (promise) {
          promise.finally((p: any) => $scope.nodeTreeBuilder.isLoading = false)
        }
      }

      $scope.onNextLevelLoaded = (nextLevelChildren: any) => {
        $scope.nodeTreeBuilder.currentNodeList = nextLevelChildren
      }

      $scope.truncateNodePath = (indexToRemove: any) => {
        $scope.outputNodePath.length = indexToRemove
        $scope.updateOutputNodePath($scope.outputNodePath)

        let pathIdentifierArray = $scope.outputNodePath.map((n: any) => n.identifier)
        $scope.loadNextLevel(pathIdentifierArray)
      }

      $scope.filterChanged = () => {
        let searchText = $scope.nodeTreeBuilder.searchInput || ''
        searchText = searchText.toUpperCase()
        let matches = $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => n.identifier.toUpperCase().includes(searchText))
        if (matches.length == 1 && matches[0].identifier.toUpperCase() == searchText) {
          let exactMatch = matches[0]
          $scope.selectNextNode(exactMatch)
        }
      }

      return $scope
    }
  })
])
