import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userfilter',
  pure: false
})
export class ArrayUserFilterPipe implements PipeTransform {

  transform(array: any, search_parameters: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    if (!search_parameters) {
      return array;
    }

    const search_string = search_parameters.toLowerCase()

    // if blocks below set standard for filtering users across application
    // search by: display name, role, first name, last name, email, username
    return array.filter(user => {
      if ((user.email.toLowerCase()).indexOf(search_string) >= 0) {
        return true
      }
      if ((user.username.toLowerCase()).indexOf(search_string) >= 0) {
        return true
      }
      if ((user.profile.display_name.toLowerCase()).indexOf(search_string) >= 0) {
        return true
      }
      if (
        (user.user_role.role.title !== null) &&
        (user.user_role.role.title !== undefined) &&
        ((user.user_role.role.title.toLowerCase()).indexOf(search_string) >= 0) &&
        (user.user_role.role.title !== 'Tech Support')
      ) {
        return true
      }
      if (
        (user.profile.name_first !== null) &&
        (user.profile.name_first !== undefined) &&
        ((user.profile.name_first.toLowerCase()).indexOf(search_string) > 0)
      ) {
        return true
      }
      if (
        (user.profile.name_last !== null) &&
        (user.profile.name_last !== undefined) &&
        ((user.profile.name_last.toLowerCase()).indexOf(search_string) >= 0)
      ) {
        return true
      }
    })
  }

}
