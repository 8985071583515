import { Component, EventEmitter, Inject, Injectable, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { objectManager } from '../../../scripts/services/ajs-object_manager';
import _ from 'lodash';
// import { fortyCore } from '../../../scripts/services/forty_core.service';
import { fortyCore } from '../../../scripts/services/fortyau_services';
import * as Restangular from '../../../../app/vendor/restangular/restangular'
import { FlashService } from '../../services/flash_service.service';

@Component({
  selector: 'ngReferenceManager', // <ng-reference-manager/>
  templateUrl: './reference-manager.component.html'
})
export class ReferenceManager implements OnInit, OnDestroy {

  // BINDINGS
  @Input() allowCreate: boolean;
  @Input() allowDestroy: boolean;
  @Input() referenceableId: number;
  @Input() referenceableType: string; 
  @Input() uiSetting: string;
  @Input() formModel: any;

  // PROPERTIES
  xxx: any = [];
  pending_transactions: number = 0;
  failed_transactions: number = 0;
  search_params: { query: string; } = { query: '' };
  search_result: any = {};
  processingStateService: any;
  unregister_ajs_reference_save_listener

  // DEPENDENCY INJECTIONS
  constructor(
    @Inject(FlashService) private flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,    // Could not be 'class'-ified so use a string as injection key
    private objectManager: objectManager,
    private Restangular: Restangular,
    @Inject('$scope') private $scope
    ) {    
    this.processingStateService = fortyCore.processingStateService;
  }

  ngOnInit() {
    this.populate_xxx()
    this.search_items() // ran on init to get @add_result_to_xxx button working
    this.listen_for_start_event()
  }
  ngOnDestroy(): void {
    // Angular $scope does not get destroyed when using tabs
    // must manually unregister
    if (this.unregister_ajs_reference_save_listener) {
      this.unregister_ajs_reference_save_listener()
    }
  }


  // FUNCTIONS
  populate_xxx() {
    // populate_xxx with what we have

    this.xxx = angular.copy(this.formModel)

    // Watch for changes and repopulate
    return this.$scope.$watch(() => this.formModel
      , (newValue: any, oldValue: any) => {

        return this.xxx = angular.copy(this.formModel)
      }
      , true);
  }

  /* WATCHERS, LISTENERS AND EMITTERS */

  // chain of events for the saving:
  // 1. the reference manager is alerted to start
  // 2. The process_items() method is called to resolve any pending_actions
  // 3. before anything it runs evaluate_transaction_status(), which sees if pending_transactions is 0
  //     if it is, we skip everything and finish out the manager.
  //     else, we loop over the transactions and handle as necessary

  listen_for_start_event() {
    if (this.uiSetting != 'read') {
      this.unregister_ajs_reference_save_listener = this.$scope.$on('reference_manager_start', (event: any, data: any) => {
        // set the referenceableId because we may not have had it
        this.referenceableId = data.id
        // reset the failed_transaction counter
        this.failed_transactions = 0
        // now on to the saving
        return this.process_items()
      });
    }
  }

  count_of_pending_transactions() {
    let transaction_count = _.filter(this.xxx, (item) => item.pending_action).length
    return transaction_count
  }

  process_items() {
    this.pending_transactions = this.count_of_pending_transactions()

    this.evaluate_transaction_status()

    var pending_items = _.filter(this.xxx, (item) => item.pending_action === 'create' || item.pending_action === 'destroy')

    _.each(pending_items, (item: any) => {
      if (item.pending_action === 'destroy') {
        this.destroy_item(item)
        item.pending_action = undefined
      }

      if (item.pending_action === 'create') {
        this.create_item(item)
        item.pending_action = undefined
      }

      if (item.pending_action === undefined) {
        // do nothing
        // console.log "hit object with no pending action"
      }
    })
  }

  increment_pending() {
    return this.pending_transactions++
  }

  decrement_pending() {
    this.pending_transactions--
    return this.evaluate_transaction_status()
  }

  increment_failed() {
    return this.failed_transactions++
  }

  // checks to see if there are any more pending_transactions
  // if not then we are done and can call emit_completion_event
  evaluate_transaction_status() {
    if (this.pending_transactions === 0) {
      return this.emit_completion_event()
    }
  }

  emit_completion_event() {
    const return_object = {
      manager: 'reference_manager',
      object: this.formModel,
      fault: this.failed_transactions > 0
    }
    return this.$scope.$emit('reference_manager_finished', return_object)
  }

  search_items = _.debounce(function() {
    return this.Restangular.all('reference_objects').get('search', this.search_params).then((success: any) => {
      return this.search_result = success
    }
      , (error: any) => console.log(error));
  }, 200)

  xxx_includes(item: any) {
    return _.find(this.xxx, (xxx_item: any) => xxx_item.reference_object_id === item.reference_object_id);
  }

  add_result_to_xxx() {
    if (!this.xxx_includes(this.search_result)) {
      this.xxx.push(this.search_result)
      return this.search_result.pending_action = 'create'
    }
  }

  ng_model_includes(item: any) {
    return _.some(this.formModel, (ng_model_item: any) => ng_model_item.reference_object_id === item.reference_object_id);
  }

  toggle_pending_action(item: any) {
    if (item.pending_action) {
      return item.pending_action = undefined
    } else {
      if (this.ng_model_includes(item)) {
        return item.pending_action = 'destroy'
      } else {
        return item.pending_action = 'create'
      }
    }
  }

  get_item_icon(item: any) {
    let icon

    if (item.pending_action) {
      if (item.pending_action === 'create') {
        icon = 'fa fa-plus-square'
      } else {
        icon = 'fa fa-minus-square-o'
      }
    } else { // there are no pending_actions
      if (this.ng_model_includes(item)) {
        icon = 'fa fa-check-square'
      } else {
        icon = 'fa fa-square-o'
      }
    }

    return icon
  }

  /* CREATE */
  create_item(item: any) {
    const reference = {
      reference_object_id: item.reference_object_id,
      referenceable_id: this.referenceableId,
      referenceable_type: this.referenceableType
    }

    return this.Restangular.all('references').post(reference).then((success: any) => this.objectManager.array_action(this.formModel, item, 'merge'), (error: any) => {
      const alert = {
        name: `create_error_${item.reference_object_id}`,
        dismissable: true,
        class: 'alert-warning',
        icon: 'fa-exclamation-triangle',
        strong: 'Error:',
        message: 'There was an error creating ' +
          `a reference for aspect #${item.reference_object_id}. ` +
          `${this.flashService.default_alert_by_code(error.status).message}`
      }
      this.flashService.emit_alert(this.$scope, alert)
      return this.increment_failed()
    }).finally(() => {
      return this.decrement_pending()
    });
  }

  /* DESTROY */
  destroy_item(item: any) {
    const build_error_alert = (error: any) => ({
      name: `delete_error_${item.reference_object_id}`,
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Error:',

      message: 'There was an error deleting ' +
        `the reference for aspect #${item.reference_object_id}. ` +
        `${this.flashService.default_alert_by_code(error?.status).message}`
    })

    let find_join_results: any

    const find_join = () => {
      // This is what the reference looks like. we dont have the join ID yet
      const item_filter = {
        'filter[reference_object_id]': item.reference_object_id,
        'filter[referenceable_id]': this.referenceableId,
        'filter[referenceable_type]': this.referenceableType
      }

      return this.Restangular.all('references').get('find', item_filter).then((success: any) => find_join_results = success, function (this: any, error: any) {
        this.flashService.emit_alert(this.$scope, build_error_alert(error))
        console.log('error locating join')
        return this.increment_failed()
      })
    }

    const remove_join = () => {
      if (find_join_results.length === 1) {
        return this.Restangular.one('references', find_join_results[0].id).remove().then(
          (success: any) => this.objectManager.array_action(this.formModel, item, 'remove'), 
          (error: any) => {
            if (!error) {
              console.log("error: ", error)
            }
            this.flashService.emit_alert(this.$scope, build_error_alert(error))
            console.log('error removing join')
            return this.increment_failed()
          }
        ).finally(() => {
          return this.decrement_pending()
        });
      } else {
        var error = undefined
        this.flashService.emit_alert(this.$scope, build_error_alert(error))
        console.log('multiple join results found')
        this.increment_failed()
        return this.decrement_pending()
      }
    }

    return find_join().then(remove_join)
  }

}

angular
  .module('complyosClient')
  .directive('ngReferenceManager', downgradeComponent({ component: ReferenceManager }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- SOURCE

// var complyosClient = angular.module('complyosClient')

// complyosClient.directive('referenceManager', [
//   'flashService',
//   'fortyCore',
//   'objectManager',
//   'Restangular',
//   function (
//     flashService: any,
//     fortyCore: any,
//     objectManager: any,
//     Restangular: any
//   ) {
//     return {
//       restrict: 'E',
//       templateUrl: 'views/directives/reference_manager.html',
//       require: 'ngModel',

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
//       //  $ngModel is the actual model that this directive interacts with
//       link ($scope: any, $element: any, $attributes: any, $ngModel: any) {

//         $scope.rm = {

          

//           // there is a delay between the time the directive is ready and ngModel is
//           // we watch for ready and run code once
//           let ng_model_ready = false
//           return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
//             if (ng_model_ready === false) {
//               $scope.rm.initialize()
//               return ng_model_ready = true
//             }
//           });
//         }
//       };
//     }
//   }
// ])






//- --------------------------------------------------------------------
//- FOR REFERENCE



// var complyosClient = angular.module('complyosClient')

// complyosClient.directive('referenceManager', [
//   'flashService',
//   'fortyCore',
//   'objectManager',
//   'Restangular',
//   function (
//     flashService: any,
//     fortyCore: any,
//     objectManager: any,
//     Restangular: any
//   ) {
//     return {
//       restrict: 'E',
//       templateUrl: 'views/directives/reference_manager.html',
//       require: 'ngModel',
//       scope: {
//         allowCreate: '=',
//         allowDestroy: '=',
//         referenceableId: '=',
//         referenceableType: '@',
//         uiSetting: '@'
//       },

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
//       //  $ngModel is the actual model that this directive interacts with
//       link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
//         $scope.processingStateService = fortyCore.processingStateService

//         $scope.rm = {

//           initialize () {
//             this.allow_create = $scope.allowCreate
//             this.allow_destroy = $scope.allowDestroy
//             this.ui_setting = $scope.uiSetting
//             this.search_params = { query: '' }
//             this.search_result = {}

//             this.populate_xxx()
//             this.search_items() // ran on init to get @add_result_to_xxx button working
//             return this.listen_for_start_event()
//           },

//           populate_xxx () {
//             // populate_xxx with what we have
            
//             this.xxx = angular.copy($ngModel.$modelValue)

//             // Watch for changes and repopulate
//             return $scope.$watch(() => $ngModel.$modelValue
//               , (newValue: any, oldValue: any) => {
                
//                 return this.xxx = angular.copy($ngModel.$modelValue)
//               }
//               , true);
//           },

//           /* WATCHERS, LISTENERS AND EMITTERS */

//           // chain of events for the saving:
//           // 1. the reference manager is alerted to start
//           // 2. The process_items() method is called to resolve any pending_actions
//           // 3. before anything it runs evaluate_transaction_status(), which sees if pending_transactions is 0
//           //     if it is, we skip everything and finish out the manager.
//           //     else, we loop over the transactions and handle as necessary

//           listen_for_start_event () {
//             return $scope.$on('reference_manager_start', (event: any, data: any) => {
//               // set the referenceableId because we may not have had it
//               this.referenceableId = data.id
//               // reset the failed_transaction counter
//               this.failed_transactions = 0
//               // now on to the saving
//               return this.process_items()
//             });
//           },

//           count_of_pending_transactions () {
//             let transaction_count = 0
//             _.some(this.xxx, function (item: any) {
//               if (item.pending_action) {
//                 return transaction_count++
//               }
//             })
//             return transaction_count
//           },

//           process_items () {
//             this.pending_transactions = this.count_of_pending_transactions()

//             this.evaluate_transaction_status()

//             return _.each(this.xxx, (item: any) => {
//               if (item.pending_action === 'destroy') {
//                 this.destroy_item(item)
//                 item.pending_action === undefined
//               }

//               if (item.pending_action === 'create') {
//                 this.create_item(item)
//                 item.pending_action === undefined
//               }

//               if (item.pending_action === undefined) {
//                 // do nothing
//                 // console.log "hit object with no pending action"

//               }
//             });
//           },

//           // COUNTERS
//           pending_transactions: 0,
//           failed_transactions: 0,

//           increment_pending () {
//             return this.pending_transactions++
//           },

//           decrement_pending () {
//             this.pending_transactions--
//             return this.evaluate_transaction_status()
//           },

//           increment_failed () {
//             return this.failed_transactions++
//           },

//           // checks to see if there are any more pending_transactions
//           // if not then we are done and can call emit_completion_event
//           evaluate_transaction_status () {
//             if (this.pending_transactions === 0) {
//               return this.emit_completion_event()
//             }
//           },

//           emit_completion_event () {
//             const return_object = {
//               manager: 'reference_manager',
//               object: $ngModel.$modelValue,
//               fault: this.failed_transactions > 0
//             }
//             return $scope.$emit('reference_manager_finished', return_object)
//           },

//           search_items () {
//             return Restangular.all('reference_objects').get('search', this.search_params).then((success: any) => {
//               return this.search_result = success
//             }
//             , (error: any) => console.log(error));
//           },

//           xxx_includes (item: any) {
//             return _.find(this.xxx, (xxx_item: any) => xxx_item.reference_object_id === item.reference_object_id);
//           },

//           add_result_to_xxx () {
//             if (!this.xxx_includes(this.search_result)) {
//               this.xxx.push(this.search_result)
//               return this.search_result.pending_action = 'create'
//             }
//           },

//           ng_model_includes (item: any) {
//             return _.some($ngModel.$modelValue, (ng_model_item: any) => ng_model_item.reference_object_id === item.reference_object_id);
//           },

//           toggle_pending_action (item: any) {
//             if (item.pending_action) {
//               return item.pending_action = undefined
//             } else {
//               if (this.ng_model_includes(item)) {
//                 return item.pending_action = 'destroy'
//               } else {
//                 return item.pending_action = 'create'
//               }
//             }
//           },

//           get_item_icon (item: any) {
//             let icon

//             if (item.pending_action) {
//               if (item.pending_action === 'create') {
//                 icon = 'fa fa-plus-square'
//               } else {
//                 icon = 'fa fa-minus-square-o'
//               }
//             } else { // there are no pending_actions
//               if (this.ng_model_includes(item)) {
//                 icon = 'fa fa-check-square'
//               } else {
//                 icon = 'fa fa-square-o'
//               }
//             }

//             return icon
//           },

//           /* CREATE */

//           create_item (item: any) {
//             const reference = {
//               reference_object_id: item.reference_object_id,
//               referenceable_id: $scope.referenceableId,
//               referenceable_type: $scope.referenceableType
//             }

//             return Restangular.all('references').post(reference).then((success: any) => objectManager.array_action($ngModel.$modelValue, item, 'merge'), (error: any) => {
//               const alert = {
//                 name: `create_error_${item.reference_object_id}`,
//                 dismissable: true,
//                 class: 'alert-warning',
//                 icon: 'fa-exclamation-triangle',
//                 strong: 'Error:',
//                 message: 'There was an error creating ' +
//                   `a reference for aspect #${item.reference_object_id}. ` +
//                   `${this.flashService.default_alert_by_code(error.status).message}`
//               }
//               flashService.emit_alert($scope, alert)
//               return this.increment_failed()
//             }).finally(() => {
//               return this.decrement_pending()
//             });
//           },

//           /* DESTROY */

//           destroy_item (item: any) {
//             const build_error_alert = (error: any) => ({
//               name: `delete_error_${item.reference_object_id}`,
//               dismissable: true,
//               class: 'alert-warning',
//               icon: 'fa-exclamation-triangle',
//               strong: 'Error:',

//               message: 'There was an error deleting ' +
//                 `the reference for aspect #${item.reference_object_id}. ` +
//                 `${this.flashService.default_alert_by_code(error.status).message}`
//             })

//             let find_join_results: any

//             const find_join = () => {
//               // This is what the reference looks like. we dont have the join ID yet
//               const item_filter = {
//                 'filter[reference_object_id]': item.reference_object_id,
//                 'filter[referenceable_id]': $scope.referenceableId,
//                 'filter[referenceable_type]': $scope.referenceableType
//               }

//               return Restangular.all('references').get('find', item_filter).then((success: any) => find_join_results = success, function(this: any, error: any) {
//                 flashService.emit_alert($scope, build_error_alert(error))
//                 console.log('error locating join')
//                 return this.increment_failed()
//               }).finally(() => {
//                 return this.decrement_pending()
//               });
//             }

//             const remove_join = () => {
//               if (find_join_results.length === 1) {
//                 return Restangular.one('references', find_join_results[0].id).remove().then((success: any) => objectManager.array_action($ngModel.$modelValue, item, 'remove'), (error: any) => {
//                   flashService.emit_alert($scope, build_error_alert(error))
//                   console.log('error removing join')
//                   return this.increment_failed()
//                 }).finally(() => {
//                   return this.decrement_pending()
//                 });
//               } else {
//                 var error = undefined
//                 flashService.emit_alert($scope, build_error_alert(error))
//                 console.log('multiple join results found')
//                 this.increment_failed()
//                 return this.decrement_pending()
//               }
//             }

//             return find_join().then(remove_join)
//           }

//         }

//         // there is a delay between the time the directive is ready and ngModel is
//         // we watch for ready and run code once
//         let ng_model_ready = false
//         return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
//           if (ng_model_ready === false) {
//             $scope.rm.initialize()
//             return ng_model_ready = true
//           }
//         });
//       }
//     };
//   }

// ])
