import '../images/favicons/manifest.json'
import './config'
import './services/forty-au-services.module'
import './services/fortyau_services'
import './services/forty_core.service'
import './services/ajs-loading_state_service'
import './app.legacy'
import './controllers/application/help'
import './providers/session_provider'
import './controllers/application/authentiwait'
import './controllers/application/control_number'
import './controllers/application/dashboard'
import './controllers/application/date_range'
import './controllers/application/demo'
import './controllers/application/digest'
import './controllers/application/dashboard_entity_filter'
import './controllers/application/feed'
import './controllers/application/footer'
import './controllers/application/home_links'
import './controllers/application/home'
import './controllers/application/main'
import './controllers/application/marketing'
import './controllers/application/messages'
import './controllers/application/navigation'
import './controllers/binders/binder'
import './controllers/binders/documentation'
import './controllers/binders/information'
import './controllers/binders/notifications'
import './controllers/binders/snapshot'
import './controllers/modals/ajs-report-modal-adapter'
import './controllers/modals/aspect_modal'
import './controllers/modals/binder_modal'
import './controllers/modals/user_organization_modal'
import './controllers/modals/new_communication_modal'
import './controllers/modals/vendor_modal'
import './controllers/organizations/survey_mode'
import './controllers/modals/entry_modal'
import './controllers/modals/footer_modal'
import './controllers/modals/organization_modal'
import './controllers/modals/pdf_capture_modal'
import './controllers/modals/pdf_preview_modal'
import './controllers/modals/requirement_modal'
import './controllers/modals/ajs-requirement-modal-adapter'
import './controllers/modals/ajs-requirement-version-history-modal-adapter'
import './controllers/modals/tag_modal'
import './controllers/modals/update_user_password_modal'
import './controllers/modals/user_modal'
import './controllers/modals/notes/entry_validation_mode_comment'
import './controllers/modals/notes/organization_validation_mode_comment'
import './controllers/modals/notes/validation_mode_comment_response'
import './controllers/modals/ajs-form-schema-preview-modal-adapter'
import './controllers/organizations/binders'
import './controllers/organizations/organization_overview_fruitalytic_tree'
import './controllers/organizations/tasks_to_complete'
import './controllers/organizations/validation_mode_comments_count'
import './controllers/organizations/validation_mode_comments'
import './controllers/organizations/schedule'
import './controllers/organizations/snapshot'
import './controllers/organizations/survey_date'
import './controllers/organizations/trend'
import './controllers/organizations/users'
import './controllers/partials/date_range_filter'
import './controllers/requirements/information'
import './controllers/requirements/store'
import './controllers/requirements/tag-filter'
import './controllers/system/dashboard'
import './controllers/system/communications'
import './controllers/system/organizations'
import './controllers/system/operators'
import './controllers/system/permissions'
import './controllers/system/references/tree_navigator'
import './controllers/system/references/tree_node'
import './controllers/system/requirements'
import './controllers/system/reports'
import './controllers/system/tags'
import './controllers/system/users'
import './controllers/users/attributes'
import './controllers/users/forgot_password'
import './controllers/users/login'
import './controllers/users/permissions'
import './controllers/users/sso'
import './controllers/users/set_password'
import './directives/analytics_trend'
import './directives/api_search_dropdown'
import './directives/api_search_option'
import './directives/attachment_manager'
import './directives/bulk_configure_requirements'
import './directives/collapse_panel'
import './directives/complete_percentage'
import './directives/confirmation_modal'
import './directives/date_range_filter'
import './directives/download'
import './directives/entry_complete_manager'
import './directives/dashboard_filter'
import './directives/filter'
import './directives/filter_text_search'
import './directives/flash'
import './directives/html5_object'
import './directives/list_filter'
import './directives/loading_indicator'
import './directives/organization_needed_prompt'
import './directives/modal_insert'
import './directives/node_tree_builder'
import './directives/note_manager'
import './directives/organization_row'
import './directives/tasks_to_complete_pie_chart'
import './directives/pagination/page_size'
import './directives/pagination/page_results_range'
import './directives/pagination/paginator_full'
import './directives/pagination/paginator'
import './directives/popout'
import './directives/query_too_large_warning'
import './directives/profile_manager'
import './directives/replace_with_child'
import './directives/review_note_count'
import './directives/satalite_paginator'
import './directives/snapshot_linear'
import './directives/snapshot_metrics'
import './directives/sort_by_manager'
import './directives/state_manager'
import './directives/status_icon'
import './directives/tag_row'
import './directives/tagging_manager'
import './directives/organization_tree_structure'
import './directives/user_lob_manager'
import './directives/user_organization_manager'
import './directives/user_role_manager'
import './services/application_services'
import './services/complyos_services'
import './services/help'
import './services/password_service'
import './services/queue_processor_service'
import './services/session_service'
import './services/store_service'
import './services/filter_service'
import './services/njs_resource.resource'
import './services/njs_service.service'
import './components/card.component'

//- CLASSIFIED & ADAPTED
import './utilities/ajs-array_functions'
// import './services/ajs-loading_state_service'
import './services/ajs-object_manager'
import './services/ajs-utils'
import './services/ajs-confirmation_modal_factory'

//- REPLACED, SLATED TO BE REMOVED
//- NG:UPGRADED
import './directives/reference_manager'
import './directives/reference_object'
import './directives/filter_pill'
import './directives/change_alert_icon'

// UPGRADED AND REMOVED
// import './services/ajs-flash_service';
// import './services/authentication_service'