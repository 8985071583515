/* eslint-disable
    camelcase,
    no-eval,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('snapshotLinear', [
  '$uibModal',
  'complyosServices',
  function ($uibModal: any, complyosServices: any) {
    return {
      restrict: 'EA',
      templateUrl: 'views/directives/snapshot_linear.html',
      scope: {
        binderSnapshot: '=binderSnapshot'
      },

      link ($scope: any, $element: any, $attrs: any) {
        $scope.graph = {
          entry_snapshots: $scope.binderSnapshot.entry_snapshots,

          svg_height_quantity: $attrs.height_quantity ? $attrs.height_quantity : '45',
          svg_height_unit: $attrs.height_unit ? $attrs.height_unit : 'px',
          svg_width: $attrs.width ? $attrs.width : '100%',

          svg_get_height () {
            return `${this.svg_height_quantity}${this.svg_height_unit}`
          },

          shape_get_fill_color (status: any) {
            return complyosServices.snapshot.get_status_color(status)
          },

          shape_get_stroke_width () {
            // "#{@svg_height_quantity / 10}#{@svg_height_unit}"
            return '.15em'
          },

          shape_get_stroke_color () {
            return 'rgb(255,255,255)'
          },

          shape_get_x_offset (entry_snapshot: any) {
            let offset = 0
            _.each(this.entry_snapshots, function (snapshot: any) {
              if (snapshot.meta.index < entry_snapshot.meta.index) {
                return offset += snapshot.meta.percent
              }
            })
            return `${offset}%`
          },

          calc_transparent_color_on_white (hex_color: any, opacity: any) {
            // TODO these methods should be moved into a color service

            // convert Hex string and opacity to rgba_array
            // Args:
            //   hex - 6 digit hex string with or without "#"
            //   opacity - number in range 0..100
            // Returns: array representing rgba values of converted
            const hexo_to_rgba_array = function (hex: any, opacity: any) {
              if (opacity == null) { opacity = undefined }
              hex = hex.replace('#', '')
              const r = parseInt(hex.substring(0, 2), 16)
              const g = parseInt(hex.substring(2, 4), 16)
              const b = parseInt(hex.substring(4, 6), 16)
              const a = opacity !== undefined ? opacity / 100 : 1
              return [r, g, b, a]
            }

            // get a RGB value which is most similar to the RGBA tuple visually on white background.
            // optionally pass in a second RGB color to use instead of white
            // Args:
            //   rgba1 - array representing rgba values of color on top of rgba2
            //   rgba2 - array representing rgba values of color below rgba1
            // Returns: array representing rgba values of combined
            const rgba_on_color = function (rgba1: any, rgba2: any = null) {
              if (rgba2 == null) { rgba2 = undefined }
              rgba2 = rgba2 !== undefined ? rgba2 : [255, 255, 255, 1]
              const r = Math.round(((1 - rgba1[3]) * rgba2[0]) + (rgba1[3] * rgba1[0]))
              const g = Math.round(((1 - rgba1[3]) * rgba2[1]) + (rgba1[3] * rgba1[1]))
              const b = Math.round(((1 - rgba1[3]) * rgba2[2]) + (rgba1[3] * rgba1[2]))
              const a = 1
              return [r, g, b, a]
            }

            // converts rgba_array to string
            // Args:
            //   rgba_array - array representing rgba values in order [red, green, blue, alpha]
            // Returns: rgba string
            const rgba_array_to_string = (rgba_array: any) => `rgba(${rgba_array[0]},${rgba_array[1]},${rgba_array[2]},${rgba_array[3]})`

            // converts string to rgba_array
            // Args:
            //   string - rgba string
            // Returns: array representing rgba values in order [red, green, blue, alpha]
            const rgba_string_to_array = function (string: any) {
              string = string.replace('rgba(', '[').replace(')', ']')
              return eval(string)
            }

            // http://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
            const rgba_array_to_hex = (rgb_array: any) => `#${((1 << 24) + (rgb_array[0] << 16) + (rgb_array[1] << 8) + rgb_array[2]).toString(16).slice(1)}`

            return rgba_array_to_hex(rgba_on_color(hexo_to_rgba_array(hex_color, opacity)))
          },

          shape_rectangle_get_fill (status: any) {
            return this.calc_transparent_color_on_white(this.shape_get_fill_color(status), 30)
          },

          shape_rectangle_get_width (entry_snapshot: any) {
            return `${entry_snapshot.meta.display_percent}%`
          },

          shape_circle_get_radius () {
            return `${this.svg_height_quantity / 6}${this.svg_height_unit}`
          },

          shape_circle_get_y_offset () {
            return `${this.svg_height_quantity / 2}${this.svg_height_unit}`
          }

        }

        return $scope.openEntryModal = function (entry_object: any, ui_setting: any) {
          entry_object['binder'] = $scope.binderSnapshot.binder
          const modalInstance = $uibModal.open({
            templateUrl: 'views/modals/entry_modal.html',
            controller: 'entryModalController',
            size: 'small',
            resolve: {
              modalOptions () {
                return {
                  
                  object: angular.copy(entry_object),
                  ui_setting
                }
              }
            }
          })

          // on close
          // - grab the returned result,
          // - or log out a reason
          return modalInstance.result.then(function (result: any) {
          }
          , function (reason: any) {
          });
        };
      }
    };
  }
])
