import angular from "angular"

export class ConfirmationModalFactory {
  static $inject = ['$uibModal'];
  constructor(
    private $uibModal: any)
  {
  }

  openConfirmationModal(confirmationObject: any) {
    // Open the modal instance and pass in our options via resolve
    const $uibModalInstance = this.$uibModal.open({
      resolve: {
        confirmationObject() {
          return confirmationObject
        }
      },
      templateUrl: 'views/directives/confirmation_modal.html',
      controller: 'confirmationModalController',
      windowClass: 'custom-modal-zindex'
    })

    // Resolve the response from the modal
    return $uibModalInstance.result.then(function () {
      // Run the function requiring confirmation
      const success = confirmationObject.confirm || (() => console.log('Confirm'))
      success()
      return true
    }
      , function () {
        const failure = confirmationObject.deny || (() => console.log('Deny'))
        failure()
        return false
      })
  }
}

angular
  .module('complyosClient')
  .service('confirmationModalFactory', ConfirmationModalFactory);

//- --------------------------------------------------------------------
//- FOR REFERENCE:

// angular
//   .module('complyosClient')
//   .factory('confirmationModalFactory', ['$uibModal', ($uibModal: any) => ({
//     openConfirmationModal(confirmationObject: any) {
//       // Open the modal instance and pass in our options via resolve
//       const $uibModalInstance = $uibModal.open({
//         resolve: {
//           confirmationObject() {
//             return confirmationObject
//           }
//         },
//         templateUrl: 'views/directives/confirmation_modal.html',
//         controller: 'confirmationModalController'
//       })

//       // Resolve the response from the modal
//       return $uibModalInstance.result.then(function () {
//         // Run the function requiring confirmation
//         const success = confirmationObject.confirm || (() => console.log('Confirm'))
//         success()
//         return true
//       }
//         , function () {
//           const failure = confirmationObject.deny || (() => console.log('Deny'))
//           failure()
//           return false
//         })
//     }
//   })])