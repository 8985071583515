import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RequirementValidators } from '../../extensions/RequirementValidators';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';
import angular from 'angular';

@Component({
  selector: 'ng-requirement-compliance-editor',
  templateUrl: './requirement-compliance-editor.component.html',
  styleUrls: ['./requirement-compliance-editor.component.scss']
})
export class RequirementComplianceEditorComponent implements OnInit {

  @Input() uiSetting: string = 'update';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()
  @Input() formSubmitAttempted;

  //Injected Serivces
  public session: any;
  
  //Properties
  form: FormGroup
  formReady: boolean = false;
  ValidationHelpers = ValidationHelpers
  
  constructor(
    @Inject('$httpParamSerializer') private $httpParamSerializer: any,
    public controlContainer: ControlContainer,
    @Inject('ENV') private ENV,
    @Inject('$rootScope') private $rootScope,
    @Inject('$window') private $window,
    @Inject('$uibModal') private $uibModal
  ) {
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.addFormFields();
  }

  // LISTS
  public compliance_methods = [
    'Form',
    'Form/Template',
    'Template',
    'Local Documentation',
    'Observation',
    'Engineering Central'
  ]

  addFormFields = () => {
    this.form = this.controlContainer.control as FormGroup;
    this.form.addControl('compliance_method', new FormControl(this.requirement.compliance_method, (control) => RequirementValidators.requiredIfPublished(control, this.form)))
    this.form.addControl('compliance_leader_assignment_instructions', new FormControl(this.requirement.compliance_leader_assignment_instructions))
    this.form.addControl('program_leader_assignment_instructions', new FormControl(this.requirement.program_leader_assignment_instructions))
    this.form.addControl('task_agent_assignment_instructions', new FormControl(this.requirement.task_agent_assignment_instructions))
    this.form.addControl('allow_partially_complete', new FormControl(this.requirement.allow_partially_complete))
    this.form.addControl('allow_closed_not_complete', new FormControl(this.requirement.allow_closed_not_complete))
    this.form.addControl('task_agent_cannot_attest', new FormControl(this.requirement.task_agent_cannot_attest))

    this.formReady = true;
  }

  launch_form_tool = () => {
    const params = {
      action: 'authorSchema'
    }
    const serilalized_params = this.$httpParamSerializer(params)
    const redirect_url = this.ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
    return this.$window.location = redirect_url
  }

  open_preview_model = () => {
    if(this.requirement.schema && this.requirement.schema.value){
      var requirement_schema = JSON.parse(this.requirement.schema.value)
      this.$uibModal.open({
        templateUrl: 'views/modals/ajs-form-schema-preview-modal-adapter.html',
        controller: 'ajsFormSchemaPreviewModalAdapter',
        size: 'lg',
        resolve: {
          modalOptions() {
            return {
              object: angular.copy(requirement_schema)
            }
          }
        }
      })
    }
  }

  updateInstructionsText = (event: any) => {
    this.requirement.instructions = event
  }
}
