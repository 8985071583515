import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
// import { complyosClient } from '../../../scripts/app.legacy'

@Component({
  selector: 'ngReferenceObject', // <ng-reference-object/>
  templateUrl: './reference-object.component.html'
})
export class ReferenceObject implements OnInit, OnChanges {
  private tooltipText: string = "";

  @Input() object!: any;

  private transcoded_object :Array<any> = new Array<any>();

  ngOnInit() {
    // console.log(this.object)
  }

  ngOnChanges(changes: any) {
    if (changes.object) {
      this.transcoded_object = this.transcode(changes.object.currentValue)
    }
  }

  transcode = function (object: any) {
    let cmmd_mode = false
    let cmmd_str = ''
    let i = 0
    var output = new Array<any>()

    if (object.assembly === undefined) {
      output.push({
        type: 'string',
        value: object.value
      })
      return output
    } else {
      while (i < object.assembly.length) {
        if (cmmd_mode && (object.assembly[i] === ']')) { // exit command_mode, run command
          cmmd_mode = false // exit command_mode
          const cmmd_fn = cmmd_str.split(':')[0]
          const cmmd_arg = cmmd_str.split(':')[1]
          cmmd_str = '' // reset the command_string for future use

          if (cmmd_fn === 'r') { // replace
            if (this.deepSearchObject(object, `${cmmd_arg}.description`) === null) {
              output.push({
                type: 'string',
                value: this.deepSearchObject(object, `${cmmd_arg}.identifier`)
              })
            } else {
              output.push({
                type: 'tooltip',
                value: this.deepSearchObject(object, `${cmmd_arg}.identifier`),
                tooltip: this.deepSearchObject(object, `${cmmd_arg}.description`)
              })
            }
          } else if (cmmd_fn === '?') { // condtional continue
            if (object[cmmd_arg] === undefined) { // break if blank
              break
            }
          }
        } else if (!cmmd_mode && (object.assembly[i] === '[')) { // entering command_mode
          cmmd_mode = true
        } else if (cmmd_mode) { // in command mode, build the command_string
          cmmd_str += object.assembly[i]
        } else if (!cmmd_mode) { // not in command_mode append object.assembly[i] to output
          output.push({
            type: 'string',
            value: object.assembly[i]
          })
        }
        ++i
      }
      return output
    }
  }
  deepSearchObject = function (o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, '.$1')
    s = s.replace(/^\./, '')
    const a = s.split('.')
    let i = 0
    while (i < a.length) {
      const k = a[i]
      if (k in o) {
        o = o[k]
      } else {
        return
      }
      ++i
    }
    return o
  }
}

angular
  .module('complyosClient')
  .directive('ngReferenceObject', downgradeComponent({ component: ReferenceObject }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('referenceObject', [
//     // injects here, , ,
//     () =>
//     ({
//         templateUrl: 'views/directives/referenceObject.html',
//         restrict: 'E',
//         scope: {
//             object: '='
//         },
//         link($scope: any) {
//             $scope = this.registerFunctions($scope)
//             $scope.transcoded_object = []
//             $scope.$watch('object', function (newValue: any, oldValue: any) {
//                 $scope.transcoded_object = $scope.transcode($scope.object)
//             }, true)
//         },

//         registerFunctions($scope: any) {
//             $scope.transcode = function (object: any) {
//                 let cmmd_mode = false
//                 let cmmd_str = ''
//                 let i = 0
//                 const output = []

//                 if (object.assembly === undefined) {
//                     output.push({
//                         type: 'string',
//                         value: object.value
//                     })
//                     return output
//                 } else {
//                     while (i < object.assembly.length) {
//                         if (cmmd_mode && (object.assembly[i] === ']')) { // exit command_mode, run command
//                             cmmd_mode = false // exit command_mode
//                             const cmmd_fn = cmmd_str.split(':')[0]
//                             const cmmd_arg = cmmd_str.split(':')[1]
//                             cmmd_str = '' // reset the command_string for future use

//                             if (cmmd_fn === 'r') { // replace
//                                 if ($scope.deepSearchObject(object, `${cmmd_arg}.description`) === null) {
//                                     output.push({
//                                         type: 'string',
//                                         value: $scope.deepSearchObject(object, `${cmmd_arg}.identifier`)
//                                     })
//                                 } else {
//                                     output.push({
//                                         type: 'tooltip',
//                                         value: $scope.deepSearchObject(object, `${cmmd_arg}.identifier`),
//                                         tooltip: $scope.deepSearchObject(object, `${cmmd_arg}.description`)
//                                     })
//                                 }
//                             } else if (cmmd_fn === '?') { // condtional continue
//                                 if (object[cmmd_arg] === undefined) { // break if blank
//                                     break
//                                 }
//                             }
//                         } else if (!cmmd_mode && (object.assembly[i] === '[')) { // entering command_mode
//                             cmmd_mode = true
//                         } else if (cmmd_mode) { // in command mode, build the command_string
//                             cmmd_str += object.assembly[i]
//                         } else if (!cmmd_mode) { // not in command_mode append object.assembly[i] to output
//                             output.push({
//                                 type: 'string',
//                                 value: object.assembly[i]
//                             })
//                         }
//                         ++i
//                     }
//                     return output
//                 }
//             }
//             $scope.deepSearchObject = function (o: any, s: any) {
//                 s = s.replace(/\[(\w+)\]/g, '.$1')
//                 s = s.replace(/^\./, '')
//                 const a = s.split('.')
//                 let i = 0
//                 while (i < a.length) {
//                     const k = a[i]
//                     if (k in o) {
//                         o = o[k]
//                     } else {
//                         return
//                     }
//                     ++i
//                 }
//                 return o
//             }

//             return $scope
//         }
//     })
// ])