// import { Injectable } from '@angular/core';
import * as angular from 'angular';

// @Injectable()
export class njsService {
    private ContactResource;

    constructor(ContactResource) {
        this.ContactResource = ContactResource;
        this.loadContacts();

        console.log("[njs -> x] || this is an example [njs] service that can be used in upgraded to be used in ng12 entities OR used as normal in njs entities")
    }

    loadContacts() {
        this.ContactResource.whoami("njs_service");
    }

    removeContact(person) {
        console.log('in contact service')
        console.log('params: ' + person)
    }
}

angular
    .module("complyosClient")
    .service("njsService", njsService);