// /* eslint-disable
//     camelcase,
//     handle-callback-err,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsStoreController', ['$rootScope', '$q', '$scope', '$uibModal', 'complyosServices', 'confirmationModalFactory', 'constants', 'FileSaver', 'filterService', 'flashService', 'fortyCore', 'helpService', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
//   $rootScope: any,
//   $q: any,
//   $scope: any,
//   $uibModal: any,
//   complyosServices: any,
//   confirmationModalFactory: any,
//   constants: any,
//   FileSaver: any,
//   filterService: any,
//   flashService: any,
//   fortyCore: any,
//   helpService: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {

//     const custom_states = {
//       nocontent: {
//         icon: 'fa-exclamation-triangle',
//         text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
//       },
//       ready: {
//         icon: 'fa-download',
//         text: 'Download Report'
//       }
//     }

    
//     $scope.flashService = angular.copy(flashService)
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
    
//     $scope.reportLoadingStateService = angular.copy(loadingStateService)
//     $scope.reportLoadingStateService.loading_states = _.merge($scope.reportLoadingStateService.loading_states, custom_states)
//     $scope.reportLoadingStateService.set_state('ready')
//     $scope.processingStateService = fortyCore.processingStateService
//     // $scope.orgPills = []
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.previousPills = null
//     $scope.requirementsCount = 0
//     $scope.organizationSelected = false
//     $scope.outstandingPromises = []
//     $scope.defaultFilters = [
//       {
//         isUnique: true,
//         isRequired: true,
//         isHidden: true,
//         Field: 'requirement_visible',
//         Op: constants.FilterType.Radio,
//         displayName: 'Visibility',
//         displayValue: 'Active',
//         Value: 'active',
//         dateCreated: dayjs().$d
//       }
//     ]

//     watchPillFilter()
//   }

//   var watchPillFilter = () => {
//     $scope.$on('filterChange-dashboard', function (e: any, pills: any) {
//       abortAllPromises($scope.outstandingPromises)
//       is_org_pill_selected(pills)
//       setTimeout(() => get_requirements(pills), 50)
//     })
//   }

//   var is_org_pill_selected = function (pills: any) {
//     $scope.organizationSelected = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
//   }

//   var get_requirements = function (pills: any) {
//     $scope.previousPills = pills
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }

//     let abortReqs = $q.defer()
//     $scope.outstandingPromises.push(abortReqs)

//     $scope.loadingStateService.set_state('loading')
//     return Restangular.one('requirements').withHttpConfig({ timeout: abortReqs.promise }).get(query_params).then(
//       (success: any) => {
//         $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//         $scope.requirements = success.items
//         $scope.requirementsCount = success.pagination_info.totalCount

//         if ($scope.requirements.length === 0) {
//           $scope.loadingStateService.set_state('nocontent')
//         } else {
//           get_binders(pills)
//         }
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//       }
//     );
//   }

//   var abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   var get_binders = function (pills: any) {
//     // $scope.orgPills = JSON.parse(JSON.stringify(pills)).filter(p => p.Field === 'organizations_ids').map(r => r.Value)
//     let pillsCopy = JSON.parse(JSON.stringify(pills))
//     // let organizationPills = pillsCopy.filter(p => p.Field === 'organizations_ids')
//     // if (!Array.isArray(organizationPills) || organizationPills.length === 0) {
//     //   // only try to show configured binders if there are any organization filters
//     //   $scope.loadingStateService.set_state('content')
//     //   return
//     // }

//     if (Array.isArray($scope.requirements)) {
//       // add temporary requirement pills to ONLY return configured binders for currently visible requirements
//       let subQueryRequirementPills = $scope.requirements.map((r: any) => {
//         return {
//           Field: 'requirement_ids',
//           Op: constants.FilterType.Multi,
//           Value: r.id,
//           dateCreated: dayjs().$d,
//           displayName: 'Requirements',
//           displayValue: r.display_name
//         }
//       })
//       pillsCopy = pillsCopy.concat(subQueryRequirementPills)
//     }

//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pillsCopy)
//       )
//     }

//     let abortBinders = $q.defer()
//     $scope.outstandingPromises.push(abortBinders)

//     Restangular.all('binders/store').withHttpConfig({ timeout: abortBinders.promise }).getList(query_params).then(
//       (success: any) => {
//         add_binders_to_requirements(success)
//         $scope.loadingStateService.set_state('content')
//       }
//       , (error: any) => {
//         if (abortBinders && abortBinders.promise && abortBinders.promise.$$state && abortBinders.promise.$$state.value === 'cancel_pending_queries') {
//           // queries canceled by user, do not show flash warning
//           console.log('user canceled, no error')
//         } else {
//           $scope.flashService.add_alert({
//             name: 'get_binders_alert',
//             dismissable: true,
//             class: 'alert-warning',
//             icon: 'fa-warning',
//             message:
//               'Cannot load configured requirements at this time. ' +
//               'Please try again later.'
//           })
//         }
//       })
//   }

//   var add_binders_to_requirements = (binders: any) => _.each(binders, (binder: any) => find_requirement_add_binder(binder))

//   var find_requirement_add_binder = (binder: any) => _.each($scope.requirements, function (requirement: any) {
//     if (requirement.id === binder.requirement_id) {
//       if (requirement.binders === undefined) {
//         return requirement.binders = [binder]
//       } else {
//         return requirement.binders.push(binder)
//       }
//     }
//   })

//   $scope.is_system_admin = () => $rootScope.session.getUser().user_role.role.id === 1

//   /* CRUD */

//   $scope.openBinderCreateModal = function (requirement: any) {
//     var organization_id
//     try {
//       let organization = $rootScope.session.getOrganization()
//       organization_id = organization.id
//     } catch (ex) {}
//     const binder_object = {
//       organization_id: organization_id,
//       requirement_id: requirement.id,
//       requirement: requirement,
//       schedule_enabled: true,
//       display_name: requirement.display_name
//     }

//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/binder_modal.html',
//       controller: 'binderModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(binder_object),
//             ui_setting: 'create'
//           }
//         }
//       }
//     })

//     // on close grab the returned (result) or something with a (reason)
//     return modalInstance.result.then(function (result: any) {
//       return find_requirement_add_binder(result)
//     }

//     , function (reason: any) {
//     });
//   }

//   $scope.viewBinder = (binder_object: any) => $scope.processingStateService.performFunction(binder_object, () => $rootScope.session.goTo(`/requirements/binder/${binder_object.id}`))

//   /* REQUIREMENT */
//   var get_requirement = (id: any) => Restangular.all('requirements').get(id).then((success: any) => {
//     return success.plain();
//     }, 
//     (error: any) => { 
//       this.flashService.process_error(error);
//       throw error;
//     }
//   )
  
//   // https://angular-ui.github.io/bootstrap/#/modal
//   $scope.openRequirementModal = async function (requirement: any, uiSetting: any) {
//     let modalInstance;
//     try {
//       requirement = await get_requirement(requirement.id);
//       modalInstance = $uibModal.open({
//         // templateUrl: 'views/modals/requirement_modal.html',
//         // controller: 'requirementModalController',
//         templateUrl: 'views/modals/ajs-requirement-modal-adapter.html',
//         controller: 'ajsRequirementModalAdapter',
//         size: 'lg',
//         resolve: {
//           // this is the object that can be passed to the modal.
//           // we copy it to eliminate binding issues
//           // require it in the dependecies as modalOptions
//           modalOptions () {
//             return {

//               object: angular.copy(requirement),
//               uiSetting
//             }
//           }
//         }
//       })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     }
//     catch(error){
//       console.error('Error:', error);
//       var object = object || undefined
//       return modalInstance.result.then(function (result: any) {

//       }, function (reason: any) {

//       }).finally(() =>
//         $scope.processingStateService.set(
//           // TODO: ####_CHECK: WHAT IS OBJECT SUPPOSED TO BE ????
//           object,
//           $scope.processingStateService.processing_states.RESTING
//         )
//       );
//     }
//   }

//   /* BULK CONFIGURE */
//   $scope.openBulkConfigureRequirementsModal = function () {
//     var $ctrl = this
//     $ctrl.items = ['configure']
//     var modalInstance = $uibModal.open({
//       // animation: $ctrl.animationsEnabled,
//       component: 'bulkConfigureRequirements', //ngRequirementModal test
//       // templateUrl: 'views/directives/bulk_configure_requirements.html',
//       size: 'xl',
//       backdrop: 'static',
//       resolve: {
//         items: function () {
//           return $ctrl.items
//         }
//       }
//     })

//     modalInstance.result.then(function (params: any) {
//       console.log(params)
//       // $ctrl.selected = selectedItem
//     }, function (params: any) {
//       console.log(params)
//       if (params === 'submit') {
//         abortAllPromises($scope.outstandingPromises)
//         setTimeout(() => get_requirements($scope.previousPills), 50)
//       }
//     })
//   }

//   $scope.exportRequirementsList = function() {
//     $scope.reportLoadingStateService.set_state('loading')
//     let query_params = {
//       report: 'requirements_export',
//       param_data: {}
//     }
//     query_params.param_data = $rootScope.storeService.stringifyTagParams(
//       $rootScope.storeService.pillsToObject($scope.previousPills)
//     )

//     Restangular.setFullResponse(true).one(`reports/requirements_export`).get(query_params).then(function (response: any) {
//       var report_file = new Blob([response.data], { type: 'text/csv' })
//       var report_name = `requirements_export_report_${dayjs().format('YYYY-MM-DD')}.csv`
//       FileSaver.saveAs(report_file, report_name)
//       $scope.reportLoadingStateService.set_state('ready')
//     }, function (error: any) {
//       $scope.reportLoadingStateService.set_state('error')
//     }).finally(() => {
//       // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
//       // Reset the full response setting
//       Restangular.setFullResponse(false)
//     })
//   }

//   $scope.get_report_button_class = () => {
//     if ($scope.reportLoadingStateService.loading_state.name === 'error') {
//       return 'btn-warning'
//     } else {
//       return 'btn-default'
//     }
//   }

//   return init()
// }])
