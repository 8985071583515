import { Component, Input, OnInit, Inject } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { FlashService } from '../../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';

@Component({
  selector: 'ngOrganizationRow',
  templateUrl: './organization-row.component.html',
  styleUrls: ['./organization-row.component.scss']
})

export class OrganizationRowComponent {

  @Input() organization: any;
  @Input() organizations: any;
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  query: string = '';
  flat_organizations: any = [];

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(treeManager) private treeManager: any,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = this.$rootScope.session
  }


  flatten_tree = (tree: any) => {
    const flattened_tree: any = []

    var recursively_flatten = function (branch: any) {
      _.each(branch, function (object: any) {
        flattened_tree.push(object)

        if (object.children) {
          return recursively_flatten(object.children)
        }
      })
    }
  }

  toggleShowChildren = (organization: any) => {
    if (organization.showChildren) {
      organization.showChildren = false
    } else {
      organization.showChildren = true
      this.treeManager.recursively_assign_key(organization.children, 'show', true)
    }
  }

  // change the active organization for user
  attempt_change_organization = (organization: any) => {
    this.processingStateService.performFunction(organization, () => {
      if (Array.isArray(organization.children) && organization.children.length > 0) {
        let organizationHasChildren = true
        let preventFilterChange = false
        this.$rootScope.session.setOrganization(organization, preventFilterChange, organizationHasChildren)
      } else {
        this.$rootScope.session.setOrganization(organization)
      }
      this.$rootScope.session.goTo('/home')
    })
  }

    // combine city and state with correct syntax as needed.
    concat_locality_and_region = function (organization: any) {
      // provide comma if both present
      if (organization.locality && organization.region) {
        return `${organization.locality}, ${organization.region}`
      } else if ((organization.locality === null) || (organization.locality === '')) {
        return organization.region
      } else if ((organization.region === null) || (organization.region === '')) {
        return organization.locality
      } else {
        // if neither provided, return empty string
        return ''
      }
    }

  /* CRUD */

  openOrganizationReadModal = (organization_object: any, organizations: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(organization_object, () => this.openOrganizationModal(organization_object, 'read', organizations))
  }

  openOrganizationUpdateModal = (organization_object: any, organizations: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(organization_object, () => this.openOrganizationModal(organization_object, 'update', organizations))
  }

  openOrganizationDestroyModal = (organization_object: any) => {
    let that = this
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm () { return that.destroy_organization(organization_object) },
      message: `<p>This action will:</p><ul><li>Delete ${organization_object.name}</li><li>Remove all the user associations that are connected to ${organization_object.name}</li><li>Delete all configured requirements belonging to ${organization_object.name}</li><li>Delete all requirement entries, attachments and notifications associated with the aforementioned configured requirements</li></ul><p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(organization_object, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then((result: any) => {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          this.processingStateService.set(organization_object, this.processingStateService.processing_states.RESTING)
        }
        return true
      })
    );
  }

  destroy_organization = (organization: any) => {
    this.Restangular.all(`organizations/${organization.id}`).remove().then((success: any) => {
      this.treeManager.remove_object_from_tree(this.organizations, organization)
      if (this.$rootScope.session.getOrganization() && (this.$rootScope.session.getOrganization().id === organization.id)) {
        return this.$rootScope.session.setOrganization(null)
      }
    },(error: any) => {
      this.processingStateService.set(organization, this.processingStateService.processing_states.RESTING)
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  openOrganizationModal = function (organization_object: any, ui_setting: any, organizations: any) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/organization_modal.html',
      controller: 'organizationModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            organization: angular.copy(organization_object),
            ui_setting,
            
            organizations: angular.copy(organizations)
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then((result: any) => {
      if (ui_setting === 'update') {
        this.treeManager.update_object_in_tree(this.organizations, result)
      }

      if (this.$rootScope.session.getOrganization() && (this.$rootScope.session.getOrganization().id === result.id)) {
        return this.$rootScope.session.setOrganization(result)
      }
    }, (reason: any) => {
    }).finally(() => this.processingStateService.set(organization_object, this.processingStateService.processing_states.RESTING));
  }
}

angular
  .module('complyosClient')
  .directive('ngOrganizationRow', downgradeComponent({ component: OrganizationRowComponent }) as angular.IDirectiveFactory)