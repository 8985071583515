import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FlashService } from '../../../services/flash_service.service';
import { AuthenticationService } from '../../../services/authentication_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import angular from 'angular';

@Component({
  selector: 'ngLogin',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private authenticationService: any;

  // Properties
  private ssoAuthenticationButtons: Array<any>;
  private localAuthenticationButtons: Array<any>;
  loginForm: FormGroup;
  failedSubmission: boolean = false;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
    @Inject(AuthenticationService) authenticationService: AuthenticationService,
    private Restangular: Restangular,
    private fb: FormBuilder
  ) { 
    this.flashService = angular.copy(flashService)
    this.authenticationService = authenticationService
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
      // We have decided not to use this for now. 
      // remember_me: [false]
    });
  }

  
  ngOnInit(): void {
    this.flashService.listen_for_alert(this.$scope)
    this.listenForAuthentication()
  }

  submitForm = () => {
    if (this.loginForm.invalid) {
      this.failedSubmission = true;
      return
    } else {
      const userObject = this.loginForm.value;
      return this.authenticationService.submit_user_credentials(userObject)
    }
  }

  listenForAuthentication = () => {
    this.$scope.$on('userIsNotAuthenticated', (event: any, args: any) =>
      this.flashService.add_alert({
        name: `${args.status}_alert`,
        dismissable: false,
        class: 'alert-danger',
        icon: 'fa-warning',
        message: 'Access Denied'
      })
    )
  }
}

angular
  .module('complyosClient')
  .directive('ngLogin', downgradeComponent({ component: LoginComponent }) as angular.IDirectiveFactory)
