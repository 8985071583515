import { Component, OnInit, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
var dayjs = require('dayjs')

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePicker),
  multi: true
};
@Component({
  selector: 'date-picker',
  template: 
  `<form [formGroup]="dateForm">
      <mat-form-field>
        <input type="hidden" [ngModelOptions]="{standalone: true}" [(ngModel)]="value" [matDatepickerFilter]="dateValidator || noFilter" [matDatepicker]="picker" placeholder="Choose a date" [disabled]="isCalendarDisabled">
        <input matInput type="text" [textMask]="mask" [ngModel]="getFormattedDate()" [disabled]="isCalendarDisabled"
        [ngModelOptions]="{standalone: true}" (blur)="onBlur()" (input)="todate('change', $event.target.value)" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </form>
    <mat-hint *ngIf="!isCalendarDisabled" style="font-size: 10px;">MM/DD/YYYY</mat-hint>`,
  styleUrls: ['./date-picker.scss'],
})

export class DatePicker implements OnInit {
  @Input() uiSetting: string;
  @Input() isCalendarDisabled: boolean;
  @Input() isDisabled: boolean;
  @Input() inputDate: any;
  @Input() dateValidator: (date: Date | null) => boolean | null = null;
  @Output() dateSelected = new EventEmitter<any>();
  noFilter = () => true;

  private formattedDate: any;
  public mask = {
    guide: true,
    showMask: true,
    // keepCharPositions : true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  dateForm: FormGroup;

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    // this will deal with timezone issues
    this.inputDate = dayjs(this.inputDate).toDate()
    this.createDateForm();
  }

  isDateValid(): boolean {
    const dateValue = this.dateForm.get('date')?.value;
    return dateValue && dayjs(dateValue).isValid();
  }

  getFormattedDate(): string {
    const dateValue = this.dateForm.get('date')?.value;
    return this.isDateValid() ? dayjs(dateValue).format('MM/DD/YYYY') : '';
  }

  createDateForm() {
    this.dateForm = this._fb.group({
      date: [this.inputDate || '']
    })
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get value(): any {
    return this.dateForm.controls.date.value;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.dateForm.controls.date.value) {
      this.todate('change', v)
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  todate(type: string, value: any) {
    const newDate = value;
    if (this.isValidDate(newDate)) {
      this.dateForm.controls['date'].setValue(newDate);
      this.onChangeCallback(newDate);
      this.dateSelected.emit(newDate);
    } else {
      this.dateForm.controls['date'].markAsDirty();
      this.dateSelected.emit(newDate);
    }
  }

  isValidDate(date: any): boolean {
    return dayjs(date, 'MM/DD/YYYY', true).isValid();
  }

  onBlur() {
    if (this.dateForm.controls.date) {
      this.onChangeCallback(this.dateForm.controls.date);
    }
  }

  onDateChanged(type: string, event: any): void {
    this.dateSelected.emit(event.value)
  }
}
