/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'


angular.module('complyosClient').directive('pageResultsRange', [
  'fortyCore', 'constants', 'arrayFunctions', 'helpService',
  (fortyCore, constants, arrayFunctions, helpService) => ({
    templateUrl: 'views/directives/pagination/page_results_range.html',
    restrict: 'E',

    scope: {
      paginationParams: '=',
      paginationResults: '<'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
    },

    registerFunctions ($scope: any) {
      $scope.getResultsRangeStart = () => {
        let pagination = $scope.paginationResults

        let rangeStart = (pagination.currentPage - 1) * pagination.pageSize
        if (pagination.itemCount > 0) {
          rangeStart = rangeStart + 1
        }

        return rangeStart
      }

      $scope.getResultsRangeEnd = () => {
        let pagination = $scope.paginationResults

        let rangeEnd = (pagination.currentPage - 1) * pagination.pageSize
        if (pagination.itemCount > 0) {
          rangeEnd = rangeEnd + pagination.itemCount
        }

        return rangeEnd
      }

      return $scope;
    }
  })

])
