import { Component, EventEmitter, Input, OnInit, Output, Inject, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { fortyCore } from '../../../scripts/services/fortyau_services';
import { helpService } from '../../../scripts/services/help';
// import * as Restangular from 'restangular'
import * as Restangular from '../../../vendor/restangular/restangular'
import angular from 'angular';
import { orderBy } from 'lodash'
import { FormControl } from '@angular/forms';
import { $q } from 'angular-ui-router';
import { FlashService } from '../../services/flash_service.service';

@Pipe({
  name: 'orderBy'
})

export class OrderByPipe implements PipeTransform {
  transform = orderBy;
}
@Component({
  selector: 'ngNoteManager',
  templateUrl: './note-manager.component.html',
})

export class NoteManager implements OnInit, OnDestroy {
  newNoteTextControl: FormControl;

  private noteListLabel: string = "";
  private placeholder: string = "";
  private nm: any = "";
  processingStateService: any;
  text: any;
  private unregister_ajs_note_save_listener

  @Input() allowCreate!: boolean;
  @Input() allowRead!: boolean;
  @Input() allowUpdate!: boolean;
  @Input() allowUpdateSingle!: boolean;
  @Input() allowWysiwyg!: boolean;
  @Input() defaultNote!: string;
  @Input() type!: string;
  @Input() noteableId!: number;
  @Input() parentId!: string;
  @Input() noteableType!: string;
  @Input() notesReadLabel!: string;
  @Input() hideNotesReadLabel!: boolean;
  @Input() noteCreateLabel!: boolean;
  @Input() hideNoteCreateLabel!: boolean;
  @Input() formModel: any;
  @Input() validationModel: any;
  @Input() uiSetting: string;
  @Input() required!: boolean;

  @Output() removeCallback = new EventEmitter<number>();
  // @Output() formModelChange = new EventEmitter<any>();
  @Output() validationModelChange = new EventEmitter<string>();

  constructor(
    @Inject(FlashService) private flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,    // Could not be 'class'-ified so use a string as injection key
    @Inject(helpService) private helpService: any, // Replace with appropriate value
    private Restangular: Restangular,
    @Inject('$scope') private $scope,
    //@Inject('$attributes') private $attributes,
    @Inject('$rootScope') private $rootScope

    ) {
    
    this.processingStateService = fortyCore.processingStateService;
    this.helpService = helpService
    this.text = fortyCore.text
  }

  ngOnInit() {
    // TODO this placeholder buisness should be done way before it gets to the manager, move it.
    if (this.noteListLabel) {
      this.placeholder = `Provide a detailed description of what has changed on this requirement. \
      For each change, the field that was changed and what the field has changed to should be included.`
    } else {
      this.placeholder = ''
    }
    // $ngModel.$validate() causes modelValue to go undefined
    // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
    // return $ngModel.$options = {
    //   allowInvalid: true
    // }

    this.nm = {
        allow_create: this.allowCreate,
        allow_read: this.allowRead,
        allow_update: this.allowUpdate,
        allow_update_single: this.allowUpdateSingle,
        allow_wysiwyg: this.allowWysiwyg,
        default_note: this.defaultNote,
        failure: undefined,
        ng_model_value: angular.copy(this.formModel),
        new_note: {
          noteable_id: this.noteableId,
          noteable_type: this.noteableType,
          parent_id: this.parentId,
          type: this.type,
          text: undefined
        },
        noteCreateLabel: this.noteCreateLabel ? this.noteCreateLabel : 'New Note',
        hideNoteCreateLabel: this.hideNoteCreateLabel,
        notesReadLabel: this.notesReadLabel ? this.notesReadLabel : 'All Notes',
        hidenotesReadLabel: this.hideNotesReadLabel
    }


    //this.add_validators()
    this.watch_for_text_change()
    this.watch_for_edit_change()
    this.watch_ng_model()
    this.listen_for_start_event()
    this.watch_for_default_note()
    this.watch_notable_id()
  }
  ngOnDestroy(): void {
    // Angular $scope does not get destroyed when using tabs
    // must manually unregister
    if (this.unregister_ajs_note_save_listener) {
      this.unregister_ajs_note_save_listener()
    }
  }

  format_placeholder_text(type: any) {
    const trimmed_type = this.text.trimPhrase(type, type.lastIndexOf(':') + 1, type.length)
    const new_placeholder = this.text.splitWords(trimmed_type, '(?=[A-Z])')
    return new_placeholder
  }
    
  begin_api_operations () {
    const promises = []
    // console.log @new_note
    // add save promise to the stack (if needed of course)
    if (this.nm.new_note.text && (this.nm.new_note.text.trim !== '')) {
      promises.push(this.create_note())
    }

    // add all the update promises to the stack
    for (let note of Array.from(this.nm.ng_model_value)) {
      if (
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        (note.old_text !== undefined) && // there is a note that we are editing
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        (note.text.trim !== '') // and the change is not empty
      ) {
        promises.push(this.save_edits(note))
      }
    }

     // run each and every promise then finish
     return $q.all(promises).then((success: any) => {
      this.emit_completion_event()
    }
    , function (error: any) {
      // alerts should be emitted by the individual promise that failed
    }).finally(function () {
    });
  }

  create_note () {
    return this.Restangular.all('notes').post(this.nm.new_note).then((success: any) => {
      this.nm.ng_model_value.push(success.plain())
      this.nm.new_note.text = ''
    }
    , function (error: any) {
      this.flashService.emit_alert(
        this.$scope,
        this.flashService.default_alert_by_code(error.status)
      )
    }).finally(function () {
    });
  }

  edit_note (note: any) {
    return note.old_text = angular.copy(note.text)
  }

  abandon_edits (note: any) {
    note.text = angular.copy(note.old_text)
    return delete note.old_text
  }

  save_edits (note: any) {
    return this.Restangular.one('notes', note.id).patch(note).then(function (response: any) {
    }, (error: any) => this.flashService.emit_alert(
      this.$scope,
      this.flashService.default_alert_by_code(error.status)
    )
    ).finally(function () {
    });
  }

  attribution (note: any) {
    if (note.user !== undefined) {
      return `by ${note.user.profile.display_name}`
    } else {
      return ''
    }
  }

  check_yo_self (note: any) {
    if (note.user !== undefined) {
      if (note.user.profile.display_name === this.$rootScope.session.getUser().profile.display_name) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  get_icon (note: any) {
    switch (note.category.toLowerCase()) {
      case 'activity': return 'fa-clock-o'
      case 'comment': return 'fa-comment-o'
      case 'review note': return 'fa-clipboard'
      default: return 'fa-sticky-note-o'
    }
  }

  // /* VALIDATION */
  get_validaton_length () {
    // console.log "checking text length"
    let length = 0
    if (this.nm.allow_create && this.nm.new_note.text) {
      // console.log "the text is #{@new_note.text}"
      ({ length } = this.nm.new_note.text)
    } else if (this.nm.allow_update_single && this.nm.ng_model_value && this.nm.ng_model_value[0] && this.nm.ng_model_value[0].text) {
      ({ length } = this.nm.ng_model_value[0].text)
    }
    console.log ("length is", length)
    return length
  }

  // add_validators () {
  //   return this.formModel.required = (modelValue: any, viewValue: any) => {
  //     // console.log "validating text length"
  //     if ((this.required === false) || (this.required === undefined)) {
  //       // console.log "valid == true"
  //       return true
  //     } else {
  //       // console.log "valid == #{@get_validaton_length() > 0}"
  //       return this.get_validaton_length() > 0
  //     }
  //   };
  // }


  watch_for_text_change () {
    return this.$scope.$watch(() => {
      return this.nm.new_note.text
    });
  }

  watch_for_edit_change () {
    return this.$scope.$watch(() => {
      return this.nm.new_note.text
    });
  }

  // /* WATCHERS, LISTENERS AND EMITTERS */
  listen_for_start_event () {
    if (this.uiSetting != 'read') {
      this.unregister_ajs_note_save_listener = this.$scope.$on('note_manager_start', (event: any, data: any) => {
        this.nm.new_note.noteable_id = data.id
        return this.begin_api_operations()
      });
    }
  }

  watch_for_default_note = () => {
    this.$scope.$watch(() => this.defaultNote, (newValue: any, oldValue: any) => {
      this.nm.default_note = newValue
      this.nm.new_note.text = newValue
      this.defaultNote = newValue
    })
  }

  emit_completion_event () {
    const return_object = {
      manager: 'note_manager',
      object: this.formModel,
      fault: this.nm.failure
    }
    return this.$scope.$emit('note_manager_finished', return_object)
  }

  // this syncronizes the $ngModel.$modelValue and @ng_model_value
  // we use @ng_model_value in the view exclusively
  watch_ng_model () {
    return this.$scope.$watch(() => this.formModel, (new_value: any, old_value: any, scope: any) => {
      // console.log $ngModel.$modelValue
      this.nm.ng_model_value = this.formModel
      // $scope.$apply()
    });
  }

  // added these lines of code due to the ng model and scoped variables not updating here
  // when they are updated on the controler. this was creating outdated scope issues
  
  // this first bit updates the ngModel value, which is usually an entry's notes
  // this.ngModel.$render = () => this.$scope.nm.ng_model_value = this.ngModel.$modelValue
  
  // this watches for updates to the noteable id. traditionally when saving a note, it was part
  // of a modal and the note manager would be dismissed immediately, so you couldnt add multiple notes.
  // but now that the batch review modal allows multiple notes to be entered at a time and on mutliple
  // entries without closing the modal, the id has to be updated so the notes are added to the correct
  // entry
  watch_notable_id () {
    this.$scope.$watch(() => this.noteableId, () => {
      if (
        this.noteableId && // if there is a noteableID
        this.nm.new_note // and there is a place to place it...
      ) {
        return this.nm.new_note.noteable_id = this.noteableId
      }
    })
  }
  
  updateNotesText (event: any) {
    // $scope.requirement.instructions = event
    if(this.allowUpdateSingle) {
      this.nm.ng_model_value[0].text = event
    } else {
      this.nm.new_note.text = event
    }
    this.validationModel = event
    this.validationModelChange.emit(this.validationModel)
  }


  // there is a delay between the time the directive is ready and ngModel is
  // we watch for ready and run code once
  // let ng_model_ready = false
  // return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
  //   if (ng_model_ready === false) {
  //     $scope.nm.initialize()
  //     return ng_model_ready = true
  //   }
  // });
}

angular
  .module('complyosClient')
  .directive('ngNoteManager', downgradeComponent({ component: NoteManager }) as angular.IDirectiveFactory)
