
import * as angular from 'angular';
import _ from 'lodash';
import { Injectable, Inject } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
var dayjs = require("dayjs")

@Injectable()
export class DateService {

  constructor(
  ) {
  }

  convert_from_imt_date (date: any) {
    // An inherit problem that we encountered with new Date() was that
    // depending on time of day and where located, the day can fluctuate.
    // As the date picker needs a JS Date object, there might be some conversion confusion
    // e.g. DB -> "2017-05-03".... new Date("2017-05-03") -> Tues May 2nd, 2017...This all has to do with time zones.
    // To always be 100% sure that the day being displayed is what is being returned from the database,
    // we crate a dayjs() on that specific day from the datebase, that mimics a JS Date object only the time is set to 0.0.0
    date = dayjs(date)
    return date.$d
  }

  convert_to_imt_date (date: any) {
    date = dayjs(date).format('YYYY-MM-DD')
    return date
  }

  convert_dates_before_saving (object: any, date_keys: any) {
    const obj = object
    _.each(date_keys, (key: any) => {
      return obj[key] = this.convert_to_imt_date(obj[key])
    })
    return obj
  }

  date_in_range (date: any, range_begin: any, range_end: any) {
    return (date >= range_begin) && (date <= range_end)
  }

  return_date_object (string: any) {
    return dayjs(string).$d
  }

  binder_date_validation (date_helper_object: any, parameters: any) {
    date_helper_object.start_date.visible = false
    const now = dayjs().startOf('day').$d
    let error_count = 0

    // binder schedule start date can not be in the past when binder modal
    // is in update mode
    if (dayjs(parameters.schedule_start_date).isBefore(now, 'days') > 0) {
      date_helper_object.start_date.message = 'Schedule Start Date can not be in the past'
      date_helper_object.start_date.visible = true
      error_count++
    }

    return error_count
  }

  organization_date_validation (date_helper_object: any, parameters: any) {
    date_helper_object.date_survey_next_begin.visible = false
    date_helper_object.date_survey_next_end.visible = false
    let error_count = 0

    // if the begin date is after the end date
    if (dayjs(parameters.date_survey_next_begin).startOf('day').diff(dayjs(parameters.date_survey_next_end).startOf('day'), 'days') > 0) {
      date_helper_object.date_survey_next_begin.message = 'Start date must come before end date'
      date_helper_object.date_survey_next_begin.visible = true
      error_count++
    }

    // if the end date is earlier than the begin date
    if (dayjs(parameters.date_survey_next_end).startOf('day').diff(dayjs(parameters.date_survey_next_begin).startOf('day'), 'days') < 0) {
      date_helper_object.date_survey_next_end.message = 'End date must come after start date'
      date_helper_object.date_survey_next_end.visible = true
      error_count++
    }

    // Dates must be populated in a set, there can (not) only be one
    if (
      dayjs(parameters.date_survey_next_begin).isValid() &&
      (!dayjs(parameters.date_survey_next_end).isValid() || parameters.date_survey_next_end === undefined)
    ) {
      date_helper_object.date_survey_next_end.message = 'End date is required for survey date range'
      date_helper_object.date_survey_next_end.visible = true
      error_count++
    }

    if (
      (!dayjs(parameters.date_survey_next_begin).isValid() || parameters.date_survey_next_begin === undefined) &&
      dayjs(parameters.date_survey_next_end).isValid()
    ) {
      date_helper_object.date_survey_next_begin.message = 'Begin date is required for survey date range'
      date_helper_object.date_survey_next_begin.visible = true
      error_count++
    }

    // require an entity selection if there is a value in any of the date fields
    if (
      (dayjs(parameters.date_survey_last).isValid() ||
      dayjs(parameters.date_survey_next_begin).isValid() ||
      dayjs(parameters.date_survey_next_end).isValid()) &&
      parameters.surveying_entity === null
    ) {
      date_helper_object.surveying_entity.message = 'An entity selection is required'
      date_helper_object.surveying_entity.visible = true
      error_count++
    }

    return error_count
  }

  date_range_validation (date_helper_object: any, parameters: any) {
    date_helper_object.begin_date.visible = false
    date_helper_object.end_date.visible = false
    const now = dayjs().startOf('day').$d
    let error_count = 0

    // if the begin date is after the end date
    if (dayjs(parameters.date_range_begin).startOf('day').diff(dayjs(parameters.date_range_end).startOf('day'), 'days') > 0) {
      date_helper_object.begin_date.message = 'Begin date must come before end date'
      date_helper_object.begin_date.visible = true
      error_count++
    }

    // if the end date is earlier than the begin date
    if (dayjs(parameters.date_range_end).startOf('day').diff(dayjs(parameters.date_range_begin).startOf('day'), 'days') < 0) {
      date_helper_object.end_date.message = 'End date must come after begin date'
      date_helper_object.end_date.visible = true
      error_count++
    }

    // if the begin date hasn't occurred yet
    if (dayjs(parameters.date_range_begin).diff(now, 'days') > 0) {
      date_helper_object.begin_date.message = 'The chosen begin date can not be a future date'
      date_helper_object.begin_date.visible = true
      error_count++
    }

    // if the end date hasn't occurred yet
    if (dayjs(parameters.date_range_end).diff(now, 'days') > 0) {
      date_helper_object.end_date.message = 'The chosen end date can not be a future date'
      date_helper_object.end_date.visible = true
      error_count++
    }

    // taken out for now, leaving in for later use if needed
    // if the begin date is greater than 3 years ago to the days. 3 years is 1,095 days. use that number to see if
    // the difference in days is greater than that number. I add 1 day to the 1,095 due to .startOf('day') discrepancies.
    // under this condition a user should be able to go back exactly 3 years to the day and pull back data.
    // if dayjs(parameters.date_range_begin).startOf('day').diff(now.startOf('day'), 'days') < -1096
    //   date_helper_object.begin_date.message = 'Begin date must start after ' + dayjs().subtract(3,'years').subtract(1,'days').format('MMMM DD, YYYY')
    //   date_helper_object.begin_date.visible = true
    //   # date_range_form.failed_submission = true
    //   error_count++

    // if the begin date is blank
    //@ts-ignore
    if (date_range_form.begin_date === '') {
      date_helper_object.begin_date.message = 'Schedule Start Date is required'
      date_helper_object.begin_date.visible = true
    }

    // if the end date is blank
    //  these two were date_range_form.end_date.$invalid. but due to scope changed to empty strings
    //@ts-ignore
    if (date_range_form.end_date === '') {
      date_helper_object.end_date.message = 'End date is required'
      date_helper_object.end_date.visible = true
    }

    return error_count
  }
}

angular
  .module("complyosClient")
  .factory("dateService", downgradeInjectable(DateService));