/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import _ from 'lodash';
// import * as _ from 'lodash';

var complyosClient = angular.module('complyosClient')

complyosClient.controller('requirementsTagFilterController', ['$rootScope', '$scope', 'loadingStateService', 'objectManager', 'Restangular', function (
  $rootScope: any,
  $scope: any,
  loadingStateService: any,
  objectManager: any,
  Restangular: any
) {
  const init = function () {
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.loadingStateService.init()
    return get_tags()
  }

  var get_tags = () =>
    Restangular.all('tags').getList().then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      return $scope.tags = success
    }

    , (error: any) => $scope.loadingStateService.process_error(error))

  $scope.tag_filter_object = {
    tags: []
  }

  $scope.reset_tag_filter_object = function () {
    $scope.tag_filter_object.tags = []
    return emit_tag_filter_object()
  }

  $scope.filter_object_includes = (tag: any) => _.includes($scope.tag_filter_object.tags, tag)

  const add_to_filter_oject = (tag: any) => objectManager.array_action($scope.tag_filter_object.tags, tag, 'merge')

  const remove_from_filter_oject = (tag: any) => objectManager.array_action($scope.tag_filter_object.tags, tag, 'remove')

  $scope.toggle_tag = function (tag: any) {
    if ($scope.filter_object_includes(tag)) {
      remove_from_filter_oject(tag)
      return emit_tag_filter_object()
    } else {
      add_to_filter_oject(tag)
      return emit_tag_filter_object()
    }
  }

  var emit_tag_filter_object = () => $rootScope.$emit('tag_filter_updated', $scope.tag_filter_object)

  return init()
}])
