/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('messagesController', ['$rootScope', '$scope', 'flashService', function (
  $rootScope: any,
  $scope: any,
  flashService: any
) {
  /* FLASHES */
  
  $scope.flashService = angular.copy(flashService)

  $rootScope.$on('add_global_alert', (event: any, data: any) => {
    $scope.flashService.add_alert(data)
  })

  $rootScope.$on('remove_global_alert_by_name', (event: any, alertName: any) => {
    $scope.flashService.remove_alert_by_name(alertName)
  })

  $rootScope.$on('clear_global_alerts', (event: any, data: any) => {
    $scope.flashService.clear_alerts()
  })
}])
