import { Component, Inject, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import angular from 'angular';
import { ArrayFunctions } from '../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngPopout',
  templateUrl: './popout.component.html'
})
export class PopoutComponent implements OnInit, OnChanges {
  // Properties
  @ViewChild('eleRef') elRef!: ElementRef;
  @Input() isPopoutOpen: boolean;
  @Input() popupPositioning: string;
  @Output() clickOutsideOfPopout = new EventEmitter<void>();
  
  constructor(

    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.helpService = angular.copy(helpService)
    this.fortyCore = angular.copy(fortyCore)
  }

  ngOnInit(): void {
    this.popupPositioning = this.popupPositioning || 'absolute'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isPopoutOpen && changes.isPopoutOpen.currentValue === true) {
      this.repositionPopout()
    }
  }

  callClickOutsideOfPopout() {
    if (this.clickOutsideOfPopout.observers.length > 0) {
      console.log(this.clickOutsideOfPopout.observers)
      this.clickOutsideOfPopout.emit();
    } else {
      console.warn('No subscribers for onClickOutsideOfPopout event');
    }
  }

  repositionPopout = () => {
    if (this.isPopoutOpen === true && this.popupPositioning == 'fixed') {
      let targetRect = this.elRef.nativeElement.find('.popout-target')[0].getBoundingClientRect()
      console.log(targetRect)
      let top = targetRect.y
      let left = targetRect.x
      
      let content = this.elRef.nativeElement.find('.popout-content')[0]
      console.log(content)
      content.style.position = 'fixed'
      content.style.top = top + 'px'
      content.style.left = left + 'px'
    }
  }

  togglePopout = () => {
    this.isPopoutOpen = !this.isPopoutOpen
    if (this.isPopoutOpen === false) {
      this.callClickOutsideOfPopout()
    }
  }
  
}

angular
  .module('complyosClient')
  .directive('ngPopout', downgradeComponent({ component: PopoutComponent }) as angular.IDirectiveFactory)
