import { Component} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FlashService } from '../../../services/flash_service.service';
import { AuthenticationService } from '../../../services/authentication_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import angular from 'angular';

@Component({
  selector: 'ngUpsell',
  templateUrl: './upsell.component.html'
})

export class UpsellComponent {}

angular
  .module('complyosClient')
  .directive('ngUpsell', downgradeComponent({ component: UpsellComponent }) as angular.IDirectiveFactory)
