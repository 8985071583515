/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('complyosUsersController', ['$rootScope', '$scope', '$uibModal', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
  $rootScope: any,
  $scope: any,
  $uibModal: any,
  confirmationModalFactory: any,
  flashService: any,
  fortyCore: any,
  loadingStateService: any,
  objectManager: any,
  Restangular: any,
  utils: any
) {
  const init = function () {
    
    $scope.flashService = angular.copy(flashService)
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.loadingStateService.init()
    $scope.processingStateService = fortyCore.processingStateService

    $scope.user_query = ''

    get_users()
  }

  var get_users = () =>
    Restangular.all('users').getList().then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      $scope.users = success
      $scope.order_users_by_function = $scope.order_users_by_display_name
    }, function (error: any) {
      $scope.loadingStateService.process_error(error)
      utils.log(error)
    })

  /* FILTER */

  $scope.user_filter = function (user: any) {
    const search_string = $scope.user_query.toLowerCase()

    // display_name
    if ((user.profile.display_name.toLowerCase()).indexOf(search_string) >= 0) {
      return true
    }

    // role
    if (
      (user.user_role.role.title !== null) &&
      (user.user_role.role.title !== undefined) &&
      ((user.user_role.role.title.toLowerCase()).indexOf(search_string) >= 0)
    ) {
      return true
    }

    // first name
    if (
      (user.profile.name_first !== null) &&
      (user.profile.name_first !== undefined) &&
      ((user.profile.name_first.toLowerCase()).indexOf(search_string) > 0)
    ) {
      return true
    }

    // last name
    if (
      (user.profile.name_last !== null) &&
      (user.profile.name_last !== undefined) &&
      ((user.profile.name_last.toLowerCase()).indexOf(search_string) >= 0)
    ) {
      return true
    }

    //  organizations
    for (let organization of Array.from(user.organizations)) {
      if (((organization as any).name.toLowerCase()).indexOf(search_string) >= 0) {
        return true
      }
    }
  }

  /* SORT */

  $scope.order_users_by_display_name = (user: any) => user.profile.display_name

  $scope.order_users_by_role = (user: any) => user.user_role.role.title

  $scope.order_users_by_organization = function (user: any) {
    // if to see if the user even has the organization attribute
    if (user.organizations.length !== 0) {
      return user.organizations[0].name
    } else {
      return 'ZZZ' // so they show up in the list last
    }
  }

  /* CRUD */

  $scope.view_user = (user_object: any) => {
    $scope.processingStateService.performFunction(
      user_object,
      () => {
        $rootScope.session.goTo(`/user/${user_object.id}`)
      }
    )
  }

  $scope.create_user = function () {
    // set the default organizations for new users.
    const new_user_object = {
      organizations: $rootScope.session.getUser().organizations
    }
    openUserModal(new_user_object, 'create')
  }

  $scope.openUserReadModal = (user_object: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(
      user_object,
      () => { openUserModal(user_object, 'read') }
    )
  }

  $scope.openUserUpdateModal = (user_object: any) => // State service will set the object to a state of PENDING
  // After action is performed or cancelled,
  // that action will be responsible for setting object to RESTING state
  $scope.processingStateService.performFunction(
    user_object,
    () => { openUserModal(user_object, 'update') }
  )

  $scope.openUserDestroyModal = function (user_object: any) {
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm () { destroy_user(user_object) },
      message: `<p>This action will:</p> \
                <ul> \
                <li>Delete the user ${user_object.profile.display_name}</li> \
                <li>Delete or nullify all of the records associated with this user</li> \
                </ul> \
                <p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(user_object, () =>
      confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          $scope.processingStateService.set(
            user_object,
            $scope.processingStateService.processing_states.RESTING
          )
        }
        return true
      })
    )
  }

  var destroy_user = (user: any) => Restangular.all(`users/${user.id}`).remove().then((success: any) => objectManager.array_action($scope.users, user, 'remove'), function (error: any) {
    $scope.flashService.process_error(error)
    return utils.log(error)
  }).finally(() =>
    $scope.processingStateService.set(
      user,
      $scope.processingStateService.processing_states.RESTING
    )
  )

  // https://angular-ui.github.io/bootstrap/#/modal
  var openUserModal = function (user_object: any, ui_setting: any) {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modals/user_modal.html',
      controller: 'userModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            object: angular.copy(user_object),
            ui_setting
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    return modalInstance.result.then(function (result: any) {
      if (ui_setting === 'create') {
        objectManager.array_action($scope.users, result, 'merge')
      }
      if (ui_setting === 'update') {
        objectManager.array_action($scope.users, result, 'update')
        if ($rootScope.session.getUser().id === result.id) {
          $rootScope.session.setProfile(result.profile)
        }
      }
    }, function (reason: any) {
      // console.log(reason)
    }).finally(() => {
      $scope.processingStateService.set(
        user_object,
        $scope.processingStateService.processing_states.RESTING
      )
    });
  }

  return init()
}])
