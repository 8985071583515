/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  camelcase,
  eqeqeq
*/
'use strict'

import * as angular from 'angular';
/* IMPORT STATEMENTS */
import {tab_config} from './component_configs/bulk_action_configs'
var dayjs = require("dayjs")
// import * as dayjs from "dayjs"
// import { default as dayjs } from 'dayjs'


angular.module('complyosClient').component('bulkConfigureRequirements', {
  templateUrl: 'views/directives/bulk_configure_requirements.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
    bulk_type: '<'
  },
  controller: ['$q', '$rootScope', '$scope', 'fortyCore', 'arrayFunctions', 'constants', 'helpService', 'queueProcessorService', 'Restangular', 'storeService', 'confirmationModalFactory', 'utils', 'flashService',
    function(
      this: any,
      $q: any,
      $rootScope: any,
      $scope: any,
      fortyCore: any,
      arrayFunctions: any,
      constants: any,
      helpService: any,
      queueProcessorService: any,
      Restangular: any,
      storeService: any,
      confirmationModalFactory: any,
      utils: any,
      flashService: any
    ) {
      var $ctrl = this;

      $ctrl.$onInit = function () {
        $scope = $ctrl.registerFunctions($scope)
        $scope.flashService = angular.copy(flashService)
        $scope.role_title = $rootScope.session.getUser().user_role.role.title
        $scope.bulkConfigure = {
          // isTagFilterPopoutOpen: false,
          previousPills: [],

          organizationsList: [],
          requirementsList: [],
          tagsList: [],

          multiInserter: {}
        }
        $scope.stagingArea = {
          editRowNumber: -1,
          stagedRequirements: [],
          editOrganizationOptionsList: [],
          editRequirementsOptionsList: [],
          validationQueue: {
            items: 0,
            isRunning: false
          }
        }
        $scope.masterLists = {
          OrganizationsList: [],
          RequirementsList: [],
          TagsList: []
        }
        $scope.isLoading = false
        $scope.schedule_states = []
        $scope.outstandingPromises = []

        $scope.show_active_help_text = false
        $scope.active_help_text = 'These requirements are not compatable because they don\'t share any common schedule states.'
        $scope.validationMessages = []

        $ctrl.items = $ctrl.resolve.items;
        $ctrl.selected = {
          bulk_type: $ctrl.items[0],
          unpaged_pills: $ctrl.items[1],
          binder_count: $ctrl.items[2]
        }
        $scope.hasPendingChanges = false

        $scope.assignments_to_update = [{value: null, name: 'Select an assignment...'}, { value: 'user_compliance_admin', name: 'Compliance Leader' }, { value: 'user_responsible', name: 'Program Leader' }, { value: 'user_assigned', name: 'Task Agent' }]
        $scope.bulkConfigure.multiInserter.selected_assign = $scope.assignments_to_update[0].value

        $scope.bulk_type = $ctrl.selected.bulk_type
        $scope.unpagedPills = $ctrl.selected.unpaged_pills
        $scope.configure_assign_help_text = 'Clicking Submit will change the assignment for all records in Pending Changes.'

        $scope.schedule_states_dissalowing_assignment = ['no_schedule', 'managed_by_paired_application']
        $scope.reloadRequired = false;
        $scope.annotationUpdated = false;

        $scope.$on('requirementsLoaded', function (e: any) {
          $scope.loadRequirements()
        })

        if ($scope.bulk_type === 'configure') {
          $scope.loadOptionsLists()
        }

        if ($scope.bulk_type !== 'configure') {
          $scope.tab_config = tab_config.loadConfig($scope.bulk_type)
          $scope.getBinders($scope.unpagedPills)
        }

        // $scope.loadScheduleGenerationStateOptions()
        $scope.initializeScheduleStates()
        // $scope.watchEntityFilter()
      }

      $ctrl.ok = function () {
        if ($scope.bulk_type !== 'archive') {
          $scope.stagingAreaToCsv()
        } else if ($scope.bulk_type === 'archive') {
          $scope.confirm_archive()
        } else {
          $scope.stagingAreaToCsv()
        }
        // $ctrl.close({ $value: 'cancel' })
      }

      $ctrl.cancel = function () {
        queueProcessorService.emptyQueue('stagingAreaValidationQueue')
        if ($scope.bulk_type !== 'configure') {
          $scope.stagingArea.stagedRequirements = []
        }
        $scope.abortAllPromises($scope.outstandingPromises)

        if($scope.bulk_type === 'configure' && $scope.reloadRequired){
          $ctrl.dismiss({ $value: 'submit' })
        }else{
          $ctrl.dismiss({ $value: 'cancel' })
        }
      }

      $ctrl.registerFunctions = ($scope: any) => {
        // --------------- [START] BULK INSERT ---------------
        $scope.bulkInsertIntoStagingAreaDelayed = () => {
          $scope.isLoading = true;
          setTimeout(() => {
            $scope.bulkInsertIntoStagingArea()
            $scope.$apply()
          }, 250);
        }

        $scope.$watch('stagingArea.editAnnotation', function(newValue, oldValue) {
          if(newValue !== oldValue){
            $scope.annotationUpdated = true
          }
        })

        $scope.displayConfig = (display_type: any) => {
          $scope.bulk_type = display_type
          $scope.stagingArea.stagedRequirements = []
          $scope.tab_config = tab_config.loadConfig(display_type)
          $scope.getBinders($scope.unpagedPills)
        }

        $scope.getBinders = (pills: any) => {
          $scope.isLoading = true
          let query_params = {
            param_data: {}
          }
          let newPills:any = angular.copy(pills)
        
          newPills = newPills.concat($scope.tab_config.additional_filter_pills)


          // $scope.loadingStateService.set_state('loading')
          query_params.param_data = $rootScope.storeService.stringifyTagParams(
            $rootScope.storeService.pillsToObject(newPills)
          )
          query_params.param_data['sorted'] = ['name']
          let abort = $q.defer()
          $scope.outstandingPromises.push(abort)

          Restangular.one('binders').withHttpConfig({ timeout: abort.promise }).get(query_params).then(
            (success: any) => {
              // $scope.loadingStateService.process_success(success.items)
              $scope.binders = success.items.filter((binder: any) => binder.schedule_generation_state !== 'archived')
              $scope.binderCount = success.pagination_info.totalCount

              if($scope.bulk_type == "archive"){
                $scope.binders = $scope.binders.filter((b: any) => (b.schedule_generation_state !== 'managed_by_paired_application' &&  b.has_citation === false))
              }

              $scope.LoadSelectedRecordsIntoStagingArea()
              $scope.isLoading = false
            }
            , (error: any) => {
              // $scope.loadingStateService.process_error(error)
              $scope.isLoading = false
              utils.log(error)
            }
          )
        }

        $scope.bulkInsertIntoStagingArea = () => {
          $scope.hasPendingChanges = true;
          $scope.validationMessages = [];
          let valid = $scope.validateBulkInsert()
          if (!valid) {
            $scope.isLoading = false;
            return
          }
          $scope.isLoading = true;
          let orgs = $scope.bulkConfigure.multiInserter.organizations
          let reqs = $scope.bulkConfigure.multiInserter.requirements

          let binders: any = [];

          orgs.forEach((org: any) => {
            reqs.forEach((req: any) => {
              let binder :any= {}
              binder = $scope.applyOrganizationSelection(binder, org)
              binder = $scope.applyRequirementSelection(binder, req)
              binder.schedule_generation_state = $scope.bulkConfigure.multiInserter.schedule_generation_state
              binder.schedule_start_date = $scope.bulkConfigure.multiInserter.schedule_start_date

              // binder.errors = [1]
              binder.validation = { pendingValidation: true }

              binders.push(binder)
            })
          });

          $scope.stagingArea.stagedRequirements = $scope.stagingArea.stagedRequirements.concat(binders)

          $scope.stagingArea.validationQueue = queueProcessorService.enqueue('stagingAreaValidationQueue', binders,
            function (item: any) {
              return Restangular.all('binders/validate_binder').getList(item).then((result: any) => {
                let res = result.plain()
                item.validation = {
                  pendingValidation: false,
                  errors: res,
                  fieldErrors: $scope.mapFieldErrors(res)
                }
              });
            },
            function () {
              // complete
            },
            function () {
              $scope.stagingArea.stagedRequirements.forEach((r: any) => {
                r.validation = {
                  pendingValidation: false,
                  errors: [{ message: 'validation failed', type: 'danger' }]
                }
              })
            })

          $scope.resetOptionsLists()
          $scope.isLoading = false;
        }

        $scope.resetOptionsLists = () => {
          
          $scope.bulkConfigure.organizationsList = angular.copy($scope.masterLists.organizationsList)
          
          $scope.bulkConfigure.requirementsList = angular.copy($scope.masterLists.requirementsList)
          
          $scope.bulkConfigure.tagsList = angular.copy($scope.masterLists.tagsList)

          // $scope.bulkConfigure.multiInserter.organizations = []
          $scope.bulkConfigure.multiInserter.requirements = []
          // $scope.bulkConfigure.multiInserter.tags = []
          $scope.bulkConfigure.multiInserter.filters = []
          $scope.bulkConfigure.multiInserter.schedule_generation_state = undefined
          $scope.bulkConfigure.multiInserter.schedule_start_date = undefined
          // $scope.bulkConfigure.previousPills = []
        }

        $scope.multiTagsChanged = () => {
          let selectedIds: any = []
          let selectedReqs: any = []
          if (Array.isArray($scope.tagsList) && $scope.tagsList.length > 0 && $scope.bulkConfigure.multiInserter.tags.length > 0) {
            selectedIds = $scope.bulkConfigure.multiInserter.tags.map((r: any) => r.value)
            selectedReqs = $scope.tagsList.filter((r: any) => selectedIds.includes(r.id))
          }
          $scope.updateTagFilter()
        }

        $scope.multiOrganizationsChanged = () => {
          let selectedIds: any = []
          let selectedReqs: any = []
          if (Array.isArray($scope.organizationsList) && $scope.organizationsList.length > 0 && $scope.bulkConfigure.multiInserter.organizations.length > 0) {
            selectedIds = $scope.bulkConfigure.multiInserter.organizations.map((r: any) => r.value)
            selectedReqs = $scope.organizationsList.filter((r: any) => selectedIds.includes(r.id))
          }
        }

        $scope.multiRequirementsChanged = () => {
          let selectedIds: any = []
          let selectedReqs: any = []
          if (Array.isArray($scope.requirementsList) && $scope.requirementsList.length > 0 && $scope.bulkConfigure.multiInserter.requirements.length > 0) {
            selectedIds = $scope.bulkConfigure.multiInserter.requirements.map((r: any) => r.value)
            selectedReqs = $scope.requirementsList.filter((r: any) => selectedIds.includes(r.id))
          }
          // let selectedIds = $scope.bulkConfigure.multiInserter.requirements.map(r => r.value)
          // let selectedReqs = $scope.requirementsList.filter(r => selectedIds.includes(r.value))
          let options = ['schedule_on', 'no_schedule', 'start_date_needed'] //, 'managed_by_paired_application'
          let activeOptions: any = []
          options.forEach(o => {
            let anyOff = selectedReqs.find(r => r[o] == false)
            if (!anyOff) {
              activeOptions.push(o)
            }
          })
          $scope.enableScheduleStates(activeOptions)
        }

        $scope.updateTagFilter = () => {
          let pills = $scope.bulkConfigure.multiInserter.tags.map((f: any) => {
            let pill :any= {
              isUnique: true,
              isRequired: false,
              Field: 'tag_ids',
              Op: 'Multi',
              displayName: 'Tags',
              displayValue: f.displayName,
              Value: f.value,
              dateCreated: dayjs()['$d']
            }
            if (f.ticked == 'CheckedWithDescendants') {
              pill.includeDescendants = true
              pill.descendantsName = 'child-tags'
            }
            return pill
          });

          $scope.bulkConfigure.previousPills = pills
        }

        $scope.organizationsApiSearchFunction = (args: any) => {
          let loadedCallback = args.loadedCallback
          const params = {
            param_data: {
              text_search: args.apiSearchInputString,
              archived: false
              // pagination_params: [{ page: 1, pageSize: 50 }]
            }
          }
          const _this = this
          // let params = { param_data: { /* if they want visibility, defualt it here */ } }
          return Restangular.all('organizations/filter_names').getList(params).then(function (success: any) {
            let response = {items: success.plain()}
  
            let searchResults :any = {}
            if (response && Array.isArray(response.items) && response.items.length > 0) {
              searchResults = response
              
              var recursive_map_fn = (i: any) => {
                return {
                  id: i.id,
                  value: i.id,
                  displayName: i.name,
                  children: i.children.map(recursive_map_fn),
                  has_children: i.has_children
                }
              }
              searchResults.items = searchResults.items.map(recursive_map_fn)
            } else {
              searchResults = {}
            }

            
            $scope.masterLists.organizationsList = searchResults.items
            
            $scope.bulkConfigure.organizationsList = angular.copy($scope.masterLists.organizationsList)
            
            $scope.stagingArea.editOrganizationOptionsList = angular.copy($scope.masterLists.organizationsList)
            loadedCallback(searchResults)
          }, function (error: any) {
            return utils.log(error)
          });
        }

        $scope.requirementsApiSearchFunction = (args: any) => {
          let loadedCallback = args.loadedCallback
          let queryParams = {
            param_data: $scope.bulkConfigure.previousPills
          }

          queryParams.param_data = $rootScope.storeService.stringifyTagParams(
            $rootScope.storeService.pillsToObject(queryParams.param_data)
          )

          queryParams.param_data.text_search = args.apiSearchInputString
          queryParams.param_data.pagination_params = [{ page: 1, pageSize: 150 }]

          return Restangular.all('requirements/filter').getList(queryParams).then(
            (success: any) => {
              let response = success.plain()

              let searchResults :any= {}
              if (response && Array.isArray(response) && response.length > 0) {
                searchResults = response
                searchResults = searchResults.map((i: any) => {
                  if (i.interval !== undefined) {
                    return { id: i.id, value: i.id, displayName: i.display_name, order: i.interval.order, interval: i.interval.name }
                  } else {
                    return { id: i.id, value: i.id, displayName: i.display_name, order: 0, interval: '' }
                  }
                })
                searchResults = (searchResults as Array<any>).sort((a: any, b: any) => a.order - b.order)
              } else {
                searchResults = {}
              }
              
              $scope.masterLists.requirementsList = searchResults
              
              $scope.bulkConfigure.requirementsList = angular.copy($scope.masterLists.requirementsList)
              
              $scope.stagingArea.editRequirementsOptionsList = angular.copy($scope.masterLists.requirementsList)
              loadedCallback({ items: searchResults, pagination_info: { totalCount: response.length, maxItemCount: response.length } })
            }
            , (error: any) => {
              utils.log(error)
            }
          );
        }

        $scope.tagsApiSearchFunction = (args: any) => {
          let loadedCallback = args.loadedCallback
          let queryParams = {
            param_data: $scope.bulkConfigure.previousPills
          }

          queryParams.param_data = $rootScope.storeService.stringifyTagParams(
            $rootScope.storeService.pillsToObject(queryParams.param_data)
          )

          queryParams.param_data.text_search = args.apiSearchInputString
          queryParams.param_data.pagination_params = [{ page: 1, pageSize: 150 }]

          const _this = this
          return Restangular.all('tags/filter_business_names').getList(queryParams).then(function (success: any) {
            let response = success.plain()
            let searchResults:any = {}
            
            if (response && Array.isArray(response) && response.length > 0) {
              searchResults = response
              var recursive_map_fn = (i: any) => {
                return { 
                  id: i.id,
                  value: i.id,
                  displayName: i.name,
                  children: i.children.map(recursive_map_fn),
                  has_children: i.has_children
                }
              }
              searchResults.items = searchResults.map(recursive_map_fn)
  
            } else {
              searchResults = {}
            }
  
            loadedCallback(searchResults)
          }, function (error: any) {
            return utils.log(error)
          });
        }

        $scope.clearStartDateIfAppropriate = function () {
          //@ts-ignore
          if ($scope.bulkConfigure.multiInserter.schedule_generation_state !== 'schedule_on' || !$scope.bulkConfigure.multiInserter.requirements.length > 0) {
            $scope.bulkConfigure.multiInserter.schedule_start_date = null
          }
        }

        $scope.enableScheduleStates = (activeScheduleStates: any) => {
          $scope.disabled_count = 0
          $scope.schedule_states.forEach((ss: any) => {
            if (activeScheduleStates.includes(ss.option_name)) {
              ss.disabled = false
            } else {
              ss.disabled = true
              $scope.disabled_count += 1
            }
          })
          $scope.activateRequirementHelpText()
        }

        $scope.activateRequirementHelpText = () => {
          if ($scope.disabled_count === $scope.schedule_states.length) {
            $scope.show_active_help_text = true
          } else {
            $scope.show_active_help_text = false
          }
        }

        $scope.validateBulkInsert = () => {
          if ($scope.bulkConfigure.multiInserter.schedule_generation_state == null) {
            $scope.validationMessages.push('A Schedule State must be selected.')
          }

          if ($scope.bulkConfigure.multiInserter.schedule_generation_state === 'schedule_on' && !$scope.bulkConfigure.multiInserter.schedule_start_date) {
            $scope.validationMessages.push('A Start Date is required.')
          }

          return $scope.validationMessages.length == 0
        }

        // --------------- [ END ] BULK INSERT ---------------

        // --------------- [START] STAGING AREA --------------
        $scope.editBinderRow = (index: any) => {
          $scope.resetEditOptionsLists()
          let currentItem = $scope.stagingArea.stagedRequirements[index]

          if ($scope.bulk_type === 'configure') {
            $scope.stagingArea.editOrganization = [{ displayName: currentItem.organizationName, value: currentItem.organization_id, ticked: true }]
            $scope.stagingArea.editRequirement = [{ displayName: currentItem.requirementName, value: currentItem.requirement_id, ticked: true }]
          }

          $scope.stagingArea.edit_binder_organization_id = currentItem.organization_id
          $scope.stagingArea.editStartDate = currentItem.schedule_start_date
          $scope.stagingArea.editScheduleGenerationState = currentItem.schedule_generation_state
          $scope.stagingArea.editComplianceAdmin = currentItem.user_compliance_admin ? currentItem.user_compliance_admin : 'Unassinged'
          $scope.stagingArea.editComplianceAdminId = (currentItem.user_compliance_admin_id === 0) ? 0 : currentItem.user_compliance_admin_id ? currentItem.user_compliance_admin_id : undefined
          $scope.stagingArea.editProgramLeader = currentItem.user_responsible ? currentItem.user_responsible : 'Unassigned'
          $scope.stagingArea.editProgramLeaderId = (currentItem.user_responsible_id === 0) ? 0 : currentItem.user_responsible_id ? currentItem.user_responsible_id : undefined
          $scope.stagingArea.editTaskAgent = currentItem.user_assigned ? currentItem.user_assigned : 'Unassigned'
          $scope.stagingArea.editTaskAgentId = (currentItem.user_assigned_id === 0) ? 0 : currentItem.user_assigned_id ? currentItem.user_assigned_id : undefined
          $scope.stagingArea.editAnnotation = currentItem.annotation
          $scope.stagingArea.editRowNumber = index
          $scope.stagingArea.editRequirementId = currentItem.requirement_id

          $scope.get_checked_assignees(currentItem)
        }

        $scope.applySelection = (list: any, valuesToSelect: any) => {
          let matches = list.filter((item: any) => valuesToSelect.includes(item.value))
          matches.forEach((match: any) => match.ticked = true)
        }

        $scope.editComplete = (index: any) => {
          $scope.hasPendingChanges = true;
          let binder = $scope.stagingArea.stagedRequirements[index]

          if ($scope.bulk_type === 'configure') {
            let selectedOrg = $scope.stagingArea.editOrganization[0]
            binder = $scope.applyOrganizationSelection(binder, selectedOrg)
            let selectedReq = $scope.stagingArea.editRequirement[0]
            binder = $scope.applyRequirementSelection(binder, selectedReq)
          }
          binder.schedule_start_date = $scope.stagingArea.editStartDate
          binder.schedule_generation_state = $scope.stagingArea.editScheduleGenerationState
          binder.annotation = $scope.stagingArea.editAnnotation
          binder.user_compliance_admin = $scope.stagingArea.editComplianceAdmin.display_name ? $scope.stagingArea.editComplianceAdmin.display_name : $scope.stagingArea.editComplianceAdmin
          binder.user_responsible = $scope.stagingArea.editProgramLeader.display_name ? $scope.stagingArea.editProgramLeader.display_name : $scope.stagingArea.editProgramLeader
          binder.user_assigned = $scope.stagingArea.editTaskAgent.display_name ? $scope.stagingArea.editTaskAgent.display_name : $scope.stagingArea.editTaskAgent
          binder.user_compliance_admin_id = ($scope.stagingArea.editComplianceAdmin.id === 0) ? 0 : $scope.stagingArea.editComplianceAdmin.id ? $scope.stagingArea.editComplianceAdmin.id : $scope.stagingArea.editComplianceAdminId
          binder.user_responsible_id = ($scope.stagingArea.editProgramLeader.id === 0) ? 0 : $scope.stagingArea.editProgramLeader.id ? $scope.stagingArea.editProgramLeader.id : $scope.stagingArea.editProgramLeaderId
          binder.user_assigned_id = ($scope.stagingArea.editTaskAgent.id === 0) ? 0 : $scope.stagingArea.editTaskAgent.id ? $scope.stagingArea.editTaskAgent.id : $scope.stagingArea.editTaskAgentId
          binder.validation = {
            pendingValidation: $scope.bulk_type === 'configure',
            errors: []
          }
          $scope.stagingArea.editRowNumber = -1

          if ($scope.bulk_type === 'configure') {
            $scope.stagingArea.validationQueue = queueProcessorService.enqueue('stagingAreaValidationQueue', [binder],
              function (item: any) {
                return Restangular.all('binders/validate_binder').getList(item).then((result: any) => {
                  let res = result.plain()
                  item.validation = {
                    pendingValidation: false,
                    errors: res,
                    fieldErrors: $scope.mapFieldErrors(res)
                  }
                });
              },
              function () {
                // complete
                if ($scope.annotationUpdated){ 
                  $scope.flashService.clear_alerts()
                  $scope.flashService.add_alert({
                    name: 'Annotation Updated',
                    dismissable: true,
                    class: 'alert-success',
                    icon: 'fa-thumbs-up',
                    strong: 'Success',
                    message: 'Annotation updated :: ' + binder.annotation + ' for ' + binder.requirementName
                  })
                  $scope.annotationUpdated = false
                }
              },
              function () {
                binder.validation = {
                  pendingValidation: false,
                  errors: [{ message: 'validation failed', type: 'danger' }]
                }
              })
          }
        }

        $scope.mapFieldErrors = (errors: any) => {
          let errorSeverity = ['info', 'warning', 'error', 'danger']
          let fieldErrors = {}
          errors.filter((e: any) => e.field).forEach((e: any) => {
            fieldErrors[e.field] = $scope.replaceTextIfHigherValue(fieldErrors[e.field], e.type, errorSeverity)
          })

          let keys = Object.keys(fieldErrors)
          keys.forEach(k => fieldErrors[k] = 'text-' + fieldErrors[k])

          return fieldErrors
        }

        $scope.replaceTextIfHigherValue = (oldString: any, newString: any, stringValues: any) => {
          let indexOfOld = stringValues.indexOf(oldString)
          let indexOfNew = stringValues.indexOf(newString)
          if (indexOfNew > indexOfOld) {
            return newString
          } else {
            return oldString
          }
        }

        $scope.resetEditOptionsLists = () => {
          
          $scope.stagingArea.editOrganizationOptionsList = angular.copy($scope.masterLists.organizationsList)
          
          $scope.stagingArea.editRequirementsOptionsList = angular.copy($scope.masterLists.requirementsList)
        }

        $scope.bulkApplyAssignmentToStagingArea = () => {
          $scope.hasPendingChanges = true;
          let selected_assign = String($scope.bulkConfigure.multiInserter.selected_assign)
          let selected_assign_id = String($scope.bulkConfigure.multiInserter.selected_assign) + '_id'
          $scope.stagingArea.stagedRequirements.forEach((req: any) => {
            // make a method????
            if (!req.mailbox_pairing_configured && !$scope.schedule_states_dissalowing_assignment.includes(req.schedule_generation_state)) {
              var assignee = $scope.bulkConfigure.multiInserter.assignees
              req[selected_assign_id] = Array.isArray(assignee) && assignee.length > 0 ? assignee[0].value : 0
              req[selected_assign] = Array.isArray(assignee) && assignee.length > 0 ? assignee[0].displayName : 'Unassigned'

              // req[selected_assign_id] = $scope.bulkConfigure.multiInserter.assignee ? $scope.bulkConfigure.multiInserter.assignee.id : 0
              // req[selected_assign] = $scope.bulkConfigure.multiInserter.assignee ? $scope.bulkConfigure.multiInserter.assignee.display_name : 'Unassigned'
            }
          })
          $scope.isLoading = false;
        }

        $scope.MarkStagingAreaToBeArchived = () => {
          $scope.hasPendingChanges = true;
          $scope.stagingArea.stagedRequirements.forEach((binder: any) => binder.schedule_generation_state = constants.schedule_generation_state.archived
          )
        }

        // $scope.bulkInsertArchiveIntoStagingArea = () => {
        //   let reqs = angular.copy($scope.binders)
        //   let binders = []
        //   reqs.forEach(req => {
        //     let binder = {}
        //     // make a method???
        //     binder.id = req.id
        //     binder.requirement_id = req.requirement_id
        //     binder.requirementName = req.display_name
        //     binder.organizationName = req.organization
        //     // binder.schedule_generation_state = 'archived'
        //     binder.schedule_start_date = req.schedule_start_date
        //     binder.organization_id = req.organization_id

        //     binders.push(binder)
        //   })
        //   $scope.stagingArea.stagedRequirements = $scope.stagingArea.stagedRequirements.concat(binders)
        //   $scope.isLoading = false;
        // }

        $scope.LoadSelectedRecordsIntoStagingArea = () => {

          

          
          let reqs = angular.copy($scope.binders)
          let binders: any = []
          let not_uniq_org_ids: any = []
          reqs.forEach((req: any) => {
            let binder:any = {}
            $scope.getAllBinderAssignments(req)
            // make a method???
            binder.id = req.id
            binder.requirement_id = req.requirement_id
            binder.requirementName = req.display_name
            binder.organizationName = req.organization
            binder.organization_id = req.organization_id
            binder.user_compliance_admin = req.user_compliance_admin
            binder.user_compliance_admin_id = req.user_compliance_admin_id
            binder.user_responsible = req.user_responsible
            binder.user_responsible_id = req.user_responsible_id
            binder.user_assigned = req.user_assigned
            binder.user_assigned_id = req.user_assigned_id
            binder.schedule_start_date = req.schedule_start_date
            binder.schedule_generation_state = req.schedule_generation_state
            binder.annotation = req.annotation
            binder.mailbox_pairing_configured = req.mailbox_pairing_configured

            binders.push(binder)
            not_uniq_org_ids.push(binder.organization_id)
          })
          $scope.unique_org_ids = [...new Set(not_uniq_org_ids)];
          $scope.stagingArea.stagedRequirements = $scope.stagingArea.stagedRequirements.concat(binders)
          $scope.isLoading = false;
        }

        $scope.confirm_archive = function () {
          const confirmation_object = {
            severity: 'danger',
            title: 'Archive Configured Requirements',
            button_icon: 'fa-archive',
            button_text: 'Archive',
            message: '<p>Clicking the "Change Schedule State to Archive" button will change the Schedule State to Archive for all filtered records.</p>',
            confirm () { $scope.stagingAreaToCsv() }
          }

          return confirmationModalFactory.openConfirmationModal(confirmation_object)
        }

        $scope.openTagFilterPopout = () => {
          $scope.toggleTagFilterPopout(true)
          setTimeout(() => { document.getElementById('node-tree-search-input').focus() }, 50)
        }

        $scope.removeBinderRow = (index: any) => {
          if ($scope.editRowNumber == index) {
            $scope.editRowNumber = -1
          }
          $scope.stagingArea.stagedRequirements.splice(index, 1)
        }

        $scope.getScheduleStateDisplayName = (scheduleGenerationStateEnum: any) => {
          let match = $scope.schedule_states.find((ss: any) => ss.value == scheduleGenerationStateEnum)
          if (match) {
            return match.display
          } else {
            return ''
          }
        }

        $scope.clearEditStartDateIfAppropriate = () => {
          if ($scope.stagingArea.editScheduleGenerationState !== 'schedule_on') {
            $scope.stagingArea.editStartDate = null
          }
        }

        $scope.clearStagingArea = () => {
          queueProcessorService.emptyQueue('stagingAreaValidationQueue')
          $scope.stagingArea.stagedRequirements = []
          $scope.binders = {}
          if ($scope.bulk_type !== 'configure') { $ctrl.dismiss({ $value: 'cancel' }) }
        }

        $scope.stagingAreaToCsv = () => {
          $scope.isLoading = true;

          const fields = ['id', 'organization_id', 'organizationName', 'requirement_id', 'requirementName', 'schedule_generation_state', 'schedule_start_date', 'annotation', 'user_compliance_admin_id', 'user_responsible_id', 'user_assigned_id'];
          const opts = { fields };

          try {
            
            const parser = new window["json2csv"].Parser(opts);
            const csv = parser.parse($scope.stagingArea.stagedRequirements);
            // var csvFile = new Blob([csv], { type: 'text/csv' });
            // let formData = new FormData()
            // formData.append('file', csv)

            Restangular.all('binders/bulk_configure').post({ data: csv }).then(function (success: any) {
              $scope.isLoading = false;
              $scope.checkBulkType()
            }, function (error: any) {
              $scope.updateStagedrequirements(error.data.slice(1))
              console.log(error.data)
              $scope.isLoading = false;
              $scope.flashService.clear_alerts()
              $scope.flashService.process_error(error)
            })
          } catch (err) {
            console.error(err);
            $scope.isLoading = false;
          }
        }

        $scope.checkBulkType = () => {
          if($scope.bulk_type === 'configure'){
            $scope.flashService.clear_alerts()
            $scope.flashService.add_alert({
              name: 'configure alert',
              dismissable: true,
              class: 'alert-success',
              icon: 'fa-thumbs-up',
              strong: 'Success',
              message: 'Number of requirements ' + $scope.stagingArea.stagedRequirements.length + ' of ' + $scope.stagingArea.stagedRequirements.length
            })
            $scope.clearStagingArea()
            $scope.reloadRequired = true
          }
          else{
            $ctrl.dismiss({ $value: 'submit' })
          }
        }

        $scope.parseErrorLog = (errorLog) => {
          const [organizationName, requirementName] = errorLog.split(" :: ");
          return { organizationName, requirementName: requirementName.trim() };
        }

        $scope.updateStagedrequirements = (errorLogs: any) => {
          const failedEntries = [];

          errorLogs.forEach(errorLog => {
            const { organizationName, requirementName } = $scope.parseErrorLog(errorLog);

            const failedEntryIndex = $scope.stagingArea.stagedRequirements.findIndex(entry =>
              entry.organizationName === organizationName && entry.requirementName.trim() === requirementName
            );
      
            if (failedEntryIndex !== -1) {
              failedEntries.push($scope.stagingArea.stagedRequirements[failedEntryIndex]);
            }
          });
          $scope.stagingArea.stagedRequirements.splice(0, $scope.stagingArea.stagedRequirements.length, ...failedEntries);
        }

        $scope.abortAllPromises = (promiseArray: any) => {
          if (Array.isArray(promiseArray) && promiseArray.length > 0) {
            promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
          }
          promiseArray.length = 0
        }

        $scope.assigneesApiSearchFunction = (args: any) => {
          let loadedCallback = args.loadedCallback
          const params = {
            param_data: {
              text_search: args.apiSearchInputString,
              pagination_params: [{ page: 1, pageSize: 999 }]
            }
          }
          let organization_id = $scope.stagingArea.edit_binder_organization_id
          let requirement_id = $scope.stagingArea.editRequirementId
          let searchResults: any = {}
          if (args.apiSearchInputString === '' && organization_id !== undefined) {
            return Restangular.all(`organizations/${organization_id}/org_users`).getList(
              {
                'assignable': true,
                'requirement_id': requirement_id
              }
            ).then((success: any) => {
              let response = success.plain()
              $scope.users = response
              searchResults = $scope.sortByMember(response, (a: any) => a.profile.display_name != null ? a.profile.display_name : "")
              searchResults = searchResults.map((r: any) => {
                return {
                  displayName: r.profile.display_name,
                  value: r.id
                }
              })
              searchResults.unshift({ displayName: 'Unassigned', value: "" })
              loadedCallback({ items: searchResults, pagination_info: { totalCount: response.length, maxItemCount: response.length } })
            }
              , (error: any) => {
                utils.log(error)
            });
          } else if (organization_id !== undefined) {
            // this happens if the user types in the search bar
            let promise = Restangular.one(`organizations/${organization_id}/parent_assignees`).get(params)

            promise.then((success: any) => {
              let response = success.plain()
              searchResults.items = $scope.sortByMember(response.items, (a: any) => a.profile.display_name != null ? a.profile.display_name : "")
              searchResults.items = searchResults.items.map((r: any) => {
                return {
                  displayName: r.profile.display_name,
                  value: r.id
                }
              })
              loadedCallback(searchResults)
            } 
            , (error: any) => {
              utils.log(error)
            });
            return promise
          }
        }

        $scope.commonAssignableUsersApiSearchFunction = (args: any) => {
          let loadedCallback = args.loadedCallback
          let params_ = {
            unique_org_ids: JSON.stringify($scope.unique_org_ids)
          }
          const organization_pills = $scope.unique_org_ids.map((org_id:any) => ({
              Field: "organizations_ids",
              Value: org_id
            })
          );
          const params = {
            param_data: {
              text_search: args.apiSearchInputString,
              pagination_params: [{ page: 1, pageSize: 999 }],
              organizations_ids: organization_pills
            }
          }
          let searchResults: any = {}
          // this happens if the user types in the search bar
          let promise =  Restangular.one(`organizations/search_common_parent_assignees`).get(params)
          promise.then((success: any) => {
            let response = success.plain()
            searchResults.items = $scope.sortByMember(response.items, (a: any) => a.profile.display_name != null ? a.profile.display_name : "")
            searchResults.items = searchResults.items.map((r: any) => {
              return {
                displayName: r.profile.display_name,
                value: r.id
              }
            })
            if (!args.apiSearchInputString) {
              searchResults.items.unshift({ displayName: 'Unassigned', value: "" })
            }
            loadedCallback(searchResults)
          }, (error: any) => {
            utils.log(error)
          });
          return promise
        }

        $scope.get_checked_assignees = (edit_binder:any) => {
          edit_binder.user_compliance_admin_id
          edit_binder.user_responsible_id
          edit_binder.user_assigned_id

          if (edit_binder.user_compliance_admin_id) {
            $scope.stagingArea.edit_user_compliance = [{ displayName: edit_binder.user_compliance_admin, value: edit_binder.user_compliance_admin_id }]
          } else if (!edit_binder.user_compliance_admin_id) {
            $scope.stagingArea.edit_user_compliance = [{ displayName: 'Unassigned', value: 0 }]
          }
          if (edit_binder.user_responsible_id) {
            $scope.stagingArea.edit_user_responsible = [{ displayName: edit_binder.user_responsible, value: edit_binder.user_responsible_id }]
          } else if (!edit_binder.user_responsible_id) {
            $scope.stagingArea.edit_user_responsible = [{ displayName: 'Unassigned', value: 0 }]
          }
          if (edit_binder.user_assigned_id) {
            $scope.stagingArea.edit_user_assigned = [{ displayName: edit_binder.user_assigned, value: edit_binder.user_assigned_id }]
          } else if (!edit_binder.user_assigned_id) {
            $scope.stagingArea.edit_user_assigned = [{ displayName: 'Unassigned', value: 0 }]
          }
        }
        $scope.edit_assignees_changed = () => {
          if ($scope.stagingArea.edit_user_compliance) {
            let selectedAdmin = $scope.stagingArea.edit_user_compliance[0]
            $scope.stagingArea.editComplianceAdminId = selectedAdmin.value
            $scope.stagingArea.editComplianceAdmin = selectedAdmin.displayName
          }
          if ($scope.stagingArea.edit_user_responsible) {
            let selectedResponsible = $scope.stagingArea.edit_user_responsible[0]
            $scope.stagingArea.editProgramLeaderId = selectedResponsible.value
            $scope.stagingArea.editProgramLeader = selectedResponsible.displayName
          }
          if ($scope.stagingArea.edit_user_assigned) {
            let selectedAssignee = $scope.stagingArea.edit_user_assigned[0]
            $scope.stagingArea.editTaskAgentId = selectedAssignee.value
            $scope.stagingArea.editTaskAgent = selectedAssignee.displayName
          }
        }

        $scope.edit_requirements_changed = () => {
          let selectedRequirement = $scope.stagingArea.editRequirement
          $scope.stagingArea.editRequirementId = selectedRequirement.value
          $scope.stagingArea.editRequirement = selectedRequirement.displayName
        }

        $scope.sortByMember = (names: any, member_to_sort_fn: any) => {
          return names.sort(function (s1: any, s2: any) {
            if (member_to_sort_fn(s1).split(' ')[1] < member_to_sort_fn(s2).split(' ')[1]) { return -1 }
            if (member_to_sort_fn(s1).split(' ')[1] > member_to_sort_fn(s2).split(' ')[1]) { return 1 }
            return 0
          });
        }
        // --------------- [ END ] STAGING AREA --------------

        // --------------- [START] SHARED --------------------
        $scope.loadOptionsLists = () => {
          $scope.isLoading = true

          setTimeout(() => {
            $scope.isLoading = false
            $scope.$apply()
          }, 250);
        }

        $scope.initializeScheduleStates = function () {
          $scope.schedule_states = [
            {
              value: constants.schedule_generation_state.schedule_on,
              display: 'Configure with start date',
              disabled: false,
              helpText: 'Complyos will generate tasks based on interval and schedule start date',
              option_name: constants.schedule_generation_state.schedule_on
            },
            {
              value: constants.schedule_generation_state.start_date_needed,
              display: 'Configure without start date',
              disabled: false,
              helpText: 'Schedule waits for user to set "start"',
              option_name: constants.schedule_generation_state.start_date_needed
            },
            {
              value: constants.schedule_generation_state.no_schedule,
              display: 'Schedule Off',
              disabled: false,
              helpText: 'Complyos will not generate tasks',
              option_name: constants.schedule_generation_state.no_schedule
            },
            {
              value: constants.schedule_generation_state.archived,
              visible: $scope.bulk_type === 'archive',
              display: 'Archive',
              disabled: false,
              helpText: 'Archive this requirement',
              option_name: constants.schedule_generation_state.archived
            },
            {
              value: constants.schedule_generation_state.managed_by_paired_application,
              // visible: $scope.bulk_type === 'archive',
              display: 'Managed by Paired Application',
              disabled: true,
              helpText: 'Schedule is managed by paired application',
              option_name: constants.schedule_generation_state.managed_by_paired_application
            }
          ]
        }

        $scope.applyOrganizationSelection = (binder: any, org: any) => {
          binder.organization_id = org.value
          binder.organizationName = org.displayName
          return binder
        }

        $scope.applyRequirementSelection = (binder: any, req: any) => {
          binder.requirement_id = req.value
          binder.requirementName = req.displayName
          return binder
        }

        // $scope.loadOrganizations = () => {
        //   let params = { param_data: { /* if they want visibility, defualt it here */ } }
        //   Restangular.all('organizations/filter').getList(params).then(
        //     (success: any) => {
        //       let organizations = success.plain()
        //       $scope.organizationsLoaded(organizations)
        //     },
        //     (error: any) => utils.log(error)
        //   )
        // }

        // $scope.organizationsLoaded = (organizations: any) => {
        //   if ($scope.masterLists.organizationsList && $scope.masterLists.organizationsList.length > 0) {
        //     return
        //   }
        //   if (!organizations) {
        //     return
        //   }
        //   var flattenedOrganizations = arrayFunctions.flattenNestedArrayRecursive(organizations).filter((o: any) => o.state.archived !== true)
        //   var organizationsList = flattenedOrganizations.map((r: any) => {
        //     return { displayName: r.name, value: r.id, level: r.level, entryId: r.entryId, parentId: r.parent_id, hasDescendants: r.hasDescendants }
        //   })

        //   $scope.masterLists.organizationsList = organizationsList
        //   
        //   $scope.bulkConfigure.organizationsList = angular.copy($scope.masterLists.organizationsList)
        //   
        //   $scope.stagingArea.editOrganizationOptionsList = angular.copy($scope.masterLists.organizationsList)
        // }

        $scope.getAllBinderAssignments = (req: any) => {
          if (req.user_compliance_admin) {
            req['user_compliance_admin'] = req.user_compliance_admin.profile.display_name
          }

          if (req.user_responsible) {
            req['user_responsible'] = req.user_responsible.profile.display_name
          }

          if (req.user_assigned) {
            req['user_assigned'] = req.user_assigned.profile.display_name
          }
          return req
        }

        // --------------- [ END ] SHARED --------------------

        // $scope.watchEntityFilter = () => {
        //   var refresh = function (e, pills) {
        //     // let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted']) || $scope.previousPills === null
        //     $scope.bulkConfigure.previousPills = pills
        //     // if (widgetShouldRefresh) {
        //     //   $scope.abortAllPromises($scope.outstandingPromises)
        //     //   setTimeout(() => getBinders(pills), 50)
        //     //   setTimeout(() => getNeedsStartDateCount(angular.copy(pills)), 50)
        //     // }
        //   }
        //   $scope.$on('filterChange-tagFilter', refresh)
        // }

        // $scope.addNewRequirementRow = () => {
        //   let newRequirement = {}
        //   $scope.bulkConfigure.stagedRequirements.push(newRequirement)
        // }

        // $scope.openTagFilterPopout = () => {
        //   $scope.toggleTagFilterPopout(true)
        //   setTimeout(() => { document.getElementById('node-tree-search-input').focus() }, 50)
        // }

        // $scope.toggleTagFilterPopout = (isOpen = null) => {
        //   if (isOpen !== null) {
        //     $scope.bulkConfigure.isTagFilterPopoutOpen = isOpen
        //   } else {
        //     $scope.bulkConfigure.isTagFilterPopoutOpen = !$scope.nodeTreeBuilder.isPopoutOpen
        //   }
        // }

        return $scope
      }
    }]
});
