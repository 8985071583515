import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { objectManager } from '../../../../scripts/services/ajs-object_manager';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { FlashService } from '../../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import dayjs from 'dayjs';

@Component({
  selector: 'ngOrganizationUsers',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss']
})
export class OrganizationUsersComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private search_parameters: any;
  private session_organization: any;
  private users: any;
  private sortNameClicked: boolean = false
  private sortRoleClicked: boolean = false

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(objectManager) private objectManager: objectManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      zerousers: {
        name: 'zerousers',
        icon: 'fa-users',
        text: 'This organization has no users'
      },
      activeorgprompt: {
        name: 'activeorgprompt',
        icon: 'fa-building',
        text: 'Organization Required. Please select from filter above.'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()

    this.session_organization = this.$rootScope.session.getOrganization()
    this.search_parameters = { user_query: '' }

    this.$scope.$on('activeOrgIdUpdated', () => {
      this.refresh()
    })

    this.refresh()
  }

  refresh = () => {
    this.session_organization = this.$rootScope.session.getOrganization()
    if (this.$rootScope.session.activeOrganizationPresent()) {
      this.getUsers()
    } else {
      this.loadingStateService.set_state('activeorgprompt')
    }
  }

  /* CRUD */

  getUsers = () => {
    this.loadingStateService.set_state('loading')
    return this.Restangular.all(`organizations/${this.session_organization.id}/org_users`).getList().then(response => { // success
      this.users = response.plain()
      this.sort_users_by_name()
      if (response.length === 0) {
        this.loadingStateService.set_state('zerousers')
      } else {
        this.loadingStateService.set_state('content')
      }
    }
    , (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    });
  }

  attemptView = (user: any) => this.processingStateService.performFunction(user, () => this.$rootScope.session.goTo(`/user/${user.id}`))

  is_session_user_weight_greater = (user: any) => this.$rootScope.session.getUser().user_role.role.weight >= user.user_role.role.weight

  enable_flash_service = (name: any, flash_class: any, custom_message: any) => {
    this.flashService.add_alert({
      name,
      dismissable: true,
      class: flash_class,
      strong: 'Success',
      icon: 'fa-thumbs-up',
      message: custom_message
    })
  }

  /* SORT */

  sort_users_by_name = () => { 
    if(!this.sortNameClicked) {
      this.users = this.users.sort((a, b) => a.profile.display_name.localeCompare(b.profile.display_name))
    } else {
      this.users = this.users.sort((a, b) => b.profile.display_name.localeCompare(a.profile.display_name))
    }
    this.sortNameClicked = !this.sortNameClicked
  }

  sort_users_by_role = () => { 
    if(!this.sortRoleClicked) {
      this.users = this.users.sort((a, b) => a.user_role.role.title.localeCompare(b.user_role.role.title))
    } else {
      this.users = this.users.sort((a, b) => b.user_role.role.title.localeCompare(a.user_role.role.title))
    }
    this.sortRoleClicked = !this.sortRoleClicked
  }

  /* ADD USER */

  openUserOrganizationModal = () => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/user_organization_modal.html',
      controller: 'userOrganizationModalController',
      size: 'small'
    })
    return modalInstance.result.then((result: any) => {
      let user
      if (result === 'create') {
        return this.create_user()
      } else {
        let current_user_instance = 0

        // a check to see if any of the people that you added were yourself, if so, this resets
        // the session user data so that when navigating back to the home screen your orgs will show up
        // if there are more than one
        // TODO: ####_CHECK
        if (_.find(result, { username: this.$rootScope.session.getUser().username })) {
          this.$rootScope.session.getUser().organizations.push(this.session_organization)
        }

        while (current_user_instance < result.length) {
          this.objectManager.array_action(this.users, result[current_user_instance], 'merge')
          current_user_instance++
        }
        if (result.length > 1) {
          this.enable_flash_service(
            `${((() => {
              const result1 = []
              for (user of Array.from(result)) {
                result1.push(user.username)
              }
              return result1
            })()).join(', ')} added`,
            'alert-success',
            `Users: ${((() => {
              const result2 = []
              for (user of Array.from(result)) {
                result2.push(user.username)
              }
              return result2
            })()).join(', ')} were successfully added to ${this.session_organization.name}`
          )
        } else {
          this.enable_flash_service(
            `${(() => {
              const result3 = []
              for (user of Array.from(result)) {
                result3.push(user.username)
              }
              return result3
            })()} added`,
            'alert-success',
            `${(() => {
              const result4 = []
              for (user of Array.from(result)) {
                result4.push(user.username)
              }
              return result4
            })()} was successfully added to ${this.session_organization.name}`
          )
        }
        if (this.loadingStateService.loading_state.name === 'nocontent') {
          this.loadingStateService.set_state('content')
        }
      }
    }
    , (reason: any) => {
      this.utils.log(`Modal dismissed at: ${dayjs()} reason: ${reason}`, 'info', false)
    });
  }

   /* USER MODAL */

   // https://angular-ui.github.io/bootstrap/#/modal
  openUserModal = (user_object, ui_setting) => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/user_modal.html',
      controller: 'userModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            object: angular.copy(user_object),
            ui_setting
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    return modalInstance.result.then((result: any) => {
      if (ui_setting === 'create') {
        this.objectManager.array_action(this.users, result, 'merge')
      }
      if (ui_setting === 'update') {
        this.objectManager.array_action(this.users, result, 'update')
        if (this.$rootScope.session.getUser().id === result.id) {
          return this.$rootScope.session.setProfile(result.profile)
        }
      }
    },(reason: any) => {

    }).finally(() =>
      this.processingStateService.set(
        user_object,
        this.processingStateService.processing_states.RESTING
      )
    );
  }

  // * There were two of these? Not sure why. Commented out the one that doesn't appear to be used * //

  //  openUserModal = (user_object: any, ui_setting: any) => {
  //   const modalInstance = this.$uibModal.open({
  //     templateUrl: 'views/modals/user_modal.html',
  //     controller: 'userModalController',
  //     size: 'small',
  //     resolve: {
  //       // this is the object that can be passed to the modal.
  //       // we copy it to eliminate binding issues
  //       // require it in the dependecies as modalOptions
  //       modalOptions () {
  //         return {
            
  //           object: angular.copy(user_object),
  //           ui_setting
  //         }
  //       }
  //     }
  //   })

  //   // on close
  //   // - grab the returned result,
  //   // - or log out a reason
  //   // - finally, sets the object back to a resting state
  //   return modalInstance.result.then((result: any) => {
  //     // objectManager.array_action($scope.users, result, 'merge')
  //     this.enable_flash_service(
  //       'user_created',
  //       'alert-info',
  //       'user successfully created! You can now add them to the organization.'
  //     )
  //     if (this.loadingStateService.loading_state.name === 'nocontent') {
  //       return this.loadingStateService.set_state('content')
  //     }
  //   }, function (reason: any) {

  //   }).finally(function () {
  //   });
  // }

  /* CREATE USER */

  // TODO for inherited orgs -- # organizations: $rootScope.session.getUser().organizations
  create_user = () => {
    const new_user_object = {
      organizations: this.$rootScope.session.getUser().organizations,
      taggings: []
    }
    return this.openUserModal(new_user_object, 'create')
  }

   /* UPDATE USER */

   openUserUpdateModal = (existing_user_object: any) => this.openUserModal(existing_user_object, 'update')

   /* READ & UPDATE */
 
   openUserReadModal = (existing_user_object: any) => this.openUserModal(existing_user_object, 'read')

  /* REMOVE USER */

  // Confirmation for removal of user-organization association on remove button click
  openUserOrganizationDestroyModal = (user_object: any) => {
    let that = this
    const confirmation_object = {
      severity: 'warning',
      title: 'Remove User',
      button_icon: 'fa-user-times',
      button_text: 'Remove User',
      // deny: method to run on dismiss
      confirm () { return that.removeUserOrganizationAssociation(user_object) },
      message: `<p>This action will:</p><ul><li>Remove ${user_object.profile.display_name} from the organization ${this.session_organization.name}</li><li>Unassign all requirements ${user_object.profile.display_name} is the compliance administrator for, responsible for, and assigned to at ${this.session_organization.name}</li><li>Delete all notifications ${user_object.profile.display_name} has at ${this.session_organization.name}</li></ul><p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(user_object, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then((result: any) => {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          this.processingStateService.set(user_object, this.processingStateService.processing_states.RESTING)
        }
        return true
      })
    );
  }

  removeUserOrganizationAssociation = (user: any) => {
    const params = {
      'user_organization[user_id]': user.id,
      'user_organization[organization_id]': this.session_organization.id
    }

    return this.Restangular.all('user_organizations').remove(params).then((success: any) => {
      this.objectManager.array_action(this.users, user, 'remove')
      // if removing self from the organization it goes into session user and removes from org list
      // then updates the active org to null and reroutes to home page
      if (user.username === this.$rootScope.session.getUser().username) {
        const org_index = _.indexOf(this.$rootScope.session.getUser().organizations, { name: this.session_organization })
        return this.$rootScope.session.getUser().organizations.splice(org_index, 1)
      }
    }, (error: any) => {
      this.processingStateService.set(user, this.processingStateService.processing_states.RESTING)
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    });
  }
}

angular
  .module('complyosClient')
  .directive('ngOrganizationUsers', downgradeComponent({ component: OrganizationUsersComponent }) as angular.IDirectiveFactory)