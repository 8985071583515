/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('html5Object', [
  '$window',
  function (
    $window: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/html5_object.html',
      scope: {
        name: '=?',
        data: '=',
        type: '=',
        height: '=?',
        minHeight: '=?',
        maxHeight: '=?',
        width: '=?',
        minWidth: '=?',
        maxWidth: '=?',
        initReady: '='
      },

      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.ho = {

          init () {
            if ($scope.width == null) { $scope.width = `${$element.width()}px` }
            if ($scope.height == null) { $scope.height = `${$element.height()}px` }
            this.size_object()
            return this.watch_window()
          },

          size_object () {
            this.set_width()
            return this.set_height()
          },

          watch_window () {
            
            return angular.element($window).bind('resize', () => {
              this.size_object()
              $scope.$apply()
            })
          },

          min_height () {
            if ($scope.minHeight) {
              return this.dimension_to_px($scope.minHeight)
            } else {
              return 0
            }
          },

          max_height () {
            if ($scope.maxHeight) {
              return this.dimension_to_px($scope.maxHeight)
            } else {
              return Number.MAX_SAFE_INTEGER
            }
          },

          set_height () {
            return this.height = Math.round(Math.min(this.max_height(), Math.max(this.min_height(), this.dimension_to_px($scope.height))))
          },

          min_width () {
            if ($scope.minWidth) {
              return this.dimension_to_px($scope.minWidth)
            } else {
              return 0
            }
          },

          max_width () {
            if ($scope.maxWidth) {
              return this.dimension_to_px($scope.maxWidth)
            } else {
              return Number.MAX_SAFE_INTEGER
            }
          },

          set_width () {
            return this.width = Math.round(Math.min(this.max_width(), Math.max(this.min_width(), this.dimension_to_px($scope.width))))
          },

          // takes dimension string "100pw"
          // and returns array breaking it down ["100pw", "100", "pw", index: 0, input: "100pw"]
          interpret_dimension (dimension_string: any) {
            const dimension_array = dimension_string.match(/^(\d+(?:\.\d+)?)(.*)$/)
            return dimension_array
          },

          dimension_to_px (dimension_string: any) {
            const dimension_array = this.interpret_dimension(dimension_string)
            const amount = dimension_array[1]
            const unit = dimension_array[2]

            if (unit === 'px') {
              return amount
            } else {
              return this[`${unit}_to_px`](amount)
            }
          },

          // Viewport Height to pixels
          vh_to_px (vh: any) {
            return $window.innerHeight * (vh / 100)
          },

          // Viewport Width to pixels
          vw_to_px (vw: any) {
            return $window.innerWidth * (vw / 100)
          },

          // Parent Height to pixels
          ph_to_px (ph: any) {
            return $element.parent()['0'].clientHeight * (ph / 100)
          },

          // Parent Width to pixels
          pw_to_px (pw: any) {
            return $element.parent()['0'].clientWidth * (pw / 100)
          }

        }

        return $scope.$watch(
          $scope.initReady,
          () => $scope.ho.init())
      }
    };
  }

])
