import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'

@Component({
  selector: 'ngFeed',
  templateUrl: './feed.component.html'
})
export class FeedComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;

  // Properties
  private itemsToShow: any;
  private userFeedObject: any;
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.loadingStateService.init()
    this.loadingStateService.set_state('loading')
  }

  ngOnInit(): void {
    this.getUserFeedObject()
  }

  getUserFeedObject = () => {
    return this.Restangular.one(`users/${this.$rootScope.session.getUser().id}/feed`).get().then((response: any) => {
      this.itemsToShow = response.new_count
      this.userFeedObject = response
      this.loadingStateService.set_state('content')
    }
    , (error: any) => console.log(error));
  }

  getFilteredFeedItems(): any[] {
    return this.userFeedObject.feed_items.filter((item, index) => index <= this.itemsToShow - 1);
  }
  
  showMoreItems = (x: any) => this.itemsToShow += x
}

angular
  .module('complyosClient')
  .directive('ngFeed', downgradeComponent({ component: FeedComponent }) as angular.IDirectiveFactory)
