import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { FlashService } from '../../../services/flash_service.service';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from '../../../services/password.service';
import { Utils } from '../../../../scripts/services/ajs-utils';
import angular from 'angular';

@Component({
  selector: 'ngSetPassword',
  templateUrl: './set-password.component.html'
})

export class SetPasswordComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private passwordService: any;

  // Properties
  private setPasswordForm: FormGroup;
  private failedSubmission: boolean = false;
  private reveal: boolean = false;
  private ui = {
    action: {
      title: null as string | null,
      message: null as string | null
    },
    user: {
      password: {
        messages: [] as string[]
      }
    }
  }

  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$state') private $state,
    @Inject(FlashService) flashService: FlashService,
    @Inject(PasswordService) passwordService: PasswordService,
    private utils: Utils,
    private Restangular: Restangular,
    private fb: FormBuilder
  ) { 
    this.flashService = angular.copy(flashService)
    this.passwordService = passwordService
    this.setPasswordForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  
  ngOnInit(): void {
    if (this.$state.params.action === 'activate') {
      this.ui.action.title = 'Activate Account'
      this.ui.action.message = 'Please enter your desired password'
    }

    if (this.$state.params.action === 'reset_password') {
      this.ui.action.title = 'Reset Account Password'
      this.ui.action.message = 'Please enter your desired password'
    }

    this.setPasswordForm.get('password')?.valueChanges.subscribe(newValue => {
      // A blank password will not need to be checked
      if (!newValue) {
        this.setPasswordForm.get('password')?.setErrors({ invalid: true })
        return
      }

      // if there is something in the password field, we check.
      const checker = this.passwordService.checker(newValue)

      if ((checker.strength >= 3) && (checker.length >= 8) && (checker.length <= 128)) {
        this.setPasswordForm.get('password')?.setErrors(null);
      } else {
        this.setPasswordForm.get('password')?.setErrors({ invalid: true })
      }

      // bubble up the messages from checker
      this.ui.user.password.messages = checker.messages
    })
  }

  toggleReveal(): void {
    this.reveal = !this.reveal
  }

  submitForm = () => {
    if (this.setPasswordForm.invalid) {
      this.failedSubmission = true;
      return
    } else {
      const user = {
        service_token: this.$state.params.service_token,
        password: this.setPasswordForm.get('password').value
      }
      return this.saveObject(user)
    }
  }

  saveObject = (object: any) => {
    // console.log(object)

    // Reset is the route we want to hit, Pass a token and a new password. It does a lookup and bam!
    return this.Restangular.all('user_services/reset').post({ user: object }).then((response: any) => {
      const success_flash = {
        strong: 'Success',
        name: 'success',
        dismissable: false,
        class: 'alert-info',
        message: 'Password has been updated'
      }
      return this.flashService.add_alert(success_flash)
    }

    , (error: any) => {
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    });
  }
}

angular
  .module('complyosClient')
  .directive('ngSetPassword', downgradeComponent({ component: SetPasswordComponent }) as angular.IDirectiveFactory)
