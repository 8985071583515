// /* eslint-disable
//     no-undef,
// */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.directive('entryCompleteManager', [
//   'flashService',
//   'Restangular',
//   function (
//     flashService: any,
//     Restangular: any
//   ) {
//     return {
//       restrict: 'E',
//       templateUrl: 'views/directives/entry_complete_manager.html',
//       require: 'ngModel',
//       scope: {
//         ngModel: '=',
//         entry: '=',
//         allowChange: '=',
//         allowIncomplete: '=',
//         allowComplete: '=',
//         allowPartiallyComplete: '=',
//         allowClosedNotComplete: '=',
//         allowRequiresResubmission: '='
//       },

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized
//       //  attribute names and their corresponding attribute values.
//       //  $ngModel is the actual model that this directive interacts with
//       link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
//         $scope.cm = {

//           initialize () {
//             this.sync_ng_model()
//             this.listen_for_start_event()

//             // $ngModel.$validate() causes modelValue to go undefined
//             // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
//             $ngModel.$options = {
//               allowInvalid: true
//             }

//             this.complete_options = [
//               {
//                 value: 'incomplete',
//                 display: 'Incomplete',
//                 visible: $scope.allowIncomplete,
//                 helpText: 'This is the default status'
//               },
//               {
//                 value: 'partially_complete',
//                 display: 'Partially Complete',
//                 visible: $scope.allowPartiallyComplete || ($scope.entry.complete_status === 'partially_complete'),
//                 disabled: !$scope.allowPartiallyComplete && ($scope.entry.complete_status === 'partially_complete'),
//                 helpText: 'Please document, in the Note section below, what is preventing you from completing this task.'
//               },
//               {
//                 value: 'attested_as_complete',
//                 display: 'Attested As Complete',
//                 visible: $scope.allowComplete,
//                 helpText: 'The attestation note is considered your signature.'
//               },
//               {
//                 value: 'requires_resubmission',
//                 display: 'Requires Resubmission',
//                 visible: $scope.allowRequiresResubmission,
//                 helpText: 'Please document deficiencies below.'
//               },
//               {
//                 value: 'closed_not_complete',
//                 display: 'Closed Not Complete',
//                 visible: $scope.allowClosedNotComplete || ($scope.entry.complete_status === 'closed_not_complete'),
//                 disabled: !$scope.allowClosedNotComplete && ($scope.entry.complete_status === 'closed_not_complete'),
//                 helpText: 'Please upload a document containing the reason for closing (to be visible by surveyors), and optionally document the reason below in the note field.'
//               }
//             ]
//           },

//           shouldShowCompleteOption (completeOption: any) {
//             return completeOption.visible
//           },

//           sync_ng_model () {
//             // $ngModel.$modelValue is a string from the calling controller
//             // this.active_complete_option is a object (one of many possible)
//             // with a string value, stored in .value
//             // we need to sync these items.

//             // set the active_complete_option based on changes to $ngModel.$modelValue
//             $scope.$watch(() => $ngModel.$modelValue, (newValue: any, oldValue: any, scope: any) => {
//               let match = this.complete_options.find((option: any) => {
//                 return option.value === newValue
//               })
//               // This value is updated in a back and forth manner
//               // once from the api when an entry is saved
//               // and once from the user, this is the value they really want
//               // if a user has made a selection for complete_status, we want to keep it
//               // otherwise we'll take whatever is returned from the API
//               if (match) {
//                 if ($ngModel.$dirty) {
//                   // $ngModel was updated by user but we are ignoring it
//                   console.log('$ngModel updated externally, retaining user preference', this.active_complete_option)
//                 } else {
//                   this.active_complete_option = match.value
//                   this.active_help_text = match.helpText
//                 }
//               } else {
//                 console.log('No match found for value:', newValue);
//               }
//             })

//             // set the $ngModel.$modelValue value based on changes to active_complete_option
//             $scope.$watch(() => this.active_complete_option, (newValue: any, oldValue: any, scope: any) => {
//               $scope.ngModel = newValue
//               if (newValue && oldValue && (newValue !== oldValue)) {
//                 let match = this.complete_options.find((option: any) => {
//                   return option.value === newValue
//                 })
//                 this.active_help_text = match.helpText
//                 $ngModel.$setDirty()
//               }
//             })
//           },

//           listen_for_start_event () {
//             $scope.$on('entry_complete_manager_start', (event: any, data: any) => {
//               this.save_entry_complete(data)
//             })
//           },

//           save_entry_complete (data: any) {
//             Restangular.all(`entries/${data.id}/complete_status`).post({
//               complete_status: this.active_complete_option
//             }).then((success: any) => {
//               $scope.ngModel = success.complete_status
//             }, (error: any) => {
//               flashService.emit_alert(
//                 $scope,
//                 flashService.default_alert_by_code(error.status)
//               )
//               this.fault = error
//             }).finally(() => {
//               this.emit_completion_event()
//             })
//           },

//           emit_completion_event () {
//             const returnObject = {
//               manager: 'entry_complete_manager',
//               object: $ngModel.$modelValue,
//               fault: this.fault
//             }
//             $scope.$emit('entry_complete_manager_finished', returnObject)
//           }
//         }

//         // there is a delay between the time the directive is ready and ngModel is
//         // we watch for ready and run code once
//         let ngModelReady = false
//         $scope.$watch(() => $ngModel, function (newValue: any, oldValue: any, scope: any) {
//           if (ngModelReady === false) {
//             $scope.cm.initialize()
//             ngModelReady = true
//           }
//         })
//       }
//     };
//   }

// ])
