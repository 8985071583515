import * as angular from 'angular';

import { Injectable, Inject } from "@angular/core";
import { Restangular } from 'ngx-restangular';

@Injectable()
export class PureNg12Service {
    constructor(
        private restangular: Restangular
    ) {
        console.log("[ng12 -> ng12] || PureNg12Service || this is an example [ng12] service that can be only be used inused in ng12 entities (because it hasnt been downgraded)")

        this.restangular.one('users', 2).all('accounts').getList();
    }

    whoami(runFrom: string) {
        console.log('pure ng12 service, called from :' + runFrom)
    }
}

// NOTE:
//      By Not downgrading this componenet to be used in njs hybrid entities
//      we deomnstrate that this is 100 % certified ng12 only component 
//      proving that pure ng12 injection is working
// -------------------------------------------------------------------------
// angular
//     .module('complyosClient')
//     .factory("PureNg12Service", downgradeInjectable(PureNg12Service));


// STEPS:
//      Key steps when making an ng12 service
//      * @Injectable()
//      * add classname ie: 'PureNg12Service' to app.module.ts -> providers
//      * to inject in other ng12 entities use: 
//              "@Inject(PureNg12Service) private pureNg12Service: PureNg12Service"
