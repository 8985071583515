/* eslint-disable
    camelcase,
    eqeqeq,
    no-return-assign,
    no-undef,
*/
'use strict'


angular.module('complyosClient').directive('modalInsert', ['$rootScope', 'helpService', 'fortyCore',
  (helpService, fortyCore, $rootScope, $scope) => ({
    templateUrl: 'views/directives/modal_insert.html',
    restrict: 'E',

    transclude: {
      headingSlot: '?headingSlot',
      content: 'content'
    },

    scope: {
      heading: '<',
      closeCallback: '&',
      submitCallback: '&',
      isLoading: '=',
      disableSubmit: '=',
      buttonName: '<',
      buttonTooltip: '<',
      buttonHelpText: '<'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore
    }
  })
])
