import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { Subscription } from 'rxjs';
import { BinderService } from '../../../services/broadcast-services/binder.service';
import { FlashService } from '../../../services/flash_service.service';
import { helpService } from '../../../../scripts/services/help';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import _ from 'lodash';

@Component({
  selector: 'ngNotification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit, OnDestroy {  
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private binder: any;
  private users: any;
  private notifications: any;
  private newNotifications: Array<any> = []
  private filteredUsers: Array<any> = []
  private selectedUserId: number;

  // subscriptions
  private binderUpdateSubscription: Subscription;
  private notificationApiCallSubscription: Subscription;
  
  constructor(
    @Inject('$state') private $state,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private helpService: helpService,
    private binderService: BinderService
  ) {
    this.loadingStateService = angular.copy(loadingStateService)
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    // handle the notification api call trigger
    this.notificationApiCallSubscription = this.binderService.notificationApiCallTrigger$.subscribe(() => this.getNotifications())
    // handle the binderObject data from binder.service
    this.binderUpdateSubscription = this.binderService.binderUpdate$.subscribe(binderObject => {
        this.binder = binderObject
      }
    )
    this.loadingStateService.init()
    this.getBinder()
    this.getNotifications()
  }

  getBinder = () => {
    // there might allready be a binder
    this.loadingStateService.set_state('loading')
    if (!this.binder) {
      return this.Restangular.all('binders').get(this.$state.params.binder_id).then((response: any) => {
        this.binder = response
        this.loadingStateService.process_success(response)
        this.getUsers()
      }, (error: any) => this.utils.log(error, 'error', false));
    } else {
      this.getUsers()
      this.loadingStateService.set_state('content')
    }
  }

  getUsers = (organization_id: any = null) => {
    if (!organization_id) {
      try {
        organization_id = this.binder.organization_id
      } catch (ex) { }
    }
    if (organization_id) {
      this.Restangular.all(`organizations/${organization_id}/org_users`).getList(
        {
          'assignable': true,
          'requirement_id': this.binder.requirement_id
        }
      ).then((response: any) => { // success
        this.loadingStateService.process_success(response)
        this.users = response
        this.filteredUsersFn(this.users)
      }
      , (error: any) => this.utils.log(error, 'error', false))
    }
  }

  filteredUsersFn = (users) => {
    this.filteredUsers = _.filter(users, (u: any) => ((u.user_role.role.title === 'User') || (u.user_role.role.title === 'Organization Admin') || u.user_role.role.title === 'Reviewer' ) && !this.userHasNotifications(u))
  }

  userHasNotifications = (user: any) => {
    if (this.notifications) {
      const users_with_notifications = this.notifications.map((n: any) => n.user_id)
      return users_with_notifications.indexOf(user.id) > -1
    } else {
      return false
    }
  }

  getNotifications = () => {
    this.Restangular.all(`binders/${this.$state.params.binder_id}/notifications`).getList().then((response: any) => {
      this.loadingStateService.process_success(response)
      return this.notifications = response
    }
    , (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  notificationIsUntouchable = (notification: any) => {
    if (this.binder) {
      const n = notification
      const b = this.binder
      return (n.user_id === b.user_compliance_admin_id) || (n.user_id === b.user_responsible_id) || (n.user_id === b.user_assigned_id)
    }
  }

  addNewNotification = () => {
    const notification = {
      user_id: null,
      binder_id: this.$state.params.binder_id,
      first: true,
      second: true,
      third: true
    }
    return this.newNotifications.push(notification)
  }

  attemptSaveNewNotification = (notification_object: any) => // State service will set the object to a state of PENDING
  // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
  this.processingStateService.performFunction(notification_object, () => this.saveNewNotification(notification_object))

  saveNewNotification = (notification_object: any) => this.Restangular.all('notifications').post(notification_object).then((success: any) => {
    // remove the new notification form the temp list
    this.newNotifications = _.reject(this.newNotifications, (n: any) => n === notification_object)

    // if the user already has a notification for this binder the database the API will update it.
    // we need to reflect that action.
    // so we remove the matching object in this.notifications, then we put the new one back in
    this.notifications = _.reject(this.notifications, (n: any) => n.user_id === success.user_id)

    // Push the newly created or updated object into the this.notifications
    return this.notifications.push(success)
  }, (error: any) => {
    var notification: any = notification || {}
    this.processingStateService.set(notification, this.processingStateService.processing_states.RESTING)
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', false)
  })

  updateNotification = (notification: any) => this.Restangular.all(`notifications/${notification.id}`).patch(notification).then((success: any) => {
  }, (error: any) => {
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', false)
  })

  // Confirmation for delete of notification on delete button click
  attemptDeleteNotification = (notification_object: any) => {
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm: () => { return this.deleteNotification(notification_object) }
      // message: message string
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(notification_object, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then((result: any) => {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          this.processingStateService.set(notification_object, this.processingStateService.processing_states.RESTING)
        }
        return true
      })
    );
  }

  deleteNotification = (notification: any) => this.Restangular.all(`notifications/${notification.id}`).remove().then((success: any) => this.notifications = _.reject(this.notifications, (n: any) => n === notification)
  , (error: any) => {
    this.processingStateService.set(notification, this.processingStateService.processing_states.RESTING)
    this.flashService.process_error(error)
    return this.utils.log(error, 'error', false)
  })

  ngOnDestroy() {
    if (this.notificationApiCallSubscription) {
      this.notificationApiCallSubscription.unsubscribe();
    }

    if (this.binderUpdateSubscription) {
      this.binderUpdateSubscription.unsubscribe();
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngNotification', downgradeComponent({ component: NotificationComponent }) as angular.IDirectiveFactory)
