// /* eslint-disable
//     camelcase,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationDashboardValidationModeCommentsCountController', ['$q', '$rootScope', '$scope', 'loadingStateService', 'arrayFunctions', 'Restangular', 'filterService', 'helpService', function (
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   loadingStateService: any,
//   arrayFunctions: any,
//   Restangular: any,
//   filterService: any,
//   helpService: any
// ) {
//   const init = function () {
//     $scope.previousPills = null
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     const custom_states = {
//       a_bunch_of_nothing: {
//         icon: 'fa-check-circle',
//         text: 'No new ValidationMode&#174; comments or Resubmission Entries or Citations'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.outstandingPromises = []
//     $scope.deferredRefresh = null

    
//     $scope.helpService = angular.copy(helpService)
//     $scope.review_count_tooltip_message = 'The number of new ValidationMode&#174; comments'

//     watchEntityFilter()
//   }

//   var executeRefresh = (pills: any) => {
//     $scope.abortAllPromises($scope.outstandingPromises)
//     setTimeout(() => get_analytic_data(pills), 50)
//   }

//   var get_analytic_data = function (pills: any) {
//     $scope.loadingStateService.set_state('loading')

//     let query_params = {
//       model: 'Entry',
//       method: 'validation_mode_comment_count',
//       param_data: {}
//     }

//     query_params.param_data = $rootScope.storeService.stringifyTagParams(
//       $rootScope.storeService.pillsToObject(pills)
//     )

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)

//     return Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then(function (success: any) {
//       $scope.loadingStateService.process_success(success)
//       $scope.counts = success.data

//       if ($scope.counts.review_count === 0 && $scope.counts.resubmission_count === 0 && $scope.counts.citation_count === 0) {
//         return $scope.loadingStateService.set_state('a_bunch_of_nothing')
//       }
//     }
//     , (error: any) => {
//       $scope.loadingStateService.set_state('query_too_large_warning')
//       $scope.deferredRefresh = executeRefresh.bind(null, pills)
      
//     });
//   }

//   var watchEntityFilter = () => {
//     var refresh = function (e: any, pills: any) {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted', 'pagination_params']) || $scope.previousPills === null
//       $scope.previousPills = pills
//       if (widgetShouldRefresh) {
//         abortAllPromises($scope.outstandingPromises)
//         setTimeout(() => get_analytic_data(pills), 50)
//       }
//     }
//     $scope.$on('refresh-dashboard-widgets', refresh)
//     $scope.$on('cancel-pending-filter-queries', () => abortAllPromises($scope.outstandingPromises))
//   }

//   var abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   return init()
// }])
