/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('complyosReferencesController', ['$http', '$rootScope', '$scope', '$uibModal', 'confirmationModalFactory', 'ENV', 'fortyCore', 'objectManager', 'Restangular', 'utils', function (
  $http: any,
  $rootScope: any,
  $scope: any,
  $uibModal: any,
  confirmationModalFactory: any,
  ENV: any,
  fortyCore: any,
  objectManager: any,
  Restangular: any,
  utils: any
) {
  // TODO add flashService for errors

  const init = function () {
    $scope.processingStateService = fortyCore.processingStateService
    $scope.node_map = []
    return get_tree_data()
  }

  var get_tree_data = function () {
    const params = {
      node_map: JSON.stringify($scope.node_map)
    }

    return Restangular.all('reference_objects/tree_navigation').getList(params).then((success: any) => // TODO Merge better
    // _.merge $scope.tree_data, success
    $scope.tree_data = success

    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    });
  }

  $scope.go_deeper = function (depth: any, identifier: any) {
    // current maximum index
    const current_indexed_depth = $scope.node_map.length - 1
    // we want to replace the item at the chosen depth
    $scope.node_map[depth] = identifier
    // Use .length to lop off everything after the index
    $scope.node_map.length = depth + 1
    return get_tree_data()
  }

  $scope.refresh_tree = function () {
    $scope.node_map = []
    return get_tree_data()
  }

  $scope.openAspectCreateModal = (node: any) => $scope.openAspectModal(node, node.factory, 'create')

  $scope.openAspectModal = function (node: any, item: any, ui_setting: any) {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modals/aspect_modal.html',
      controller: 'aspectModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            object: angular.copy(item),
            ui_setting,
            node
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    return modalInstance.result.then(function (result: any) {
      if (ui_setting === 'create') {
        objectManager.array_action(node.items, result, 'merge')
      }
      if (ui_setting === 'update') {
        return objectManager.array_action(node.items, result, 'update')
      }
    }, function (reason: any) {

    }).finally(function () {
    });
  }

  $scope.openAspectDestroyModal = function (aspect_array: any, aspect: any) {
    const confirmation_object = {
      // severity: severity string
      // title: ti   tle string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm () { return delete_aspect(aspect_array, aspect) },
      message: `<p>This action will:</p> \
<ul> \
<li>Delete the aspect ${aspect.identifier}</li> \
<li>Delete any dependant aspects</li> \
</ul> \
<p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    return $scope.processingStateService.performFunction(aspect, () =>
      confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          $scope.processingStateService.set(
            aspect,
            $scope.processingStateService.processing_states.RESTING
          )
        }
        return true
      })
    );
  }

  var delete_aspect = (aspect_array: any, aspect: any) =>
    Restangular.all(`reference_objects/aspects/${aspect.id}`).remove().then((success: any) => objectManager.array_action(aspect_array, aspect, 'remove')

      , function (error: any) {
        $scope.flashService.process_error(error)
        return utils.log(error)
      })

  return init()
}])
