import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { fortyCore } from '../../../../scripts/services/fortyau_services';
import { Utils } from '../../../../scripts/services/ajs-utils';
import _ from 'lodash';

@Component({
  selector: 'ngSystemDashboard',
  templateUrl: './system-dashboard.component.html'
})
export class SystemDashboardComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  // Properties
  private duckData: Array<any> = [];
  private title: string = 'Complyos Dashboard';
  private duckHistoryEnabled: boolean;
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('fortyCore') private fortyCore: any,  
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.fortyCore = fortyCore
  }

  ngOnInit(): void {
    const ducks = [
      'attachment_count',
      'binder_count',
      'organization_count',
      'requirement_count',
      'user_count'
    ]

    this.loadingStateService.init()
    this.lineUpTheDucks(ducks)
  }

  lineUpTheDucks = (ducks: any) => _.each(ducks, (duck: any) => {
    const duckQuery = {
      model: 'Instance',
      method: duck,
      param_data: {}
    }
    return this.getDuckData(duckQuery).then(() => {
      if (this.duckData.length === ducks.length) {
        this.sortDucks()
        return this.loadingStateService.set_state('content')
      }
    })
  })

  sortDucks = () => {
    this.duckData.sort((a,b) => {
      let firstValue = a.meta.analytic_method
      let secondValue = b.meta.analytic_method
      if(firstValue < secondValue) {
        return -1
      }
      if(firstValue > secondValue) {
        return 1
      }
      return 0
    })
  }

  getDuckData = (duck_query: any) => this.Restangular.one('analytics/gather').get(duck_query).then((response: any) => {
    this.duckData.push(response)
    , (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    }
  })

  getDuckAttributes = (method: any) => {
    let duckAttributes = {}
    switch (this.fortyCore.text.condensePhrase(method, 1, '_')) {
      case 'attachment':
        duckAttributes = {
          icon: 'fa-file-pdf-o',
          display_name: 'Attachments'
        }
        break
      case 'binder':
        duckAttributes = {
          icon: 'fa-list',
          display_name: 'Configured Requirements'
        }
        break
      case 'organization':
        duckAttributes = {
          icon: 'fa-building-o',
          display_name: 'Organizations'
        }
        break
      case 'requirement':
        duckAttributes = {
          icon: 'fa-clipboard',
          display_name: 'Requirements'
        }
        break
      case 'user':
        duckAttributes = {
          icon: 'fa-group',
          display_name: 'Users'
        }
        break
    }
    return duckAttributes
  }

  checkDuckData = (duckStat: any) => {
    if (this.fortyCore.math.check_presence(duckStat)) {
      return duckStat
    } else {
      return 'N/A'
    }
  }

  activeDuckPercentage = (duckStats: any) => {
    if (this.fortyCore.math.check_presence(duckStats.count_active)) {
      return this.fortyCore.math.set_decimal_places(this.fortyCore.math.percentagize_without_rounding(duckStats.count_active / duckStats.count_all), 1) + '%'
    } else {
      return 'N/A'
    }
  }

  seeDuckHistory = (duckStats: any) => this.duckHistoryEnabled = true
}

angular
  .module('complyosClient')
  .directive('ngSystemDashboard', downgradeComponent({ component: SystemDashboardComponent }) as angular.IDirectiveFactory)
