import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngTagRow', // ng-tag-row
  templateUrl: './tag-row.component.html',
  styleUrls: ['./tag-row.component.scss']
})
export class TagRowComponent implements OnInit, OnChanges {

  @Input() tag: any;
  @Input() stageTagFn: any;
  @Input() getTagIconFn: any;
  @Input() tagFilterFn: any;
  @Input() searchString: any;
  @Input() allowCreate: any;
  @Input() allowDestroy: any;

  private filtered_tags: Array<any> = [];

  constructor() { }

  ngOnInit(): void {
    this.filtered_tags = this.tag.children
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes.searchString)
    if (changes.searchString && this.tag.children.length > 0) {
      this.filtered_tags = this.tag.children.filter(t => this.tagFilterFn(t))
    }
  }

}

angular
  .module('complyosClient')
  .directive('ngTagRow', downgradeComponent({ component: TagRowComponent }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('tagRow', [
//   'fortyCore', 'helpService',
//   (fortyCore, helpService) => ({
//     templateUrl: 'views/directives/tag_row.html',
//     restrict: 'E',

//     scope: {
//       stageTagFn: '&',
//       getTagIconFn: '&',
//       tagFilterFn: '&',
//       allowCreate: '<',
//       allowDestroy: '<',
//       tag: '='
//     },

//     link($scope: any, $element: any, $attributes: any) {
//       $scope = this.registerFunctions($scope)

//       return $scope.fortyCore = fortyCore
//     },

//     registerFunctions($scope: any) {
//       return $scope
//     }
//   })
// ])