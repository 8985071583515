import { Component, Inject, OnInit, Input, OnChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngStatusIcon',
  templateUrl: './status-icon.component.html'
})
export class StatusIconComponent implements OnInit, OnChanges {
  @Input() status: any;
  
  // properties
  private statusObject: any = {};

  constructor(
    @Inject('complyosServices') private complyosServices
  ) {
  }

  ngOnInit(): void {
    this.updateStatusObject()
  }

  ngOnChanges(changes: any) {
    if (changes.status && !changes.status.isFirstChange()) {
      this.updateStatusObject()
    }
  }

  updateStatusObject = () => {
    this.statusObject = {
      color: this.complyosServices.snapshot.get_status_color(this.status),
      icon: this.complyosServices.snapshot.get_status_icon(this.status),
      number: this.complyosServices.snapshot.get_status_text(this.status)
    };
  }
}

angular
  .module('complyosClient')
  .directive('ngStatusIcon', downgradeComponent({ component: StatusIconComponent }) as angular.IDirectiveFactory)
