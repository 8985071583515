import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { Utils } from '../../../scripts/services/ajs-utils';
import _ from 'lodash';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import { EntryModalComponent } from '../modals/entry-modal/entry-modal.component';
import { MatDialog } from '@angular/material/dialog';
var dayjs = require("dayjs")

@Component({
  selector: 'ngScheduleList',
  templateUrl: './schedule-list.component.html'
})
export class ScheduleListComponent implements OnInit {
  // Injected Services
  private textService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private bindersNeedStartDateCount = 0;
  private resultsCount: number = 0;
  private outstandingPromises: Array<any> = []
  private filteredSchedule: any;
  private schedule: any;
  private currentFilter: any;


  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('$window') private $window,
    @Inject('fortyCore') private fortyCore: any,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('$q') private $q,
    @Inject('ENV') private ENV,
    @Inject('$httpParamSerializer') private $httpParamSerializer: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private utils: Utils,
    private dialog: MatDialog
  ) { 
    this.textService = fortyCore.text
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      nocontent: {
        icon: 'fa-archive',
        html: '<div>Your Organization has no incomplete task dates matching your selected filters.  Please go to your <a href="' + `${window.location.origin}`+ '/requirements/binders">List</a> to manage your Organization configured requirement(s). If no results, go to the <a href="' + `${window.location.origin}`+ '/requirements/store">Store</a> to configure if the requirement(s) are applicable to your Organization.</div>',
      },
      activeorgprompt: {
        name: 'activeorgprompt',
        icon: 'fa-building',
        text: 'Organization Required. Please select from filter above.'
      },
      currentorgisarchived: {
        name: 'currentorgisarchived',
        icon: 'fa-building',
        text: 'This Organization is Archived. Please select an active Organization from the filter above.'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()

    this.watchEntityFilter()
  }

  watchEntityFilter = () => {
    this.$scope.$on('filterChange-dashboard', (e: any, pills: any) => {
      this.abortAllPromises(this.outstandingPromises)
      setTimeout(() => this.getSchedule(angular.copy(pills)), 50)
      setTimeout(() => this.getNeedsStartDateCount(angular.copy(pills)), 50)
    })
  }

  /* CRUD */

  getNeedsStartDateCount = (pills: any) => {
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }
    this.Restangular.one(`binders/needs_start_date_count`).get(query_params).then(
      (success: any) => {
        let result = success.plain()
        this.bindersNeedStartDateCount = result.record_count
      }
      , (error: any) => {
        this.loadingStateService.process_error(error)
        this.utils.log(error, 'error', null)
      }
    )
  }



  getSchedule = (pills: any) => {
    this.loadingStateService.set_state('loading')
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.Restangular.one(`entries/collection_schedule`).withHttpConfig({ timeout: abort.promise }).get(query_params).then(
      (success: any) => {
        this.loadingStateService.process_success(success.items)
        this.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        this.resultsCount = success.pagination_info.totalCount
        this.schedule = this.processSchedule(success.items)
        this.filteredSchedule = this.processSchedule(success.items) // Filtering happens API side, depricated
      }
      , (error: any) => {
        this.loadingStateService.process_error(error)
        this.utils.log(error, 'error', null)
      }
    )
  }

  viewStartDateNeededOnListPage = () => {
    let startDateNeededPill = {
      isUnique: true,
      Field: 'schedule_generation_state',
      Op: 'Equals',
      displayName: 'Schedule State',
      displayValue: 'Start Date Needed',
      Value: 'start_date_needed',
      dateCreated: dayjs().$d
    }
    this.$rootScope.$broadcast('push-new-filter', startDateNeededPill)
    this.$rootScope.session.goTo('/requirements/binders')
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  /* FORM */

  canLaunchFormTool = (entry: any) => {
    return entry.id && entry.binder.requirement.has_form
  }

  launchFormTool = (entry: any) => {
    let entryOrgId
    let activeOrg = this.$rootScope.session.data.activeOrganization
    if (entry && entry.binder && entry.binder.organization && entry.binder.organization.id) {
      entryOrgId = entry.binder.organization.id
    } else if (activeOrg && activeOrg.id) {
      entryOrgId = this.$rootScope.session.data.activeOrganization.id
    } else {
      alert('no organization set for this record.')
    }
    const params = {
      action: 'completeForm',
      id: entry.id,
      return_url: `${this.$window.location.href}?organization_id=${entryOrgId}`
    }
    const serilalized_params = this.$httpParamSerializer(params)
    const redirect_url = this.ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
    return this.$window.location = redirect_url
  }

  /* VENDOR */

  canOpenVendorModal = (entry: any) => (entry.id !== null) && entry.binder.vendor && (entry.binder.vendor !== null)

  openVendorModal = function (entry: any) {
    let modalInstance
    return modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/vendor_modal.html',
      controller: 'vendorModalController',
      size: 'sm',
      resolve: {
        modalOptions () {
          
          return { vendor: angular.copy(entry.binder.vendor) }
        }
      }
    })
  }

  /**
  * Open the modal to view entry object
  * @param {integer} the id of the entry
  * @param {string} the UI `setting` that the modal wil use.
  *   see entry.js -   setModalSettings() function
  */
  openEntryModal = (entry_object: any, ui_setting: any) => {
    const dialogRef = this.dialog.open(EntryModalComponent, {
      width: '600px',
      data: { entryObject: entry_object, uiSetting: ui_setting }
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'close' || result === undefined || result === 'outsideClick') {
        return
      }

      entry_object = result
      let mi = -1
      let ti = -1

      // search for the entry in the list
      _.each(this.schedule, (monthGroup: any, monthIndex: any) =>
        _.each(monthGroup.entries, function (entry: any, entryIndex: any) {
          if (_.isEqual(entry.id, entry_object.id)) {
            // [monthIndex, entryIndex]
            mi = monthIndex
            return ti = entryIndex
          }
        })
      )

      const entryFound = () => (mi !== -1) && (ti !== -1)

      // for completing an entry
      if (entry_object.complete_status === 'attested_as_complete' || entry_object.state.locked) {
        // remove the entry and the monthGroup
        if (entryFound()) {
          this.schedule[mi].entries.splice(ti, 1)
          // if there is no longer a entry in the monthGroup, then hide that that as well
          if (this.schedule[mi].entries.length === 0) {
            this.schedule.splice(mi, 1)
          }
        }
      } else {
        // for updating the attachment count
        if (entryFound()) {
          // adding org name to match how it will display on the pug file.  it will disappear otherwise
          let entry_object_with_org_name = {
            ...entry_object,
            binder: {
                ...entry_object.binder,
                organization: {
                    display_name: entry_object.binder.organization_name
                }
            }
          }
          this.schedule[mi].entries[ti] = entry_object_with_org_name
        }
      }

      // we update the original list starting on 103 and then update the filtered list
      return this.reflow(this.currentFilter)
    }, (reason: any) => this.utils.log(`Modal dismissed at: ${dayjs().$d} reason: ${reason}`, 'info', null))
  }

  isAll = (entry: any) => {
    return true
  }

  isPartiallyComplete = (entry: any) => {
    return (
      this.isRequiresReview(entry) ||
      this.isRequiresResubmission(entry) ||
      this.isActuallyPartiallyComplete(entry)
    )
  }

  isRequiresReview = (entry: any) => {
    return (
      (entry.attachments.length >= 1) &&
      (entry.binder.requires_review === true)
    )
  }

  isRequiresResubmission = (entry: any) => {
    return entry.complete_status === 'requires_resubmission'
  }

  isActuallyPartiallyComplete = (entry: any) => {
    return (
      (entry.attachments.length >= 1) ||
      (entry.complete_status === 'partially_complete')
    )
  }

  reflow = (filter: any) => {
    let filtered_schedule: any
    if (this.currentFilter) {
      filtered_schedule = []
      _.each(this.schedule, (monthGroup: any) => {
        // first check if there are any matches in the monthGroup.
        const match_in_monthGroup = _.findIndex(monthGroup.entries, (entry: any) => this[`is${filter}`](entry))

        // if there are any matches, then push them into filtered_schedule.
        if (match_in_monthGroup !== -1) {
          const new_month_group = {
            id: monthGroup.id,
            entries: _.filter(monthGroup.entries, (entry: any) => this[`is${filter}`](entry))
          }
          return filtered_schedule.push(new_month_group)
        }
      })
      return this.filteredSchedule = filtered_schedule
    } else {
      return this.filteredSchedule = this.schedule
    }
  }

  applyFilter = (filter: any) => {
    this.currentFilter = filter
    return this.reflow(filter)
  }

  /**
  * Add the month separators, create a title slug, move the ICS
  * strings, etc, etc
  * @param {array} collection of binder objects
  */
  processSchedule = (schedule: any) => {
    let processed = schedule

    // Order by month
    processed = _.orderBy(processed, (p: any) => dayjs(p.event_date).unix()
      , true)

    // Posts go to the same month category if they have the same month and same year
    processed = this.filterHeaderChunk(processed, (p1: any, p2: any) =>
      // true or false
      (dayjs(p1.event_date).month() === dayjs(p2.event_date).month()) && (dayjs(p1.event_date).year() === dayjs(p2.event_date).year())

    // Identify the groups by month
    , (p: any) => dayjs(dayjs(p.event_date)).startOf('month'))

    return processed
  }

  navigate_to_management_url = (binder: any) => {
    window.open(
      binder.mailbox_pairing_management_url,
      '_blank'
    )
  }

  filterHeaderChunk = (orig: any, same: any, getChunkID: any) => {
    if (!(orig instanceof Array)) { return orig }
    if (orig.length === 0) { return orig }
    const result = []
    let cur = []
    let i = 0
    i = 0
    while (i < orig.length) {
      if ((i === 0) || same(orig[i], orig[i - 1])) {
        cur.push(orig[i])
      } else {
        result.push({
          id: getChunkID(orig[i - 1]),
          entries: cur
        })

        cur = [orig[i]]
      }
      i++
    }
    result.push({
      id: getChunkID(orig[orig.length - 1]),
      entries: cur
    })

    i = 0
    while (i < result.length) {
      result[i].$$hashKey = i
      i++
    }
    return result
  }
}

angular
  .module('complyosClient')
  .directive('ngScheduleList', downgradeComponent({ component: ScheduleListComponent }) as angular.IDirectiveFactory)
