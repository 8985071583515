/* eslint-disable
    camelcase,
    no-return-assign,
    no-self-assign,
    no-undef,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import * as _ from 'lodash';

var complyosClient = angular.module('complyosClient')

complyosClient.directive('userOrganizationManager', [
  '$rootScope',
  'confirmationModalFactory',
  'flashService',
  'fortyCore',
  'objectManager',
  'Restangular',
  'loadingStateService',
  '$q',
  function (
    $rootScope: any,
    confirmationModalFactory: any,
    flashService: any,
    fortyCore: any,
    objectManager: any,
    Restangular: any,
    loadingStateService: any,
    $q: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/user_organization_manager.html',
      require: 'ngModel',
      scope: {
        allowCreate: '=',
        allowDestroy: '=',
        allowRead: '=',
        allowUpdate: '=',
        hasError: '=?',
        user: '=',
        pickOrgsAccess: '=',
        roleSelected: '=',
        minsuggestedlength: '=?',
        uiSetting: '=',
        filterByRole: "=",
        filterByLobs: "="
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized
      //    attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        
        $scope.loadingStateService = angular.copy(loadingStateService)
        $scope.outstandingPromises = []
        $scope.uom = {

          initialize () {
            $scope.loadingStateService.init()
            this.allow_create = $scope.allowCreate
            this.allow_destroy = $scope.allowDestroy
            this.allow_read = $scope.allowRead
            this.allow_update = $scope.allowUpdate
            this.has_error = $scope.hasError
            this.pick_orgs_access = $scope.pickOrgsAccess
            this.roleSelected = $scope.roleSelected

            this.user = $scope.user

            this.users_organizations = $ngModel.$modelValue // user organizations
            this.organizations = []
            this.flat_organizations = []

            this.org_query = ''
            this.organizations_to_show = true

            // this.get_organizations()
            this.add_validators()
            this.add_warners()
            this.watch_ng_model()
            this.listen_for_start_event()

            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          get_organizations () {
            this.organizations = []
            this.flat_organizations = []
            
            if (Array.isArray($scope.filterByLobs) && $scope.filterByLobs.length > 0 && $scope.filterByRole) {
              const params = {
                "lob[]": $scope.filterByLobs.map((lob: any) => lob.title),
                "role_id": $scope.filterByRole
              }

              $scope.abortAllPromises($scope.outstandingPromises)
              let abort = $q.defer()
              $scope.outstandingPromises.push(abort)
              $scope.loadingStateService.set_state('loading')

              return Restangular.all('organizations/show_organizations_in_lobs').withHttpConfig({ timeout: abort.promise }).getList(params).then(
                (success: any) => {
                  this.organizations = success
                  this.flat_organizations = $scope.uom.flatten_tree(success)
                  $scope.loadingStateService.process_success(success)
                  if ($scope.uiSetting === 'create') {
                    this.preflight_to_stage_orgs()
                  }
                  return $ngModel.$validate()
                }, function (error: any) {
                  $scope.loadingStateService.process_error(error)
                  return console.log(error)
                });
            } else {
              return Restangular.all('organizations').getList().then(
                (success: any) => {
                  this.organizations = success
                  this.flat_organizations = $scope.uom.flatten_tree(success)
                  $scope.loadingStateService.process_success(success)
                  if ($scope.uiSetting === 'create') {
                    this.preflight_to_stage_orgs()
                  }
                  return $ngModel.$validate()
                }, function (error: any) {
                  $scope.loadingStateService.process_error(error)
                  return console.log(error)
                });
            }
          },

          users_organizations_include (organization: any) {
            return _.some($scope.uom.users_organizations, (user_organization: any) => user_organization.id === organization.id);
          },

          /* STAGE ORGANIZATIONS */

          // Guide to setting the scene for adding orgs to a user...
          // No need for "STAGE" fright!
          // In accordance with COS 813, there was a desire to have new users
          // inherit the "acting" user's orgs.
          // Below are the steps to guide you through the process and "break a leg" out there.
          // after we fill @organizations, preflight_to_stage_orgs() is run to set
          // any inherited orgs with a pending action and label as a preflight org.
          // Due to Angular scope, get_organization_icon() is run to update the
          // scoped variables. This method distinguishes between whether an org is
          // preflighted or not, then returns the icon.
          // If a org is preflighted, it runs get_preflight_org_icon(), else, it
          // continues as normal. (this helps to distinguish between CRUD states)
          // When a user starts checking and unchecking boxes, stage_organization()
          // is run to set an orgs pending_action.
          // stage_organization() will first determine if a org is preflighted,
          // if so, it runs stage_preflight_org(), else it continues.
          // With the preflight logic, the 'CREATE' state is allowed to inherit
          // orgs and act independently.

          preflight_to_stage_orgs () {
            // Loop over each item in array. send that organization checking function.
            // If org has children, also send it recursivly to the children.
            let mark_organization_tree_items = (organization_node_array: any) => {
              _.each(organization_node_array, (organization: any) => {
                if (this.users_organizations_include(organization)) {
                  do_the_thing(organization)
                }
                if (organization.children) {
                  mark_organization_tree_items(organization.children)
                }
              })
            }

            let do_the_thing = (organization: any) => {
              organization.pending_action = 'create'
              organization.preflight_org = true
            }

            mark_organization_tree_items(this.organizations)
          },

          get_preflight_org_icon (organization: any) {
            let icon

            if (organization.pending_action) {
              icon = 'fa fa-plus-square'
            } else {
              icon = 'fa fa-square-o'
            }

            return icon
          },

          get_organization_icon (organization: any) {
            let icon

            if (organization.preflight_org) {
              icon = $scope.uom.get_preflight_org_icon(organization)
            } else {
              if (organization.pending_action) {
                if (organization.pending_action === 'create') {
                  icon = 'fa fa-plus-square'
                } else {
                  icon = 'fa fa-minus-square-o'
                }
              } else { // there are no pending_actions
                if ($scope.uom.users_organizations_include(organization)) {
                  icon = 'fa fa-check-square'
                } else {
                  icon = 'fa fa-square-o'
                }
              }
            }

            return icon
          },

          // creates and sets pending_action attribute on the organization.
          // used for icon display
          // used in process_organizations function
          // used for validation
          // sets pending_action to:
          //   - undefined if pending action is canceled
          //   - destroy   if organization was exsting and is no longer needed
          //   - create    if organization did not exist and is wanted
          stage_organization (organization: any) {
            if ($scope.uiSetting === 'read') {
              return 'in read mode'
            } else {
              // old logic replaced by click3. retained for comparison.
              // if (organization.pending_action) {
              //   organization.pending_action = undefined
              // } else {
              //   if (this.users_organizations_include(organization)) {
              //     organization.pending_action = 'destroy'
              //   } else {
              //     organization.pending_action = 'create'
              //   }
              // }

              // multi click awesomeness!
              // first click -- but not really. we dont track it. If the
              // organization is deselected or pending removal its in the "no" state
              // we turn it "yes" and all the children below it "yes"
              //
              // second click -- (we can tell it is here because it has a value)
              // the value will be in the "yes" state.
              // next logic split is determined by child states.
              // if ANY child is in yes state we set EVERY child to the no state.
              //
              // third click -- top org is still in yes state. similar to second click
              // if there are ZERO children in yes state. we deselect the top org.
              // organization is reset to the no state.
              //
              // Staging & preflight code remain untouched.

              // first click
              if ($scope.uom.click3organizationIsNo(organization)) {
                $scope.uom.click3setYes(organization)
                $scope.uom.click3setAllDescendantsYes(organization)
              } else {
                // second click
                if ($scope.uom.click3anyDescendantYes(organization)) {
                  $scope.uom.click3setAllDescendantsNo(organization)
                // third click
                } else { // All children no
                  $scope.uom.click3setNo(organization)
                }
              }
            }

            return $ngModel.$validate()
          },

          click3organizationIsYes (organization: any) {
            if (organization.preflight_org) {
              switch (organization.pending_action) {
                case 'create':
                  return true
                case undefined:
                  return false
              }
            } else if ($scope.uom.users_organizations_include(organization)) {
              switch (organization.pending_action) {
                case 'destroy':
                  return false
                case undefined:
                  return true
              }
            } else {
              switch (organization.pending_action) {
                case 'create':
                  return true
                case undefined:
                  return false
              }
            }
          },
          click3organizationIsNo (organization: any) {
            return !$scope.uom.click3organizationIsYes(organization)
          },
          click3setYes (organization: any) {
            if (organization.preflight_org) {
              organization.pending_action = 'create'
            } else if (!$scope.uom.users_organizations_include(organization)) {
              organization.pending_action = 'create'
            } else {
              organization.pending_action = undefined
            }
          },
          click3setNo (organization: any) {
            if (organization.preflight_org) {
              return organization.pending_action = undefined
            } else if ($scope.uom.users_organizations_include(organization)) {
              organization.pending_action = 'destroy'
            } else {
              organization.pending_action = undefined
            }
          },
          click3setAllDescendantsYes (organization: any) {
            _.each(organization.children, function (child: any) {
              $scope.uom.click3setYes(child)
              $scope.uom.click3setAllDescendantsYes(child)
            })
          },
          click3setAllDescendantsNo (organization: any) {
            _.each(organization.children, function (child: any) {
              $scope.uom.click3setNo(child)
              $scope.uom.click3setAllDescendantsNo(child)
            })
          },
          click3anyDescendantYes (organization: any) {
            let found = false
            _.each(organization.children, function (child: any) {
              if ($scope.uom.click3organizationIsYes(child)) {
                found = true
              } else {
                if ($scope.uom.click3anyDescendantYes(child)) {
                  found = true
                }
              }
            })
            return found
          },

          flatten_tree (array: any) {
            let flat_array: any = []

            _.each(array, function (item: any) {
              flat_array.push(item)

              if (item['children'] && (item['children'].length > 0)) {
                return flat_array = flat_array.concat(
                  $scope.uom.flatten_tree(item['children'])
                )
              }
            })

            return flat_array
          },

          /* DISPLAY */

          search: {
            show () {
              if ($scope.uom.messages.no_role_selected()) {
                return false // hide
              } else if ($scope.uom.messages.no_orgs_necessary()) {
                return false
              } else {
                return !$scope.uom.messages.no_orgs_configured()
              }
            }
          },

          messages: {
            show () {
              return $scope.uom.messages.no_role_selected() ||
              $scope.uom.messages.no_orgs_necessary() ||
              $scope.uom.messages.no_orgs_configured() ||
              $scope.uom.messages.no_orgs_match()
            },

            no_role_selected () {
              return !$scope.roleSelected &&
              ($scope.roleSelected !== undefined)
            },

            // this message appears if a the pending role is allowed to have 'some' orgs
            no_orgs_necessary () {
              return !$scope.pickOrgsAccess &&
              ($scope.pickOrgsAccess !== undefined)
            },

            // this message should appear in read only mode and the user doesn't have any orgs already set.
            no_orgs_configured () {
              return !$scope.uom.messages.no_orgs_necessary() &&
              !$scope.allowCreate &&
              !$scope.allowDestroy &&
              $scope.uom.users_organizations &&
              ($scope.uom.users_organizations.length === 0)
            },

            // !$scope.uom.organizations_to_show
            // $attributes.required &&
            // $scope.uom.organizations_to_show &&

            // this message should appear in any mode and there is a query and
            // there are orgs to show.
            no_orgs_match () {
              return $scope.uom.org_query &&
              $scope.uom &&
              $scope.uom.filtered_organizations &&
              !$scope.uom.filtered_organizations.length
            }
          }, // &&
          // $scope.uom.users_organizations &&
          // $scope.uom.users_organizations.length

          table: {
            show () {
              if (!$scope.uom.messages.no_role_selected() &&
                !$scope.uom.messages.no_orgs_necessary() &&
                $scope.uom &&
                $scope.uom.filtered_organizations &&
                $scope.uom.filtered_organizations.length
              ) {
                return true
              }
            }
          },

          /* FILTER */

          // checks a organization for a match with search string
          // or a match against other logic
          // takes args
          //   - organization: object checking against
          // returns: true or false
          organization_passes_filter (organization: any) {
            const search_string = $scope.uom.org_query.toLowerCase()
            let match = false

            // create and destroy filter
            if ($scope.allowCreate || $scope.allowDestroy) {
              if ((organization.name.toLowerCase()).indexOf(search_string) >= 0) {
                match = true
              }

              if (search_string.toLowerCase() === 'included') {
                if ((
                  $scope.uom.users_organizations_include(organization) &&
                  (organization.pending_action !== 'destroy')
                ) || (organization.pending_action === 'create')) {
                  match = true
                }
              }
            } else { // read only filter
              if (search_string) {
                if (
                  $scope.uom.users_organizations_include(organization) &&
                  ((organization.name.toLowerCase()).indexOf(search_string) >= 0)
                ) {
                  match = true
                }
              } else {
                if ($scope.uom.users_organizations_include(organization)) {
                  match = true
                }
              }
            }

            if (match) {
              $scope.uom.organizations_to_show = true
            }

            return match
          },

          // allows a filter organizations based on the view mode, and
          // permissions if editing then show all organizations, and allow
          // "organizationged" query if viewing then only the organizationged
          // show up. searchs still works

          organization_filter (organization: any) {
            // recursive function that checks the organization and children
            // against organization_passes_filter function
            // takes args
            //   - organization: object checking against
            // returns: true or false

            var organization_or_descendent_passes_filter = function (organization: any) {
              let match = false

              if ($scope.uom.organization_passes_filter(organization)) {
                match = true
              }

              if ((match === false) && organization.children) {
                // Some (Alias: any) Returns true if any of the values in the list
                // pass the predicate truth test. Short-circuits and stops
                // traversing the list if a true element is found.
                match = _.some(organization.children, (organization: any) => organization_or_descendent_passes_filter(organization))
              }

              return match
            }

            // the function that kicks it all off
            // check the top level organizations, and recursively the children
            // with itself
            // takes args
            //   - organization: object checking against
            // returns: true or false
            return organization_or_descendent_passes_filter(organization)
          },

          /* ACTIONS */

          pending_transactions: 0,
          failed_transactions: 0,

          increment_pending () {
            return this.pending_transactions++
          },

          decrement_pending () {
            this.pending_transactions--
            return this.evaluate_transaction_status()
          },

          increment_failed () {
            return this.failed_transactions++
          },

          // checks to see if there are any more pending_transactions
          // if not then we are done and can call emit_completion_event
          evaluate_transaction_status () {
            if (this.pending_transactions === 0) {
              return this.emit_completion_event()
            }
          },

          // loops over all the organizations, then recursively the children
          // with each organization it accesses any pending actions
          // if it needs to be created then it:
          //   - creates a user_organization
          //   - sends the user_organization to the create_user_organization fn()
          // if it needs to be deleted then it:
          //   - looks up the user_organization in @users_organizations
          //   - sends the user_organization to the destroy_user_organization fn()
          //
          // finally it calls evaluate_transaction_status function in the case
          // that there are no organizations to process.
          // Ensures @emit_completion_event is called
          process_organizations () {
            var process_organizations_and_children = (organizations: any) => {
              // loop through all the organizations and deal with it
              // https://www.youtube.com/watch?v=fZlEi8Kfsag&feature=youtu.be&t=242
              return _.each(organizations, (organization: any) => {
                let user_organization
                if (organization.pending_action === 'destroy') {
                  user_organization = {
                    organization_id: organization.id,
                    user_id: this.user.id
                  }
                  this.destroy_user_organization(user_organization)
                  organization.pending_action === undefined
                }

                if (organization.pending_action === 'create') {
                  user_organization = {
                    organization_id: organization.id,
                    user_id: this.user.id
                  }
                  this.create_user_organization(user_organization)
                  organization.pending_action === undefined
                }

                if (organization.children) {
                  return process_organizations_and_children(organization.children)
                }
              });
            }

            // process all the top level organizations, and the children
            process_organizations_and_children(this.organizations)

            return this.evaluate_transaction_status()
          },

          // creates user_organization.
          // increment_pending action counter
          // on success, pushes user_organization into @users_organizations
          // on error, emits alert to flashService
          // finally decrement_pending action counter
          create_user_organization (user_organization: any) {
            this.increment_pending()

            return Restangular.all(
              'user_organizations'
            ).post(user_organization)

              .then((success: any) => {
                return objectManager.array_action($scope.uom.users_organizations, success, 'merge')
              }

              , (error: any) => {
                const alert = {
                  name: `create_error_${user_organization.organization_id}`,
                  dismissable: true,
                  class: 'alert-warning',
                  icon: 'fa-exclamation-triangle',
                  strong: 'Error:',
                  message: 'There was an error adding user ' +
                  `to organization '${user_organization.organization_id}'. ` +
                  `${flashService.default_alert_by_code(error.status).message}`
                }
                flashService.emit_alert($scope, alert)
                return this.increment_failed()
              }).finally(() => {
                return this.decrement_pending()
              });
          },

          // destroy user_organization.
          // increment_pending action counter
          // on success, removes user_organization from @users_organizations
          // on error, emits alert to flashService
          // finally decrement_pending action counter
          destroy_user_organization (user_organization: any) {
            this.increment_pending()

            // intermediary step that should not be necessary
            const nested_param_obect = {
              'user_organization[user_id]': user_organization.user_id,
              'user_organization[organization_id]': user_organization.organization_id
            }

            return Restangular.all('user_organizations').remove(nested_param_obect)

              .then((success: any) => {
                return objectManager.array_action(
                  $scope.uom.users_organizations,
                  user_organization,
                  'remove'
                )
              }

              , (error: any) => {
                const alert = {
                  name: `delete_error_${user_organization.organization_id}`,
                  dismissable: true,
                  class: 'alert-warning',
                  icon: 'fa-exclamation-triangle',
                  strong: 'Error:',
                  message: 'There was an error removing user ' +
                  `from organization '${user_organization.organization_id}'. ` +
                  `${flashService.default_alert_by_code(error.status).message}`
                }
                flashService.emit_alert($scope, alert)
                return this.increment_failed()
              }).finally(() => {
                return this.decrement_pending()
              });
          },

          /* VALIDATIONS */

          // counts all the organizations for validation
          // loops over all the organizations, then recursively the children
          // includes all the organizations that are:
          //  - pending to be created
          //  - currently organizationged, and not pending a destroy action
          // does not count:
          //  - organizations that are not organizationged
          //  - organizations that are organizationged and pending a destroy action
          //
          // Returns a integer
          get_validaton_length () {
            let count = 0

            var count_organizations_and_children = (organizations: any) => _.each(organizations, function (organization: any) {
              if (organization.pending_action === 'create') {
                count = count + 1
              } else if (
                (organization.pending_action === undefined) &&
                organization.preflight_org
              ) {
                count = count
              } else if (
                $scope.uom.users_organizations_include(organization) &&
                (organization.pending_action !== 'destroy')
              ) {
                count = count + 1
              }

              if (organization.children) {
                return count_organizations_and_children(organization.children)
              }
            })

            // count top level organizations, then children
            count_organizations_and_children(this.organizations)

            return count
          },

          // pushes custom validators into model validators array
          // works with angular form validation
          add_validators () {
            // ensure get_validaton_length is greater 0
            $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              const { required } = $attributes
              if ((required === false) || (required === undefined)) {
                return true
              } else {
                return this.get_validaton_length() > 0
              }
            }

            // ensure get_validaton_length is greater than minlength
            $ngModel.$validators.minlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.minlength) {
                return true
              } else {
                return this.get_validaton_length() >= $attributes.minlength
              }
            }

            // ensure get_validaton_length is less than maxlength
            return $ngModel.$validators.maxlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.maxlength) {
                return true
              } else {
                return this.get_validaton_length() <= $attributes.maxlength
              }
            };
          },

          add_warners () {
            // the object that we are putting our messages in
            $ngModel.$warnings = {}

            return $ngModel.$validators.minsuggestedlength = (modelValue: any, viewValue: any) => {
              if ($scope.minsuggestedlength !== undefined) {
                const minsuggestedlength = `Please select at least ${$scope.minsuggestedlength} organization`
                // add or remove the warning message
                if (this.get_validaton_length() < $scope.minsuggestedlength) {
                  if (!_.has($ngModel.$warnings, 'minsuggestedlength')) {
                    $ngModel.$warnings.minsuggestedlength = minsuggestedlength
                  }
                } else {
                  if (_.has($ngModel.$warnings, 'minsuggestedlength')) {
                    delete $ngModel.$warnings.minsuggestedlength
                  }
                }
                // $ngModel.$warnings = _.without($ngModel.$warnings, warning)

                // syncronate the warnings with the view
                this.warnings = $ngModel.$warnings
              }

              // returning true will keep this validator from causing
              // the manager to error out. We only want a warning not a error
              return true
            };
          },

          /* WATCHERS */

          check_warning_state () {
            return _.isEmpty(this.warnings)
          },

          start_update_organizations () {
            // in order to update organizations there must be a user id
            // if you are editing a user that is already in the system the it is
            // a piece of cake, it has an id and we can rock and roll
            if (this.user.id !== undefined) {
              return this.process_organizations()

            // If you want to make a user and organizations at the same time
            // you must first make a user. and then get the id, this is handled
            // mostly by the user modal and the save relay. they will save the user
            // the user will come back from the api with id
            // this takes time and we need to be patient
            } else {
              return $scope.$watch(function(this: any) {
                return this.user.id
              }
              , (new_value: any, old_value: any, scope: any) => {
                if (this.user.id !== undefined) {
                  return this.process_organizations()
                }
              });
            }
          },

          /* WATCHERS, LISTENERS AND EMITTERS */
          listen_for_start_event () {
            return $scope.$on('user_organization_manager_start', (event: any, data: any) => {
              // reset the failed_transactions so that we
              // have a chance of fixing the issue and trying again
              this.failed_transactions = 0
              // now on to the saving
              return this.start_update_organizations()
            });
          },

          emit_completion_event () {
            const return_object = {
              manager: 'user_organization_manager',
              object: $scope.uom.users_organizations,
              fault: this.failed_transactions > 0
            }
            return $scope.$emit('user_organization_manager_finished', return_object)
          },

          // this syncronizes the $ngModel.$modelValue and @users_organizations
          // we use @users_organizations in the view exclusively
          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
              $scope.uom.users_organizations = $ngModel.$modelValue
            });
          }

        }

        $scope.abortAllPromises = (promiseArray: any) => {
          if (Array.isArray(promiseArray) && promiseArray.length > 0) {
            promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
          }
          promiseArray.length = 0
        }

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.uom.initialize()
            return ng_model_ready = true
          }
        })

        $scope.$watch('filterByLobs',
          function (newValue: any, oldValue: any) {
            $scope.uom.get_organizations()
          }, true
        )

        // TODO move this into it's own function, call on init
        return $scope.$watch(() => $scope.minsuggestedlength, function (new_value: any, old_value: any, scope: any) {
          if (new_value !== old_value) {
            return $ngModel.$validate()
          }
        });
      }
    };
  }

])
