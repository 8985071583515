import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { treeManager } from '../../../../scripts/services/ajs-tree_manager_service';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { FlashService } from '../../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';

@Component({
  selector: 'ngOrganizationList',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})

export class OrganizationListComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  query: string = '';
  organizations: any;
  flat_organizations: any = [];

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(treeManager) private treeManager: treeManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = this.$rootScope.session
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.getOrganizations();
  }

  ngOnChanges() {
    if (this.organizations) {
      this.flat_organizations = this.flatten_tree(this.organizations)
    }
  }

  getOrganizations = () => {
    this.loadingStateService.set_state('loading')
    return this.Restangular.all('organizations').getList().then(response => {
      this.organizations = response
      this.loadingStateService.process_success(response)
      this.search();
      this.flat_organizations = this.treeManager.flatten_tree(this.organizations)
    },
    (error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    });
  }

  flatten_tree = (tree: any) => {
    const flattened_tree: any = []

    var recursively_flatten = function (branch: any) {
      _.each(branch, function (object: any) {
        flattened_tree.push(object)

        if (object.children) {
          return recursively_flatten(object.children)
        }
      })
    }
  }

  resetOrganizationTreeVisibility = () => {
    this.treeManager.recursively_assign_key(this.organizations, 'show', true)
    this.treeManager.recursively_assign_key(this.organizations, 'showChildren', false)
  }

  // watchEntityFilter = ((newValue: any, oldValue: any) => {
  //   this.flat_organizations = this.$rootScope.tree_manager.flatten_tree(this.organizations)
  // }, true)

  search = _.debounce(function() {
    this.resetOrganizationTreeVisibility();
    if (this.query !== '') {
      this.treeManager.recursively_assign_key(this.organizations, 'show', false);

      const recursively_evaluate_visibility = (organization_array: any) => {
        let any_match = false
        // Loop over the organization_array and look for any_match in tree
        organization_array.forEach((organization: any) => {
          let exact_match = false
          let deep_match = false
          // check the top level organization for an exact_match
          if (this.organizationQueryMatch(organization)) {
            exact_match = true
          }
          // loop over the children in a recursive manner look for a deep_match.
          if (organization.children) {
            deep_match = recursively_evaluate_visibility(organization.children)
          }
          // set any_match varible for recursion purposes.
          if (exact_match || deep_match) {
            any_match = true
          }
          // Assign show attributes based on match variables
          organization.show = (exact_match || deep_match)
          organization.showChildren = deep_match
        })
        return any_match
      }

      recursively_evaluate_visibility(this.organizations)
    }
  }, 200)

  recordCount = () => {
    if (this.query) {
      let num = this.flat_organizations.filter((org: any) => this.organizationQueryMatch(org)).length
      return `(${num} Matches)`
    } else {
      let num = this.flat_organizations.length
      if (num !== 0) {
        return `(${num} Total)`
      }
    }
  }

  // change the active organization for user
  attempt_change_organization = (organization: any) => {
    this.processingStateService.performFunction(organization, () => {
      if (Array.isArray(organization.children) && organization.children.length > 0) {
        let organizationHasChildren = true
        let preventFilterChange = false
        this.$rootScope.session.setOrganization(organization, preventFilterChange, organizationHasChildren)
      } else {
        this.$rootScope.session.setOrganization(organization)
      }
      this.$rootScope.session.goTo('/home')
    })
  }

        /* FILTER */

  // function that actually does string comparison for matching.
  organizationQueryMatch = (organization: any) => {
    const search_string = this.query.toLowerCase()
    let match = false

    if (search_string === '') {
      match = true
    } else if (organization.name.toLowerCase().indexOf(search_string) >= 0) {
      match = true
    } else if (organization.identifier && ((organization.identifier.toLowerCase()).indexOf(search_string) >= 0)) {
      match = true
    } else if (organization.org_type && (organization.org_type.toLowerCase().indexOf(search_string) >= 0)) {
      match = true
    } else if (organization.region && ((organization.region.toLowerCase()).indexOf(search_string) >= 0)) {
      match = true
    } else if (organization.locality && ((organization.locality.toLowerCase()).indexOf(search_string) >= 0)) {
      match = true
    }

    return match
  }

  openOrganizationCreateModal = (organizations: any) => {
    const new_organization_object = {}
    return this.openOrganizationModal(new_organization_object, 'create', organizations)
  }

  openOrganizationModal = function (organization_object: any, ui_setting: any, organizations: any) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/organization_modal.html',
      controller: 'organizationModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            organization: angular.copy(organization_object),
            ui_setting,
            
            organizations: angular.copy(organizations)
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then((result: any) => {
      if (ui_setting === 'create') {
        // ensure that the organization will be visible.
        result.show = true
        this.treeManager.add_object_to_tree(this.organizations, result)
      }

      if (this.$rootScope.session.getOrganization() && (this.$rootScope.session.getOrganization().id === result.id)) {
        return this.$rootScope.session.setOrganization(result)
      }
    }, (reason: any) => {
    }).finally(() => this.processingStateService.set(organization_object, this.processingStateService.processing_states.RESTING));
  }
}

angular
  .module('complyosClient')
  .directive('ngOrganizationList', downgradeComponent({ component: OrganizationListComponent }) as angular.IDirectiveFactory)
