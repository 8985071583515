// Config setting for Bulk Action tabs on /requirements/binders
export class tab_config {
  // op:1 = 'equal to', op:2 = 'contains', op:3 = 'not equal to'
  static tab_configs = [
    // The assign tab needs requirements that are not paired with Engineering Central and do have a start date
    {
      tab_name: "assign",
      display_name: "Bulk Assign",
      additional_filter_pills: [{
        simple_column_filter: true,
        Field: 'schedule_start_date',
        op: 3,
        Value: null
      }, {
        simple_column_filter: true,
        Field: 'mailbox_pairing_courier_id',
        op: 1,
        Value: null
      }],
      columns: ["organization_name", "display_name", "compliance_leader_name", "program_leader_name, task_agent_name"]
    },
        
    // The archive tab needs all requirements that are not currently archived
    {
      tab_name: "archive",
      display_name: "Bulk Archive",
      additional_filter_pills: [{
         Field: 'schedule_generation_state',
         Value: 'active'
      }],
      columns: ["organization_name", "display_name", "schedule_state", "start_date"]
    }
  ]

  static loadConfig = (tab_name:any) => {
      var current_config = tab_config.tab_configs.find((config: { tab_name:any }) => config.tab_name === tab_name) 
      return current_config
  }
}
