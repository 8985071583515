/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

declare var Dynamsoft: any
declare var EnumDWT_ImageType: any

var complyosClient = angular.module('complyosClient')

complyosClient.controller('pdfCaptureModalController', ['$q', '$scope', '$timeout', '$uibModalInstance', 'fileService', 'flashService', 'modalOptions', 'utils', function (
  $q: any,
  $scope: any,
  $timeout: any,
  $uibModalInstance: any,
  fileService: any,
  flashService: any,
  modalOptions: any,
  utils: any
) {
  /* INITIALIZATION */

  let DWObject: any

  const init = function () {
    
    $scope.flashService = angular.copy(flashService)
    $scope.modalOptions = modalOptions

    // Register OnWebTwainReady event.
    // This event fires as soon as Dynamic Web TWAIN is initialized and ready to be used
    Dynamsoft.WebTwainEnv.RegisterEvent(
      'OnWebTwainReady',
      continue_init()
    )
    // Manually call the load function this will respond with OnWebTwainReady event
    return Dynamsoft.WebTwainEnv.Load()
  }

  var continue_init = () =>
    set_DWObject().then((success: any) => assemble_sources()

      , (failure: any) => $scope.flashService.add_alert(
      {
        name: 'set_DWObject_failure',
        message: `Failed to initialize in a timely manner. Please re-open this modal. -- ${failure}`
      }
    )
    )

  // Get the Dynamic Web TWAIN object that is embeded in the div with id 'dwtcontrolContainer'
  // it takes a second to load, so we need to wait_for_it until it is ready
  var set_DWObject = function () {
    const deferred = $q.defer()
    let elapsed_time = 0
    const alloted_time = 5000
    const interval = 50

    // wait for the DWObject, fail after the alloted_time has elapsed
    var wait_for_it = () =>
      $timeout(function () {
        DWObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer')
        if (DWObject) {
          return deferred.resolve(DWObject)
        } else {
          if (elapsed_time < alloted_time) {
            elapsed_time += interval
            return wait_for_it()
          } else {
            return deferred.reject(`Failed to set DWObjects in ${elapsed_time}ms.`)
          }
        }
      }
      , interval)

    wait_for_it()

    return deferred.promise
  }

  var assemble_sources = function () {
    const default_source_name = DWObject.__defaultSourceName.trim()
    let source_count = DWObject.SourceCount

    // does something ... From Dynamsoft examples on gathering sources
    if ((source_count === 0) && Dynamsoft.Lib.env.bMac) {
      DWObject.CloseSourceManager()
      DWObject.ImageCaptureDriverType = 0
      DWObject.OpenSourceManager()
      source_count = DWObject.SourceCount
    }

    // show a message that there are no scan sources
    if (source_count === 0) {
      return $scope.flashService.add_alert(
        {
          name: 'DWObject.OpenSource_failure',
          message: 'Cannot locate a scan source.' +
            'Please ensure the scanner is connected and on, then re-open this modal' +
            ` -- DWObject.SourceCount = ${source_count}.`
        }
      )

    // loop over and process the sources for user selection
    } else {
      let i = 0
      return (() => {
        const result = []
        while (i < source_count) {
          const current_source_name = DWObject.GetSourceNameItems(i).trim()
          // shove the source into the sources array
          $scope.capture.options.sources.push(
            {
              index: i,
              label: current_source_name
            }
          )
          // if there is only one source, select it
          if (source_count === 1) {
            $scope.capture.settings.SourceIndex = 0
          }
          // if the source name is == __defaultSourceName, save it in settings
          if (current_source_name === default_source_name) {
            $scope.capture.settings.SourceIndex = i
          }
          result.push(i++)
        }
        return result
      })()
    }
  }

  $scope.capture = {

    /* OPTIONS */

    options: {
      sources: [],
      resolutions: [
        { label: '100 dpi', value: 100 },
        { label: '150 dpi', value: 150 },
        { label: '200 dpi', value: 200 },
        { label: '300 dpi', value: 300 }
      ],
      pixel_types: [
        { label: 'BW', value: 0 },
        { label: 'GRAY', value: 1 },
        { label: 'RGB', value: 2 }
        // { label: "PALETTE",  value: 3 },
        // { label: "CMY",      value: 4 },
        // { label: "CMYK",     value: 5 },
        // { label: "YUV",      value: 6 },
        // { label: "YUVK",     value: 7 },
        // { label: "CIEXYZ",   value: 8 },
        // { label: "LAB",      value: 9 },
        // { label: "SRGB",     value: 10 },
        // { label: "SCRGB",    value: 11 },
        // { label: "INFRARED", value: 16 },
      ]
    },

    /* SETTINGS */

    settings: {
      SourceIndex: undefined,
      IfShowUI: false,
      PixelType: 1,
      Resolution: 200,
      IfFeederEnabled: true,
      IfDuplexEnabled: false,
      IfDisableSourceAfterAcquire: true,
      IfAutomaticDeskew: true,
      PageSize: 3
    },

    /* INDICES */

    current_index: -1,

    last_index () {
      return this.image_urls.length - 1
    },

    /* IMAGE URLS */

    image_urls: [],

    total_images () {
      return this.image_urls.length
    },

    load_image_urls () {
      const all_indices = _.range(DWObject.HowManyImagesInBuffer)
      const loaded_indices = _.range(this.total_images())
      const new_indices = _.difference(all_indices, loaded_indices)
      _.each(new_indices, (index: any) => {
        return this.image_urls.push(this.get_image_url(index))
      })
      return this.current_index = new_indices[0]
    },

    get_image_url (index: any) {
      return DWObject.GetImageURL(index)
    },

    update_current_image_url () {
      return this.image_urls[this.current_index] = this.get_image_url(this.current_index)
    },

    /* NAVIGATION */

    navigate_first () {
      return this.current_index = 0
    },

    navigate_previous () {
      return this.current_index--
    },

    navigate_next () {
      return this.current_index++
    },

    navigate_last () {
      return this.current_index = this.last_index()
    },

    /* EDITING */

    zoom_level: 100,

    zoom_in () {
      return this.zoom_level = this.zoom_level * 1.2
    },

    zoom_out () {
      return this.zoom_level = this.zoom_level / 1.2
    },

    rotate_left () {
      if (DWObject.RotateLeft(this.current_index)) {
        return this.update_current_image_url()
      }
    },

    rotate_right () {
      if (DWObject.RotateRight(this.current_index)) {
        return this.update_current_image_url()
      }
    },

    flip () {
      if (DWObject.Flip(this.current_index)) {
        return this.update_current_image_url()
      }
    },

    mirror () {
      if (DWObject.Mirror(this.current_index)) {
        return this.update_current_image_url()
      }
    },

    destroy () {
      if (DWObject.RemoveImage(this.current_index)) {
        this.image_urls.splice(this.current_index, 1)
        if (this.current_index > this.last_index()) {
          return this.current_index--
        }
      }
    }

  }

  /* IMAGE AQUISITION */

  $scope.load_image = function () {
    // Open the system's file browser to load image
    DWObject.IfShowFileDialog = true
    // Load images in all supported formats (.bmp, .jpg, .tif, .png, .pdf).
    return DWObject.LoadImageEx(
      '', // fileName
      EnumDWT_ImageType.IT_ALL, // imageType
      acquire_image_success, // optionalAsyncSuccessFunc
      acquire_image_failure // optionalAsyncFailureFunc
    )
  }

  $scope.acquire_image = function () {
    // Select a Data Source (a device like scanner) from the Data Source Manager.
    DWObject.SelectSourceByIndex($scope.capture.settings.SourceIndex)

    if (DWObject.OpenSource() === false) {
      return $scope.flashService.add_alert(
        {
          name: 'DWObject.OpenSource_failure',
          message: `Cannot connect to device, please ensure the source is connected and on. \
-- DWObject.OpenSource() failed.`
        }
      )
    } else {
      DWObject.IfShowUI = $scope.capture.settings.IfShowUI
      DWObject.PixelType = $scope.capture.settings.PixelType
      DWObject.Resolution = $scope.capture.settings.Resolution
      DWObject.IfFeederEnabled = $scope.capture.settings.IfFeederEnabled
      DWObject.IfDuplexEnabled = $scope.capture.settings.IfDuplexEnabled
      DWObject.IfDisableSourceAfterAcquire = $scope.capture.settings.IfDisableSourceAfterAcquire
      DWObject.IfAutomaticDeskew = $scope.capture.settings.IfAutomaticDeskew
      DWObject.PageSize = $scope.capture.settings.PageSize

      // if paper is NOT loaded on the feeder
      if ($scope.capture.settings.IfFeederEnabled) {
        if ((DWObject.IfFeederLoaded !== true) && (DWObject.ErrorCode === 0)) {
          $scope.flashService.add_alert(
            {
              name: 'DWObject.OpenSource_failure',
              message: 'No paper detected. Please load papers and try again.'
            }
          )
          return
        }
      }

      // actually get the image
      DWObject.AcquireImage(
        acquire_image_success,
        acquire_image_failure
      )

      return DWObject.RegisterEvent('OnPostTransfer', () => DWObject_OnPostTransfer())
    }
  }

  var DWObject_OnPostTransfer = function () {
    // DWObject.BlankImageMaxStdDev = 20;
    if (DWObject.IsBlankImageExpress(DWObject.CurrentImageIndexInBuffer)) {
      return DWObject.RemoveImage(DWObject.CurrentImageIndexInBuffer)
    }
  }

  var acquire_image_success = () =>
    $timeout(() => $scope.capture.load_image_urls()
      , 250)

  var acquire_image_failure = (errorCode: any, errorString: any) =>
    $scope.flashService.add_alert(
      {
        name: 'acquire_image_failure',
        message: `Failed to load acquire image(s). -- ${errorCode}: ${errorString}`
      }
    )

  /* SAVING */

  $scope.submit_form = function (form: any) {
    if (form.$valid) {
      return convert_to_base_64()
    } else {
      return form.failed_submission = true
    }
  }

  var convert_to_base_64 = function () {
    // indices: specifies the indices of images in buffer. The index is 0-based.
    const indices = Array.apply(null, { length: DWObject.HowManyImagesInBuffer }).map(Number.call, Number)
    // Type: Numeric Value (IT_BMP: 0,s IT_JPG: 1,s IT_TIF: 2,s IT_PNG: 3,s IT_PDF: 4)
    const enumImageType = 4
    return DWObject.ConvertToBase64(indices, enumImageType, convert_to_base_64_success, convert_to_base_64_failure)
  }

  var convert_to_base_64_success = function (base_64_object: any) {
    const file_name = `${$scope.modalOptions.file_name.trim().replace(/\s+/g, '_')}.pdf`
    const mime_type = 'application/pdf'
    const base_64_data = base_64_object.getData(0, base_64_object.getLength())
    const file_object = fileService.base64toFile(mime_type, base_64_data, file_name)
    return close_modal(file_object)
  }

  var convert_to_base_64_failure = (errorCode: any, errorString: any) =>
    // TODO -- test this
    $scope.flashService.add_alert(
      {
        name: 'convert_to_base_64_failure',
        message: `Saving file failed. -- ${errorCode}: ${errorString}`
      }
    )

  /* DEBUGGING */

  $scope.show_me_the_money = function () {
    console.log('http://developer.dynamsoft.com/dwt/api-reference')
    console.log('$scope.pdf_capture_form')
    console.log($scope.pdf_capture_form)
    console.log('modalOptions')
    console.log(modalOptions)
    console.log('$scope.capture')
    console.log($scope.capture)
    console.log('DWObject')
    console.log(DWObject)
  }

  /* MODAL */

  var close_modal = function (file: any) {
    Dynamsoft.WebTwainEnv.Unload()
    return $uibModalInstance.close(file)
  }

  $scope.dismiss_modal = function () {
    Dynamsoft.WebTwainEnv.Unload()
    return $uibModalInstance.dismiss('cancel')
  }

  return init()
}])
