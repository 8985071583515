/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('snapshotMetrics', [
  'complyosServices',
  'fortyCore',
  function (complyosServices: any, fortyCore: any) {
    return {
      restrict: 'EA',
      templateUrl: 'views/directives/snapshot_metrics.html',
      scope: {
        metrics: '=metrics',
        showPercent: '=?',
        showZeros: '=?'
      },

      link ($scope: any, $element: any, $attrs: any) {
        $scope.mo = { // metrics_object

          initialize () {
            // set the defaults
            this.showPercent = $scope.showPercent
            this.showZeros = $scope.showZeros
            this.metrics = $scope.metrics
          },

          get_total () {
            return this.metrics.total ? this.metrics.total : 0
          },

          get_legend_color (metric: any) {
            return complyosServices.snapshot.get_status_color(metric)
          },

          titalize (string: any) {
            const split_words = fortyCore.text.splitWords(string, '_')
            return fortyCore.text.titalize(split_words)
          },

          calculate_percent_of_total (value: any) {
            const percent = ((value / this.get_total()) * 100)
            return Number((percent).toFixed(1))
          }
        }

        $scope.mo.initialize()
      }
    };
  }

])
