import { Component, Inject, Input, OnInit } from '@angular/core';
import * as Restangular from '../../../../vendor/restangular/restangular'
import angular from 'angular';
import { FlashService } from '../../../services/flash_service.service';

@Component({
  selector: 'ng-requirement-version-history-list',
  templateUrl: './requirement-version-history-list.component.html',
  styleUrls: ['./requirement-version-history-list.component.scss']
})
export class RequirementVersionHistoryListComponent implements OnInit {

  @Input() requirementId: number;
  @Input() flashServiceRef: FlashService

  // Injected Services
  private loadingStateService: any;

  //properties
  version_history: Array<any> = []
  requirement: any

  constructor(
    private Restangular: Restangular,
    @Inject('$uibModal') private $uibModal,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('$rootScope') private $rootScope,
  ) {
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.getVersionHistory()
  }

  getVersionHistory() {
    this.loadingStateService.set_state('loading')
    return this.Restangular.one(`requirements/${ this.requirementId }/get_version_history`).get().then(
      (success: any) => {
        this.version_history = success.plain()
      }, (error: any) => {
        this.flashServiceRef.process_error(error)
      }
    ).finally(() => { this.loadingStateService.set_state('content') });
  }

  getVersion(snapshotId) {
    return this.Restangular.one(`requirements/${this.requirementId}/show_version/${snapshotId}`).get().then(
      (success_response: any) => {
        var version = success_response.plain()
        console.log(version)
      }, (error: any) => this.flashServiceRef.process_error(error)
    ).finally(function () { });
  }

  hasEditState = () => {
    return this.version_history.find((vh) => vh.is_edit_state)
  }
  createEditState = () => {
    this.loadingStateService.set_state('loading')
    return this.Restangular.one(`requirements/${this.requirementId}/load_or_create_edit_state`).get().then((success: any) => {
      // this.editRequirement = success.plain()
      this.loadingStateService.set_state('content')
      this.$rootScope.$broadcast('requirementEditState', success.id)
    }, (error) => {
      this.flashServiceRef.process_error(error)
      this.loadingStateService.set_state('error')
    }).then(() => { this.getVersionHistory() })
  }

  inspectRequirementVersion = (requirementId, versionInfo) => {
    return this.Restangular.one(`requirements/${requirementId}/show_version/${versionInfo.snapshot_id}`).get().then(
      (success_response: any) => {
        var version = success_response.plain()
        this.openRequirementModal(version, "read", versionInfo)
      }, (error: any) => this.flashServiceRef.process_error(error)
    ).finally(function () { });
  }
  inspectRequirement = (requirementId) => this.Restangular.all('requirements').get(requirementId).then(
    (success: any) => {
      var requirement = success.plain()
      this.openRequirementModal(requirement, "read")
    } 
  )
  editRequirement = (requirementId) => this.Restangular.one(`requirements/${requirementId}/load_or_create_edit_state`).get().then(
    (response: any) => {
      var requirement = response.plain()
      this.openRequirementModal(requirement, "update")
    }, (error: any) => this.flashServiceRef.process_error(error)
  )

  openRequirementModal = function (requirement_object: any, uiSetting: any, versionInfo: any = null, related_object: any = null) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/ajs-requirement-modal-adapter.html',
      controller: 'ajsRequirementModalAdapter',
      size: 'lg',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions() {
          return {
            object: angular.copy(requirement_object),
            versionInfo: angular.copy(versionInfo),
            uiSetting
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    let that = this
    modalInstance.result.finally(() => {
      this.getVersionHistory()
    });
  }

}
