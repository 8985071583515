/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('requirementsInformationController', ['$state', '$scope', 'loadingStateService', 'Restangular', 'fileService', 'utils', function (
  $state: any,
  $scope: any,
  loadingStateService: any,
  Restangular: any,
  fileService: any,
  utils: any
) {
  /* LOADING FEEDBACK */
  
  $scope.loadingStateService = angular.copy(loadingStateService)
  $scope.loadingStateService.init()

  /* RESTANGULAR */
  const requirement = Restangular.one(`binders/${$state.params.binder_id}/requirement`)

  $scope.getAttachment = (attachment: any) => Restangular.one(`attachments/${attachment.id}/download`).get().then((response: any) => fileService.downloadBase64File(
    response,
    attachment.document_content_type,
    attachment.document_file_name
  )

  , function (error: any) {
    // TODO Flash Service
    utils.log('Failed attachment GET.', 'error')
    return utils.log(error)
  })

  const getRequirement = () =>
    requirement.get().then(function (response: any) {
      $scope.loadingStateService.process_success(response)
      return $scope.requirement = response
    }

    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    })

  return getRequirement()
}])
