/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import * as $ from 'jquery';

var complyosClient = angular.module('complyosClient')

complyosClient.directive('stateManager', [
  'flashService',
  'Restangular',
  function (
    flashService: any,
    Restangular: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/state_manager.html',
      require: 'ngModel',
      scope: {
        isArchivable: '=?',
        isLockable: '=?',
        isUpdateable: '=?',
        isInactivateable: '=?',
        binderPairingState: '=?',
        lockedWarning: '=?',
        unlockedWarning: '=?',
        archivedWarning: '=?',
        inactiveWarning: '=?',
        activateWarning: '=?',
        hasCitation: '=?'
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.sm = {

          /* INIT */
          initialize () {
            // set the defaults 
            this.is_archivable = $scope.isArchivable !== undefined ? $scope.isArchivable : false
            this.is_lockable = $scope.isLockable !== undefined ? $scope.isLockable : false
            this.is_inactivateable = $scope.isInactivateable !== undefined ? $scope.isInactivateable : false
            this.is_updateable = $scope.isUpdateable !== undefined ? $scope.isUpdateable : false
            this.binder_pairing_state = $scope.binderPairingState !== undefined ? $scope.binderPairingState : false
            this.archived_warning = $scope.archivedWarning !== undefined ? $scope.archivedWarning : 'This item will be archived. Archiving will cause the item to be uneditable and is irreversible. Use with caution.'
            this.paired_archived_warning = $scope.pairedArchivedWarning !== undefined ? $scope.pairedArchivedWarning : 'This requirement is paired and will need to be deleted in the Engineering Central Future PM Admin prior to archiving in Complyos. For more information on what is paired, see the Integrations tab.'
            this.inactive_warning = $scope.inactiveWarning !== undefined ? $scope.inactiveWarning : 'This item will be inactivated.'
            this.activate_warning = $scope.activateWarning !== undefined ? $scope.activateWarning : 'This item will be reactivated.'
            this.locked_warning = $scope.lockedWarning !== undefined ? $scope.lockedWarning : 'This item will be locked. Locking will cause the item to be uneditable until it is unlocked by an admin.'
            this.unlocked_warning = $scope.unlockedWarning !== undefined ? $scope.unlockedWarning : 'This item will be unlocked. Unlocking will cause the item to be editable again.'
            this.has_citation = $scope.hasCitation !== undefined ? $scope.hasCitation : false
            this.citation_warning = $scope.citation_warning !== undefined ? $scope.citation_warning : 'This Requirement cannot be archived because Citation Received box is checked.'
            
            this.staged_state_value = angular.copy($ngModel.$modelValue)

            this.watch_ng_model()
            return this.listen_for_form_save_event()
          },

          /* HELPERS */

          // If our staged_state_value and original_value don't exist, we cannot calculate based on them
          // They exist after initialization, so this method can be used as a sanity check for that
          is_initialized () {
            return !!this.staged_state_value && !!$ngModel.$modelValue
          },

          /* INACTIVE */

          // Show method will run, so we know everything is initialized.
          // Because show runs first, we don't need that check in subsequent view related methods
          // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
          inactive_show () {
            // If 'I have been initialized' && 'I am inactivatable' && 'I am inactive'
            return this.is_initialized() && ((this.is_inactivateable && this.is_updateable) || !!this.staged_state_value.inactive)
          },

          inactive_disabled () {
            return !this.is_inactivateable
          },

          inactive_toggle () {
            if (!this.inactive_disabled()) {
              return this.staged_state_value.inactive = !this.staged_state_value.inactive
            }
          },

          inactive_warning_show () {
            return this.is_initialized() && !this.inactive_disabled() && !$ngModel.$modelValue.inactive && this.staged_state_value.inactive
          },

          activate_warning_show () {
            return this.is_initialized() && !this.inactive_disabled() && $ngModel.$modelValue.inactive && !this.staged_state_value.inactive
          },

          /* LOCKED */

          // Show method will run, so we know everything is initialized.
          // Because show runs first, we don't need that check in subsequent view related methods
          // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
          locked_show () {
            // If 'I have initialized' && (('I am able to be interacted with') || 'I am locked')
            return this.is_initialized() && ((this.is_lockable && this.is_updateable) || !!this.staged_state_value.locked)
          },

          locked_warning_show () {
            return this.is_initialized() && !this.locked_disabled() && !$ngModel.$modelValue.locked && this.staged_state_value.locked
          },

          unlocked_warning_show () {
            return this.is_initialized() && !this.locked_disabled() && $ngModel.$modelValue.locked && !this.staged_state_value.locked
          },

          locked_disabled () {
            return !this.is_updateable
          },

          locked_toggle () {
            if (!this.locked_disabled()) {
              return this.staged_state_value.locked = !this.staged_state_value.locked
            }
          },

          /* ARCHIVED */

          // Show method will run, so we know everything is initialized.
          // Because show runs first, we don't need that check in subsequent view related methods
          // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
          archived_show () {
            // If 'I have initialized' && (('I an interactable') || 'I am archived')
            return this.is_initialized() && ((this.is_archivable && this.is_updateable) || !!$ngModel.$modelValue.archived)
          },

          archived_warning_show () {
            return this.is_initialized() && !this.archived_disabled() && !$ngModel.$modelValue.archived && this.staged_state_value.archived
          },

          paired_archived_warning_show () {
            return this.is_initialized() && !this.archived_disabled() && !$ngModel.$modelValue.archived && this.staged_state_value.archived && this.binder_pairing_state
          },

          citation_warning_show () {
            return this.is_initialized() && !this.archived_disabled() && !$ngModel.$modelValue.archived && this.staged_state_value.archived && this.has_citation
          },

          archived_disabled () {
            return !this.is_updateable || !!$ngModel.$modelValue.archived
          },

          archived_toggle () {
            if (!this.archived_disabled()) {
              // $ngModel.$modelValue.archived = !$ngModel.$modelValue.archived
              $scope.$emit('state_manager_updated', !this.staged_state_value.archived)
              return this.staged_state_value.archived = !this.staged_state_value.archived
            }
          },

          /* ACTIONS */

          get_changed_state () {
            const patch_object :any = {}

            if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.locked !== !!this.staged_state_value.locked)) {
              patch_object.locked = this.staged_state_value.locked
            }

            if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.archived !== !!this.staged_state_value.archived)) {
              patch_object.archived = this.staged_state_value.archived
            }

            if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.inactive !== !!this.staged_state_value.inactive)) {
              patch_object.inactive = this.staged_state_value.inactive
            }

            if ($.isEmptyObject(patch_object)) {
              return null
            } else {
              return patch_object
            }
          },

          save_state () {
            const patch_object = this.get_changed_state()

            // Empty objects dont need to be patched
            if (patch_object === null) {
              // No changes were made, therefore, no changes should be emitted
              return this.emit_completion_event(null)
            } else {
              return Restangular.all(`/states/${$ngModel.$modelValue.id}`).patch(patch_object).then((success_response: any) => {
                this.staged_state_value = success_response.state
                return this.failure = false
              }
              , (error_response: any) => {
                const alert = {
                  name: 'update_state',
                  dismissable: true,
                  class: 'alert-warning',
                  icon: 'fa-exclamation-triangle',
                  strong: 'Error:',
                  message: `There was an error updating the state. ${flashService.default_alert_by_code(error_response.status).message}`
                }
                flashService.emit_alert($scope, alert)
                return this.failure = true
              }).finally(() => {
                return this.emit_completion_event(this.staged_state_value)
              });
            }
          },

          /* WATCHERS, LISTENERS AND EMITTERS */
          listen_for_form_save_event () {
            return $scope.$on('state_manager_start', (event: any, data: any) => {
              return this.save_state()
            });
          },

          emit_completion_event (changed_state: any) {
            const return_object = {
              manager: 'state_manager',
              object: changed_state,
              fault: this.failure
            }

            return $scope.$emit('state_manager_finished', return_object)
          },

          // this syncronizes the $ngModel.$modelValue and @staged_state_value
          // we use this when not updating, because staged values are unnecessary
          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
              if (!this.is_updateable) {
                return this.staged_state_value = $ngModel.$modelValue
              }
            });
          }

        } // END STATE MANAGER

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.sm.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
