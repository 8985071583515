/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
'use strict'

import angular from "angular";

export class tagModalController implements angular.IController {
    static $inject = ['$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'utils'];

    $onInit() { }

    constructor(
        $scope: any,
        $uibModalInstance: any,
        flashService: any,
        fortyCore: any,
        modalOptions: any,
        Restangular: any,
        utils: any) {
        
        // ...
        // code goes here
        const init = function () {

            $scope.flashService = angular.copy(flashService)
            $scope.processingStateService = fortyCore.processingStateService
            $scope.tag = modalOptions.tag
            $scope.ui_setting = modalOptions.ui_setting

            $scope.unaltered_tag = angular.copy(modalOptions.tag)

            // Prepare Tags for select list

            return $scope.parent_select_opitons = angular.copy(flatten_tags(modalOptions.tags))
        }

        /* DISPLAY */

        // creates a flattened array of the nested $scope.parent_select_opitons array and updates
        // the title with spacing for tree structure
        var flatten_tags = function (tags_array: any) {
            tags_array.sort(function (a: any, b: any) {
                if (a['title'].toLowerCase() > b['title'].toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            let flattened_tags = []
            let i = 0
            while (i < tags_array.length) {
                tags_array[i].title = `${determine_depth(tags_array[i])}${tags_array[i].title}`
                flattened_tags.push(tags_array[i])
                flattened_tags = flattened_tags.concat(flatten_tags(tags_array[i]['children']))
                i++
            }
            return flattened_tags
        }

        // creates dynamic indentions for the tag select menu
        // TODO maybe not the best name.
        var determine_depth = function (tag: any) {
            let spacing = ''
            let i = 0
            while (i < tag.depth) {
                spacing += '\u00A0\u00A0'
                i++
            }
            return spacing
        }

        // helper method to disable select options that are self, children, descendants
        // Adds disabled attr option item in $('#parent_select option').
        // takes Arg:
        //   - tag: object
        // Returns
        //  nothing
        var disable_tags = function (tag: any) {
            // Find self, disble
            let option
            for (option of Array.from($('#parent_select option'))) {
                if (option.value === `number:${tag.id}`) {
                    option.disabled = true
                }
            }

            // find children, disable
            let i = 0
            while (i < tag.children.length) {
                for (option of Array.from($('#parent_select option'))) {
                    if (option.value === `number:${tag.children[i].id}`) {
                        option.disabled = true
                        if (tag.children !== undefined) {
                            disable_tags(tag.children[i])
                        }
                    }
                }
                i++
            }
        }

        setTimeout(function () {
            if ($scope.tag.children !== undefined) {
                disable_tags($scope.tag)
            }
        }, 1000)

        /* SAVE */

        $scope.submit_form = (tag_form: any, tag_object: any) =>
            // State service will set the form_object to a state of PENDING
            // After action is performed or cancelled, that action will be responsible
            // for setting object to RESTING state
            $scope.processingStateService.performFunction(tag_form, function () {
                if (tag_form.$valid) {
                    if ($scope.ui_setting === 'update') {
                        return update_tag(tag_object)
                    } else {
                        return create_tag(tag_object)
                    }
                } else {
                    $scope.processingStateService.set(
                        tag_form,
                        $scope.processingStateService.processing_states.RESTING
                    )
                    tag_form.failed_submission = true
                    return tag_object.title.$invalid = true
                }
            })

        var create_tag = (tag_object: any) => Restangular.all('tags').post(tag_object).then((success: any) => closeModalAndReturn(success), function (error: any) {
            $scope.flashService.process_error(error)
            return utils.log(error)
        }).finally(() =>
            $scope.processingStateService.set(
                $scope.tag_form,
                $scope.processingStateService.processing_states.RESTING
            )
        )

        var update_tag = (tag_object: any) => Restangular.one('tags', tag_object.id).patch(tag_object).then((success: any) => closeModalAndReturn(success), function (error: any) {
            $scope.flashService.process_error(error)
            return utils.log(error)
        }).finally(() =>
            $scope.processingStateService.set(
                $scope.tag_form,
                $scope.processingStateService.processing_states.RESTING
            )
        )

        $scope.dismissModal = function () {
            $uibModalInstance.dismiss('cancel')
        }

        var closeModalAndReturn = function (object: any) {
            $uibModalInstance.close(object)
        }

        init()
    }
}
angular
    .module('complyosClient')
    .controller('tagModalController', tagModalController)


// ------------------------------------




// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('tagModalController', ['$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'utils', function (
//   $scope: any,
//   $uibModalInstance: any,
//   flashService: any,
//   fortyCore: any,
//   modalOptions: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
    
//     $scope.flashService = angular.copy(flashService)
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.tag = modalOptions.tag
//     $scope.ui_setting = modalOptions.ui_setting
    
//     $scope.unaltered_tag = angular.copy(modalOptions.tag)

//     // Prepare Tags for select list
    
//     return $scope.parent_select_opitons = angular.copy(flatten_tags(modalOptions.tags))
//   }

//   /* DISPLAY */

//   // creates a flattened array of the nested $scope.parent_select_opitons array and updates
//   // the title with spacing for tree structure
//   var flatten_tags = function (tags_array: any) {
//     tags_array.sort(function (a: any, b: any) {
//       if (a['title'].toLowerCase() > b['title'].toLowerCase()) {
//         return 1
//       } else {
//         return -1
//       }
//     })
//     let flattened_tags = []
//     let i = 0
//     while (i < tags_array.length) {
//       tags_array[i].title = `${determine_depth(tags_array[i])}${tags_array[i].title}`
//       flattened_tags.push(tags_array[i])
//       flattened_tags = flattened_tags.concat(flatten_tags(tags_array[i]['children']))
//       i++
//     }
//     return flattened_tags
//   }

//   // creates dynamic indentions for the tag select menu
//   // TODO maybe not the best name.
//   var determine_depth = function (tag: any) {
//     let spacing = ''
//     let i = 0
//     while (i < tag.depth) {
//       spacing += '\u00A0\u00A0'
//       i++
//     }
//     return spacing
//   }

//   // helper method to disable select options that are self, children, descendants
//   // Adds disabled attr option item in $('#parent_select option').
//   // takes Arg:
//   //   - tag: object
//   // Returns
//   //  nothing
//   var disable_tags = function (tag: any) {
//     // Find self, disble
//     let option
//     for (option of Array.from($('#parent_select option'))) {
//       if (option.value === `number:${tag.id}`) {
//         option.disabled = true
//       }
//     }

//     // find children, disable
//     let i = 0
//     while (i < tag.children.length) {
//       for (option of Array.from($('#parent_select option'))) {
//         if (option.value === `number:${tag.children[i].id}`) {
//           option.disabled = true
//           if (tag.children !== undefined) {
//             disable_tags(tag.children[i])
//           }
//         }
//       }
//       i++
//     }
//   }

//   setTimeout(function () {
//     if ($scope.tag.children !== undefined) {
//       disable_tags($scope.tag)
//     }
//   }, 1000)

//   /* SAVE */

//   $scope.submit_form = (tag_form: any, tag_object: any) =>
//     // State service will set the form_object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible
//     // for setting object to RESTING state
//     $scope.processingStateService.performFunction(tag_form, function () {
//       if (tag_form.$valid) {
//         if ($scope.ui_setting === 'update') {
//           return update_tag(tag_object)
//         } else {
//           return create_tag(tag_object)
//         }
//       } else {
//         $scope.processingStateService.set(
//           tag_form,
//           $scope.processingStateService.processing_states.RESTING
//         )
//         tag_form.failed_submission = true
//         return tag_object.title.$invalid = true
//       }
//     })

//   var create_tag = (tag_object: any) => Restangular.all('tags').post(tag_object).then((success: any) => closeModalAndReturn(success), function (error: any) {
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   }).finally(() =>
//     $scope.processingStateService.set(
//       $scope.tag_form,
//       $scope.processingStateService.processing_states.RESTING
//     )
//   )

//   var update_tag = (tag_object: any) => Restangular.one('tags', tag_object.id).patch(tag_object).then((success: any) => closeModalAndReturn(success), function (error: any) {
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   }).finally(() =>
//     $scope.processingStateService.set(
//       $scope.tag_form,
//       $scope.processingStateService.processing_states.RESTING
//     )
//   )

//   $scope.dismissModal = function () {
//     $uibModalInstance.dismiss('cancel')
//   }

//   var closeModalAndReturn = function (object: any) {
//     $uibModalInstance.close(object)
//   }

//   return init()
// }])
