import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, ValidationErrors } from '@angular/forms';
import * as Restangular from '../../../vendor/restangular/restangular'
import { ValidationHelpers } from '../../extensions/ValidationHelpers';

@Component({
  selector: 'ng-requirement-author-notes-editor',
  templateUrl: './requirement-author-notes-editor.component.html',
  styleUrls: ['./requirement-author-notes-editor.component.scss']
})
export class RequirementAuthorNotesEditorComponent implements OnInit {

  @Input() defaultNote: string
  @Input() uiSetting: string = 'update';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()

  //Injected Serivces
  public session: any;

  //Properties
  form: FormGroup
  formReady: boolean = false;
  current_selected_interval = {}
  selected_option: any = {}
  disable_instructions = true
  preloaded_option: any = {}
  freeText: any = {}
  ValidationHelpers = ValidationHelpers

  constructor(
    public controlContainer: ControlContainer,
    @Inject('$rootScope') private $rootScope,
    private Restangular: Restangular,
  ) {
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.addFormFields();
  }

  // LISTS


  addFormFields = () => {
    this.form = this.controlContainer.control as FormGroup

    this.formReady = true;
  }
}
