/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('applicationNavigationController', ['$rootScope', '$scope', function (
  $rootScope: any,
  $scope: any
) {
  /* HELPER METHODS */
  function isVisible (element: any) {
    return element.visible()
  }

  /* DISPLAY */
  const set_navigation = function () {
    return $rootScope.navigation = {

      collapsed: true,

      collapse () {
        return this.collapsed = true
      },

      toggle () {
        return this.collapsed = !this.collapsed
      },

      brand: {
        text () {
          if ($rootScope.session.activeOrganizationPresent()) {
            return $rootScope.session.getOrganization().name.replace(/ /g, '\u00a0');
          } else if ($rootScope.session.sso_domained()) {
            return 'HCA Complyos'
          } else {
            return 'Complyos'
          }
        },
        action () {
          if ($rootScope.session.activeUserPresent()) { // user is logged in
            $rootScope.session.goTo('/home')
          } else { // user is not logged in
            $rootScope.session.goTo('/main')
          }
        }
      },

      envBanner: {
        env: $rootScope.session.data.ENV.NAME,
        visible () {
          return(
            this.env == 'local' || this.env == 'qa' || this.env == 'staging'
          )
        },
        text () {
          return this.env.toUpperCase();
        }
      },

      items: [
        {
          id: 'navLinkOrganizations',
          icon: undefined,
          text: 'Organizations',
          action () {
            $rootScope.session.goTo('/complyos/organizations')
          },
          visible () {
            return (
              $rootScope.session.activeUserPresent() &&
              $rootScope.session.userCan('organizations_read')
            )
          }
        },
        {
          id: 'navGroupBurger',
          icon: 'fa-bars',
          text: 'Menu',
          textClass: 'visible-xs',
          visible () {
            return (
              $rootScope.session.activeUserPresent()
            )
          },
          links: [
            {
              id: 'navLabelComplyos',
              text: 'Complyos',
              visible () {
                // find the group inside the container array
                var container = $scope.navigation.items.find((item: any) => {
                  return item.id === 'navGroupBurger'
                })
                // Define all the visual "sub-items" under this label
                let subItemIds = [
                  'navLinkComplyosRequirements',
                  'navLinkComplyosReferences',
                  'navLinkComplyosOrganizations',
                  'navLinkComplyosUsers',
                  'navLinkComplyosTags',
                  'navLinkComplyosDashboard',
                  'navLinkComplyosReports',
                  'navLinkComplyosEmailLogs',
                  'navLinkComplyosCommunications',
                  'navLinkComplyosPermissions',
                  'navLinkComplyosOperators'
                ]
                // filter all of the subItems from container.links
                let subItems = container.links.filter((link: any) => {
                  return subItemIds.includes(link.id)
                })
                // return true if below condtions are met
                return (
                  subItems.some(isVisible)
                )
              }
            },
            {
              id: 'navLinkComplyosRequirements',
              icon: 'fa-clipboard',
              text: 'Requirements',
              action () {
                $rootScope.session.goTo('/complyos/requirements')
              },
              visible () {
                // user appears to be a requirement read only type of user
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('requirements_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosReferences',
              icon: 'fa-book',
              text: 'References',
              action () {
                $rootScope.session.goTo('/complyos/references')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('references_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosOperators',
              icon: 'fa-pencil',
              text: 'Operators',
              action () {
                $rootScope.session.goTo('/complyos/operators')
              },
              visible () {
                return (
                  $rootScope.session.userCan('operators_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosOrganizations',
              icon: 'fa-building-o',
              text: 'Organizations',
              action () {
                $rootScope.session.goTo('/complyos/organizations')
              },
              visible () {
                return (
                  $rootScope.session.userCan('organizations_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosUsers',
              icon: 'fa-group',
              text: 'Users',
              action () {
                $rootScope.session.goTo('/complyos/users')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('users_complyos_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosTags',
              icon: 'fa-tags',
              text: 'Tags',
              action () {
                $rootScope.session.goTo('/complyos/tags')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('tags_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosDashboard',
              icon: 'fa-line-chart',
              text: 'Dashboard',
              action () {
                $rootScope.session.goTo('/complyos/dashboard')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('dashboard_complyos_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosReports',
              icon: 'fa-file-excel-o',
              text: 'Reports',
              action () {
                $rootScope.session.goTo('/complyos/reports')
              },
              visible () {
                return (
                  $rootScope.session.userCan('reports_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosEmailLogs',
              icon: 'fa-envelope-o',
              text: 'Email Logs',
              action () {
                $rootScope.session.goTo('/complyos/email_logs')
              },
              visible () {
                return (
                  $rootScope.session.userCan('email_logs_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosCommunications',
              icon: 'fa-bullhorn',
              text: 'Communications',
              action () {
                $rootScope.session.goTo('/complyos/communications')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('communications_page_visit')
                )
              }
            },
            {
              id: 'navLinkComplyosPermissions',
              icon: 'fa-unlock-alt',
              text: 'Permissions',
              action () {
                $rootScope.session.goTo('/complyos/permissions')
              },
              visible () {
                return (
                  $rootScope.session.userCan('permissions_page_visit')
                )
              }
            },
            {
              id: 'navLabelOrganization',
              text: 'Organization',
              visible () {
                // find the group inside the container array
                var container = $scope.navigation.items.find((item: any) => {
                  return item.id === 'navGroupBurger'
                })
                // Define all the visual "sub-items" under this label
                let subItemIds = [
                  'navLinkOrganizations',
                  'navLinkOrganizationDashboard',
                  'navLinkOrganizationUsers',
                  'navLinkOrganizationSurveyMode',
                  'navLinkOrganizationValidationModeComments'
                ]
                // filter all of the subItems from container.links
                let subItems = container.links.filter((link: any) => {
                  return subItemIds.includes(link.id)
                })
                // return true if below condtions are met
                return (
                  $rootScope.session.activeUserPresent() &&
                  // $rootScope.session.activeOrganizationPresent() &&
                  subItems.some(isVisible)
                )
              }
            },
            {
              id: 'navLinkOrganizationDashboard',
              icon: 'fa-tachometer',
              text: 'Dashboard',
              action () {
                $rootScope.session.goTo('/organization/dashboard')
              },
              visible () {
                // LOGIC USED IN HOME_LINKS.JS
                // Keep this current, then apply there.
                return (
                  // $rootScope.session.activeOrganizationPresent() &&
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('dashboard_organization_page_visit')
                )
              }
            },
            {
              id: 'navLinkOrganizationUsers',
              icon: 'fa-users',
              text: 'Users',
              action () {
                $rootScope.session.goTo('/organization/users')
              },
              visible () {
                return (
                  // $rootScope.session.activeOrganizationPresent() &&
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('users_organization_page_visit')
                )
              }
            },
            {
              id: 'navLinkOrganizationSurveyMode',
              icon: 'fa-clipboard',
              text: 'SurveyMode&#174;',
              action () {
                $rootScope.session.goTo('/organization/survey_mode')
              },
              visible () {
                return (
                  // $rootScope.session.activeOrganizationPresent() &&
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('surveymode_page_visit')
                )
              }
            },
            {
              id: 'navLinkOrganizationValidationModeComments',
              icon: 'fa-comments-o',
              text: 'ValidationMode&#174;',
              action () {
                $rootScope.session.goTo('/organization/validation_mode_comments')
              },
              visible () {
                return (
                  // $rootScope.session.activeOrganizationPresent() &&
                  $rootScope.session.activeUserPresent() &&
                  $rootScope.session.userCan('validationmode_page_visit')
                )
              }
            },
            {
              id: 'navLabelRequirements',
              text: 'Requirements',
              visible () {
                // find the group inside the container array
                var container = $scope.navigation.items.find((item: any) => {
                  return item.id === 'navGroupBurger'
                })
                // Define all the visual "sub-items" under this label
                let subItemIds = [
                  'navLinkRequirementsBinders',
                  'navLinkRequirementsSchedule',
                  'navLinkRequirementsStore'
                ]
                // filter all of the subItems from container.links
                let subItems = container.links.filter((link: any) => {
                  return subItemIds.includes(link.id)
                })
                // return true if below condtions are met
                return (
                  $rootScope.session.activeUserPresent() &&
                  // $rootScope.session.activeOrganizationPresent() &&
                  subItems.some(isVisible)
                )
              }
            },
            {
              id: 'navLinkRequirementsBinders',
              icon: 'fa-list',
              text: 'List',
              action () {
                $rootScope.session.goTo('/requirements/binders')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent() &&
                  // $rootScope.session.activeOrganizationPresent() &&
                  $rootScope.session.userCan('store_page_visit')
                )
              }
            },
            {
              id: 'navLinkRequirementsSchedule',
              icon: 'fa-calendar',
              text: 'Schedule',
              action () {
                $rootScope.session.goTo('/requirements/schedule')
              },
              visible () {
                // LOGIC USED IN HOME_LINKS.JS
                // Keep this current, then apply there.
                return (
                  $rootScope.session.activeUserPresent() &&
                  // $rootScope.session.activeOrganizationPresent() &&
                  // $rootScope.session.activeOrganizationInteractable() &&
                  $rootScope.session.userCan('schedule_page_visit')
                )
              }
            },
            {
              id: 'navLinkRequirementsStore',
              icon: 'fa-cart-plus',
              text: 'Store',
              action () {
                $rootScope.session.goTo('/requirements/store')
              },
              visible () {
                // LOGIC USED IN HOME_LINKS.JS
                // Keep this current, then apply there.
                return (
                  $rootScope.session.activeUserPresent() &&
                  // $rootScope.session.activeOrganizationPresent() &&
                  // $rootScope.session.activeOrganizationInteractable() &&
                  $rootScope.session.userCan('store_page_visit')
                )
              }
            }
          ]
        },
        {
          id: 'navGroupUser',
          icon: 'fa-user',
          text: 'Account',
          textClass: 'visible-xs',
          visible () {
            return true
          },
          links: [
            {
              id: 'navLinkHome',
              icon: 'fa-home',
              text: 'Home',
              action () {
                $rootScope.session.goTo('/home')
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkUserAccount',
              icon: 'fa-user',
              text: 'My Profile',
              action () {
                $rootScope.session.goTo('/user/' + $rootScope.session.getUser().id)
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkLoginSso',
              icon: 'fa-sign-out',
              text: 'Login: Single Sign On',
              action () {
                $rootScope.session.goTo('/user_services/sso')
              },
              visible () {
                return (
                  $rootScope.session.data.sso_domained &&
                  !$rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkLoginUnPw',
              icon: 'fa-sign-out',
              text: 'Login: Username/Password',
              action () {
                $rootScope.session.goTo('/user_services/login')
              },
              visible () {
                return (
                  !$rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkSuspend',
              icon: 'fa-clock-o',
              text: 'Suspend',
              action () {
                $rootScope.session.suspendSession()
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkLogout',
              icon: 'fa-sign-out',
              text: 'Logout',
              action () {
                $rootScope.session.logOut()
              },
              visible () {
                return (
                  $rootScope.session.activeUserPresent()
                )
              }
            },
            {
              id: 'navLinkForgotPassword',
              icon: 'fa-question-circle',
              text: 'Forgot Password',
              action () {
                $rootScope.session.goTo('/user_services/forgot_password')
              },
              visible () {
                return (
                  !$rootScope.session.activeUserPresent()
                )
              }
            }
          ]
        },
        {
          id: 'navLinkHelp',
          icon: 'fa-question-circle',
          text: 'Help',
          textClass: 'visible-xs',
          action () {
            if ($rootScope.session.activeUserPresent()) {
              $rootScope.session.goTo('/help')
            } else {
              $rootScope.session.goTo('/marketing')
            }
          },
          visible () {
            return true
          }
        }
      ]
    };
  }

  /* Initialization */
  set_navigation()

  /* LISTENERS */
  return $scope.$on('session_updated', (scope: any, object: any) => set_navigation());
}])
