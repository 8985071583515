// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsBinderNotificationsController', ['$rootScope', '$state', '$scope', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'Restangular', 'utils', function (
//   $rootScope: any,
//   $state: any,
//   $scope: any,
//   confirmationModalFactory: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
    
//     $scope.flashService = angular.copy(flashService)
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.newNotifications = []
//     $scope.processingStateService = fortyCore.processingStateService

//     $scope.loadingStateService.init()

//     $scope.$on('notifications_update', (event: any, data: any) => getNotifications())

//     $scope.$on('binder_update', (event: any, data: any) => $scope.binder = data)

//     getBinder()
//     getNotifications()
//     return getUsers()
//   }

//   var getBinder = function () {
//     // there might allready be a binder
//     if (!$scope.binder) {
//       return Restangular.all('binders').get($state.params.binder_id).then((
//         // success
//         response: any
//       ) => $scope.binder = response

//       , (error: any) => utils.log(error));
//     }
//   }

//   var getUsers = function (organization_id: any = null) {
//     if (!organization_id) {
//       try {
//         organization_id = $scope.binder.organization_id
//       } catch (ex) { }
//     }
//     if (organization_id) {
//       Restangular.all(`organizations/${organization_id}/org_users`).getList(
//         {
//           'assignable': true,
//           'requirement_id': $scope.binder.requirement_id
//         }
//       ).then((response: any) => { // success
//         $scope.loadingStateService.process_success(response)
//         return $scope.users = response
//       }
//       , (error: any) => utils.log(error))
//     }
//   }

//   $scope.filtered_users = () =>
//     _.filter($scope.users, (u: any) => ((u.user_role.role.title === 'User') || (u.user_role.role.title === 'Organization Admin') || u.user_role.role.title === 'Reviewer' ) && !user_has_notification(u))

//   var user_has_notification = function (user: any) {
//     if ($scope.notifications) {
//       const users_with_notifications = $scope.notifications.map((n: any) => n.user_id)
//       return users_with_notifications.indexOf(user.id) > -1
//     } else {
//       return false
//     }
//   }

//   var getNotifications = () =>
//     Restangular.all(`binders/${$state.params.binder_id}/notifications`).getList().then(function (response: any) {
//       $scope.loadingStateService.process_success(response)
//       return $scope.notifications = response
//     }
//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     })

//   $scope.notification_is_untouchable = function (notification: any) {
//     if ($scope.binder) {
//       const n = notification
//       const b = $scope.binder
//       return (n.user_id === b.user_compliance_admin_id) || (n.user_id === b.user_responsible_id) || (n.user_id === b.user_assigned_id)
//     }
//   }

//   $scope.addNewNotification = function () {
//     const notification = {
//       user_id: null,
//       binder_id: $state.params.binder_id,
//       first: true,
//       second: true,
//       third: true
//     }
//     return $scope.newNotifications.push(notification)
//   }

//   $scope.attemptSaveNewNotification = (notification_object: any) => // State service will set the object to a state of PENDING
//   // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//   $scope.processingStateService.performFunction(notification_object, () => saveNewNotification(notification_object))

//   var saveNewNotification = (notification_object: any) => Restangular.all('notifications').post(notification_object).then(function (success: any) {
//     // remove the new notification form the temp list
//     $scope.newNotifications = _.reject($scope.newNotifications, (n: any) => n === notification_object)

//     // if the user already has a notification for this binder the database the API will update it.
//     // we need to reflect that action.
//     // so we remove the matching object in $scope.notifications, then we put the new one back in
//     $scope.notifications = _.reject($scope.notifications, (n: any) => n.user_id === success.user_id)

//     // Push the newly created or updated object into the $scope.notifications
//     return $scope.notifications.push(success)
//   }, function (error: any) {
//     var notification: any = notification || {}
//     $scope.processingStateService.set(notification, $scope.processingStateService.processing_states.RESTING)
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   })

//   $scope.updateNotification = (notification: any) => Restangular.all(`notifications/${notification.id}`).patch(notification).then(function (success: any) {
//   }, function (error: any) {
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   })

//   // Confirmation for delete of notification on delete button click
//   $scope.attemptDeleteNotification = function (notification_object: any) {
//     const confirmation_object = {
//       // severity: severity string
//       // title: title string
//       // button_icon: icon string
//       // button_text: text string
//       // deny: method to run on dismiss
//       confirm () { return deleteNotification(notification_object) }
//       // message: message string
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(notification_object, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(notification_object, $scope.processingStateService.processing_states.RESTING)
//         }
//         return true
//       })
//     );
//   }

//   var deleteNotification = (notification: any) => Restangular.all(`notifications/${notification.id}`).remove().then((success: any) => $scope.notifications = _.reject($scope.notifications, (n: any) => n === notification)
//   , function (error: any) {
//     $scope.processingStateService.set(notification, $scope.processingStateService.processing_states.RESTING)
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   })

//   return init()
// }])
