/* eslint-disable
    camelcase,
    no-undef,
*/
import * as angular from 'angular';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';

export class ArrayFunctions {
  static $inject = ['$rootScope'];
  constructor(
    private $rootScope: any
  ) {
  }

  pushAndOverwriteList(targetList: any, updatesList: any, matchFn: any) {
    if (updatesList && Array.isArray(updatesList)) {
      updatesList.forEach(update => {
        targetList = this.pushAndOverwrite(targetList, update, matchFn)
      })
    }

    return targetList
  }

  pushAndOverwrite(targetList: any, update: any, matchFn: any) {
    let newList = targetList.filter((existingEntry: any) => !matchFn(existingEntry, update))
    newList.push(update)

    return newList
  }

  squashOptions(parentList: any, idKey: any, valueKey: any) {
    let squashedList = []
    let deepList = JSON.parse(JSON.stringify(parentList))

    while (deepList.length > 0) {
      let current = deepList[0]
      var squashedEntry = this.squashOption(deepList, idKey, valueKey, current[idKey])
      squashedList.push(squashedEntry)
      deepList = deepList.filter((entry: any) => current[idKey] !== entry[idKey])
    }

    return squashedList
  }

  squashOption(list: any, idKey: any, valueKey: any, matchValue: any) {
    let matches = list.filter((entry: any) => entry[idKey] === matchValue)

    if (matches.length === 1) {
      return matches[0]
    }

    let values: any = []
    matches.forEach((match: any) => values.push(match[valueKey]))

    let returnObj = JSON.parse(JSON.stringify(matches[0]))
    returnObj[valueKey] = values

    return returnObj
  }

  hasMeaningfulListChange(oldList: any, newList: any, entriesToIgnore: any) {
    let isDifferent = false

    // remove ignored entries
    let oldListConsidered = Array.isArray(oldList) ? oldList.filter(entry => !entriesToIgnore.includes(entry.Field)) : []
    let newListConsidered = Array.isArray(newList) ? newList.filter(entry => !entriesToIgnore.includes(entry.Field)) : []
    if(this.$rootScope.session.isNavigationLocked()) {
      oldListConsidered.map(o => { delete o.isLocked })
      newListConsidered.map(n => { delete n.isLocked })
    }

    // if lengths are different then the lists have changed
    isDifferent = oldListConsidered.length !== newListConsidered.length
    if (isDifferent) {
      return isDifferent
    }

    // order both lists but field THEN value
    isDifferent = !_.isEqual(oldListConsidered, newListConsidered)

    return isDifferent
  }

  flattenNestedArrayRecursive(nestedArray: any, parentId = null, level = 0) {
    var localArray: any = []

    if (Array.isArray(nestedArray)) {
      nestedArray.forEach(entry => {
        entry.entryId = entry.id
        if (!entry.entryId) {
          entry.entryId = uuidv4()
        }
        entry.level = level
        if (parentId && level > 0) {
          entry.parentId = parentId
        }
        localArray.push(entry)
        if (entry.children && entry.children.length > 0) {
          var flattenedChildren = this.flattenNestedArrayRecursive(entry.children, entry.entryId, level + 1)
          localArray = localArray.concat(flattenedChildren)
          entry.hasDescendants = true
          // prune children
        }
      })
    }

    return localArray
  }
}

angular
  .module('complyosClient')
  .service('arrayFunctions', ArrayFunctions);


//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').factory('arrayFunctions', ['$rootScope', function (
//   $rootScope: any
// ) {
//   return {
//     pushAndOverwriteList (targetList: any, updatesList: any, matchFn: any) {
//       if (updatesList && Array.isArray(updatesList)) {
//         updatesList.forEach(update => {
//           targetList = this.pushAndOverwrite(targetList, update, matchFn)
//         })
//       }

//       return targetList
//     },

//     pushAndOverwrite (targetList: any, update: any, matchFn: any) {
//       let newList = targetList.filter((existingEntry: any) => !matchFn(existingEntry, update))
//       newList.push(update)

//       return newList
//     },

//     squashOptions (parentList: any, idKey: any, valueKey: any) {
//       let squashedList = []
//       let deepList = JSON.parse(JSON.stringify(parentList))

//       while (deepList.length > 0) {
//         let current = deepList[0]
//         var squashedEntry = this.squashOption(deepList, idKey, valueKey, current[idKey])
//         squashedList.push(squashedEntry)
//         deepList = deepList.filter((entry: any) => current[idKey] !== entry[idKey])
//       }

//       return squashedList
//     },

//     squashOption (list: any, idKey: any, valueKey: any, matchValue: any) {
//       let matches = list.filter((entry: any) => entry[idKey] === matchValue)

//       if (matches.length === 1) {
//         return matches[0]
//       }

//       let values: any = []
//       matches.forEach((match: any) => values.push(match[valueKey]))

//       let returnObj = JSON.parse(JSON.stringify(matches[0]))
//       returnObj[valueKey] = values

//       return returnObj
//     },

//     hasMeaningfulListChange (oldList: any, newList: any, entriesToIgnore: any) {
//       let isDifferent = false

//       // remove ignored entries
//       let oldListConsidered = Array.isArray(oldList) ? oldList.filter(entry => !entriesToIgnore.includes(entry.Field)) : []
//       let newListConsidered = Array.isArray(newList) ? newList.filter(entry => !entriesToIgnore.includes(entry.Field)) : []
//       oldListConsidered.map(o => { delete o.isLocked })
//       newListConsidered.map(n => { delete n.isLocked })

//       // if lengths are different then the lists have changed
//       isDifferent = oldListConsidered.length !== newListConsidered.length
//       if (isDifferent) {
//         return isDifferent
//       }

//       // order both lists but field THEN value
//       isDifferent = !_.isEqual(oldListConsidered, newListConsidered)

//       return isDifferent
//     },

//     flattenNestedArrayRecursive (nestedArray: any, parentId = null, level = 0) {
//       var localArray: any = []

//       if (Array.isArray(nestedArray)) {
//         nestedArray.forEach(entry => {
//           entry.entryId = entry.id
//           if (!entry.entryId) {
//             entry.entryId = uuidv4()
//           }
//           entry.level = level
//           if (parentId && level > 0) {
//             entry.parentId = parentId
//           }
//           localArray.push(entry)
//           if (entry.children && entry.children.length > 0) {
//             var flattenedChildren = this.flattenNestedArrayRecursive(entry.children, entry.entryId, level + 1)
//             localArray = localArray.concat(flattenedChildren)
//             entry.hasDescendants = true
//             // prune children
//           }
//         })
//       }

//       return localArray
//     }
//   };
// }])
