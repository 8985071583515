// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationValidationModeCommentsController', ['$location', '$q', '$rootScope', '$scope', '$uibModal', 'dateService', 'filterService', 'fortyCore', 'loadingStateService', 'Restangular', function (
//   $location: any,
//   $q: any,
//   $rootScope: any,
//   $scope: any,
//   $uibModal: any,
//   dateService: any,
//   filterService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.processingStateService = fortyCore.processingStateService
    
//     $scope.dateService = angular.copy(dateService)
//     $scope.session = $rootScope.session
//     $scope.loadingStateService.init()
//     $scope.validationCommentCount = 0
//     $scope.isActiveOrg = $scope.session.activeOrganizationPresent()

//     // uib-tooltip-html was complaining when I pass text directly, for some
//     // reason it is happy when I pass HTML in a variable like this
//     $scope.addressThisCommentTooltip = 'Address this ValidationMode&#174; comment'
//     $scope.validation_mode_comments = []
//     $scope.outstandingPromises = []

//     if ($location.url().includes('validation_mode')) {
//       $scope.showGlobalFilter = true
//     }

//     watchPillFilter()
//     $scope.watchActiveOrgChange()
//     $scope.watchForNewComments()
//     $scope.watchForUpdateComments()
//   }

//   $scope.isOrgPillSelected = (pills: any) => {
//     let activeOrganizationPresent = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
//     return activeOrganizationPresent
//   }

//   var watchPillFilter = () => {
//     $scope.$on('filterChange-dashboard', function (e: any, pills: any) {
//       $scope.abortAllPromises($scope.outstandingPromises)
//       // if on survey mode page, don't make the api call when there are no org pills
//       // it takes forever, causes timeouts and we don't show this component unless an org pill has been selected anyway
//       if($scope.showGlobalFilter) {
//         setTimeout(() => $scope.get_validation_mode_comments(pills), 50)
//       } else if(!$scope.showGlobalFilter && $scope.isOrgPillSelected(pills)) {
//         setTimeout(() => $scope.get_validation_mode_comments(pills), 50)
//       }
//     })
//   }

//   $scope.watchActiveOrgChange = () => {
//     $scope.$on('activeOrgIdUpdated', () => {
//       $scope.isActiveOrg = $scope.session.activeOrganizationPresent()
//     })
//   }

//   $scope.watchForNewComments = function () {
//     $rootScope.$on('validationModeCommentAdded', (event: any, data: any) => {
//       if ($scope.loadingStateService.loading_state.name === 'nocontent') {
//         $scope.loadingStateService.set_state('content')
//       }
//       $scope.validation_mode_comments.push(data)
//     })
//   }

//   $scope.watchForUpdateComments = function () {
//     $rootScope.$on('validationModeCommentUpdated', (event: any, data: any, flag: boolean) => {
//       $scope.validation_mode_comments.forEach((e: any) => {
//         if(e.entry && e.entry.binder && e.entry.binder.id === data.id){
//           e.entry.binder.has_citation = flag
//         }
//       });
//     })
//   }

//   $scope.get_validation_mode_comments = function (pills: any) {
//     $scope.loadingStateService.set_state('loading')

//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }

//     let abort = $q.defer()
//     $scope.outstandingPromises.push(abort)
    
//     Restangular.one(`binders/validation_mode`).withHttpConfig({ timeout: abort.promise }).get(query_params).then(
//       (success: any) => {
//         $scope.validation_mode_comments = success.plain().items
//         $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//         $scope.validationCommentCount = success.pagination_info.totalCount
//         $scope.loadingStateService.process_success(success.items)
//       }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//       }
//     )
//   }

//   $scope.abortAllPromises = (promiseArray: any) => {
//     if (Array.isArray(promiseArray) && promiseArray.length > 0) {
//       promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
//     }
//     promiseArray.length = 0
//   }

//   $scope.newOrganizationCommentToolTip = function () {
//     if($scope.isActiveOrg) {
//       return `Create a new ValidationMode&#174; comment for ${$rootScope.session.getOrganization().name}.`
//     } else {
//       return `There can only be one organization selected in the filter to add a new validation mode comment.`
//     }
//   }

//   $scope.openValidationModeCommentAcknowlegementModal = function (validation_mode_comment_object: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/notes/validation_mode_comment_response.html',
//       controller: 'validationModeCommentResponseModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             validation_mode_comment_object: angular.copy(validation_mode_comment_object)
//           }
//         }
//       }
//     })

//     modalInstance.result.then(function (returnObject: any) {
//       validation_mode_comment_object.responses.push(returnObject.acknowledgementObject)
//       if (validation_mode_comment_object.noteable_type === 'Entry') {
//         validation_mode_comment_object.entry.complete_status = returnObject.markEntryAsRequiresResubmission
//       }
//     })
//   }

// //   // TODO: ####_CHECK: I dont think this controller file is being used
// //   $scope.submit_form = function (form: any, object: any) {
// //     // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'submit_acknowledgement'.
// //     submit_acknowledgement(form, object)
// //   }

//   $scope.openEntryModal = function (entry_object: any) {
//     let modalInstance
//     let ui_setting = $scope.session.userCan('entries_update') ? 'update' : 'read'
//     modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/entry_modal.html',
//       controller: 'entryModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(entry_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     modalInstance.result.then(function (returnObject: any) {
//       Object.assign(entry_object, returnObject)
//     })
//   }

//   $scope.userRoleIsBLacklisted = function () {
//     if (
//       ($scope.session.getUser().user_role.role.title === 'LOB Admin') ||
//       ($scope.session.getUser().user_role.role.title === 'LOB Read-Only') ||
//       ($scope.session.getUser().user_role.role.title === 'Organization Read-Only')
//     ) {
//       return true
//     }
//   }

//   $scope.openOrganizationNoteModal = function () {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/notes/organization_validation_mode_comment.html',
//       controller: 'organizationValidationModeCommentModalController',
//       size: 'lg',
//       resolve: {
//         modalOptions () {}
//       }
//     })

//     return modalInstance.result.then(
//       (success: any) => {
//         $scope.validation_mode_comments.push(success)
//       }
//     );
//   }

//   init()
// }])
