import { Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
@Component({
  selector: 'ngChangeAlertIcon',
  templateUrl: './change-alert-icon.component.html'
})
export class ChangeAlertIcon implements OnInit {
  @Input() changeAlert!: string;
  @Input() tooltipEnabled!: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}

angular
  .module('complyosClient')
  .directive('ngChangeAlertIcon', downgradeComponent({ component: ChangeAlertIcon }) as angular.IDirectiveFactory)
