/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('requirementModalController', ['$httpParamSerializer', '$scope', '$uibModal', '$uibModalInstance', '$window', 'complyosServices', 'ENV', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'confirmationModalFactory', 'storeService', '$rootScope', function (
  $httpParamSerializer: any,
  $scope: any,
  $uibModal: any,
  $uibModalInstance: any,
  $window: any,
  complyosServices: any,
  ENV: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any,
  confirmationModalFactory: any,
  storeService: any,
  $rootScope: any
) {
  const initialize = function () {
    $scope.requirement = modalOptions.object

    $scope.flashService = angular.copy(flashService)
    $scope.fortyCore = fortyCore
    $scope.processingStateService = fortyCore.processingStateService
    $scope.ui_setting = modalOptions.ui_setting
    $scope.disable_instructions = true

    $scope.compliance_methods = [
      'Form',
      'Form/Template',
      'Template',
      'Local Documentation',
      'Observation',
      'Engineering Central'
    ]

    // where you are using the widget, pass in current state to labeText for the value you want to display
    // [label-text]="'editState'" will display Edit State on the label
    $scope.stateConfigs = {
      published_state: {
        true: { currentState: 'Published', transitionState: 'Unpublished', icon: 'fa fa-check', transitionIcon: 'fa fa-minus', function: 'transitionFunction', currentStateColor: '#18BC9C', transitionStateColor: '#F39C12' },
        false: { currentState: 'Unpublished', transitionState: 'Published', icon: 'fa fa-minus', transitionIcon: 'fa fa-check', function: 'transitionFunction', currentStateColor: '#F39C12', transitionStateColor: '#18BC9C' },
      },
    }
    set_schedule_state_options()
    set_annotation_states()
    // set_submission_window_types()

    $scope.flashService.listen_for_alert($scope)
    if ($scope.ui_setting !== 'create') {
      get_requirement().then(() => {
        if ($scope.ui_setting == 'duplicate') {
          duplicate_requirement()
        }
      })
    } else {
      $scope.requirement.published = false
      $scope.requirement.schedule_on = true
      $scope.requirement.start_date_needed = true
      $scope.requirement_already_published = false
    }

    get_list_of_options()

    if ($scope.ui_setting === 'create') { return get_intervals() }
  }

  // COPIED
  var set_schedule_state_options = () => {
    let isRegularScheduleDisabled = $scope.ui_setting === 'update' && ($scope.requirement.can_disable_schedule_state === false || $scope.requirement.can_disable_managed_by_paired_application === false)
    let isManageByPairedApplicationDisabled = $scope.ui_setting === 'read' || ($scope.requirement.managed_by_paired_application && $scope.requirement.can_disable_managed_by_paired_application === false)
    $scope.schedule_state_options = [
      {
        value: 'schedule_on',
        display: 'Configure with start date',
        disabled: isRegularScheduleDisabled,
        helpText: 'Complyos will generate tasks based on interval and schedule start date'
      },
      {
        value: 'start_date_needed',
        display: 'Configure without start date',
        disabled: true,
        helpText: 'Schedule does not start until user sets "start date"'
      },
      {
        value: 'no_schedule',
        display: 'Schedule Off',
        disabled: isRegularScheduleDisabled,
        helpText: 'Complyos will not generate tasks'
      },
      {
        value: 'managed_by_paired_application',
        display: 'Managed by Paired Application',
        disabled: isManageByPairedApplicationDisabled,
        helpText: 'Third party app may generate tasks'
      }
    ]
  }

  let set_annotation_states = () => {
    $scope.annotation_states = [
      { value: 'annotation_required', display: 'Annotation Required' },
      { value: 'annotation_optional', display: 'Annotation Optional' },
      { value: 'annotation_disabled', display: 'Annotation Disabled' }
    ]
  }

  // // #OPTION_B
  // let set_submission_window_types = () => {
  //   $scope.submission_window_types = [
  //     { value: 'day', display: 'Event Day Only' },
  //     { value: 'month', display: 'Whole Month' }
  //   ]
  // }

  $scope.updateInstructionsText = (event: any) => {
    $scope.requirement.instructions = event
  }

  var get_requirement = () => Restangular.all('requirements').get($scope.requirement.id).then(function (success: any) {
      $scope.requirement = success
      if ($scope.requirement.interval_id) {
        $scope.requirement.interval_id = $scope.requirement.interval_id.toString()
      }
      
      $scope.requirement_already_published = angular.copy(success.published)
      set_schedule_state_options()
      get_intervals()
      get_list_of_options()

      $scope.original_published_state = $scope.requirement.published
      $scope.original_archived_state = !!$scope.requirement.state.archived
      if ($scope.requirement.edit_state_for_id) {
        get_original_requirement_states($scope.requirement.edit_state_for_id)
      }
    }, (error: any) => $scope.flashService.process_error(error)
  )
  var get_original_requirement_states = (id) => Restangular.all('requirements').get(id).then(success => {
      var original_requirement = success.plain()
      $scope.original_published_state = original_requirement.published
      $scope.original_archived_state = original_requirement.state.archived === true
    }, (error: any) => $scope.flashService.process_error(error)
  )


  var duplicate_requirement = () => {
    if ($scope.ui_setting !== 'duplicate') {
      return;
    }

    // zero-out requirement_id & ui_setting
    delete $scope.requirement.id
    $scope.ui_setting = 'create'
    
    // set 
    $scope.default_note = `This requirement was copied from "${$scope.requirement.name}"`
    $scope.requirement.name = `[DUPLICATED] ${$scope.requirement.name}`;

    // detach unrelated objects
    $scope.requirement.notes = []
    $scope.requirement.author_notes = []
    $scope.requirement.attachments = []
    $scope.taggingsToStage = $scope.requirement.taggings
    $scope.requirement.taggings = []
    $scope.requirement.reference_objects.forEach(ro => { ro.pending_action = 'create' })
    $scope.requirement.published = false
    delete $scope.requirement.state

    $scope.flashService.add_alert({
      name: 'duplicate_requirement_modal_alert',
      dismissable: false,
      icon: 'fa-copy',
      class: 'alert-info',
      strong: 'Duplication',
      message: 'You are about to duplicate a requirement'
    })
  }

  var get_list_of_options = () => {
    const params = 'requirement-date-instructions-dropdown'
    return Restangular.all('options_lists/get_list_of_options').get(params).then(function (success: any){
      $scope.options_list = success.plain()
      $scope.options = $scope.options_list.options_values
      set_loaded_instructions()
    })
  }

  // COPIED
  var get_intervals = () => {
    //let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        // text_search: args.apiSearchInputString
      }
    }
    return Restangular.all('intervals').getList(params).then(function (success: any) {
      $scope.intervals = success.plain()
      if ($scope.requirement.interval !== undefined) {
        return $scope.get_interval_description()
      }
    })
  }

  // COPIED
  $scope.submission_window_name = (submission_window_type :string) => {
    let period_name = 'date'
    if (submission_window_type !== null 
      && submission_window_type !== undefined 
      && submission_window_type != "day") {
      // there us another period specified ie: week, month, or year, then use that text
      period_name = submission_window_type
    }

    return period_name;
  }

  // COPIED
  $scope.sync_state_options = (field: any) => {
    let newValue = $scope.requirement[field]

    // if schedule off was just turned on, turn all other options off
    //    and the reverse
    if (field === 'no_schedule' && newValue === true) {
      $scope.requirement.schedule_on = false
      $scope.requirement.start_date_needed = false
      $scope.requirement.managed_by_paired_application = false
    } else if (field !== 'no_schedule' && newValue === true) {
      $scope.requirement.no_schedule = false
    }

    // if schedule off was just turned on, turn all other options off
    if (field === 'managed_by_paired_application' && newValue === true) {
      $scope.requirement.schedule_on = true
    }

    // if schedule was turned on, make start_date_needed match (true and false)
    if ($scope.requirement.schedule_on) {
      $scope.requirement.start_date_needed = true
    } else {
      $scope.requirement.start_date_needed = false
    }

    // if schedule_on is not checked at this stage, managed_by_paired_application also should not be on
    if ($scope.requirement.schedule_on === false) {
      $scope.requirement.managed_by_paired_application = false
    }
  }


  // COPIED
  $scope.confirm_unpublish = function (transitionTo: string) {
    if ($scope.requirement.published === true) {
      const confirmation_object = {
        severity: 'danger',
        title: 'Unpublish Requirement',
        button_icon: 'fa-cloud-download',
        button_text: 'Unpublish',
        message: '<p>Are you sure you want to unpublish this? Unpublishing may have adverse side effects. The preferred flow would be to archive it.</p>',
        confirm () { set_published() }
      }

      return confirmationModalFactory.openConfirmationModal(confirmation_object)
    } else {
      set_published()
    }
  }
  // COPIED
  var set_published = function () {
    return $scope.requirement.published = !$scope.requirement.published
  }
  // COPIED
  var set_loaded_instructions = function () {
    $scope.preloaded_option = _.find($scope.options, (i: any) => i.display_name == $scope.requirement.schedule_start_date_instructions)
    if($scope.preloaded_option === undefined) { 
      $scope.freeText = _.find($scope.options, (i: any) => i.value == 'Free Text')
      $scope.requirement.option_id = $scope.freeText.id.toString()
      $scope.disable_instructions = false
    } else {
      $scope.requirement.option_id = $scope.preloaded_option.id.toString()
    }
  }

  $scope.get_interval_description = function () {
    const selected_interval = _.find($scope.intervals, (i: any) => i.id == $scope.requirement.interval_id)
    return $scope.current_selected_interval = selected_interval
  }
  
  $scope.set_start_date_instructions = function (onLoad: boolean) {
    // load the text into the text area
    $scope.selected_option = _.find($scope.options, (i: any) => i.id == $scope.requirement.option_id)
    $scope.requirement.schedule_start_date_instructions = $scope.selected_option.display_name
    $scope.disable_instructions = $scope.selected_option.display_name != 'Free Text' ? true : false
  }

  $scope.future_scheduling_option_clicked = function (clickedOption: any) {
    let schedulingOptions = ['rolling_start_date', 'user_prompted_date_generation']
    let updatedValue = $scope.requirement[clickedOption]
    if (updatedValue === true) {
      let nonSelectedOptions = schedulingOptions.filter(opt => opt !== clickedOption)
      nonSelectedOptions.forEach(opt => $scope.requirement[opt] = false)
    }
  }

  $scope.launch_form_tool = function () {
    const params = {
      action: 'authorSchema'
    }
    const serilalized_params = $httpParamSerializer(params)
    const redirect_url = ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
    return $window.location = redirect_url
  }

  $scope.viewCurrentVersion = () => {
    $scope.openRequirementModal($scope.requirement, 'read')
  }

  $scope.openRequirementModal = function (requirement_object: any, ui_setting: any) {
    let that = this
    Restangular.one(`requirements/${requirement_object.edit_state_for_id}`).get().then(function (response: any) { // success
      var requirement_object = response.plain()
      $uibModal.open({
        templateUrl: 'views/modals/requirement_modal.html',
        controller: 'requirementModalController',
        size: 'lg',
        resolve: {
          modalOptions() {
            return {
              object: angular.copy(requirement_object),
              ui_setting
            }
          }
        }
      })
    }, function (error: any) {
      return that.utils.log(error, 'error', false)
    })
  }

  $scope.discard_edit_state = function () {
    let that = this
    Restangular.one(`requirements/${$scope.requirement.id}/discard_edit_state`).get().then(function (response: any) { // success
      // var requirement_object = response.plain()
      closeModalAndReturn();
    }, function (error: any) {
      return that.utils.log(error, 'error', false)
    })
  }

  $scope.valid_for_checkpoint = (form: any, object: any) => {
    var valid = true;

    if (!form.field_name.$valid)
      valid = false
      
    return valid;
  }

  $scope.checkpoint_form_submit = ($event, form: any, object: any) => {
    var promise = $scope.submit_form(form, object, false)
  }

  $scope.confirm_submit_form = (requirement_form, requirement) => {
    

    if ($scope.ui_setting === 'create') {
      return $scope.submit_form(requirement_form, requirement)
    }
    const confirmation_object = {
      severity: 'warning',
      title: 'Save',
      button_icon: 'fa-check',
      button_text: 'Save',
      message: '<div><b>Caution:</b> Saving will apply these changes to all configured requirements that use this requirement-template</div><br><div>If you are not ready to apply these changes to configured requirements, use "Pause" to hold your changes instead</div>',
      confirm() { $scope.submit_form(requirement_form, requirement) }
    }

    return confirmationModalFactory.openConfirmationModal(confirmation_object)

  }

  $scope.submit_form = (form: any, object: any, complete_edit_state: boolean = true) => {
    var valid = false;

    if (complete_edit_state) {
      valid = form.$valid
    } else if (!complete_edit_state) {
      valid = $scope.valid_for_checkpoint(form)
    }

    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will
    // be responsible for setting object to RESTING state

    var promiseChain = [
      'base_object_save_promise_alias',
      'tagging_manager_promise_alias',
      [
        'attachment_manager_promise_alias',
        'reference_manager_promise_alias',
        'note_manager_promise_alias'
      ],
      'state_manager_promise_alias'
    ]

    if (complete_edit_state) {
      promiseChain.push('version_control_completor_promise_alias')
    }

    var promise;
    $scope.processingStateService.performFunction(form, function () {
      if (valid) {
        promise = complyosServices.save_relay.start(
          $scope,
          object,
          form,
          'requirements',
          promiseChain,
          closeModalAndReturn
        )
        return promise
      } else {
        $scope.processingStateService.set(
          form,
          $scope.processingStateService.processing_states.RESTING
        )
        $scope.flashService.add_alert({
          name: 'requirements_failed_frontend_validation',
          dismissable: true,
          icon: 'fa-exclamation-triangle',
          class: 'alert-warning',
          strong: 'Failed Validation',
          message: 'Please review all tabs for validation issues'
        })
        
        promise = new Promise((resolve, reject) => {
          reject();
        });

        return form.failed_submission = true
      }
    })

    return promise
  }

  // COPIED
  $scope.transitionButtonClick = (transitionTo: any) => {
    if (transitionTo === 'Unpublished' || transitionTo === 'Published') {
      $scope.confirm_unpublish(transitionTo)
    }
  }

  // COPIED
  $scope.cancelButtonClick = (event: any) => {
    set_published()
  }

  var closeModalAndReturn = () => {
    // get the full object so that it merges nicely into the list
    if ($scope.requirement.is_edit_state) {
      $scope.requirement.id = $scope.requirement.edit_state_for_id
    }

    get_requirement().then(() => $uibModalInstance.close($scope.requirement))
  }

  $scope.closeModal = () => $uibModalInstance.dismiss('cancel')

  return initialize()
}])
