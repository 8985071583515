import 'angular';
import 'angular-animate'
import 'angular-cookies'
import 'angular-file-saver'
import 'angular-resource'
import 'angular-route'
import 'angular-sanitize'
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls'
import 'angular-ui-router'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import 'ng-file-upload'
// import 'restangular/dist/restangular'
import '../vendor/restangular/restangular'
import 'angular-markdown-directive'
import 'angular-marked'
import 'angular-chart.js'
import 'sha-1/sha1'
import 'isteven-angular-multiselect/isteven-multi-select'
import 'forty-datetime-directive/dist/forty-datetime'
import 'angularjs-dropdown-multiselect/src/angularjs-dropdown-multiselect'
import * as rrule from '../scripts/utilities/rrule/rrule'
import * as json2csv from '../scripts/utilities/json2csv/json2csv'
window["json2csv"] = json2csv
window["rrule"] = rrule
