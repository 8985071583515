import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { FlashService } from '../../services/flash_service.service';
import * as Restangular from '../../../vendor/restangular/restangular'
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';
import _ from 'lodash';
import { Utils } from '../../../scripts/services/ajs-utils';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationHelpers } from 'app/_ng/extensions/ValidationHelpers';

@Component({
  selector: 'ngRequirementModal', // njs: ng-requirement-modal, ng12: ngRequirementModal
  templateUrl: './requirement-modal.component.html',
  styleUrls: ['./requirement-modal.component.scss']
})
export class RequirementModal implements OnInit {

  @Input() requirement: any; 
  @Input() versionInfo: any;
  @Input() oldRequirement: any;
  @Input() uiSetting: string;
  @Output() dismissFn = new EventEmitter();
  @Output() closeFn = new EventEmitter<any>();


  // Injected Services
  private flashService: any
  private session: any;
  private processingStateService: any

  // Properties
  // public uiSetting : string;
  public disable_instructions = true
  public requirement_already_published = false
  public formSubmitAttempted = false

  // Litter
  public taggingsToStage = []
  public defaultNote = undefined
  // public original_published_state
  // public original_archived_state
  public options_list
  public options
  public preloaded_option
  public selected_option
  public freeText
  public intervals
  public current_selected_interval

  // TODO: REPLACE
  public requirement_form: any = {}

  constructor(
    @Inject('$httpParamSerializer') private $httpParamSerializer: any,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$uibModal') private $uibModal,
    @Inject('$window') private $window,
    @Inject('complyosServices') private complyosServices,
    private confirmationModalFactory: ConfirmationModalFactory,
    @Inject('ENV') private ENV,
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private Restangular: Restangular,
    private utils: Utils,
    private formBuilder: FormBuilder
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService
    this.session = $rootScope.session
  }

  // TODO: enable duplication
  ngOnInit(): void {
    // this.uiSetting = this.uiSetting;

    // where you are using the widget, pass in current state to labeText for the value you want to display
    // [label-text]="'editState'" will display Edit State on the label
    // set_submission_window_types()
    this.flashService.listen_for_alert(this.$scope)
    if (this.uiSetting !== 'create') {
      this.get_requirement().then(() => {
        if (this.uiSetting == 'duplicate') {
          this.duplicate_requirement()
        }
      })
    } else {
      this.requirement.published = false
      this.requirement.schedule_on = true
      this.requirement.start_date_needed = true
      this.requirement_already_published = false
    }

    if (this.uiSetting === 'create') { return this.get_intervals() }

    // this.initForm()

    
  }

  // ------------------------------------- v DISTRIBUTED IMPLEMENTATION v -------------------------------------
  // Properties
  public requirementFormGroup: FormGroup = this.formBuilder.group({});

  // initForm = () => {
  //   this.requirementFormGroup = this.formBuilder.group({
  //     // informationFormGroup: new FormGroup({}),
  //     // complianceFormGroup: new FormGroup({}),
  //     // intervalFormGroup: new FormGroup({}),
  //     // referencesAndTagsFormGroup: new FormGroup({}),
  //     // StatusFormGroup: new FormGroup({}),
  //     // NotesFormGroup: new FormGroup({}),
  //     // IntegrationsFormGroup: new FormGroup({}),

  //     //-----------------
  //     // generalInfo: this.formBuilder.group({
  //     //   fullName: ['Trang Le'],
  //     //   address: ['Ho Chi Minh City, Viet Nam'],
  //     //   dateOfBirth: ['1888-07-07'],
  //     //   phoneInfo: this.formBuilder.group({
  //     //     mobilePhone: ['+84981111111'],
  //     //     homePhone: ['+84982222222']
  //     //   })
  //     // }),
  //     // emergencyContacts: this.formBuilder.array([['+84983333333'], ['+84984444444']])
  //   });
  // }
  // ------------------------------------- ^ DISTRIBUTED IMPLEMENTATION ^ -------------------------------------

  updateInstructionsText = (event: any) => {
    this.requirement.instructions = event
  }

  get_requirement = () => this.Restangular.all('requirements').get(this.requirement.id).then((success: any) => {
      this.requirement = success.plain()
      if (this.requirement.interval_id) {
        this.requirement.interval_id = this.requirement.interval_id.toString()
      }

      this.requirement_already_published = angular.copy(success.published)
      this.get_intervals()

      // this.original_published_state = this.requirement.published
      // this.original_archived_state = !!this.requirement.state.archived
      if (this.requirement.edit_state_for_id) {
        this.get_original_requirement_states(this.requirement.edit_state_for_id)
      }
    }, (error: any) => this.flashService.process_error(error)
  )
  get_original_requirement_states = (id) => this.Restangular.all('requirements').get(id).then(success => {
      var original_requirement = success.plain()
      // this.original_published_state = original_requirement.published
      // this.original_archived_state = original_requirement.state.archived === true
    }, (error: any) => this.flashService.process_error(error)
  )


  duplicate_requirement = () => {
    if (this.uiSetting !== 'duplicate') {
      return;
    }

    // zero-out requirement_id & uiSetting
    this.requirement.id = null;
    delete this.requirement.id

    // set 
    this.defaultNote = `This requirement was copied from "${this.requirement.name}"`
    this.requirement.name = `[DUPLICATED] ${this.requirement.name}`;
    // this.requirementFormGroup.get('name').setValue(`[DUPLICATED] ${this.requirement.name}`)

    // TODO: make sure these transfer
    // detach unrelated objects
    this.requirement.notes = []
    this.requirement.author_notes = []
    this.requirement.attachments = []
    this.taggingsToStage = this.requirement.taggings
    this.requirement.taggings = []
    this.requirement.reference_objects.forEach(ro => { ro.pending_action = 'create' })
    this.requirement.published = false
    delete this.requirement.state
    // this.requirement = angular.copy(this.requirement)

    this.flashService.add_alert({
      name: 'duplicate_requirement_modal_alert',
      dismissable: false,
      icon: 'fa-copy',
      class: 'alert-info',
      strong: 'Duplication',
      message: 'You are about to duplicate a requirement'
    })

    this.uiSetting = 'create'
  }

  get_intervals = () => {
    //let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        // text_search: args.apiSearchInputString
      }
    }
    return this.Restangular.all('intervals').getList(params).then((success: any) => {
      this.intervals = success.plain()
      if (this.requirement.interval !== undefined) {
        return this.get_interval_description()
      }
    })
  }

  submission_window_name = (submission_window_type: string) => {
    let period_name = 'date'
    if (submission_window_type !== null
      && submission_window_type !== undefined
      && submission_window_type != "day") {
      // there us another period specified ie: week, month, or year, then use that text
      period_name = submission_window_type
    }

    return period_name;
  }

  // COPIED
  sync_state_options = (field: any) => {
    let newValue = this.requirement[field]

    // if schedule off was just turned on, turn all other options off
    //    and the reverse
    if (field === 'no_schedule' && newValue === true) {
      this.requirement.schedule_on = false
      this.requirement.start_date_needed = false
      this.requirement.managed_by_paired_application = false
    } else if (field !== 'no_schedule' && newValue === true) {
      this.requirement.no_schedule = false
    }

    // if schedule off was just turned on, turn all other options off
    if (field === 'managed_by_paired_application' && newValue === true) {
      this.requirement.schedule_on = true
    }

    // if schedule was turned on, make start_date_needed match (true and false)
    if (this.requirement.schedule_on) {
      this.requirement.start_date_needed = true
    } else {
      this.requirement.start_date_needed = false
    }

    // if schedule_on is not checked at this stage, managed_by_paired_application also should not be on
    if (this.requirement.schedule_on === false) {
      this.requirement.managed_by_paired_application = false
    }
  }

  confirm_unpublish = (transitionTo: string) => {
    if (this.requirement.published === true) {
      const confirmation_object = {
        severity: 'danger',
        title: 'Unpublish Requirement',
        button_icon: 'fa-cloud-download',
        button_text: 'Unpublish',
        message: '<p>Are you sure you want to unpublish this? Unpublishing may have adverse side effects. The preferred flow would be to archive it.</p>',
        confirm() { this.set_published() }
      }

      return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
    } else {
      this.set_published()
    }
  }

  set_published = () => {
    return this.requirement.published = !this.requirement.published
  }

  get_interval_description = () => {
    const selected_interval = _.find(this.intervals, (i: any) => i.id == this.requirement.interval_id)
    return this.current_selected_interval = selected_interval
  }


  // // TODO: move to interval editor
  // future_scheduling_option_clicked = (clickedOption: any) => {
  //   let schedulingOptions = ['rolling_start_date', 'user_prompted_date_generation']
  //   let updatedValue = this.requirement[clickedOption]
  //   if (updatedValue === true) {
  //     let nonSelectedOptions = schedulingOptions.filter(opt => opt !== clickedOption)
  //     nonSelectedOptions.forEach(opt => this.requirement[opt] = false)
  //   }
  // }

  launch_form_tool = () => {
    const params = {
      action: 'authorSchema'
    }
    const serilalized_params = this.$httpParamSerializer(params)
    const redirect_url = this.ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
    return this.$window.location = redirect_url
  }

  viewCurrentVersion = () => {
    this.openRequirementModal(this.requirement, 'read')
  }

  openRequirementModal = (requirement_object: any, uiSetting: any) => {
    let that = this
    this.Restangular.one(`requirements/${requirement_object.edit_state_for_id}`).get().then((response: any) => { // success
      var requirement_object = response.plain()
      this.$uibModal.open({
        // templateUrl: 'views/modals/requirement_modal.html',
        // controller: 'requirementModalController',
        templateUrl: 'views/modals/ajs-requirement-modal-adapter.html',
        controller: 'ajsRequirementModalAdapter',
        size: 'lg',
        resolve: {
          modalOptions() {
            return {
              object: angular.copy(requirement_object),
              uiSetting
            }
          }
        }
      })
    }, (error: any) => {
      return that.utils.log(error, 'error', false)
    })
  }

  // SAVE
  public pause_form_submit = (requirement_form, requirement) => {
    var promise = this.submit_form(requirement_form, requirement, false)
  }
  public validate_form_pause_submit = () => {
    // when PAUSING, there are almost no requirements- you should be able to save the form in whatever state it is in
    // EXCEPT: a name is required
    var errors = []

    var nameField = this.requirementFormGroup.get('name')
    if (!nameField.valid) {
      errors.push({
        control_name: 'name',
        error_name: 'required',
        error_value: 'name field is required'
      });
    }

    return errors
  }
  public save_form_submit = (requirement_form, requirement) => {
    if (this.uiSetting === 'create') {
      return this.submit_form(requirement_form, requirement)
    }
    
    const confirmation_object = {
      severity: 'warning',
      title: 'Save',
      button_icon: 'fa-check',
      button_text: 'Save',
      message: '<div><b>Caution:</b> Saving will apply these changes to all configured requirements that use this requirement-template</div><br><div>If you are not ready to apply these changes to configured requirements, use "Pause" to hold your changes instead</div>',
      confirm: () => this.submit_form(requirement_form, requirement)
    }

    return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
  }

  submit_form = (form: any, object: any, complete_edit_state: boolean = true) => {
    this.formSubmitAttempted = true
    var valid = false;
    var errs = []

    if (complete_edit_state) {
      ValidationHelpers.updateTreeValidity(this.requirementFormGroup)
      valid = this.requirementFormGroup.valid
      errs = ValidationHelpers.getFormValidationErrors(this.requirementFormGroup.controls)
    } else if (!complete_edit_state) {
      errs = this.validate_form_pause_submit()
      valid = errs.length < 1
    }

    var updated_requirement = _.merge(this.requirement, this.requirementFormGroup.value)

    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will
    // be responsible for setting object to RESTING state

    var promiseChain = [
      'base_object_save_promise_alias',
      'tagging_manager_promise_alias',
      [
        'attachment_manager_promise_alias',
        'reference_manager_promise_alias',
        'note_manager_promise_alias'
      ],
      'state_manager_promise_alias'
    ]

    if (complete_edit_state) {
      promiseChain.push('version_control_completor_promise_alias')
    }

    var promise
    this.processingStateService.performFunction(form, () => {
      if (valid) {
        // VALID -> SAVE
        promise = this.complyosServices.save_relay.start(
          this.$scope,
          updated_requirement,
          form,
          'requirements',
          promiseChain,
          this.closeModalAndReturn
        )
        return promise

      } else {
        // NOT-VALID, BREAK
        this.processingStateService.set(form, this.processingStateService.processing_states.RESTING)
        this.flashService.add_alert({
          name: 'requirements_failed_frontend_validation',
          dismissable: true,
          icon: 'fa-exclamation-triangle',
          class: 'alert-warning',
          strong: 'Failed Validation',
          message: 'Please review all tabs for validation issues'
        })

          promise = new Promise((resolve, reject) => {
            reject();
          });

          return form.failed_submission = true
        }
      }
    )

    return promise
  }

  discard_edit_requirement = (requirement: any) => {
    const confirmation_object = {
      severity: 'danger',
      title: 'Discard',
      button_icon: 'fa-trash',
      button_text: 'Discard',
      message: '<div><b>Caution:</b> This will delete the Edit State Version.  This version will no longer be available.',
      confirm: () => this.discard_edit_state(requirement)
    }

    return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
  }

  discard_edit_state = (requirement: any) => {
    this.Restangular.all(`requirements/${requirement.id}/discard_edit_state`).remove().then((response: any) => {
      if(this.oldRequirement && this.oldRequirement.edit_state_id){
        this.oldRequirement.edit_state_id = null
        this.modalClose(this.oldRequirement)
      }
      else{
        this.$rootScope.$broadcast('requirementEditState', null)
        this.closeModalAndReturn()
      }
    }, (error: any) => {
      return this.utils.log(error, 'error', false)
    })
  }

  transitionButtonClick = (transitionTo: any) => {
    if (transitionTo === 'Unpublished' || transitionTo === 'Published') {
      this.confirm_unpublish(transitionTo)
    }
  }

  cancelButtonClick = (event: any) => {
    this.set_published()
  }

  closeModalAndReturn = () => {
    // get the full object so that it merges nicely into the list
    if (this.requirement.is_edit_state) {
      this.requirement.id = this.requirement.edit_state_for_id
    }

    this.get_requirement().then(() => this.modalClose(this.requirement))
  }

  modalDismiss = () => {
    this.dismissFn.emit()
  }
  modalClose = (response) => {
    this.closeFn.emit(response)
  }

}

angular
  .module('complyosClient')
  .directive('ngRequirementModal', downgradeComponent({ component: RequirementModal }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- TO PORT...

// complyosClient.controller('', [
// // 'taOptions',
//  function (
//   // taOptions: any,
// ) {
//   const initialize = function () {

//     // // where you are using the widget, pass in current state to labeText for the value you want to display
//     // // [label-text]="'editState'" will display Edit State on the label
//     // set_schedule_state_options()
//     // set_annotation_states()
//     // // set_submission_window_types()

    
//     // $scope.flashService.listen_for_alert($scope)
//     // if ($scope.ui_setting !== 'create') {
//     //   get_requirement().then(() => {
//     //     if ($scope.ui_setting == 'duplicate') {
//     //       duplicate_requirement()
//     //     }
//     //   })
//     // } else {
//     //   $scope.requirement.published = false
//     //   $scope.requirement.schedule_on = true
//     //   $scope.requirement.start_date_needed = true
//     //   $scope.requirement_already_published = false
//     // }

//     // get_list_of_options()

//     // if ($scope.ui_setting === 'create') { return get_intervals() }
//   }

  

//   $scope.closeModal = () => $uibModalInstance.dismiss('cancel')

//   return initialize()
// }])

//- --------------------------------------------------------------------
//- FOR REFERENCE

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementModalController', ['$httpParamSerializer', '$scope', '$uibModal', '$uibModalInstance', '$window', 'complyosServices', 'ENV', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'taOptions', 'confirmationModalFactory', 'storeService', '$rootScope', function (
//   $httpParamSerializer: any,
//   $scope: any,
//   $uibModal: any,
//   $uibModalInstance: any,
//   $window: any,
//   complyosServices: any,
//   ENV: any,
//   flashService: any,
//   fortyCore: any,
//   modalOptions: any,
//   Restangular: any,
//   taOptions: any,
//   confirmationModalFactory: any,
//   storeService: any,
//   $rootScope: any
// ) {
//   const initialize = function () {
//     $scope.requirement = modalOptions.object

//     $scope.flashService = angular.copy(flashService)
//     $scope.fortyCore = fortyCore
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.ui_setting = modalOptions.ui_setting
//     $scope.disable_instructions = true

//     $scope.compliance_methods = [
//       'Form',
//       'Form/Template',
//       'Template',
//       'Local Documentation',
//       'Observation',
//       'Engineering Central'
//     ]

//     // where you are using the widget, pass in current state to labeText for the value you want to display
//     // [label-text]="'editState'" will display Edit State on the label
//     $scope.stateConfigs = {
//       published_state: {
//         true: { currentState: 'Published', transitionState: 'Unpublished', icon: 'fa fa-check', transitionIcon: 'fa fa-minus', function: 'transitionFunction', currentStateColor: '#18BC9C', transitionStateColor: '#F39C12' },
//         false: { currentState: 'Unpublished', transitionState: 'Published', icon: 'fa fa-minus', transitionIcon: 'fa fa-check', function: 'transitionFunction', currentStateColor: '#F39C12', transitionStateColor: '#18BC9C' },
//       },
//     }
//     set_schedule_state_options()
//     set_annotation_states()
//     // set_submission_window_types()

//     taOptions.defaultTagAttributes.a.target = '_blank'
//     taOptions.toolbar = [
//       [
//         // 'h1'
//         // 'h2'
//         // 'h3'
//         // 'h4'
//         // 'h5'
//         // 'h6'
//         // 'p'
//         // 'pre'
//         // 'quote'
//       ],
//       [
//         'bold',
//         'italics',
//         'underline',
//         // 'strikeThrough'
//         'ul',
//         'ol'
//         // 'redo'
//         // 'undo'
//         // 'clear'
//       ],
//       // ,[
//       //   'justifyLeft'
//       //   'justifyCenter'
//       //   'justifyRight'
//       //   'indent'
//       //   'outdent'
//       // ]
//       [
//         'html',
//         // 'insertImage'
//         'insertLink'
//         // 'insertVideo'
//         // 'wordcount'
//         // 'charcount'
//       ]
//     ]

//     $scope.flashService.listen_for_alert($scope)
//     if ($scope.ui_setting !== 'create') {
//       get_requirement().then(() => {
//         if ($scope.ui_setting == 'duplicate') {
//           duplicate_requirement()
//         }
//       })
//     } else {
//       $scope.requirement.published = false
//       $scope.requirement.schedule_on = true
//       $scope.requirement.start_date_needed = true
//       $scope.requirement_already_published = false
//     }

//     get_list_of_options()

//     if ($scope.ui_setting === 'create') { return get_intervals() }
//   }

//   var set_schedule_state_options = () => {
//     let isRegularScheduleDisabled = $scope.ui_setting === 'update' && ($scope.requirement.can_disable_schedule_state === false || $scope.requirement.can_disable_managed_by_paired_application === false)
//     let isManageByPairedApplicationDisabled = $scope.ui_setting === 'read' || ($scope.requirement.managed_by_paired_application && $scope.requirement.can_disable_managed_by_paired_application === false)
//     $scope.schedule_state_options = [
//       {
//         value: 'schedule_on',
//         display: 'Configure with start date',
//         disabled: isRegularScheduleDisabled,
//         helpText: 'Complyos will generate tasks based on interval and schedule start date'
//       },
//       {
//         value: 'start_date_needed',
//         display: 'Configure without start date',
//         disabled: true,
//         helpText: 'Schedule does not start until user sets "start date"'
//       },
//       {
//         value: 'no_schedule',
//         display: 'Schedule Off',
//         disabled: isRegularScheduleDisabled,
//         helpText: 'Complyos will not generate tasks'
//       },
//       {
//         value: 'managed_by_paired_application',
//         display: 'Managed by Paired Application',
//         disabled: isManageByPairedApplicationDisabled,
//         helpText: 'Third party app may generate tasks'
//       }
//     ]
//   }

//   let set_annotation_states = () => {
//     $scope.annotation_states = [
//       { value: 'annotation_required', display: 'Annotation Required' },
//       { value: 'annotation_optional', display: 'Annotation Optional' },
//       { value: 'annotation_disabled', display: 'Annotation Disabled' }
//     ]
//   }

//   // // #OPTION_B
//   // let set_submission_window_types = () => {
//   //   $scope.submission_window_types = [
//   //     { value: 'day', display: 'Event Day Only' },
//   //     { value: 'month', display: 'Whole Month' }
//   //   ]
//   // }

//   $scope.updateInstructionsText = (event: any) => {
//     $scope.requirement.instructions = event
//   }

//   var get_requirement = () => Restangular.all('requirements').get($scope.requirement.id).then(function (success: any) {
//     $scope.requirement = success
//     if ($scope.requirement.interval_id) {
//       $scope.requirement.interval_id = $scope.requirement.interval_id.toString()
//     }

//     $scope.requirement_already_published = angular.copy(success.published)
//     set_schedule_state_options()
//     get_intervals()
//     get_list_of_options()

//     $scope.original_published_state = $scope.requirement.published
//     $scope.original_archived_state = !!$scope.requirement.state.archived
//     if ($scope.requirement.edit_state_for_id) {
//       get_original_requirement_states($scope.requirement.edit_state_for_id)
//     }
//   }, (error: any) => $scope.flashService.process_error(error)
//   )
//   var get_original_requirement_states = (id) => Restangular.all('requirements').get(id).then(success => {
//     var original_requirement = success.plain()
//     $scope.original_published_state = original_requirement.published
//     $scope.original_archived_state = original_requirement.state.archived === true
//   }, (error: any) => $scope.flashService.process_error(error)
//   )


//   var duplicate_requirement = () => {
//     if ($scope.ui_setting !== 'duplicate') {
//       return;
//     }

//     // zero-out requirement_id & ui_setting
//     delete $scope.requirement.id
//     $scope.ui_setting = 'create'

//     // set 
//     $scope.default_note = `This requirement was copied from "${$scope.requirement.name}"`
//     $scope.requirement.name = `[DUPLICATED] ${$scope.requirement.name}`;

//     // detach unrelated objects
//     $scope.requirement.notes = []
//     $scope.requirement.author_notes = []
//     $scope.requirement.attachments = []
//     $scope.taggingsToStage = $scope.requirement.taggings
//     $scope.requirement.taggings = []
//     $scope.requirement.reference_objects.forEach(ro => { ro.pending_action = 'create' })
//     $scope.requirement.published = false
//     delete $scope.requirement.state

//     $scope.flashService.add_alert({
//       name: 'duplicate_requirement_modal_alert',
//       dismissable: false,
//       icon: 'fa-copy',
//       class: 'alert-info',
//       strong: 'Duplication',
//       message: 'You are about to duplicate a requirement'
//     })
//   }

//   var get_list_of_options = () => {
//     const params = 'requirement-date-instructions-dropdown'
//     return Restangular.all('options_lists/get_list_of_options').get(params).then(function (success: any) {
//       $scope.options_list = success.plain()
//       $scope.options = $scope.options_list.options_values
//       set_loaded_instructions()
//     })
//   }

//   var get_intervals = () => {
//     //let loadedCallback = args.loadedCallback
//     const params = {
//       param_data: {
//         // text_search: args.apiSearchInputString
//       }
//     }
//     return Restangular.all('intervals').getList(params).then(function (success: any) {
//       $scope.intervals = success.plain()
//       if ($scope.requirement.interval !== undefined) {
//         return $scope.get_interval_description()
//       }
//     })
//   }

//   $scope.submission_window_name = (submission_window_type: string) => {
//     let period_name = 'date'
//     if (submission_window_type !== null
//       && submission_window_type !== undefined
//       && submission_window_type != "day") {
//       // there us another period specified ie: week, month, or year, then use that text
//       period_name = submission_window_type
//     }

//     return period_name;
//   }

//   $scope.sync_state_options = (field: any) => {
//     let newValue = $scope.requirement[field]

//     // if schedule off was just turned on, turn all other options off
//     //    and the reverse
//     if (field === 'no_schedule' && newValue === true) {
//       $scope.requirement.schedule_on = false
//       $scope.requirement.start_date_needed = false
//       $scope.requirement.managed_by_paired_application = false
//     } else if (field !== 'no_schedule' && newValue === true) {
//       $scope.requirement.no_schedule = false
//     }

//     // if schedule off was just turned on, turn all other options off
//     if (field === 'managed_by_paired_application' && newValue === true) {
//       $scope.requirement.schedule_on = true
//     }

//     // if schedule was turned on, make start_date_needed match (true and false)
//     if ($scope.requirement.schedule_on) {
//       $scope.requirement.start_date_needed = true
//     } else {
//       $scope.requirement.start_date_needed = false
//     }

//     // if schedule_on is not checked at this stage, managed_by_paired_application also should not be on
//     if ($scope.requirement.schedule_on === false) {
//       $scope.requirement.managed_by_paired_application = false
//     }
//   }



//   $scope.confirm_unpublish = function (transitionTo: string) {
//     if ($scope.requirement.published === true) {
//       const confirmation_object = {
//         severity: 'danger',
//         title: 'Unpublish Requirement',
//         button_icon: 'fa-cloud-download',
//         button_text: 'Unpublish',
//         message: '<p>Are you sure you want to unpublish this? Unpublishing may have adverse side effects. The preferred flow would be to archive it.</p>',
//         confirm() { set_published() }
//       }

//       return confirmationModalFactory.openConfirmationModal(confirmation_object)
//     } else {
//       set_published()
//     }
//   }

//   var set_published = function () {
//     return $scope.requirement.published = !$scope.requirement.published
//   }

//   var set_loaded_instructions = function () {
//     $scope.preloaded_option = _.find($scope.options, (i: any) => i.display_name == $scope.requirement.schedule_start_date_instructions)
//     if ($scope.preloaded_option === undefined) {
//       $scope.freeText = _.find($scope.options, (i: any) => i.value == 'Free Text')
//       $scope.requirement.option_id = $scope.freeText.id.toString()
//       $scope.disable_instructions = false
//     } else {
//       $scope.requirement.option_id = $scope.preloaded_option.id.toString()
//     }
//   }

//   $scope.get_interval_description = function () {
//     const selected_interval = _.find($scope.intervals, (i: any) => i.id == $scope.requirement.interval_id)
//     return $scope.current_selected_interval = selected_interval
//   }

//   $scope.set_start_date_instructions = function (onLoad: boolean) {
//     // load the text into the text area
//     $scope.selected_option = _.find($scope.options, (i: any) => i.id == $scope.requirement.option_id)
//     $scope.requirement.schedule_start_date_instructions = $scope.selected_option.display_name
//     $scope.disable_instructions = $scope.selected_option.display_name != 'Free Text' ? true : false
//   }

//   $scope.future_scheduling_option_clicked = function (clickedOption: any) {
//     let schedulingOptions = ['rolling_start_date', 'user_prompted_date_generation']
//     let updatedValue = $scope.requirement[clickedOption]
//     if (updatedValue === true) {
//       let nonSelectedOptions = schedulingOptions.filter(opt => opt !== clickedOption)
//       nonSelectedOptions.forEach(opt => $scope.requirement[opt] = false)
//     }
//   }

//   $scope.launch_form_tool = function () {
//     const params = {
//       action: 'authorSchema'
//     }
//     const serilalized_params = $httpParamSerializer(params)
//     const redirect_url = ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
//     return $window.location = redirect_url
//   }

//   $scope.viewCurrentVersion = () => {
//     $scope.openRequirementModal($scope.requirement, 'read')
//   }

//   $scope.openRequirementModal = function (requirement_object: any, ui_setting: any) {
//     let that = this
//     Restangular.one(`requirements/${requirement_object.edit_state_for_id}`).get().then(function (response: any) { // success
//       var requirement_object = response.plain()
//       $uibModal.open({
//         templateUrl: 'views/modals/requirement_modal.html',
//         controller: 'requirementModalController',
//         size: 'lg',
//         resolve: {
//           modalOptions() {
//             return {
//               object: angular.copy(requirement_object),
//               ui_setting
//             }
//           }
//         }
//       })
//     }, function (error: any) {
//       return that.utils.log(error, 'error', false)
//     })
//   }

//   $scope.discard_edit_state = function () {
//     let that = this
//     Restangular.one(`requirements/${$scope.requirement.id}/discard_edit_state`).get().then(function (response: any) { // success
//       // var requirement_object = response.plain()
//       closeModalAndReturn();
//     }, function (error: any) {
//       return that.utils.log(error, 'error', false)
//     })
//   }

//   $scope.valid_for_checkpoint = (form: any, object: any) => {
//     var valid = true;

//     if (!form.field_name.$valid)
//       valid = false

//     return valid;
//   }

//   $scope.checkpoint_form_submit = ($event, form: any, object: any) => {
//     var promise = $scope.submit_form(form, object, false)
//   }

//   $scope.confirm_submit_form = (requirement_form, requirement) => {
//     if ($scope.ui_setting === 'create') {
//       return $scope.submit_form(requirement_form, requirement)
//     }
//     const confirmation_object = {
//       severity: 'warning',
//       title: 'Save',
//       button_icon: 'fa-check',
//       button_text: 'Save',
//       message: '<div><b>Caution:</b> Saving will apply these changes to all configured requirements that use this requirement-template</div><br><div>If you are not ready to apply these changes to configured requirements, use "Pause" to hold your changes instead</div>',
//       confirm() { $scope.submit_form(requirement_form, requirement) }
//     }

//     return confirmationModalFactory.openConfirmationModal(confirmation_object)

//   }

//   $scope.submit_form = (form: any, object: any, complete_edit_state: boolean = true) => {
//     var valid = false;

//     if (complete_edit_state) {
//       valid = form.$valid
//     } else if (!complete_edit_state) {
//       valid = $scope.valid_for_checkpoint(form)
//     }

//     // State service will set the form to a state of PENDING
//     // After action is performed or cancelled, that action will
//     // be responsible for setting object to RESTING state

//     var promiseChain = [
//       'base_object_save_promise_alias',
//       'tagging_manager_promise_alias',
//       [
//         'attachment_manager_promise_alias',
//         'reference_manager_promise_alias',
//         'note_manager_promise_alias'
//       ],
//       'state_manager_promise_alias'
//     ]

//     if (complete_edit_state) {
//       promiseChain.push('version_control_completor_promise_alias')
//     }

//     var promise;
//     $scope.processingStateService.performFunction(form, function () {
//       if (valid) {
//         promise = complyosServices.save_relay.start(
//           $scope,
//           object,
//           form,
//           'requirements',
//           promiseChain,
//           closeModalAndReturn
//         )
//         return promise
//       } else {
//         $scope.processingStateService.set(
//           form,
//           $scope.processingStateService.processing_states.RESTING
//         )
//         $scope.flashService.add_alert({
//           name: 'requirements_failed_frontend_validation',
//           dismissable: true,
//           icon: 'fa-exclamation-triangle',
//           class: 'alert-warning',
//           strong: 'Failed Validation',
//           message: 'Please review all tabs for validation issues'
//         })

//         promise = new Promise((resolve, reject) => {
//           reject();
//         });

//         return form.failed_submission = true
//       }
//     })

//     return promise
//   }


//   $scope.transitionButtonClick = (transitionTo: any) => {
//     if (transitionTo === 'Unpublished' || transitionTo === 'Published') {
//       $scope.confirm_unpublish(transitionTo)
//     }
//   }

//   $scope.cancelButtonClick = (event: any) => {
//     set_published()
//   }

//   var closeModalAndReturn = () => {
//     // get the full object so that it merges nicely into the list
//     if ($scope.requirement.is_edit_state) {
//       $scope.requirement.id = $scope.requirement.edit_state_for_id
//     }

//     get_requirement().then(() => $uibModalInstance.close($scope.requirement))
//   }

//   $scope.closeModal = () => $uibModalInstance.dismiss('cancel')

//   return initialize()
// }])
