import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { DomSanitizer } from "@angular/platform-browser";
import DOMPurify from 'dompurify';

@Component({
  selector: 'ngFormSchemaPreview',
  templateUrl: './form-schema-preview-modal.component.html'
})

export class FormSchemaPreviewModal implements OnInit {
  allFields: Array<object> = [];
  @Output() dismissFn = new EventEmitter();
  
  constructor(@Inject('$scope') private $scope: any, private sanitizer: DomSanitizer){
  }

  ngOnInit(): void {
    this.traverse_data(this.$scope.requirement.body.fields);
  }

  traverse_data(fields:any):void {
    fields.forEach( (field:any) => {
      if(field.fields === undefined){
        field = this.checkFieldStamp(field)
        this.allFields.push(field);
      }
      else if(field.fields){
        this.traverse_data(field.fields);
      }
    });
  }

  checkFieldStamp(field:any):object {
    if (field.attributes.value === '' && field.attributes.valueFunction && field.attributes.valueFunction.fnParam) {
      switch (field.attributes.valueFunction.fnParam) {
        case 'currentDate':
          field.attributes.value = new Date().toLocaleDateString('en-US')
          break
      }
    }
    return field;
  }

  santizeHtmlRender(content:any):any {
    const sanitizedContent = DOMPurify.sanitize(content)
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent)
  }

  dismissModal = () => {
    this.dismissFn.emit()
  }
}

angular
  .module('complyosClient')
  .directive('ngFormSchemaPreview', downgradeComponent({ component: FormSchemaPreviewModal }) as angular.IDirectiveFactory)