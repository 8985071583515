import { Component, Directive, ElementRef, Injector, Input } from "@angular/core"
import { UpgradeComponent } from "@angular/upgrade/static"

@Directive({ selector: 'ajs-dashboard-filter' })
export class ajsDashboardFilterWrapper extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() disableFiltersConfig: Array<string> = [];
  @Input() allowLocking: any;
  @Input() injectedDefaultFilters: any;
  @Input() pageName: any;
  @Input() hideClearFiltersButton: boolean = false;
  @Input() defaultFilterConfig?: any;
  @Input() showFormRequirements?: boolean
  @Input() disableCaching: boolean = false;
  @Input() broadcastIdentifier: string = 'filterChange-dashboard';
  @Input() debounceTime?: number;
  @Input() searchHitsPlaceholder?: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('dashboardFilter', elementRef, injector);
  }
}