/* eslint-disable
    camelcase,
    no-control-regex,
    no-return-assign,
    no-undef,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('attachmentManager', [
  '$http',
  '$httpParamSerializer',
  '$rootScope',
  '$timeout',
  '$uibModal',
  '$window',
  'confirmationModalFactory',
  'dateService',
  'ENV',
  'fileService',
  'flashService',
  'fortyCore',
  'helpService',
  'loadingStateService',
  'objectManager',
  'Restangular',
  'Upload',
  'utils',
  function (
    $http: any,
    $httpParamSerializer: any,
    $rootScope: any,
    $timeout: any,
    $uibModal: any,
    $window: any,
    confirmationModalFactory: any,
    dateService: any,
    ENV: any,
    fileService: any,
    flashService: any,
    fortyCore: any,
    helpService: any,
    loadingStateService: any,
    objectManager: any,
    Restangular: any,
    Upload: any,
    utils: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/attachment_manager.html',
      require: 'ngModel',
      scope: {
        allowCreate: '=?',
        allowDestroy: '=?',
        allowRead: '=?',
        allowScan: '=?',
        approvedFileExtensions: '@',
        record: '=',
        recordId: '=',
        recordType: '@',
        defaultFileName: '@',
        pathClass: '@',
        showError: '=?',
        uiSetting: '@',
        completed: '=?',
        emailUploadAddress: '@',
        allowEmail: '=?'
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.processingStateService = fortyCore.processingStateService
        
        $scope.loadingStateService = angular.copy(loadingStateService)
        $scope.detectionServices = fortyCore.detectionService
        $scope.helpService = helpService

        $scope.am = {

          /* INIT */

          initialize () {
            // set the defaults
            this.ng_model_value = $ngModel.$modelValue
            this.path_class = $scope.pathClass
            this.record = $scope.record
            this.record_id = $scope.recordId
            this.record_type = $scope.recordType
            this.email_upload_address = $scope.emailUploadAddress
            $scope.allowCreate = $scope.allowCreate !== undefined ? $scope.allowCreate : false
            $scope.allowDestroy = $scope.allowDestroy !== undefined ? $scope.allowDestroy : false
            $scope.allowRead = $scope.allowRead !== undefined ? $scope.allowRead : true
            $scope.allowScan = $scope.allowScan !== undefined ? $scope.allowScan : true
            $scope.allowEmail = $scope.allowEmail !== undefined ? $scope.allowEmail : false
            $scope.showError = $scope.showError !== undefined ? $scope.showError : false

            this.watch_ng_model() // this updates the attachments array in the view
            this.add_validators()
            this.listen_for_form_save_event()

            // if the parent model has an id and no attachments were 'bound in'
            // we need to make an attempt to get them
            if (($ngModel.$modelValue === undefined || $ngModel.$modelValue.length === 0) && this.record_id) {
              this.get_attachments()
            }

            if ((this.record_type === 'Entry') && (this.record !== undefined)) {
              this.get_binder()
            }

            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          get_attachments () {
            return Restangular.all(`${this.path_class}`).get(`${this.record_id}/attachments`).then((success_response: any) => $ngModel.$modelValue = success_response, (error_response: any) => flashService.emit_alert($scope, flashService.default_alert_by_code(error_response.status))).finally(function () {
            });
          },

          get_binder () {
            return Restangular.all('binders').get(this.record.binder.id).then((
              // success
              response: any
            ) => $scope.binder = response

            , (error: any) => utils.log(error));
          },

          /* STATE */

          state: $scope.processingStateService.processing_states.RESTING,

          pending_transactions: 0,
          failed_transactions: 0,

          increment_pending () {
            $scope.processingStateService.set(this, $scope.processingStateService.processing_states.PENDING)
            return this.pending_transactions++
          },

          decrement_pending () {
            this.pending_transactions--
            if (this.pending_transactions === 0) {
              $scope.processingStateService.set(this, $scope.processingStateService.processing_states.RESTING)
              return this.emit_completion_event()
            }
          },

          increment_failed () {
            return this.failed_transactions++
          },

          /* STAGING & CREATION */

          staged_files: [],

          stage_files (files: any) {
            if (files && files.length) {
              let i = 0
              while (i < files.length) {
                this.stage_file(files[i])
                i++
              }
            }
          },

          stage_file (file: any) {
            objectManager.array_action(this.staged_files, file, 'merge')
            return $ngModel.$validate()
          },

          unstage_file (file: any) {
            objectManager.array_action(this.staged_files, file, 'remove')
            return $ngModel.$validate()
          },

          upload_staged_files () {
            return Array.from(this.staged_files).map((file) =>
              this.create_attachment(file))
          },

          create_attachment (file: any) {
            this.increment_pending()
            return Upload.upload({
              url: `${ENV.COMPLYOS_API_URL}/attachments`,
              data: {
                attachment: {
                  record_id: this.record_id,
                  record_type: this.record_type,
                  document: file
                }
              },
              method: 'POST',
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((success_response: any) => {
              if (success_response.status === 204) {
                // uploading empty files is technically valid as far as http codes are concerned, but we want to treat it like an error
                const alert = {
                  name: `upload_error_${file.name}`,
                  dismissable: true,
                  class: 'alert-warning',
                  icon: 'fa-exclamation-triangle',
                  strong: 'Error:',
                  message: `There was an error uploading ${file.name}. Filesize is 0KB. Verify contents of file and try again.`
                }
                flashService.emit_alert($scope, alert)
                return this.increment_failed()
              } else {
                objectManager.array_action($ngModel.$modelValue, success_response.data, 'merge')
                return this.unstage_file(file)
              }
            }
            , (error_response: any) => {
              const alert = {
                name: `upload_error_${file.name}`,
                dismissable: true,
                class: 'alert-warning',
                icon: 'fa-exclamation-triangle',
                strong: 'Error:',
                message: `There was an error uploading ${file.name}. ${flashService.default_alert_by_code(error_response.status).message}`
              }
              flashService.emit_alert($scope, alert)
              return this.increment_failed()
            }).finally(() => {
              return this.decrement_pending()
            });
          },

          /* DOCUMENT SOURCES */

          // EMAIL TO MAILBOX
          // FORM
          // PDF UPLOAD
          // SCAN
          // VENDOR

          /* EMAIL TO API */

          can_copy_email_upload_address () {
            return $scope.allowCreate &&
            $scope.allowEmail &&
            !$scope.detectionServices.isMobileAmalgam()
          },

          copy_email_upload_address () {
            fortyCore.text.copy($scope.emailUploadAddress)
            return this.document_source_help_text = 'The Email Upload Address has been copied to your clipboard. Use the Email Upload Address to email completed documentation directly to this entry in Complyos.'
          },

          /* FORM */

          can_launch_form_tool () {
            return $scope.allowCreate &&
            (this.record_type === 'Entry') &&
            this.record.binder.requirement.has_form
          },

          launch_form_tool () {
            console.log(this.record)
            let recordOrgId
            let activeOrg = $rootScope.session.data.activeOrganization
            if (this.record && this.record.binder && this.record.binder.organization_id) {
              recordOrgId = this.record.binder.organization_id
            } else if (activeOrg && activeOrg.id) {
              recordOrgId = $rootScope.session.data.activeOrganization.id
            } else {
              alert('no organization set for this record.')
            }
            const params = {
              action: 'completeForm',
              id: this.record.id,
              return_url: `${$window.location.href}?organization_id=${recordOrgId}`
            }
            const serilalized_params = $httpParamSerializer(params)
            const redirect_url = ENV.COMPLYOS_FORMS_URL + '/' + '?' + serilalized_params
            return $window.location = redirect_url
          },

          /* PDF UPLOAD */

          can_pdf_upload () {
            return $scope.allowCreate
          },

          /* SCAN */

          can_pdf_capture () {
            return $scope.allowCreate &&
            $scope.allowScan &&
            !$scope.detectionServices.isMobileAmalgam()
          },

          openPdfCaptureModal () {
            let file_name: any
            if ($scope.defaultFileName) {
              file_name = $scope.defaultFileName.trim().toLowerCase().replace(/\s+/g, '_')
            } else {
              file_name = ''
            }

            const modalInstance = $uibModal.open({
              templateUrl: 'views/modals/pdf_capture_modal.html',
              controller: 'pdfCaptureModalController',
              size: 'xl',
              animation: false, // dont animate opening
              backdrop: 'static', // dont allow user to click backdrop to close
              keyboard: false, // dont allow user to escape to close
              resolve: {
                modalOptions () {
                  return { file_name }
                }
              }
            })
            return modalInstance.result.then((result: any) => {
              return this.stage_file(result)
            }
            , function (reason: any) {
            });
          },

          /* VENDOR */

          can_contact_vendor () {
            return $scope.allowCreate &&
            $scope.binder &&
            ($scope.binder.vendor !== null && $scope.binder.vendor !== '')
          },

          contact_vendor () {
            return this.document_source_help_text = $scope.binder.vendor.replace(new RegExp('\n', 'g'), '<br />')
          },

          /* DOCUMENT SOURCE HELP TEXT */

          document_source_help_text: '',

          can_see_document_source_help_text () {
            return this.document_source_help_text !== ''
          },

          /* READ / DOWNLOAD */

          read_attachment (attachment: any) {
            this.openPdfPreviewModal(attachment)
            $scope.clicked = null
            $scope.processingStateService.set(attachment, $scope.processingStateService.processing_states.RESTING)
          },

          open_file_url (url: any, target: any) {
            $window.open(url, target)
            return true
          },

          download_attachment (attachment: any) {
            return this.open_file_url(attachment.url, '_blank')
          },

          openPdfPreviewModal (file_object: any) {
            const modalInstance = $uibModal.open({
              templateUrl: 'views/modals/pdf_preview_modal.html',
              controller: 'pdfPreviewModalController',
              size: 'xl',
              resolve: {
                modalOptions () {
                  return { file_object }
                }
              }
            })
            return modalInstance.result.then(function (result: any) {
            }
            , function (reason: any) {
            });
          },

          can_blob () {
            const canBlob = !!new Blob()
            return canBlob
          },

          is_pdf (attachment: any) {
            const isPDF = attachment.document_content_type === 'application/pdf'
            return isPDF
          },

          can_object () {
            const canObject = !!document.createElement('object')
            return canObject
          },

          determine_loading_indicator_visibility (index: any) {
            return $scope.clicked === index
          },

          attempt_inspect_attachment (attachment: any, index: any) {
            $scope.clicked = index
            // State service will set the object to a state of PENDING
            // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
            $scope.loadingStateService.init()
            return $scope.processingStateService.performFunction(attachment, () => {
              return this.inspect_attachment(attachment)
            })
          },

          inspect_attachment (attachment: any) {
            // mobile or safari -- open in a new tab.
            if ($scope.detectionServices.isMobileAmalgam() || $scope.detectionServices.isSafari()) {
              $scope.processingStateService.set(attachment, $scope.processingStateService.processing_states.RESTING)
              return this.open_file_url(attachment.url, '_blank')

            // I am not Safari; I am a pdf, can use an html5 'object', and can use 'blob'
            } else if (this.is_pdf(attachment) && this.can_object() && this.can_blob()) {
              return this.read_attachment(attachment)

            // I am none of the above
            } else {
              this.open_file_url(attachment.url, '_self')
              return $scope.processingStateService.set(attachment, $scope.processingStateService.processing_states.RESTING)
            }
          },

          /* DESTROY */

          mark_attachment (attachment: any) {
            attachment.marked = true
            objectManager.array_action($ngModel.$modelValue, attachment, 'update')
            return $ngModel.$validate()
          },

          unmark_attachment (attachment: any) {
            attachment.marked = false
            objectManager.array_action($ngModel.$modelValue, attachment, 'update')
            return $ngModel.$validate()
          },

          marked_attachments () {
            const marked_attachments = []
            if ($ngModel.$modelValue && $ngModel.$modelValue.length) {
              for (let attachment of Array.from($ngModel.$modelValue)) {
                //@ts-ignore
                if (attachment.marked === true) {
                  marked_attachments.push(attachment)
                }
              }
            }
            return marked_attachments
          },

          destroy_attachments (attachments: any) {
            return Array.from(attachments).map((attachment) =>
              this.destroy_attachment(attachment))
          },

          destroy_attachment (attachment: any) {
            this.increment_pending()
            return Restangular.one('attachments', attachment.id).remove().then((success_response: any) => objectManager.array_action($ngModel.$modelValue, attachment, 'remove'), (error_response: any) => {
              const alert = {
                name: `delete_error_${attachment.document_file_name}`,
                dismissable: true,
                class: 'alert-warning',
                icon: 'fa-exclamation-triangle',
                strong: 'Error:',
                message: `There was an error deleting ${attachment.document_file_name}. ${flashService.default_alert_by_code(error_response.status).message}, please try again.`
              }
              flashService.emit_alert($scope, alert)
              return this.increment_failed()
            }).finally(() => {
              return this.decrement_pending()
            });
          },

          /* WATCHERS, LISTENERS AND EMITTERS */

          listen_for_form_save_event () {
            return $scope.$on('attachment_manager_start', (event: any, data: any) => {
              // set the record_id because previously we may not have had it
              this.record_id = data.id

              if ((this.marked_attachments().length + this.staged_files.length) > 0) {
                this.upload_staged_files()
                return this.destroy_attachments(this.marked_attachments())
              } else {
                return this.emit_completion_event()
              }
            });
          },

          emit_completion_event () {
            const return_object = {
              manager: 'attachment_manager',
              object: $ngModel.$modelValue,
              fault: this.failed_transactions > 0
            }
            $scope.$emit('attachment_manager_finished', return_object)
            // reset the transaction counters
            this.pending_transactions = 0
            return this.failed_transactions = 0
          },

          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue && $ngModel.$modelValue.length, (new_value: any, old_value: any, scope: any) => {
              this.ng_model_value = $ngModel.$modelValue
            });
          },

          /* VALIDATION */

          get_validaton_length () {
            let length = 0
            if (this.staged_files) {
              length += this.staged_files.length
            }
            if ($ngModel.$modelValue) {
              for (let attachment of Array.from($ngModel.$modelValue)) {
                //@ts-ignore
                if (!attachment.marked) {
                  length++
                }
              }
            }
            return length
          },

          add_validators () {
            $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              if ($attributes.required === false) {
                return true
              } else {
                return this.get_validaton_length() > 0
              }
            }

            $ngModel.$validators.minlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.minlength) {
                return true
              } else {
                return this.get_validaton_length() >= $attributes.minlength
              }
            }

            return $ngModel.$validators.maxlength = (modelValue: any, viewValue: any) => {
              if (!$attributes.maxlength) {
                return true
              } else {
                return this.get_validaton_length() <= $attributes.maxlength
              }
            };
          },

          /* VIEW SETTINGS */

          show_table () {
            return (this.staged_files.length > 0) || ($ngModel.$modelValue && ($ngModel.$modelValue.length > 0))
          },

          set_error_state () {
            if ($scope.showError && $ngModel.$invalid) {
              this.document_source_help_text = 'A document is required'
              return true
            } else {
              return false
            }
          },

          copyAddress () {}

        } // END ATTACHMENT MANAGER

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.am.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
