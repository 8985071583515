/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
    no-unsafe-finally,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var dayjs = require("dayjs")


var complyosClient = angular.module('complyosClient')

complyosClient.service('fileService', [
  'FileSaver',
  'Blob',
  function (FileSaver: any, Blob: any) {
    return {
      base64toByteArray (base64: any) {
        base64 = base64.replace(/\r?\n|\r/g, '')
        const byteCharacters = atob(base64)

        const byteNumbers = new Array(byteCharacters.length)
        _.forEach(byteNumbers, (v: any, i: any) => byteNumbers[i] = byteCharacters.charCodeAt(i))

        const byteArray = new Uint8Array(byteNumbers)

        return byteArray
      },

      byteArrayToBlob (byteArray: any, content_type: any) {
        const blob = new Blob([byteArray], { type: content_type })
        return blob
      },

      downloadBase64File (responseData: any, content_type: any, file_name: any) {
        const byteArray = this.base64toByteArray(responseData)
        const data = this.byteArrayToBlob(byteArray, { type: content_type })
        return FileSaver.saveAs(data, file_name)
      },

      dataURLtoFile (dataurl: any, filename: any) {
        // Data URLs use the following syntax:
        // data:[mimetype];[encoding],[encoded_data]
        const data_url_halves = dataurl.split(',')
        const mimetype = data_url_halves[0].match(/:(.*?);/)[1]
        const encoded_data = data_url_halves[1]
        const bstr = atob(encoded_data)
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([ u8arr ], filename, { type: mimetype })
      },

      base64toFile (file_type: any, data: any, file_name: any) {
        return this.dataURLtoFile(`data:${file_type};base64,${data}`, file_name)
      }
    };
  }

])

complyosClient.service('dateService', function () {
  // May 3, 2017
  // JS does not have an inherit method for converting a date to ISO format('YYYY-MM-DD') without time
  // This prototype solves this by converting to ISO and then taking a substring of just the date portion
  // From here on out, imt_date will refer to the ISO format without time, Iso Minus Time...
  return {

    convert_from_imt_date (date: any) {
      // An inherit problem that we encountered with new Date() was that
      // depending on time of day and where located, the day can fluctuate.
      // As the date picker needs a JS Date object, there might be some conversion confusion
      // e.g. DB -> "2017-05-03".... new Date("2017-05-03") -> Tues May 2nd, 2017...This all has to do with time zones.
      // To always be 100% sure that the day being displayed is what is being returned from the database,
      // we crate a dayjs() on that specific day from the datebase, that mimics a JS Date object only the time is set to 0.0.0
      date = dayjs(date)
      return date.$d
    },

    convert_to_imt_date (date: any) {
      date = dayjs(date).format('YYYY-MM-DD')
      return date
    },

    convert_dates_before_saving (object: any, date_keys: any) {
      const obj = object
      _.each(date_keys, (key: any) => {
        return obj[key] = this.convert_to_imt_date(obj[key])
      })
      return obj
    },

    date_in_range (date: any, range_begin: any, range_end: any) {
      return (date >= range_begin) && (date <= range_end)
    },

    return_date_object (string: any) {
      return dayjs(string).$d
    },

    binder_date_validation (date_helper_object: any, parameters: any) {
      date_helper_object.start_date.visible = false
      const now = dayjs().startOf('day').$d
      let error_count = 0

      // binder schedule start date can not be in the past when binder modal
      // is in update mode
      if (dayjs(parameters.schedule_start_date).isBefore(now, 'days') > 0) {
        date_helper_object.start_date.message = 'Schedule Start Date can not be in the past'
        date_helper_object.start_date.visible = true
        error_count++
      }

      return error_count
    },

    organization_date_validation (date_helper_object: any, parameters: any) {
      date_helper_object.date_survey_next_begin.visible = false
      date_helper_object.date_survey_next_end.visible = false
      let error_count = 0

      // if the begin date is after the end date
      if (dayjs(parameters.date_survey_next_begin).startOf('day').diff(dayjs(parameters.date_survey_next_end).startOf('day'), 'days') > 0) {
        date_helper_object.date_survey_next_begin.message = 'Start date must come before end date'
        date_helper_object.date_survey_next_begin.visible = true
        error_count++
      }

      // if the end date is earlier than the begin date
      if (dayjs(parameters.date_survey_next_end).startOf('day').diff(dayjs(parameters.date_survey_next_begin).startOf('day'), 'days') < 0) {
        date_helper_object.date_survey_next_end.message = 'End date must come after start date'
        date_helper_object.date_survey_next_end.visible = true
        error_count++
      }

      // Dates must be populated in a set, there can (not) only be one
      if (
        dayjs(parameters.date_survey_next_begin).isValid() &&
        (!dayjs(parameters.date_survey_next_end).isValid() || parameters.date_survey_next_end === undefined)
      ) {
        date_helper_object.date_survey_next_end.message = 'End date is required for survey date range'
        date_helper_object.date_survey_next_end.visible = true
        error_count++
      }

      if (
        (!dayjs(parameters.date_survey_next_begin).isValid() || parameters.date_survey_next_begin === undefined) &&
        dayjs(parameters.date_survey_next_end).isValid()
      ) {
        date_helper_object.date_survey_next_begin.message = 'Begin date is required for survey date range'
        date_helper_object.date_survey_next_begin.visible = true
        error_count++
      }

      // require an entity selection if there is a value in any of the date fields
      if (
        (dayjs(parameters.date_survey_last).isValid() ||
        dayjs(parameters.date_survey_next_begin).isValid() ||
        dayjs(parameters.date_survey_next_end).isValid()) &&
        parameters.surveying_entity === null
      ) {
        date_helper_object.surveying_entity.message = 'An entity selection is required'
        date_helper_object.surveying_entity.visible = true
        error_count++
      }

      return error_count
    },

    date_range_validation (date_helper_object: any, parameters: any) {
      date_helper_object.begin_date.visible = false
      date_helper_object.end_date.visible = false
      const now = dayjs().startOf('day').$d
      let error_count = 0

      // if the begin date is after the end date
      if (dayjs(parameters.date_range_begin).startOf('day').diff(dayjs(parameters.date_range_end).startOf('day'), 'days') > 0) {
        date_helper_object.begin_date.message = 'Begin date must come before end date'
        date_helper_object.begin_date.visible = true
        error_count++
      }

      // if the end date is earlier than the begin date
      if (dayjs(parameters.date_range_end).startOf('day').diff(dayjs(parameters.date_range_begin).startOf('day'), 'days') < 0) {
        date_helper_object.end_date.message = 'End date must come after begin date'
        date_helper_object.end_date.visible = true
        error_count++
      }

      // if the begin date hasn't occurred yet
      if (dayjs(parameters.date_range_begin).diff(now, 'days') > 0) {
        date_helper_object.begin_date.message = 'The chosen begin date can not be a future date'
        date_helper_object.begin_date.visible = true
        error_count++
      }

      // if the end date hasn't occurred yet
      if (dayjs(parameters.date_range_end).diff(now, 'days') > 0) {
        date_helper_object.end_date.message = 'The chosen end date can not be a future date'
        date_helper_object.end_date.visible = true
        error_count++
      }

      // taken out for now, leaving in for later use if needed
      // if the begin date is greater than 3 years ago to the days. 3 years is 1,095 days. use that number to see if
      // the difference in days is greater than that number. I add 1 day to the 1,095 due to .startOf('day') discrepancies.
      // under this condition a user should be able to go back exactly 3 years to the day and pull back data.
      // if dayjs(parameters.date_range_begin).startOf('day').diff(now.startOf('day'), 'days') < -1096
      //   date_helper_object.begin_date.message = 'Begin date must start after ' + dayjs().subtract(3,'years').subtract(1,'days').format('MMMM DD, YYYY')
      //   date_helper_object.begin_date.visible = true
      //   # date_range_form.failed_submission = true
      //   error_count++

      // if the begin date is blank
      //@ts-ignore
      if (date_range_form.begin_date === '') {
        date_helper_object.begin_date.message = 'Schedule Start Date is required'
        date_helper_object.begin_date.visible = true
      }

      // if the end date is blank
      //  these two were date_range_form.end_date.$invalid. but due to scope changed to empty strings
      //@ts-ignore
      if (date_range_form.end_date === '') {
        date_helper_object.end_date.message = 'End date is required'
        date_helper_object.end_date.visible = true
      }

      return error_count
    }

    // strip_time_and_gmt: (date) -> dayjs(date).format('YYYY-MM-DD')

    // Due to this causing confusion in dates, we are stripping this out... 2017-05-03
    // convertToClientGMT: (date) ->
    //   dateOffset = date.getTimezoneOffset()
    //   date.setMinutes(date.getMinutes() + dateOffset)
    //   return date
    //
    // convertStringToClientGMT: (dateString) ->
    //   date = new Date(dateString)
    //   return @convertToClientGMT(date)
  };
})

// complyosClient.factory('loadingStateService', function () {
//   return {
//     loading_states: {
//       authenticating: {
//         name: 'authenticating',
//         icon: 'fa-cog fa-spin',
//         text: 'Redirecting through Ping Authentication'
//       },
//       loading: {
//         name: 'loading',
//         icon: 'fa-cog fa-spin',
//         text: 'Loading'
//       },
//       nocontent: {
//         name: 'nocontent',
//         icon: 'fa-circle-thin',
//         text: 'No Data Found'
//       },
//       unauthorized: {
//         name: 'unauthorized',
//         icon: 'fa-lock',
//         text: 'Permission Denied'
//       },
//       error: {
//         name: 'error',
//         icon: 'fa-exclamation-triangle',
//         text: 'Error loading Data'
//       },
//       content: {
//         name: 'content',
//         icon: null,
//         text: null
//       },
//       organizationRequired: {
//         name: 'organizationRequired',
//         icon: 'fa-building',
//         text: 'Organization Required'
//       },
//       query_too_large_warning: {
//         name: 'query_too_large_warning',
//         icon: 'fa-cog',
//         show_indicator: false
//       }
//     },

//     set_state (state: any) {
//       return this.loading_state = this.loading_states[state]
//     },

//     // make a get_current_state function and rename this to
//     // get_default_state to avoid confusion
//     get_state (state: any) {
//       return this.loading_states[state]
//     },

//     process_success (response: any) {
//       if ((response.status === 204) || (response.length === 0) || (response === {})) {
//         return this.set_state('nocontent')
//       } else {
//         return this.set_state('content')
//       }
//     },

//     process_error (error: any) {
//       switch (error.status) {
//         case 401: case 403:
//           return this.set_state('unauthorized')
//         default:
//           return this.set_state('error')
//       }
//     },


//     init (load_state: any) {
//       return this.set_state('loading')
//     }

//   };
// })

// complyosClient.factory('flashService', ['$timeout', function ($timeout: any) {
//   return {
//     default_alerts: {
//       ok: {
//         name: 'default_ok',
//         dismissable: true,
//         class: 'alert-info',
//         icon: 'fa-thumbs-up',
//         strong: 'Success',
//         message: 'Standard response for successful HTTP requests'
//       },

//       success: {
//         name: 'default_success',
//         dismissable: true,
//         class: 'alert-success',
//         icon: 'fa-thumbs-up',
//         strong: 'Success',
//         message: 'Standard response for successful HTTP requests'
//       },

//       hunch: {
//         name: 'default_hunch',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'There was an unknown error. Please contact us so we can work on a resolution for you'
//       },

//       toolarge: {
//         name: 'default_toolarge',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'Your file was too large for us to handle'
//       },

//       error: {
//         name: 'default_error',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'Something went wrong'
//       },

//       unauthorized: {
//         name: 'default_unauthorized',
//         dismissable: true,
//         class: 'alert-danger',
//         icon: 'fa-lock',
//         strong: 'Unauthorized',
//         message: 'You do not have sufficient privileges to perform this action'
//       },

//       unprocessable: {
//         name: 'default_unprocessable',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Bad data',
//         message: 'This action cannot be processed'
//       },

//       timeout: {
//         name: 'default_timeout',
//         dismissable: true,
//         class: 'alert-info',
//         icon: 'fa-clock-o',
//         strong: 'Timeout',
//         message: 'Your session has expired and you must log in again'
//       },

//       locked: {
//         name: 'default_locked',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-lock',
//         strong: 'Locked',
//         message: 'This element is currently un-editable. Please contact your administrator if you believe this is in error'
//       },

//       unacceptable: {
//         name: 'default_not_acceptable',
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error',
//         message: 'File contents were corrupted during upload or on retrieval from the server. Verify contents and try again.'
//       }
//     },

//     alerts: [],

//     add_default_alert (name: any) {
//       return this.add_alert(this.default_alerts[name])
//     },

//     add_alert (alert_object: any) {
//       // extend the default_alert_options with the custom alert_object
//       const default_alert_options = {
//         dismissable: true,
//         class: 'alert-warning',
//         icon: 'fa-exclamation-triangle',
//         strong: 'Error'
//       }
      
//       alert_object = angular.extend(default_alert_options, alert_object)

//       if ((alert_object.name !== undefined) && (alert_object.name !== null)) {
//         let duplicate_alert = false

//         _.each(this.alerts, function (a: any) {
//           if (a.name === alert_object.name) {
//             duplicate_alert = true
//             a.class = a.class + ' animated shake'
//             return $timeout(() => a.class = a.class.replace(/animated shake/g, '')
//               , 1000);
//           }
//         })

//         if (!duplicate_alert) {
//           const full_alert_object = _.merge(alert_object, this.alert_functions(alert_object))
//           return this.alerts.push(full_alert_object)
//         }
//       }
//     },

//     remove_alert (alert_object: any) {
//       return this.alerts = _.pull(this.alerts, alert_object)
//     },

//     remove_alert_by_name (alert_name: any) {
//       return this.alerts = this.alerts.filter(function (alert) {
//         return alert.name !== alert_name
//       })
//     },

//     clear_alerts () {
//       return this.alerts = []
//     },

//     emit_alert (scope: any, alert: any) {
//       return scope.$emit('emit_alert', alert)
//     },

//     broadcast_alert (scope: any, alert: any) {
//       return scope.$broadcast('broadcast_alert', alert)
//     },

//     listen_for_alert (scope: any) {
//       scope.$on('broadcast_alert', (event: any, data: any) => {
//         return this.add_alert(data)
//       })

//       return scope.$on('emit_alert', (event: any, data: any) => {
//         return this.add_alert(data)
//       });
//     },

//     default_alert_by_code (code: any) {
//       switch (code) {
//         case 200:
//           return this.default_alerts['ok']
//         case 401: case 403:
//           return this.default_alerts['unauthorized']
//         case 406:
//           return this.default_alerts['unacceptable']
//         case 413:
//           return this.default_alerts['toolarge']
//         case 422:
//           return this.default_alerts['unprocessable']
//         case 498:
//           return this.default_alerts['timeout']
//         case 423:
//           return this.default_alerts['locked']
//         case -1:
//           return this.default_alerts['hunch']
//         default:
//           return this.default_alerts['error']
//       }
//     },

//     process_error (error: any) {
//       // console.log(error)
      
//       const alert_object = angular.copy(this.default_alert_by_code(error.status))
//       if (Array.isArray(error.data)) {
//         alert_object.message = alert_object.message + ' :: ' + error.data.join(', ')
//       }

//       return this.add_alert(alert_object)
//     },

//     alert_functions (alert_object: any) {
//       return {
//         dismiss: () => {
//           return this.remove_alert(alert_object)
//         }
//       }
//     }

//   };
// }])

// complyosClient.factory('utils', ['ENV', (ENV: any) => ({
//   /**
//     * Helper method to $scope.$apply safely. Will prevent the dreaded
//     * "digest already in progress" error when trying to apply
//     *
//     * @param {object} Current scope
//     * @param {function} The function to apply
//     */
//   safeApply ($scope: any, fn: any) {
//     const phase = $scope.$$phase
//     if ((phase === '$apply') || (phase === '$digest')) {
//       if (fn && (typeof (fn) === 'function')) {
//         return fn()
//       }
//     } else {
//       return $scope.$apply(fn)
//     }
//   },

//   /**
//     * This function is used to log things to the web console in
//     * development and staging mode. It will not run the console.log
//     * function in other modes and will not run the function if
//     * it doesn't exist - good for older browsers such as IE8
//     * Coloring/message severity not compatible with object inspection
//     *
//     * @param {any} The content that is logged to the console
//     * @param {string} String used to color the message
//     * @param {boolean} Used to push an alert...but not in production
//     */
//   log (what: any, severity = null, force: any) {
//     if (force == null) { force = false }
//     if ((ENV.NAME === 'local') || (ENV.NAME === 'staging') || (ENV.NAME === 'qa')) {
//       if (force) {
//         alert(what) // ha
//       } else {
//         try {
//         // Check if the type exists and check if console is
//         // a member property of window
//           if (typeof console === 'object') {
//             if (severity) {
//               // Pick a style, bro
//               const style = (() => {
//                 switch (false) {
//                   case severity !== 'primary': return 'background: transparent; color:#357ebd;'
//                   case severity !== 'error': return 'background: #d9534f; color:#fff;'
//                   case severity !== 'success': return 'background: #47a447; color:#fff;'
//                   case severity !== 'warning': return 'background: #f0ad4e; color:#5F4500;'
//                   case severity !== 'info': return 'background: #5bc0de; color:#004B61;'
//                   default: return 'background: transparent; color:#343434;'
//                 }
//               })()

//               return console.log(`%c ${what}`, style + 'padding: 0.1rem 0.3rem;')
//             } else {
//               return console.log(what)
//             }
//           }
//         } finally {
//           return
//         }
//       }
//     }
//   },

//   shortenFileName (str: any, newLength: any) {
//     if (str.length < newLength) {
//       return str
//     } else {
//       let name = str.substr(0, str.lastIndexOf('.'))
//       const ext = str.substr(str.lastIndexOf('.') + 1)
//       if (name.length > (newLength - 3 - ext.length)) {
//         name = name.substr(0, newLength - 3 - ext.length)
//       }
//       return `${name}...${ext}`
//     }
//   }
// })
// ])

// complyosClient.factory('objectManager', ['$q', 'utils', function ($q: any, utils: any) {
//   const actions = [
//     'merge',
//     'remove',
//     'update'
//   ]

//   const merge_object = function (arr: any, obj: any) {
//     let index
//     if (obj.id === undefined) {
//       index = -1
//     } else {
//       index = _.findIndex(arr, { id: obj.id })
//     }
//     if (index > -1) {
//       return _.merge(arr[index], obj)
//     } else {
//       return arr.push(obj)
//     }
//   }

//   const remove_object = function (arr: any, obj: any) {
//     if (obj.id === undefined) {
//       return _.remove(arr, obj)
//     } else {
//       return _.remove(arr, { id: obj.id })
//     }
//   }

//   const update_object = function (arr: any, obj: any) {
//     if (obj.id === undefined) {
//       return utils.log('object does not have id, cannot update array', 'error')
//     } else {
//       const index = _.findIndex(arr, { id: obj.id })
//       if (index > -1) {
//         // we want to keep the memory address
//         const object_l = arr[index]
//         const object_r = obj
//         const l_keys = _.keys(object_l)
//         const r_keys = _.keys(object_r)
//         const remove_keys = _.difference(l_keys, r_keys)
//         _.each(remove_keys, (key: any) => object_l[key] = undefined)
//         return _.each(r_keys, function (key: any) {
//           object_l[key] = object_r[key]
//         });
//       }
//     }
//   }

//   return {
//     array_action (arr: any, obj: any, action: any) {
//       if (!arr || !obj || (actions.indexOf(action) === -1)) {
//         utils.log('Array or Object incorrect.', 'error')
//         utils.log({ arr, obj, action, index: actions.indexOf(action) })
//         return false
//       }

//       switch (action) {
//         case 'merge':
//           merge_object(arr, obj)
//           break
//         case 'remove':
//           remove_object(arr, obj)
//           break
//         case 'update':
//           update_object(arr, obj)
//           break
//       }

//       return true
//     }
//   };
// }])

complyosClient.factory('treeManagerService', ['objectManager',
  function (objectManager: any) {
    var tree_manager :any= {}

    tree_manager.update_object_in_tree = (tree: any, updated_object: any) => {
      const object = tree_manager.find_object_in_tree_by_id(tree, updated_object.id)

      if (object.parent_id !== updated_object.parent_id) {
        tree_manager.remove_object_from_tree(tree, object)
        return tree_manager.add_object_to_tree(tree, updated_object)
      } else {
        const { depth } = object
        _.extend(object, updated_object)
        return object.depth = depth
      }
    }

    tree_manager.add_object_to_tree = (tree: any, object: any) => {
      if (object.parent_id) {
        const parent = tree_manager.find_object_in_tree_by_id(tree, object.parent_id)
        parent.children.push(object)
      } else {
        tree.push(object)
      }


      tree_manager.set_object_depth(tree, object)
    }

    tree_manager.set_object_depth = (tree: any, object: any) => {
      if (object.parent_id) {
        const parent = tree_manager.find_object_in_tree_by_id(tree, object.parent_id)
        object.depth = parent.depth + 1
      } else {
        object.depth = 0
      }

      if (object.children) {
        return _.each(object.children, (child: any) => {
          return tree_manager.set_object_depth(tree, child)
        });
      }
    }

    tree_manager.remove_object_from_tree = (tree: any, object: any) => {
      if (object.parent_id) {
        const parent = tree_manager.find_object_in_tree_by_id(tree, object.parent_id)
        return objectManager.array_action(parent.children, object, 'remove')
      } else {
        return objectManager.array_action(tree, object, 'remove')
      }
    }

    tree_manager.find_object_in_tree_by_id = (tree: any, id: any) => {
      let target_object: any

      var check_object_and_children = function (object: any) {
        if (object.id === id) {
          target_object = object
        }

        if ((target_object === undefined) && object.children) {
          return _.each(object.children, (child: any) => check_object_and_children(child));
        }
      }

      _.each(tree, (object: any) => check_object_and_children(object))

      return target_object
    }

    tree_manager.flatten_tree = (tree: any) => {
      const flattened_tree: any = []

      var recursively_flatten = function (branch: any) {
        _.each(branch, function (object: any) {
          flattened_tree.push(object)

          if (object.children) {
            return recursively_flatten(object.children)
          }
        })
      }

      recursively_flatten(tree)
      return flattened_tree
    }

    tree_manager.set_show_property = (tree_list: any) => {
      // set default show property on each organization

      const flat_list = tree_manager.flatten_tree(tree_list)

      return _.each(flat_list, function (item: any) {
        item.show = false
        return 'cool'
      });
    }

    tree_manager.recursively_assign_key = (tree: any, key: any, value: any) => {
      var assign = function (branch: any) {
        _.each(branch, function (object: any) {
          object[key] = value
          if (object.children) {
            return assign(object.children)
          }
        })
      }

      assign(tree)
    }

    return tree_manager
  }
])
