import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ngApiSearchDropdown',
  templateUrl: './api-search-dropdown.component.html'
})
export class ApiSearchDropdownComponent implements OnInit {
  // Properties
  @Output() outputChange = new EventEmitter<void>();
  @Input() dropdownCloseFn: () => void;
  @Input() applyCurrentNodePath: () => void;
  @Input() apiSearchFunction: any;
  @Input() nodeTreeBuilderConfigConfig: any;
  @Input() descendantsName: string;
  @Input() descendantsPrefix: string;
  @Input() outputNodePath: any;
  @Input() refreshOnOpen: boolean;
  @Input() noMultiSelect: boolean;
  @Input() useSmallPopout: boolean;
  @Input() hideApply: boolean;
  @Input() summaryIsCount: boolean;
  @Input() enableDescendantChecking: boolean;
  @Input() hideSearch: boolean;
  @Input() hideSelectedListInPopout: boolean;
  @Input() placeholder: string;
  @Input() displayNameKey: string;
  @Input() valueKey: string;
  @Input() popupPositioning: string;

  private nodeTreeBuilder = { isLoading: true, isPopoutOpen: false, searchInput: '', nodePath: [], currentNodeList: [], maxItemCount: 150, paginationInfo: undefined, error: null };
  private tickOptions = { Unchecked: false, Checked: true, CheckedWithDescendants: 'CheckedWithDescendants' };
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService
  ) { 
    this.helpService = angular.copy(helpService)
    this.fortyCore = angular.copy(fortyCore)
  }

  ngOnInit(): void {
    if (!this.refreshOnOpen) {
      this.loadSearchResults('')
    }

    this.displayNameKey = this.displayNameKey || "displayName"
    this.valueKey = this.valueKey || "value"
  }

  triggerOutputChange(outputNodePath: any) {
    this.outputChange.emit(outputNodePath)
    this.dropdownClose()
  }

  updateOutputNodePath = (updated) => {
    this.outputNodePath = updated
    setTimeout(() => { this.triggerOutputChange(this.outputNodePath) }, 100)
  }

  applyNodePath = () => {
    // this.nodeTreeBuilder.isPopoutOpen = false;
    this.applyCurrentNodePath()
    this.reset()
  }

  reset = () => {
    this.updateOutputNodePath([])
    this.clearList()
    this.nodeTreeBuilder.isPopoutOpen = false
  }

  clearList = () => {
    this.nodeTreeBuilder.currentNodeList = []
    this.nodeTreeBuilder.searchInput = ''
  }

  openNodeTreeBuilderPopout = () => {
    this.toggleNodeTreeBuilderPopout(true);
    setTimeout(() => {
      let search = document.getElementById('node-tree-search-input')
      if (search != null) {
        search.focus()
      }
    }, 50)
  }

  dropdownOpen = () => {
    if (this.refreshOnOpen) {
      this.nodeTreeBuilder.currentNodeList = []
      this.filterChanged(false)
    }
  }

  dropdownClose = () => {
    this.nodeTreeBuilder.isPopoutOpen = !this.nodeTreeBuilder.isPopoutOpen
    if (this.refreshOnOpen) {
      this.clearList()
    }
    if (this.dropdownCloseFn) {
      setTimeout(() => { this.dropdownCloseFn() }, 100)
    }
  }
  
  toggleNodeTreeBuilderPopout = (isOpen = null) => {
    if (isOpen !== null) {
      this.nodeTreeBuilder.isPopoutOpen = isOpen
    } else {
      this.nodeTreeBuilder.isPopoutOpen = !this.nodeTreeBuilder.isPopoutOpen
    }

    // on open
    if (this.nodeTreeBuilder.isPopoutOpen) {
      this.dropdownOpen()
    }

    // on close
    if (this.nodeTreeBuilder.isPopoutOpen !== true) {
      this.dropdownClose()
    }
  }


  selectNextNode = (node: any) => {
    let path = JSON.parse(JSON.stringify(this.outputNodePath))
    if (this.noMultiSelect) {
      path = []
      this.syncCurrentListToValue(this.tickOptions.Unchecked)
      this.toggleNodeTreeBuilderPopout(false)
      // this.nodeTreeBuilder.isPopoutOpen = false
    }
    let nodeHasDescendants = Array.isArray(node.children) && node.children.length > 0 || node.has_children == true
    node.ticked = this.bumpTickProperty(node.ticked, this.enableDescendantChecking, nodeHasDescendants)

    path = path.filter((n: any) => n[this.valueKey] != node[this.valueKey])
    if (node.ticked) {
      path.push(node)
    }
    this.updateOutputNodePath(path)
  }

  bumpTickProperty = (currentState: any, descendantsEnabled: any, hasDescendants: any) => {
    currentState = currentState || this.tickOptions.Unchecked;
    // this.tickOptions
    let validOptions = 2;
    if (descendantsEnabled && hasDescendants) {
      validOptions = validOptions + 1;
    }
    let index = this.getIndexfromOption(currentState);

    let selectedIndex = index - 1
    if (selectedIndex < 0) {
      selectedIndex = validOptions - 1
    }

    return this.getOptionFromIndex(selectedIndex);
  }
  getIndexfromOption = (selectionOption: any) => {
    const optsArray = Object.values(this.tickOptions);
    return optsArray.indexOf(selectionOption);
  }
  getOptionFromIndex = (index: any) => {
    const optsArray = Object.values(this.tickOptions);
    return optsArray[index];
  }

  loadSearchResultsDebounced = _.debounce((searchText: any) => this.loadSearchResults(searchText), 1000)
  loadSearchResults = (apiSearchInputString: any) => {
    this.nodeTreeBuilder.paginationInfo = undefined
    this.nodeTreeBuilder.currentNodeList = []
    this.nodeTreeBuilder.isLoading = true
    this.nodeTreeBuilder.error = null;
    let promise = this.apiSearchFunction({ apiSearchInputString, loadedCallback: this.apiSearchResultsLoaded })
    if (promise) {
      promise.then(null, (e: any) => this.nodeTreeBuilder.error = e)
      promise.finally((p: any) => this.nodeTreeBuilder.isLoading = false)
    }
  }

  apiSearchResultsLoaded = (apiSearchResults: any) => {
    if (Array.isArray(apiSearchResults.items)) {
      this.nodeTreeBuilder.currentNodeList = apiSearchResults.items || []
    } else {
      this.nodeTreeBuilder.currentNodeList = []
    }
    this.nodeTreeBuilder.paginationInfo = apiSearchResults.pagination_info
    // this.checkAllSelectedOptions()
    this.syncCurrentListToValue(this.tickOptions.Checked, this.tickOptions.Checked)
    this.syncCurrentListToValue(this.tickOptions.CheckedWithDescendants, this.tickOptions.CheckedWithDescendants)
  }

  syncCurrentListToValue = (newTickValue: any, onlySyncOptionsWithOldTickValue: any = null) => {
    let nodePathOptionsToChange = this.outputNodePath
    if (onlySyncOptionsWithOldTickValue) {
      nodePathOptionsToChange = nodePathOptionsToChange.filter((npo: any) => npo.ticked == onlySyncOptionsWithOldTickValue)
    }
    let values = nodePathOptionsToChange.map((n: any) => n[this.valueKey])
    // let checkedOptions = this.nodeTreeBuilder.currentNodeList.filter((n: any) => values.includes(n[this.valueKey]))
    let checkedOptions = this.findAllOptionsByValue(this.nodeTreeBuilder.currentNodeList, values)
    checkedOptions.forEach((o: any) => { o.ticked = newTickValue })
  }

  findAllOptionsByValue = (list: any, values: Array<any>) => {
    let matches: any = []
    list.forEach((n: any) => {
      if (values.includes(n[this.valueKey])) {
        matches.push(n)
      }
      if (Array.isArray(n.children) && n.children.length > 0) {
        let childrenMatches = this.findAllOptionsByValue(n.children, values)
        matches = matches.concat(childrenMatches)
      }
    })
    return matches
    // $scope.nodeTreeBuilder.currentNodeList.filter((n: any) => values.includes(n[$scope.valueKey]))
  }

  truncateNodePath = (indexToRemove: any) => {
    if (indexToRemove == -1) {
      // remove all selected options from PATH and deselect all selected options in list
      let valuesToDeselect = this.outputNodePath.map((n: any) => n[this.valueKey])
      // flat
      // let tickedOptions = this.nodeTreeBuilder.currentNodeList.filter((n: any) => n.ticked)
      let tickedOptions = this.findAllOptionsByValue(this.nodeTreeBuilder.currentNodeList, valuesToDeselect)
      tickedOptions.forEach((n: any) => { n.ticked = false })
      this.outputNodePath.length = 0
    } else {
      // remove ONE selected option from PATH, and delesect that option in the list
      let value = this.outputNodePath[indexToRemove][this.valueKey]
      this.outputNodePath.splice(indexToRemove, 1)
      // flat
      // let nodesToUncheck = this.nodeTreeBuilder.currentNodeList.filter((n: any) => n[this.valueKey] == value)
      let nodesToUncheck = this.findAllOptionsByValue(this.nodeTreeBuilder.currentNodeList, [value])
      nodesToUncheck.forEach((n: any) => { n.ticked = false })
    }
    this.updateOutputNodePath(this.outputNodePath)
  }

  filterChanged = (debounce = true) => {
    let searchText = this.nodeTreeBuilder.searchInput || ''
    searchText = searchText.toUpperCase()
    if (debounce) {
      this.loadSearchResultsDebounced(searchText)
    } else {
      this.loadSearchResults(searchText)
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngApiSearchDropdown', downgradeComponent({ component: ApiSearchDropdownComponent }) as angular.IDirectiveFactory)
