import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

export class RequirementValidators {
  static requiredIfPublished(control: AbstractControl, form: FormGroup): ValidationErrors | null {
    var publishedField = form.get('published')
    if (!publishedField)
      return null

    var isPublished = publishedField.value
    if (!isPublished)
      return null

    if (!control.value) {
      return { requiredIfPublished: true }
    }
    return null
  }
}