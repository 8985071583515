/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'


angular.module('complyosClient').directive('filterTextSearch', [
  'fortyCore', 'arrayFunctions', 'helpService', 'Restangular',
  (fortyCore, arrayFunctions, helpService, Restangular) => ({
    templateUrl: 'views/directives/filter_text_search.html',
    restrict: 'E',

    scope: {
      onOutputChange: '&',
      applyCurrentTextSearch: '&',
      findSearchMatchesFunction: '&',
      filterTextSearchOutput: '=',
      blacklistedFilters: '=',
      searchHitsPlaceholder: '='
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
      $scope.filterTextSearch = {
        isLoading: false,
        isPopoutOpen: false,
        searchInput: ''
      }

      $scope.$on('filterReset', () => {
        $scope.reset()
      })

      // $scope.itemPluralName = $scope.itemPluralName || 'items'
      $scope.updateFilterTextSearchOutput($scope.filterTextSearchOutput || {})

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.updateFilterTextSearchOutput = (matchInformation: any) => {
        $scope.filterTextSearchOutput = matchInformation
        setTimeout(() => { $scope.onOutputChange() }, 100)
      }

      $scope.applyOnEnter = ($event: any) => {
        if ($event.keyCode == 13) {
          $scope.filterChanged().then((p: any) => $scope.parentApply())
        }
      }

      $scope.parentApply = () => {
        // $scope.filterTextSearch.isPopoutOpen = false;
        $scope.applyCurrentTextSearch()
        $scope.reset()
      }

      $scope.reset = () => {
        $scope.filterTextSearch.searchInput = ''
      }

      // $scope.nullishCoalesce = (check: any, defaultTo: any) => {
      //   if (check !== null && check != undefined) {
      //     return check
      //   }
      //   return defaultTo
      // }

      $scope.matchesFoundCallback = (matchInformation: any) => {
        $scope.updateFilterTextSearchOutput(matchInformation)
      }

      $scope.filterChanged = () => {
        let searchText = $scope.filterTextSearch.searchInput || ''
        searchText = searchText.toUpperCase()
        $scope.filterTextSearch.isLoading = true
        let whitelistFields: any = []
        let blacklistFields = $scope.blacklistedFilters
        let promise = $scope.findSearchMatchesFunction({ filterString: searchText, matchesFoundCallback: $scope.matchesFoundCallback, whitelistFields: whitelistFields, blacklistFields: blacklistFields })
        return promise.then((p: any) => {
          $scope.filterTextSearch.isLoading = false
          $scope.$apply()
        });
      }

      return $scope
    }
  })
])
