import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BinderService {
  private binderUpdateSubject = new Subject<any>()
  private entryApiTriggerSubject = new Subject<void>()
  private notificationApiTriggerSubject = new Subject<void>()
  private binderApiTriggerSubject = new Subject<void>()

  // observable for components to subscribe to to get binder object updates
  binderUpdate$ = this.binderUpdateSubject.asObservable()

  // the binder components can subscribe to this to trigger the entry api call
  entryApiCallTrigger$ = this.entryApiTriggerSubject.asObservable()

  // the binder components can subscribe to this to trigger the notification api call
  notificationApiCallTrigger$ = this.notificationApiTriggerSubject.asObservable()

  // the binder components can subscribe to this to trigger the binder api call
  binderApiCallTrigger$ = this.binderApiTriggerSubject.asObservable()

  // method to broadcast data
  broadcastBinder(binderObject: any) {
    this.binderUpdateSubject.next(binderObject)
  }

  // method to trigger entry api call
  triggerEntryApiCall() {
    this.entryApiTriggerSubject.next()
  }

  // method to trigger notification api call
  triggerNotificationApiCall() {
    this.notificationApiTriggerSubject.next()
  }

  // method to trigger binder api call
  triggerBinderApiCall() {
    this.binderApiTriggerSubject.next()
  }
}