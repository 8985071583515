// /* eslint-disable
//     no-undef
// */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('controlNumberController', ['$rootScope', '$state', '$scope', '$timeout', '$uibModal', 'loadingStateService', 'Restangular', function (
//   $rootScope: any,
//   $state: any,
//   $scope: any,
//   $timeout: any,
//   $uibModal: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     const customStates = {
//       loading: {
//         text: `Validating permissions for record ${$state.params.control_number}`
//       },
//       validated: {
//         name: 'validated',
//         icon: 'fa-check',
//         text: 'CHECK!'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, customStates)
//     $scope.loadingStateService.init()

//     /*
//     This controller is a workflow wrapper that enables a 'cold call' to the page.
//     (allow for a user to follow a link from outside the application)
//     It will check the API for specific permissions, and reject
//     any users that do not have access to the records in question.
//     Finally it loads any required models (ex: a binders organization)
//     that allow complyos work correctly.

//     1) user navigates to page
//     3) api call for control_number information
//     4) 200/401 response
//       * 401 redirect to home
//     5) switch on control model.
//       organization - load modal
//       requirement - load modal
//       binder - set org in session & redirect to binder page
//     */

//     $timeout(() => {
//       getControlNumberRecord()
//     }, 750)
//   }

//   var getControlNumberRecord = function () {
//     Restangular.one(
//       `control_numbers/${$state.params.control_number}`
//     ).get().then(function (success: any) {
//       $scope.loadingStateService.set_state('validated')
//       $timeout(() => {
//         processControlNumberRecord(success.plain())
//       }, 250)
//     }, function (error: any) {
//       if (error.status === 500) {
//         addRecordNotFoundFlash(error)
//       } else if (error.status === 401) {
//         addPermissionFailureFlash(error)
//       } else {
//         addGenericError(error)
//       }
//       $rootScope.session.goTo('/home')
//     })
//   }

//   var processControlNumberRecord = function (controlNumberRecord: any) {
//     switch (controlNumberRecord.controllable_type) {
//       case 'Organization':
//         openOrganizationModal(controlNumberRecord)
//         break
//       case 'Requirement':
//         openRequirementModal(controlNumberRecord)
//         break
//       case 'Binder':
//         loadBinderInformationPage(controlNumberRecord)
//         break
//     }
//   }

//   var openOrganizationModal = function (controlNumberRecord: any) {
//     let organization = controlNumberRecord.controllable

//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/organization_modal.html',
//       controller: 'organizationModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
//             organization: organization,
//             organizations: [], // required for some reason
//             ui_setting: 'read'
//           }
//         }
//       }
//     })

//     modalInstance.result.then(function (result: any) {
//       // happy close
//     }, function (reason: any) {
//       // angry close
//     }).finally(function () {
//       $rootScope.session.goTo('/home')
//     })
//   }

//   var openRequirementModal = function (controlNumberRecord: any) {
//     let requirement = controlNumberRecord.controllable

//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/requirement_modal.html',
//       controller: 'requirementModalController',
//       size: 'lg',
//       resolve: {
//         modalOptions () {
//           return {
//             object: requirement,
//             ui_setting: 'read'
//           }
//         }
//       }
//     })

//     return modalInstance.result.then(function (result: any) {
//       // happy close
//     }, function (reason: any) {
//       // angry close
//     }).finally(function () {
//       $rootScope.session.goTo('/home')
//     });
//   }

//   var loadBinderInformationPage = function (controlNumberRecord: any) {
//     // first get the organization
//     Restangular.one(
//       `organizations/${controlNumberRecord.controllable.organization_id}`
//     ).get().then(function (success: any) {
//       // then set the organization
//       $rootScope.session.setOrganization(success.plain())
//       // finally redirect user
//       $rootScope.session.goTo(`requirements/binder/${controlNumberRecord.controllable_id}`)
//     }, function (error: any) {
//       supportingRecordError(error)
//     })
//   }

//   var addRecordNotFoundFlash = (error: any) => {
//     console.log(error)
//     $rootScope.$broadcast(
//       'add_global_alert', {
//         name: 'recordNotFoundAlert',
//         dismissable: true,
//         class: 'alert-warning',
//         strong: null,
//         message: 'Record not found'
//       }
//     )
//   }

//   var addPermissionFailureFlash = (error: any) => {
//     console.log(error)
//     $rootScope.$broadcast(
//       'add_global_alert', {
//         name: 'binderOrganizationAlert',
//         dismissable: true,
//         class: 'alert-warning',
//         strong: null,
//         message: 'You do not have permission to view this record'
//       }
//     )
//   }

//   var addGenericError = (error: any) => {
//     console.log(error)
//     $rootScope.$broadcast(
//       'add_global_alert', {
//         name: 'controlNumberGenericAlert',
//         dismissable: true,
//         class: 'alert-warning',
//         strong: null,
//         message: "Whoops, can't do that :("
//       }
//     )
//   }

//   var supportingRecordError = (error: any) => {
//     console.log(error)
//     $rootScope.$broadcast(
//       'add_global_alert', {
//         name: 'supportingRecordError',
//         dismissable: true,
//         class: 'alert-warning',
//         strong: null,
//         message: 'Error loading supporting records'
//       }
//     )
//   }

//   return init()
// }])
