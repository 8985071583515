/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('complyosClient').directive('dateRangeFilter', () =>

  ({
    scope: {
      onSubmit: '=',
      model: '=ngModel'
    },
    restrict: 'E',
    templateUrl: 'views/directives/date_range_filter.html',
    replace: true,

    link (scope: any, element: any, attrs: any, controllers: any) {
      return scope.submit_form = function (date_range_form: any) {
        if (date_range_form.$valid) {
          return scope.onSubmit(date_range_form)
        } else {
          return date_range_form.failed_submission = true
        }
      };
    }
  })
)
