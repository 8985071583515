import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngTermsOfService',
  templateUrl: './terms-of-service.component.html'
})
export class TermsOfServiceComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<TermsOfServiceComponent>
  ) {}

  ngOnInit(): void {
  }

  closeModal = () => this.dialogRef.close('close')
}

angular
  .module('complyosClient')
  .directive('ngTermsOfService', downgradeComponent({ component: TermsOfServiceComponent }) as angular.IDirectiveFactory)
