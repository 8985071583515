import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import { helpService } from '../../../../scripts/services/help';
var dayjs = require("dayjs")

@Component({
  selector: 'ngCompletePercentage',
  templateUrl: './complete-percentage.component.html'
})
export class CompletePercentageComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  private reportLoadingStateService: any;
  
  // Properties
  private previousPills: any = null;
  private data: any = null;
  private dateRangeBegin: Date;
  private dateRangeEnd: Date;
  private deferredRefresh: any;
  private outstandingPromises: Array<any> = [];
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(helpService) private helpService: any,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.helpService = angular.copy(helpService)
  }

  ngOnInit(): void {
    const custom_states = {
      ready: {
        icon: 'fa-download',
        text: 'Download Report'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.reportLoadingStateService = angular.copy(this.loadingStateService)
    this.reportLoadingStateService.loading_states = _.merge(this.reportLoadingStateService.loading_states, custom_states)
    this.reportLoadingStateService.set_state('ready')
    this.watchEntityFilter()
  }

  getAnalyticData = (pills: any) => {
    this.loadingStateService.set_state('loading')

    let query_params = {
      model: 'Binder',
      method: 'complete_percentage',
      param_data: {}
    }

    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then((response: any) => {
      let res = response.plain()
      this.data = res.data
      this.dateRangeBegin = res.args.table.date_range_begin
      this.dateRangeEnd = res.args.table.date_range_end
      if (this.data && this.data.percent_complete !== undefined && !isNaN(this.data.percent_complete)) {
        this.data.percent_complete = this.data.percent_complete.toFixed(2);
        this.loadingStateService.process_success(response)
      } else {
        this.loadingStateService.set_state('error')
      }
    }, (error: any) => {
      this.loadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
    })
  }

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getAnalyticData(pills), 50)
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted', 'pagination_params']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        if (resultsCount <= 30000) {
          this.executeRefresh(pills)
        } else {
          this.loadingStateService.set_state('query_too_large_warning')
          this.deferredRefresh = this.executeRefresh.bind(null, pills)
        }
      }
    }
    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  dashboardCompleteReport = () => {
    this.reportLoadingStateService.set_state('loading')

    let query_params = {
      report: 'dashboard_completion',
      param_data: {}
    }
    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(this.previousPills)
    )

    this.Restangular.setFullResponse(true).one(`reports/dashboard_completion`).get(query_params).then((response: any) => {
      let reportFile = new Blob([response.data], { type: 'text/csv' })
      let reportName = `dashboard_completion_report_${dayjs().format('YYYY-MM-DD')}.csv`
      FileSaver.saveAs(reportFile, reportName)
      this.reportLoadingStateService.set_state('ready')
    }
    , (error: any) => {
      console.log(error)
      this.reportLoadingStateService.set_state('error')
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  getReportButtonClass = () => {
    if (this.reportLoadingStateService.loading_state.name === 'error') {
      return 'btn-warning'
    } else {
      return 'btn-default'
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngCompletePercentage', downgradeComponent({ component: CompletePercentageComponent }) as angular.IDirectiveFactory)
