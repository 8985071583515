/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('profileManager', [
  'flashService',
  'Restangular',
  function (
    flashService: any,
    Restangular: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/profile_manager.html',
      require: 'ngModel',
      scope: {
        allowUpdate: '='
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized
      //  attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.pm = {

          initialize () {
            this.watch_ng_model()
            this.listen_for_start_event()

            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          // this syncronizes the $ngModel.$modelValue and @ng_model_value
          // we use @ng_model_value in the view exclusively
          watch_ng_model () {
            return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
              // we need to do our best to only update the real good stuff
              // if the user.profile is blank we can trust that what we are
              // not going to override any user inputted data
              if (this.ng_model_value === undefined) {
                this.ng_model_value = new_value

              // if the user has added data then @ng_model_value != undefined
              // we need to be careful about what we do with $ngModel.$modelValue
              // so step through each key value pair and only override it if there
              // is nothing allready there.
              } else {
                _.each(new_value, (value: any, key: any, obj: any) => {
                  if ((this.ng_model_value[key] === undefined) && (obj[key] !== null)) {
                    // console.log  "updating @ng_model_value #{key} to #{value}"
                    return this.ng_model_value[key] = value
                  }
                })
              }
            });
          },

          listen_for_start_event () {
            return $scope.$on('profile_manager_start', (event: any, data: any) => {
              return this.start_update_profile()
            });
          },

          start_update_profile () {
            // there is no creating a profile. only updating one that exists.
            // the profiles are created after the user is and linked in the api

            // in order to do update a profile there must be an id
            // if you are editing a user that is already in the system the it is
            // a piece of cake, it has an id and we can rock and roll
            if ($ngModel.$modelValue && $ngModel.$modelValue.id) {
              return this.actually_update_profile()

            // If you want to make a profile and a user at the same time
            // you must first make a user. and then get the id, this is handled
            // mostly by the user modal and the save relay. they will save the user
            // the user will come back from the api with a blank-ish profile
            // the watch_ng_model function will will look at this profile and do
            // its best to only update the real attributes. like id
            // this takes time and we need to be patient
            } else {
              // console.log "waiting for profile id..."
              return $scope.$watch(() => $ngModel.$modelValue
                , (new_value: any, old_value: any, scope: any) => {
                  if ($ngModel.$modelValue.id !== undefined) {
                    return this.actually_update_profile()
                  }
                });
            }
          },

          actually_update_profile () {
            // console.log "saving off profile #{@ng_model_value.id}"
            const profile = Restangular.all(`profiles/${this.ng_model_value.id}`)
            return profile.patch(this.ng_model_value).then((success: any) => $ngModel.$modelValue = success, (error: any) => {
              flashService.emit_alert(
                $scope,
                flashService.default_alert_by_code(error.status)
              )
              return this.fault = error
            }).finally(() => {
              return this.emit_completion_event()
            });
          },

          emit_completion_event () {
            const return_object = {
              manager: 'profile_manager',
              object: $ngModel.$modelValue,
              fault: this.fault
            }
            return $scope.$emit('profile_manager_finished', return_object)
          }

        }

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.pm.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
