import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { FlashService } from '../../../services/flash_service.service';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { ValidationHelpers } from 'app/_ng/extensions/ValidationHelpers';
import angular from 'angular';
import _ from 'lodash';

@Component({
  selector: 'ng-requirement-tabs',
  templateUrl: './requirement-tabs.component.html',
  styleUrls: ['./requirement-tabs.component.scss']
})
export class RequirementTabsComponent implements OnInit, OnDestroy {

  @Input() uiSetting = 'read'
  @Input() selectedTabIndex = 0
  @Input() requirementId
  @Input() versionInfo: any
  @Input() modalProcessingStateServiceRef   // this is for controlling the small spinner (only used when saving)
  @Output() onSaveCompleted = new EventEmitter()
  @Output() isValid = new EventEmitter<boolean>()
  @Output() isPauseValid = new EventEmitter<boolean>()

  // Injected Services
  private flashService: any
  public session: any;
  private processingStateService: any

  // Properties
  public requirement
  public requirementFormGroup: FormGroup = this.formBuilder.group({});
  public formSubmitAttempted = false
  private failure: boolean = false;
  private updated_requirement: any = null;
  private unregister_ajs_requirement_completor_save_listener: any

  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    private confirmationModalFactory: ConfirmationModalFactory,
    @Inject('complyosServices') private complyosServices,
    @Inject(FlashService) flashService: FlashService,
    private formBuilder: FormBuilder,
    @Inject('fortyCore') private fortyCore: any,
    private Restangular: Restangular
  ) { 
    this.flashService = angular.copy(flashService)
    this.$scope.flashService = this.flashService  // NEEDED FOR SAVE RELAY FAILURES
    this.processingStateService = fortyCore.processingStateService
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.validityListener()
    this.load_requirement_version()
    this.listen_for_form_complete_edit_state_event()
  }
  ngOnDestroy(): void {
    // Angular $scope does not get destroyed when using tabs
    // must manually unregister
    if (this.unregister_ajs_requirement_completor_save_listener) {
      this.unregister_ajs_requirement_completor_save_listener()
    }
  }


  load_requirement_version = () => {
    if (this.versionInfo.is_edit_state) {
      this.getEditRequirement()
    } else if (this.versionInfo.snapshot_id) {
      this.getRequirementVersion()
    } else {
      this.getRequirement()
    }
  }

  // LOAD
  getEditRequirement = () => this.Restangular.one(`requirements/${this.requirementId}/load_or_create_edit_state`).get().then((success: any) => {
    this.requirement = success.plain()
  })
  getRequirement = () => this.Restangular.one(`requirements/${this.requirementId}`).get().then((success: any) => {
    this.requirement = success.plain()
  })
  getRequirementVersion = () => this.Restangular.one(`requirements/${this.requirementId}/show_version/${this.versionInfo.snapshot_id}`).get().then((success: any) => {
    this.requirement = success.plain()
  })

  // SAVE
  public validityListener = () => {
    this.requirementFormGroup.valueChanges.subscribe((val) => {
      this.isValid.emit(this.requirementFormGroup.valid)
      this.isPauseValid.emit(this.requirementFormGroup.get('name').valid)
    });
  }
  public pause_form_submit = (requirement_form, requirement) => {
    var promise = this.submit_form(requirement_form, requirement, false)
  }
  public validate_form_pause_submit = () => {
    // when PAUSING, there are almost no requirements- you should be able to save the form in whatever state it is in
    // EXCEPT: a name is required
    var errors = []

    var nameField = this.requirementFormGroup.get('name')
    if (!nameField.valid) {
      errors.push({
        control_name: 'name',
        error_name: 'required',
        error_value: 'name field is required'
      });
    }

    return errors
  }
  public save_form_submit = (requirement_form, requirement) => {
    if (this.uiSetting === 'create') {
      return this.submit_form(requirement_form, requirement)
    }
    const confirmation_object = {
      severity: 'warning',
      title: 'Save',
      button_icon: 'fa-check',
      button_text: 'Save',
      message: '<div><b>Caution:</b> Saving will apply these changes to all configured requirements that use this requirement-template</div><br><div>If you are not ready to apply these changes to configured requirements, use "Pause" to hold your changes instead</div>',
      confirm: () => this.submit_form(requirement_form, requirement)
    }

    return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
  }
  public save_complete_callback = () =>  {
    this.getRequirement().then(() => this.onSaveCompleted.emit(this.requirement))
  }

  public submit_form = (form: any, object: any, complete_edit_state: boolean = true) => {
    this.formSubmitAttempted = true
    var valid = false;
    var errs = []
    
    if (complete_edit_state) {
      ValidationHelpers.updateTreeValidity(this.requirementFormGroup)
      valid = this.requirementFormGroup.valid
      errs = ValidationHelpers.getFormValidationErrors(this.requirementFormGroup.controls)
    } else if (!complete_edit_state) {
      errs = this.validate_form_pause_submit()
      valid = errs.length < 1
    }

    var updated_requirement = _.merge(this.requirement, this.requirementFormGroup.value)

    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will
    // be responsible for setting object to RESTING state

    var promiseChain = [
      'base_object_save_promise_alias',
      'tagging_manager_promise_alias',
      [
        'attachment_manager_promise_alias',
        'reference_manager_promise_alias',
        'note_manager_promise_alias'
      ],
      'state_manager_promise_alias'
    ]

    if (complete_edit_state) {
      promiseChain.push('version_control_completor_promise_alias')
    }

    this.processingStateService.performFunction(form, () => {
      if (valid) {
        // VALID -> SAVE
        this.modalProcessingStateServiceRef.processing_state = this.modalProcessingStateServiceRef.processing_states.PENDING
        var promise = this.complyosServices.save_relay.start(
          this.$scope,
          updated_requirement,
          form,
          'requirements',
          promiseChain,
          this.save_complete_callback
        )
        promise.finally(() => this.modalProcessingStateServiceRef.processing_state = this.modalProcessingStateServiceRef.processing_states.RESTING)
        return promise

      } else {
        // NOT-VALID, BREAK
        this.processingStateService.set(form, this.processingStateService.processing_states.RESTING)
        this.flashService.add_alert({
          name: 'requirements_failed_frontend_validation',
          dismissable: true,
          icon: 'fa-exclamation-triangle',
          class: 'alert-warning',
          strong: 'Failed Validation',
          message: 'Please review all tabs for validation issues'
        })

        return form.failed_submission = true
      }
    })
  }

  complete_edit_state = () => {
    if (this.requirementId == null || this.requirementId < 1 || this.requirement.is_edit_state != true) {
      return this.emit_completion_event(null);
    } else {
      return this.Restangular.one(`requirements/${this.requirement.id}/complete_edit_state`).get().then((response: any) => {
        this.updated_requirement = response.plain()
        this.failure = false
      }, (error_response: any) => {
        const alert = {
          name: 'commit_version_changes',
          dismissable: true,
          class: 'alert-warning',
          icon: 'fa-exclamation-triangle',
          strong: 'Error:',
          message: error_response.data[0]
        }
        this.flashService.emit_alert(this.$scope, alert)
        return this.failure = true
      }).finally(() => {
        this.emit_completion_event(this.updated_requirement)
      });
    }
  }

  /* WATCHERS, LISTENERS AND EMITTERS */
  listen_for_form_complete_edit_state_event() {
    if (this.uiSetting != 'read') {
      this.unregister_ajs_requirement_completor_save_listener = this.$scope.$on('version_control_completor_start', (event: any, data: any) => {
        // Timeout: the attachments need time to process apparently, I dont love this approach though
        setTimeout(() => this.complete_edit_state(), 1000)
      });
    }
  }

  emit_completion_event(updated_requirement: any) {
    const return_object = {
      manager: 'version_control_completor',
      object: updated_requirement,
      fault: this.failure
    }

    return this.$scope.$emit('version_control_completor_finished', return_object)
  }
}
