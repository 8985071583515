/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from 'angular';
import * as _ from 'lodash';


var complyosClient = angular.module('complyosClient')

complyosClient.controller('complyosTagsController', ['$scope', '$uibModal', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'treeManagerService', 'utils', function (
  $scope: any,
  $uibModal: any,
  confirmationModalFactory: any,
  flashService: any,
  fortyCore: any,
  loadingStateService: any,
  objectManager: any,
  Restangular: any,
  treeManagerService: any,
  utils: any
) {
  const init = function () {
    // service instantiation
    
    $scope.flashService = angular.copy(flashService)
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.processingStateService = fortyCore.processingStateService
    
    $scope.tree_manager = angular.copy(treeManagerService)

    // variable instantiation
    $scope.tag_query = { query: '' }
    $scope.allow_independent_toggle = true

    // function instantiation
    $scope.loadingStateService.init()
    return get_tags()
  }

  var get_tags = () =>
    Restangular.all('tags').getList().then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      $scope.tags = success
      $scope.flat_tags = $scope.tree_manager.flatten_tree(success)
      return $scope.tree_manager.set_show_property($scope.flat_tags)
    }
    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    })

  /* CRUD */

  $scope.openTagCreateModal = function (tags: any) {
    const new_tag_object = {}
    return openTagModal(new_tag_object, 'create', tags)
  }

  $scope.openTagReadModal = (tag_object: any, tag_list: any) =>
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(tag_object, () => openTagModal(tag_object, 'read', tag_list))

  $scope.openTagUpdateModal = (tag_object: any, tags: any) =>
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(tag_object, () => openTagModal(tag_object, 'update', tags))

  $scope.openTagDestroyModal = function (tag_object: any) {
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm () { return destroy_tag(tag_object) },
      message:
        '<p>This action will:</p>' +
        '<ul>' +
          `<li>Delete the tag ${tag_object.title}</li>` +
          `<li>Delete all descendant tags of tag ${tag_object.title}</li>` +
          `<li>Delete all taggings for tag ${tag_object.title}</li>` +
          `<li>Delete all taggings of all descendants of tag ${tag_object.title}</li>` +
        '</ul>' +
        '<p>This action is not reversible.</p>'
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    return $scope.processingStateService.performFunction(tag_object, () =>
      confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          $scope.processingStateService.set(
            tag_object,
            $scope.processingStateService.processing_states.RESTING
          )
        }
        return true
      })
    );
  }

  var destroy_tag = (tag_object: any) => // State service will set the object to a state of PENDING
  // After action is performed or cancelled,
  // that action will be responsible for setting object to RESTING state
  Restangular.one('tags', tag_object.id).remove().then((success: any) => $scope.tree_manager.remove_object_from_tree($scope.tags, tag_object)

    , function (error: any) {
      $scope.processingStateService.set(
        tag_object,
        $scope.processingStateService.processing_states.RESTING
      )
      $scope.flashService.process_error(error)
      return utils.log(error)
    })

  /* MODAL */

  var openTagModal = function (tag_object: any, ui_setting: any, tags: any) {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modals/tag_modal.html',
      controller: 'tagModalController',
      size: 'small',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            tag: angular.copy(tag_object),
            ui_setting,
            
            tags: angular.copy(tags)
          }
        }
      }
    })

    return modalInstance.result.then(function (result: any) {
      if (ui_setting === 'create') {
        $scope.tree_manager.add_object_to_tree($scope.tags, result)
        return $scope.flat_tags.push(result)
      } else if (ui_setting === 'update') {
        return $scope.tree_manager.update_object_in_tree($scope.tags, result)
      }
    }, function (reason: any) {

    }).finally(() =>
      $scope.processingStateService.set(
        tag_object,
        $scope.processingStateService.processing_states.RESTING
      )
    );
  }

  /* FILTER */

  $scope.tag_passes_filter = function (tag: any) {
    const search_string = $scope.tag_query.query.toLowerCase()
    let match = false

    if (search_string === '') {
      match = true
    }
    if (tag.title.toLowerCase().indexOf(search_string) >= 0) {
      match = true
    }

    return match
  }

  // function used for the view and allows for tranversion down through the children
  $scope.tag_filter = function (tag: any) {
    if ($scope.tag_query.query.length === 0) {
      if ($scope.allow_independent_toggle === false) {
        $scope.tree_manager.set_show_property($scope.tags)
        $scope.allow_independent_toggle = true
      }

      return true
    } else {
      var tag_or_descendent_passes_filter = function (tag: any) {
        let match = false

        if ($scope.tag_passes_filter(tag)) {
          match = true

          $scope.allow_independent_toggle = false
        }

        if ((match === false) && tag.children) {
          match = _.some(tag.children, (tag: any) => tag_or_descendent_passes_filter(tag))

          tag.show = match
        }

        return match
      } 

      return tag_or_descendent_passes_filter(tag)
    }
  }

  return init()
}])
