/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('userOrganizationModalController', ['$q', '$rootScope', '$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'Restangular', 'utils', '$uibModal', 'loadingStateService', function (
  $q: any,
  $rootScope: any,
  $scope: any,
  $uibModalInstance: any,
  flashService: any,
  fortyCore: any,
  Restangular: any,
  utils: any,
  $uibModal: any,
  loadingStateService: any
) {
  /* THINGS */
  $scope.processingStateService = fortyCore.processingStateService
  
  $scope.loadingStateService = angular.copy(loadingStateService)
  $scope.loadingStateService.init()
  const session_organization = $rootScope.session.getOrganization()
  
  $scope.flashService = angular.copy(flashService)
  $scope.organization_name = session_organization.name
  $scope.users = []
  $scope.pending_users = []
  $scope.disabled_rows = {}
  const users_to_return_to_list: any = []
  $scope.no_pending_users = false
  $scope.current_user = $rootScope.session.getUser()

  const get_all_users = function () {
    const params = {
      add_user_to_org: true
    }

    return Restangular.all('users').getList(params).then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      return $scope.users = success
    }

    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    });
  }

  /* FILTER */

  $scope.query = {
    users_query: ''
  }

  $scope.users_filter_function = function (user: any) {
    const search_string = $scope.query.users_query.toLowerCase()

    if ((user.profile.display_name.toLowerCase()).indexOf(search_string) >= 0) {
      return true
    }
    if ((user.user_role.role.title.toLowerCase()).indexOf(search_string) >= 0) {
      return true
    }
    if ((user.email.toLowerCase()).indexOf(search_string) >= 0) {
      return true
    }
    if ((user.username.toLowerCase()).indexOf(search_string) >= 0) {
      return true
    }
  }

  /* STAGE USER */

  // moves a user object to pending users
  // adds a the selected row to disabled rows, so the row becomes unavailable to select again
  $scope.move_user_to_pending = function (user: any) {
    $scope.no_pending_users = false
    $scope.disabled_rows[user.username] = true
    return $scope.pending_users.push(user)
  }

  // removes a user from the pending users list
  // removes the user from the disabled row, allows the row to be selected again
  $scope.remove_pending_user = function (user: any, index: any) {
    $scope.no_pending_users = false
    $scope.disabled_rows[user.username] = false
    return $scope.pending_users.splice(index, 1)
  }

  // essentially a fork in the road sign to determine which staging method to choose
  $scope.select_user = function (user: any, index: any) {
    // check to see if the session user can add the user they are clicking
    // if they can't we kick them out of that ability
    // else we let me pass and add the user to staging
    if (!$scope.permission_to_add_user(user)) {
      // create flash message to display to user if unavailable user
      const flash_message = {
        name: 'default_unauthorized',
        dismissable: true,
        class: 'alert-danger',
        strong: 'Unauthorized',
        message: 'You do not have sufficient privileges to add this user'
      }
      $scope.flashService.add_alert(flash_message)
    } else {
      if ($scope.disabled_rows[user.username]) {
        let current_pending_users_index = 0
        let pending_user_index = null
        while (current_pending_users_index < $scope.pending_users.length) {
          if ($scope.pending_users[current_pending_users_index].username === user.username) {
            pending_user_index = current_pending_users_index
            break
          }
          current_pending_users_index++
        }
        return $scope.remove_pending_user(user, pending_user_index)
      } else {
        return $scope.move_user_to_pending(user)
      }
    }
  }

  /* ADD USER */

  $scope.submit_form = function (form: any) {
    if (!$scope.pending_users.length) {
      $scope.no_pending_users = true
    }
    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    return $scope.processingStateService.performFunction(form, function () {
      if (form.$valid && $scope.pending_users.length) {
        return saveObject($scope.pending_users[current_pending_user])
      } else {
        $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
        return form.failed_submission = true
      }
    })
  }

  // dismiss the modal and provide a reason
  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  // close modal, and pass object back to initiator
  const closeModalAndReturn = function (added_users: any) {
    $uibModalInstance.close(added_users)
  }

  // Beginning of save process for adding a user to organization
  // if the user is found in api it calls createUserOrganization
  var saveObject = (object: any) => findUserByEmail(object.username).then(function (response: any) {
    if (response) {
      return createUserOrganization(response, session_organization)
    }
  })

  // find a user by their username
  // sets up some params with the username and then calls Restangular to find user
  var findUserByEmail = function (username: any) {
    const deferred = $q.defer()
    const username_object = {
      filter: {
        username: username
      }
    }

    Restangular.all('users/find').post(username_object).then((response: any) => deferred.resolve(response)
      , function (error: any) {
        if (error.status === 404) {
          return deferred.resolve(null)
        } else {
          deferred.reject
          $scope.flashService.process_error(error)
          return $scope.processingStateService.set($scope.user_form, $scope.processingStateService.processing_states.RESTING)
        }
      })

    return deferred.promise
  }

  // sets up params to for adding the org id to a user
  // posts to Restangular in the org route with the params.
  var current_pending_user = 0
  var createUserOrganization = function (user: any, organization: any) {
    const params = {
      user_organization: {
        user_id: user.id,
        organization_id: organization.id
      }
    }

    return Restangular.all('user_organizations').post(params).then(function (response: any) {
      users_to_return_to_list.push(user)
      current_pending_user++
      if (current_pending_user < $scope.pending_users.length) {
        return saveObject($scope.pending_users[current_pending_user])
      } else {
        return closeModalAndReturn(users_to_return_to_list)
      }
    }
    , function (error: any) {
      $scope.flashService.process_error(error)
      return $scope.processingStateService.set($scope.user_form, $scope.processingStateService.processing_states.RESTING)
    });
  }

  $scope.create_new_user = () => closeModalAndReturn('create')

  /* ROLE CHECK */

  // checks if the current_user can add the user_in_list to a organization
  // if FN returns true the user can be added
  $scope.permission_to_add_user = function (user_in_list: any) {
    if ((user_in_list.user_role.role.orgs_access === 'all') && (user_in_list.user_role.role.assignable === false)) {
      return false
    }

    if (user_in_list.user_role.role.orgs_access === 'none') {
      return false
    } else if (
      // the current_user does not have all orgs_access AND
      //@ts-ignore
      (!$scope.current_user.user_role.role.orgs_access === 'all') &&
      // the current_user role weight is less than that of the user_in_list
      ($scope.current_user.user_role.role.weight < user_in_list.user_role.role.weight)
    ) {
      return false
    }

    // revoke permission to add, if the user is locked, inactive, or archived
    // user_in_list.state.locked ||
    if (user_in_list.state.inactive || user_in_list.state.archived) {
      return false
    } else { // otherwise the user in list should be able to be added
      return true
    }
  }

  /* INIT */

  return get_all_users()
}])
