import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BinderService } from '../../../services/broadcast-services/binder.service';
var dayjs = require('dayjs')

@Component({
  selector: 'ngBinderSnapshot',
  templateUrl: './binder-snapshot.component.html'
})
export class BinderSnapshotComponent implements OnInit, OnDestroy {
  // Injected Services
  private loadingStateService: any;

  // Properties
  private binderSnapshot: any;

  // subscriptions
  private binderApiCallSubscription: Subscription;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$state') private $state,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private route: ActivatedRoute,
    private binderService: BinderService
  ) {
    this.loadingStateService = angular.copy(loadingStateService)
    this.loadingStateService.init()
  }

  ngOnInit(): void {
    // handle the binderObject data from binder.service
    this.binderApiCallSubscription = this.binderService.binderApiCallTrigger$.subscribe(() => this.getBinderSnapshot())
    this.getBinderSnapshot()
  }

  getBinderSnapshot = () => {
    let query_params = {
      model: 'Binder',
      id: this.$state.params.binder_id,
      method: 'snapshot',
      param_data: {
        binder_id: this.$state.params.binder_id,
        date_range_begin: dayjs().subtract(3, 'years').$d,
        date_range_end: dayjs().$d
      }
    }
    this.loadingStateService.set_state('loading')
    return this.Restangular.one('analytics/gather').get(query_params).then((response: any) => {
      this.loadingStateService.process_success(response)
      return this.binderSnapshot = response
    }
    , (error: any) => this.loadingStateService.process_error(error));
  }

  ngOnDestroy() {
    if (this.binderApiCallSubscription) {
      this.binderApiCallSubscription.unsubscribe()
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngBinderSnapshot', downgradeComponent({ component: BinderSnapshotComponent }) as angular.IDirectiveFactory)
