import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'angulareditor',
  template: `<angular-editor (input)="onInput($event)" [config]="editorConfig" [(ngModel)]="textAreaContent" [placeholder]="placeholder"></angular-editor>`
})
export class AngularEditor implements OnInit, OnChanges {
  @Input() textAreaContent: string | undefined
  @Input() passTextContent: string | undefined
  @Input() setting!: string
  @Input() placeholder!: string | undefined
  @Input() model: any
  @Output() onTextEdit = new EventEmitter<string>()

  // getting data from requirements modal
  ngOnInit() {
    this.updateEditorConfig()
  }
  
  ngOnChanges(changes: any) {
    if(changes.passTextContent) {
      this.textAreaContent = changes.passTextContent.currentValue
    }
  }
  
  // passing data to requirements modal
  onInput(event: any) {
    this.onTextEdit.emit(this.textAreaContent)
  }
  
  updateEditorConfig() {
    this.editorConfig.editable = this.setting === 'read' ? false : true
    this.editorConfig.showToolbar = this.setting === 'read' ? false : true
  }

  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    showToolbar: false,
    height: '20rem',
    minHeight: '10rem',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'heading',
        'fontName',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'undo',
        'redo'
      ],
      [
        'fontSize',
        'backgroundColor',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
  }
}
