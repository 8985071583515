/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
var dayjs = require("dayjs")

const FilterOperations = [{ displayName: 'Equals', value: 'Equals' },
  { displayName: 'Not Equals', value: 'NotEquals' },
  { displayName: 'Contains', value: 'Contains' }
]


angular.module('complyosClient').directive('filter', [
  'fortyCore', 'constants', 'arrayFunctions', 'helpService', 'storeService', '$rootScope', 'filterService',
  (
    fortyCore,
    constants,
    arrayFunctions,
    helpService,
    storeService: any,
    $rootScope,
    filterService
  ) => ({
    templateUrl: 'views/directives/filter.html',
    restrict: 'E',

    scope: {
      disableFiltersConfig: '=',
      filterChangedCallback: '&',
      filterConfigs: '=',
      presetFilters: '<',
      loadingState: '=',
      allowLocking: '=',
      hideClearFiltersButton: '=',
      untouchedPresets: '<',
      defaultFilterConfig: '=',
      debounceTime: '=',
      searchHitsPlaceholder: '='
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)
      $scope.activeOrgIsPresent = $rootScope.session.activeOrganizationPresent()
      $scope.lastSurveyDate = undefined
      $scope.isFilterLocked = $rootScope.session.data.isFilterLocked
      $scope.enums = constants;
      $scope.filter = {
        filterOperations: FilterOperations,
        appliedFilters: [],
        currentFieldConfig: null,
        currentFilter: {},
        selectedOperation: null,
        selectedOption: null,
        selectedMultiOptions: [],
        selectedNodePath: [],
        filterTextSearchOutput: {}
      }
      $scope.date = {
        customRangeOpen: false,
        date_range_end: null,
        date_range_begin: null
      }
      $scope.presets = [
        { name: '6 mo', displayName: '6 months', tooltip: 'last 6 months', range: { date_range_begin: dayjs().subtract(6, 'months').$d, date_range_end: dayjs().utc().endOf('day').$d } },
        { name: '1 yr', displayName: '1 year', tooltip: 'last year', range: { date_range_begin: dayjs().subtract(1, 'years').$d, date_range_end: dayjs().utc().endOf('day').$d } },
        { name: '3 yr', displayName: '3 years', tooltip: 'last 3 years', range: { date_range_begin: dayjs().subtract(3, 'years').$d, date_range_end: dayjs().utc().endOf('day').$d } }
      ]

      if ($scope.presetFilters) {
        $scope.filter.appliedFilters = $scope.presetFilters;
        $scope.processFilterPills()
      }

      // $scope.selectFilterConfig(0)
      $scope.applyDefaultFilterConfig()
      $scope.checkForSurveyDate()

      $scope.$on('session_updated', () => {
        $scope.checkForSurveyDate()
      })

      $scope.$on('filtersLoaded-dashboard', function (event: any, presetFilters: any) {
        $scope.filter.appliedFilters = presetFilters;
        $scope.processFilterPills()
      })

      $scope.$on('lockFilter', function (e: any) {
        $scope.lockFilter()
      })

      $scope.$on('push-new-filter', function (event: any, newFilter: any) {
        let matchFn
        if (newFilter.isUnique) {
          matchFn = (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique)
        } else {
          matchFn = (existing: any, update: any) => (existing.Field === update.Field && existing.Value == update.Value)
        }
        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwrite($scope.filter.appliedFilters, newFilter, matchFn)
        $scope.processFilterPills()
      })

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.selectFilterConfig = (position: any) => {
        let filterConfig = $scope.filterConfigs.find((fc: any) => fc.position == position)
        if (filterConfig) {
          filterConfig.ticked = true;
          $scope.filter.currentFieldConfig = [filterConfig]
          $scope.handleFilterConfigChange()
        }
      }

      $scope.checkForSurveyDate = () => {
        $scope.activeOrgIsPresent = $rootScope.session.activeOrganizationPresent()
        if($scope.activeOrgIsPresent) {
          let activeOrg = $rootScope.session.getOrganization()
          $scope.lastSurveyDate = activeOrg.date_survey_last ? dayjs(activeOrg.date_survey_last) : undefined
        } else {
          $scope.lastSurveyDate = undefined
        }
      }

      $scope.applyDefaultFilterConfig = () => {
        if ($scope.defaultFilterConfig) {
          let defaultConfig = $scope.filterConfigs.find((fc: any) => fc.fieldValue === $scope.defaultFilterConfig)
          $scope.selectFilterConfig(defaultConfig.position)
        } else {
          $scope.selectFilterConfig(0)
        }
        $scope.handleFilterConfigChange()
      }

      $scope.handleFilterConfigChange = () => {
        const selectedFilterConfig = Array.isArray($scope.filter.currentFieldConfig) && $scope.filter.currentFieldConfig.length > 0 ? $scope.filter.currentFieldConfig[0] : null;
        if (selectedFilterConfig === null) {
          $scope.filter.currentFilter = {}
        } else {
          $scope.filter.currentFilter.isUnique = selectedFilterConfig.isUnique;
          $scope.filter.currentFilter.isRequired = selectedFilterConfig.isRequired;
          $scope.filter.currentFilter.displayName = selectedFilterConfig.fieldDisplayName;
          $scope.filter.currentFilter.Field = selectedFilterConfig.fieldValue;
          $scope.filter.selectedMultiOptions = []

          if (selectedFilterConfig.type > 1) {
            var operation;
            switch (selectedFilterConfig.type) {
              case constants.FilterType.Radio:
              case constants.FilterType.Select:
              case constants.FilterType.NodeTreeBuilder:
                operation = 'Equals'
                break;
              case constants.FilterType.Multi:
              case constants.FilterType.MultiAPISearch:
                operation = 'Multi'
                break;
              case constants.FilterType.Date:
                operation = 'Date'
                break;
              case constants.FilterType.CustomSearch:
                operation = 'CustomSearch'
                break;
              default:
                operation = null;
            }

            $scope.filter.currentFilter.Op = operation;
          }
        }
      };

      $scope.handleFilterOperationChange = (operation: any) => {
        $scope.filter.selectedOperation = operation;
        $scope.filter.currentFilter.Op = operation.value != null ? operation.value : null;
      }

      $scope.handleFilterInputChange = (e: any) => {
        e.persist();
        $scope.filter.currentFilter.displayValue = e.target.value;
        $scope.filter.currentFilter.Value = e.target.value;
      }

      $scope.handleSelectOptionChange = () => {
        const selectOption = $scope.filter.selectedOption[0];
        var optsText = selectOption.displayName;
        var optsValue = selectOption.value;
        $scope.filter.currentFilter.displayValue = optsText != null ? optsText : null;
        $scope.filter.currentFilter.Value = optsValue != null ? optsValue : null;
      }

      $scope.handleMultiOptionChange = () => {
        const selectedMultiOptions = $scope.filter.selectedMultiOptions
        const filterConfig = $scope.filter.currentFieldConfig[0]
        var selectedFilters = selectedMultiOptions.map((f: any) => {
          var pill :any = { displayValue: f.displayName, Value: f.value }
          if (filterConfig.enableDescendantChecking && f.ticked == 'CheckedWithDescendants') {
            pill.includeDescendants = true
            pill.descendantsName = filterConfig.descendantsName || 'descendants'
          }
          return (pill)
        })
        $scope.filter.currentFilter.items = selectedFilters
      }

      $scope.handleFilterTextSearchChange = () => {
        const filterTextSearchOutput = $scope.filter.filterTextSearchOutput;
        var optsText = filterTextSearchOutput.searchString
        var optsValue = filterTextSearchOutput.matches
        $scope.filter.currentFilter.displayValue = optsText != null ? optsText : null;
        $scope.filter.currentFilter.Value = optsValue != null ? optsValue : null;
      }

      $scope.handleNodePathBuilderChange = () => {
        const nodePath = $scope.filter.selectedNodePath;
        var optsText = nodePath.map((n: any) => n.identifier).join(' ');
        var optsValue = null
        if (nodePath.length > 0) {
          optsValue = nodePath[nodePath.length - 1].id
        }
        $scope.filter.currentFilter.includeDescendants = true
        $scope.filter.currentFilter.displayValue = optsText != null ? optsText : null;
        $scope.filter.currentFilter.Value = optsValue != null ? optsValue : null;
      }

      $scope.applyFilter = ($event: any) => {
        !$event || $event.preventDefault()
        var filters = []
        var newFilter :any = {}
        Object.assign(newFilter, $scope.filter.currentFilter);
        let isMulti = newFilter.Op === 'Multi'
        let isSelect = newFilter.Op === 'Equals'

        if (newFilter === null
          || newFilter === undefined
          || $scope.isEmpty(newFilter.Field)
          || $scope.isEmpty(newFilter.Op)
          || $scope.isEmpty(newFilter.displayName)
          || (isSelect && (!newFilter.displayValue || !newFilter.Value))
          || (!isMulti && $scope.isEmpty(newFilter.displayValue))
          || (isMulti && (newFilter.items === undefined
            || newFilter.items.length < 1))) {
          alert('Filter is not complete, please set all fields.')
          return
        } else if (isMulti) {
          filters = newFilter.items.map((f: any) => {
            let pill :any = {
              isUnique: newFilter.isUnique,
              isRequired: newFilter.isRequired,
              Field: newFilter.Field,
              Op: newFilter.Op,
              displayName: newFilter.displayName,
              displayValue: f.displayValue,
              Value: f.Value,
              dateCreated: dayjs().$d,
              isLocked: newFilter.isLocked
            }
            if (f.includeDescendants) {
              pill.includeDescendants = f.includeDescendants
              pill.descendantsName = f.descendantsName
            }
            return pill
          });
        } else if (isSelect) {
          filters =
            [{
              isUnique: newFilter.isUnique,
              isRequired: newFilter.isRequired,
              Field: newFilter.Field,
              Op: newFilter.Op,
              displayName: newFilter.displayName,
              displayValue: newFilter.displayValue,
              Value: newFilter.Value,
              dateCreated: dayjs().$d,
              isLocked: newFilter.isLocked
            }]
          if (newFilter.includeDescendants) {
            filters[0].includeDescendants = newFilter.includeDescendants
          }
        } else {
          filters =
            [{
              isUnique: newFilter.isUnique,
              isRequired: newFilter.isRequired,
              Field: newFilter.Field,
              Op: newFilter.Op,
              displayName: newFilter.displayName,
              displayValue: newFilter.displayValue,
              Value: newFilter.Value,
              dateCreated: dayjs().$d,
              isLocked: newFilter.isLocked
            }]
        }

        let matchFn
        if (newFilter.isUnique) {
          matchFn = (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique)
        } else {
          matchFn = (existing: any, update: any) => (existing.Field === update.Field && existing.Value == update.Value)
        }
        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwriteList($scope.filter.appliedFilters, filters, matchFn)
        $scope.resetFilterBuilder()
        $scope.processFilterPills()
      }

      $scope.resetFilterBuilder = () => {
        $scope.filter.currentFieldConfig = []
        $scope.filter.selectedOperation = null
        $scope.filter.currentFilter = {}
        $scope.filter.selectedOption = null
        $scope.filter.selectedMultiOptions = []
        $scope.filter.selectedNodePath = []
        $scope.filter.filterTextSearchOutput = {}
        $scope.untickAll($scope.filterConfigs)
        $rootScope.$broadcast('filterReset')

        // $scope.selectFilterConfig(0)
        $scope.applyDefaultFilterConfig()
      }

      $scope.processFilterPills = () => {
        $scope.applyPresets();
        $scope.updatePresetDatePills();
        $scope.sortFilters();
        let pills = JSON.parse(JSON.stringify($scope.filter.appliedFilters))
        $scope.filterChangedCallback({ appliedFilters: pills })
      }
      $scope.processFilterPillsDebounced = _.debounce(() => $scope.processFilterPills(), $scope.debounceTime)

      $scope.untickAll = (options: any) => {
        options = options.map((opt: any) => {
          opt.ticked = false;
          return opt;
        });
      }

      $scope.isEmpty = (field: any) => {
        if (field === null || field === undefined || field.length < 1) {
          return true
        } else {
          return false;
        }
      }

      $scope.removeFilter = (index: any) => {
        $scope.filter.appliedFilters.splice(index, 1)
        $scope.processFilterPillsDebounced()
      }

      // if any presets were removed, reapply to their default state
      $scope.applyPresets = () => {
        $scope.untouchedPresets.forEach((p: any) => {
          let result = $scope.filter.appliedFilters.filter((a: any) => a.Field === p.Field)
          if (result.length === 0) {
            $scope.filter.appliedFilters.push(p)
          }
        })
      }

      $scope.reload_cached_organizations = () => {
        storeService.getOrganizations()
      }

      $scope.clearAllFilters = () => {
        // set applied filters to empty array
        // processFilterPills will pop the presets back in there
        $scope.filter.appliedFilters = []
        $scope.resetFilterBuilder()
        $scope.processFilterPills()
      }

      $scope.lockFilter = () => {
        // lock all filters
        $scope.filter.appliedFilters.map((a: any) => { a.isLocked = !$scope.isFilterLocked })
        $rootScope.session.data.isFilterLocked = !$scope.isFilterLocked
        $scope.isFilterLocked = !$scope.isFilterLocked
        // do all the magic to update the cache
        $scope.resetFilterBuilder()
        $scope.processFilterPills()
      }

      $scope.toggleCustomDateRangeInput = () => {
        $scope.date.customRangeOpen = !$scope.date.customRangeOpen;
      }

      $scope.sortFilters = () => {
        $scope.filter.appliedFilters.sort(function (x: any, y: any) {
          let localOrder = 0
          localOrder = $scope.nullishCoalesce(y.isUnique, false) - $scope.nullishCoalesce(x.isUnique, false)
          if (localOrder === 0) {
            localOrder = x.displayName.localeCompare(y.displayName)
          }
          return localOrder
        });
      }
      $scope.updatePresetDatePills = () => {
        if (!$scope.filter.currentFieldConfig?.[0]?.list) {
          return
        }

        var datePills = $scope.filter.appliedFilters.filter((filter: any) => filter.Field == 'date' || filter.Field == 'review')
        datePills.forEach((pill: any) => {
          let presetMatch = $scope.filter.currentFieldConfig[0].list.find((preset: any) => preset.displayName == pill.displayValue)
          if (presetMatch) {
            pill.Value = { date_range_begin: presetMatch.range.date_range_begin, date_range_end: presetMatch.range.date_range_end }
          }
        })
      }

      $scope.applyRadioFilter = (preset: any) => {
        var newFilter :any= {};
        Object.assign(newFilter, $scope.filter.currentFilter);

        var visibilityFilter :any= {
          isUnique: newFilter.isUnique,
          isRequired: newFilter.isRequired,
          Field: newFilter.Field,
          Op: newFilter.Op,
          displayName: 'Visibility',
          displayValue: preset.displayName,
          Value: preset.value,
          dateCreated: dayjs().$d,
          isLocked: newFilter.isLocked
        }

        if (preset.overrideFieldValue) {
          visibilityFilter.Field = preset.overrideFieldValue
        }

        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwrite($scope.filter.appliedFilters, visibilityFilter, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
        $scope.processFilterPills()

        $scope.visibility = null;
        $scope.resetFilterBuilder()
      }

      $scope.applyDateRange = (preset: any) => {
        let begin = preset.range.date_range_begin
        let end = preset.range.date_range_end

        var newFilter :any= {};
        Object.assign(newFilter, $scope.filter.currentFilter);

        var dateFilter = {
          isUnique: newFilter.isUnique,
          isRequired: newFilter.isRequired,
          Field: newFilter.Field,
          Op: newFilter.Op,
          displayName: $scope.filter.currentFilter.Field == 'review_window' ? 'Review Window' : newFilter.displayName,
          displayValue: preset.displayName,
          Value: { date_range_begin: begin, date_range_end: end },
          dateCreated: dayjs().$d,
          isLocked: newFilter.isLocked
        }

        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwrite($scope.filter.appliedFilters, dateFilter, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
        $scope.processFilterPills()

        $scope.date.date_range_begin = null;
        $scope.date.date_range_end = null;
        $scope.resetFilterBuilder()
      }
      $scope.applyCustomDateRange = () => {
        let begin = dayjs($scope.date.date_range_begin)
        let end = dayjs($scope.date.date_range_end)

        if (!begin || !end) {
          alert('Custom date filter is incomplete, please set both a start and end date');
          return
        }

        var newFilter :any= {};
        Object.assign(newFilter, $scope.filter.currentFilter);

        var dateString = begin.format('MM/DD/YYYY') + ' - ' + end.format('MM/DD/YYYY');
        var dateFilter :any = {
          isUnique: newFilter.isUnique,
          isRequired: newFilter.isRequired,
          Field: newFilter.Field,
          Op: newFilter.Op,
          displayName: newFilter.displayName,
          displayValue: dateString,
          Value: { date_range_begin: begin.$d, date_range_end: end.utc().endOf('day').$d },
          dateCreated: dayjs.$d,
          isLocked: newFilter.isLocked
        }

        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwrite($scope.filter.appliedFilters, dateFilter, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
        $scope.processFilterPills()

        $scope.date.date_range_begin = null;
        $scope.date.date_range_end = null;
        $scope.toggleCustomDateRangeInput();
        $scope.resetFilterBuilder()
      }

      $scope.applyLastSurveyDateInput = () => {
        let today = dayjs()

        var newFilter :any= {};
        Object.assign(newFilter, $scope.filter.currentFilter);

        var dateString = $scope.lastSurveyDate.format('MM/DD/YYYY') + ' - ' + today.format('MM/DD/YYYY');
        var dateFilter :any = {
          isUnique: newFilter.isUnique,
          isRequired: newFilter.isRequired,
          Field: newFilter.Field,
          Op: newFilter.Op,
          displayName: 'Last Survey Date',
          displayValue: dateString,
          Value: { date_range_begin: $scope.lastSurveyDate.$d, date_range_end: today.utc().endOf('day').$d },
          dateCreated: dayjs.$d,
          isLocked: newFilter.isLocked
        }
        $scope.filter.appliedFilters = arrayFunctions.pushAndOverwrite($scope.filter.appliedFilters, dateFilter, (existing: any, update: any) => (existing.Field === update.Field && existing.isUnique && update.isUnique))
        $scope.processFilterPills()
        $scope.resetFilterBuilder()
      }

      $scope.isFilterIgnored = (filterField: any) => {
        if (Array.isArray($scope.disableFiltersConfig) && $scope.disableFiltersConfig.length > 0) {
          var activeFilter = $scope.filterConfigs.find(fc => fc.fieldValue == filterField) != null
          let isIgnored = $scope.disableFiltersConfig.includes(filterField) && !activeFilter
          return isIgnored
        }
        return false
      }

      $scope.nullishCoalesce = (check: any, defaultTo: any) => {
        if (check !== null && check != undefined) {
          return check
        }
        return defaultTo
      }

      $scope.activeDateRange = (fieldName: any) => {
        let match = null;
        var dateFilter = $scope.filter.appliedFilters.find((f: any) => f.Field == fieldName)
        if (dateFilter) {
          match = 'CUSTOM'
          let displayedValue = dateFilter.displayValue
          let nameMatch = $scope.filter.currentFieldConfig[0].list.find((p: any) => p.displayName == displayedValue)
          if (nameMatch) {
            match = nameMatch.displayName
          } else if(dateFilter.displayName == 'Last Survey Date') {
            match = 'SURVEY'
          }
        }
        return match
      }

      $scope.getActiveRadio = (fieldName: any) => {
        let match = null;
        var radioFilter = $scope.filter.appliedFilters.find((f: any) => f.Field == fieldName)
        if (radioFilter) {
          match = 'CUSTOM'
          let displayedValue = radioFilter.displayValue
          let nameMatch = $scope.filter.currentFieldConfig[0].list.find((p: any) => p.displayName == displayedValue)
          if (nameMatch) {
            match = nameMatch.displayName
          }
        }
        return match
      }

      $scope.isFilterSelectPartOfGroup = () => {
        let optionIsSelected = !($scope.filter.currentFieldConfig == null || $scope.filter.currentFieldConfig.length == 0)
        let exludedOptions = [$scope.enums.FilterType.Date, $scope.enums.FilterType.Radio]
        let optionInExcludedOptions = $scope.filter.currentFieldConfig != null && $scope.filter.currentFieldConfig.length > 0 && exludedOptions.includes($scope.filter.currentFieldConfig[0].type)

        let res = optionIsSelected && !optionInExcludedOptions
        return res;
      }

      $scope.openFilterTypeSelector = () => {
        setTimeout(() => {
          let isOpen = $('#filter .checkboxLayer.show').length > 0
          if (!isOpen) {
            $('#filter button')[0].click()
          }
        }, 20);
      }
      $scope.closeFilterTypeSelector = () => {
        setTimeout(() => {
          let isOpen = $('#filter .checkboxLayer.show').length > 0
          if (isOpen) {
            $('#filter button')[0].click()
          }
        }, 200);
      }

      return $scope
    }
  })

])
