// /* eslint-disable
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('applicationMainController', ['ENV', '$rootScope', '$scope', function (
//   ENV: any,
//   $rootScope: any,
//   $scope: any
// ) {
//   const init = () => {
//     // $scope.welcome = 'welcome user'

//     $scope.ssoAuthenticationButtons = [
//       {
//         title: 'Login: Single Sign On',
//         class: 'btn-primary',
//         visible: () => {
//           return (
//             !$rootScope.session.activeUserPresent() &&
//             $rootScope.session.data.sso_domained
//           )
//         },
//         action: () => {
//           $rootScope.session.goTo('/user_services/sso')
//         }
//       }
//     ]
//     $scope.localAuthenticationButtons = [
//       {
//         title: 'Login: Username/Password',
//         class: 'btn-link',
//         visible: () => {
//           return (
//             !$rootScope.session.activeUserPresent() &&
//             ENV.NAME !== 'staging' &&
//             ENV.NAME !== 'production'
//           )
//         },
//         action: () => {
//           $rootScope.session.goTo('/user_services/login')
//         }
//       }
//     ]
//   }

//   return init()
// }])
