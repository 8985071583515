/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('communicationsModalController', ['$scope', '$uibModalInstance', 'complyosServices', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'utils', '$rootScope', function (
  $scope: any,
  $uibModalInstance: any,
  complyosServices: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any,
  utils: any,
  $rootScope: any,
) {
  // HEY Yo dog. listen up this modal is different yo.
  // normally we have notes that are attached to a base object. ex: Entry or Requirement.
  // but we decided to mix it up hard. we are using notes that are not attached to anything.
  // - WOW right? -
  // to make this happen with out having to do a bunch of re-development
  // (and because we are running out of time in this sprit) we are going to still use the note directive
  // to do this we need to provide some base object for the manager to attach to in a familar and tested way.
  // so we fake a base object hard. see empty_base_object.
  // in the save relay, we do not call base_object_save, and we do not need to pass in a api route for the base object.
  // take a look at the save relay code, Its good stuff and it would be helpful to know when it comes to debugging

  const init = function () {
    $scope.processingStateService = fortyCore.processingStateService

    $scope.flashService = angular.copy(flashService)
    $scope.flashService.listen_for_alert($scope)

    $scope.ui_setting = modalOptions.ui_setting
    $scope.communicationId = modalOptions.communicationId

    $scope.assignNotes()
    loadCommunication()

    return $scope.empty_base_object
  }

  $scope.submit_form = (form: any) => $scope.processingStateService.performFunction(form, function () {
    if (form.$valid) {
      return complyosServices.save_relay.start(
        $scope,
        $scope.empty_base_object,
        form,
        'not used string representing the api route of the base object',
        ['note_manager_promise_alias', 'tagging_manager_promise_alias'], // note the lack of base_object_save
        closeModalAndReturn
        // ['base_object_save_promise_alias', 'tagging_manager_promise_alias'], // note the lack of base_object_save
      )

    } else {
      $scope.processingStateService.set(
        form,
        $scope.processingStateService.processing_states.RESTING
      )
      return form.failed_submission = true
    }
  })

  $scope.assignNotes = () => {
    if(modalOptions.communicationText !== null) {
        $scope.empty_base_object = {
          notes: [modalOptions.communicationText]
        }
        $scope.notes_validation_model = modalOptions.communicationText
    } else {
      $scope.empty_base_object = {
        notes: []
      }
      $scope.notes_validation_model = ""
    }
  }

  var loadCommunication = function () {
    if ($scope.ui_setting === 'create') {
      return
    }

    // const params = {
    //   id: $scope.communicationId
    //   // type: 'Feed::Communication'
    // }

    return Restangular.one('notes', $scope.communicationId).get().then(function (success: any) {
      let note = success.plain()
      note.old_text = note.text
      $scope.empty_base_object.notes = [note]
      return $scope.communications = success
    }, function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    });

    // -----------------------
    // id = $scope.communicationId

    // return Restangular.all('reference_objects').get(id).then(function (success) {
    //   $scope.reference_object = success.plain()
    //   // bind the aspect to the referenece_object so that the header automatically updates
    //   return $scope.reference_object[$scope.parent_node.depth] = $scope.aspect
    // }, error => console.log(error))
  }

  var closeModalAndReturn = () => $uibModalInstance.close($scope.empty_base_object.notes[0])

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  return init()
}])
