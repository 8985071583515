import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngHome',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  // Properties
  private user: any;

  constructor(
    @Inject('$rootScope') private $rootScope
  ) {
  }

  ngOnInit(): void {
    this.user = this.$rootScope.session.getUser()
    this.waitForHappy()
  }

  waitForHappy = () => {
    this.$rootScope.$watch(() => this.$rootScope.session, (new_value: any, old_value: any, scope: any) => {
      if (new_value.data && new_value.data.happy) {
        this.organizationPreflightcheck()
        this.tagsPreflightCheck()
      }
    }, true)
  }

  organizationPreflightcheck = () => {
    if (this.user.user_role.role.orgs_access === 'some') {
      if (this.user.organizations.length === 0) {
        this.$rootScope.$broadcast(
          'add_global_alert',
          {
            name: 'organization_preflight_error',
            dismissable: true,
            class: 'alert-warning',
            strong: null,
            message: 'Currently, you are not added to any organizations. ' +
                          'If you believe this to be an error, ' +
                          'contact your Organization Admin for a resolution.'
          }
        )
      }
    }
  }

  tagsPreflightCheck = () => {
    if (
      (this.user.user_role.role.tags_access === 'some') &&
      (this.user.taggings.length === 0)
    ) {
      return this.$rootScope.$broadcast(
        'add_global_alert',
        {
          name: 'tags_preflight_error',
          dismissable: true,
          class: 'alert-warning',
          strong: 'Alert',
          message: 'Currently, you do not have any tags. ' +
                        'If you believe this to be an error, ' +
                        'contact your Organization Admin for a resolution.'
        }
      )
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngHome', downgradeComponent({ component: HomeComponent }) as angular.IDirectiveFactory)
