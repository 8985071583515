import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../../scripts/services/ajs-confirmation_modal_factory';
import { objectManager } from '../../../../scripts/services/ajs-object_manager';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import { FlashService } from '../../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import * as FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { BinderModalComponent } from '../../modals/binder-modal/binder-modal.component';
var dayjs = require("dayjs")

@Component({
  selector: 'ngBindersList',
  templateUrl: './binders-list.component.html',
  styleUrls: ['./binders-list.component.scss']
})
export class BindersListComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private previousPills: Array<any> = [];
  private outstandingPromises: Array<any> = [];
  private binders: Array<any> = [];
  private bindersNeedStartDateCount = 0;
  private binderCount: number = 0;
  private userCanClickBulkButton: boolean = false;
  private bindersLoaded: boolean = false;


  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    // complyosServices: any,   // <--- does not seem to be needed
    // ENV: any,                // <--- does not seem to be needed
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(objectManager) private objectManager: objectManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private arrayFunctions: ArrayFunctions,
    private dialog: MatDialog
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      nocontent: {
        icon: 'fa-exclamation-triangle',
        text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()

    this.watchEntityFilter()
  }

  displayPairingKeyIcon = (binder: any) => {
    if (
      (
        binder.requirement.managed_by_paired_application === true
        // binder.schedule_enabled === false ||
        // binder.requirement.schedule_state === 'schedule_optional' ||
        // binder.requirement.schedule_state === 'schedule_disabled'
      ) &&
      !binder.archived &&
      binder.requirement.compliance_method === 'Engineering Central' // TODO: this will have to be romoved if we pair with a second application
    ) {
      return true
    } else {
      return false
    }
  }

  // sort by method will take sort type and pass to api as a filter pill to be sorted and returned
  sortBy = (sort_method: any) => {
    console.log(sort_method)
  }

  hasUserAppliedNotRequiredPills = (pills: any)  => {
    this.userCanClickBulkButton = pills.some((p: any) => p.isRequired === false || p.isRequired === undefined)
  }

  /* CRUD */

  getBinders = (pills: any) => {
    this.bindersLoaded = false
    let query_params = {
      param_data: {}
    }
    this.loadingStateService.set_state('loading')
    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )
    query_params.param_data['sorted'] = ['name']
    return this.Restangular.one('binders').get(query_params).then((success: any) => {
        this.loadingStateService.process_success(success.items)
        this.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        this.binders = success.items
        this.binderCount = success.pagination_info.totalCount
        this.bindersLoaded = true
      }, (error: any) => {
        this.loadingStateService.process_error(error)
        this.utils.log(error, 'error', null)
      }
    )
  }

  getNeedsStartDateCount = (pills: any) => {
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }
    return this.Restangular.one(`binders/needs_start_date_count`).get(query_params).then((success: any) => {
        let result = success.plain()
        this.bindersNeedStartDateCount = result.record_count
      }, (error: any) => {
        this.loadingStateService.process_error(error)
        this.utils.log(error, 'error', null)
      }
    )
  }

  /* MODALS */

  openBinderReadModal = (binder_object: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(binder_object, () => this.openBinderModal(binder_object, 'read'))
  }

  openBinderUpdateModal = (binder_object: any) => {
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    this.processingStateService.performFunction(binder_object, () => this.openBinderModal(binder_object, 'update'))
  }

  openBinderModal = (binderObject, uiSetting) => {
    const dialogRef = this.dialog.open(BinderModalComponent, {
      width: '600px',
      data: { binderObject: binderObject, uiSetting: uiSetting }
    })
    dialogRef.beforeClosed().subscribe(result => {
      if (result === undefined) {
        // Handle clicking outside the modal to close
        return dialogRef.close('outsideClick')
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'close') {
        this.processingStateService.set(binderObject, this.processingStateService.processing_states.RESTING)
        return
      }
      if (uiSetting === 'create') {
        this.objectManager.array_action(this.binders, result, 'merge')
      } else if (uiSetting === 'update') {
        this.objectManager.array_action(this.binders, result, 'update')
      }
      this.processingStateService.set(binderObject, this.processingStateService.processing_states.RESTING)
    })
  }
  // https://angular-ui.github.io/bootstrap/#/modal
  // openBinderModal = (binder_object: any, ui_setting: any) => {
  //   const modalInstance = this.$uibModal.open({
  //     templateUrl: 'views/modals/binder_modal.html',
  //     controller: 'binderModalController',
  //     size: 'small',
  //     resolve: {
  //       // this is the object that can be passed to the modal.
  //       // we copy it to eliminate binding issues
  //       // require it in the dependecies as modalOptions
  //       modalOptions () {
  //         return {
            
  //           object: angular.copy(binder_object),
  //           ui_setting
  //         }
  //       }
  //     }
  //   })

  //   // on close
  //   // - grab the returned result,
  //   // - or log out a reason
  //   // - finally, sets the object back to a resting state
  //   return modalInstance.result.then((result: any) => {
  //     if (ui_setting === 'create') {
  //       this.objectManager.array_action(this.binders, result, 'merge')
  //     }
  //     if (ui_setting === 'update') {
  //       return this.objectManager.array_action(this.binders, result, 'update')
  //     }
  //   }, function (reason: any) {

  //   }).finally(() =>
  //     this.processingStateService.set(
  //       binder_object,
  //       this.processingStateService.processing_states.RESTING
  //     )
  //   );
  // }

  navigateToManagementUrl = function (binder: any) {
    window.open(
      binder.mailbox_pairing_management_url,
      '_blank'
    )
  }

  pushStartDateNeededPill = () => {
    let startDateNeededPill = {
      isUnique: true,
      Field: 'schedule_generation_state',
      Op: 'Equals',
      displayName: 'Schedule State',
      displayValue: 'Start Date Needed',
      Value: 'start_date_needed',
      dateCreated: dayjs().$d
    }
    this.$rootScope.$broadcast('push-new-filter', startDateNeededPill)
  }

  getMailboxPairingTooltip = (binder) => {
    return binder.mailbox_pairing_configured ? 'Paired with Engineering Central' : 'Should be Paired with Engineering Central'
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        this.abortAllPromises(this.outstandingPromises)
        this.hasUserAppliedNotRequiredPills(pills)
        setTimeout(() => this.getBinders(pills), 50)
        
        setTimeout(() => this.getNeedsStartDateCount(angular.copy(pills)), 50)
      }
    }
    this.$scope.$on('filterChange-dashboard', refresh)
  }

  /* BULK CONFIGURE */
  openBulkArchiveRequirementsModal = () => {
    let unpaged_pills = this.previousPills.filter((pills: any) => (pills.Field !== 'pagination_params'))
    let binderCount = this.binderCount
    let items = ['assign', unpaged_pills, binderCount]
    const modalInstance = this.$uibModal.open({
      component: 'bulkConfigureRequirements',
      size: 'xl',
      resolve: {
        items: function () {
          return items
        }
      }
    })

    return modalInstance.result.then((params: any) => {
      console.log(params)
    }, (params: any) => {
      if (params === 'submit') {
        setTimeout(() => this.getBinders(this.previousPills), 50)
      }
    });
  }

  /* JSON EXPORT BUTTON */
  downloadJsonExport = (binder: any) => {
    this.flashService.clear_alerts()
    let query_params = {
      report: 'json_dump_export',
      param_data: {
        binder_ids: [binder.id],
        organization_ids: [binder.organization_id]
      }
    }

    return this.Restangular.setFullResponse(true).one(`reports/json_dump_export`).get(query_params).then((response: any) => {
      var report_file = new Blob([response.data], { type: 'text/csv' })
      var report_name = `json_export_report_${dayjs().format('YYYY-MM-DD')}.csv`
      FileSaver.saveAs(report_file, report_name)
    },(error: any) => {
      window.scrollTo({ top: 0 })
      this.flashService.add_alert({
        name: 'report_error',
        dismissable: true,
        class: 'alert-warning',
        icon: 'fa-warning',
        strong: error.statusText,
        message: error.data[0]
      })
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  canShowJsonDownload = function (binder: any) {
    let user_type = this.$scope.session.getUser().user_role.role.title
    if(user_type == 'System Admin' && binder.requirement.compliance_method.includes('Form')) {
      return true
    } else {
      return false
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngBindersList', downgradeComponent({ component: BindersListComponent }) as angular.IDirectiveFactory)
