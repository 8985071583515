// 'use strict'

// var StatusIconComponent = {
//   selector: "statusIcon", //<loading-indicator>
//   template: require('../../views/directives/status-icon.pug'),
//   restrict: 'E',
//   bindings: {
//     status: '<'
//   },

//   controller: class StatusIconController {
//     // BINDING PLACEHOLDERS
//     private status: any;
//     private statusObject: any = {};


//     static $inject = ['$scope', 'complyosServices'];
//     constructor(private $scope, private complyosServices) {
//     }

//     $onInit = () => {
//       this.statusObject = {
//         color: this.complyosServices.snapshot.get_status_color(this.status),
//         icon: this.complyosServices.snapshot.get_status_icon(this.status),
//         number: this.complyosServices.snapshot.get_status_text(this.status)
//       }
//     }
//   }
// }

// angular
//   .module('complyosClient')
//   .component(StatusIconComponent.selector, StatusIconComponent);