// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsBinderSnapshotController', ['$state', '$scope', '$uibModal', 'loadingStateService', 'Restangular', function (
//   $state: any,
//   $scope: any,
//   $uibModal: any,
//   loadingStateService: any,
//   Restangular: any
// ) {
//   const init = function () {
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     get_binder_snapshot()

//     return $scope.$on('snapshot_update', (event: any, data: any) => get_binder_snapshot());
//   }

//   var get_binder_snapshot = function () {
//     let query_params = {
//       model: 'Binder',
//       id: $state.params.binder_id,
//       method: 'snapshot',
//       param_data: {
//         binder_id: $state.params.binder_id,
//         date_range_begin: dayjs().subtract(3, 'years').$d,
//         date_range_end: dayjs().$d
//       }
//     }
//     $scope.loadingStateService.set_state('loading')
//     return Restangular.one('analytics/gather').get(query_params).then(function (response: any) {
//       $scope.loadingStateService.process_success(response)
//       return $scope.binder_snapshot = response
//     }
//     , (error: any) => $scope.loadingStateService.process_error(error));
//   }

//   return init()
// }])
