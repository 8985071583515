/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

import angular from "angular";
import _ from "lodash";

export class applicationDemoController implements angular.IController {
    static $inject = ['$uibModal', '$rootScope', '$state', '$scope', 'loadingStateService', 'flashService', 'utils', 'fortyCore'];

    $onInit() { }

    constructor(
        $uibModal: any,
        $rootScope: any,
        $state: any,
        $scope: any,
        loadingStateService: any,
        flashService: any,
        utils: any,
        fortyCore: any) {
        /* LOADING FEEDBACK */
        $scope.loadingStateService = angular.copy(loadingStateService)
        $scope.detectionService = fortyCore.detectionService

        // define custom states and merge
        const custom_states = {
            custom: {
                icon: 'fa-thumbs-up',
                text: 'Either everyone is doing their job or no one is, There is nothing to do today.'
            }
        }

        $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
        $scope.loadingStateService.init()

        /* FLASHES */
        $scope.flashService = angular.copy(flashService)

        $scope.flashService.add_alert({
            name: 'demo_controller_loading_alert',
            dismissable: false,
            class: 'alert-success',
            strong: 'Congratulations',
            message: 'You have loaded the page. This message is loaded via the controller'
        })

        $scope.add_custom_flash = () =>
            $scope.flashService.add_alert({
                strong: 'Info',
                name: 'demo_controller_custom_flash',
                dismissable: true,
                class: 'alert-info',
                message: 'This is a custom Information flash message.'
            })

        $scope.long_tooltip_tester = () => 'I don evea wan yo bananas. Give me dat banana!'

        // --------------- ANGULAR 12+ EXAMPLE SECTION ---------------
        $scope.exampleInputNumber = 77;
        $scope.filter = {}
        $scope.singleFilter = {
            "isUnique": true,
            "isRequired": true,
            "Field": "date",
            "Op": 4,
            "displayName": "Date",
            "displayValue": "6 months",
            "Value": {
                "date_range_begin": "2022-11-22T21:32:26.561Z",
                "date_range_end": "2023-05-22T23:59:59.999Z"
            },
            "dateCreated": "2023-04-26T19:16:16.492Z"
        }
        $scope.filter.appliedFilters = [
            {
                "isUnique": true,
                "isRequired": true,
                "Field": "date",
                "Op": 4,
                "displayName": "Date",
                "displayValue": "6 months",
                "Value": {
                    "date_range_begin": "2022-11-22T21:32:26.561Z",
                    "date_range_end": "2023-05-22T23:59:59.999Z"
                },
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "isUnique": true,
                "isRequired": true,
                "isHidden": true,
                "Field": "pagination_params",
                "Op": 4,
                "displayName": "Page",
                "displayValue": "page: 1 (50/pg)",
                "Value": {
                    "page": 1,
                    "pageSize": 50
                },
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "isUnique": true,
                "isRequired": true,
                "Field": "visible",
                "Op": 6,
                "displayName": "Visibility",
                "displayValue": "Active",
                "Value": "active",
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "isUnique": true,
                "isRequired": true,
                "Field": "schedule_visibility",
                "Op": 6,
                "displayName": "Visibility",
                "displayValue": "All",
                "Value": "all",
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "isUnique": true,
                "isRequired": true,
                "Field": "acknowledged_scope",
                "Op": 6,
                "displayName": "Visibility",
                "displayValue": "Action Needed",
                "Value": "action_needed",
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "isUnique": true,
                "isRequired": true,
                "Field": "published_visibility",
                "Op": 6,
                "displayName": "Visibility",
                "displayValue": "Published",
                "Value": "published",
                "dateCreated": "2023-04-26T19:16:16.492Z"
            },
            {
                "includeDescendants": true,
                "descendantsName": 'descendants',
                "Field": "interval_ids",
                "Op": "Multi",
                "displayName": "Intervals",
                "displayValue": "Weekly",
                "Value": 11,
                "dateCreated": "2023-05-22T20:32:36.157Z"
            }
        ]
        $scope.callbackFn = function (e: any) {
            alert(`Fn triggered from within Angular 12+ component, and handled in Angularjs component. Value is: ${e}`);
        }
        // ----------- [END] ANGULAR 12+ EXAMPLE SECTION -------------

        $scope.editable_demo_states = {
            locked_true: {
                locked: true
            },
            locked_false: {
                locked: false
            },
            locked_null: {
                locked: null
            },

            inactive_true: {
                inactive: true
            },
            inactive_false: {
                inactive: false
            },
            inactive_null: {
                inactive: null
            },

            archived_true: {
                archived: true
            },
            archived_false: {
                archived: false
            },
            archived_null: {
                archived: null
            }
        }

        $scope.uneditable_demo_states = {
            locked_true: {
                locked: true
            },
            locked_false: {
                locked: false
            },
            locked_null: {
                locked: null
            },

            inactive_true: {
                inactive: true
            },
            inactive_false: {
                inactive: false
            },
            inactive_null: {
                inactive: null
            },

            archived_true: {
                archived: true
            },
            archived_false: {
                archived: false
            },
            archived_null: {
                archived: null
            }
        }
    }
}
angular
    .module('complyosClient')
    .controller('applicationDemoController', applicationDemoController)

// ------------------------------------

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('applicationDemoController', ['$uibModal', '$rootScope', '$state', '$scope', 'loadingStateService', 'flashService', 'utils', 'fortyCore', function (
//   $uibModal: any,
//   $rootScope: any,
//   $state: any,
//   $scope: any,
//   loadingStateService: any,
//   flashService: any,
//   utils: any,
//   fortyCore: any
// ) {
//   /* LOADING FEEDBACK */
//   $scope.loadingStateService = angular.copy(loadingStateService)
//   $scope.detectionService = fortyCore.detectionService

//   // define custom states and merge
//   const custom_states = {
//     custom: {
//       icon: 'fa-thumbs-up',
//       text: 'Either everyone is doing their job or no one is, There is nothing to do today.'
//     }
//   }

//   $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//   $scope.loadingStateService.init()

//   /* FLASHES */
//   $scope.flashService = angular.copy(flashService)

//   $scope.flashService.add_alert({
//     name: 'demo_controller_loading_alert',
//     dismissable: false,
//     class: 'alert-success',
//     strong: 'Congratulations',
//     message: 'You have loaded the page. This message is loaded via the controller'
//   })

//   $scope.add_custom_flash = () =>
//     $scope.flashService.add_alert({
//       strong: 'Info',
//       name: 'demo_controller_custom_flash',
//       dismissable: true,
//       class: 'alert-info',
//       message: 'This is a custom Information flash message.'
//     })

//   $scope.long_tooltip_tester = () => 'I don evea wan yo bananas. Give me dat banana!'

//   // --------------- ANGULAR 12+ EXAMPLE SECTION ---------------
//   $scope.exampleInputNumber = 77;
//   $scope.filter = {}
//   $scope.singleFilter = {
//     "isUnique": true,
//     "isRequired": true,
//     "Field": "date",
//     "Op": 4,
//     "displayName": "Date",
//     "displayValue": "6 months",
//     "Value": {
//       "date_range_begin": "2022-11-22T21:32:26.561Z",
//       "date_range_end": "2023-05-22T23:59:59.999Z"
//     },
//     "dateCreated": "2023-04-26T19:16:16.492Z"
//   }
//   $scope.filter.appliedFilters = [
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "Field": "date",
//       "Op": 4,
//       "displayName": "Date",
//       "displayValue": "6 months",
//       "Value": {
//         "date_range_begin": "2022-11-22T21:32:26.561Z",
//         "date_range_end": "2023-05-22T23:59:59.999Z"
//       },
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "isHidden": true,
//       "Field": "pagination_params",
//       "Op": 4,
//       "displayName": "Page",
//       "displayValue": "page: 1 (50/pg)",
//       "Value": {
//         "page": 1,
//         "pageSize": 50
//       },
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "Field": "visible",
//       "Op": 6,
//       "displayName": "Visibility",
//       "displayValue": "Active",
//       "Value": "active",
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "Field": "schedule_visibility",
//       "Op": 6,
//       "displayName": "Visibility",
//       "displayValue": "All",
//       "Value": "all",
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "Field": "acknowledged_scope",
//       "Op": 6,
//       "displayName": "Visibility",
//       "displayValue": "Action Needed",
//       "Value": "action_needed",
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "isUnique": true,
//       "isRequired": true,
//       "Field": "published_visibility",
//       "Op": 6,
//       "displayName": "Visibility",
//       "displayValue": "Published",
//       "Value": "published",
//       "dateCreated": "2023-04-26T19:16:16.492Z"
//     },
//     {
//       "includeDescendants": true,
//       "descendantsName": 'descendants',
//       "Field": "interval_ids",
//       "Op": "Multi",
//       "displayName": "Intervals",
//       "displayValue": "Weekly",
//       "Value": 11,
//       "dateCreated": "2023-05-22T20:32:36.157Z"
//     }
//   ]
//   $scope.callbackFn = function (e: any) {
//     alert(`Fn triggered from within Angular 12+ component, and handled in Angularjs component. Value is: ${e}`);
//   }
//   // ----------- [END] ANGULAR 12+ EXAMPLE SECTION -------------

//   $scope.editable_demo_states = {
//     locked_true: {
//       locked: true
//     },
//     locked_false: {
//       locked: false
//     },
//     locked_null: {
//       locked: null
//     },

//     inactive_true: {
//       inactive: true
//     },
//     inactive_false: {
//       inactive: false
//     },
//     inactive_null: {
//       inactive: null
//     },

//     archived_true: {
//       archived: true
//     },
//     archived_false: {
//       archived: false
//     },
//     archived_null: {
//       archived: null
//     }
//   }

//   return $scope.uneditable_demo_states = {
//     locked_true: {
//       locked: true
//     },
//     locked_false: {
//       locked: false
//     },
//     locked_null: {
//       locked: null
//     },

//     inactive_true: {
//       inactive: true
//     },
//     inactive_false: {
//       inactive: false
//     },
//     inactive_null: {
//       inactive: null
//     },

//     archived_true: {
//       archived: true
//     },
//     archived_false: {
//       archived: false
//     },
//     archived_null: {
//       archived: null
//     }
//   }
// }])
