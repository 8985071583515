import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as Restangular from '../../../vendor/restangular/restangular'
import _ from 'lodash';
import { RequirementValidators } from '../../extensions/RequirementValidators';
import { ValidationHelpers } from '../../extensions/ValidationHelpers';

@Component({
  selector: 'ng-requirement-interval-editor',
  templateUrl: './requirement-interval-editor.component.html',
  styleUrls: ['./requirement-interval-editor.component.scss']
})
export class RequirementIntervalEditorComponent implements OnInit {

  @Input() uiSetting: string = 'update';
  @Input() requirement: any = {};
  @Output() requirementChange = new EventEmitter<any>()
  @Input() formSubmitAttempted;

  //Injected Serivces
  public session: any;

  //Properties
  form: FormGroup
  formReady: boolean = false;
  current_selected_interval = {}
  selected_option: any = {}
  disable_instructions = true
  preloaded_option :any = {}
  freeText :any = {}
  ValidationHelpers = ValidationHelpers

  constructor(
    public controlContainer: ControlContainer,
    @Inject('$rootScope') private $rootScope,
    private Restangular: Restangular,
  ) {
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    
    this.addFormFields();
    this.listeners();
    this.set_schedule_state_options();
    this.get_intervals();
    this.get_list_of_options();
    // this.set_start_date_instructions();
  }

  // LISTS
  public schedule_state_options = []
  public intervals = []
  public options_list :any = {}  // why do we need this??
  public options = []       // schedule options


  addFormFields = () => {
    this.form = this.controlContainer.control as FormGroup
    this.form.addControl('schedule_on', new FormControl(this.requirement.schedule_on))
    this.form.addControl('start_date_needed', new FormControl(this.requirement.start_date_needed))
    this.form.addControl('no_schedule', new FormControl(this.requirement.no_schedule))
    this.form.addControl('managed_by_paired_application', new FormControl(this.requirement.managed_by_paired_application))
    this.form.addControl('interval_id', new FormControl(this.requirement.interval_id, (control) => this.IntervalValidator(control, this.form)))
    this.form.addControl('option_id', new FormControl(this.requirement.option_id, (control) => this.IntervalValidator(control, this.form)))
    this.form.addControl('schedule_start_date_instructions', new FormControl(this.requirement.schedule_start_date_instructions, (control) => this.IntervalValidator(control, this.form)))
    this.form.addControl('rolling_start_date', new FormControl(this.requirement.rolling_start_date))
    this.form.addControl('user_prompted_date_generation', new FormControl(this.requirement.user_prompted_date_generation))
    this.form.addControl('next_task_date_instructions', new FormControl(this.requirement.next_task_date_instructions))
    this.form.addControl('submission_window_days_before', new FormControl(this.requirement.submission_window_days_before))
    this.form.addControl('submission_window_days_after', new FormControl(this.requirement.submission_window_days_after))
    this.form.addControl('first_notifier_days_before', new FormControl(this.requirement.first_notifier_days_before, (control) => this.IntervalValidator(control, this.form)))
    this.form.addControl('second_notifier_days_before', new FormControl(this.requirement.second_notifier_days_before, (control) => this.IntervalValidator(control, this.form)))
    this.form.addControl('third_notifier_days_before', new FormControl(this.requirement.third_notifier_days_before, (control) => this.IntervalValidator(control, this.form)))

    this.formReady = true;
  }

  set_schedule_state_options = () => {
    let isRegularScheduleDisabled = this.uiSetting === 'update' && (this.requirement.can_disable_schedule_state === false || this.requirement.can_disable_managed_by_paired_application === false)
    let isManageByPairedApplicationDisabled = this.uiSetting === 'read' || (this.requirement.managed_by_paired_application && this.requirement.can_disable_managed_by_paired_application === false)
    this.schedule_state_options = [
      {
        value: 'schedule_on',
        display: 'Configure with start date',
        disabled: isRegularScheduleDisabled,
        helpText: 'Complyos will generate tasks based on interval and schedule start date'
      },
      {
        value: 'start_date_needed',
        display: 'Configure without start date',
        disabled: true,
        helpText: 'Schedule does not start until user sets "start date"'
      },
      {
        value: 'no_schedule',
        display: 'Schedule Off',
        disabled: isRegularScheduleDisabled,
        helpText: 'Complyos will not generate tasks'
      },
      {
        value: 'managed_by_paired_application',
        display: 'Managed by Paired Application',
        disabled: isManageByPairedApplicationDisabled,
        helpText: 'Third party app may generate tasks'
      }
    ]
  }

  get_intervals = () => {
    //let loadedCallback = args.loadedCallback
    const params = {
      param_data: {
        // text_search: args.apiSearchInputString
      }
    }
    return this.Restangular.all('intervals').getList(params).then((success: any) => {
      this.intervals = success.plain()
      // TODO: WHUT is this?
      if (this.requirement.interval !== undefined) {
        return this.update_interval_description()
      }
    })
  }
  update_interval_description = () => {
    const selected_interval = _.find(this.intervals, (i: any) => i.id == this.form.controls['interval_id'].value)
    this.current_selected_interval = selected_interval
    this.requirement.interval = selected_interval
  }
  get_list_of_options = () => {
    const params = 'requirement-date-instructions-dropdown'
    return this.Restangular.all('options_lists/get_list_of_options').get(params).then((success: any) => {
      this.options_list = success.plain()
      this.options = this.options_list.options_values
      this.set_loaded_instructions()
    })
  }
  set_loaded_instructions = () => {
    this.preloaded_option = _.find(this.options, (i: any) => i.display_name == this.requirement.schedule_start_date_instructions)
    if (this.preloaded_option === undefined) {
      this.freeText = _.find(this.options, (i: any) => i.value == 'Free Text')
      this.requirement.option_id = this.freeText.id.toString()
      this.form.get('option_id').setValue(this.freeText.id)
      this.disable_instructions = false
    } else {
      this.requirement.option_id = this.preloaded_option.id.toString()
      this.form.get('option_id').setValue(this.preloaded_option.id)
    }
  } 
  set_start_date_instructions = () => {
    // load the text into the text area
    this.selected_option = _.find(this.options, (i: any) => i.id == this.form.controls["option_id"].value)
    if (this.selected_option) {
      this.form.controls['schedule_start_date_instructions'].patchValue(this.selected_option.display_name)
      this.disable_instructions = this.selected_option.display_name != 'Free Text' ? true : false
    } else {
      this.disable_instructions = true
    }
  }

  // TODO: this should act like a radio button
  future_scheduling_option_clicked = (clickedOption: any) => {
    let schedulingOptions = ['rolling_start_date', 'user_prompted_date_generation']
    // let updatedValue = this.requirement[clickedOption]
    var updatedField = this.form.get(clickedOption)
    if (updatedField.value === true) {
      let nonSelectedOptions = schedulingOptions.filter(opt => opt !== clickedOption)
      nonSelectedOptions.forEach(opt => this.form.controls[opt].patchValue(false))
    }
  }

  sync_state_options = _.debounce((field: any) => {

    let newValue = this.form.get(field).value

    // if schedule off was just turned on, turn all other options off
    //    and the reverse
    if (field === 'no_schedule' && newValue === true) {
      this.form.get('schedule_on').setValue(false)
      this.form.get('start_date_needed').setValue(false)
      this.form.get('managed_by_paired_application').setValue(false)
    } else if (field !== 'no_schedule' && newValue === true) {
      this.form.get('no_schedule').setValue(false)
    }

    // if schedule off was just turned on, turn all other options off
    if (field === 'managed_by_paired_application' && newValue === true) {
      this.form.get('schedule_on').setValue(true)
    }

    // if schedule was turned on, make start_date_needed match (true and false)
    if (this.form.get('schedule_on').value) {
      this.form.get('start_date_needed').setValue(true)
    } else {
      this.form.get('start_date_needed').setValue(false)
    }

    // if schedule_on is not checked at this stage, managed_by_paired_application also should not be on
    if (this.form.get('schedule_on').value === false) {
      this.form.get('managed_by_paired_application').setValue(false)
    }

    // Debounce 10 so that FormControl has a chance to get the updated values befor we process them
  }, 10)
  submission_window_name = (submission_window_type: string) => {
    let period_name = 'date'
    if (submission_window_type !== null
      && submission_window_type !== undefined
      && submission_window_type != "day") {
      // there is another period specified ie: week, month, or year, then use that text
      period_name = submission_window_type
    }

    return period_name;
  }

  listeners = () => {
    this.form.get("interval_id").valueChanges.subscribe((val) => {
      this.update_interval_description()
      this.requirement.interval_id = val
      this.requirementChange.emit(this.requirement)
    });
    this.form.get('rolling_start_date').valueChanges.subscribe(() => {
      this.future_scheduling_option_clicked('rolling_start_date')
    })
    this.form.get('user_prompted_date_generation').valueChanges.subscribe(() => {
      this.future_scheduling_option_clicked('user_prompted_date_generation')
    })
  }
  // VALIDATORS
  IntervalValidator = (control: AbstractControl, form: FormGroup): ValidationErrors | null => {
    if (this.controlContainer && this.form.value.no_schedule !== true) {
      return RequirementValidators.requiredIfPublished(control, form)
    }
    return null
  }
}
