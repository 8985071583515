import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { FlashService } from '../../../services/flash_service.service';
import { RequirementVersionComparerComponent } from '../requirement-version-comparer/requirement-version-comparer.component';

@Component({
  selector: 'ngRequirementVersionHistoryModal',
  templateUrl: './requirement-version-history-modal.component.html',
  styleUrls: ['./requirement-version-history-modal.component.scss']
})
export class RequirementVersionHistoryModal implements OnInit {

  @Input() requirementId: number;
  @Input() requirement: any
  @Output() dismissFn = new EventEmitter();
  @Output() closeFn = new EventEmitter<any>();

  @ViewChild('editComparer') editComparer: RequirementVersionComparerComponent

  //Injected Services
  private flashService: any
  private processingStateService: any

  // Properties
  public selectedTabIndex: number = 0;
  isEditValid: boolean = false
  isPauseValid: boolean = false

  constructor(
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    @Inject('$rootScope') private $rootScope,

  ) {
    this.flashService = angular.copy(flashService)
    this.processingStateService = angular.copy(fortyCore.processingStateService)
  }

  ngOnInit(): void {
    this.watchForUpdateRequirement()
  }

  on_save_completed = () => {
    setTimeout(() => this.selectedTabIndex = 0, 0)
  }
  isEditValidChanged = (isValid) => {
    this.isEditValid = isValid
  }
  isPauseValidChanged = (isValid) => {
    this.isPauseValid = isValid
  }

  pause_form_submit = () => {
    this.editComparer.editableRequirement.pause_form_submit({}, {})
  }
  save_form_submit = () => {
    this.editComparer.editableRequirement.save_form_submit({}, {})
  }

  modalDismiss = () => {
    this.dismissFn.emit(this.requirement)
  }

  watchForUpdateRequirement = function () {
    this.$rootScope.$on('requirementEditState', (event: any, edit_state_id: any) => {
      this.requirement.edit_state_id = edit_state_id
    })
  }

  modalClose = () => {
    this.closeFn.emit(this.requirement)
  }
}

angular
  .module('complyosClient')
  .directive('ngRequirementVersionHistoryModal', downgradeComponent({ component: RequirementVersionHistoryModal }) as angular.IDirectiveFactory)