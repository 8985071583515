// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('applicationMarketingController', ['$http', '$location', '$rootScope', '$scope', 'ENV', 'utils', function (
//   $http: any,
//   $location: any,
//   $rootScope: any,
//   $scope: any,
//   ENV: any,
//   utils: any
// ) {
//   const init = function () {
//     // console.log('Init()')
//     $scope.active_slide = 0
//     return $scope.COMPLYOS_DOMAIN = ENV.COMPLYOS_DOMAIN
//   }

//   $scope.rewind_slide = function () {
//     if ($scope.active_slide === 0) {
//       return $scope.active_slide = 8
//     } else {
//       return $scope.active_slide = $scope.active_slide - 1
//     }
//   }

//   $scope.advance_slide = function () {
//     if ($scope.active_slide === 8) {
//       return $scope.active_slide = 0
//     } else {
//       return $scope.active_slide = $scope.active_slide + 1
//     }
//   }

//   $scope.build_email_href = function () {
//     /* MAIL RECIEPENTS */
//     let user_email, user_name, user_role
//     const to = [`info@${ENV.COMPLYOS_DOMAIN}`]
//     const cc = []
//     const bcc: any = []

//     /* USER INFORMATION */
//     const user = $rootScope.session.getUser()

//     if (user) {
//       user_name = user.profile.display_name
//       user_email = user.email
//       user_role = user.user_role.role.title
//       cc.push(user.email)
//     } else {
//       user_name = ''
//       user_email = ''
//       user_role = ''
//     }

//     /* LOCATION INFORMATION */
//     const site = $location.$$host
//     const current_page = $location.$$absUrl

//     /* EMAIL */
//     const subject = `Complyos Access: ${site}`

//     const body = `\
// I would like more information about Complyos for my organization\
// `

//     let href = `mailto:${to.join(',')}?`

//     if (cc.length) {
//       href += `cc=${cc.join(',')}&`
//     }
//     if (bcc.length) {
//       href += `bcc=${bcc.join(',')}&`
//     }

//     href += `subject=${encodeURIComponent(subject)}&`
//     href += `body=${encodeURIComponent(body)}`

//     return href
//   }

//   return init()
// }])
