/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

var dayjs = require("dayjs")


angular.module('complyosClient').directive('popout', [
  'fortyCore', 'constants', 'arrayFunctions', 'helpService',
  (fortyCore, constants, arrayFunctions, helpService) => ({
    templateUrl: 'views/directives/popout.html',
    restrict: 'E',
    transclude: true,

    scope: {
      isPopoutOpen: '=',
      onClickOutsideOfPopout: '&',
      popupPositioning: '<'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope.popupPositioning = $scope.popupPositioning || 'absolute'
      $scope = this.registerFunctions($scope)
      
      $scope.elRef = $ele
      $scope.watchIsPopoutOpen()

      $scope.helpService = helpService
      return $scope.fortyCore = fortyCore;
    },

    registerFunctions ($scope: any) {
      $scope.repositionPopout = () => {
        if ($scope.isPopoutOpen === true && $scope.popupPositioning == 'fixed') {
          let targetRect = $scope.elRef.find('.popout-target')[0].getBoundingClientRect()
          let top = targetRect.y
          let left = targetRect.x
          
          let content = $scope.elRef.find('.popout-content')[0]
          content.style.position = 'fixed'
          content.style.top = top + 'px'
          content.style.left = left + 'px'
        }
      }

      $scope.togglePopout = () => {
        $scope.isPopoutOpen = !$scope.isPopoutOpen
        if ($scope.isPopoutOpen ===false && $scope.onClickOutsideOfPopout) {
          $scope.onClickOutsideOfPopout()()
        }
      }

      $scope.watchIsPopoutOpen = () => {
        $scope.$watch('isPopoutOpen', (newValue: any, oldValue: any) => {
          if (newValue === true) {
            $scope.repositionPopout()
          }
        }, true)
      }

      return $scope
    }
  })

])
