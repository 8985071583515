import { Component, Directive, ElementRef, Injector, Input, OnInit } from "@angular/core"
import { UpgradeComponent } from "@angular/upgrade/static"

@Directive({ selector: 'ajs-loading-indicator' })
export class ajsLoadingIndicator extends UpgradeComponent implements OnInit {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() loadingState: any;
  @Input() funSize: any;
  @Input() background: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('loadingIndicator', elementRef, injector);
  }
}