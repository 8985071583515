// import $ from 'jquery'
// window["jQuery"] = $

// import vendor.legacy files
import './scripts/vendor.legacy';

// import angularjs APP 
import './scripts/index.legacy'

// import NG+ APP
import { AppModule } from './_ng/app.module';

//---------------------------------------------------------------------

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
// import { environment } from './environments/environment';

// if (environment.production) {
//   enableProdMode();
// }

// BOOTSTRAP HYBRID APP
platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
    console.log("Bootstrapping in Hybrid mode with Angular & AngularJS");
    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    upgrade.bootstrap(document.body, ['complyosClient', 'FortyAUServices'], { strictDi: true });
});