/* eslint-disable
    no-undef,
*/
'use strict'


angular.module('complyosClient').provider('sessionLauncher', ['ENV', function sessionLauncherProvider(this: any, ENV: any) {
  // Begin

  this.withSession = () => {
    let deferred = new Promise((resolve, reject) => {
      var callValidate = () => {
        this.validate().then((success: any) => {
          resolve(success)
        }, (error: any) => {
          reject(error)
        })
      }

      if (
        this.getSessionData().token &&
        this.getSessionData().happy
      ) {
        // *sessionBreadCrumb* console.log('session_provider > withSession() :: TOKEN + HAPPY, VALIDATING')
        callValidate()
      } else {
        // *sessionBreadCrumb* console.log('session_provider > withSession() :: NOT HAPPY, WAITING')
        // Everybody wants to see me throw a fireball, but thats not right, not in real life.
        // https://youtu.be/wqKdV_jAovI?t=34
        this.doKungFoo()
        // create function to be called on every storage change,
        // and resolved when session is happy.
        var waitForEvent = (event: any) => {
          let eventSession = JSON.parse(event.value)
          if (eventSession.happy) {
            // *sessionBreadCrumb* console.log('session_provider > withSession() :: FINALLY HAPPY, VALIDATING')
            callValidate()
            document.removeEventListener('itemInserted', waitForEvent, false)
          }
        }
        // register it with the itemInserted event
        document.addEventListener('itemInserted', waitForEvent, false)
      }
    })
    return deferred
  }

  this.validate = function () {
    let user_services_params = JSON.stringify({ token: this.getSessionData().token })
    let apiUrl = `${ENV.COMPLYOS_API_URL}/user_services/token_validate`

    // *sessionBreadCrumb* console.log(`session_provider > validate('${token}')`)

    let deferred = new Promise(function (resolve, reject) {
      var request = new XMLHttpRequest()
      request.responseType = 'json'
      request.onreadystatechange = function () {
        // user is authenticated
        if (
          request.readyState === 4 && // HTTP request is complete
          request.status === 200
        ) {
          let success = {
            success: true,
            response: request.response
          }
          resolve(success)

        // all other failures
        } else if (
          request.readyState === 4 &&
          request.status !== 200
        ) {
          let error = {
            success: false,
            response: request.response
          }
          reject(error)
        }
      }
      request.open('POST', apiUrl, true) // true for asynchronous
      request.setRequestHeader("Content-type", "application/json;");
      request.send(user_services_params)
    })

    return deferred
  }

  this.getSessionData = function () {
    let localStorageContainer = window.location.host.replace(/\./g, '_').split(':')[0] + '_session'
    return JSON.parse(window.localStorage.getItem(localStorageContainer))
  }

  this.clearSessionDataToken = function () {
    let localStorageContainer = window.location.host.replace(/\./g, '_').split(':')[0] + '_session'
    let sessionData = JSON.parse(window.localStorage.getItem(localStorageContainer))
    sessionData.token = undefined
    window.localStorage.setItem(localStorageContainer, JSON.stringify(sessionData))
  }

  this.$get = function sessionLauncherFactory () {
    //@ts-ignore
    return new SessionLauncher()
  }

  // JS HACK //

  // WHY ???
  // The storage event fires when a storage area (localStorage or sessionStorage)
  // has been modified in the context of another document.
  //   window.addEventListener('storage', function (event) {} )
  // but,,,and here is the rub, A huge caveat from the MDN docs:
  // [window.addEventListener('storage', ...)] won't work on the same page that
  // is making the changes — it is really a way for other pages on the domain
  // using the storage to sync any changes that are made.

  // so we doKungFoo, Since JS is dynamical language just rewrite original functions.
  // https://stackoverflow.com/a/43634169

  this.doKungFoo = function () {
    // save og setItem function, we may need it, probably not.
    var originalSetItem = localStorage.setItem
    // override the setItem function
    localStorage.setItem = function (key, value) {
      var event :any= new Event('itemInserted')
      event.value = value // Optional..
      event.key = key // Optional..
      document.dispatchEvent(event)
      originalSetItem.apply(this, arguments)
    }
  }

  // end
}])
