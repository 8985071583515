/* eslint-disable
    camelcase,
    eqeqeq,
    no-return-assign,
    no-undef,
*/
'use strict'

var CollapsePanelComponent = {
  selector: "collapsePanel", //<collapse-panel>
  // templateUrl: 'views/directives/collapse_panel.html',
  template: require('../../views/directives/collapse_panel.pug'),
  restrict: 'E',
  bindings: {
    collapsed: '<',
    header: '<',
    icon: '<',
    preventCollapse: '<',
    
    panelStyle: '<',
    panelHeadingStyle: '<',
    panelHeadingTextStyle: '<',
  },
  transclude: {
    headingContent: '?headingContent',
    content: 'content'
  },

  controller: class CollapsePanelController {
    // BINDING PLACEHOLDERS
    private collapsed: any = false
    private header: any;
    private icon: any;
    private preventCollapse: any;

    private panelStyle: any;
    private panelHeadingStyle: any;
    private panelHeadingTextStyle: any;

    // PROPERTIES
    // none

    static $inject = ['$rootScope', 'helpService', 'fortyCore', '$scope'];
    constructor(private $rootScope, private helpService, private fortyCore, private $scope) {
      // this.panelHeadingStyle = {background: 'blue'}
    }

    $onInit = () => {
    };

    toggle_panel_body = () => {
      this.collapsed = !this.collapsed
    }

    print_things = () => {
      console.log(this.preventCollapse)
      console.log(this.panelStyle)
      console.log(this.panelHeadingStyle)
    }


  }
};

angular
  .module('complyosClient')
  .component(CollapsePanelComponent.selector, CollapsePanelComponent);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('collapsePanel', ['$rootScope', 'helpService', 'fortyCore',
//   (helpService, fortyCore, $rootScope, $scope) => ({
//     templateUrl: 'views/directives/collapse_panel.html',
//     restrict: 'E',

//     transclude: {
//       headingContent: '?headingContent',
//       content: 'content'
//     },

//     scope: {
//       collapsed: '=?',
//       header: '<',
//       icon: '<',
//       preventCollapse: '<',
//       panelStyle: '='
//     },

//     link ($scope: any, $ele: any, $attrs: any) {
//       $scope.toggle_panel_body = () => {
//         $scope.collapsed = !$scope.collapsed
//       }

//       $scope.helpService = helpService
//       return $scope.fortyCore = fortyCore
//     }
//   })
// ])
