// /* eslint-disable
//     camelcase,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('ssoAuthController', ['$cookies', '$location', '$rootScope', '$scope', '$timeout', '$window', 'authenticationService', 'ENV', 'loadingStateService', 'Restangular', function (
//   $cookies: any,
//   $location: any,
//   $rootScope: any,
//   $scope: any,
//   $timeout: any,
//   $window: any,
//   authenticationService: any,
//   ENV: any,
//   loadingStateService: any,
//   Restangular: any,
// ) {
//   const init = function () {
//     // set all the custom states needed for the sso service
//     // https://docs.oracle.com/cd/E19681-01/821-1818/gjwal/index.html
//     const custom_states = {
//       sso_init: {
//         name: 'sso_init',
//         icon: 'fa-cog fa-spin',
//         text: 'Redirecting for SSO authentication'
//       },
//       sso_error_idpNotFound: {
//         name: 'sso_error_idpNotFound',
//         icon: 'fa-exclamation-triangle',
//         text: 'SSO identity provider not found'
//       },
//       sso_error_SSOFailed: {
//         name: 'sso_error_SSOFailed',
//         icon: 'fa-exclamation-triangle',
//         text: 'SSO failed',
//         try_again: true
//       },
//       sso_error_UserNotProvisioned: {
//         name: 'sso_error_UserNotProvisioned',
//         icon: 'fa-exclamation-triangle',
//         text: 'User not provisioned'
//       },
//       sso_error_TokenNotSet: {
//         name: 'sso_error_TokenNotSet',
//         icon: 'fa-exclamation-triangle',
//         text: 'Token not set',
//         try_again: true
//       },
//       sso_error_SessionNotConfigured: {
//         name: 'sso_error_SessionNotConfigured',
//         icon: 'fa-exclamation-triangle',
//         text: 'Session not configured',
//         try_again: true
//       },
//       sso_error_UserNotEnabled: {
//         name: 'sso_error_UserNotEnabled',
//         icon: 'fa-exclamation-triangle',
//         text: 'User not enabled'
//       },
//       sso_success: {
//         name: 'sso_success',
//         icon: 'fa-thumbs-up',
//         text: 'User authenticated'
//       }
//     }

    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.set_state('sso_init')

//     if ($rootScope.session.paramExists('sso', 'success')) {
//       return process_sso_success()
//     } else if ($rootScope.session.paramExists('sso', 'error')) {
//       return process_sso_error()
//     } else {
//       return start_sso_process()
//     }
//   }

//   // Client loads
//   // SSO page loads init runs, params are empty, start_sso_process() is called
//   // start_sso_process calls initiate_sso_login method in authenticationService
//   // initiate_sso_login redirects browser to API /initiate endpoint
//   // API creates sso request
//   // API redirects broweser to SSO request url
//   // SSO provider validates username and password
//   // SSO provider redirects browser to API /consume endpoint
//   // API processes response, and sets token on user
//   // API exposes token via shared cookie
//   // API redirects to client SSO page
//   // Client loads
//   // SSO page detects a sso success param as present,
//   // Client gets token from shared cookie
//   // session is restored

//   /* FUNCTIONS */
//   var start_sso_process = function () {
//     if ($rootScope.session.sso_domained()) {
//       const sso_idp = $rootScope.session.get_sso_idp()
//       return authenticationService.initiate_sso_login(sso_idp.name)
//     } else {
//       return $scope.loadingStateService.set_state('sso_error_idpNotFound')
//     }
//   }

//   // ### THIS FAILS TO BROADCAST ###

//   var process_sso_success = function () {
//     // Check to see if the token shared via cookie is present
//     Restangular.one('user_services/cookie_validate').get().then((response)=> {
//       if (response.token) {
//         let token = response.token
  
//         // // BROADCAST THE TOKEN
//         // console.log('sso > process_sso_success() :: ATTEMPTING BROADCASTING TOKEN')
//         // $rootScope.$broadcast('sessionToken', token)
  
//         // BYPASS THE BROADCAST
//         $rootScope.session.bypassBroadcast(token)
  
//         if ($rootScope.session.data.token === token) {
//           $scope.loadingStateService.set_state('sso_success')
  
//         // TRY TO RECOVER ON FAILURE
//         } else {
//           console.log('sso > process_sso_success() :: BROADCAST BYPASS FAILED')
//           $scope.loadingStateService.set_state('sso_error_SessionNotConfigured')
//           console.log($scope.loadingStateService.loading_state.name)
//           $rootScope.session.removeTokenCookie()
//         }
//       } else {
//         $scope.loadingStateService.set_state('sso_error_TokenNotSet')
//       }

//       $rootScope.session.deleteParam('sso', 'success')
//     })
//   }

//   var process_sso_error = function () {
//     const error = $rootScope.session.getParam('sso', 'error')
//     // https://docs.oracle.com/cd/E19681-01/821-1818/gjwal/index.html
//     $scope.loadingStateService.set_state(`sso_error_${error}`)
//     if (error === 'UserNotProvisioned') {
//       return $timeout(function () {
//         return $rootScope.session.goTo('/marketing')
//       }, 1500)
//     }
//     $rootScope.session.deleteParam('sso', 'error')
//   }

//   return init()
// }])
