// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// import angular from 'angular'
// import Humanize from 'humanize-plus'


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsBinderDocumentationController', ['$uibModal', '$rootScope', '$state', '$scope', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
//   $uibModal: any,
//   $rootScope: any,
//   $state: any,
//   $scope: any,
//   confirmationModalFactory: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
//     $scope.processingStateService = fortyCore.processingStateService
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
    
//     $scope.flashService = angular.copy(flashService)
    
//     $scope.fortyCore = angular.copy(fortyCore)

//     $scope.loadingStateService.init()

//     $scope.$on('entries_update', (event: any, data: any) => get_entries())

//     $scope.$on('binder_update', (event: any, data: any) => $scope.binder = data)

//     get_binder()
//     get_entries()
//   }

//   var get_binder = function () {
//     // there might allready be a binder
//     if (!$scope.binder) {
//       return Restangular.all('binders').get($state.params.binder_id).then((
//         // success
//         response: any
//       ) => $scope.binder = response

//       , (error: any) => utils.log(error));
//     }
//   }

//   var get_entries = () =>
//     Restangular.all(`binders/${$state.params.binder_id}/entries_stubs`).getList().then(function (response: any) { // success
//       $scope.loadingStateService.process_success(response)
//       return $scope.entries = response
//     }
//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     })

//   $scope.is_scheduled = (entry: any) => entry.date_submission_window_open && entry.date_submission_window_close

//   $scope.is_requires_resubmission = (entry: any) => entry.complete_status === 'requires_resubmission'

//   /* CRUD */

//   const openEntryModal = (entry_object: any, ui_setting: any) => {
//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled,
//     // that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(entry_object, () => {
//       // modal config+open
//       const modalInstance = $uibModal.open({
//         templateUrl: 'views/modals/entry_modal.html',
//         controller: 'entryModalController',
//         size: 'small',
//         resolve: {
//           modalOptions () {
//             return {
              
//               object: angular.copy(entry_object), // TODO: load full entry
//               ui_setting
//             }
//           }
//         }
//       })

//       // modal close
//       // - grab the returned result,
//       // - or log out a reason
//       return modalInstance.result.then(function (result: any) {
//         if (ui_setting === 'create') {
//           objectManager.array_action($scope.entries, result, 'merge')
//           if ($scope.loadingStateService.loading_state.name === 'nocontent') {
//             $scope.loadingStateService.set_state('content')
//           }
//         }
//         if (ui_setting === 'update') {
//           objectManager.array_action($scope.entries, result, 'update')
//         }
//         return $rootScope.$broadcast('snapshot_update', { action: 'refresh' })
//       }, function (reason: any) {
//         console.log(reason)
//       }).finally(() => {
//         // reset processing state service when modal instance is finished
//         $scope.processingStateService.set(
//           entry_object,
//           $scope.processingStateService.processing_states.RESTING
//         )
//       });
//     })
//   }

//   $scope.openEntryCreateModal = function () {
//     const entry = {
//       binder: $scope.binder,
//       binder_id: $state.params.binder_id,
//       created_by: 'user',
//       complete_status: 'incomplete',
//       attachments: [],
//       notes: [],
//       state: {
//         inactive: null
//       }
//     }
//     return openEntryModal(entry, 'create')
//   }

//   const reloadEntryAndOpenModal = (entry, ui_setting, fn) => {
//     // s15-c2556-HOTFIX: this reloading the entry should be down inside the modal (wherea spinner is shown)
//     //    but alas, this is a hotfix, so this is the quickest way to get it working again
//     return Restangular.all('entries').get(entry.id).then((response: any) => { // success
//       let full_entry = response.plain()
//       fn(full_entry, ui_setting)
//     }, (error: any) => utils.log(error))
//   }

//   $scope.openEntryReadModal = (entry: any) => {
//     // openEntryModal(entry, 'read')
//     reloadEntryAndOpenModal(entry, 'read', openEntryModal)
//   }

//   $scope.openEntryUpdateModal = (entry: any) => {
//     // openEntryModal(entry, 'update')
//     reloadEntryAndOpenModal(entry, 'update', openEntryModal)
//   }

//   $scope.openEntryDestroyModal = function (entry: any) {
//     const confirmation_object = {
//       // severity: severity string
//       // title: ti   tle string
//       // button_icon: icon string
//       // button_text: text string
//       // deny: method to run on dismiss
//       confirm () { return destroy_entry(entry) },
//       message: `<p>This action will:</p> \
// <ul> \
// <li>Delete the entry for ${entry.event_date}</li> \
// </ul> \
// <p>This action is not reversible.</p>`
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled,
//     // that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(entry, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(
//             entry,
//             $scope.processingStateService.processing_states.RESTING
//           )
//         }
//         return true
//       })
//     );
//   }

//   var destroy_entry = (entry: any) => Restangular.all(`entries/${entry.id}`).remove().then((success: any) => objectManager.array_action($scope.entries, entry, 'remove'), function (error: any) {
//     $scope.flashService.process_error(error)
//     return utils.log(error)
//   }).finally(() => {
//     $scope.processingStateService.set(
//       entry,
//       $scope.processingStateService.processing_states.RESTING
//     )
//   })

//   $scope.capitalize = function (string: any) {
//     return Humanize.capitalize(string).replace('_', ' ')
//   }

//   return init()
// }])
