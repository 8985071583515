// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('dateRangeFilterController', ['$rootScope', '$scope', 'dateService', 'loadingStateService', 'objectManager', 'Restangular', function (
//   $rootScope: any,
//   $scope: any,
//   dateService: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any
// ) {
//   const init = function () {
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.loadingStateService.init()

//     $scope.date_range = {
//       date_range_begin: dayjs().subtract(10, 'years').$d,
//       date_range_end: dayjs().$d
//     }
//     $scope.date_params = {
//       date_range_begin: dayjs().subtract(10, 'years').$d,
//       date_range_end: dayjs().$d
//     }
//     $scope.selected_preset = '1yr'
//     $scope.preset_date_ranges = [
//       {
//         key: '1yr',
//         name: 'Last 12 Months',
//         range: {
//           date_range_begin: dayjs().subtract(1, 'years').$d,
//           date_range_end: dayjs().$d
//         }
//       },
//       {
//         key: '2yrs',
//         name: 'Last 2 Years',
//         range: {
//           date_range_begin: dayjs().subtract(2, 'years').$d,
//           date_range_end: dayjs().$d
//         }
//       },
//       {
//         key: '3yrs',
//         name: 'Last 3 Years',
//         range: {
//           date_range_begin: dayjs().subtract(3, 'years').$d,
//           date_range_end: dayjs().$d
//         }
//       },
//       {
//         key: 'all',
//         name: 'All',
//         range: {
//           date_range_begin: dayjs().subtract(10, 'years').$d,
//           date_range_end: dayjs().$d
//         }
//       },
//       {
//         key: 'custom',
//         name: 'Custom',
//         range: {
//           date_range_begin: dayjs().subtract(1, 'years').$d,
//           date_range_end: dayjs().$d
//         }
//       }
//     ]
//     $scope.date_helpers = {
//       begin_date: {
//         message: '',
//         visible: false
//       },
//       end_date: {
//         message: '',
//         visible: false
//       }
//     }
//     $scope.select_preset_date_range($scope.preset_date_ranges[0])
//     $scope.watch_reset_date_filter()
//     $scope.watch_reset_active_preset()
//   }

//   $scope.select_preset_date_range = function (preset: any) {
//     if (!preset.active) {
//       reset_active_preset()
//       preset.active = true
//       $scope.selected_preset = preset.key

//       if ($scope.selected_preset !== 'custom') {
//         $scope.date_params = {
//           date_range_begin: dayjs(preset.range.date_range_begin).format('YYYY-MM-DD'),
//           date_range_end: dayjs(preset.range.date_range_end).format('YYYY-MM-DD')
//         }
//         emit_date_filter_object()
//       } else {
//         $scope.date_params = {
//           date_range_begin: $scope.range.date_range_begin,
//           date_range_end: $scope.range.date_range_end
//         }
//         emit_date_filter_object()
//       }
//     }
//   }

//   $scope.$watch('date_range.date_range_begin', (newValue: any, oldValue: any) => {
//     if (newValue !== undefined) {
//       dateService.date_range_validation($scope.date_helpers, $scope.date_range)
//     }

//     if ($scope.selected_preset === 'custom' &&
//         !$scope.date_range_form.begin_date.$invalid
//     ) {
//       $scope.date_range_begin = dayjs($scope.date_range.date_range_begin).format('YYYY-MM-DD')
//       $scope.date_params = {
//         date_range_begin: dayjs($scope.date_range_begin).format('YYYY-MM-DD'),
//         date_range_end: dayjs($scope.date_range_end).format('YYYY-MM-DD')
//       }
//       emit_date_filter_object()
//     }
//   })

//   $scope.$watch('date_range.date_range_end', (newValue: any, oldValue: any) => {
//     if (newValue !== undefined) {
//       dateService.date_range_validation($scope.date_helpers, $scope.date_range)
//     }

//     if ($scope.selected_preset === 'custom' &&
//         !$scope.date_range_form.end_date.$invalid
//     ) {
//       $scope.date_range_end = dayjs($scope.date_range.date_range_end).format('YYYY-MM-DD')
//       $scope.date_params = {
//         date_range_begin: dayjs($scope.date_range_begin).format('YYYY-MM-DD'),
//         date_range_end: dayjs($scope.date_range_end).format('YYYY-MM-DD')
//       }
//       emit_date_filter_object()
//     }
//   })

//   $scope.watch_reset_date_filter = () => {
//     return $rootScope.$on('reset_date_filter', (scope: any, object: any) => {
//       $scope.select_preset_date_range($scope.preset_date_ranges[0])
//     });
//   }

//   $scope.watch_reset_active_preset = () => {
//     return $rootScope.$on('reset_active_preset', (scope: any, object: any) => {
//       reset_active_preset()
//     });
//   }

//   const reset_active_preset = () => {
//     _.each($scope.preset_date_ranges, (p: any) => p.active = undefined)
//   }

//   let emit_date_filter_object = () => {
//     $rootScope.$emit('date_filter_updated', $scope.date_params)
//   }

//   return init()
// }])
