/* eslint-disable camelcase, no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('validationModeCommentResponseModalController', ['$scope', '$uibModalInstance', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'dateService', function (
  $scope: any,
  $uibModalInstance: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any,
  dateService: any
) {
  const init = function () {
    $scope.processingStateService = fortyCore.processingStateService
    $scope.dateService = dateService
    
    $scope.flashService = angular.copy(flashService)

    $scope.validation_mode_comment = modalOptions.validation_mode_comment_object
    $scope.acknowledgementComment = {
      // initial ValidationMode will be type Feed::ValidationModeComment
      // for an Entry or Organization. responses are Feed::Comment
      type: 'Feed::Comment',
      noteable_id: $scope.validation_mode_comment.noteable_id,
      parent_id: $scope.validation_mode_comment.id,
      noteable_type: $scope.validation_mode_comment.noteable_type,
      text: '',
      requiresResubmission: false
    }
  }

  $scope.attempt_submit_acknowledgement = (form: any) => {
    if (form.$valid) {
      form.failed_submission = false
      $scope.submit_acknowledgement()
    } else {
      form.failed_submission = true
    }
  }

  $scope.submit_acknowledgement = function () {
    Restangular.all('notes').post($scope.acknowledgementComment).then(
      (note_save_success: any) => {
        if ($scope.acknowledgementComment.requiresResubmission === true) {
          $scope.markEntryAsRequiresResubmission().then((requiresResubmissionSuccess: any) => {
            $scope.buildAndSubmitReturnObject(note_save_success.plain(), requiresResubmissionSuccess.complete)
          },
          (requiresResubmissionError: any) => {
            $scope.flashService.process_error(requiresResubmissionError)
          })
        } else {
          $scope.buildAndSubmitReturnObject(note_save_success.plain(), false)
        }
      },
      (noteSaveError: any) => {
        $scope.flashService.process_error(noteSaveError)
      }
    )
  }

  $scope.markEntryAsRequiresResubmission = () => {
    return Restangular.all(
      `entries/${$scope.validation_mode_comment.entry.id}/complete_status`
    ).post(
      { complete_status: 'requires_resubmission' }
    )
  }

  $scope.buildAndSubmitReturnObject = (acknowledgementObject: any, markEntryAsRequiresResubmission: any) => {
    let returnObject = {
      acknowledgementObject: acknowledgementObject,
      markEntryAsRequiresResubmission: markEntryAsRequiresResubmission
    }

    $uibModalInstance.close(returnObject)
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  return init()
}])
