/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'


angular.module('complyosClient').directive('pageSize', [
  'fortyCore', 'constants', 'arrayFunctions', 'helpService',
  (fortyCore, constants, arrayFunctions, helpService) => ({
    templateUrl: 'views/directives/pagination/page_size.html',
    restrict: 'E',

    scope: {
      paginationParams: '=',
      paginationResults: '<',
      paginationChangedCallback: '&'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope = this.registerFunctions($scope)

      $scope.itemsPerPageOptions = [
        { label: 'Not paged', value: -1 },
        { label: '10', value: 10 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
        { label: '75', value: 75 },
        { label: '100', value: 100 },
        { label: '200', value: 200 }
      ]
    },

    registerFunctions ($scope: any) {
      return $scope;
    }
  })

])
