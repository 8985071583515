import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as Restangular from '../../../../vendor/restangular/restangular'
import angular from 'angular';

@Component({
  selector: 'ngDigest',
  templateUrl: './digest.component.html'
})
export class DigestComponent implements OnInit {
  // Properties
  private digest: any;
  private eventsArray: { key: string, value: any }[] = [];
  private session: any;

  constructor(
    @Inject('$rootScope') private $rootScope,
    private Restangular: Restangular
  ) {
  }

  ngOnInit(): void {
    this.session = this.$rootScope.session
    this.getDigest()
  }

  getDigest = () => {
    return this.Restangular.one(`users/${this.session.getUser().id}/digest`).get().then((response: any) => {
      this.digest = response
      this.eventsArray = Object.entries(this.digest.events).map(([key, value]) => ({ key, value }));
      console.log(this.eventsArray)
    }
    , (error: any) => {
      console.log(error)
    })
  }

  viewItem = (item: any) => {
    this.$rootScope.session.setOrganization(item.organization)
    this.$rootScope.session.goTo('/requirements/binder/' + item.id)
  }
}

angular
  .module('complyosClient')
  .directive('ngDigest', downgradeComponent({ component: DigestComponent }) as angular.IDirectiveFactory)
