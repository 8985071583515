// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS101: Remove unnecessary use of Array.from
//  * DS102: Remove unnecessary code created because of implicit returns
//  * DS205: Consider reworking code to avoid use of IIFEs
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// import angular from 'angular';
// import * as _ from 'lodash';
// var dayjs = require("dayjs")

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('organizationUsersController', ['$uibModal', '$rootScope', '$scope', 'confirmationModalFactory', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', function (
//   $uibModal: any,
//   $rootScope: any,
//   $scope: any,
//   confirmationModalFactory: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any
// ) {
//   const init = function () {
//     $scope.flashService = angular.copy(flashService)
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     const custom_states = {
//       zerousers: {
//         name: 'zerousers',
//         icon: 'fa-users',
//         text: 'This organization has no users'
//       },
//       activeorgprompt: {
//         name: 'activeorgprompt',
//         icon: 'fa-building',
//         text: 'Organization Required. Please select from filter above.'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.session_organization = $rootScope.session.getOrganization()
//     $scope.search_parameters = { user_query: '' }

//     $scope.$on('activeOrgIdUpdated', function () {
//       $scope.refresh()
//     })

//     $scope.refresh()
//   }

//   $scope.refresh = () => {
//     $scope.session_organization = $rootScope.session.getOrganization()
//     if ($rootScope.session.activeOrganizationPresent()) {
//       getUsers()
//     } else {
//       $scope.loadingStateService.set_state('activeorgprompt')
//     }
//   }

//   var getUsers = function () {
//     const users = Restangular.all(`organizations/${$scope.session_organization.id}/users`)

//     $scope.loadingStateService.set_state('loading')

//     return users.getList().then(function (response: any) { // success
//       $scope.users = response
//       if (response.length === 0) {
//         $scope.loadingStateService.set_state('zerousers')
//       } else {
//         $scope.loadingStateService.set_state('content')
//       }
//       return $scope.order_users_by_function = $scope.order_users_by_name
//     }
//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     });
//   }

//   $scope.attemptView = (user: any) => $scope.processingStateService.performFunction(user, () => $rootScope.session.goTo(`/user/${user.id}`))

//   $scope.is_session_user_weight_greater = (user: any) => $rootScope.session.getUser().user_role.role.weight >= user.user_role.role.weight

//   const enable_flash_service = (name: any, flash_class: any, custom_message: any) =>
//     $scope.flashService.add_alert({
//       name,
//       dismissable: true,
//       class: flash_class,
//       strong: 'Success',
//       icon: 'fa-thumbs-up',
//       message: custom_message
//     })

//   /* SORT */

//   $scope.order_users_by_name = (user: any) => user.profile.display_name

//   $scope.order_users_by_role = (user: any) => user.user_role.role.title

//   /* FILTER */

//   $scope.user_filter = function (user: any) {
//     const search_string = $scope.search_parameters.user_query.toLowerCase()

//     // if blocks below set standard for filtering users across application
//     // search by: display name, role, first name, last name, email, username
//     if ((user.email.toLowerCase()).indexOf(search_string) >= 0) {
//       return true
//     }
//     if ((user.username.toLowerCase()).indexOf(search_string) >= 0) {
//       return true
//     }
//     if ((user.profile.display_name.toLowerCase()).indexOf(search_string) >= 0) {
//       return true
//     }
//     if (
//       (user.user_role.role.title !== null) &&
//       (user.user_role.role.title !== undefined) &&
//       ((user.user_role.role.title.toLowerCase()).indexOf(search_string) >= 0) &&
//       (user.user_role.role.title !== 'Tech Support')
//     ) {
//       return true
//     }
//     if (
//       (user.profile.name_first !== null) &&
//       (user.profile.name_first !== undefined) &&
//       ((user.profile.name_first.toLowerCase()).indexOf(search_string) > 0)
//     ) {
//       return true
//     }
//     if (
//       (user.profile.name_last !== null) &&
//       (user.profile.name_last !== undefined) &&
//       ((user.profile.name_last.toLowerCase()).indexOf(search_string) >= 0)
//     ) {
//       return true
//     }
//   }

//   /* ADD USER */

//   $scope.openUserOrganizationModal = function () {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/user_organization_modal.html',
//       controller: 'userOrganizationModalController',
//       size: 'small'
//     })
//     return modalInstance.result.then(function (result: any) {
//       let user
//       if (result === 'create') {
//         return create_user()
//       } else {
//         let current_user_instance = 0

//         // a check to see if any of the people that you added were yourself, if so, this resets
//         // the session user data so that when navigating back to the home screen your orgs will show up
//         // if there are more than one
//         // TODO: ####_CHECK
//         if (_.find(result, { username: $rootScope.session.getUser().username })) {
//           $rootScope.session.getUser().organizations.push($scope.session_organization)
//         }

//         while (current_user_instance < result.length) {
//           objectManager.array_action($scope.users, result[current_user_instance], 'merge')
//           current_user_instance++
//         }
//         if (result.length > 1) {
//           enable_flash_service(
//             `${((() => {
//               const result1 = []
//               for (user of Array.from(result)) {
//                 result1.push(user.username)
//               }
//               return result1
//             })()).join(', ')} added`,
//             'alert-success',
//             `Users: ${((() => {
//               const result2 = []
//               for (user of Array.from(result)) {
//                 result2.push(user.username)
//               }
//               return result2
//             })()).join(', ')} were successfully added to ${$scope.session_organization.name}`
//           )
//         } else {
//           enable_flash_service(
//             `${(() => {
//               const result3 = []
//               for (user of Array.from(result)) {
//                 result3.push(user.username)
//               }
//               return result3
//             })()} added`,
//             'alert-success',
//             `${(() => {
//               const result4 = []
//               for (user of Array.from(result)) {
//                 result4.push(user.username)
//               }
//               return result4
//             })()} was successfully added to ${$scope.session_organization.name}`
//           )
//         }
//         if ($scope.loadingStateService.loading_state.name === 'nocontent') {
//           $scope.loadingStateService.set_state('content')
//         }
//       }
//     }
//     , function (reason: any) {
//       utils.log(`Modal dismissed at: ${dayjs().$d} reason: ${reason}`, 'info')
//     });
//   }

//   /* USER MODAL */

//   let openUserModal = function (user_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/user_modal.html',
//       controller: 'userModalController',
//       size: 'small',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions () {
//           return {
            
//             object: angular.copy(user_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     return modalInstance.result.then(function (result: any) {
//       // objectManager.array_action($scope.users, result, 'merge')
//       enable_flash_service(
//         'user_created',
//         'alert-info',
//         'user successfully created! You can now add them to the organization.'
//       )
//       if ($scope.loadingStateService.loading_state.name === 'nocontent') {
//         return $scope.loadingStateService.set_state('content')
//       }
//     }, function (reason: any) {

//     }).finally(function () {
//     });
//   }

//   /* CREATE USER */

//   // TODO for inherited orgs -- # organizations: $rootScope.session.getUser().organizations
//   var create_user = function () {
//     const new_user_object = {
//       organizations: $rootScope.session.getUser().organizations,
//       taggings: []
//     }
//     return openUserModal(new_user_object, 'create')
//   }

//   /* UPDATE USER */

//   $scope.openUserUpdateModal = (existing_user_object: any) => openUserModal(existing_user_object, 'update')

//   /* READ & UPDATE */

//   $scope.openUserReadModal = (existing_user_object: any) => openUserModal(existing_user_object, 'read')

//   // $scope.openUserReadModal = (user_object)->
//   //   # State service will set the object to a state of PENDING
//   //   # After action is performed or cancelled,
//   //   # that action will be responsible for setting object to RESTING state
//   //   $scope.processingStateService.performFunction(
//   //     user_object, ()->
//   //       openUserModal(user_object, 'read')
//   //   )

//   // $scope.openUserUpdateModal = (user_object)->
//   //   # State service will set the object to a state of PENDING
//   //   # After action is performed or cancelled,
//   //   # that action will be responsible for setting object to RESTING state
//   //   $scope.processingStateService.performFunction(
//   //     user_object, ()->
//   //       openUserModal(user_object, 'update')
//   //   )

//   // https://angular-ui.github.io/bootstrap/#/modal
//   openUserModal = function (user_object, ui_setting) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/user_modal.html',
//       controller: 'userModalController',
//       size: 'small',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions () {
//           return {
            
//             object: angular.copy(user_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     return modalInstance.result.then(function (result: any) {
//       if (ui_setting === 'create') {
//         objectManager.array_action($scope.users, result, 'merge')
//       }
//       if (ui_setting === 'update') {
//         objectManager.array_action($scope.users, result, 'update')
//         if ($rootScope.session.getUser().id === result.id) {
//           return $rootScope.session.setProfile(result.profile)
//         }
//       }
//     }, function (reason: any) {

//     }).finally(() =>
//       $scope.processingStateService.set(
//         user_object,
//         $scope.processingStateService.processing_states.RESTING
//       )
//     );
//   }

//   /* REMOVE USER */

//   // Confirmation for removal of user-organization association on remove button click
//   $scope.openUserOrganizationDestroyModal = function (user_object: any) {
//     const confirmation_object = {
//       severity: 'warning',
//       title: 'Remove User',
//       button_icon: 'fa-user-times',
//       button_text: 'Remove User',
//       // deny: method to run on dismiss
//       confirm () { return removeUserOrganizationAssociation(user_object) },
//       message: `<p>This action will:</p><ul><li>Remove ${user_object.profile.display_name} from the organization ${$scope.session_organization.name}</li><li>Unassign all requirements ${user_object.profile.display_name} is the compliance administrator for, responsible for, and assigned to at ${$scope.session_organization.name}</li><li>Delete all notifications ${user_object.profile.display_name} has at ${$scope.session_organization.name}</li></ul><p>This action is not reversible.</p>`
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(user_object, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(user_object, $scope.processingStateService.processing_states.RESTING)
//         }
//         return true
//       })
//     );
//   }

//   var removeUserOrganizationAssociation = function (user: any) {
//     const params = {
//       'user_organization[user_id]': user.id,
//       'user_organization[organization_id]': $scope.session_organization.id
//     }

//     return Restangular.all('user_organizations').remove(params).then(function (success: any) {
//       objectManager.array_action($scope.users, user, 'remove')
//       // if removing self from the organization it goes into session user and removes from org list
//       // then updates the active org to null and reroutes to home page
//       if (user.username === $rootScope.session.getUser().username) {
//         const org_index = _.indexOf($rootScope.session.getUser().organizations, { name: $scope.session_organization })
//         return $rootScope.session.getUser().organizations.splice(org_index, 1)
//       }
//     }, function (error: any) {
//       $scope.processingStateService.set(user, $scope.processingStateService.processing_states.RESTING)
//       $scope.flashService.process_error(error)
//       return utils.log(error)
//     });
//   }

//   return init()
// }])
