import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'naturalsort',
  pure: false
})
export class ArrayNaturalSortPipe implements PipeTransform {

  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    // array.sort((a: any, b: any) => {
    //   if (a[field] < b[field]) {
    //     return -1;
    //   } else if (a[field] > b[field]) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // });
    array.sort((a, b) => a[field].localeCompare(b[field], undefined, { numeric: true, sensitivity: 'base' }));
    return array;
  }

}
