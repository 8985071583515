/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.controller('dashboardEntityFilterController', ['$scope', '$rootScope', function (
  $scope: any,
  $rootScope: any
) {
  const init = function () {
    // console.log('init(), tag_filter.js')

    $scope.filterOptions = [
      {
        name: 'All Surveying Entities',
        id: undefined
      },
      {
        name: 'The Joint Commission (TJC)',
        id: 2
      },
      {
        name: 'TJC Documentation List',
        id: 74
      },
      {
        name: 'Centers for Medicare and Medicaid Services (CMS)',
        id: 7
      },
      {
        name: 'Environmental Protection Agency (EPA)',
        id: 15
      },
      {
        name: 'Urgent Care Association (UCA)',
        id: 56
      }
    ]

    $scope.entity_filter = $scope.filterOptions[0]
  }

  $scope.broadcastEntityFilterChange = () => {
    // console.log('$scope.entity_filter', $scope.entity_filter)
    $rootScope.$emit('entityFilterChange', $scope.entity_filter)
  }

  return init()
}])
