/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

angular.module('complyosClient').directive('referenceTreeNode', () =>

  ({
    scope: false,
    restrict: 'E',
    templateUrl: 'views/panels/system/references/tree_node.html',
    // replace: true

    link (scope: any, $element: any, $attributes: any, $ngModel: any) {}
  })
)
// init = ()->
//   console.log "scope.node"
//   console.log scope.node
//
// init()
