/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var LoadingIndicatorComponent = {
  selector: "loadingIndicator", //<loading-indicator>
  // templateUrl: 'views/directives/loading-state.html',
  template: require('../../views/directives/loading-state.pug'),
  restrict: 'E',
  bindings: {
    // loading_state: '=loadingState',
    // fun_size: '=funSize',
    // background: '=background',
    //               ^ -------- this business did not work

    loadingState: '=',
    funSize: '=',
    background: '='
  },

  controller: class LoadingIndicatorController {
    // BINDING PLACEHOLDERS
    private loadingState: any;
    private funSize: any = false;
    private background: any;

    // PROPERTIES
    private show: boolean = false;


    static $inject = ['$scope'];
    constructor(private $scope) {
      this.funSize = this.funSize || false;
    }


    $onInit = () => {
      // let that = this
      // this.$scope.$watch('loadingState', function (newValue: any, oldValue: any) {
      //   // do nothing if loading state is newly defined
      //   if (!newValue) { return }

      //   // hide the loading indicator if there is content
      //   if (that.loadingState.name === 'content' || that.loadingState.show_indicator === false) {
      //     that.show = false
      //   } else {
      //     that.show = true
      //   }
      // });
    }

    $doCheck () {

      // hide the loading indicator if there is content
      if (this.loadingState && (this.loadingState.name === 'content' || this.loadingState.show_indicator === false)) {
        this.show = false
      } else {
        this.show = true
      }
    }

    $onChanges (changes) {
      if (changes.loadingState && changes.loadingState.currentValue) {

        // hide the loading indicator if there is content
        if (this.loadingState.name === 'content' || this.loadingState.show_indicator === false) {
          this.show = false
        } else {
          this.show = true
        }
      }
    }
  }
}

angular
  .module('complyosClient')
  .component(LoadingIndicatorComponent.selector, LoadingIndicatorComponent);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('loadingIndicator', () =>
// ({
//   // templateUrl: 'views/directives/loading-state.html',
//   template: require('../../views/directives/loading-state.pug'),
//   restrict: 'E',
//   scope: {
//     loading_state: '=loadingState',
//     fun_size: '=funSize',
//     background: '=background'
//   },

//   link(scope: any, element: any, attrs: any) {
//     return scope.$watch('loading_state', function (newValue: any, oldValue: any) {
//       // do nothing if loading state is newly defined
//       if (!newValue) { return }

//       // hide the loading indicator if there is content
//       if (scope.loading_state.name === 'content' || scope.loading_state.show_indicator === false) {
//         scope.show = false
//       } else {
//         scope.show = true
//       }
//     });
//   }
// }))
