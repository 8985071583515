import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { helpService } from '../../../../scripts/services/help';
import { Utils } from '../../../../scripts/services/ajs-utils';
import { BinderService } from '../../../services/broadcast-services/binder.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BinderModalComponent } from '../../modals/binder-modal/binder-modal.component';

@Component({
  selector: 'ngInformation',
  templateUrl: './information.component.html'
})
export class InformationComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  private session: any;
  
  // Properties
  private binder: any;
  private dummyForm: FormGroup;
  private informationCollapse: boolean = false;
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$state') private $state,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject('$uibModal') private $uibModal,
    @Inject(helpService) private helpService: any,
    private Restangular: Restangular,
    private utils: Utils,
    private binderService: BinderService,
    private dialog: MatDialog
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.loadingStateService.init()
    this.helpService = angular.copy(helpService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    this.dummyForm = new FormGroup({
      ngFileManagerControl: new FormControl(null)
    })
    this.getBinder()
  }

  toggleCollapse = () => {
    this.informationCollapse = !this.informationCollapse
  }

  getBinder = () => {
    this.loadingStateService.set_state('loading')
    this.Restangular.one(`binders/${this.$state.params.binder_id}`).get().then((response: any) => {
      this.loadingStateService.process_success(response)
      this.binder = response
      this.broadcastBinder(this.binder)
    }
    ,(error: any) => {
      this.loadingStateService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  broadcastBinder = (binderObject: any) => this.binderService.broadcastBinder(binderObject)

  broadcastBinderUpdate = (oldBinderObject: any, newBinderObject: any) => {
    this.broadcastBinder(newBinderObject)
    this.binderService.triggerEntryApiCall()
    this.binderService.triggerNotificationApiCall()
    // this.$rootScope.$broadcast('notifications_update', { action: 'refresh' })
    // return this.$rootScope.$broadcast('entries_update', { action: 'refresh' })
  }

  navigateToManagementUrl = function (binder: any) {
    window.open(
      binder.mailbox_pairing_management_url,
      '_blank'
    )
  }

  openBinderModal = (binder: any, uiSetting: any) => {
    // save binder.organization_id for comparison after user saves
    const initalBinderOrganizationId = binder.organization_id

    const dialogRef = this.dialog.open(BinderModalComponent, {
      width: '600px',
      data: { binderObject: binder, uiSetting: uiSetting }
    })
    dialogRef.beforeClosed().subscribe(result => {
      if (result === undefined) {
        // Handle clicking outside the modal to close
        return dialogRef.close('outsideClick')
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'close') {
        return
      }

      if (result !== undefined && result !== 'outsideClick') {
        this.broadcastBinderUpdate(binder, result)
        this.binder = result
        // redirect user to binders page if organization has changed
        if (initalBinderOrganizationId !== result.organization_id) {
          return this.$rootScope.session.goTo('/requirements/binders')
        }
      }
    })
  }
}

angular
  .module('complyosClient')
  .directive('ngInformation', downgradeComponent({ component: InformationComponent }) as angular.IDirectiveFactory)
