import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { Utils } from '../../../../scripts/services/ajs-utils';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BinderService } from '../../../services/broadcast-services/binder.service';

@Component({
  selector: 'ngBinder',
  templateUrl: './binder.component.html'
})
export class BinderComponent implements OnInit, OnDestroy {  
  // Properties
  private binder: any;
  private binderId: string;
  private routeSub: any;

  // subscriptions
  private binderUpdateSubscription: Subscription;
  
  constructor(
    @Inject('$scope') private $scope,
    @Inject('$state') private $state,
    private Restangular: Restangular,
    private utils: Utils,
    private route: ActivatedRoute,
    private binderService: BinderService
  ) {
  }

  ngOnInit(): void {
    // handle the binderObject data from binder.service
    this.binderUpdateSubscription = this.binderService.binderUpdate$.subscribe(binderObject => {
        this.binder = binderObject
      }
    )

    this.getBinder()
  }

  getBinder = () => {
    // there might allready be a binder, dont get a duplicate.
    if (!this.binder) {
      // will need to upgrade state when fully on angular 15
      this.Restangular.all('binders').get(this.$state.params.binder_id).then((success: any) => {
        this.binder = success
      }, (error: any) => {
        this.utils.log(error, 'error', false)
      })
    }
  }

  ngOnDestroy() {
    if (this.binderUpdateSubscription) {
      this.binderUpdateSubscription.unsubscribe();
    }
  }
}

angular
  .module('complyosClient')
  .directive('ngBinder', downgradeComponent({ component: BinderComponent }) as angular.IDirectiveFactory)
