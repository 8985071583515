/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('usersAttributesController', ['$uibModal', '$rootScope', '$state', '$scope', 'loadingStateService', 'Restangular', 'utils', function (
  $uibModal: any,
  $rootScope: any,
  $state: any,
  $scope: any,
  loadingStateService: any,
  Restangular: any,
  utils: any
) {
  const init = function () {
    
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.loadingStateService.init()

    return get_user()
  }

  var get_user = () =>
    Restangular.all('users').get($state.params.user_id).then(function (success: any) {
      $scope.loadingStateService.process_success(success)
      return $scope.user = success
    }

    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      return utils.log(error)
    })

  $scope.openUserUpdateModal = function () {
    const modalInstance = $uibModal.open({
      templateUrl: 'views/modals/user_modal.html',
      controller: 'userModalController',
      size: 'small',
      resolve: {
      // this is the object that can be passed to the modal.
      // we copy it to eliminate binding issues
      // require it in the dependecies as modalOptions
        modalOptions () {
          return {
            
            object: angular.copy($scope.user),
            ui_setting: 'update'
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then(function (result: any) {
      _.merge($scope.user, result)
      if ($rootScope.session.getUser().id === result.id) {
        return $rootScope.session.setProfile(result.profile)
      }
    }

    , function (reason: any) {
    });
  }

  /* LAUNCH PASSWORD UPDATE MODAL */
  $scope.openUserPasswordModal = function (user: any) {
    if ($rootScope.session.getUser().id === user.id) {
      const modalInstance = $uibModal.open({
        templateUrl: 'views/modals/update_user_password_modal.html',
        controller: 'updateUserPasswordModalController',
        size: 'small',
        resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
          modalOptions () {
            return {
              
              user: angular.copy($rootScope.session.getUser())
            }
          }
        }
      })

      // on close
      // - grab the returned result,
      // - or log out a reason
      return modalInstance.result.then(function (result: any) {

      } // do nothing, passwords are not visible anywhere

      , function (reason: any) {
      });
    }
  }

  return init()
}])
