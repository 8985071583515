/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('aspectModalController', ['$scope', '$uibModalInstance', 'complyosServices', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', function (
  $scope: any,
  $uibModalInstance: any,
  complyosServices: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any
) {
  const init = function () {
    $scope.processingStateService = fortyCore.processingStateService
    
    $scope.flashService = angular.copy(flashService)
    $scope.ui_setting = modalOptions.ui_setting
    $scope.aspect = modalOptions.object
    $scope.parent_node = modalOptions.node
    get_reference_object()

    return $scope.flashService.listen_for_alert($scope)
  }

  var get_reference_object = function () {
    let id
    if ($scope.ui_setting === 'create') {
      id = $scope.parent_node.factory.parent_id
    } else {
      ({ id } = $scope.aspect)
    }

    return Restangular.all('reference_objects').get(id).then(function (success: any) {
      $scope.reference_object = success.plain()
      // bind the aspect to the referenece_object so that the header automatically updates
      return $scope.reference_object[$scope.parent_node.depth] = $scope.aspect
    }
    , (error: any) => console.log(error));
  }

  $scope.click_modal_icon = function () {
    console.log('$scope.aspect')
    return console.log($scope.aspect)
  }

  $scope.submit_form = (form: any, object: any) =>
    // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(form, function () {
      if (form.$valid) {
        return begin_save(object)
      } else {
        $scope.processingStateService.set(
          form,
          $scope.processingStateService.processing_states.RESTING
        )
        return form.failed_submission = true
      }
    })

  var begin_save = function (object: any) {
    if ($scope.ui_setting === 'create') {
      return create_object(object)
    } else {
      return update_object(object)
    }
  }

  var create_object = (object: any) => Restangular.all('reference_objects/aspects').post(object).then(function (success: any) {
    $scope.aspect = success
    return closeModalAndReturn()
  }, (error: any) => // loop through each error_type in the data object
  (() => {
    const result = []
    for (var error_type in error.data) {
      var type = 0
      // then loop through each item specified to that error_type
      result.push((() => {
        const result1 = []
        while (type < error.data[error_type].length) {
          const alert = {
            name: 'aspect_creation',
            dismissable: true,
            class: 'alert-danger',
            icon: 'fa-exclamation-triangle',
            strong: `${error_type + '_error'}`,
            message: `${$scope.parent_node.type} ${$scope.aspect.identifier.toUpperCase()} ${error.data[error_type]}`
          }
          // emit that error
          flashService.emit_alert($scope, alert)
          result1.push(type++)
        }
        return result1
      })())
    }
    return result
  })()

  ).finally(function () {
  })

  var update_object = (object: any) => Restangular.one('reference_objects/aspects', object.id).patch({ aspect: object }).then(function (success: any) {
    $scope.aspect = success.plain()
    return closeModalAndReturn()
  }, (error: any) => $scope.flashService.process_error(error)).finally(function () {
  })

  /* MODAL FUNCTIONS */

  // dismiss the modal and provide a reason
  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  // close modal, and pass object back to initiator
  var closeModalAndReturn = function () {
    $uibModalInstance.close($scope.aspect)
  }

  return init()
}])
