import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

@Component({
  selector: 'ngFilterPill',
  templateUrl: './filter-pill.component.html'
})
export class FilterPill implements OnInit {
  private tooltipText: string = "";

  @Input() index!: number;
  @Input() displayName!: string;
  @Input() value!: string;
  @Input() isRequired!: boolean;
  @Input() isHidden: boolean = false;
  @Input() includeDescendants: boolean = false;
  @Input() descendantsName: string | undefined = undefined;
  @Input() isIgnored: boolean = false;

  @Output() removeCallback = new EventEmitter<number>();

  ngOnInit() {
    this.tooltipText = this.getTooltip()
  }

  removeClicked() {
    this.removeCallback.emit(this.index)
  }

  getTooltip = () => {
    let text = ''
    if (this.isRequired) {
      let requiredText = this.displayName + ' is locked or required. '
      text = text + requiredText
    }
    if (this.isIgnored) {
      let ignoredText = 'This filter-type is disabled on this page, it is ignored when your filters are applied'
      text = text + ignoredText
    }

    return text
  }
}

angular
  .module('complyosClient')
  .directive('ngFilterPill', downgradeComponent({ component: FilterPill }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('filterPill', [
//     'fortyCore', 'helpService',
//     // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fortyCore' implicitly has an 'any' type... Remove this comment to see the full error message
//     (fortyCore, helpService) => ({
//         templateUrl: 'views/directives/filter_pill.html',
//         restrict: 'E',

//         scope: {
//             index: '<',
//             displayName: '<',
//             value: '<',
//             removeCallback: '&',
//             isRequired: '<',
//             isHidden: '<',
//             includeDescendants: '<',
//             descendantsName: '<',
//             isIgnored: '<'
//         },

//         link($scope: any, $ele: any, $attrs: any) {
//             $scope.getTooltip = () => {
//                 let text = ''
//                 if ($scope.isRequired) {
//                     let requiredText = $scope.displayName + ' is locked or required. '
//                     text = text + requiredText
//                 }
//                 if ($scope.isIgnored) {
//                     let ignoredText = 'This filter-type is disabled on this page, it is ignored when your filters are applied'
//                     text = text + ignoredText
//                 }

//                 return text
//             }

//             $scope.tooltipText = $scope.getTooltip()
//             
//             $scope.helpService = angular.copy(helpService)
//             return $scope.fortyCore = fortyCore
//         }
//     })
// ])
