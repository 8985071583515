/* eslint-disable
    no-undef,
*/
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.controller('organizationDashboardController', ['$q', '$rootScope', '$scope', 'arrayFunctions', 'loadingStateService', 'Restangular', 'utils', 'filterService', function (
  $q: any,
  $rootScope: any,
  $scope: any,
  arrayFunctions: any,
  loadingStateService: any,
  Restangular: any,
  utils: any,
  filterService: any
) {
  const init = function () {
    $scope.previousPills = null
    $scope.loadingStateService = angular.copy(loadingStateService)
    $scope.loadingStateService.init()
    $scope.dashboardCollectionBinderCount = 0
    $scope.param_to_remove = ['sorted', 'pagination_params']
    $scope.filtersToDisable = ['visible', 'role_ids']
    $scope.outstandingPromises = []

    watchPillFilter()
  }

  var watchPillFilter = () => {
    $scope.$on('filterChange-dashboard', function (e: any, pills: any) {
      let binderCountMayChange = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['date', 'sorted', 'pagination_params']) || $scope.previousPills === null
      $scope.previousPills = pills
      if (binderCountMayChange) {
        abortAllPromises($scope.outstandingPromises)
        setTimeout(() => getBinderCount(pills, binderCountMayChange), 50)
      } else {
        $rootScope.$broadcast('refresh-dashboard-widgets', pills, $scope.dashboardCollectionBinderCount)
      }
    })
  }

  var getBinderCount = function (pills: any, binderCountMayChange: any) {
    $scope.loadingStateService.set_state('loading')

    var queryParams = {
      model: 'Binder',
      method: 'binder_count',
      param_data: {}
    }

    let newPills = $rootScope.storeService.pillsToRemove(pills, $scope.param_to_remove)
    queryParams.param_data = $rootScope.storeService.stringifyTagParams(
      $rootScope.storeService.pillsToObject(newPills)
    )

    let abort = $q.defer()
    $scope.outstandingPromises.push(abort)

    Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(queryParams).then(function (success: any) {
      $scope.dashboardCollectionBinderCount = success.data.collection_count
      $scope.loadingStateService.set_state('content')
      if ($scope.dashboardCollectionBinderCount > 0 || binderCountMayChange) {
        // $scope.broadcastPaginationResults(success.pagination)
        $rootScope.$broadcast('refresh-dashboard-widgets', pills, $scope.dashboardCollectionBinderCount)
      }
    }
    , function (error: any) {
      $scope.loadingStateService.process_error(error)
      utils.log(error)
    })
  }

  var abortAllPromises = (promiseArray: any) => {
    $rootScope.$broadcast('cancel-pending-filter-queries')
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  $scope.shouldShowSurveyTracker = function () {
    let organization = $rootScope.session.getOrganization()

    if (!organization) {
      return false
    }

    if (
      organization.date_survey_last ||
      (organization.date_survey_next_begin && organization.date_survey_next_end)
    ) {
      return true
    } else {
      return false
    }
  }

  init()
}])
