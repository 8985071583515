/* eslint-disable
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


angular.module('complyosClient').directive('changeAlertIcon', [
  'helpService',
  (helpService: any) => ({
    templateUrl: 'views/directives/change_alert_icon.html',
    restrict: 'E',

    scope: {
      change_alert: '=changeAlert'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      return $scope.helpService = helpService
    }
  })

])
