import * as angular from 'angular';

// import { Restangular } from 'ngx-restangular'

export class njsResource {
    private apiRoot: string = "http://localhost:3000/ng12service";

    constructor(
        // @Inject(HttpClient) public HttpClient: HttpClient,
        // @Inject(Restangular) private restangular: Restangular
    ) {
        console.log("[njs -> x] || this is an example [njs] service that can be used in upgraded to be used in ng12 entities OR used as normal in njs entities")
    }

    whoami(runFrom: string) {
        console.log('njs (upgraded for ng12) service, called from :' + runFrom)
    }
}

angular
    .module('complyosClient')
    .service('njsResource', njsResource);