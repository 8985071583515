import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { FlashService } from '../../services/flash_service.service';
import * as Restangular from '../../../vendor/restangular/restangular'
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';// 'app/scripts/services/ajs-confirmation_modal_factory';

@Component({
  selector: 'ngStateManager', // ng-state-manager
  templateUrl: './state-manager.component.html',
  styleUrls: ['./state-manager.component.scss']
})
export class StateManagerComponent implements OnInit, OnChanges {
  private flashService: any;

  @Input() isArchivable: boolean = false;
  @Input() isLockable: boolean = false;
  @Input() isUpdateable: boolean = false;
  @Input() isInactivateable: boolean = false;
  @Input() isEditState: boolean = false;
  @Input() isArchivedPending: boolean = false;
  @Input() isInactivePending: boolean = false;
  @Input() binderPairingState: boolean = false;
  @Input() hasCitation: boolean = false;
  @Input() lockedWarning: string = 'This item will be locked. Locking will cause the item to be uneditable until it is unlocked by an admin.';
  @Input() unlockedWarning: string = 'This item will be unlocked. Unlocking will cause the item to be editable again.';
  @Input() archivedWarning: string = 'This item will be archived. Archiving will cause the item to be uneditable and is irreversible. Use with caution.';
  @Input() inactiveWarning: string = 'This item will be inactivated.';
  @Input() activateWarning: string = 'This item will be reactivated.';
  @Input() stateTypes: Array<string>;
  
  @Input() formModel: any;
  
  private failure = false;
  private staged_state_value: any;
  private pairedArchivedWarning: string = 'This requirement is paired and will need to be deleted in the Engineering Central Future PM Admin prior to archiving in Complyos. For more information on what is paired, see the Integrations tab.';
  private citationWarning: string = 'This Requirement cannot be archived because Citation Received box is checked.';
  private filtered_state_configs: Array<any> = [];

  constructor(
    @Inject(FlashService) flashService: FlashService,
    private Restangular: Restangular,
    @Inject('$scope') private $scope,
    private confirmationModalFactory: ConfirmationModalFactory,
  ) {
    this.flashService = angular.copy(flashService)


  }

  confirm_archived_toggle = (transitionTo: string) => {
    var toggleTo = !this.staged_state_value.archived;
    if (toggleTo == true) {
      var that = this
      const confirmation_object = {
        severity: 'danger',
        title: 'Archive Requirement',
        button_icon: 'fa-archive',
        button_text: 'Archive',
        message: '<p>Are you sure you want to archive this Requirement?</p>',
        confirm() { that.archived_toggle() }
      }

      return this.confirmationModalFactory.openConfirmationModal(confirmation_object)
    } else {
      this.archived_toggle()
    }
  }

  archived_toggle = () => {
    if (!this.archived_disabled()) {
      // $ngModel.$modelValue.archived = !$ngModel.$modelValue.archived
      var archivedStateConfig = this.state_configs.find(sc => sc.name == 'archived');
      this.staged_state_value.archived = !this.staged_state_value.archived
      archivedStateConfig.isPending = !archivedStateConfig.isPending
      archivedStateConfig.currentValue = this.staged_state_value.archived
    }
  }

  inactive_toggle = () => {
    if (!this.inactive_disabled()) {
      var inactiveStateConfig = this.state_configs.find(sc => sc.name == 'inactive');
      this.staged_state_value.inactive = !this.staged_state_value.inactive
      inactiveStateConfig.isPending = !inactiveStateConfig.isPending
      inactiveStateConfig.currentValue = this.staged_state_value.inactive
    }
  }

  private state_configs = [
    {
      name: 'archived',
      true: { 
        currentState: 'Archived', 
        icon: 'fa fa-archive',
        transitionState: 'Unarchived', 
        transitionIcon: 'fa fa-folder-open-o', 
        transitionFunction: this.confirm_archived_toggle, 
        currentStateColor: '#E74C3C', 
        transitionStateColor: '#3498db',
        warnings: [
          {
            textClass: "text-warning",
            warningText: "This item will be archived. Archiving will cause the item to be uneditable and is irreversible. Use with caution."
          }, 
        ]
      },
      false: { 
        currentState: 'Unarchived', 
        icon: 'fa fa-folder-open-o',
        transitionState: 'Archived', 
        transitionIcon: 'fa fa-archive', 
        transitionFunction: this.confirm_archived_toggle, 
        currentStateColor: '#3498db', 
        transitionStateColor: '#E74C3C'
      },
      default: false,
      isPending: false,
      currentValue: undefined
    },
    {
      name: 'inactive',
      true: { 
        currentState: 'Inactive', 
        icon: 'fa fa-ban',
        transitionState: 'Active', 
        transitionIcon: 'fa fa-check-circle', 
        transitionFunction: this.inactive_toggle,
        currentStateColor: '#e6da00', 
        transitionStateColor: '#e6da00',
        // warnings: [
        //   {
        //     textClass: "text-warning",
        //     warningText: "This item will be archived. Archiving will cause the item to be uneditable and is irreversible. Use with caution."
        //   }, 
        // ]
      },
      false: { 
        currentState: 'Active', 
        icon: 'fa fa-check-circle',
        transitionState: 'Inactive', 
        transitionIcon: 'fa fa-ban',
        transitionFunction: this.inactive_toggle,
        currentStateColor: '#e6da00', 
        transitionStateColor: '#e6da00'
      },
      default: false,
      isPending: false,
      currentValue: undefined
    },
  ];

  private state_change_tracker = []



  ngOnInit(): void {
    this.staged_state_value = angular.copy(this.formModel)
    this.update_state_configs(this.staged_state_value)
    return this.listen_for_form_save_event()
  }

  ngOnChanges(changes: any) {
    if (changes.formModel) {
      if (this.isUpdateable) {
        this.staged_state_value = angular.copy(this.formModel)
        this.update_state_configs(this.staged_state_value)
      }
    }
  }

  update_state_configs = (updated_stage_state_values) => {
    if (!this.staged_state_value)
      return
    // var stateTypes = ["inactive","locked","archived"]
    var stateTypes = this.stateTypes
    stateTypes.forEach(st => {
      var current_state_config = this.state_configs.find(sc => sc.name == st)
      if (current_state_config)
        if (updated_stage_state_values[st] === true || updated_stage_state_values[st] === false)
          current_state_config.currentValue = updated_stage_state_values[st]
        else 
          current_state_config.currentValue = current_state_config.default

      // find the index of current_state_config in filtered_state_configs
      const existingIndex = this.filtered_state_configs.findIndex(fsc => fsc.name === current_state_config.name)

      if (existingIndex > -1) {
        // ppdate the existing object in filtered_state_configs
        this.filtered_state_configs[existingIndex] = current_state_config
      } else {
        // push to filtered_state_configs if it doesn't already exist
        this.filtered_state_configs.push(current_state_config)
      }
    })
  }

  /* HELPERS */

  // If our staged_state_value and original_value don't exist, we cannot calculate based on them
  // They exist after initialization, so this method can be used as a sanity check for that
  is_initialized() {
    return !!this.staged_state_value && !!this.formModel
  }

  /* WATCHERS, LISTENERS AND EMITTERS */
  listen_for_form_save_event() {
    // if (this.uiSetting != 'read') {
    return this.$scope.$on('state_manager_start', (event: any, data: any) => {
      return this.save_state()
    });
    // }
  }

  save_state() {
    const patch_object = this.get_changed_state()

    // Empty objects dont need to be patched
    if (patch_object === null) {
      // No changes were made, therefore, no changes should be emitted
      return this.emit_completion_event(null)
    } else {
      return this.Restangular.all(`/states/${this.formModel.id}`).patch(patch_object).then((success_response: any) => {
        this.staged_state_value = success_response.state
        return this.failure = false
      }
        , (error_response: any) => {
          const alert = {
            name: 'update_state',
            dismissable: true,
            class: 'alert-warning',
            icon: 'fa-exclamation-triangle',
            strong: 'Error:',
            message: `There was an error updating the state. ${this.flashService.default_alert_by_code(error_response.status).message}`
          }
          this.flashService.emit_alert(this.$scope, alert)
          return this.failure = true
        }).finally(() => {
          return this.emit_completion_event(this.staged_state_value)
        });
    }
  }

  get_changed_state() {
    const patch_object: any = {}

    if (!!this.formModel && !!this.staged_state_value && (!!this.formModel.locked !== !!this.staged_state_value.locked)) {
      patch_object.locked = this.staged_state_value.locked
    }

    if (!!this.formModel && !!this.staged_state_value && (!!this.formModel.archived !== !!this.staged_state_value.archived)) {
      patch_object.archived = this.staged_state_value.archived
    }

    if (!!this.formModel && !!this.staged_state_value && (!!this.formModel.inactive !== !!this.staged_state_value.inactive)) {
      patch_object.inactive = this.staged_state_value.inactive
    }

    if ($.isEmptyObject(patch_object)) {
      return null
    } else {
      return patch_object
    }
  }

  emit_completion_event(changed_state: any) {
    const return_object = {
      manager: 'state_manager',
      object: changed_state,
      fault: this.failure
    }

    return this.$scope.$emit('state_manager_finished', return_object)
  }

  /* INACTIVE */

  // Show method will run, so we know everything is initialized.
  // Because show runs first, we don't need that check in subsequent view related methods
  // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
  inactive_show() {
    // If 'I have been initialized' && 'I am inactivatable' && 'I am inactive'
    return this.is_initialized() && ((this.isInactivateable && this.isUpdateable) || !!this.staged_state_value.inactive)
  }

  inactive_disabled() {
    return !this.isInactivateable
  }

  inactive_warning_show() {
    return this.is_initialized() && !this.inactive_disabled() && !this.formModel.inactive && this.staged_state_value.inactive
  }

  activate_warning_show() {
    return this.is_initialized() && !this.inactive_disabled() && this.formModel.inactive && !this.staged_state_value.inactive
  }

  /* LOCKED */

  // Show method will run, so we know everything is initialized.
  // Because show runs first, we don't need that check in subsequent view related methods
  // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
  locked_show() {
    // If 'I have initialized' && (('I am able to be interacted with') || 'I am locked')
    return this.is_initialized() && ((this.isLockable && this.isUpdateable) || !!this.staged_state_value.locked)
  }

  locked_warning_show() {
    return this.is_initialized() && !this.locked_disabled() && !this.formModel.locked && this.staged_state_value.locked
  }

  unlocked_warning_show() {
    return this.is_initialized() && !this.locked_disabled() && this.formModel.locked && !this.staged_state_value.locked
  }

  locked_disabled() {
    return !this.isUpdateable
  }

  locked_toggle() {
    if (!this.locked_disabled()) {
      return this.staged_state_value.locked = !this.staged_state_value.locked
    }
  }

  /* ARCHIVED */

  // Show method will run, so we know everything is initialized.
  // Because show runs first, we don't need that check in subsequent view related methods
  // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
  archived_show() {
    // If 'I have initialized' && (('I an interactable') || 'I am archived')
    return this.is_initialized() && ((this.isArchivable) || !!this.formModel.archived)
  }

  archived_warning_show() {
    return this.is_initialized() && !this.archived_disabled() && !this.formModel.archived && this.staged_state_value.archived
  }
  archived_warning = () => {
    if (this.archived_warning_show())
      return this.archivedWarning
  }

  paired_archived_warning_show() {
    return this.is_initialized() && !this.archived_disabled() && !this.formModel.archived && this.staged_state_value.archived && this.binderPairingState
  }

  citation_warning_show () {
    return this.is_initialized() && !this.archived_disabled() && !this.formModel.archived && this.staged_state_value.archived && this.hasCitation
  }

  archived_disabled() {
    return !this.isUpdateable || (!!this.formModel.archived && !this.isEditState)
  }
}

// complyosClient.directive('', [
//   function (
//   ) {
//     return {
//       require: 'ngModel',

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
//       link($scope: any, $element: any, $attributes: any, $ngModel: any) {
//         $scope.sm = {





//           /* ACTIONS */

//           // this syncronizes the $ngModel.$modelValue and @staged_state_value
//           // we use this when not updating, because staged values are unnecessary
//           watch_ng_model() {
//             return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
//               if (!this.is_updateable) {
//                 return this.staged_state_value = $ngModel.$modelValue
//               }
//             });
//           }

//         } // END STATE MANAGER

//         // there is a delay between the time the directive is ready and ngModel is
//         // we watch for ready and run code once
//         let ng_model_ready = false
//         return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
//           if (ng_model_ready === false) {
//             $scope.sm.initialize()
//             return ng_model_ready = true
//           }
//         });
//       }
//     };
//   }

// ])


angular
  .module('complyosClient')
  .directive('ngStateManager', downgradeComponent({ component: StateManagerComponent }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// complyosClient.directive('stateManager', [
//   'flashService',
//   'Restangular',
//   function (
//     flashService: any,
//     Restangular: any
//   ) {
//     return {
//       restrict: 'E',
//       templateUrl: 'views/directives/state_manager.html',
//       require: 'ngModel',
//       scope: {
//         isArchivable: '=?',
//         isLockable: '=?',
//         isUpdateable: '=?',
//         isInactivateable: '=?',
//         binderPairingState: '=?',
//         lockedWarning: '=?',
//         unlockedWarning: '=?',
//         archivedWarning: '=?',
//         inactiveWarning: '=?',
//         activateWarning: '=?'
//       },

//       //  $scope is an Angular scope object.
//       //  $element is the jqLite-wrapped element that this directive matches.
//       //  $attributes is a hash object with key-value pairs of normalized attribute names and their corresponding attribute values.
//       link($scope: any, $element: any, $attributes: any, $ngModel: any) {
//         $scope.sm = {

//           /* INIT */
//           initialize() {
//             // set the defaults 
//             this.is_archivable = $scope.isArchivable !== undefined ? $scope.isArchivable : false
//             this.is_lockable = $scope.isLockable !== undefined ? $scope.isLockable : false
//             this.is_inactivateable = $scope.isInactivateable !== undefined ? $scope.isInactivateable : false
//             this.is_updateable = $scope.isUpdateable !== undefined ? $scope.isUpdateable : false
//             this.binder_pairing_state = $scope.binderPairingState !== undefined ? $scope.binderPairingState : false
//             this.archived_warning = $scope.archivedWarning !== undefined ? $scope.archivedWarning : 'This item will be archived. Archiving will cause the item to be uneditable and is irreversible. Use with caution.'
//             this.paired_archived_warning = $scope.pairedArchivedWarning !== undefined ? $scope.pairedArchivedWarning : 'This requirement is paired. The pairing key needs to be deleted prior to archiving this requirement. Click the Integrations tab to view the pairings that need to be deleted.'
//             this.inactive_warning = $scope.inactiveWarning !== undefined ? $scope.inactiveWarning : 'This item will be inactivated.'
//             this.activate_warning = $scope.activateWarning !== undefined ? $scope.activateWarning : 'This item will be reactivated.'
//             this.locked_warning = $scope.lockedWarning !== undefined ? $scope.lockedWarning : 'This item will be locked. Locking will cause the item to be uneditable until it is unlocked by an admin.'
//             this.unlocked_warning = $scope.unlockedWarning !== undefined ? $scope.unlockedWarning : 'This item will be unlocked. Unlocking will cause the item to be editable again.'

//             this.staged_state_value = angular.copy($ngModel.$modelValue)

//             this.watch_ng_model()
//             return this.listen_for_form_save_event()
//           },

//           /* HELPERS */

//           // If our staged_state_value and original_value don't exist, we cannot calculate based on them
//           // They exist after initialization, so this method can be used as a sanity check for that
//           is_initialized() {
//             return !!this.staged_state_value && !!$ngModel.$modelValue
//           },

//           /* INACTIVE */

//           // Show method will run, so we know everything is initialized.
//           // Because show runs first, we don't need that check in subsequent view related methods
//           // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
//           inactive_show() {
//             // If 'I have been initialized' && 'I am inactivatable' && 'I am inactive'
//             return this.is_initialized() && ((this.is_inactivateable && this.is_updateable) || !!this.staged_state_value.inactive)
//           },

//           inactive_disabled() {
//             return !this.is_inactivateable
//           },

//           inactive_toggle() {
//             if (!this.inactive_disabled()) {
//               return this.staged_state_value.inactive = !this.staged_state_value.inactive
//             }
//           },

//           inactive_warning_show() {
//             return this.is_initialized() && !this.inactive_disabled() && !$ngModel.$modelValue.inactive && this.staged_state_value.inactive
//           },

//           activate_warning_show() {
//             return this.is_initialized() && !this.inactive_disabled() && $ngModel.$modelValue.inactive && !this.staged_state_value.inactive
//           },

//           /* LOCKED */

//           // Show method will run, so we know everything is initialized.
//           // Because show runs first, we don't need that check in subsequent view related methods
//           // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
//           locked_show() {
//             // If 'I have initialized' && (('I am able to be interacted with') || 'I am locked')
//             return this.is_initialized() && ((this.is_lockable && this.is_updateable) || !!this.staged_state_value.locked)
//           },

//           locked_warning_show() {
//             return this.is_initialized() && !this.locked_disabled() && !$ngModel.$modelValue.locked && this.staged_state_value.locked
//           },

//           unlocked_warning_show() {
//             return this.is_initialized() && !this.locked_disabled() && $ngModel.$modelValue.locked && !this.staged_state_value.locked
//           },

//           locked_disabled() {
//             return !this.is_updateable
//           },

//           locked_toggle() {
//             if (!this.locked_disabled()) {
//               return this.staged_state_value.locked = !this.staged_state_value.locked
//             }
//           },

//           /* ARCHIVED */

//           // Show method will run, so we know everything is initialized.
//           // Because show runs first, we don't need that check in subsequent view related methods
//           // Essentially, we are preventing errors in the console that, although they exist, would negatively affect nothing
//           archived_show() {
//             // If 'I have initialized' && (('I an interactable') || 'I am archived')
//             return this.is_initialized() && ((this.is_archivable && this.is_updateable) || !!$ngModel.$modelValue.archived)
//           },

//           archived_warning_show() {
//             return this.is_initialized() && !this.archived_disabled() && !$ngModel.$modelValue.archived && this.staged_state_value.archived
//           },

//           paired_archived_warning_show() {
//             return this.is_initialized() && !this.archived_disabled() && !$ngModel.$modelValue.archived && this.staged_state_value.archived && this.binder_pairing_state
//           },

//           archived_disabled() {
//             return !this.is_updateable || !!$ngModel.$modelValue.archived
//           },

//           archived_toggle() {
//             if (!this.archived_disabled()) {
//               // $ngModel.$modelValue.archived = !$ngModel.$modelValue.archived
//               console.log(this.staged_state_value.archived)
//               return this.staged_state_value.archived = !this.staged_state_value.archived
//             }
//           },

//           /* ACTIONS */

//           get_changed_state() {
//             const patch_object: any = {}

//             if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.locked !== !!this.staged_state_value.locked)) {
//               patch_object.locked = this.staged_state_value.locked
//             }

//             if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.archived !== !!this.staged_state_value.archived)) {
//               patch_object.archived = this.staged_state_value.archived
//             }

//             if (!!$ngModel.$modelValue && !!this.staged_state_value && (!!$ngModel.$modelValue.inactive !== !!this.staged_state_value.inactive)) {
//               patch_object.inactive = this.staged_state_value.inactive
//             }

//             if ($.isEmptyObject(patch_object)) {
//               return null
//             } else {
//               return patch_object
//             }
//           },

//           save_state() {
//             const patch_object = this.get_changed_state()

//             // Empty objects dont need to be patched
//             if (patch_object === null) {
//               // No changes were made, therefore, no changes should be emitted
//               return this.emit_completion_event(null)
//             } else {
//               return Restangular.all(`/states/${$ngModel.$modelValue.id}`).patch(patch_object).then((success_response: any) => {
//                 this.staged_state_value = success_response.state
//                 return this.failure = false
//               }
//                 , (error_response: any) => {
//                   const alert = {
//                     name: 'update_state',
//                     dismissable: true,
//                     class: 'alert-warning',
//                     icon: 'fa-exclamation-triangle',
//                     strong: 'Error:',
//                     message: `There was an error updating the state. ${flashService.default_alert_by_code(error_response.status).message}`
//                   }
//                   flashService.emit_alert($scope, alert)
//                   return this.failure = true
//                 }).finally(() => {
//                   return this.emit_completion_event(this.staged_state_value)
//                 });
//             }
//           },

//           /* WATCHERS, LISTENERS AND EMITTERS */
//           listen_for_form_save_event() {
//             return $scope.$on('state_manager_start', (event: any, data: any) => {
//               return this.save_state()
//             });
//           },

//           emit_completion_event(changed_state: any) {
//             const return_object = {
//               manager: 'state_manager',
//               object: changed_state,
//               fault: this.failure
//             }

//             return $scope.$emit('state_manager_finished', return_object)
//           },

//           // this syncronizes the $ngModel.$modelValue and @staged_state_value
//           // we use this when not updating, because staged values are unnecessary
//           watch_ng_model() {
//             return $scope.$watch(() => $ngModel.$modelValue, (new_value: any, old_value: any, scope: any) => {
//               if (!this.is_updateable) {
//                 return this.staged_state_value = $ngModel.$modelValue
//               }
//             });
//           }

//         } // END STATE MANAGER

//         // there is a delay between the time the directive is ready and ngModel is
//         // we watch for ready and run code once
//         let ng_model_ready = false
//         return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
//           if (ng_model_ready === false) {
//             $scope.sm.initialize()
//             return ng_model_ready = true
//           }
//         });
//       }
//     };
//   }

// ])
