/* eslint-disable
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('FooterController', ['$uibModalInstance', '$scope', 'utils', function (
  $uibModalInstance: any,
  $scope: any,
  utils: any
) {
  const init = function () {
    $scope.initReady = false
    return $uibModalInstance.rendered.then(() => $scope.initReady = true)
  }

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  const closeModalAndReturn = function (object: any) {
    $uibModalInstance.close(object)
  }

  return init()
}])
