// /* eslint-disable
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'


// angular.module('complyosClient').directive('reviewNoteCount', [
//   'helpService',
//   'fortyCore',
//   '$rootScope',
//   function(helpService, fortyCore, $rootScope) {
//     return {
//       templateUrl: 'views/directives/review_note_count.html',
//       restrict: 'E',
//       scope: {
//         counts: '=counts'
//       },
//       link: function($scope, $ele, $attrs) {
//         $scope.helpService = helpService;
//         $scope.fortyCore = fortyCore;

//         $scope.pushCitationPill = function() {
//           let citationPill = {
//             isUnique: true,
//             Field: 'visible',
//             displayName: 'Visibility',
//             displayValue: 'Citation',
//             Value: 'citation'
//           };
//           $rootScope.$broadcast('push-new-filter', citationPill);
//         };
//       }
//     };
//   }
// ]);