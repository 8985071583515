import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';
import { objectManager } from '../../../scripts/services/ajs-object_manager';
import { Utils } from '../../../scripts/services/ajs-utils';
import { FlashService } from '../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
import * as FileSaver from 'file-saver';
import { constants } from '../../../../app/scripts/services/constants'
var dayjs = require("dayjs")

@Component({
  selector: 'ngStore',
  templateUrl: './store.component.html'
})
export class StoreComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private reportLoadingStateService: any;
  private session: any;

  // Properties
  private previousPills: Array<any> = [];
  private outstandingPromises: Array<any> = [];
  private requirements: Array<any> = [];
  private requirementsCount: number = 0;
  private organizationsSelected: boolean = false;
  private defaultFilters: Array<any> = [];
  private organizationSelected: boolean = false;

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    // complyosServices: any,   // <--- does not seem to be needed
    // ENV: any,                // <--- does not seem to be needed
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(objectManager) private objectManager: objectManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
  ) {
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      nocontent: {
        icon: 'fa-exclamation-triangle',
        text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
      },
      ready: {
        icon: 'fa-download',
        text: 'Download Report'
      }
    }
    this.defaultFilters = [
      {
        isUnique: true,
        isRequired: true,
        isHidden: true,
        Field: 'requirement_visible',
        Op: constants.FilterType.Radio,
        displayName: 'Visibility',
        displayValue: 'Active',
        Value: 'active',
        dateCreated: dayjs().$d
      }
    ]
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.reportLoadingStateService = angular.copy(this.loadingStateService)
    this.reportLoadingStateService.loading_states = _.merge(this.reportLoadingStateService.loading_states, custom_states)
    this.reportLoadingStateService.set_state('ready')

    this.watchEntityFilter()
  }

  isOrgPillSelected = (pills: any) => {
    this.organizationSelected = pills.some((pill:any) => { return pill.Field === 'organizations_ids'})
  }

  /* CRUD */

  getRequirements = function (pills: any) {
    this.previousPills = pills
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }

    this.loadingStateService.set_state('loading')
    return this.Restangular.one('requirements').get(query_params).then(
      (success: any) => {
        this.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        this.requirements = success.items
        this.requirementsCount = success.pagination_info.totalCount

        if (this.requirements.length === 0) {
          this.loadingStateService.set_state('nocontent')
        } else {
          this.getBinders(pills)
        }
      }
      , (error: any) => {
        this.loadingStateService.process_error(error)
      }
    );
  }

  /* REQUIREMENT */
  getRequirement = (id: any) => this.Restangular.all('requirements').get(id).then((success: any) => {
    return success.plain();
    }, 
    (error: any) => { 
      this.flashService.process_error(error);
      throw error;
    }
  )

  getBinders = function (pills: any) {
    // $scope.orgPills = JSON.parse(JSON.stringify(pills)).filter(p => p.Field === 'organizations_ids').map(r => r.Value)
    let pillsCopy = JSON.parse(JSON.stringify(pills))
    // let organizationPills = pillsCopy.filter(p => p.Field === 'organizations_ids')
    // if (!Array.isArray(organizationPills) || organizationPills.length === 0) {
    //   // only try to show configured binders if there are any organization filters
    //   $scope.loadingStateService.set_state('content')
    //   return
    // }

    if (Array.isArray(this.requirements)) {
      // add temporary requirement pills to ONLY return configured binders for currently visible requirements
      let subQueryRequirementPills = this.requirements.map((r: any) => {
        return {
          Field: 'requirement_ids',
          Op: constants.FilterType.Multi,
          Value: r.id,
          dateCreated: dayjs().$d,
          displayName: 'Requirements',
          displayValue: r.display_name
        }
      })
      pillsCopy = pillsCopy.concat(subQueryRequirementPills)
    }

    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pillsCopy)
      )
    }

    return this.Restangular.all('binders/store').getList(query_params).then((success: any) => {
        this.addBindersToRequirements(success)
        this.loadingStateService.set_state('content')
    }, (error: any) => {
      this.flashService.add_alert({
        name: 'get_binders_alert',
        dismissable: true,
        class: 'alert-warning',
        icon: 'fa-warning',
        message:
          'Cannot load configured requirements at this time. ' +
          'Please try again later.'
      })
    })
  }

  addBindersToRequirements = (binders: any) => _.each(binders, (binder: any) => this.findRequirementAddBinder(binder))

  findRequirementAddBinder = (binder: any) => _.each(this.requirements,(requirement: any) => {
    if (requirement.id === binder.requirement_id) {
      if (requirement.binders === undefined) {
        return requirement.binders = [binder]
      } else {
        return requirement.binders.push(binder)
      }
    }
  })

  isSystemAdmin = () => this.$rootScope.session.getUser().user_role.role.id === 1

  /* MODALS */

  openBinderCreateModal = (requirement: any) => {
    var organization_id
    try {
      let organization = this.$rootScope.session.getOrganization()
      organization_id = organization.id
    } catch (ex) {}
    const binder_object = {
      organization_id: organization_id,
      requirement_id: requirement.id,
      requirement: requirement,
      schedule_enabled: true,
      display_name: requirement.display_name
    }

    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/binder_modal.html',
      controller: 'binderModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            
            object: angular.copy(binder_object),
            ui_setting: 'create'
          }
        }
      }
    })

    // on close grab the returned (result) or something with a (reason)
    return modalInstance.result.then((result: any) => {
      return this.findRequirementAddBinder(result)
    }

    , function (reason: any) {
    });
  }

  openRequirementModal = async (requirement: any, uiSetting: any) => {
    let modalInstance;
    try {
      requirement = await this.getRequirement(requirement.id);
      modalInstance = this.$uibModal.open({
        // templateUrl: 'views/modals/requirement_modal.html',
        // controller: 'requirementModalController',
        templateUrl: 'views/modals/ajs-requirement-modal-adapter.html',
        controller: 'ajsRequirementModalAdapter',
        size: 'lg',
        resolve: {
          // this is the object that can be passed to the modal.
          // we copy it to eliminate binding issues
          // require it in the dependecies as modalOptions
          modalOptions () {
            return {

              object: angular.copy(requirement),
              uiSetting
            }
          }
        }
      })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    }
    catch(error) {
      console.error('Error:', error);
      var object = object || undefined
      return modalInstance.result.then((result: any) => {

      }, (reason: any) => {

      }).finally(() =>
        this.processingStateService.set(
          // TODO: ####_CHECK: WHAT IS OBJECT SUPPOSED TO BE ????
          object,
          this.processingStateService.processing_states.RESTING
        )
      );
    }
  }

  openBulkConfigureRequirementsModal = () => {
    let items = ['configure']
    var modalInstance = this.$uibModal.open({
      // animation: $ctrl.animationsEnabled,
      component: 'bulkConfigureRequirements', //ngRequirementModal test
      // templateUrl: 'views/directives/bulk_configure_requirements.html',
      size: 'xl',
      backdrop: 'static',
      resolve: {
        items: function () {
          return items
        }
      }
    })

    let that = this
    modalInstance.result.then((params: any) => {
      console.log(params)
      // $ctrl.selected = selectedItem
    }, function (params: any) {
      console.log(params)
      if (params === 'submit') {
        that.abortAllPromises(that.outstandingPromises)
        setTimeout(() => that.getRequirements(that.previousPills), 50)
      }
    })
  }

  exportRequirementsList = () => {
    this.reportLoadingStateService.set_state('loading')
    let query_params = {
      report: 'requirements_export',
      param_data: {}
    }
    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(this.previousPills)
    )

    return this.Restangular.setFullResponse(true).one(`reports/requirements_export`).get(query_params).then((response: any) => {
      var report_file = new Blob([response.data], { type: 'text/csv' })
      var report_name = `requirements_export_report_${dayjs().format('YYYY-MM-DD')}.csv`
      FileSaver.saveAs(report_file, report_name)
      this.reportLoadingStateService.set_state('ready')
    }, function (error: any) {
      this.reportLoadingStateService.set_state('error')
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  getReportButtonClass = () => {
    if (this.reportLoadingStateService.loading_state.name === 'error') {
      return 'btn-warning'
    } else {
      return 'btn-default'
    }
  }

  viewBinder = (binder_object: any) => this.processingStateService.performFunction(binder_object, () => this.$rootScope.session.goTo(`/requirements/binder/${binder_object.id}`))

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  watchEntityFilter = () => {
    this.$scope.$on('filterChange-dashboard', (e: any, pills: any) => {
      this.abortAllPromises(this.outstandingPromises)
      this.isOrgPillSelected(pills)
      setTimeout(() => this.getRequirements(pills), 50)
    })
  }
}

angular
  .module('complyosClient')
  .directive('ngStore', downgradeComponent({ component: StoreComponent }) as angular.IDirectiveFactory)
