/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.directive('userRoleManager', [
  'flashService',
  'Restangular',
  '$timeout',
  function (
    flashService: any,
    Restangular: any,
    $timeout: any
  ) {
    return {
      restrict: 'E',
      templateUrl: 'views/directives/user_role_manager.html',
      require: 'ngModel',
      scope: {
        allowUpdate: '=',
        showError: '=',
        uiSetting: '='
      },

      //  $scope is an Angular scope object.
      //  $element is the jqLite-wrapped element that this directive matches.
      //  $attributes is a hash object with key-value pairs of normalized
      //  attribute names and their corresponding attribute values.
      //  $ngModel is the actual model that this directive interacts with
      link ($scope: any, $element: any, $attributes: any, $ngModel: any) {
        $scope.urm = {

          initialize () {
            this.pending_user_role = { role: {} }
            this.get_roles()
            this.watch_ng_model()
            this.listen_for_start_event()

            // $ngModel.$validate() causes modelValue to go undefined
            // http://stackoverflow.com/questions/29111328/angularjs-1-3-validator-causes-modelvalue-to-go-undefined
            return $ngModel.$options = {
              allowInvalid: true
            }
          },

          watch_ng_model () {
            $scope.$watch(() => $ngModel.$modelValue, function (new_value: any, old_value: any, scope: any) {
              // console.log('newVal: ', new_value)
              // console.log('oldVal: ', old_value)
              if ($ngModel.$modelValue && $ngModel.$modelValue.id && $scope.urm.roles) {
                $scope.urm.pending_user_role.role = _.find($scope.urm.roles, (role: any) => role.id === $ngModel.$modelValue.role.id)
                $scope.urm.validate()
              }
            })
          },

          get_roles () {
            const roles = Restangular.one('roles')
            // const lobs = Restangular.all('tags/show_lob_tags')
            // lobs.getList().then((response: any) => 
            //   {
            //     console.log('lobs: ' ,response.plain())
            //   }
            // );
            
            return roles.getList().then((response: any) => {
              this.roles = response

              if ($scope.uiSetting !== 'read') {
                let filteredRoles = []

                for (let i = 0; i < this.roles.length; i++) {
                  const role = this.roles[i]

                  filteredRoles.push(role)
                }

                this.roles = filteredRoles
              }

              return $timeout(() => {
                return this.set_pending_user_role()
              }
              , 1000)
            }
            , (error: any) => flashService.emit_alert(
              $scope,
              flashService.default_alert_by_code(error.status)
            )
            );
          },

          set_pending_user_role () {
            if ($scope.uiSetting === 'create') {
              this.pending_user_role.role = {}
            } else if ($ngModel.$modelValue && ($ngModel.$modelValue !== undefined) && $ngModel.$modelValue.id) {
              this.pending_user_role.role = _.find(this.roles, (role: any) => role.id === $ngModel.$modelValue.role.id)
            }

            // Now that all the pending code has been set up
            // we can add the validators and validate to avoid
            // any client errors
            this.add_validators()
            return this.validate()
          },

          update_pending_user_role () {
            $ngModel.$modelValue.$pending_user_role = this.pending_user_role.role
            return this.validate()
          },

          add_validators () {
            return $ngModel.$validators.required = (modelValue: any, viewValue: any) => {
              if ($attributes.required === false) {
                return true
              } else {
                if (this.pending_user_role.role.id === undefined) {
                  return false
                } else {
                  return true
                }
              }
            };
          },

          validate () {
            return $ngModel.$validate()
          },

          show_validation_error () {
            return $scope.showError && !$ngModel.$valid
          },

          listen_for_start_event () {
            return $scope.$on('user_role_manager_start', (event: any, data: any) => {
              if ($scope.allowUpdate) {
                return this.start_update_user_role()
              } else {
                return this.emit_completion_event()
              }
            });
          },

          start_update_user_role () {
            // there is no creating a user_role. only updating one that exists.
            // the user_roles are created after the user is and linked in the api

            // in order to do update a user_role there must be an id
            // if you are editing a user that is already in the system the it is
            // a piece of cake, it has an id and we can rock and roll
            if ($ngModel.$modelValue && $ngModel.$modelValue.id) {
              return this.update_user_role()

            // If you want to set a user_role and a user at the same time
            // you must first make a user. and then get the id, this is handled
            // mostly by the user modal and the save relay. they will save the user
            // the user will come back from the api with a default user_role
            // the watch_ng_model function will will look at this user_role and do
            // its best to only update the non touched attributes. Including id
            // this takes time and we need to be patient
            } else {
              // console.log "waiting for user_role id..."
              return $scope.$watch(() => $ngModel.$modelValue
                , (new_value: any, old_value: any, scope: any) => {
                  if ($ngModel.$modelValue.id !== undefined) {
                    return this.update_user_role()
                  }
                });
            }
          },

          update_user_role () {
            // console.log "patching user_role #{@ng_model_value.id}"

            const patch_object = {
              id: $ngModel.$modelValue.id,
              role_id: this.pending_user_role.role.id,
              role: this.pending_user_role.role
            }

            const user_role = Restangular.all(`user_roles/${patch_object.id}`)
            return user_role.patch(patch_object).then((success: any) => $ngModel.$modelValue = success, (error: any) => {
              flashService.emit_alert(
                $scope,
                flashService.default_alert_by_code(error.status)
              )
              return this.fault = error
            }).finally(() => {
              return this.emit_completion_event()
            });
          },

          emit_completion_event () {
            const return_object = {
              manager: 'user_role_manager',
              object: $ngModel.$modelValue,
              fault: this.fault
            }
            return $scope.$emit('user_role_manager_finished', return_object)
          }

        }

        // there is a delay between the time the directive is ready and ngModel is
        // we watch for ready and run code once
        let ng_model_ready = false
        return $scope.$watch(() => $ngModel, function (new_value: any, old_value: any, scope: any) {
          if (ng_model_ready === false) {
            $scope.urm.initialize()
            return ng_model_ready = true
          }
        });
      }
    };
  }

])
