// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
//     no-unused-vars,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var dayjs = require("dayjs")


// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('entryModalController', ['$scope', '$uibModalInstance', 'complyosServices', 'confirmationModalFactory', 'dateService', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'utils', '$rootScope', 'ENV', function (
//   $scope: any,
//   $uibModalInstance: any,
//   complyosServices: any,
//   confirmationModalFactory: any,
//   dateService: any,
//   flashService: any,
//   fortyCore: any,
//   modalOptions: any,
//   Restangular: any,
//   utils: any,
//   $rootScope: any,
//   ENV: any
// ) {
//   const init = function () {
//     $scope.entry = modalOptions.object
//     $scope.entry.notes = []
//     $scope.entry_validation_model = ""
    
//     $scope.flashService = angular.copy(flashService)
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.ui_setting = modalOptions.ui_setting
//     $scope.flashService.listen_for_alert($scope)
//     $scope.watch_complete_status()
//     if ($scope.entry.id) {
//       return (get_full_entry($scope.ui_setting))
//     }
//   }

//   $scope.show_task_cannot_attest_message = () => {
//     if (($rootScope.session.getUser().id === $scope.entry.binder.user_assigned_id) && $scope.entry.binder.requirement.task_agent_cannot_attest) {
//       return true
//     } else {
//       return false
//     }
//   }

//   $scope.allow_closed_not_complete = () => {
//     if (
//       $scope.entry.binder.requirement.allow_closed_not_complete ||
//       $scope.session.getUser().user_role.role.title === 'System Admin' ||
//       $scope.session.getUser().user_role.role.title === 'Organization Admin' ||
//       $scope.session.getUser().user_role.role.title === 'Reviewer'
//     ) {
//       return true
//     } else if (modalOptions.ui_setting === 'create') {
//       return false
//     } else {
//       return false
//     }
//   }

//   /* RESTANGULAR */
//   var get_full_entry = (action: any) => {
//     $scope.isLoading = true;
//     return Restangular.all('entries').get($scope.entry.id).then(function (response: any) { // success
//       $scope.isLoading = false;
//       $scope.entry = response.plain()
//       return format_database_dates($scope.entry)
//     }
//     , (error: any) => utils.log(error))
//   }

//   $scope.pretty_array_length = function (array: any) {
//     if (array && (array.length > 0)) {
//       return `(${array.length})`
//     }
//   }

//   // COMPLETE STATE & NOTES //

//   $scope.should_allow_complete_status = function (status_name: any) {
//     let allowed = true

//     if (status_name === 'attested_as_complete') {
//       if ($scope.entry.binder.requirement.task_agent_cannot_attest) {
//         if ($rootScope.session.getUser().id === $scope.entry.binder.user_assigned_id) {
//           allowed = false
//         }
//       }
//     }

//     if (status_name === 'attested_as_complete' || status_name === 'closed_not_complete') {
//       if ($scope.entry.binder.requires_review && $scope.session.getUser().user_role.role.title === 'User') {
//         allowed = false
//       }
//     }

//     if (status_name === 'closed_not_complete' && !$scope.entry.binder.requirement.allow_closed_not_complete) {
//       allowed = false
//     }

//     if (status_name === 'partially_complete' && !$scope.entry.binder.requirement.allow_partially_complete) {
//       allowed = false
//     }

//     return allowed
//   }

//   $scope.watch_complete_status = function () {
//     $scope.$watch('entry.complete_status', (newState: any, oldState: any) => {
//       if (newState !== oldState) {
//         $scope.populate_note(newState)
//       }
//     })
//   }

//   $scope.populate_note = function (complete_status: any) {
//     if (complete_status === 'attested_as_complete') {
//       $scope.prepopulated_note = `I, ${$scope.session.getUser().profile.display_name}, certify this requirement, to the best of my knowledge, has all information and documentation necessary to demonstrate compliance with all applicable standards contained within this requirement.`
//     } else if (complete_status === 'requires_resubmission') {
//       $scope.prepopulated_note = `Task documentation did not meet this requirement's applicable standards and requires resubmission. Returned to schedule by ${$scope.session.getUser().profile.display_name}.`
//     } else {
//       $scope.prepopulated_note = ''
//     }
//   }

//   $scope.willGenerateNextTaskFromNextTaskDate = function (entry: any) {
//     let willGenerateNextTaskFromNextTaskDate = entry.binder.requirement.user_prompted_date_generation === true && entry.created_by !== 'user'
//     return willGenerateNextTaskFromNextTaskDate
//   }

//   var format_database_dates = function (object: any) {
//     if (typeof object.date_completed === 'string') {
//       if ($scope.ui_setting !== 'create') {
//         object.date_completed = dateService.convert_from_imt_date(object.date_completed)
//       }
//     }

//     if (typeof object.date_submitted === 'string') {
//       if ($scope.ui_setting !== 'create') {
//         object.date_submitted = dateService.convert_from_imt_date(object.date_submitted)
//       }
//     }

//     if (typeof object.event_date === 'string') {
//       if ($scope.ui_setting !== 'create') {
//         object.event_date = dateService.convert_from_imt_date(object.event_date)
//       }
//     }

//     if (typeof object.user_provided_next_event_date === 'string') {
//       if ($scope.ui_setting !== 'create') {
//         object.user_provided_next_event_date = dateService.convert_from_imt_date(object.user_provided_next_event_date)
//       }
//     }
//   }

//   const start_date_conversion = function (dates_to_convert: any, object: any) {
//     // we want to update the date submitted only when the entry is checked off as complete.
//     if (object.complete_status === 'attested_as_complete') {
//       object.date_submitted = dayjs().$d
//       dates_to_convert.push('date_submitted')
//     }

//     if ((typeof object.date_completed !== 'undefined') && (object.date_completed !== null)) {
//       dates_to_convert.push('date_completed')
//     }

//     if ((typeof object.user_provided_next_event_date !== 'undefined') && (object.user_provided_next_event_date !== null)) {
//       dates_to_convert.push('user_provided_next_event_date')
//     }

//     return dateService.convert_dates_before_saving(object, dates_to_convert)
//   }

//   /* FORM SUBMISSION AND SAVE PROCESSES */

//   $scope.user_can_attest = function () {
//     if ($scope.entry.binder && $scope.entry.binder.requires_review) {
//       return $rootScope.session.getUser().user_role.role.weight > 0
//     } else {
//       return true
//     }
//   }

//   $scope.note_required = (entry: any) => {
//     if (
//       entry.complete_status === 'partially_complete' ||
//       entry.complete_status === 'requires_resubmission'
//     ) {
//       return true
//     } else {
//       return false
//     }
//   }

//   $scope.attachment_required = (entry: any) => {
//     if (
//       (entry.complete_status === 'attested_as_complete' && entry.binder.requirement.compliance_method !== 'Observation') ||
//       entry.complete_status === 'closed_not_complete'
//     ) {
//       return true
//     } else {
//       return false
//     }
//   }

//   $scope.submit_form = (form: any, object: any) =>
//     // State service will set the form to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible
//     // for setting object to RESTING state
//     $scope.processingStateService.performFunction(form, function () {
//       let promise_chain = []

//       // only update state_manager when the entry is inactive
//       // && you are system administrator
//       // (inactive entries won't update in the API anyway)
//       // otherwise update everything
//       if ($rootScope.session.getUser().user_role.role.title === 'System Admin') {
//         if (object.state.inactive) {
//           promise_chain = [
//             'state_manager_promise_alias'
//           ]
//         } else {
//           promise_chain = [
//             'base_object_save_promise_alias',
//             'attachment_manager_promise_alias',
//             'note_manager_promise_alias',
//             'entry_complete_manager_promise_alias',
//             'state_manager_promise_alias'
//           ]
//         }
//       } else {
//         promise_chain = [
//           'base_object_save_promise_alias',
//           'attachment_manager_promise_alias',
//           'note_manager_promise_alias',
//           'entry_complete_manager_promise_alias'
//         ]
//       }

//       if (form.$valid) {
//         // this date conversion process used to be before the processingStateService perform method.
//         // But a bug was discovered that when submitting an invalid form,
//         // it would convert the dates, but then would fail, making the date fields go blank
//         // after the failed submission, making the form non-submittable and unfixable due to disabled fields.
//         // to circumvent this issue, we convert the dates only after we know we have a valid form.
//         const dates_to_convert = ['event_date']
//         let object_copy = angular.copy(object)
//         object_copy = start_date_conversion(dates_to_convert, object_copy)

//         // July 19, 2017:
//         // see notes on jade file
//         // saving the state manager has been taken out
//         return complyosServices.save_relay.start(
//           $scope,
//           object_copy,
//           form,
//           'entries',
//           promise_chain,
//           closeModalAndReturn
//         )
//       } else {
//         $scope.processingStateService.set(
//           form,
//           $scope.processingStateService.processing_states.RESTING
//         )
//         return form.failed_submission = true
//       }
//     })

//   /* MODAL FUNCTIONS */

//   // dismiss the modal and provide a reason
//   $scope.dismissModal = function () {
//     $uibModalInstance.dismiss('cancel')
//   }

//   // close modal, and pass object back to initiator
//   var closeModalAndReturn = function (data:any) {
//     $scope.entry.id = data.id
//     get_full_entry('close').then(function () {
//       const returning = $scope.entry
//       $scope.entry.event_date = dateService.convert_to_imt_date($scope.entry.event_date)
//       return $uibModalInstance.close($scope.entry)
//     })
//   }

//   return init()
// }])
