import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ArrayFunctions } from '../../../scripts/utilities/ajs-array_functions';
import * as Restangular from '../../../vendor/restangular/restangular'
import { FlashService } from '../../services/flash_service.service';
import * as FileSaver from 'file-saver';
import angular from 'angular';
import dayjs from 'dayjs';
import papa from 'papaparse';

@Component({
  selector: 'ngReportFilterModal',
  templateUrl: './report-filter-modal.component.html'
})
export class ReportFilterModal implements OnInit {
  // Injected Services
  private flashService: any;
  private loadingStateService: any;
  
  // Properties
  private correctFiltersSelected: boolean = false
  private showFormRequirements: boolean = false
  private defaultArrayOfDisabledFilters: Array<string> = ['search_hits', 'date', 'single_requirements_id', 'organizations_ids', 'requirement_ids', 'visible', 'acknowledged_scope', 'schedule_visibility', 'requirement_visible', 'role_ids', 'schedule_generation_state', 'interval_ids', 'visibility', 'published_visibility_store', 'reference_id', 'published_visibility', 'assignee_user_id', 'tag_ids', 'surveying_ids', 'review_window', 'email_logs_visibility']
  private currentPills: Array<any> = [];
  private helpTextToDisplay: Array<any> = [];
  @Input () reportConfig!: any;
  @Output() closeFn = new EventEmitter();
  @Output() dismissFn = new EventEmitter();

  constructor(
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(FlashService) flashService: FlashService,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
    ) 
    { 
    this.loadingStateService = angular.copy(loadingStateService)
    this.flashService = angular.copy(flashService)
    }

  ngOnInit(): void {
    this.loadingStateService.init()
    this.enableFilters(this.reportConfig)
    this.watchEntityFilter()
    this.showFormRequirements = this.reportConfig.report_name == 'form_response' ? true : false
  }

  enableFilters = (report_config) => {
    if(report_config.enabled_filters) {
      this.defaultArrayOfDisabledFilters = this.defaultArrayOfDisabledFilters.filter((el) => {
        return !report_config.enabled_filters.includes(el)
      })
    }
  }

  runReport = () => {
    this.setLoadingFlashAlerts()
    let queryParams = this.buildQueryParams()
    this.Restangular.setFullResponse(true).one(`reports/${this.reportConfig.report_name}`).get(queryParams).then((response: any) => {
      this.loadingStateService.process_success(response)
      this.checkReportSizeAndParseCsv(response)
      }, (error: any) => {
      this.setErrorFlashAlerts(error)
    // -
    }).finally(() => {
      // TODO: use seperate service for fullReponseQueries: https://github.com/mgonto/restangular#setfullresponse
      // Reset the full response setting
      this.Restangular.setFullResponse(false)
    })
  }

  buildQueryParams = () => {
      let queryParams = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(this.currentPills)
      )
    }
    return queryParams
  }

  checkReportSizeAndParseCsv = (response) => {
    response.reportFile = new Blob([response.data], { type: 'text/csv' })
    response.reportName = `${this.reportConfig.report_name}_${dayjs().format('YYYY-MM-DD')}.csv`
    if (response.data.length < 500000) { // larger files break the preview.
      response.csvPreview = this.parseCsv(response.data)
      this.close(response)

    } else {
      let error = { data: ['Report too large to preview. Report is downloading.'], statusText: 'Warning' }
      this.downloadReport(response)
      this.setErrorFlashAlerts(error)
      this.loadingStateService.loading_state = {
        name: `report_downloaded`,
        icon: 'fa-download',
        text: `Report Downloaded`
      }
    }
  }

  downloadReport = (report) => {
    FileSaver.saveAs(report.reportFile, report.reportName)
    return 'report exported'
  }

  parseCsv = function (data_to_parse: any) {
    const csvRowArray = papa.parse(data_to_parse).data
    const csvData = {
      headers: [],
      rows: []
    }
    let row = 0
    while (row < csvRowArray.length) {
      // Each array item, which is a row, is a string
      // we now split that row into an array on a comma to create our "cells"
      if (row === 0) {
        // First row is our headers
        csvData.headers = csvRowArray[row]
      } else {
        // every row after is actual data
        csvData.rows.push(csvRowArray[row])
      }
      row++
    }
    return csvData
  }

  close = (event) => {
    this.closeFn.emit(event)
  }

  dismiss = () => {
    this.dismissFn.emit()
  }

  checkSelectedPills = (pillFieldsToCheck: Array<string>) => {
    this.correctFiltersSelected = this.reportConfig.required_filters.every(item => pillFieldsToCheck.includes(item))
  }

  setHelpText = (pillFieldsToCheck: Array<string>) => {
    this.helpTextToDisplay = []
    this.reportConfig.required_filters.map(item => {
      if(!pillFieldsToCheck.includes(item)) {
        this.helpTextToDisplay.push(this.reportConfig.help_text[item])
      }
    })
  }

  // call methods to set help text and disable run button
  setReportDisplay(pillFieldsToCheck) {
    if(this.reportConfig.required_filters) {
      this.checkSelectedPills(pillFieldsToCheck)
      this.setHelpText(pillFieldsToCheck)
    } else {
      this.correctFiltersSelected = true
    }
  }

  // Error and loading Handling
  setLoadingFlashAlerts = () => {
    this.flashService.clear_alerts()
    this.loadingStateService.loading_state = {
      name: `loading_report`,
      icon: 'fa-gear fa-spin',
      text: `Loading Report ${this.reportConfig.pretty_name}`
    }
  }

  setErrorFlashAlerts = (error: any) => {
    this.loadingStateService.process_error(error)
    this.flashService.add_alert({
      name: 'report_error',
      class: 'alert-danger',
      strong: error.statusText,
      message: error.data[0]
    })
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any) => {
      let pillFieldsToCheck = pills.map(pill => pill.Field)
      this.currentPills = pills
      this.setReportDisplay(pillFieldsToCheck)
    }
    this.$scope.$on('filterChange-dashboard', refresh)
  }

}

angular
  .module('complyosClient')
  .directive('ngReportFilterModal', downgradeComponent({ component: ReportFilterModal }) as angular.IDirectiveFactory)