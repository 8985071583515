// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('requirementsBinderInformationController', ['$rootScope', '$state', '$scope', '$uibModal', 'fileService', 'loadingStateService', 'Restangular', 'utils', 'helpService', function (
//   $rootScope: any,
//   $state: any,
//   $scope: any,
//   $uibModal: any,
//   fileService: any,
//   loadingStateService: any,
//   Restangular: any,
//   utils: any,
//   helpService: any
// ) {
//   const init = function () {
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.loadingStateService.init()
//     $scope.helpService = helpService
//     get_binder()
//   }

//   var get_binder = () =>
//     Restangular.one(`binders/${$state.params.binder_id}`).get().then(function (response: any) {
//       $scope.loadingStateService.process_success(response)
//       $scope.binder = response
//       broadcast_binder($scope.binder)
//     }
//     , function (error: any) {
//       $scope.loadingStateService.process_error(error)
//       return utils.log(error)
//     })

//   $scope.openBinderModal = function (binder: any, ui_setting: any) {
//     // save binder.organization_id for comparison after user saves
//     const initalBinderOrganizationId = binder.organization_id

//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/binder_modal.html',
//       controller: 'binderModalController',
//       size: 'small',
//       resolve: {
//         modalOptions () {
//           return {
            
//             object: angular.copy(binder),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     return modalInstance.result.then(function (result: any) {
//       broadcast_binder_update(binder, result)
//       $scope.binder = result
//       // redirect user to binders page if organization has changed
//       if (initalBinderOrganizationId !== result.organization_id) {
//         return $rootScope.session.goTo('/binders')
//       }
//     }
//     , function (reason: any) {
//     });
//   }

//   var broadcast_binder = (binder_object: any) => $rootScope.$broadcast('binder_update', binder_object)

//   var broadcast_binder_update = function (old_binder_object: any, new_binder_object: any) {
//     broadcast_binder(new_binder_object)
//     $rootScope.$broadcast('notifications_update', { action: 'refresh' })
//     return $rootScope.$broadcast('entries_update', { action: 'refresh' })
//   }

//   $scope.naturalCompare = function (a, b) {
//     return a.value.localeCompare(b.value, undefined, { numeric: true, sensitivity: 'base' });
//   }

//   $scope.navigate_to_management_url = function (binder: any) {
//     window.open(
//       binder.mailbox_pairing_management_url,
//       '_blank'
//     )
//   }

//   return init()
// }])
