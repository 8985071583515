// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('homeLinksController', ['$location', '$rootScope', '$scope', '$window', 'utils', '$http', 'Restangular', function (
//   $location: any,
//   $rootScope: any,
//   $scope: any,
//   $window: any,
//   utils: any,
//   $http: any,
//   Restangular: any
// ) {
//   $scope.links = {
//     dashboard: {
//       icon: 'fa-tachometer',
//       title: 'Dashboard',
//       text: 'Compliance at a glance',
//       click () {
//         return $rootScope.session.goTo('/organization/dashboard')
//       },
//       visible () {
//         // LOGIC TAKEN FROM NAVIGATION.JS
//         // navLinkOrganizationDashboard > visible()
//         // Keep that current, then apply here.
//         return (
//           // $rootScope.session.activeOrganizationPresent() &&
//           $rootScope.session.activeUserPresent() &&
//           $rootScope.session.getUser().user_role.role.weight >= 30 &&
//           $rootScope.session.getUser().user_role.role.title !== 'Requirement Author'
//         )
//       }
//     },
//     schedule: {
//       icon: 'fa-calendar',
//       title: 'Schedule',
//       text: "What's coming due or is overdue",
//       click () {
//         return $rootScope.session.goTo('/requirements/schedule')
//       },
//       visible () {
//         // LOGIC TAKEN FROM NAVIGATION.JS
//         // navLinkRequirementsSchedule > visible()
//         // Keep that current, then apply here.
//         return (
//           $rootScope.session.activeUserPresent() &&
//           // $rootScope.session.activeOrganizationPresent() &&
//           // $rootScope.session.activeOrganizationInteractable() &&
//           $rootScope.session.userCan('binders_read') &&
//           $rootScope.session.userCan('organizations_read')
//         )
//       }
//     },
//     list: {
//       icon: 'fa-list',
//       title: 'List',
//       text: 'See all of your configured requirements here',
//       click () {
//         return $rootScope.session.goTo('/requirements/binders')
//       },
//       visible () {
//         // LOGIC TAKEN FROM NAVIGATION.JS
//         // navLinkRequirementsSchedule > visible()
//         // Keep that current, then apply here.
//         return (
//           $rootScope.session.activeUserPresent() &&
//           // $rootScope.session.activeOrganizationPresent() &&
//           $rootScope.session.userCan('binders_read') &&
//           $rootScope.session.userCan('organizations_read')
//         )
//       }
//     },
//     digest: {
//       icon: 'fa-newspaper-o',
//       title: 'Notifications',
//       text: 'All of your daily notifications in one place',
//       click () {
//         return $scope.digest_collapsed = !$scope.digest_collapsed
//       },
//       visible () {
//         return (
//           $rootScope.session.activeUserPresent() && (
//             ($rootScope.session.getUser().user_role.role.title === 'Organization Admin') ||
//             ($rootScope.session.getUser().user_role.role.title === 'User')
//           )
//         )
//       }
//     },
//     feed: {
//       icon: 'fa-bullhorn',
//       title: 'Communication Feed',
//       text: 'Important announcements about the Complyos platform',
//       click () {
//         return $scope.feed_collapsed = !$scope.feed_collapsed
//       },
//       visible () {
//         return (
//           $rootScope.session.activeUserPresent()
//         )
//       }
//     },
//     store: {
//       icon: 'fa-shopping-cart',
//       title: 'Store',
//       text: 'View, acquire and configure new requirements',
//       click () {
//         return $rootScope.session.goTo('/requirements/store')
//       },
//       visible () {
//         // LOGIC TAKEN FROM NAVIGATION.JS
//         // navLinkRequirementsStore > visible()
//         // Keep that current, then apply here.
//         return (
//           $rootScope.session.activeUserPresent() &&
//           // $rootScope.session.activeOrganizationPresent() &&
//           // $rootScope.session.activeOrganizationInteractable() &&
//           $rootScope.session.userCan('binders_read') &&
//           $rootScope.session.userCan('requirements_read') &&
//           $rootScope.session.userCan('organizations_read')
//         )
//       }
//     },
//     user_guide: {
//       icon: 'fa-question-circle-o',
//       title: 'Help',
//       text: 'Find FAQ, Guides, Videos and More',
//       click () {
//         return $rootScope.session.goTo('help')
//       },
//       visible () {
//         return true
//       }
//     }
//   }

//   const init = function () {
//     $scope.digest_collapsed = true
//     $scope.feed_collapsed = true
//     return toggle_feed_collapsed_if_there_are_new_feed_items()
//   }

//   var toggle_feed_collapsed_if_there_are_new_feed_items = () =>
//     Restangular.one(`users/${$rootScope.session.getUser().id}/feed`).get().then(function (response: any) {
//       if (response.new_count > 0) {
//         return $scope.feed_collapsed = false
//       }
//     }
//     , (error: any) => console.log(error))

//   return init()
// }])
