import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// HYBRID IMPORTS

// libraries
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// THIS WILL BE DEPRECATED IN ANGULAR 17
// Need to switch back to:
// import { MatTableModule } from '@angular/material/table';
// This will break A LOT of styling
// ng generate @angular/material:mdc-migration can help do some of this automatically
// We cannot use the new tooltip until angular 17 anyway. They cannot be customized. Fix is coming out after this code is pushed
// https://github.com/angular/components/issues/26056
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app-component/app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// import angular+ APP
import { AngularEditorModule } from '@kolkov/angular-editor';
// THIS WILL BE DEPRECATED IN ANGULAR 17. See MatTable comments
import { MatLegacyTooltipModule, MatLegacyTooltipDefaultOptions, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
// THIS WILL BE DEPRECATED IN ANGULAR 17. See MatTable comments
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { RestangularModule, Restangular } from 'ngx-restangular';
import { PureNg12Service } from './services/pure-ng12-service.service';
import { ng12Resource } from './services/ng12_resource.resource';
import { ng12Service } from './services/ng12_service.service';
import { FilterPill } from './components/filter-pill/filter-pill.component';
import { SampleComponent } from './components/sample-component/sample-component.component';
import { ReferenceObject } from './components/reference-object/reference-object.component';
import { AngularEditor } from '../scripts/utilities/angular-editor';
import { ChangeAlertIcon } from './components/change-alert-icon/change-alert-icon.component';
import { DateRangePicker } from './components/date-range-picker/date-range-picker';
import { fortyCoreProvider, $timeoutProvider, njsResourceProvider, objectManagerProvider, restangularProvider, helpServiceProvider, $uibModalProvider, utilsProvider, confirmationModalFactoryProvider, arrayFunctionsProvider, loadingStateServiceProvider, $stateProvider, $qProvider, uploadProvider, $httpParamSerializerProvider, envProvider, $windowProvider, complyosServicesProvider, treeManagerProvider } from '../scripts/services/ajs-upgraded-provider';
import { ReferenceManager } from './components/reference-manager/reference-manager.component';
import { StateWidget } from './components/state-widget/state-widget.component';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { ArrayNaturalSortPipe } from './pipes/array-natural-sort.pipe';
import { CapitalizePipe } from './pipes/capitalize-pipe';
import { AttachmentManager } from './components/attachment-manager/attachment-manager.component';
import { ajsSatalitePaginatorWrapper } from '../scripts/directives/pagination/ajs-satalite-paginator-wrapper';
import { ajsCollapsePanelWrapper } from '../scripts/directives/ajs-collapse-panel-wrapper';
import { ajsDashboardFilterWrapper } from '../scripts/directives/ajs-dashboard-filter-wrapper';
import { ajsOrganizationNeededPromptWrapper } from '../scripts/directives/ajs-organization-needed-prompt-wrapper';
import { ajsPaginatorWrapper } from '../scripts/directives/pagination/ajs-paginator-wrapper';
import { ajsLoadingIndicator } from '../scripts/directives/ajs-loading-indicator';
import { ajsSortByManager } from 'app/scripts/directives/ajs-sort-by-manager';
import { BindersListComponent } from './components/organizations/binders-list/binders-list.component';
import { StoreComponent } from './components/store/store.component';
import { FlashMessages } from './components/flash-messages/flash-messages.component';
import { FlashService } from './services/flash_service.service';
import { DateService } from './services/date_service.service';
import { HelpComponent } from './components/help/help.component';
import { NoteManager } from './components/note-manager/note-manager.component';
import { OrganizationUsersComponent } from './components/organizations/organization-users/organization-users.component';
import { OrganizationUsersModalComponent } from './components/organizations/organization-users-modal/organization-users-modal.component';
import { ReportFilterModal } from './components/report-filter-modal/report-filter-modal.component';
import { ReportsComponent } from './components/reports/reports.component';
import { RequirementModal } from './components/requirement-modal/requirement-modal.component';
import { RequirementInformationEditorComponent } from './components/requirement-information-editor/requirement-information-editor.component';
import { RequirementComplianceEditorComponent } from './components/requirement-compliance-editor/requirement-compliance-editor.component';
import { RequirementIntervalEditorComponent } from './components/requirement-interval-editor/requirement-interval-editor.component';
import { RequirementIntegrationsEditorComponent } from './components/requirement-integrations-editor/requirement-integrations-editor.component';
import { RequirementAuthorNotesEditorComponent } from './components/requirement-author-notes-editor/requirement-author-notes-editor.component';
import { RequirementStatusEditorComponent } from './components/requirement-status-editor/requirement-status-editor.component';
import { RequirementVersionHistoryModal } from './components/requirement-version-history/requirement-version-history-modal/requirement-version-history-modal.component';
import { RequirementVersionHistoryListComponent } from './components/requirement-version-history/requirement-version-history-list/requirement-version-history-list.component';
import { RequirementVersionComparerComponent } from './components/requirement-version-history/requirement-version-comparer/requirement-version-comparer.component';
import { RequirementTabsComponent } from './components/requirement-version-history/requirement-tabs/requirement-tabs.component';
import { RequirementVersionSelectorComponent } from './components/requirement-version-history/requirement-version-selector/requirement-version-selector.component';
import { EmailLogsComponent } from './components/email-logs/email-logs.component';
import { RequirementsListComponent } from './components/requirements-list/requirements-list.component';
import { StateManagerComponent } from './components/state-manager/state-manager.component';
import { TagRowComponent } from './components/tag-row/tag-row.component';
import { TaggingManagerComponent } from './components/tagging-manager/tagging-manager.component';
import { ArrayUserFilterPipe } from './pipes/array-user-filter.pipe';
import { VersionLabel } from './components/version-label/version-label.component';
import { FormSchemaPreviewModal } from './components/form-schema-preview-modal/form-schema-preview-modal.component';
import { OrganizationListComponent } from './components/organizations/organization-list/organization-list.component';
import { OrganizationRowComponent } from './components/organizations/organization-row/organization-row.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { LandingMainComponent } from './components/landing-main/landing-main.component';
import { AuthenticationService } from './services/authentication_service.service';
import { LoginComponent } from './components/authentication/login/login.component';
import { SsoLoginComponent } from './components/authentication/sso-login/sso-login.component';
import { UpsellComponent } from './components/home-pages/upsell/upsell.component';
import { HomeComponent } from './components/home-pages/home/home.component';
import { HomeLinksComponent } from './components/home-pages/home-links/home-links.component';
import { FeedComponent } from './components/home-pages/feed/feed.component';
import { DigestComponent } from './components/home-pages/digest/digest.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { DateRangeFilter } from './components/date-range-filter/date-range-filter.component';
import { PermissionComponent } from './components/permission/permission.component';
import { SurveyModeComponent } from './components/survey-mode/survey-mode.component';
import { ValidationModeCommentsComponent } from './components/validation-mode-comments/validation-mode-comments.component';
import { UserDashboardComponent } from './components/dashboard/user-dashboard/user-dashboard.component';
import { TrendComponent } from './components/dashboard/trend/trend.component';
import { QueryTooLargeWarningComponent } from './components/dashboard/query-too-large-warning/query-too-large-warning.component';
import { SurveyDateComponent } from './components/dashboard/survey-date/survey-date.component';
import { TasksToCompleteComponent } from './components/dashboard/tasks-to-complete/tasks-to-complete.component';
import { TaskPieChart } from './components/dashboard/task-pie-chart/task-pie-chart.component';
import { CompletePercentageComponent } from './components/dashboard/complete-percentage/complete-percentage.component';
import { ValidationModeCommentsCountComponent } from './components/dashboard/validation-mode-comments-count/validaiton-mode-comments-count.component';
import { OrganizationOverviewComponent } from './components/dashboard/organization-overview/organization-overview.component';
import { OrganizationOverviewRowItemComponent } from './components/dashboard/organization-overview-row-item/organization-overview-row-item.component';
import { SnapshotComponent } from './components/dashboard/snapshot/snapshot.component';
import { SnapshotLinearComponent } from './components/snapshot-linear/snapshot-linear.component';
import { SnapshotMetrics } from './components/snapshot-metrics/snapshot-metrics.component';
import { BinderComponent } from './components/binders/binder/binder.component';
import { InformationComponent } from './components/binders/information/information.component';
import { BinderSnapshotComponent } from './components/binders/binder-snapshot/binder-snapshot.component';
import { DocumentationComponent } from './components/binders/documentation/documentation.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { NotificationComponent } from './components/binders/notification/notification.component';
import { BinderModalComponent } from './components/modals/binder-modal/binder-modal.component';
import { DatePicker } from './components/date-picker/date-picker';
import { ApiSearchDropdownComponent } from './components/api-search-dropdown/api-search-dropdown.component';
import { PopoutComponent } from './components/popout/popout.component';
import { ApiSearchOptionComponent } from './components/api-search-option/api-search-option.component';
import { ControlNumbersComponent } from './components/control-numbers/control-numbers.component';
import { SystemDashboardComponent } from './components/dashboard/system-dashboard/system-dashboard.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './components/authentication/set-password/set-password.component';
import { PasswordService } from './services/password.service';
import { EntryModalComponent } from './components/modals/entry-modal/entry-modal.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FooterComponent } from './components/footer/footer.component';
import { TermsOfServiceComponent } from './components/modals/terms-modal/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/modals/privacy-modal/privacy-policy.component';

// -------------------------------------------------------------------------------------------
// NOTE: IMPORTS HAVE TO BE RELATIVE PATHS
// NOTE: IMPORTS HAVE TO BE RELATIVE PATHS
// NOTE: IMPORTS HAVE TO BE RELATIVE PATHS
// NOTE: now that I have your attention, 'app/..' does not exist when the application is built
//       so please make sure to use relative paths when importing this! ty <3
// -------------------------------------------------------------------------------------------

// Function for setting the default restangular configuration
// console.error("set up ngx-restangular properly")
export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.setBaseUrl('http://api.restngx.local/v1');
  RestangularProvider.setDefaultHeaders({ 'Authorization': 'Bearer UDXPx-Xko0w4BRKajozCVy20X11MRZs1' });
}

export const myCustomTooltipDefaults: MatLegacyTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 500,
  disableTooltipInteractivity:true 
};
@NgModule({
  declarations: [
    // proper angular components
    ArraySortPipe,
    AngularEditor,
    ArrayNaturalSortPipe,
    CapitalizePipe,
    FilterPill,
    SampleComponent,
    ReferenceObject,
    ChangeAlertIcon,
    ReferenceManager,
    StateWidget,
    StateManagerComponent,
    RequirementModal,
    NoteManager,
    FlashMessages,
    RequirementsListComponent,
		VersionLabel,
    ReportFilterModal,
    ReportsComponent,
    HelpComponent,
    TagRowComponent,
    TaggingManagerComponent,
    AttachmentManager,
    OrganizationUsersComponent,
    OrganizationUsersModalComponent,
    ArrayUserFilterPipe,
    FormSchemaPreviewModal,

    //ajs-component wrappers
    ajsOrganizationNeededPromptWrapper,
    ajsPaginatorWrapper,
    ajsSatalitePaginatorWrapper,
    ajsCollapsePanelWrapper,
    ajsDashboardFilterWrapper,
    ajsLoadingIndicator,
    ajsSortByManager,
    RequirementInformationEditorComponent,
    RequirementComplianceEditorComponent,
    RequirementIntervalEditorComponent,
    RequirementIntegrationsEditorComponent,
    RequirementAuthorNotesEditorComponent,
    RequirementStatusEditorComponent,
    RequirementVersionHistoryModal,
    RequirementVersionHistoryListComponent,
    RequirementVersionComparerComponent,
    RequirementTabsComponent,
    RequirementVersionSelectorComponent,
    EmailLogsComponent,
    BindersListComponent,
    StoreComponent,
    OrganizationListComponent,
    OrganizationRowComponent,
    MarketingComponent,
    LandingMainComponent,
    LoginComponent,
    SsoLoginComponent,
    UpsellComponent,
    HomeComponent,
    HomeLinksComponent,
    FeedComponent,
    DigestComponent,
    ScheduleListComponent,
    DateRangeFilter,
    DateRangePicker,
    PermissionComponent,
    SurveyModeComponent,
    ValidationModeCommentsComponent,
    UserDashboardComponent,
    TrendComponent,
    QueryTooLargeWarningComponent,
    SurveyDateComponent,
    TasksToCompleteComponent,
    TaskPieChart,
    CompletePercentageComponent,
    ValidationModeCommentsCountComponent,
    OrganizationOverviewComponent,
    OrganizationOverviewRowItemComponent,
    SnapshotComponent,
    SnapshotLinearComponent,
    SnapshotMetrics,
    BinderComponent,
    InformationComponent,
    BinderSnapshotComponent,
    DocumentationComponent,
    StatusIconComponent,
    NotificationComponent,
    BinderModalComponent,
    DatePicker,
    ApiSearchDropdownComponent,
    PopoutComponent,
    ApiSearchOptionComponent,
    ControlNumbersComponent,
    SystemDashboardComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    EntryModalComponent,
    FooterComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent
  ],
  // MAY BE ABLE TO REMOVE ENTRY COMPONENETS
  entryComponents: [
    // AngularEditor,
    // FilterPill,
    // ReferenceObject,
    // SampleComponent,
    // ChangeAlertIcon,
    // ReferenceManager,
    // NoteManager,
    // FlashMessages
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    HttpClientModule,
    AppRoutingModule,
    // the withConfig will let us contiue to use [disable] attr in pug file to disable fields
    // https://angular.io/api/forms/SetDisabledStateOption
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    MatLegacyTooltipModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right'
    }),
    MatLegacyTabsModule,
    MatLegacyTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    TextMaskModule,
    RestangularModule.forRoot(RestangularConfigFactory),
  ],
  providers: [
    // ContactResource,
    PureNg12Service,
    ng12Resource,
    ng12Service,
    FlashService,
    PasswordService,
    AuthenticationService,
    DateService,
    $stateProvider,
    $qProvider,
    $httpParamSerializerProvider,
    $windowProvider,
    njsResourceProvider,
    // flashServiceProvider,
    fortyCoreProvider,
    objectManagerProvider,
    treeManagerProvider,
    restangularProvider,
    helpServiceProvider,
    $timeoutProvider,
    envProvider,
    $uibModalProvider,
    complyosServicesProvider,
    loadingStateServiceProvider,
    utilsProvider,
    uploadProvider,
    confirmationModalFactoryProvider,
    arrayFunctionsProvider,
    // this is needed from angular 12 to 13 upgrade.  tooltips were not disappearing sometimes when the mouse hovers off a button or list item
    {provide: MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults},
    {provide: '$scope', useExisting: '$rootScope'}
    // $scopeProvider
  ],
  exports: [
    // SampleComponent,
    // AngularEditor,
    // FilterPill,
    // ChangeAlertIcon,
    // ReferenceObject,
    // NoteManager,
    // FlashMessages
  ],
  schemas: [
    // TODO: NG:UPGRADE
    //        Comment out NO_ERRORS_SCHEMA to check if there are any components you missed upgrading
    //        NG2+ does not like NGjs named transclude slots (it throws errors when it sees them)- which is 
    //        why we use NO_ERRORS_SCHEMA to ignore those errors BUT we may also ignore njs components that
    //        we forgot to upgrade, so toggle this off to see if you missed any
    NO_ERRORS_SCHEMA
  ],
})
export class AppModule {

  ngDoBootstrap() {
  }
}

// // BOOTSTRAP HYBRID APP
// platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
//     console.log("Bootstrapping in Hybrid mode with Angular & AngularJS");
//     const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
//     upgrade.bootstrap(document.body, ['complyosClient', 'FortyAUServices']);
// });

