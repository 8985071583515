/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */




//- --------------------------------------------------------------------
//- DEPRICATED!!
//-
//- Use the new angular 12 version: flash-messagews.component .pug/.ts components!
//-
//- if you are still using this component. take the time to switch over to the
//- new ng12 version! [ pls :) ]
//- --------------------------------------------------------------------


// 'use strict'
//
// angular.module('complyosClient').directive('flashDirective', () =>
//   ({
//     templateUrl: 'views/directives/flash-display.html',
//     restrict: 'E',
//     scope: {
//       alert_array: '=alerts'
//     }
//   })
// )
