import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngValidationModeCommentsCount',
  templateUrl: './validation-mode-comments-count.component.html'
})
export class ValidationModeCommentsCountComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  // Properties
  private previousPills: any = null;
  private counts: any;
  private deferredRefresh: any;
  private outstandingPromises: Array<any> = [];
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    const custom_states = {
      a_bunch_of_nothing: {
        icon: 'fa-check-circle',
        text: 'No new ValidationMode&#174; comments or Resubmission Entries or Citations'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.watchEntityFilter()
  }

  getAnalyticData = (pills: any) => {
    this.loadingStateService.set_state('loading')

    let query_params = {
      model: 'Entry',
      method: 'validation_mode_comment_count',
      param_data: {}
    }

    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    return this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then((success: any) => {
      this.loadingStateService.process_success(success)
      this.counts = success.data

      if (this.counts.review_count === 0 && this.counts.resubmission_count === 0 && this.counts.citation_count === 0) {
        return this.loadingStateService.set_state('a_bunch_of_nothing')
      }
    }
    , (error: any) => {
      this.loadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
      
    });
  }

  pushCitationPill = () => {
    let citationPill = {
      isUnique: true,
      Field: 'visible',
      displayName: 'Visibility',
      displayValue: 'Citation',
      Value: 'citation'
    };
    this.$rootScope.$broadcast('push-new-filter', citationPill);
  };

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getAnalyticData(pills), 50)
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted', 'pagination_params']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        this.executeRefresh(pills)
      }
    }
    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }
}

angular
  .module('complyosClient')
  .directive('ngValidationModeCommentsCount', downgradeComponent({ component: ValidationModeCommentsCountComponent }) as angular.IDirectiveFactory)
