import * as angular from 'angular';

import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
import { Restangular } from 'ngx-restangular';
// import { Restangular } from 'ngx-restangular'

@Injectable()
export class ng12Resource {
    private apiRoot: string = "http://localhost:3000/ng12service";

    constructor(
        // @Inject(HttpClient) public HttpClient: HttpClient,
        // @Inject(Restangular) private restangular: Restangular
        // @Inject(ExampleService) private exampleService: ExampleService,

        private restangular: Restangular
    ) {
        console.log("[ng12 -> x] || this is an example [ng12] service that can be used in ng12 entities OR downgraded to be used in njs entities")

        this.restangular.one('users', 2).all('accounts').getList();
    }

    whoami(runFrom: string) {
        console.log('ng12 (downgraded for njs) service, called from :' + runFrom)
    }
}

angular
    .module('complyosClient')
    .factory("ng12Resource", downgradeInjectable(ng12Resource));