/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// ------------------------------------------------------------------------------------
// NOTE:        THIS COMPONENET HAS BEEN UPGRADED, THIS SHOULD BE REPLACED THEN REMOVED
// REPLACED BY: reference-object.component.ts
// ------------------------------------------------------------------------------------

angular.module('complyosClient').directive('referenceObject', [
  // injects here, , ,
  () =>
    ({
      templateUrl: 'views/directives/referenceObject.html',
      restrict: 'E',
      scope: {
        object: '='
      },
      link ($scope: any) {
        $scope = this.registerFunctions($scope)
        $scope.transcoded_object = []
        $scope.$watch('object', function (newValue: any, oldValue: any) {
          $scope.transcoded_object = $scope.transcode($scope.object)
        }, true)
      },

      registerFunctions ($scope: any) {
        $scope.transcode = function (object: any) {
          let cmmd_mode = false
          let cmmd_str = ''
          let i = 0
          const output = []

          if (object.assembly === undefined) {
            output.push({
              type: 'string',
              value: object.value
            })
            return output
          } else {
            while (i < object.assembly.length) {
              if (cmmd_mode && (object.assembly[i] === ']')) { // exit command_mode, run command
                cmmd_mode = false // exit command_mode
                const cmmd_fn = cmmd_str.split(':')[0]
                const cmmd_arg = cmmd_str.split(':')[1]
                cmmd_str = '' // reset the command_string for future use

                if (cmmd_fn === 'r') { // replace
                  if ($scope.deepSearchObject(object, `${cmmd_arg}.description`) === null) {
                    output.push({
                      type: 'string',
                      value: $scope.deepSearchObject(object, `${cmmd_arg}.identifier`)
                    })
                  } else {
                    output.push({
                      type: 'tooltip',
                      value: $scope.deepSearchObject(object, `${cmmd_arg}.identifier`),
                      tooltip: $scope.deepSearchObject(object, `${cmmd_arg}.description`)
                    })
                  }
                } else if (cmmd_fn === '?') { // condtional continue
                  if (object[cmmd_arg] === undefined) { // break if blank
                    break
                  }
                }
              } else if (!cmmd_mode && (object.assembly[i] === '[')) { // entering command_mode
                cmmd_mode = true
              } else if (cmmd_mode) { // in command mode, build the command_string
                cmmd_str += object.assembly[i]
              } else if (!cmmd_mode) { // not in command_mode append object.assembly[i] to output
                output.push({
                  type: 'string',
                  value: object.assembly[i]
                })
              }
              ++i
            }
            return output
          }
        }
        $scope.deepSearchObject = function (o: any, s: any) {
          s = s.replace(/\[(\w+)\]/g, '.$1')
          s = s.replace(/^\./, '')
          const a = s.split('.')
          let i = 0
          while (i < a.length) {
            const k = a[i]
            if (k in o) {
              o = o[k]
            } else {
              return
            }
            ++i
          }
          return o
        }

        return $scope
      }
    })
])

// ---------------------------- [BACKUP BELOW] ----------------------------

// angular.module('complyosClient').directive('referenceObject', function ($compile, utils) {
//   const stringTemplate = value => `${value}`

//   const tooltipTemplate = function (value, tooltip) {
//     let html = '<span class="cursor-help"'
//     html += `uib-popover="${tooltip}"`
//     html += 'popover-placement="bottom" popover-trigger="mouseenter" >'
//     html += value
//     return html += '</span>'
//   }

//   // Build a html string from a [o]bject that represent:
//   // types of data and
//   // the order the data is in
//   const buildHtmlString = function (o) {
//     let s = '' // Output [s]tring
//     for (let e of Array.from(o)) {
//       switch (e.type) {
//         case 'string':
//           s += stringTemplate(e.value)
//           break
//         case 'tooltip':
//           s += tooltipTemplate(e.value, e.tooltip)
//           break
//       }
//     }
//     return s
//   }

//   // read a [p]attern string and pull outs its data and tooltip values from a json [o]bject
//   // return array of objects for building reference
//   const transcode = function (object) {
//     let cmmd_mode = false
//     let cmmd_str = ''
//     let i = 0
//     const output = []

//     if (object.assembly === undefined) {
//       output.push({
//         type: 'string',
//         value: object.value
//       })
//       return output
//     } else {
//       while (i < object.assembly.length) {
//         if (cmmd_mode && (object.assembly[i] === ']')) { // exit command_mode, run command
//           cmmd_mode = false // exit command_mode
//           const cmmd_fn = cmmd_str.split(':')[0]
//           const cmmd_arg = cmmd_str.split(':')[1]
//           cmmd_str = '' // reset the command_string for future use

//           if (cmmd_fn === 'r') { // replace
//             if (deepSearchObject(object, `${cmmd_arg}.description`) === null) {
//               output.push({
//                 type: 'string',
//                 value: deepSearchObject(object, `${cmmd_arg}.identifier`)
//               })
//             } else {
//               output.push({
//                 type: 'tooltip',
//                 value: deepSearchObject(object, `${cmmd_arg}.identifier`),
//                 tooltip: deepSearchObject(object, `${cmmd_arg}.description`)
//               })
//             }
//           } else if (cmmd_fn === '?') { // condtional continue
//             if (object[cmmd_arg] === undefined) { // break if blank
//               break
//             }
//           }
//         } else if (!cmmd_mode && (object.assembly[i] === '[')) { // entering command_mode
//           cmmd_mode = true
//         } else if (cmmd_mode) { // in command mode, build the command_string
//           cmmd_str += object.assembly[i]
//         } else if (!cmmd_mode) { // not in command_mode append object.assembly[i] to output
//           output.push({
//             type: 'string',
//             value: object.assembly[i]
//           })
//         }
//         ++i
//       }
//       return output
//     }
//   }

//   // Deep search a [o]bject for a [s]tring representing a data structutre key
//   var deepSearchObject = function (o, s) {
//     s = s.replace(/\[(\w+)\]/g, '.$1')
//     s = s.replace(/^\./, '')
//     const a = s.split('.')
//     let i = 0
//     while (i < a.length) {
//       const k = a[i]
//       if (k in o) {
//         o = o[k]
//       } else {
//         return
//       }
//       ++i
//     }
//     return o
//   }

//   const linker = ($scope, $element, $attrs) =>

//     // Watch for changes and rebuild
//     $scope.$watch('object', function (newValue, oldValue) {
//       const transcoded_object = transcode($scope.object)
//       const htmlString = buildHtmlString(transcoded_object)

//       $element.html(htmlString)
//       $compile($element.contents())($scope)
//     }
//       , true)

//   return {
//     restrict: 'E',
//     link: linker,
//     scope: {
//       object: '='
//     }
//   }
// })
