import { Component, Inject, OnInit } from '@angular/core';
import angular from 'angular';
import { ConfirmationModalFactory } from '../../../scripts/services/ajs-confirmation_modal_factory';
import { objectManager } from '../../../scripts/services/ajs-object_manager';
import { Utils } from '../../../scripts/services/ajs-utils';
import { ArrayFunctions } from '../../../scripts/utilities/ajs-array_functions';
import { FlashService } from '../../services/flash_service.service';
import _ from 'lodash';
import * as Restangular from '../../../vendor/restangular/restangular'
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../scripts/services/help';
var dayjs = require("dayjs")

@Component({
  selector: 'ngRequirementsList',
  templateUrl: './requirements-list.component.html',
  styleUrls: ['./requirements-list.component.scss']
})
export class RequirementsListComponent implements OnInit {
  // Injected Services
  private flashService: any;
  private processingStateService: any;
  private loadingStateService: any;
  private session: any;

  // Properties
  private previousPills: Array<any> = [];
  private requirementsCount: number = 0;
  private requirements: Array<any> = [];

  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$scope') private $scope,
    @Inject('$rootScope') private $rootScope,
    // complyosServices: any,   // <--- does not seem to be needed
    // ENV: any,                // <--- does not seem to be needed
    @Inject(FlashService) flashService: FlashService,
    @Inject('fortyCore') private fortyCore: any,
    private helpService: helpService,
    @Inject('loadingStateService') loadingStateService: any,
    @Inject(objectManager) private objectManager: objectManager,
    private Restangular: Restangular,
    private utils: Utils,
    private confirmationModalFactory: ConfirmationModalFactory,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.flashService = angular.copy(flashService)
    this.processingStateService = fortyCore.processingStateService;
    this.loadingStateService = angular.copy(loadingStateService)
    this.session = $rootScope.session
  }

  ngOnInit(): void {
    const custom_states = {
      nocontent: {
        icon: 'fa-exclamation-triangle',
        text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()

    this.watchEntityFilter()
  }

  getRequirements = function (pills: any) {
    let query_params = {
      param_data: this.$rootScope.storeService.stringifyTagParams(
        this.$rootScope.storeService.pillsToObject(pills)
      )
    }
    this.loadingStateService.set_state('loading')
    let that = this
    return this.Restangular.one('requirements').get(query_params).then(function (success: any) {
        that.loadingStateService.process_success(success.items)
        that.$rootScope.$broadcast('pagination-results-updated', success.pagination_info)
        that.requirements = success.items
        that.requirementsCount = success.pagination_info.totalCount
      }, (error: any) => {
        that.loadingStateService.process_error(error)
        that.utils.log(error)
      }
    );
  }

  /* CRUD */

  openRequirementCreateModal = function () {
    const new_requirement_object = {
      attachments: [], // blank arrays are added so we can merge new items into something
      taggings: [],
      reference_objects: [],
      notes: [],
      author_notes: [],
      annotation_state: 'annotation_optional',
      schedule_on: true,
      start_date_needed: true,
      allow_partially_complete: true,
      allow_closed_not_complete: false,
      task_agent_cannot_attest: false
    }
    return this.openRequirementModal(new_requirement_object, 'create')
  }

  openDuplicateRequirementModal = (requirement_object: any) => {
    this.Restangular.all('requirements').get(requirement_object.id).then((response: any) => {
      var duplicate_requirement_object = response.plain()
      this.processingStateService.performFunction(requirement_object, () => this.openRequirementModal(duplicate_requirement_object, 'duplicate', requirement_object))
    }, function (error: any) {
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  openRequirementReadModal = (requirement_object: any) => {
    this.Restangular.all('requirements').get(requirement_object.id).then((response: any) => {
      this.loadingStateService.process_success(response)
      var read_requirement_object = response.plain()
      this.processingStateService.performFunction(requirement_object, () => this.openRequirementModal(read_requirement_object, 'read', requirement_object))
    }, function (error: any) {
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    })
  }

  openRequirementUpdateModal = (requirement_object: any) => { // State service will set the object to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    // TODO: load_or_create_edit_state before loaunching modal 
    let that = this
    // that.processingStateSer:intervice.performFunction(requirement_object, () => {console.log("test")})
    that.Restangular.one(`requirements/${requirement_object.id}/load_or_create_edit_state`).get().then(function (response: any) { // success
      that.loadingStateService.process_success(response)
      var edit_state_requirement_object = response.plain()
      // console.log('edit_state_requirement_object: ', edit_state_requirement_object);
      that.processingStateService.performFunction(requirement_object, () => that.openRequirementModal(edit_state_requirement_object, 'update', requirement_object))
    }, function (error: any) {
      that.flashService.process_error(error)
      return that.utils.log(error, 'error', false)
    })
  }

  openVersionHistoryModal = (requirement_object: any) => {
    this.processingStateService.performFunction(requirement_object, () => {})
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/ajs-requirement-version-history-modal-adapter.html',
      controller: 'ajsRequirementVersionHistoryModalAdapter',
      size: 'xl',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions() {
          return {
            requirementId: requirement_object.id,
            requirement: angular.copy(requirement_object),
            // uiSetting
          }
        }
      }
    })

    modalInstance.result.then((result: any) => {
        return this.objectManager.array_action(this.requirements, result, 'update')
    }, function (reason: any) {})
    
    .finally(() => {
      this.processingStateService.set(
        requirement_object,
        this.processingStateService.processing_states.RESTING
      )
    });
  }

  openRequirementDestroyModal = function (requirement_object: any) {
    let that = this
    const confirmation_object = {
      // severity: severity string
      // title: title string
      // button_icon: icon string
      // button_text: text string
      // deny: method to run on dismiss
      confirm() { return that.destroy_requirement(requirement_object) },
      message: `<p>This action will:</p><ul><li>Delete the requirement ${requirement_object.name}</li><li>Delete all the configured requirements that have this requirement linked to it</li><li>Delete all requirement entries, attachments and notifications associated with the configured requirements being deleted.</li></ul><p>This action is not reversible.</p>`
    }

    // State service will set the object to a state of PENDING
    // After action is performed or cancelled,
    // that action will be responsible for setting object to RESTING state
    return this.processingStateService.performFunction(requirement_object, () =>
      this.confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
        // Closing confirmation modal always gives a result
        // If result is false (cancel), then set back to resting
        if (!result) {
          that.processingStateService.set(
            requirement_object,
            that.processingStateService.processing_states.RESTING
          )
        }
        return true
        
      })
    );
  }

  destroy_requirement = (requirement: any) => this.Restangular.all(`requirements/${requirement.id}`).remove().then((success: any) => { this.objectManager.array_action(this.requirements, requirement, 'remove') }
    , (error: any) => {
      this.processingStateService.set(requirement, this.processingStateService.processing_states.RESTING)
      this.flashService.process_error(error)
      return this.utils.log(error, 'error', false)
    })

  // https://angular-ui.github.io/bootstrap/#/modal
  openRequirementModal = (requirement_object: any, uiSetting: any, related_object: any = null) => {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/ajs-requirement-modal-adapter.html',
      controller: 'ajsRequirementModalAdapter',
      size: 'lg',
      resolve: {
        // this is the object that can be passed to the modal.
        // we copy it to eliminate binding issues
        // require it in the dependecies as modalOptions
        modalOptions() {
          return {

            object: angular.copy(requirement_object),
            uiSetting,
            old_object: angular.copy(related_object)
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    // - finally, sets the object back to a resting state
    let that = this
    return modalInstance.result.then((result: any) => {
      if (uiSetting === 'create' || uiSetting === 'duplicate') {
        that.objectManager.array_action(that.requirements, result, 'merge')
      }
      if (uiSetting === 'update') {
        return that.objectManager.array_action(that.requirements, result, 'update')
      }
    }, (reason: any) => {
      if (uiSetting === 'update' && (related_object.is_edit_state == false && related_object.edit_state_id == undefined)) {
        this.discard_edit_state(related_object)
      }
    }).finally(() => {
      that.processingStateService.set(
        requirement_object,
        that.processingStateService.processing_states.RESTING
      )

      if (related_object != null) {
        that.processingStateService.set(related_object, that.processingStateService.processing_states.RESTING)
      }
    });
  }

  discard_edit_state = (requirement) => {
    this.Restangular.all(`requirements/${requirement.id}/discard_edit_state`).remove().then((response: any) => { // success
      // var requirement_object = response.plain()
    }, (error: any) => {
      return this.utils.log(error, 'error', false)
    })
  }

  is_current_published_version(requirement) {
    return requirement.id && requirement.published && !requirement.state.archived
  }

  is_thirty_day_window(requirement) {
    return requirement.updated_at <= dayjs().subtract(23, 'months').toISOString()
  }

  is_sixty_day_window(requirement) {
   return requirement.updated_at <= dayjs().subtract(22, 'months').toISOString() && requirement.updated_at > dayjs().subtract(23, 'months').toISOString()
  }

  is_ninety_day_window(requirement) {
    return requirement.updated_at <= dayjs().subtract(21, 'months').toISOString() && requirement.updated_at > dayjs().subtract(22, 'months').toISOString()
  }

  watchEntityFilter() {
    let that = this
    var refresh = function (e: any, pills: any) {
      let widgetShouldRefresh = that.arrayFunctions.hasMeaningfulListChange(that.previousPills, pills, ['sorted'])
      that.previousPills = pills
      if (widgetShouldRefresh) {
        that.getRequirements(pills)
      }
    }
    this.$scope.$on('filterChange-dashboard', refresh)
  }
}

angular
  .module('complyosClient')
  .directive('ngRequirementsList', downgradeComponent({ component: RequirementsListComponent }) as angular.IDirectiveFactory)

//- --------------------------------------------------------------------
//- FOR REFERENCE

// complyosClient.controller('complyosRequirementsController', ['$uibModal', '$scope', '$rootScope', 'complyosServices', 'ENV', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', 'confirmationModalFactory', 'arrayFunctions', function (
//   $uibModal: any,
//   $scope: any,
//   $rootScope: any,
//   complyosServices: any,
//   ENV: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any,
//   confirmationModalFactory: any,
//   arrayFunctions: any
// ) {
//   const init = function () {

//     $scope.flashService = angular.copy(flashService)

//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.previousPills = []
//     const custom_states = {
//       nocontent: {
//         icon: 'fa-exclamation-triangle',
//         text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.requirementsCount = 0

//     watchEntityFilter()
//   }

//   var getRequirements = function (pills: any) {
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }
//     $scope.loadingStateService.set_state('loading')
//     return Restangular.one('requirements').get(query_params).then(function (success: any) {
//       $scope.loadingStateService.process_success(success.items)
//       $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//       $scope.requirements = success.items
//       $scope.requirementsCount = success.pagination_info.totalCount
//     }
//       , (error: any) => {
//         $scope.loadingStateService.process_error(error)
//         utils.log(error)
//       }
//     );
//   }

//   /* CRUD */

//   $scope.openRequirementCreateModal = function () {
//     const new_requirement_object = {
//       attachments: [], // blank arrays are added so we can merge new items into something
//       taggings: [],
//       reference_objects: [],
//       notes: [],
//       author_notes: [],
//       annotation_state: 'annotation_optional',
//       schedule_on: true,
//       start_date_needed: true,
//       allow_partially_complete: true,
//       allow_closed_not_complete: false,
//       task_agent_cannot_attest: false
//     }
//     return openRequirementModal(new_requirement_object, 'create')
//   }

//   $scope.openRequirementReadModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => openRequirementModal(requirement_object, 'read'))

//   $scope.openDuplicateRequirementModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => {
//       openRequirementModal(requirement_object, 'duplicate')
//     })

//   $scope.openRequirementUpdateModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => openRequirementModal(requirement_object, 'update'))

//   $scope.openRequirementDestroyModal = function (requirement_object: any) {
//     const confirmation_object = {
//       // severity: severity string
//       // title: title string
//       // button_icon: icon string
//       // button_text: text string
//       // deny: method to run on dismiss
//       confirm() { return destroy_requirement(requirement_object) },
//       message: `<p>This action will:</p><ul><li>Delete the requirement ${requirement_object.name}</li><li>Delete all the configured requirements that have this requirement linked to it</li><li>Delete all requirement entries, attachments and notifications associated with the configured requirements being deleted.</li></ul><p>This action is not reversible.</p>`
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled,
//     // that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(requirement_object, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(
//             requirement_object,
//             $scope.processingStateService.processing_states.RESTING
//           )
//         }
//         return true
//       })
//     );
//   }

//   var destroy_requirement = (requirement: any) => Restangular.all(`requirements/${requirement.id}`).remove().then((success: any) => objectManager.array_action($scope.requirements, requirement, 'remove')

//     , function (error: any) {
//       $scope.processingStateService.set(
//         requirement,
//         $scope.processingStateService.processing_states.RESTING
//       )
//       $scope.flashService.process_error(error)
//       return utils.log(error)
//     })

//   // https://angular-ui.github.io/bootstrap/#/modal
//   var openRequirementModal = function (requirement_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/requirement_modal.html',
//       controller: 'requirementModalController',
//       size: 'lg',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions() {
//           return {

//             object: angular.copy(requirement_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     return modalInstance.result.then(function (result: any) {
//       if (ui_setting === 'create' || ui_setting === 'duplicate') {
//         objectManager.array_action($scope.requirements, result, 'merge')
//       }
//       if (ui_setting === 'update') {
//         return objectManager.array_action($scope.requirements, result, 'update')
//       }
//     }, function (reason: any) {

//     }).finally(() =>
//       $scope.processingStateService.set(
//         requirement_object,
//         $scope.processingStateService.processing_states.RESTING
//       )
//     );
//   }

//   var watchEntityFilter = () => {
//     var refresh = function (e: any, pills: any) {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted'])
//       $scope.previousPills = pills
//       if (widgetShouldRefresh) {
//         getRequirements(pills)
//       }
//     }
//     $scope.$on('filterChange-dashboard', refresh)
//   }

//   return init()
// }])
