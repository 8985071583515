import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import _ from 'lodash';
import * as Restangular from '../../../../vendor/restangular/restangular'
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';

@Component({
  selector: 'ngTasksToComplete',
  templateUrl: './tasks-to-complete.component.html'
})
export class TasksToCompleteComponent implements OnInit {
  // Injected Services
  private loadingStateService: any;
  
  // Properties
  private previousPills: any = null;
  private deferredRefresh: any;
  private fruitalytics: any;
  private outstandingPromises: Array<any> = [];
  
  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
  ) { 
    this.loadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    const custom_states = {
      kudos: {
        icon: 'fa-check-circle',
        text: '100% on top of the work'
      },
      no_tasks: {
        icon: 'fa-info-circle',
        text: 'No Tasks to Display'
      }
    }
    this.loadingStateService.loading_states = _.merge(this.loadingStateService.loading_states, custom_states)
    this.loadingStateService.init()
    this.watchEntityFilter()
  }

  getAnalyticData = (pills: any) => {
    this.loadingStateService.set_state('loading')

    let query_params = {
      model: 'Binder',
      method: 'tasks_to_complete',
      param_data: {}
    }

    query_params.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)
    this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(query_params).then((response: any) => {
      if (response.data['T'] === 0) {
        this.loadingStateService.set_state('no_tasks')
      }
      else if ((response.data.open_entry_count === 0) && (response.data.past_due_entry_count === 0) && (response.data['1'] === 0) && (response.data['T'] !== 0)) {
        this.loadingStateService.set_state('kudos')
      } else {
        this.loadingStateService.process_success(response)
      }
      this.fruitalytics = response.plain()
    }, (error: any) => {
      this.loadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
    })
  }

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getAnalyticData(pills), 50)
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted', 'pagination_params']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        if (resultsCount <= 30000) {
          this.executeRefresh(pills)
        } else {
          this.loadingStateService.set_state('query_too_large_warning')
          this.deferredRefresh = this.executeRefresh.bind(null, pills)
        }
      }
    }
    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }
}

angular
  .module('complyosClient')
  .directive('ngTasksToComplete', downgradeComponent({ component: TasksToCompleteComponent }) as angular.IDirectiveFactory)
