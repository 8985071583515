/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('userModalController', ['$rootScope', '$scope', '$uibModalInstance', 'complyosServices', 'ENV', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'utils', 'loadingStateService', function (
  $rootScope: any,
  $scope: any,
  $uibModalInstance: any,
  complyosServices: any,
  ENV: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any,
  utils: any,
  loadingStateService: any
) {
  const init = function () {
    
    $scope.flashService = angular.copy(flashService)
    $scope.processingStateService = fortyCore.processingStateService
    $scope.ui_setting = modalOptions.ui_setting
    $scope.user = modalOptions.object
    $scope.valid_username_pattern = /^[\S]+$/
    $scope.active = 0
    $scope.add_user_lobs_placeholder()
    $scope.watch_user_lobs() 

    $scope.flashService.listen_for_alert($scope)

    
    $scope.loadingStateService = angular.copy(loadingStateService)

    // checks to see if you are viewing yourself
    if ($rootScope.session.getUser().id === $scope.user.id) {
      $scope.self = true
    } else {
      $scope.self = false
    }

    // checks if trying to create a user
    // if so it creates a placeholder object
    // sets up basic properties for creation
    if ($scope.ui_setting === 'create') {
      add_user_role_placeholder()
      
      set_sso_attributes()
      $scope.user.username = ''
      $scope.role_selected = false
      $scope.pending_or_existing_user_role = pending_or_existing_user_role()
      $scope.role_allows_org_selection = role_allows_org_selection()
      $scope.loadingStateService.set_state('content')
      return $scope.role_allows_tag_selection = role_allows_tag_selection()
    } else { // read, update
      return get_user()
    }
  }

  var set_sso_attributes = function () {
    if ($rootScope.session.sso_domained()) {
      // default to true to encourage all users to be SSO
      // change this boolean to toggle on and off user sso preference
      $scope.user.sso = false
      // always set the sso_idp_id if we have it
      return $scope.user.sso_idp_id = $rootScope.session.get_sso_idp().id
    }
  }

  var get_user = () => Restangular.all('users').get($scope.user.id).then(function (success: any) {
    $scope.user = success
    $scope.pending_or_existing_user_role = pending_or_existing_user_role()
    $scope.role_allows_org_selection = role_allows_org_selection()
    $scope.role_allows_tag_selection = role_allows_tag_selection()
    $scope.add_user_lobs_placeholder()
    $scope.role_selected = role_selected()
    $scope.loadingStateService.process_success(success)
  }
  , (error: any) => $scope.loadingStateService.process_error(error))

  var add_user_role_placeholder = function () {
    $scope.add_user_lobs_placeholder()
    $scope.user.user_role = {}
    return $scope.user.user_role.$pending_user_role = {}
  }
  $scope.add_user_lobs_placeholder = function () {
    $scope.user.user_lobs = $scope.user.user_lobs || []
    return $scope.user.user_lobs.$pending_user_lobs = $scope.user.user_lobs.$pending_user_lobs || $scope.user.user_lobs
  }

  var role_allows_org_selection = function () {
    // if the user is being edited they have a user role
    if ($scope.user.user_role) {
      // if the user will get a role change, use the pending_user_role
      if ($scope.user.user_role.$pending_user_role) {
        return $scope.user.user_role.$pending_user_role.orgs_access === 'some'
      // there is no pending role object, use the orginal user role
      } else {
        return $scope.user.user_role.role.orgs_access === 'some'
      }

    // if the user is being created they wont have a user role
    } else {
      return false // this is just an assumption
    }
  }

  var role_allows_tag_selection = function () {
    // if a user has a user role -- READ, UPDATE
    if ($scope.user.user_role) {
      if ($scope.user.user_role.$pending_user_role) {
        return $scope.user.user_role.$pending_user_role.tags_access === 'some'
      } else { // there is no pending role object
        return $scope.user.user_role.role.tags_access === 'some'
      }

    // if a user doesnt have a user role -- CREATE
    } else {
      return false // this is just an assumption
    }
  }

  var pending_or_existing_user_role = () => {
    if ($scope.user.user_role) {
      if ($scope.user.user_role.$pending_user_role) {
        return $scope.user.user_role.$pending_user_role.id
      } else { // there is no pending role object
        return $scope.user.user_role.role.id
      }

    } else {
      return false
    }
  }

  var pending_or_existing_user_lobs = () => {
    if (!role_allows_tag_selection()) {
      return false
    }
    
    if ($scope.user.user_lobs) {
      if ($scope.user.user_lobs.$pending_user_lobs) {
        return $scope.user.user_lobs.$pending_user_lobs
      } else {
        return $scope.user.user_lobs
      }
    } else {
      return false
    }
  }

  var pending_or_existing_multi_lob = () => {
    if ($scope.user.user_role) {
      if ($scope.user.user_role.$pending_user_role) {
        return $scope.user.user_role.$pending_user_role.multi_lob || false
      } else {
        return $scope.user.user_role.role.multi_lob || false
      }

    } else {
      return false
    }
  }

  var role_selected = function () {
    if (($scope.ui_setting === 'read') || ($scope.ui_setting === 'update')) {
      return !_.isEmpty($scope.user.user_role)
    } else {
      return !_.isEmpty($scope.user.user_role.$pending_user_role)
    }
  }

  $scope.post_save_tags_access = function () {
    // if a user has a user role -- READ, UPDATE
    if ($scope.user.user_role) {
      // if user has a pending_user_role (their role is being updated)
      if ($scope.user.user_role.$pending_user_role) {
        return $scope.user.user_role.$pending_user_role.tags_access
      } else { // their role will remain unchainged
        return $scope.user.user_role.role.tags_access
      }

    // if a user doesnt have a user role -- CREATE
    } else {
      return 'some' // this is just an assumption
    }
  }

  $scope.check_tab_completion = function (form: any, tab: any) {
    switch (tab) {
      case 0:
        if (form.field_email) {
          return form.field_email.$valid && form.field_username.$valid
        }
        break
      case 1:
        if (form.field_role) {
          return form.field_role.$valid && (!form.field_lob || form.field_lob.$valid)
        }
        break
      case 2:
        if (form.field_taggings) {
          return form.field_taggings.$valid
        }
        break
      case 3:
        if (form.field_organizations) {
          return form.field_organizations.$valid
        }
        break
    }
  }

  $scope.move_through_form = function (direction: any) {
    if (direction === 'forwards') {
      return $scope.active += 1
    } else {
      return $scope.active -= 1
    }
  }

  $scope.log_form = function (form: any) {
    // user role manager updating to the form
    // profile manager not even in form
    // username and email updating on form
    // taggings dont update
    // organizations doesnt update

    console.log('form')
    return console.log(form)
  }

  // simple function to detormine if a angular form objects has any warnings
  // the warnings will be an array under the key of $warnings
  const form_has_warnings = function (form: any) {
    let form_warnings = false

    for (let key in form) {
      if (
        form.hasOwnProperty(key) &&
        form[key] &&
        (form[key].$warnings !== undefined) &&
        !_.isEmpty(form[key].$warnings)
      ) {
        form_warnings = true
      }
    }

    return form_warnings
  }

  // moving to an object approach for the warnings. we need to check and see if each individual
  // field warnings object is empty or not.
  $scope.check_for_warnings_result = (form: any, tab: any) => form[tab] && (form[tab].$warnings !== undefined) && !_.isEmpty(form[tab].$warnings)

  const emit_form_warning = function (form: any) {
    const alert = {
      name: 'form_warning',
      dismissable: true,
      class: 'alert-warning',
      icon: 'fa-exclamation-triangle',
      strong: 'Notice',
      message: `There are possible errors on this form, marked in orange. \
Please look over each tab carefully and ensure that you wish to continue. \
Confirm by clicking save again.`
    }
    flashService.emit_alert($scope, alert)
    return form.warned_user = true
  }

  const emit_form_error = function (form: any) {
    const alert = {
      name: 'form_error',
      dismissable: true,
      class: 'alert-danger',
      icon: 'fa-exclamation-triangle',
      strong: 'Notice',
      message: `There are errors on this form marked in red. \
Please look over them carefully and resolve.`
    }
    return flashService.emit_alert($scope, alert)
  }

  $scope.attempt_submit_form = function (form: any, object: any) {
    // per COS 813, it was decided that tags and orgs needed to be required still after security changes on tag/org accesses
    // with changes to 'all', 'some', 'none'. As a compromise, we settled on notifying the acting user if they
    // didn't fill out the tags or orgs. As a result, we made the user have to click the submit button twice,
    // if they wish to create a user without tags or orgs.

    form.submit_attempted = true

    // due to too many scenarios, it was easier and simpler to separate the logic into 3 different ifs.
    // This has allowed for each scenario to act independently of the others.

    // to pass the test and create the user, the form has to be valid
    // and either the form has to have warnings and have warned the user
    // or the form can't have warnings.
    if (
      form.$valid &&
      ((form_has_warnings(form) && (form.warned_user === true)) || !form_has_warnings(form))
    ) {
      submit_form(form, object)
    }

    // no matter what, if there is a field that is invalid, we need to throw the error message
    if (form.$invalid) { // form.$invalid
      emit_form_error(form)
    }

    // if there are warnings and we haven't warned the user, throw them the warnings message.
    if (
      form_has_warnings(form) &&
      (form.warned_user === undefined)
    ) {
      return emit_form_warning(form)
    }
  }

  var submit_form = (form: any, object: any) =>
    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will
    // be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(form, function () {
      // we only want the first save order if we have role that is changing and the requesting user
      // has all org access or no org access.
      // The second is necessary for org admins. Due to associated orgs, a user has to have associated orgs
      // with the requesting user before the role can be saved.
      // This does not matter for users with all or none org access, only some access

      let promise_chain
      if ($scope.user.user_role.$pending_user_role && (($rootScope.session.getUser().user_role.role.orgs_access === 'all') || ($rootScope.session.getUser().user_role.role.orgs_access === 'none'))) {
        promise_chain = [
          'base_object_save_promise_alias', // always first, defaults user role to user
          'user_role_manager_promise_alias',
          'user_organization_manager_promise_alias', // user role depends upon user organization.
          'tagging_manager_promise_alias',
          'profile_manager_promise_alias',
          'user_lob_manager_promise_alias',
          'state_manager_promise_alias' // always last
        ]
      } else {
        promise_chain = [
          'base_object_save_promise_alias', // always first, defaults user role to user
          'user_organization_manager_promise_alias', // user role depends upon user organization.
          'tagging_manager_promise_alias',
          'profile_manager_promise_alias',
          'user_role_manager_promise_alias',
          'user_lob_manager_promise_alias',
          'state_manager_promise_alias' // always last
        ]
      }

      if ($scope.role_allows_tag_selection !== true) {
        // user does not needc LOB's, remove entry from promise chain
        promise_chain = promise_chain.filter(n => n != 'user_lob_manager_promise_alias')
      }

      return complyosServices.save_relay.start(
        $scope,
        object,
        form,
        'users',
        promise_chain,
        closeModalAndReturn
      )
    })

  /* MODAL */
  // close modal, and pass object back to initiator
  var closeModalAndReturn = () =>
    // get the full object so that it merges nicely into the list
    get_user().then(() => $uibModalInstance.close($scope.user))

  // dismiss the modal and provide a reason
  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.$watch('user.user_role.$pending_user_role', 
    function (newValue: any, oldValue: any) {
      $scope.user.user_lobs = []
      $scope.role_selected = role_selected()
      $scope.pending_or_existing_user_role = pending_or_existing_user_role()
      $scope.role_allows_org_selection = role_allows_org_selection()
      $scope.add_user_lobs_placeholder()
      $scope.pending_or_existing_multi_lob = pending_or_existing_multi_lob()
      return $scope.role_allows_tag_selection = role_allows_tag_selection()
    }, true
  )

  $scope.watch_user_lobs = () => {
    $scope.$watch('user.user_lobs.$pending_user_lobs',
      function (newValue: any, oldValue: any) {
        return $scope.pending_or_existing_user_lobs = pending_or_existing_user_lobs()
      }, true
    )
  }

  return init()
}])
