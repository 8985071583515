import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as Restangular from '../../../vendor/restangular/restangular'
import angular from 'angular';
import { FlashService } from '../../services/flash_service.service';

@Component({
  selector: 'ngVersionLabel', //ng-version-label
  templateUrl: './version-label.component.html'
})
export class VersionLabel implements OnInit {
  @Input() versionNumber: string;

  @Input() requirementId: number;
  @Input() isEditState: boolean = false;
  @Input() isSnapshot: boolean = false;
  @Input() isCurrentVersion: boolean = false;
  @Input() isNew: boolean = false;

  @Output() viewCurrentVersionFn = new EventEmitter();

  private flashService: any;

  private failure: boolean = false;
  private updated_requirement: any = null;

  constructor(
    private Restangular: Restangular,
    @Inject('$scope') private $scope,
    @Inject(FlashService) flashService: FlashService,
  ){
    this.flashService = angular.copy(flashService)
  }

  ngOnInit(): void {
    // TODO: MOVE (just like requirement-tabs.component.ts)
    this.listen_for_form_save_event()
  }



  getVersionNumberTooltip = () => "version number: " + this.versionNumber;

  // TODO: MOVE (just like requirement-tabs.component.ts)
  save_state = () => {
    if (this.requirementId == null || this.requirementId < 1 || this.isEditState != true) {
      return this.emit_completion_event(null);
    } else {
      return this.Restangular.one(`requirements/${this.requirementId}/complete_edit_state`).get().then((response: any) => {
        this.updated_requirement = response.plain()
        this.failure = false
      }, (error_response: any) => {
        const alert = {
          name: 'commit_version_changes',
          dismissable: true,
          class: 'alert-warning',
          icon: 'fa-exclamation-triangle',
          strong: 'Error:',
          message: error_response.data[0]
        }
        this.flashService.emit_alert(this.$scope, alert)
        return this.failure = true
      }).finally(() => {
        this.emit_completion_event(this.updated_requirement)
      });
    }
  }

  /* WATCHERS, LISTENERS AND EMITTERS */
  // TODO: MOVE (just like requirement-tabs.component.ts)
  listen_for_form_save_event() {
    return this.$scope.$on('version_control_completor_start', (event: any, data: any) => {
      return this.save_state()
    });
  }

  // TODO: MOVE (just like requirement-tabs.component.ts)
  emit_completion_event(updated_requirement: any) {
    const return_object = {
      manager: 'version_control_completor',
      object: updated_requirement,
      fault: this.failure
    }

    return this.$scope.$emit('version_control_completor_finished', return_object)
  }
}

angular
  .module('complyosClient')
  .directive('ngVersionLabel', downgradeComponent({ component: VersionLabel }) as angular.IDirectiveFactory)