/* eslint-disable
    camelcase,
    eqeqeq,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var dayjs = require("dayjs")

var complyosClient = angular.module('complyosClient')

complyosClient.controller('dateRangeController', ['$scope', '$rootScope', 'dateService', function (
  $scope: any,
  $rootScope: any,
  dateService: any
) {
  $scope.toggle_panel_body = () => $scope.collapse_panel_body = !$scope.collapse_panel_body

  const init = function () {
    $scope.collapse_panel_body = true
    $scope.presets = [
      // {
      //   name: '11 yr',
      //   range: {
      //     date_range_begin: dayjs().subtract(10, 'years').$d,
      //     date_range_end: dayjs().add(1, 'years').$d
      //   }
      // },
      {
        name: '6 mo',
        range: {
          date_range_begin: dayjs().subtract(6, 'months').$d,
          date_range_end: dayjs().$d
        }
      },
      {
        name: '1 yr',
        range: {
          date_range_begin: dayjs().subtract(1, 'years').$d,
          date_range_end: dayjs().$d
        }
      },
      {
        name: '2 yr',
        range: {
          date_range_begin: dayjs().subtract(2, 'years').$d,
          date_range_end: dayjs().$d
        }
      },
      {
        name: '3 yr',
        range: {
          date_range_begin: dayjs().subtract(3, 'years').$d,
          date_range_end: dayjs().$d
        }
      }
    ]
    return $scope.select_preset($scope.presets[0])
  }

  const reset_active_preset = () =>
    _.each($scope.presets, (p: any) => p.active = undefined)

  const ranges_are_similar = function (range_a: any, range_b: any) {
    const a_begin = dateService.convert_to_imt_date(range_a.date_range_begin)
    const a_end = dateService.convert_to_imt_date(range_a.date_range_end)
    const b_begin = dateService.convert_to_imt_date(range_b.date_range_begin)
    const b_end = dateService.convert_to_imt_date(range_b.date_range_end)
    return a_begin == b_begin && a_end == b_end
  }

  const format_range = (range: any) => ({
    date_range_begin: dateService.convert_to_imt_date(range.date_range_begin),
    date_range_end: dateService.convert_to_imt_date(range.date_range_end)
  })

  const broadcast_range_update = function (range: any) {
    $scope.broadcast_range = format_range(range)
    return $rootScope.$broadcast('dashboard_date_range_update', $scope.broadcast_range)
  }

  $rootScope.$on('request_dashboard_date_range', (event: any, data: any) => $rootScope.$broadcast('dashboard_date_range', $scope.broadcast_range))

  $scope.select_preset = function (preset: any) {
    if (!preset.active) {
      reset_active_preset()
      preset.active = true
      $scope.range = angular.copy(preset.range)
      return broadcast_range_update(format_range(preset.range))
    }
  }

  $scope.select_range = function (range: any) {
    if (!ranges_are_similar($scope.broadcast_range, range)) {
      reset_active_preset()
      return broadcast_range_update(format_range(range))
    }
  }

  return init()
}])
