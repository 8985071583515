// import { Injectable } from '@angular/core';
import * as angular from 'angular';

import { Injectable, Inject } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';
// import { ContactResource } from './contact.resource'
import { njsResource } from '../../scripts/services/njs_resource.resource'
import { PureNg12Service } from './pure-ng12-service.service';

@Injectable()
export class ng12Service {

    constructor(
        // @Inject(ContactResource) private ContactResource: ContactResource,
        @Inject(PureNg12Service) private pureNg12Service: PureNg12Service,
        private njsResource: njsResource
        ) {
        // this.ContactResource = ContactResource;
        this.loadContacts();
        this.pureNg12Service.whoami('ng12_service')
    }

    loadContacts() {
        // this.ContactResource.gettest(77);

        this.njsResource.whoami("ng12_service");

        // this.runLogsService.ApiAction_delete(111);
    }

    removeContact(person) {
        console.log('in contact service')
        console.log('params: ' + person)
    }
}

angular
    .module("complyosClient")
    .factory("ng12Service", downgradeInjectable(ng12Service));