/* eslint-disable
    camelcase,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


angular.module('complyosClient').directive('pieOfTheDayChart', [
  'complyosServices',
  (complyosServices: any) => ({
    templateUrl: 'views/directives/tasks_to_complete_pie_chart.html',
    restrict: 'E',

    scope: {
      fruitalytics: '=fruitalytics'
    },

    link ($scope: any, $element: any, $attributes: any) {
      let pie_total = (
        ($scope.fruitalytics.data['*-#'] ? $scope.fruitalytics.data['*-#'] : 0) +
        ($scope.fruitalytics.data['*-X'] ? $scope.fruitalytics.data['*-X'] : 0) +
        ($scope.fruitalytics.data['1'] ? $scope.fruitalytics.data['1'] : 0)
      )

      $scope.slices = []

      if ($scope.fruitalytics.data['*-#']) {
        $scope.slices.push({
          label: 'Open Tasks',
          value: $scope.fruitalytics.data['*-#'],
          percent: ($scope.fruitalytics.data['*-#'] / pie_total),
          color: complyosServices.snapshot.get_status_color('open')
        })
      }

      if ($scope.fruitalytics.data['*-X']) {
        $scope.slices.push({
          label: 'Past Due Tasks',
          value: $scope.fruitalytics.data['*-X'],
          percent: ($scope.fruitalytics.data['*-X'] / pie_total),
          color: complyosServices.snapshot.get_status_color('past_due')
        })
      }

      if ($scope.fruitalytics.data['1']) {
        $scope.slices.push({
          label: 'Partially Complete Tasks',
          value: $scope.fruitalytics.data['1'],
          percent: ($scope.fruitalytics.data['1'] / pie_total),
          color: complyosServices.snapshot.get_status_color('partially_complete')
        })
      }

      const pieEl = document.querySelector('svg')

      let cumulativePercent = 0

      const getCoordinatesForPercent = function (percent: any) {
        const x = Math.cos(2 * Math.PI * percent)
        const y = Math.sin(2 * Math.PI * percent)
        return [x, y]
      }

      const get_large_arc_flag = function (percent: any) {
        if (percent > 0.5) {
          return 1
        } else {
          return 0
        }
      }

      return _.each($scope.slices, function (slice: any) {
        const start_array = getCoordinatesForPercent(cumulativePercent)
        const startX = start_array[0]
        const startY = start_array[1]

        cumulativePercent += slice.percent

        const end_array = getCoordinatesForPercent(cumulativePercent)
        const endX = end_array[0]
        const endY = end_array[1]

        const largeArcFlag = get_large_arc_flag(slice.percent)

        const pathData = [
          `M ${startX} ${startY}`,
          `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`,
          'L 0 0'
        ].join(' ')

        const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        pathEl.setAttribute('d', pathData)
        pathEl.setAttribute('fill', slice.color)
        return pieEl.appendChild(pathEl)
      });
    }
  })

]
)
