/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'


var complyosClient = angular.module('complyosClient')

complyosClient.controller('organizationModalController', [ '$scope', '$uibModalInstance', 'dateService', 'complyosServices', 'flashService', 'fortyCore', 'modalOptions', 'Restangular', 'treeManagerService', 'utils', function (
  $scope: any,
  $uibModalInstance: any,
  dateService: any,
  complyosServices: any,
  flashService: any,
  fortyCore: any,
  modalOptions: any,
  Restangular: any,
  treeManagerService: any,
  utils: any
) {
  const init = function () {
    $scope.processingStateService = fortyCore.processingStateService
    
    $scope.flashService = angular.copy(flashService)
    $scope.organization = modalOptions.organization
    $scope.ui_setting = modalOptions.ui_setting
    
    $scope.fortyCore = angular.copy(fortyCore)
    
    $scope.tree_manager = angular.copy(treeManagerService)
    $scope.business_is_loading = true
    // Prepare Tags for select list
    
    $scope.parent_select_opitons = angular.copy(flatten_organizations(modalOptions.organizations))

    $scope.surveying_entities = [
      'AAAHC-MDS',
      'AAAHC-NMDS',
      'CMS',
      'EPA',
      'TJC-AHC',
      'TJC-HAP',
      'UCA'
    ]

    $scope.date_helpers = {
      date_survey_last: {
        message: '',
        visible: false
      },
      date_survey_next_begin: {
        message: '',
        visible: false
      },
      date_survey_next_end: {
        message: '',
        visible: false
      },
      surveying_entity: {
        message: '',
        visible: false
      }
    }

    // ensure self or children cannot be selected when ui_setting === 'update'
    if (
      $scope.ui_setting === 'update' &&
      $scope.organization.id !== undefined
    ) {
      disable_organizations($scope.organization)
    }

    if ($scope.organization.id) {
      return (get_full_organization())
    } else {
      $scope.selected_business_type = {}
      $scope.is_business_create = true
      return getBusinessParent()
    }
  }

  var getBusinessParent = () => {
    Restangular.all('tags/filter_business_parent').getList().then(function (success: any) {
      $scope.flat_tags = $scope.tree_manager.flatten_tree(success)
      $scope.business_is_loading = false
      return $scope.tree_manager.set_show_property($scope.flat_tags)
    }
    , (error: any) => utils.log(error))
  }

  let get_full_organization = () => Restangular.all('organizations').get($scope.organization.id).then(function (response: any) { // success
    response.date_survey_last = dateService.convert_from_imt_date(response.date_survey_last)
    response.date_survey_next_begin = dateService.convert_from_imt_date(response.date_survey_next_begin)
    response.date_survey_next_end = dateService.convert_from_imt_date(response.date_survey_next_end)

    $scope.organization = response
    $scope.selected_business_type = response.taggings.length > 0 ? response.taggings[0].tag : {}
    $scope.is_business_create = response.taggings.length === 0
    getBusinessParent()
    $scope.original_date_survey_last = $scope.organization.date_survey_last
    $scope.original_date_survey_next_begin = $scope.organization.date_survey_next_begin
    $scope.original_date_survey_next_begin = $scope.organization.date_survey_next_end
  }
  , (error: any) => utils.log(error))
  /* DISPLAY */

  // creates a flattened array of the nested $scope.parent_select_opitons array and updates
  // the name with spacing for tree structure
  var flatten_organizations = function (organizations_array: any) {
    organizations_array.sort(function (a: any, b: any) {
      if (a['name'].toLowerCase() > b['name'].toLowerCase()) {
        return 1
      } else {
        return -1
      }
    })
    let flattened_organizations = []
    let i = 0
    while (i < organizations_array.length) {
      organizations_array[i].name = `${determine_depth(organizations_array[i])}${organizations_array[i].name}`
      flattened_organizations.push(organizations_array[i])
      flattened_organizations = flattened_organizations.concat(flatten_organizations(organizations_array[i]['children']))
      i++
    }
    return flattened_organizations
  }

  // creates dynamic indentions for the organizations select menu
  // TODO maybe not the best name.
  var determine_depth = function (organization: any) {
    let spacing = ''
    let i = 0
    while (i < organization.depth) {
      spacing += '\u00A0\u00A0'
      i++
    }
    return spacing
  }

  // helper method to disable select options that are self, children, descendants
  // Adds disabled attr option item in $('#parent_select option').
  // takes Arg:
  //   - organization: object
  // Returns
  //  nothing
  var disable_organizations = function (organization: any) {
    // Find self, disble
    let option
    for (option of Array.from($('#parent_select option'))) {
      if (option.value === `number:${organization.id}`) {
        option.disabled = true
      }
    }

    // find children, disable
    let i = 0
    while (i < organization.children.length) {
      for (option of Array.from($('#parent_select option'))) {
        if (option.value === `number:${organization.children[i].id}`) {
          option.disabled = true
          disable_organizations(organization.children[i])
        }
      }
      i++
    }
  }

  $scope.submit_form = (form: any, object: any) =>
    // State service will set the form to a state of PENDING
    // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
    $scope.processingStateService.performFunction(form, function () {
      let number_of_errors = 0

      if (
        $scope.organization.date_survey_next_begin !== $scope.original_date_survey_next_begin ||
        $scope.organization.date_survey_next_end !== $scope.original_date_survey_next_end ||
        $scope.organization.date_survey_last !== $scope.original_date_survey_last
      ) {
        number_of_errors = dateService.organization_date_validation($scope.date_helpers, object)
      } else {
        number_of_errors = 0
      }

      if (_.isEmpty($scope.selected_business_type) || $scope.selected_business_type.undefined) {
        number_of_errors++
      }

      if (number_of_errors === 0) {
        // successful save path
        $scope.date_helpers.date_survey_next_begin.visible = false
        $scope.date_helpers.date_survey_next_end.visible = false
        $scope.date_helpers.surveying_entity.visible = false
        object['org_type'] = $scope.selected_business_type.tag_title;

        return start_save(object, form)
      } else {
        // fail submission path
        $scope.processingStateService.set(form, $scope.processingStateService.processing_states.RESTING)
        return form.failed_submission = true
      }
    })

  /* SAVE PROCESS COUNTERS */
  $scope.fault_counter = undefined
  $scope.pending_requests = undefined

  const increment_fault_counter = () => $scope.fault_counter++

  /* SAVE TRIGGERS */
  var start_save = function (object: any, form: any) {
    $scope.pending_requests = 0
    $scope.fault_counter = 0
    return save_base_object(object, form)
  }

  const after_save = (object: any = null) => trigger_state_manager_start(object)

  const finally_do = function () {
    $scope.processingStateService.set($scope.organization_form, $scope.processingStateService.processing_states.RESTING)
    if ($scope.fault_counter === 0) {
      return closeModalAndReturn($scope.organization)
    }
  }

  var save_the_tags = function (object: any, form: any) {
    let tagging :any= {
      tag_id: $scope.selected_business_type.id,
      taggable_id: object.id,
      taggable_type: 'Organization'
    }
    if ($scope.is_business_create === true) {
      create_the_tags(tagging)
    } else {
      tagging.id = object.taggings[0].id
      update_the_tags(tagging)
    }
  }

  var create_the_tags = function (tagging: any) {
    return Restangular.all('taggings').post(tagging).then((success: any) => {
      $scope.organization.taggings = [success.tag]
    });
  }

  var update_the_tags = function (tagging: any) {
    return Restangular.one('taggings', tagging.id).patch(tagging).then((success: any) => {
      $scope.organization.taggings[0].tag = success.tag
    });
  }

  /* SAVE FUNCTIONS / LISTENERS */
  var save_base_object = function (object: any, form: any) {
    // TODO there is probably a better way to do the below
    if ($scope.ui_setting === 'create') {
      return Restangular.all('organizations').post(object).then((success: any) => {
        $scope.organization = success
        object.id = success.id
        save_the_tags(object, form)
      }, function (error: any) {
        increment_fault_counter()
        return $scope.flashService.process_error(error)
      }).finally(() => after_save());
    } else {
      return Restangular.one('organizations', object.id).patch({ organization: object }).then((success: any) => {
        $scope.organization = success
        save_the_tags(object, form)
      }, function (error: any) {
        increment_fault_counter()
        return $scope.flashService.process_error(error)
      }).finally(() => after_save());
    }
  }

  var trigger_state_manager_start = function (object: any) {
    if (($scope.fault_counter === 0) && ($scope.ui_setting !== 'create')) {
      return $scope.$broadcast('state_manager_start', object)
    } else {
      return finally_do()
    }
  }

  $scope.$on('state_manager_finished', function (event: any, manager_result: any) {
    // Only update state if changes were made
    if (manager_result.object !== null) {
      $scope.organization.state = manager_result.object
    }
    if (manager_result.fault) { increment_fault_counter() }
    return finally_do()
  })

  $scope.dismissModal = function () {
    $uibModalInstance.dismiss('cancel')
  }

  var closeModalAndReturn = function (object: any) {
    $uibModalInstance.close(object)
  }

  $scope.handleBusinessChange = function (type: any) {
    let selected_tag = $scope.flat_tags.find((f: any) => f.id === type.id)
    $scope.selected_business_type.id = selected_tag.id
    $scope.selected_business_type.tag_title = selected_tag.title
  }

  return init()
}])
