import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { TermsOfServiceComponent } from '../modals/terms-modal/terms-of-service.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from '../modals/privacy-modal/privacy-policy.component';

@Component({
  selector: 'ngFooter',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor(
    @Inject('$rootScope') private $rootScope,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  clickHelpLink = () => {
    if (this.$rootScope.session.activeUserPresent()) {
      this.$rootScope.session.goTo('/help')
    } else {
      this.$rootScope.session.goTo('/marketing')
    }
  }

  openTermsOfServiceModal = () => {
    this.dialog.open(TermsOfServiceComponent, {
      width: '100%'
    })
  }

  openPrivacyPolicyModal = () => {
    this.dialog.open(PrivacyPolicyComponent, {
      width: '100%'
    })
  }
}

angular
  .module('complyosClient')
  .directive('ngFooter', downgradeComponent({ component: FooterComponent }) as angular.IDirectiveFactory)
