import { Component, Inject, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
var dayjs = require("dayjs")

@Component({
  selector: 'ngSurveyDate',
  templateUrl: './survey-date.component.html'
})
export class SurveyDateComponent implements OnInit {
  @Input() organization: any;
  
  // Properties
  private dateSurveyLast: Date;
  private dateSurveyNextBegin: Date;
  private dateSurveyNextEnd: Date;
  
  constructor(
    @Inject('$rootScope') private $rootScope,
  ) {
  }

  ngOnInit(): void {
    this.dateSurveyLast = dayjs(this.organization.date_survey_last).format('MMMM DD, YYYY')
    this.dateSurveyNextBegin = dayjs(this.organization.date_survey_next_begin).format('MMMM DD, YYYY')
    this.dateSurveyNextEnd = dayjs(this.organization.date_survey_next_end).format('MMMM DD, YYYY')
  }
}

angular
  .module('complyosClient')
  .directive('ngSurveyDate', downgradeComponent({ component: SurveyDateComponent }) as angular.IDirectiveFactory)
