/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars
*/
'use strict'
// test also a test

angular.module('complyosClient').directive('filterPill', [
  'fortyCore', 'helpService',
  (fortyCore, helpService) => ({
    templateUrl: 'views/directives/filter_pill.html',
    restrict: 'E',

    scope: {
      index: '<',
      displayName: '<',
      value: '<',
      removeCallback: '&',
      isRequired: '<',
      isHidden: '<',
      includeDescendants: '<',
      descendantsName: '<',
      isIgnored: '<'
    },

    link ($scope: any, $ele: any, $attrs: any) {
      $scope.getTooltip = () => {
        let text = ''
        if ($scope.isRequired) {
          let requiredText = $scope.displayName + ' is locked or required. '
          text = text + requiredText
        }
        if ($scope.isIgnored) {
          let ignoredText = 'This filter-type is disabled on this page, it is ignored when your filters are applied'
          text = text + ignoredText
        }

        return text
      }

      $scope.tooltipText = $scope.getTooltip()
      
      $scope.helpService = angular.copy(helpService)
      return $scope.fortyCore = fortyCore
    }
  })
])
