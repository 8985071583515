// /* eslint-disable
//     camelcase,
//     no-return-assign,
//     no-undef,
// */
// // TODO: This file was created by bulk-decaffeinate.
// // Fix any style issues and re-enable lint.
// /*
//  * decaffeinate suggestions:
//  * DS102: Remove unnecessary code created because of implicit returns
//  * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
//  */
// 'use strict'

//  //- --------------------------------------------------------------------
//  //- UPGRADED: see requirements-list.component.ts
//  //- --------------------------------------------------------------------

// var complyosClient = angular.module('complyosClient')

// complyosClient.controller('complyosRequirementsController', ['$uibModal', '$scope', '$rootScope', 'complyosServices', 'ENV', 'flashService', 'fortyCore', 'loadingStateService', 'objectManager', 'Restangular', 'utils', 'confirmationModalFactory', 'arrayFunctions', function (
//   $uibModal: any,
//   $scope: any,
//   $rootScope: any,
//   complyosServices: any,
//   ENV: any,
//   flashService: any,
//   fortyCore: any,
//   loadingStateService: any,
//   objectManager: any,
//   Restangular: any,
//   utils: any,
//   confirmationModalFactory: any,
//   arrayFunctions: any
// ) {
//   const init = function () {
    
//     $scope.flashService = angular.copy(flashService)
    
//     $scope.loadingStateService = angular.copy(loadingStateService)
//     $scope.processingStateService = fortyCore.processingStateService
//     $scope.previousPills = []
//     const custom_states = {
//       nocontent: {
//         icon: 'fa-exclamation-triangle',
//         text: 'The filters you have applied do not return any results.  Please select new filters and try again.'
//       }
//     }
//     $scope.loadingStateService.loading_states = _.merge($scope.loadingStateService.loading_states, custom_states)
//     $scope.loadingStateService.init()
//     $scope.requirementsCount = 0

//     watchEntityFilter()
//   }

//   var getRequirements = function (pills: any) {
//     let query_params = {
//       param_data: $rootScope.storeService.stringifyTagParams(
//         $rootScope.storeService.pillsToObject(pills)
//       )
//     }
//     $scope.loadingStateService.set_state('loading')
//     return Restangular.one('requirements').get(query_params).then(function (success: any) {
//       $scope.loadingStateService.process_success(success.items)
//       $rootScope.$broadcast('pagination-results-updated', success.pagination_info)
//       $scope.requirements = success.items
//       $scope.requirementsCount = success.pagination_info.totalCount
//     }
//     , (error: any) => {
//       $scope.loadingStateService.process_error(error)
//       utils.log(error)
//     }
//     );
//   }

//   /* SORT */
//   // SORTING WILL NOW BE DONE VIA THE GLOBAL FILTER
//   // THESE ARE UNUSED NOW. LEAVING COMMENTED OUT JUST IN CASE

//   // The result of these functions will be sorted using the <, ===, > operator.
//   // $scope.order_requirements_by_interval = (requirement) => {
//   //   if (requirement.interval !== undefined) {
//   //     return requirement.interval.order
//   //   } else {
//   //     return 0
//   //   }
//   // }

//   // $scope.order_requirements_by_requirement = requirement => requirement.name

//   // $scope.order_requirements_by_compliance_method = requirement => requirement.compliance_method

//   // $scope.order_requirements_by_published = requirement => requirement.published

//   /* CRUD */

//   $scope.openRequirementCreateModal = function () {
//     const new_requirement_object = {
//       attachments: [], // blank arrays are added so we can merge new items into something
//       taggings: [],
//       reference_objects: [],
//       notes: [],
//       author_notes: [],
//       annotation_state: 'annotation_optional',
//       schedule_on: true,
//       start_date_needed: true,
//       allow_partially_complete: true,
//       allow_closed_not_complete: false,
//       task_agent_cannot_attest: false
//     }
//     return openRequirementModal(new_requirement_object, 'create')
//   }

//   $scope.openRequirementReadModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => openRequirementModal(requirement_object, 'read'))

//   $scope.openDuplicateRequirementModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => {
//       openRequirementModal(requirement_object, 'duplicate')
//     })

//   $scope.openRequirementUpdateModal = (requirement_object: any) => // State service will set the object to a state of PENDING
//     // After action is performed or cancelled, that action will be responsible for setting object to RESTING state
//     $scope.processingStateService.performFunction(requirement_object, () => openRequirementModal(requirement_object, 'update'))

//   $scope.openRequirementDestroyModal = function (requirement_object: any) {
//     const confirmation_object = {
//       // severity: severity string
//       // title: title string
//       // button_icon: icon string
//       // button_text: text string
//       // deny: method to run on dismiss
//       confirm () { return destroy_requirement(requirement_object) },
//       message: `<p>This action will:</p><ul><li>Delete the requirement ${requirement_object.name}</li><li>Delete all the configured requirements that have this requirement linked to it</li><li>Delete all requirement entries, attachments and notifications associated with the configured requirements being deleted.</li></ul><p>This action is not reversible.</p>`
//     }

//     // State service will set the object to a state of PENDING
//     // After action is performed or cancelled,
//     // that action will be responsible for setting object to RESTING state
//     return $scope.processingStateService.performFunction(requirement_object, () =>
//       confirmationModalFactory.openConfirmationModal(confirmation_object).then(function (result: any) {
//         // Closing confirmation modal always gives a result
//         // If result is false (cancel), then set back to resting
//         if (!result) {
//           $scope.processingStateService.set(
//             requirement_object,
//             $scope.processingStateService.processing_states.RESTING
//           )
//         }
//         return true
//       })
//     );
//   }

//   var destroy_requirement = (requirement: any) => Restangular.all(`requirements/${requirement.id}`).remove().then((success: any) => objectManager.array_action($scope.requirements, requirement, 'remove')

//     , function (error: any) {
//       $scope.processingStateService.set(
//         requirement,
//         $scope.processingStateService.processing_states.RESTING
//       )
//       $scope.flashService.process_error(error)
//       return utils.log(error)
//     })

//   // https://angular-ui.github.io/bootstrap/#/modal
//   var openRequirementModal = function (requirement_object: any, ui_setting: any) {
//     const modalInstance = $uibModal.open({
//       templateUrl: 'views/modals/requirement_modal.html',
//       controller: 'requirementModalController',
//       size: 'lg',
//       resolve: {
//         // this is the object that can be passed to the modal.
//         // we copy it to eliminate binding issues
//         // require it in the dependecies as modalOptions
//         modalOptions () {
//           return {
            
//             object: angular.copy(requirement_object),
//             ui_setting
//           }
//         }
//       }
//     })

//     // on close
//     // - grab the returned result,
//     // - or log out a reason
//     // - finally, sets the object back to a resting state
//     return modalInstance.result.then(function (result: any) {
//       if (ui_setting === 'create' || ui_setting === 'duplicate') {
//         objectManager.array_action($scope.requirements, result, 'merge')
//       }
//       if (ui_setting === 'update') {
//         return objectManager.array_action($scope.requirements, result, 'update')
//       }
//     }, function (reason: any) {

//     }).finally(() =>
//       $scope.processingStateService.set(
//         requirement_object,
//         $scope.processingStateService.processing_states.RESTING
//       )
//     );
//   }

//   var watchEntityFilter = () => {
//     var refresh = function (e: any, pills: any) {
//       let widgetShouldRefresh = arrayFunctions.hasMeaningfulListChange($scope.previousPills, pills, ['sorted'])
//       $scope.previousPills = pills
//       if (widgetShouldRefresh) {
//         getRequirements(pills)
//       }
//     }
//     $scope.$on('filterChange-dashboard', refresh)
//   }

//   return init()
// }])
