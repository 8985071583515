/* eslint-disable
    camelcase,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict'

var complyosClient = angular.module('complyosClient')

complyosClient.controller('digestController', ['$scope', 'Restangular', '$rootScope', function (
  $scope: any,
  Restangular: any,
  $rootScope: any
) {
  const get_digest = function () {
    const digest = Restangular.one(`users/${$rootScope.session.getUser().id}/digest`)
    return digest.get().then((
      // success
      response: any
    ) => $scope.digest = response
    , (
      // error
      error: any
    ) => console.log(error)
    );
  }

  $scope.viewItem = (item: any) => {
    $rootScope.session.setOrganization(item.organization)
    $rootScope.session.goTo('/requirements/binder/' + item.id)
  }

  return get_digest()
}])
