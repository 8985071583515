/* eslint-disable
  no-return-assign,
  no-undef,
  semi,
  operator-linebreak,
  no-unused-vars,
  eqeqeq
*/
'use strict'

var dayjs = require("dayjs")

var SatalitePaginatorComponent = {
  selector: "satalitePaginator", //<satalite-paginator>
  // templateUrl: 'views/directives/satalite_paginator.html',
  template: require('../../views/directives/satalite_paginator.pug'),
  restrict: 'E',
  bindings: {
    paginationChangeBroadcast: '<',
    paginationChangeListen: '<'
  },

  controller: class SatalitePaginatorController {
    // BINDING PLACEHOLDERS
    private paginationChangeBroadcast: any;
    private paginationChangeListen: any;

    // PROPERTIES
    private paginationParams: any = {};
    private paginationResults: any = {};


    static $inject = ['fortyCore', 'constants', 'arrayFunctions', 'helpService', '$scope', '$rootScope',];
    constructor(private fortyCore, private constants, private arrayFunctions, private helpService, private $scope, private $rootScope) {
    }

    $onInit = () => {
      this.paginationParams = {}
      this.paginationResults = {}

      this.paginationResultsReceived(this.paginationResults)
      let that = this
      this.$scope.$on(this.paginationChangeListen, function (e: any, paginationInfo: any) {
        that.paginationResults = paginationInfo
        that.paginationResultsReceived(paginationInfo)
      })
    }

    paginationResultsReceived = (updatedPaginationResults: any) => {
      this.paginationParams.page = updatedPaginationResults.currentPage
      this.paginationParams.pageSize = updatedPaginationResults.pageSize
    }

    paginationChanged = () => {
      let currentPage = this.paginationParams.page
      let pageSize = this.paginationParams.pageSize
      let message = 'page: ' + currentPage + ' (' + pageSize + '/pg)'

      let pagePill = {
        isUnique: true,
        isRequired: true,
        isHidden: true,
        Field: 'pagination_params',
        Op: this.constants.FilterType.Select,
        displayName: 'Page',
        displayValue: message,
        Value: this.paginationParams,
        dateCreated: dayjs().$d
      }
      this.$rootScope.$broadcast(this.paginationChangeBroadcast, pagePill);
    }

  }
}

angular
  .module('complyosClient')
  .component(SatalitePaginatorComponent.selector, SatalitePaginatorComponent);

//- --------------------------------------------------------------------
//- FOR REFERENCE

// angular.module('complyosClient').directive('satalitePaginator', [
//   'fortyCore', 'constants', 'arrayFunctions', 'helpService', '$rootScope',
//   (fortyCore, constants, arrayFunctions, helpService, $rootScope) => ({
//     // templateUrl: 'views/directives/satalite_paginator.html',
//     template: require('../../views/directives/satalite_paginator.pug'),
//     restrict: 'E',

//     scope: {
//       paginationChangeBroadcast: '<',
//       paginationChangeListen: '<'
//     },

//     link ($scope: any, $ele: any, $attrs: any) {
//       $scope = this.registerFunctions($scope)

//       $scope.paginationParams = {}
//       $scope.paginationResults = {}

//       $scope.paginationResultsReceived($scope.paginationResults)

//       $scope.$on($scope.paginationChangeListen, function (e: any, paginationInfo: any) {
//         $scope.paginationResults = paginationInfo
//         $scope.paginationResultsReceived(paginationInfo)
//       })
//     },

//     registerFunctions ($scope: any) {
//       $scope.paginationResultsReceived = (updatedPaginationResults: any) => {
//         $scope.paginationParams.page = updatedPaginationResults.currentPage
//         $scope.paginationParams.pageSize = updatedPaginationResults.pageSize
//       }

//       $scope.paginationChanged = () => {
//         let currentPage = $scope.paginationParams.page
//         let pageSize = $scope.paginationParams.pageSize
//         let message = 'page: ' + currentPage + ' (' + pageSize + '/pg)'

//         let pagePill = {
//           isUnique: true,
//           isRequired: true,
//           isHidden: true,
//           Field: 'pagination_params',
//           Op: constants.FilterType.Select,
//           displayName: 'Page',
//           displayValue: message,
//           Value: $scope.paginationParams,
//           dateCreated: dayjs().$d
//         }
//         $rootScope.$broadcast($scope.paginationChangeBroadcast, pagePill);
//       }

//       return $scope;
//     }
//   })

// ])
