import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { helpService } from '../../../../scripts/services/help';
import angular from 'angular';
import _ from 'lodash';
import { ArrayFunctions } from '../../../../scripts/utilities/ajs-array_functions';
import * as Restangular from '../../../../vendor/restangular/restangular';
import dayjs from 'dayjs';

@Component({
  selector: 'ngSnapshot',
  templateUrl: './snapshot.component.html'
})
export class SnapshotComponent implements OnInit {
  // Injected Services
  private reportLoadingStateService: any;
  private snapshotLoadingStateService: any;
  
  // Properties
  private deferredRefresh: any;
  private outstandingPromises: Array<any> = [];
  private datePills: any;
  private organizationSnapshot: any;
  private previousPills: any;
  
  constructor(
    @Inject('$uibModal') private $uibModal,
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('$q') private $q,
    @Inject('loadingStateService') loadingStateService: any,
    private helpService: helpService,
    private Restangular: Restangular,
    private arrayFunctions: ArrayFunctions
  ) {
    this.snapshotLoadingStateService = angular.copy(loadingStateService)
    this.reportLoadingStateService = angular.copy(loadingStateService)
  }

  ngOnInit(): void {
    const customStates = {
      ready: {
        icon: 'fa-download',
        text: 'Download Report'
      }
    }
    this.reportLoadingStateService.loading_states = _.merge(this.reportLoadingStateService.loading_states, customStates)
    this.reportLoadingStateService.init()
    this.reportLoadingStateService.set_state('ready')
    this.snapshotLoadingStateService.init()
    this.watchEntityFilter()
  }

  executeRefresh = (pills: any) => {
    this.abortAllPromises(this.outstandingPromises)
    setTimeout(() => this.getAnalyticData(pills), 50)
    this.getDatePill(pills)
  }

  watchEntityFilter = () => {
    var refresh = (e: any, pills: any, resultsCount: any) => {
      let widgetShouldRefresh = this.arrayFunctions.hasMeaningfulListChange(this.previousPills, pills, ['sorted']) || this.previousPills === null
      this.previousPills = pills
      if (widgetShouldRefresh) {
        if (resultsCount <= 30000) {
          this.executeRefresh(pills)
        } else {
          this.snapshotLoadingStateService.set_state('query_too_large_warning')
          this.deferredRefresh = this.executeRefresh.bind(null, pills)
        }
      }
    }
    this.$scope.$on('refresh-dashboard-widgets', refresh)
    this.$scope.$on('cancel-pending-filter-queries', () => this.abortAllPromises(this.outstandingPromises))
  }

  getAnalyticData = (pills: any) => {
    this.snapshotLoadingStateService.set_state('loading')

    let queryParams = {
      model: 'Binder',
      method: 'snapshot',
      param_data: {}
    }
    queryParams.param_data = this.$rootScope.storeService.stringifyTagParams(
      this.$rootScope.storeService.pillsToObject(pills)
    )
    queryParams.param_data['sorted'] = ['risk']

    let abort = this.$q.defer()
    this.outstandingPromises.push(abort)

    this.Restangular.one('analytics/gather').withHttpConfig({ timeout: abort.promise }).get(queryParams).then((success: any) => {
      this.$rootScope.$broadcast('pagination-results-updated', success.pagination)
      this.snapshotLoadingStateService.process_success(success)
      this.organizationSnapshot = success
    }
    , (error: any) => {
      this.snapshotLoadingStateService.set_state('query_too_large_warning')
      this.deferredRefresh = this.executeRefresh.bind(null, pills)
    })
  }
  

  abortAllPromises = (promiseArray: any) => {
    if (Array.isArray(promiseArray) && promiseArray.length > 0) {
      promiseArray.forEach(p => p.resolve('cancel_pending_queries'))
    }
    promiseArray.length = 0
  }

  getDatePill = (pills: any) => {
    let datePill = pills.find((p: any) => p.Field === 'date')
    if (datePill) {
      this.datePills = JSON.parse(JSON.stringify(pills.find((p: any) => p.Field === 'date')))
      this.datePills.Value.date_range_begin = dayjs(this.datePills.Value.date_range_begin).format('MM-DD-YYYY')
      this.datePills.Value.date_range_end = dayjs(this.datePills.Value.date_range_end).format('MM-DD-YYYY')
    }
  }

  // fake a entry object to pass to the entry modal
  fakeItTillYouMakeIt = (entry_snapshot: any) => {
    let fake_entry
    return fake_entry = { id: entry_snapshot.entry_id, binder: { user_assigned_id: entry_snapshot.binder.user_assigned_id } }
  }

  // update the orginal entry snapshot
  youMadeIt = (entry_snapshot: any, entry_object: any) => entry_snapshot.status = entry_object.status

  // TODO: this may not be needed, Remove if this is true.
  openEntryModal = function (entry_snapshot: any, ui_setting: any) {
    const entry_object = this.fakeItTillYouMakeIt(entry_snapshot)
    const modalInstance = this.$uibModal.open({
      templateUrl: 'views/modals/entry_modal.html',
      controller: 'entryModalController',
      size: 'small',
      resolve: {
        modalOptions () {
          return {
            
            object: angular.copy(entry_object),
            ui_setting
          }
        }
      }
    })

    // on close
    // - grab the returned result,
    // - or log out a reason
    return modalInstance.result.then((result: any) => this.youMadeIt(entry_snapshot, result)
      , (reason: any) => {
      });
  }
}

angular
  .module('complyosClient')
  .directive('ngSnapshot', downgradeComponent({ component: SnapshotComponent }) as angular.IDirectiveFactory)
